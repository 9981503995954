import React from 'react';
import PropTypes from 'prop-types';

import { DefaultIntents } from 'constants/intents';
import { Manage } from '../../Icons';

function EditButton(props) {
  const { onClick, data } = props;

  if (data.intentName === DefaultIntents.FallbackIntent.displayIntentName) {
    return null;
  }

  return (
    <div>
      <div className="flex items-center">
        <button
          type="button"
          className="flex bg-spaceCadetBlue80 font-ptScanCap rounded-2xl items-center px-3 py-2 text-center hover:bg-spaceCadetBlue"
          onClick={onClick}
        >
          <Manage />
          <span className="text-white capitalize text-xs pl-1 font-bold">Edit Intent</span>
        </button>
      </div>
    </div>
  );
}

EditButton.propTypes = {
  onClick: PropTypes.func,
  data: PropTypes.any,
};

export default EditButton;
