/* eslint-disable react/forbid-prop-types */

import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import PhoneInputWithCountrySelect from 'react-phone-number-input';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import classNames from 'classnames';

import { RootState } from 'slices';
import { CustomSelect, InputText, PrimaryButton, TransactionTable } from 'components';
import GeneralButton from 'components/Buttons/GeneralButton';
import { Activate, Back, Save } from 'components/Icons';
import { countries, getValueForSelect } from 'utils/helpers';
import { COLLECTIONS } from 'constants/db';
import { ClientStatus } from 'enums/client.enum';
import { PATHS } from 'routes/paths';

import { ModalTypes, transactionTableHeaders } from './constants';
import FormEditHeader from './containers/FormEditHeader';
import ToggleStatusBtn from './components/ToggleStatusBtn';

function FormPage(props) {
  const {
    handleSubmit,
    onSubmit,
    register,
    errors,
    handleInputChange,
    formData,
    handleSelectChange,
    editMode,
    customLoading,
    control,
    handlePhoneNoChange,
    handleOnToggleAccountStatus,
    handleOnActivateAccount,
    triggerChild,
    restCountryProps,
  } = props;
  const navigate = useNavigate();

  const locationData = useSelector((state: RootState) => state.user.locationData);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="page">
      <div className="flex justify-between items-center">
        <div>
          {!editMode ? (
            <span className="text-spaceCadetBlue font-bold text-lg font-ptScanCap pb-4">
              Create a New Client
            </span>
          ) : (
            <FormEditHeader formData={formData} />
          )}
        </div>
        <div>
          <GeneralButton
            text="Back"
            btnColor="bg-spaceCadetBlue80"
            color="white"
            radius="2xl"
            textSize="xs"
            btnHoverColor="hover:bg-spaceCadetBlue"
            icon={<Back />}
            handleOnClick={() => navigate(-1)}
          />
        </div>
      </div>
      <hr className="my-3" />

      <div className="p-8 bg-white drop-shadow rounded-xl mt-6">
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="grid grid-cols-1 xs:grid-cols-1 rg:grid-cols-2 lg:grid-cols-3 gap-4">
            <div className="mb-4">
              <Controller
                name="companyName"
                control={control}
                render={({ field }) => (
                  <InputText
                    type="text"
                    elementId="companyName"
                    label="Company Name"
                    placeholder="eg: ABC Company Ltd"
                    register={register}
                    error={errors?.companyName}
                    value={formData?.companyName}
                    disabled={formData.isApproved}
                    onChange={(e) => {
                      field.onChange(e);
                      handleInputChange(e);
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 xs:grid-cols-1 rg:grid-cols-2 lg:grid-cols-3 gap-4">
            <div className="mb-4">
              <Controller
                name="adminEmail"
                control={control}
                render={({ field }) => (
                  <InputText
                    elementId="adminEmail"
                    label="Admin Account Email"
                    placeholder="eg: jdoe@abc.com"
                    type="email"
                    register={register}
                    error={errors.adminEmail}
                    value={formData.adminEmail}
                    disabled={formData.isApproved}
                    onChange={(e) => {
                      field.onChange(e);
                      handleInputChange(e);
                    }}
                  />
                )}
              />
            </div>
            <div className="mb-4">
              <Controller
                name="primaryContactName"
                control={control}
                render={({ field }) => (
                  <InputText
                    elementId="primaryContactName"
                    label="Primary Contact Name"
                    type="text"
                    placeholder="eg: John Doe"
                    register={register}
                    error={errors.primaryContactName}
                    value={formData.primaryContactName}
                    onChange={(e) => {
                      field.onChange(e);
                      handleInputChange(e);
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 xs:grid-cols-1 rg:grid-cols-2 lg:grid-cols-3 gap-4">
            <div className="form-control mb-4">
              <label className="label" htmlFor="primaryContactNo">
                <span className="label-text text-base font-base font-lato">
                  Primary Contact Number
                </span>
              </label>
              <Controller
                name="primaryContactNo"
                control={control}
                render={({ field }) => (
                  <PhoneInputWithCountrySelect
                    className={`input input-bordered focus:outline-none ${
                      errors?.primaryContactNo ? 'border border-begoniaRed' : ''
                    }`}
                    defaultCountry={locationData?.country_code}
                    value={formData?.primaryContactNo}
                    control={control}
                    id="primaryContactNo"
                    onChange={(e) => {
                      field.onChange(e);
                      handlePhoneNoChange('primaryContactNo', e);
                    }}
                    limitMaxLength
                  />
                )}
              />
              {errors?.primaryContactNo && (
                <label className="label relative mt-2" htmlFor="primaryContactNo">
                  <span className="label-text-alt absolute text-error">
                    {errors?.primaryContactNo?.message?.toString()}
                  </span>
                </label>
              )}
            </div>

            <div className="form-control mb-4">
              <label className="label" htmlFor="secondaryContactNo">
                <span className="label-text text-base font-base font-lato">
                  Secondary Contact Number
                </span>
              </label>
              <Controller
                name="secondaryContactNo"
                control={control}
                render={({ field }) => (
                  <PhoneInputWithCountrySelect
                    className={`input input-bordered focus:outline-none ${
                      errors?.secondaryContactNo ? 'border border-begoniaRed' : ''
                    }`}
                    defaultCountry={locationData?.country_code}
                    value={formData?.secondaryContactNo}
                    control={control}
                    id="secondaryContactNo"
                    onChange={(e) => {
                      field.onChange(e);
                      handlePhoneNoChange('secondaryContactNo', e);
                    }}
                    limitMaxLength
                  />
                )}
              />
              {errors?.secondaryContactNo && (
                <label className="label relative mt-2" htmlFor="secondaryContactNo">
                  <span className="label-text-alt absolute text-error">
                    {errors?.secondaryContactNo?.message?.toString()}
                  </span>
                </label>
              )}
            </div>
          </div>
          <div className="grid grid-cols-1 xs:grid-cols-1 rg:grid-cols-2 lg:grid-cols-3 gap-4">
            <div className="mb-4">
              <div className="form-control">
                <label className="label" htmlFor="country">
                  <span className="label-text text-base font-base font-lato">Country</span>
                </label>
              </div>
              <CustomSelect
                id="country"
                required
                isClearable
                options={countries}
                error={errors?.country}
                value={getValueForSelect(formData?.country, countries)}
                onChange={(event) => handleSelectChange(event, 'country')}
                {...restCountryProps}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 xs:grid-cols-1 rg:grid-cols-2 lg:grid-cols-3 gap-4">
            <div className="mb-4">
              <Controller
                name="streetAddress"
                control={control}
                render={({ field }) => (
                  <InputText
                    elementId="streetAddress"
                    label="Street Address"
                    type="text"
                    register={register}
                    error={errors.streetAddress}
                    value={formData.streetAddress}
                    onChange={(e) => {
                      field.onChange(e);
                      handleInputChange(e);
                    }}
                  />
                )}
              />
            </div>
            <div className="mb-4">
              <Controller
                name="city"
                control={control}
                render={({ field }) => (
                  <InputText
                    elementId="city"
                    label="City"
                    type="text"
                    placeholder=""
                    register={register}
                    error={errors.city}
                    value={formData.city}
                    onChange={(e) => {
                      field.onChange(e);
                      handleInputChange(e);
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 xs:grid-cols-1 rg:grid-cols-2 lg:grid-cols-3 gap-4">
            <div className="mb-4">
              <Controller
                name="stateProvinceRegion"
                control={control}
                render={({ field }) => (
                  <InputText
                    elementId="stateProvinceRegion"
                    label="State/ Province / Region"
                    type="text"
                    placeholder=""
                    register={register}
                    error={errors.stateProvinceRegion}
                    value={formData.stateProvinceRegion}
                    onChange={(e) => {
                      field.onChange(e);
                      handleInputChange(e);
                    }}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                name="postalCodeZipCode"
                control={control}
                render={({ field }) => (
                  <InputText
                    elementId="postalCodeZipCode"
                    label="ZIP Code/ Postal Code"
                    type="text"
                    placeholder=""
                    register={register}
                    error={errors.postalCodeZipCode}
                    value={formData.postalCodeZipCode}
                    onChange={(e) => {
                      field.onChange(e);
                      handleInputChange(e);
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="mt-4 mb-4">
            <PrimaryButton
              text={editMode ? 'Update Client Account' : 'Save'}
              btnColor="bg-spaceCadetBlue80"
              color="white"
              radius="2xl"
              textSize="base"
              btnHoverColor="hover:bg-spaceCadetBlue"
              icon={<Save />}
              disabled={customLoading.formSubmit}
              loading={customLoading.formSubmit}
            />
          </div>
        </form>
      </div>

      {editMode && formData._id && (
        <div className="p-8 bg-white drop-shadow rounded-xl mt-6">
          <div className="flex flex-row justify-end">
            {formData.status === ClientStatus.UNAPPROVED && (
              <motion.button
                onClick={() => handleOnActivateAccount(formData, ModalTypes.ActivateAccountModal)}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.9 }}
                type="submit"
                className={classNames(
                  'px-[20px] py-[10px] rounded-2xl text-white font-ptScanCap bg-spaceCadetBlue80 hover:bg-spaceCadetBlue',
                  {
                    'bg-spaceCadetBlue80': customLoading.activateAccount,
                    'bg-spaceCadetBlue': !customLoading.activateAccount,
                  },
                )}
              >
                {customLoading.activateAccount ? (
                  <span className="loading loading-infinity loading-lg" />
                ) : (
                  <span className="text-sm font-semibold flex flex-row items-center">
                    <Activate /> &nbsp; Activate
                  </span>
                )}
              </motion.button>
            )}
            {formData.status !== ClientStatus.UNAPPROVED && (
              <ToggleStatusBtn
                formData={formData}
                loading={customLoading.toggleAccount}
                disabled={customLoading.toggleAccount}
                handleOnToggleAccountStatus={handleOnToggleAccountStatus}
              />
            )}
          </div>
          <TransactionTable
            recordId={formData._id}
            collection={COLLECTIONS.Client}
            transactionTableHeaders={transactionTableHeaders}
            triggerChild={triggerChild.transactions}
          />
        </div>
      )}
    </div>
  );
}

FormPage.propTypes = {
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  register: PropTypes.func,
  handlePhoneNoChange: PropTypes.func,
  errors: PropTypes.any,
  formData: PropTypes.any,
  control: PropTypes.any,
  handleInputChange: PropTypes.func,
  handleOnActivateAccount: PropTypes.func,
  handleOnToggleAccountStatus: PropTypes.func,
  handleSelectChange: PropTypes.func,
  editMode: PropTypes.bool,
  customLoading: PropTypes.shape({
    formSubmit: PropTypes.bool,
    toggleAccount: PropTypes.bool,
    activateAccount: PropTypes.bool,
  }),
  triggerChild: PropTypes.any,
  restCountryProps: PropTypes.any,
};

export default FormPage;
