import React from 'react';

import { countryCodesList, getValueForSelect } from 'utils/helpers';
import { reactSelectPhoneInputStyles } from 'styles/select';

import { IFormElementProps } from './InputText';
import CustomSelect from './CustomSelect';

interface IPhoneInputProps extends IFormElementProps {
  dialCodeElementId?: string;
  className?: any;
  value?: string;
  countryCodeValue?: string;
  onCountryCodeChange?: any;
  maxLength?: number;
}

/**
 * Represents a phone number input component that integrates with `react-hook-form`.
 *
 * @component
 *
 * @param {IPhoneInputProps} props - The properties of the `PhoneInput` component.
 * @param {string} props.label - The label to display above the input field.
 * @param {string} props.elementId - The unique identifier for the input element.
 * @param {string} [props.placeholder] - The placeholder text to show in the input field.
 * @param {IValidationRules} [props.validationRules] - Validation rules for the input field.
 * @param {IErrorMessages} [props.errorMessages] - Error messages for different validation errors.
 * @param {UseFormRegister<FieldValues>} props.register - `react-hook-form` to register the input.
 * @param {FieldError | Merge<FieldError, FieldErrorsImpl<any>>} [props.error] - Error object.
 *
 * @returns {JSX.Element} - A JSX element representing the `PhoneInput` component.
 *
 * @example
 * Usage example of the `PhoneInput` component.
 * <PhoneInput
 *   elementId="phone"
 *   label="Phone Number"
 *   placeholder="Enter your phone number"
 *   value=""
 *   value="+94"
 *   register={register}
 *   error={errors.phone}
 *   validationRules={{ required: true, pattern: /^\+?\d+$/ }}
 *   errorMessages={{
 *     required: 'Phone number is required',
 *     pattern: 'Please enter a valid phone number',
 *   }}
 * />
 */
function PhoneInput(props: IPhoneInputProps): JSX.Element {
  const {
    label,
    elementId,
    dialCodeElementId,
    placeholder = '',
    value,
    countryCodeValue,
    register,
    error,
    onChange,
    onCountryCodeChange,
    maxLength,
  } = props;

  return (
    <div className="form-control">
      <label className="label" htmlFor="countryCode">
        <span className="label-text text-base font-normal font-lato">{label}</span>
      </label>
      <div className="join input_group__phone_code__container">
        {/* country code dropdown */}
        <CustomSelect
          options={countryCodesList}
          customStyles={reactSelectPhoneInputStyles}
          value={getValueForSelect(countryCodeValue, countryCodesList)}
          onChange={(val) => onCountryCodeChange(val, dialCodeElementId)}
          id={elementId}
        />

        {/* mobile number input */}
        <div className="w-full">
          <div className="w-full">
            <input
              {...(register ? register(elementId) : {})}
              id={elementId}
              type="number"
              value={value || ''}
              placeholder={placeholder}
              className={`input input-bordered join-item w-full ${error ? 'input-error' : ''}`}
              onChange={(e) => {
                if (e.target.value.length > (maxLength || 10)) {
                  return;
                }
                onChange(e);
              }}
            />
          </div>
        </div>
      </div>

      {error && (
        <label className="label relative p-y-2" htmlFor={elementId}>
          <span className="label-text-alt absolute text-error">{error.message.toString()}</span>
        </label>
      )}
    </div>
  );
}

export default PhoneInput;
