import React, { useCallback, useRef } from 'react';
import ReactDom from 'react-dom';
import { Button, Modal } from 'react-daisyui';
import PropTypes from 'prop-types';

// #region assets | components
import PrimaryButton from 'components/Buttons/Primary';
import CustomSelect from 'components/Form/CustomSelect';
import { SendApprove, ApproveLater, Activate } from 'components/Icons';

// #region utils
import { reactSelectCustomStyles } from 'styles/select';

/**
 * This model use to send user account for the approval
 * @param props
 * @returns {JSX.Element} - A JSX element representing the `Modal` component.
 */
function ApprovalModal(props) {
  const { title, userName, id, show, onClose } = props;

  const ref = useRef<HTMLDialogElement>(null);

  /** This function use to view and hide the model */
  const handleShow = useCallback(() => {
    ref.current?.showModal();
  }, [ref]);

  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
  ];

  return ReactDom.createPortal(
    <div>
      <Modal
        open={show}
        className="top-0 left-0 right-0 p-0 border border-gainsboroBlue rounded-lg z-50 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(auto-1rem)] max-w-3xl max-h-full"
      >
        <Modal.Header className="font-bold pb-2 mb-2 border-b p-4">
          <div className="flex justify-between items-center">
            <div className="grid grid-cols-1 sm:grid-cols-2 w-fit flex-wrap items-center">
              <div className="text-xl font-bold font-ptScanCap">{title}</div>
            </div>
            <div className="text-sm font-normal bg-red">
              <Button
                size="xs"
                shape="circle"
                className="btn btn-xs btn-circle border-2 bg-white border-rasinBlack font-bold"
                onClick={onClose}
              >
                x
              </Button>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="p-4">
          <div className="text-sm text-center mt-1">
            <p className="font-lato font-normal text-sm">{userName}</p>
            <p className="font-lato font-normal text-sm">User ID: {id}</p>
          </div>
          <div className="border border-northTexasGreen bg-milkGreen rounded-xl p-4 mt-5 grid grid-cols-1">
            <span className="text-sm font-normal font-lato">Select an Approver from the list.</span>
            <div className="mt-4 mb-4">
              <CustomSelect
                isClearable
                isDisabled
                isLoading
                options={options}
                customStyles={reactSelectCustomStyles}
              />
            </div>
            <PrimaryButton
              text="Send for Approval"
              btnColor="bg-spaceCadetBlue80"
              btnHoverColor="hover:bg-spaceCadetBlue"
              color="white"
              icon={<SendApprove />}
              width="[100px]"
              textSize="sm font-bold font-ptScanCap"
              radius="2xl"
            />
          </div>
        </Modal.Body>
        <div className="w-full pt-5 pb-5 mt-3 bg-culturedGray flex flex-wrap justify-center items-center">
          <PrimaryButton
            text="Approve Later"
            btnColor="bg-spaceCadetBlue80"
            btnHoverColor="hover:bg-spaceCadetBlue"
            color="white"
            icon={<ApproveLater />}
            width="[100px]"
            textSize="md font-bold font-ptScanCap"
            radius="2xl"
            handleOnClick={onClose}
          />
        </div>
      </Modal>
    </div>,
    document.body, // render out side the parent components hierarchy(in the body)
  );
}

ApprovalModal.propTypes = {
  title: PropTypes.string,
  userName: PropTypes.string,
  id: PropTypes.string,
  btnText: PropTypes.string,
  btnBgColor: PropTypes.string,
  btnTextColor: PropTypes.string,
  btnHoverColor: PropTypes.string,
  onConfirm: PropTypes.func,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  btnIcon: PropTypes.element,
};

export default ApprovalModal;
