/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import PropTypes from 'prop-types';
import ReactDom from 'react-dom';

import { Drawer } from 'react-daisyui';
import EditMessage from './EditMessage';

function SideBar({
  isSelected,
  textRef,
  nodeName,
  setNodeName,
  handleVariable,
  visible,
  toggleHide,
  selectedNode,
}) {
  // return ReactDom.createPortal(
  //   <Drawer
  //     end
  //     open={visible}
  //     onClickOverlay={toggleHide}
  //     side={
  //       <>
  //         {isSelected && nodeName?.id !== '1' && (
  //           <div className="bg-[#F9F9F9] min-h-screen w-1/3">
  //             <EditMessage
  //               textRef={textRef}
  //               nodeName={nodeName}
  //               setNodeName={setNodeName}
  //               handleVariable={handleVariable}
  //               isSelected={isSelected}
  //             />
  //           </div>
  //         )}
  //       </>
  //     }
  //   />,
  //   document.body,
  // );
  return (
    <div className="bg-gray-50 p-4 h-screen">
      {isSelected && nodeName?.id !== '1' ? (
        <EditMessage
          textRef={textRef}
          nodeName={nodeName}
          setNodeName={setNodeName}
          handleVariable={handleVariable}
        />
      ) : (
        <div className="p-2 m-4">Message</div>
      )}
    </div>
  );
}

SideBar.propTypes = {
  isSelected: PropTypes.bool,
  textRef: PropTypes.any,
  nodeName: PropTypes.any,
  setNodeName: PropTypes.any,
  handleVariable: PropTypes.func,
  visible: PropTypes.bool,
  toggleHide: PropTypes.func,
  selectedNode: PropTypes.any,
};

export default SideBar;
