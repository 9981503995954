import * as React from 'react';
import PropTypes from 'prop-types';

function UserApprove(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color, width, height } = props;
  return (
    <svg
      width={22}
      height={22}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#clip0_857_410)"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M10 12a2 2 0 104 0 2 2 0 00-4 0z" />
        <path d="M11.102 17.957C7.898 17.65 5.198 15.663 3 12c2.4-4 5.4-6 9-6 3.6 0 6.6 2 9 6-.21.35-.431.695-.663 1.032M15 19l2 2 4-4" />
      </g>
      <defs>
        <clipPath id="clip0_857_410">
          <path fill="#fff" d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
UserApprove.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default UserApprove;
