/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { apiErrorHandler } from 'api/handler';
import { RootState } from 'slices';
import { updateURLWithSearchParams } from 'utils/helpers';
import { PromptModal } from 'components';

import { fetchContactListData } from 'services/contact.service';
import {
  createCase,
  getCaseById,
  linkUnlinkConversationService,
  updateCase,
} from 'services/case.service';
import { IContactListURLQueryParams } from 'views/Contact/List/interface';
import { createNote, deleteNote, updateNote } from 'services/note.service';
import { INote } from 'views/Contact/ContactView/interface';
import { caseFormValidationSchema } from './validations';
import CaseFormPage from './page';
import UpdateContact from './modals/updateContact';
import ReopenCaseModal from './modals/reopenCase';
import { ModalTypes, PromptTypes, modalDefaultOptions, promptDefaultOptions } from './constants';
import LinkChatModal from './modals/linkChatModal';
import UnlinkChatModal from './modals/unlinkChatModal';

function CasesForm() {
  const userSlice = useSelector((state: RootState) => state.user);

  const [searchParams] = useSearchParams();

  const [formData, setFormData] = useState<any>({
    severity: 'medium',
  });

  const [promptOptions, setPromptOptions] = useState(promptDefaultOptions);
  const [modalOptions, setModalOptions] = useState(modalDefaultOptions);
  const [isOpenAddNoteDrawer, setIsOpenAddNoteDrawer] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const [customLoading, setCustomLoading] = useState({
    formSubmit: false,
    activateAccount: false,
    toggleAccount: false,
    fetchForm: false,
  });

  const location = useLocation();
  const navigate = useNavigate();

  const [triggerChild, setTriggerChild] = useState({ transactions: null });

  // contacts data based on the client
  const [contactListData, setContactListData] = useState([]);

  // data relevant to a selected contact in the dropdown
  const [selectedContactData, setSelectedContactData] = useState<any>({});

  // data relevant to a selected contact in the dropdown
  const [currentContactData, setCurrentContactData] = useState({});

  // show contact drawer model
  const [visible, setVisible] = useState(false);

  // state for header data in edit mode
  const [headersData, setHeaderData] = useState({});

  // active tab in the bottom table section in edit mode
  const [activeTab, setActiveTab] = useState('Conversations');

  const [isCaseClosed, setIsCaseClosed] = useState(false);
  const [isDisabledContact, setIsDisabledContact] = useState(false);

  const pageType = searchParams.get('md');
  const pageId = searchParams.get('i');

  const { register, handleSubmit, formState, control } = useForm<any>({
    resolver: yupResolver(caseFormValidationSchema),
    values: useMemo(() => formData, [formData]),
  });

  const { field: countryField } = useController({ name: 'country', control });

  const { errors } = formState;

  useEffect(() => {
    if (!pageType) {
      updateURLWithSearchParams({ md: 'new' });
    }
    if (pageType && pageType === 'edit' && pageId) {
      setEditMode(true);
      fetchCasesData(pageId);
    }
    getContactListData();
  }, []);

  // set default location based values for new form
  useEffect(() => {
    if (!editMode && userSlice.locationData?.country_code) {
      const { country_code, country_calling_code } = userSlice.locationData;
      setFormData((prevState: any) => ({
        ...prevState,
        country: country_code,
        dialCode: country_calling_code,
      }));
    }
  }, [userSlice.locationData]);

  useEffect(() => {
    const { onChange: onCountryChange } = countryField;
    onCountryChange(formData.country);
  }, [formData?.country]);

  useEffect(() => {
    setFormData({
      ...formData,
      primaryContactNo: selectedContactData?.primaryContactNo,
      adminEmail: selectedContactData?.email,
    });
  }, [selectedContactData?.primaryContactNo, selectedContactData?.email]);

  async function fetchCasesData(id: string) {
    try {
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: true, fetchForm: true }));
      const { data } = await getCaseById(id);
      if (data?.node) {
        const mappedData = {
          ...data?.node[0],
        };
        setCurrentContactData(data.node[0]?.contactData);
        setSelectedContactData(data.node[0]?.contactData);
        setFormData(mappedData);
        if (mappedData?.status === 'closed' || mappedData?.status === 'completed') {
          setIsCaseClosed(true);
        }
        setHeaderData(mappedData);
      }
    } catch (e) {
      const { message: exception } = apiErrorHandler(e);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: false, fetchForm: false }));
    }
  }

  // Handles the form submission
  const onSubmit = async () => {
    try {
      const payload = formData;
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: true }));
      // create a client using the form data
      if (!editMode) {
        const { data } = await createCase(payload);
        if (data) {
          const mappedData = {
            ...data?.node,
          };
          setFormData({ ...payload, ...mappedData });
          setEditMode(true);
          updateURLWithSearchParams({ md: 'edit', i: data?.node?._id });
          setCurrentContactData(selectedContactData);
          setHeaderData({ ...payload, ...mappedData });
          setTriggerChild((prevState) => ({ ...prevState, transactions: uuid() }));
          toast.success('Successfully Created a Case');
        }
      } else {
        if (formData?.status === 'closed' || formData?.status === 'completed') {
          handleOnPromptModel();
          return;
        }
        await UpdateCaseFunction(payload);
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: false }));
    }
  };

  // general function for updating the case
  const UpdateCaseFunction = async (payload: any) => {
    const { data } = await updateCase(payload);
    if (data) {
      const mappedData = {
        ...data?.node,
      };
      setFormData({ ...payload, ...mappedData });
      setEditMode(true);
      updateURLWithSearchParams({ md: 'edit', i: data?.node?._id });
      setHeaderData({ ...payload, ...mappedData });
      setTriggerChild((prevState) => ({ ...prevState, transactions: uuid() }));
      toast.success('Successfully Updated the Case');
    }
  };

  /**
   * Fetch contact list data based on provided query parameters
   *
   * @param {IContactListURLQueryParams} params - Url search params
   */
  const getContactListData = async (params: IContactListURLQueryParams = {}) => {
    try {
      const { data } = await fetchContactListData((params = { page: null, limit: null }));
      const docs = (data?.docs || []).map((doc) => ({
        value: doc?._id,
        label: `${doc?.firstName || ''} ${doc?.lastName || ''} ${doc?.email || ''} ${
          doc?.primaryContactNo || ''
        }`,
        other: {
          _id: doc?._id,
          firstName: doc?.firstName,
          lastName: doc?.lastName,
          primaryContactNo: doc?.primaryContactNo,
          email: doc?.email,
          streetAddress: doc?.streetAddress,
          company: doc?.company,
          designation: doc?.designation,
        },
      }));

      setContactListData(docs);
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    }
  };

  function loadDynamicLoadingProp() {
    if (promptOptions.type === PromptTypes.ToggleAccount) {
      return customLoading.toggleAccount;
    }
    return false;
  }

  /** **************************** Component Handling functions ********************************* */
  // handle the input text change
  const handleInputChange = (event: { target: { id: any; value: any } }) => {
    const { id, value } = event.target;
    setFormData({
      ...formData,
      ...selectedContactData,
      [id]: value,
    });
  };

  // handle the dropdown change
  const handleSelectChange = (event: { value: any; other: any }, id: string) => {
    if (id === 'contactId') {
      setSelectedContactData({ ...event?.other });
      setIsDisabledContact(true);
      if (event === null) {
        setIsDisabledContact(false);
      }
    }
    if (id === 'tags') {
      setFormData({
        ...formData,
        [id]: event || null,
      });
    } else {
      setFormData({
        ...formData,
        [id]: event?.value || null,
      });
    }
  };

  // handle phone number change
  const handlePhoneNoChange = (id: string, value: string) => {
    setFormData({
      ...formData,
      [id]: value,
    });
  };
  /** ***************************************************************************** */

  /** **************************** Modal Triggering functions ********************************* */
  // contact update modal triggering function
  const handleOnUpdateContact = () => {
    setModalOptions((prevState) => ({
      ...prevState,
      show: true,
      actionStatus: false,
      type: ModalTypes.UpdateContact,
      data: { formData, contactListData },
    }));
  };

  // Reopen case modal triggering function
  const handleOnReopenCase = (event) => {
    event.preventDefault();
    setModalOptions((prevState) => ({
      ...prevState,
      show: true,
      actionStatus: false,
      type: ModalTypes.ReopenCase,
      data: {},
    }));
  };

  const handleOnLinkChat = (event) => {
    event.preventDefault();
    setModalOptions((prevState) => ({
      ...prevState,
      show: true,
      actionStatus: false,
      type: ModalTypes.LinkChat,
      data: { recordId: formData._id },
    }));
  };

  const handleOnUnlinkChat = (event) => {
    event.preventDefault();
    setModalOptions((prevState) => ({
      ...prevState,
      show: true,
      actionStatus: false,
      type: ModalTypes.UnlinkChat,
      data: { recordId: formData._id },
    }));
  };

  // Closed or Complete case modal triggering function
  const handleOnPromptModel = () => {
    setPromptOptions({
      show: true,
      title: 'Are You Sure ',
      message: `Do you want to ${formData?.status} the case? Once you marked as ${formData?.status} You will no longer be able to edit the case.`,
      onConfirm: () => handleClosedCase(),
      type: PromptTypes.ToggleAccount,
    });
  };

  // Delete modal triggering function
  const handleOnPromptDeleteNoteModal = (data: any) => {
    setPromptOptions({
      show: true,
      title: 'Are You Sure?',
      message: 'Are you sure you want to Delete this Note?',
      onConfirm: () => handleDeleteNote(data._id),
      type: PromptTypes.ToggleAccount,
    });
  };
  /** ***************************************************************************** */

  const handleClosedCase = async () => {
    try {
      const payload = formData;
      setCustomLoading((prevState) => ({ ...prevState, activateAccount: true }));
      await UpdateCaseFunction(payload);
      setIsCaseClosed(true);
      setPromptOptions({
        show: false,
        ...promptOptions,
      });
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, activateAccount: false }));
    }
  };

  // When click the confirm button client account activate
  const handleUpdateContact = async (contactId) => {
    try {
      const payload = formData;
      payload.contactId = contactId;
      setCustomLoading((prevState) => ({ ...prevState, activateAccount: true }));
      const { data } = await updateCase(payload);
      if (data?.node) {
        toast.success('Successfully Updated the Contact');
        setCurrentContactData(data.node.contactData);
        const { primaryContactNo, email } = data.node.contactData;
        setFormData({
          ...formData,
          primaryContactNo,
          adminEmail: email,
        });
        setTriggerChild((prevState) => ({ ...prevState, transactions: uuid() }));
        setModalOptions({ ...modalOptions, show: false });
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, activateAccount: false }));
    }
  };

  const handleReopenCase = async (reason) => {
    try {
      const payload = formData;
      payload.reopenReason = reason;
      payload.status = 'open';
      setCustomLoading((prevState) => ({ ...prevState, activateAccount: true }));
      await UpdateCaseFunction(payload);
      setIsCaseClosed(false);
      setModalOptions({ ...modalOptions, show: false });
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, activateAccount: false }));
    }
  };

  /**
   * Delete Note
   */
  const handleDeleteNote = async (id: string) => {
    try {
      await deleteNote(id);
      toast.success('Note Deleted Successfully');
      setPromptOptions({
        show: false,
        ...promptOptions,
      });
      setTriggerChild((prevState) => ({ ...prevState, transactions: uuid() }));
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    }
  };

  const handleLinkUnlinkChat = async (conversationList: any, isUnlink = false) => {
    try {
      if (conversationList.length === 0) {
        return;
      }
      const stringIdArray = conversationList.map((obj: any) => obj._id);
      const payload: any = {
        isUnlink: false,
        conversationIds: stringIdArray,
      };
      if (isUnlink) {
        payload.isUnlink = true;
      }
      const data = await linkUnlinkConversationService(formData._id, payload);
      if (data.status === 200) {
        toast.success('Note Updated Successfully');
        setModalOptions({ ...modalOptions, show: false });
        setTriggerChild((prevState) => ({ ...prevState, transactions: uuid() }));
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    }
  };

  /** other triggering functions */
  const showAddNote = () => {
    setIsOpenAddNoteDrawer(!isOpenAddNoteDrawer);
  };

  // view contact drawer show
  const showPreview = () => {
    setVisible(!visible);
  };

  // handling the tab names in edit mode
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <>
      <CaseFormPage
        onSubmit={onSubmit}
        errors={errors}
        formData={formData}
        handleInputChange={handleInputChange}
        handleSelectChange={handleSelectChange}
        handleSubmit={handleSubmit}
        register={register}
        editMode={editMode}
        customLoading={customLoading}
        control={control}
        handlePhoneNoChange={handlePhoneNoChange}
        triggerChild={triggerChild}
        contactListData={contactListData}
        visible={visible}
        selectedContactData={selectedContactData}
        currentContactData={currentContactData}
        showPreview={showPreview}
        handleTabClick={handleTabClick}
        activeTab={activeTab}
        headersData={headersData}
        updateContact={handleOnUpdateContact}
        promptHandler={handleOnPromptDeleteNoteModal}
        isCaseClosed={isCaseClosed}
        reopenCase={handleOnReopenCase}
        showAddNote={showAddNote}
        isOpenAddNoteDrawer={isOpenAddNoteDrawer}
        setIsOpenAddNoteDrawer={setIsOpenAddNoteDrawer}
        handleOnLinkChat={handleOnLinkChat}
        handleOnUnlinkChat={handleOnUnlinkChat}
        setTriggerChild={setTriggerChild}
        isDisabledContact={isDisabledContact}
      />

      {modalOptions.type === ModalTypes.UpdateContact && modalOptions.show && (
        <UpdateContact
          handleUpdate={handleUpdateContact}
          {...modalOptions}
          toggleModal={() => setModalOptions({ ...modalOptions, show: false })}
        />
      )}

      {modalOptions.type === ModalTypes.ReopenCase && modalOptions.show && (
        <ReopenCaseModal
          handleUpdate={handleReopenCase}
          {...modalOptions}
          toggleModal={() => setModalOptions({ ...modalOptions, show: false })}
        />
      )}

      {promptOptions.show && (
        <PromptModal
          onCancel={() => {
            setPromptOptions({ ...promptOptions, show: false });
          }}
          {...promptOptions}
          loading={loadDynamicLoadingProp()}
        />
      )}

      {modalOptions.type === ModalTypes.ReopenCase && modalOptions.show && (
        <ReopenCaseModal
          handleUpdate={handleReopenCase}
          {...modalOptions}
          toggleModal={() => setModalOptions({ ...modalOptions, show: false })}
        />
      )}

      {modalOptions.type === ModalTypes.LinkChat && modalOptions.show && (
        <LinkChatModal
          handleUpdate={handleLinkUnlinkChat}
          triggerChild={triggerChild}
          {...modalOptions}
          toggleModal={() => setModalOptions({ ...modalOptions, show: false })}
        />
      )}
      {modalOptions.type === ModalTypes.UnlinkChat && modalOptions.show && (
        <UnlinkChatModal
          handleUpdate={handleLinkUnlinkChat}
          triggerChild={triggerChild}
          {...modalOptions}
          toggleModal={() => setModalOptions({ ...modalOptions, show: false })}
        />
      )}
    </>
  );
}

export default CasesForm;
