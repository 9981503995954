export const defaultTransactionTableHeaders = [
  {
    key: 'sequenceId',
    value: 'Transaction ID',
  },
  {
    key: 'description',
    value: 'Transaction Description',
  },
  {
    key: 'createdAt',
    value: 'Transaction Date and Time',
    type: 'dateTime',
  },
  {
    key: 'createdBy',
    value: 'User',
    type: 'transactionUser',
  },
];
