/* eslint-disable implicit-arrow-linebreak */
import _ from 'lodash';

import { TOKEN, USER_NODE, USER_PERMISSIONS } from 'constants/local-storage.const';
import { logoutAction } from 'slices/auth';
import { SideBarMenuItem } from 'routes/sideBar.routes';
import { AuthLogoutTypes } from 'enums/auth.enum';

import { encryptedLocalStorage } from './local-storage';

/**
 * Get the access token from local storage.
 * @returns {string | null} The access token.
 */
export const getAccessToken = (): string | null => encryptedLocalStorage.getItem(TOKEN);

/**
 * Get the client id from local storage.
 * @returns {string | null} The access token.
 */
export const getClientId = (): string | null =>
  encryptedLocalStorage.getItem(USER_NODE)?.clientId || null;

/**
 * Get the user type from local storage.
 * @returns {string | null} The access token.
 */
export const getUserType = (): string | null =>
  encryptedLocalStorage.getItem(USER_NODE)?.userType || null;

/**
 * Get the user id from local storage.
 * @returns {string | null} The access token.
 */
export const getUserId = (): string | null => encryptedLocalStorage.getItem(USER_NODE)?._id || null;

/**
 * Store the access token in local storage.
 * @param {string} token - The access token to store.
 */
export const storeAccessToken = (token: string) => encryptedLocalStorage.setItem(TOKEN, token);

/**
 * Logout handler function.
 * @param {Function} dispatch - Redux dispatch function.
 */
export const logoutHandler = (dispatch: any, type: AuthLogoutTypes) => {
  handleBrowserLogout();
  // Dispatch the logout action
  dispatch(logoutAction());
};

export function handleBrowserLogout() {
  // Additional logic for clearing the access token if needed
  encryptedLocalStorage.removeItem(TOKEN);
  encryptedLocalStorage.removeItem(USER_NODE);
  encryptedLocalStorage.removeItem(USER_PERMISSIONS);
}

/**
 * Check if a user has a specific permission.
 * @param {string|string[]} permission - The permission or permissions to check.
 * @returns {boolean} True if the user has the permission(s), otherwise false.
 */
export const hasPermissionAccess = (permission: string | string[]): boolean => {
  const permissions = encryptedLocalStorage.getItem(USER_PERMISSIONS) || [];

  // Convert single string permission to an array for uniform processing
  if (!Array.isArray(permission)) {
    permission = [permission];
  }

  if (Array.isArray(permissions)) {
    // Check if any of the permissions in the array match
    return permissions.some((perm) => permissions.some((x) => x.key === perm));
  }
  return false;
};

/**
 * Check if a user has access to a menu item based on permissions and user type.
 * @param {SideBarMenuItem} item - The menu item to check.
 * @returns {boolean} True if the user has access, otherwise false.
 */
export function checkMenuPermission(item: SideBarMenuItem): boolean {
  const { permissionKeys, label, subMenu } = item;
  let { userAccesses = [] } = item;

  const userPermissions = encryptedLocalStorage.getItem(USER_PERMISSIONS) || [];
  const user = encryptedLocalStorage.getItem(USER_NODE);

  if (!user) {
    return false;
  }

  // Single Parent && Multiple Children
  if (subMenu && subMenu.length > 0) {
    userAccesses = subMenu.map((obj) => obj.userAccesses).flat();
    userAccesses = _.uniq(userAccesses);

    if (!userAccesses.some((x) => x.includes(user.userType))) {
      return false;
    }
  }

  // Single Parent && No Children || Sub Parent
  if (!subMenu || subMenu.length === 0) {
    if (!userAccesses.some((x) => x.includes(user.userType))) {
      return false;
    }
  }

  // !TODO: Temporarily commented until new client new user is given role and permission
  // if (userPermissions.length === 0) {
  //   return false;
  // }

  // Check if any permissionKey is present in userPermissions
  // !TODO: Temporarily commented until new client new user is given role and permission
  // const hasPermission = permissionKeys.some(
  //   (permissionKey) =>
  //     userPermissions.some((userPermission: Permission) => userPermission.key === permissionKey),
  //   // @ts-ignore
  // );

  // !TODO: Temporarily commented until new client new user is given role and permission
  // if (hasPermission) {
  //   return true;
  // }

  // return false;
  return true;
}

/**
 * Check if a user has access based on user type.
 * @param {string} permission - The permission to check.
 * @returns {boolean} True if the user has the permission, otherwise false.
 */
export const hasAccess = (userTypes = []): boolean => {
  const user = encryptedLocalStorage.getItem(USER_NODE);

  if (!user) {
    return false;
  }

  if (user?.userType && userTypes.includes(user.userType)) {
    return true;
  }

  return false;
};
