/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-daisyui';
import { toast } from 'react-toastify';

import { CustomSelect, PrimaryButton } from 'components';
import { SendApprove, ViewContact } from 'components/Icons';
import { fetchUsersWithPermission } from 'services/user.service';
import { PERMISSIONS } from 'constants/permissions';
import { apiErrorHandler } from 'api/handler';
import { getValueForSelect } from 'utils/helpers';
import { getUserId } from 'utils/auth';
import { PATHS } from 'routes/paths';

function SendForApprovalModal(props) {
  const { show, toggleModal, handleSendForApproval, loading, formData } = props;

  const navigate = useNavigate();

  const { name, sequenceId } = formData;
  const currentUserId = getUserId();

  const [userOptions, setUserOptions] = useState([]);
  const [customLoading, setCustomLoading] = useState({ users: false });
  const [selectedUserId, setSelectedUserId] = useState(null);

  useEffect(() => {
    fetchUsersForApproval();
  }, [formData?.clientId, formData?.userType]);

  const fetchUsersForApproval = async () => {
    try {
      setCustomLoading((prevState) => ({ ...prevState, users: true }));
      const { data } = await fetchUsersWithPermission(
        PERMISSIONS.USER_MANAGEMENT.USERS.APPROVE_USER,
        {
          clientId: formData?.clientId,
          userType: formData?.userType,
        },
      );
      if (data?.nodes) {
        setUserOptions(
          data.nodes
            .filter((val) => val._id !== formData._id && val._id !== currentUserId)
            .map((val) => ({ label: val.name, value: val._id })),
        );
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, users: false }));
    }
  };

  return (
    <Modal
      open={show}
      className="top-0 left-0 right-0 p-0 border border-gainsboroBlue rounded-lg z-50 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(auto-1rem)] max-w-2xl max-h-full"
    >
      <Modal.Header className="font-bold pb-2 mb-2 p-4">
        <div className="flex justify-between items-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 w-fit flex-wrap items-center">
            <div className="text-xl font-bold font-ptScanCap">Send for Approval</div>
          </div>
          <div className="text-sm font-normal bg-red">
            <Button
              size="xs"
              shape="circle"
              className="btn btn-xs btn-circle border-2 bg-white border-rasinBlack font-bold"
              onClick={toggleModal}
            >
              x
            </Button>
          </div>
        </div>
        <hr className="mt-3" />
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="text-center my-1">
            <p className="font-lato font-normal text-sm">{name}</p>
            <p className="font-lato font-normal text-sm">User ID : {sequenceId}</p>
          </div>
          <div className="border border-northTexasGreen bg-milkGreen rounded-xl p-4 m-5 grid grid-cols-1">
            <span className="text-sm font-normal font-lato mb-2">
              Select an Approver from the list.
            </span>
            <CustomSelect
              isDisabled={loading}
              isLoading={customLoading.users}
              options={userOptions}
              value={getValueForSelect(selectedUserId, userOptions)}
              onChange={(event) => {
                setSelectedUserId(event.value || null);
              }}
            />
            <div className="w-fit mt-3">
              <PrimaryButton
                text="Send for Approval"
                btnColor="bg-spaceCadetBlue80"
                btnHoverColor="hover:bg-spaceCadetBlue"
                color="white"
                icon={<SendApprove />}
                radius="2xl"
                textSize="base"
                disabled={loading || !selectedUserId}
                handleOnClick={() => handleSendForApproval(selectedUserId)}
                loading={loading}
              />
            </div>
          </div>
        </div>

        <div className="w-full py-8 bg-culturedGray border-t flex flex-wrap justify-center items-center gap-3">
          <PrimaryButton
            text="Add Contact"
            btnColor="bg-spaceCadetBlue80"
            color="white"
            radius="2xl"
            btnHoverColor="hover:bg-spaceCadetBlue"
            icon={<ViewContact />}
            disabled={loading}
            handleOnClick={() => navigate(`${PATHS.CONTACT.FORM.url}`)}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

SendForApprovalModal.propTypes = {
  show: PropTypes.bool,
  loading: PropTypes.bool,
  toggleModal: PropTypes.func,
  handleSendForApproval: PropTypes.func,
  formData: PropTypes.any,
};

export default SendForApprovalModal;
