import React from 'react';
import PropTypes from 'prop-types';

function SubMenuArrow(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color = '#475574', width = '20', height = '20' } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <g
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        clipPath="url(#clip0_2474_502)"
      >
        <path d="M12.5 9.167l-2.5 2.5-2.5-2.5" />
        <path d="M10 2.5a7.5 7.5 0 100 15 7.5 7.5 0 000-15z" />
      </g>
      <defs>
        <clipPath id="clip0_2474_502">
          <path fill="#fff" d="M0 0H20V20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

SubMenuArrow.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default SubMenuArrow;
