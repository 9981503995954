import React from 'react';
import PropTypes from 'prop-types';

function NotCondition() {
  return (
    <div className="not-condition flex">
      Run path:
      <span>NOT</span>
      <span className="badge bg-coralRed" />
    </div>
  );
}
export default NotCondition;
