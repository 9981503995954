import React from 'react';

import { IndexRouteObject, Navigate, RouteObject } from 'react-router-dom';

import LoginPage from 'views/Auth/Login';
import SignUpPage from 'views/Auth/SignUp';
import SetupPasswordPage from 'views/Auth/SetupPassword';
import ResetPasswordPage from 'views/Auth/ResetPassword';
import TempPasswordSetupPage from 'views/Auth/TempPasswordSetup';

import ClientAccountForm from 'views/ClientManagement/Form';
import ClientAccountListView from 'views/ClientManagement/List';
import UserAccountForm from 'views/UserManagement/Form';
import UserAccountListView from 'views/UserManagement/List';
import ContactForm from 'views/Contact/ContactForm';
import ContactList from 'views/Contact/List';
import CasesListView from 'views/CaseManagement/List';
import CasesForm from 'views/CaseManagement/Form';
import ChannelListView from 'views/UserManagement/Channels/List';
import ChannelForm from 'views/UserManagement/Channels/Form';
import BotChatListView from 'views/Inbox/BotChat/List';
import MessengerInstanceList from 'views/Settings/Messengers/List';
import ContactView from 'views/Contact/ContactView';
import ChatInbox from 'views/Inbox/Chat';
import MessengerInstanceForm from 'views/Settings/Messengers/Form';
import ChatView from 'views/CaseManagement/ChatView';
import CompletedChatList from 'views/Inbox/CompletedChats/List';

import RolePermissionList from 'views/UserManagement/RolePermission/List';
import RolePermissionForm from 'views/UserManagement/RolePermission/Form';
import IntentsPage from 'views/Settings/Messengers/Form/Intents';
import FlowBuilder from 'views/Settings/FlowBuilder';
import TestComponentPage from 'views/TestComponent';
import UserProfile from 'views/AccountManagement/Profile';
import { PATHS } from './paths';

interface UpdatedRouteObject extends IndexRouteObject {
  isProtected: boolean;
}

const publicRoutes: Array<UpdatedRouteObject> = [
  {
    path: PATHS.AUTH.LOGIN.url,
    element: <LoginPage />,
    isProtected: false,
    index: true,
  },
  {
    path: PATHS.AUTH.SIGN_UP.url,
    element: <SignUpPage />,
    isProtected: false,
    index: true,
  },
  {
    path: PATHS.AUTH.SETUP_PASSWORD.url,
    element: <SetupPasswordPage />,
    isProtected: false,
    index: true,
  },
  {
    path: PATHS.AUTH.RESET_PASSWORD.url,
    element: <ResetPasswordPage />,
    isProtected: false,
    index: true,
  },
  {
    path: PATHS.AUTH.TEMP_PASSWORD_SETUP.url,
    element: <TempPasswordSetupPage />,
    isProtected: false,
    index: true,
  },
];

// Routes
const authProtectedRoutes: Array<UpdatedRouteObject> = [
  // ...publicRoutes,
  {
    path: PATHS.INBOX.DEFAULT.url,
    element: <ChatInbox />,
    isProtected: true,
    index: true,
  },
  {
    path: PATHS.INBOX.DEFAULT.url2,
    element: <ChatInbox />,
    isProtected: true,
    index: true,
  },
  {
    path: '*',
    element: <Navigate to={PATHS.INBOX.DEFAULT.url} />,
    isProtected: true,
    index: true,
  },

  // Profile
  {
    path: PATHS.ACCOUNT_MANAGEMENT.PROFILE.url,
    element: <UserProfile />,
    isProtected: true,
    index: true,
  },

  // Clients
  {
    path: PATHS.CLIENT_MANAGEMENT.LIST.url,
    element: <ClientAccountListView />,
    isProtected: true,
    index: true,
  },
  {
    path: PATHS.CLIENT_MANAGEMENT.FORM.url,
    element: <ClientAccountForm />,
    isProtected: true,
    index: true,
  },
  // Users
  {
    path: PATHS.USER_MANAGEMENT.LIST.url,
    element: <UserAccountListView />,
    isProtected: true,
    index: true,
  },
  {
    path: PATHS.USER_MANAGEMENT.FORM.url,
    element: <UserAccountForm />,
    isProtected: true,
    index: true,
  },
  {
    path: PATHS.USER_MANAGEMENT.TEST.url,
    element: <TestComponentPage />,
    isProtected: true,
    index: true,
  },
  {
    path: PATHS.CONTACT.FORM.url,
    element: <ContactForm />,
    isProtected: true,
    index: true,
  },
  {
    path: PATHS.CONTACT.LIST.url,
    element: <ContactList />,
    isProtected: true,
    index: true,
  },
  {
    path: PATHS.CONTACT.FORM.url,
    element: <ContactForm />,
    isProtected: true,
    index: true,
  },
  {
    path: PATHS.CONTACT.VIEW.url,
    element: <ContactView />,
    isProtected: true,
    index: true,
  },
  {
    path: PATHS.USER_MANAGEMENT.ROLE.LIST.url,
    element: <RolePermissionList />,
    isProtected: true,
    index: true,
  },
  {
    path: PATHS.USER_MANAGEMENT.ROLE.FORM.url,
    element: <RolePermissionForm />,
    isProtected: true,
    index: true,
  },

  // Cases
  {
    path: PATHS.CASES_MANAGEMENT.LIST.url,
    element: <CasesListView />,
    isProtected: true,
    index: true,
  },
  {
    path: PATHS.CASES_MANAGEMENT.FORM.url,
    element: <CasesForm />,
    isProtected: true,
    index: true,
  },
  {
    path: PATHS.CASES_MANAGEMENT.VIEW.url,
    element: <ChatView />,
    isProtected: true,
    index: true,
  },

  // Channels
  {
    path: PATHS.USER_MANAGEMENT.CHANNEL.LIST.url,
    element: <ChannelListView />,
    isProtected: true,
    index: true,
  },
  {
    path: PATHS.USER_MANAGEMENT.CHANNEL.FORM.url,
    element: <ChannelForm />,
    isProtected: true,
    index: true,
  },

  // Bot Chat
  {
    path: PATHS.INBOX.BOT_CHAT.LIST.url,
    element: <BotChatListView />,
    isProtected: true,
    index: true,
  },

  // Completed Chats
  {
    path: PATHS.INBOX.COMPLETED_CHAT.LIST.url,
    element: <CompletedChatList />,
    isProtected: true,
    index: true,
  },

  // Messenger Instance
  {
    path: PATHS.MESSENGERS.LIST.url,
    element: <MessengerInstanceList />,
    isProtected: true,
    index: true,
  },
  {
    path: PATHS.MESSENGERS.FORM.url,
    element: <MessengerInstanceForm />,
    isProtected: true,
    index: true,
  },
  {
    path: PATHS.MESSENGERS.INTENTS.url,
    element: <IntentsPage />,
    isProtected: true,
    index: true,
  },
  // Flow Builder
  {
    path: PATHS.MESSENGERS.FLOW_BUILDER.url,
    element: <FlowBuilder />,
    isProtected: true,
    index: true,
  },
];

const allPublicRoutes = [
  {
    path: '/',
    element: <Navigate to={PATHS.AUTH.LOGIN.url} />,
    isProtected: false,
    index: true,
  },
  {
    path: '*',
    element: <Navigate to={PATHS.AUTH.LOGIN.url} />,
    isProtected: false,
    index: true,
  },
  ...publicRoutes,
];

export { authProtectedRoutes, allPublicRoutes };
