import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

function FormEditHeader(props) {
  const { formData } = props;

  return (
    <div className="client-account__userHeader flex items-center justify-between mb-4">
      <div className="flex items-center">
        <div className="client-account__details items-center">
          <div className="flex">
            <h2 className="text-spaceCadetBlue font-bold font-ptScanCap text-lg pb-2 srg:text-xl pr-5">
              View Channel
            </h2>
            <div className="flex">
              <div
                className={`badge ${
                  formData?.isActive ? 'bg-green-600' : 'bg-red-600'
                } text-white xs:w-20 font-medium p-3 text-center items-center text-xs border-slate-50`}
              >
                {String(formData?.status).toUpperCase()}
              </div>
            </div>
          </div>

          <div className="flex flex-wrap gap-x-4 items-center">
            <p className="text-spaceCadetBlue font-normal font-lato text-base rg:text-lg">
              Channel ID : {formData?.channelId}
            </p>
            <p className="text-spaceCadetBlue font-normal font-lato text-base rg:text-lg">
              Channel Name : {formData?.channelName}
            </p>
          </div>
          <div className="flex flex-wrap gap-x-4 items-center">
            <p className="text-spaceCadetBlue font-normal font-lato text-sm rg:text-base">
              Created At :{' '}
              {moment(formData?.createdAt, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD - hh:mm:ss A')}
            </p>
            <p className="text-spaceCadetBlue font-normal font-lato text-sm rg:text-base">
              Last Updated :{' '}
              {moment(formData?.updatedAt, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD - hh:mm:ss A')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

FormEditHeader.propTypes = {
  formData: PropTypes.any,
};

export default FormEditHeader;
