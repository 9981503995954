import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import {
  addNewAPIRequestMappingSetting,
  deleteAPIRequestMappingSetting,
  updateAPIRequestMappingSetting,
} from 'slices/flow';
import ResponseField from './ResponseField';
import { IMappingSetting } from '../interfaces';

interface IMappingSettingsProps {
  nodeId?: string;
  requestData?: {
    mappingSettings?: IMappingSetting[];
  };
  mappingSettingComponentVisible?: boolean;
  toggleCreateVariableModal?: () => void;
}

function MappingSettings(props: IMappingSettingsProps) {
  const { nodeId, requestData, mappingSettingComponentVisible, toggleCreateVariableModal } = props;

  const dispatch = useDispatch();
  /**
   * Handle add new mapping setting
   */
  const handleAddMappingSetting = () => {
    dispatch(addNewAPIRequestMappingSetting({ nodeId }));
  };

  /**
   * Delete mapping setting by Id
   * @param {string} mappingId - Setting id
   */
  const handleDeleteMappingSetting = (mappingId: string) => {
    dispatch(deleteAPIRequestMappingSetting({ nodeId, mappingId }));
  };

  /**
   * Handle update mapping settings
   * @param {string} mappingId - Mapping setting id
   * @param {Partial<IMappingSetting>} updatedMappingData - New mapping setting data
   */
  const handleUpdateMappingSetting = (
    mappingId: string,
    updatedMappingData: Partial<IMappingSetting>,
  ) => {
    dispatch(updateAPIRequestMappingSetting({ nodeId, mappingId, updatedMappingData }));
  };

  return (
    <div className="mapping-settings">
      <div className="heading__text heading__text--capitalized">MAPPING SETTINGS</div>

      {mappingSettingComponentVisible && (
        <>
          <div className="heading flex justify-between">
            <div className="heading__text">Response Field</div>
            <div className="doc inline-flex items-center">
              <button type="button" className="add-item__link" onClick={toggleCreateVariableModal}>
                Create variable
              </button>
            </div>
          </div>

          <div className="space-y-2">
            {requestData?.mappingSettings?.map((mappingSetting: IMappingSetting) => (
              <ResponseField
                key={mappingSetting._id}
                _id={mappingSetting._id}
                mapKey={mappingSetting.mapKey}
                variable={mappingSetting.variable}
                handleDeleteMappingSetting={handleDeleteMappingSetting}
                handleUpdateMappingSetting={handleUpdateMappingSetting}
              />
            ))}
          </div>
        </>
      )}

      <button type="button" className="add-component__btn btn" onClick={handleAddMappingSetting}>
        + Add
      </button>

      <p className="mapping-setting-text--muted">
        {'Use the request data in the following text block as JSONPath {{"$[\'someKey\']"}}'}
      </p>
    </div>
  );
}

MappingSettings.propTypes = {
  nodeId: PropTypes.string,
  requestData: PropTypes.object,
  mappingSettingComponentVisible: PropTypes.bool,
  toggleCreateVariableModal: PropTypes.func,
};

export default MappingSettings;
