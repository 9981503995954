import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import classNames from 'classnames';

function PrimaryButton(props) {
  const {
    id,
    className = '',
    text,
    btnColor,
    color,
    icon,
    width = 'w-auto',
    radius,
    btnHoverColor,
    textSize,
    handleOnClick,
    disabled,
    loading,
    type = 'submit',
    paddingX = '[20px]',
    paddingY = '[10px]',
  } = props;
  return (
    <motion.button
      id={id}
      onClick={handleOnClick}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.9 }}
      type={type}
      className={classNames(
        `px-${paddingX} py-${
          loading ? '0' : paddingY
        } btn ${className} ${btnColor} font-ptScanCap text-base text-${color} ${width} capitalize h-auto rounded-${radius} ${btnHoverColor} ${
          disabled ? 'btn-disabled' : ''
        }`,
        {
          'min-w-[120px]': loading,
        },
      )}
    >
      <div className="flex items-center gap-2.5">
        {!loading && icon}
        {loading ? (
          <span className="loading loading-infinity loading-lg" />
        ) : (
          <span className={`text-${textSize} ${disabled && 'text-white'}`}>{text}</span>
        )}
      </div>
    </motion.button>
  );
}

PrimaryButton.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string,
  btnColor: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.element,
  width: PropTypes.string,
  radius: PropTypes.string,
  btnHoverColor: PropTypes.string,
  textSize: PropTypes.string,
  paddingX: PropTypes.string,
  paddingY: PropTypes.string,
  disabled: PropTypes.bool,
  handleOnClick: PropTypes.func,
  loading: PropTypes.bool,
};

export default PrimaryButton;
