/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import PropTypes from 'prop-types';

import { SALUTATIONS } from 'constants/contact.const';
import { CustomSelect, InputText } from 'components';
import { reactSelectCustomStyles } from 'styles/select';
import VariableDropdown from '../../VariableDropdown';

import { contactsValueTypes, contactsValueWithFormFieldTypes } from './constants';
import { groupedOptions } from '../../constants';
import { IContactForm } from './interfaces';
import FormField from './components/FormField';

interface IAddContactFormProps {
  formData: IContactForm;
  useSelectedValueType: (valueType: string, array: any[], id: string) => any;
  useSelectedVariableValue: (valueType: string, array: any[], id: string) => any;
  handleInputChange: (event: any, section: string) => void;
  handleCheckChange: (event: any, section: string) => void;
  handleSelectChange: (event: any, section: string, id: string) => void;
  handleSelectVariableChange: (event: any, section: string, id: string) => void;
  isFormValid: boolean;
}
/**
 * This is contact form in flow builder drawer
 */
function AddContactForm(props: IAddContactFormProps) {
  const {
    formData,
    useSelectedValueType,
    useSelectedVariableValue,
    handleInputChange,
    handleCheckChange,
    handleSelectChange,
    handleSelectVariableChange,
    isFormValid,
  } = props;

  const handlers = {
    useSelectedValueType,
    useSelectedVariableValue,
    handleInputChange,
    handleCheckChange,
    handleSelectChange,
    handleSelectVariableChange,
  };

  return (
    <div>
      <form>
        <div className="flow-contact__form">
          <div className="flow-contact__form-headings">
            <div>
              <span>Form Field Name</span>
              <span>Value Type</span>
              <span>Value </span>
            </div>
          </div>
          {/* Salutation */}
          <FormField
            label="Salutation"
            id="salutation"
            isFieldEnabled={formData?.salutation?.isFieldEnabled}
            valueType={formData?.salutation?.valueType}
            value={formData?.salutation?.value}
            options={contactsValueWithFormFieldTypes.filter(
              (option) => option.value === 'variable' || option.value === 'formField',
            )}
            formData={formData}
            handlers={handlers}
            isFormValid={isFormValid}
            requiredField
          />

          {/* FirstName */}
          <FormField
            label="First Name"
            id="firstName"
            isFieldEnabled={formData?.firstName?.isFieldEnabled}
            valueType={formData?.firstName?.valueType}
            value={formData?.firstName?.value}
            options={contactsValueTypes}
            formData={formData}
            handlers={handlers}
            isFormValid={isFormValid}
            requiredField
          />
          {/* Last name */}
          <FormField
            label="Last Name"
            id="lastName"
            isFieldEnabled={formData?.lastName?.isFieldEnabled}
            valueType={formData?.lastName?.valueType}
            value={formData?.lastName?.value}
            options={contactsValueTypes}
            formData={formData}
            handlers={handlers}
            isFormValid={isFormValid}
            requiredField
          />
          {/* Email */}
          <FormField
            label="Email"
            id="email"
            isFieldEnabled={formData?.email?.isFieldEnabled}
            valueType={formData?.email?.valueType}
            value={formData?.email?.value}
            options={contactsValueTypes}
            formData={formData}
            handlers={handlers}
            isFormValid={isFormValid}
            requiredField
          />
          {/* Primary contact No */}
          <FormField
            label="Primary contact No"
            id="primaryContactNo"
            isFieldEnabled={formData?.primaryContactNo?.isFieldEnabled}
            valueType={formData?.primaryContactNo?.valueType}
            value={formData?.primaryContactNo?.value}
            options={contactsValueTypes}
            formData={formData}
            handlers={handlers}
            isFormValid={isFormValid}
            requiredField
          />
          {/* Secondary contact No */}
          <FormField
            label="Secondary contact No"
            id="secondaryContactNo"
            isFieldEnabled={formData?.secondaryContactNo?.isFieldEnabled}
            valueType={formData?.secondaryContactNo?.valueType}
            value={formData?.secondaryContactNo?.value}
            options={contactsValueTypes}
            formData={formData}
            handlers={handlers}
          />
          {/* Preferred Contact Method */}
          <FormField
            label="Preferred Contact Method"
            id="preferredContactMethod"
            isFieldEnabled={formData?.preferredContactMethod?.isFieldEnabled}
            valueType={formData?.preferredContactMethod?.valueType}
            value={formData?.preferredContactMethod?.value}
            options={[...contactsValueTypes, { label: 'Form Field', value: 'formField' }]}
            formData={formData}
            handlers={handlers}
            isFormValid={isFormValid}
            requiredField
          />
          {/* Gender */}
          <FormField
            label="Gender "
            id="gender"
            isFieldEnabled={formData?.gender?.isFieldEnabled}
            valueType={formData?.gender?.valueType}
            value={formData?.gender?.value}
            options={[...contactsValueTypes, { label: 'Form Field', value: 'formField' }]}
            formData={formData}
            handlers={handlers}
          />
          {/* Date of Birth */}
          <FormField
            label="Date of Birth"
            id="dateOfBirth"
            isFieldEnabled={formData?.dateOfBirth?.isFieldEnabled}
            valueType={formData?.dateOfBirth?.valueType}
            value={formData?.dateOfBirth?.value}
            options={contactsValueTypes}
            formData={formData}
            handlers={handlers}
          />
          {/* Designation  */}
          <FormField
            label="Designation "
            id="designation"
            isFieldEnabled={formData?.designation?.isFieldEnabled}
            valueType={formData?.designation?.valueType}
            value={formData?.designation?.value}
            options={contactsValueTypes}
            formData={formData}
            handlers={handlers}
          />
          {/* Country */}
          <FormField
            label="Country"
            id="country"
            isFieldEnabled={formData?.country?.isFieldEnabled}
            valueType={formData?.country?.valueType}
            value={formData?.country?.value}
            options={[...contactsValueTypes, { label: 'Form Field', value: 'formField' }]}
            formData={formData}
            handlers={handlers}
            isFormValid={isFormValid}
            requiredField
          />
          {/* Street Address */}
          <FormField
            label="Street Address"
            id="streetAddress"
            isFieldEnabled={formData?.streetAddress?.isFieldEnabled}
            valueType={formData?.streetAddress?.valueType}
            value={formData?.streetAddress?.value}
            options={contactsValueTypes}
            formData={formData}
            handlers={handlers}
          />
          {/* City */}
          <FormField
            label="City"
            id="city"
            isFieldEnabled={formData?.city?.isFieldEnabled}
            valueType={formData?.city?.valueType}
            value={formData?.city?.value}
            options={contactsValueTypes}
            formData={formData}
            handlers={handlers}
          />
          {/* State */}
          <FormField
            label="State"
            id="state"
            isFieldEnabled={formData?.state?.isFieldEnabled}
            valueType={formData?.state?.valueType}
            value={formData?.state?.value}
            options={contactsValueTypes}
            formData={formData}
            handlers={handlers}
          />
          {/* Postal Code */}
          <FormField
            label="Postal Code"
            id="postalCode"
            isFieldEnabled={formData?.postalCode?.isFieldEnabled}
            valueType={formData?.postalCode?.valueType}
            value={formData?.postalCode?.value}
            options={contactsValueTypes}
            formData={formData}
            handlers={handlers}
          />
        </div>
      </form>
    </div>
  );
}

AddContactForm.propTypes = {
  formData: PropTypes.any,
  useSelectedValueType: PropTypes.func,
  useSelectedVariableValue: PropTypes.func,
  handleInputChange: PropTypes.func,
  handleCheckChange: PropTypes.func,
  handleSelectChange: PropTypes.func,
  handleSelectVariableChange: PropTypes.func,
  isFormValid: PropTypes.bool,
  //   onSubmit: PropTypes.func,
};

export default AddContactForm;
