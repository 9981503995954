/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDebounce } from 'use-debounce';

import { apiErrorHandler } from 'api/handler';
import { fetchCompletedConversationsList } from 'services/conversation.service';
import { getChannelList } from 'services/channel.service';
import { fetchUserListData } from 'services/user.service';
import { getClientId } from 'utils/auth';
import { fetchContactListData } from 'services/contact.service';

import { tableDefaultValues } from './constants';
import { ICompletedChatListURLQueryParams } from './interface';
import ListPage from './page';
import { handleExportExcel, mapDocs } from './utils';

function CompletedChatList() {
  const location = useLocation();
  const navigate = useNavigate();

  const [searchByInput, setSearchByInput] = React.useState();
  const [debouncedSearchBy] = useDebounce(searchByInput, 500);

  const [urlSearchParams, setUrlSearchParams] = useState(null); //  URL search parameters
  const [customLoading, setCustomLoading] = useState({ list: false });

  const [triggerChild, setTriggerChild] = useState({ contact: null });

  const [listData, setListData] = useState(tableDefaultValues);

  const [channelsList, setChannelsList] = useState([]);
  const [agentsList, setAgentsList] = useState([]);
  const [contactsList, setContactsList] = useState([]);

  // Effect to initialize URL search parameters from location
  useEffect(() => {
    const urlSearch = Object.fromEntries(new URLSearchParams(location.search));
    if (urlSearch && urlSearch.page) {
      replaceUrlParamsHandler({ ...urlSearchParams, ...urlSearch });
    } else {
      replaceUrlParamsHandler({ page: 1, limit: 10 });
    }
    setUrlSearchParams(urlSearch);
  }, []);

  useEffect(() => {
    if (urlSearchParams) {
      getListData({ ...listData, ...urlSearchParams });
    }
  }, [urlSearchParams, triggerChild.contact]);

  useEffect(() => {
    if (debouncedSearchBy || debouncedSearchBy === '') {
      handleFilterBy(debouncedSearchBy, 'messages');
    }
  }, [debouncedSearchBy]);

  useEffect(() => {
    getChannelListData();
    getAgentsListData();
    getContactsListData();
  }, []);

  /** fetching the contacts, channels and agents for the filter dropdowns ***** */
  const getChannelListData = async () => {
    try {
      const { data: channelData } = await getChannelList({ limit: 0 });
      if (channelData?.node) {
        const mappedData = channelData?.node.docs.map((obj: { channelName: any; _id: any }) => ({
          label: obj?.channelName,
          value: obj?._id,
        }));
        setChannelsList(mappedData);
      } else {
        setChannelsList([]);
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    }
  };

  const getAgentsListData = async () => {
    try {
      const { data: userData } = await fetchUserListData({
        clientId: getClientId(),
      });
      if (userData) {
        const mappedData = userData?.docs.map((obj: { name: any; _id: any }) => ({
          label: obj?.name,
          value: obj?._id,
        }));
        setAgentsList(mappedData);
      } else {
        setAgentsList([]);
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    }
  };

  const getContactsListData = async () => {
    try {
      const { data } = await fetchContactListData({ limit: 0 });
      if (data) {
        const docs = (data?.docs || []).map((doc: { _id: any; firstName: any; lastName: any }) => ({
          value: doc?._id,
          label: `${doc?.firstName} ${doc?.lastName}`,
        }));
        setContactsList(docs);
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    }
  };
  /** ******************************************************************************* */

  const getListData = async (params: ICompletedChatListURLQueryParams) => {
    try {
      setCustomLoading((prevState) => ({ ...prevState, list: true }));
      const { data } = await fetchCompletedConversationsList(params);
      if (data?.node) {
        setListData({ ...listData, ...data.node, docs: mapDocs(data.node.docs) });
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, list: false }));
    }
  };

  /**
   * Function to replace URL search parameters
   * @param { ICompletedChatListURLQueryParams } params -  Url search query params
   */
  const replaceUrlParamsHandler = ({ page = '1', ...rest }: any) => {
    const queryParams = {
      page,
      ...rest,
    };
    const searchParams = new URLSearchParams(queryParams);
    setUrlSearchParams(queryParams);
    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  /**
   * Handle page change in pagination by replacing the url search params
   *
   * @param {number} pageNo - Page number user requesting to navigate
   */
  const handlePageChange = (pageNo: number) => {
    replaceUrlParamsHandler({
      ...urlSearchParams,
      page: pageNo,
    });
  };

  /**
   * A callback function that is triggered when the user changes the number of
   * results to display per page.
   * @param {number} limit - The new limit value (i.e. number of results per page).
   */
  const handleResultsPerPageChange = (limit: number) => {
    setListData((prevTableData) => ({ ...prevTableData, limit }));
    replaceUrlParamsHandler({
      page: listData.page,
      limit,
    });
  };

  /**
   * Handle filtering by adding and removing url params using value from the element and passed id
   * @param {string} value - Value that used to search or filter the field eg: doe@abc.com
   * @param {string} id - Field or parameter that need to use for filtering. eg: adminEmail
   */
  const handleFilterBy = (value: string, id: string) => {
    // create a copy of url search param
    const updatedSearchParams = {
      ...urlSearchParams,
    };
    // add new param
    if (value) {
      updatedSearchParams[id] = value;
    } else {
      delete updatedSearchParams[id]; // Remove the key if value is undefined
    }
    // update url
    replaceUrlParamsHandler(updatedSearchParams);
  };

  /**
   * Based on the passed column set url query params for column wise sorting
   * @param {boolean} isDescending - If true sorting is descending order else ascending order
   * @param {string} column - Which column need to be used
   */
  const handleColumnWiseSorting = (isDescending: any, column: any) => {
    replaceUrlParamsHandler({
      sort: column,
      order: isDescending ? 1 : -1,
    });
  };

  return (
    <ListPage
      listData={listData}
      handleFilterBy={handleFilterBy}
      handlePageChange={handlePageChange}
      handleResultsPerPageChange={handleResultsPerPageChange}
      urlSearchParams={urlSearchParams}
      setSearchByInput={setSearchByInput}
      searchByInput={searchByInput}
      handleColumnSorting={handleColumnWiseSorting}
      customLoading={customLoading}
      channelsList={channelsList}
      agentsList={agentsList}
      contactsList={contactsList}
      setTriggerChild={setTriggerChild}
      handleExport={() => handleExportExcel(listData.docs)}
    />
  );
}

export default CompletedChatList;
