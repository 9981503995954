import * as React from 'react';
import PropTypes from 'prop-types';

function Manage(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color, width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none" viewBox="0 0 24 24">
      <g
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        clipPath="url(#clip0_223_172)"
      >
        <path d="M3 21h4L20 8a2.829 2.829 0 00-4-4L3 17v4zM14.5 5.5l4 4" />
        <path d="M12 8L7 3 3 7l5 5M7 8L5.5 9.5M16 12l5 5-4 4-5-5M16 17l-1.5 1.5" />
      </g>
      <defs>
        <clipPath id="clip0_223_172">
          <path fill="#fff" d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
Manage.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Manage;
