export const defaultTableHeaders = [
  {
    key: 'sequenceId',
    value: 'Case ID',
  },
  {
    key: 'status',
    value: 'Status',
    type: 'statusLabel',
    params: {
      open: {
        label: 'OPEN',
        color: 'black',
        bgColor: 'bg-lotionLightGray',
        borderColor: 'bg-gainsboroBlue',
      },
      completed: {
        label: 'COMPLETED',
        color: 'black',
        bgColor: 'bg-lotionLightGray',
        borderColor: 'bg-gainsboroBlue',
      },
      deleted: {
        label: 'DELETED',
        color: 'black',
        bgColor: 'bg-lotionLightGray',
        borderColor: 'bg-gainsboroBlue',
      },
      closed: {
        label: 'CLOSED',
        color: 'black',
        bgColor: 'bg-lotionLightGray',
        borderColor: 'bg-gainsboroBlue',
      },
    },
  },
  {
    key: 'severity',
    value: 'Severity',
    type: 'isSeverity',
  },
  {
    key: 'category',
    value: 'Category',
  },
  {
    key: 'subject',
    value: 'Subject',
  },
  {
    key: 'contactId',
    value: 'Contact',
  },
  {
    key: 'createdAt',
    value: 'Created At',
    type: 'dateTime',
  },
  {
    key: 'updatedAt',
    value: 'Last Updated',
    type: 'dateTime',
  },
  {
    key: 'manage',
    value: 'Manage',
    type: 'manageBtn',
  },
];

export const tableDefaultValues = {
  totalDocs: 0,
  totalPages: 0,
  limit: 10,
  page: 1,
  headers: defaultTableHeaders,
  docs: [],
  sort: null,
  order: -1,
  searchBy: null,
};

// create an array of options for sort by select
export const orderByOptions = defaultTableHeaders.map((header) => ({
  value: header.key,
  label: header.value,
}));

export const excelHeaders = [
  {
    key: 'sequenceId',
    value: 'Case ID',
  },
  {
    key: 'status',
    value: 'Status',
    type: 'statusLabel',
    params: {
      open: {
        label: 'OPEN',
        color: 'white',
        bgColor: 'bg-northTexasGreen',
        borderColor: 'none',
      },
      completed: {
        label: 'COMPLETED',
        color: 'white',
        bgColor: 'bg-northTexasGreen',
        borderColor: 'none',
      },
    },
  },
  {
    key: 'severity',
    value: 'Severity',
  },
  {
    key: 'category',
    value: 'Category',
  },
  {
    key: 'subject',
    value: 'Subject',
  },
  {
    key: 'contactId',
    value: 'Contact',
  },
  {
    key: 'createdAt',
    value: 'Created At',
    type: 'dateTime',
  },
  {
    key: 'updatedAt',
    value: 'Last Updated',
    type: 'dateTime',
  },
];
