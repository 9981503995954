import { UserType } from 'constants/user.const';
import * as yup from 'yup';

const defaultAccountFormValidationSchema = {
  userType: yup.string().required('Please select user type'),
  name: yup.string().required('Please enter user name'),
  email: yup.string().email('Please enter valid email').required('Please enter email'),
  primaryContactNo: yup.string().required('Please enter contact number'),
  roles: yup
    .array()
    .min(1, 'Please select at least one user role')
    .required('Please select user role'),
  gender: yup.string().required('Please select a gender'),
  enableNickName: yup.boolean().notRequired(),
};

// Validations
export const userAccountFormValidationSchema = yup.object(defaultAccountFormValidationSchema);

// Create a function to generate the validation schema
export const getUserAccountValidationSchema = (formData: any) => {
  // Define the initial validation schema
  let validationSchema: any = defaultAccountFormValidationSchema;

  // Conditionally add clientId validation when userType is 'clientUser'
  if (formData.userType === UserType.ClientUser) {
    validationSchema = {
      ...validationSchema,
      clientId: yup.string().required('Please select client'),
    };
  }
  // Conditionally add nickname validation when enableNickName is true
  if (formData.enableNickName) {
    validationSchema = {
      ...validationSchema,
      nickname: yup.string().required('Nickname is required'),
    };
  }

  const schema = yup.object(validationSchema);

  return schema;
};
