/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-simple-toasts';

import { PromptModal } from 'components';

import { getChannelList } from 'services/channel.service';
import {
  buildInstanceBot,
  createMessengerInstance,
  getInstanceBotLocaleStatus,
  getMessengerInstanceById,
  toggleInstanceStatus,
  updateMessengerInstance,
} from 'services/messenger.service';

import { apiErrorHandler } from 'api/handler';
import { updateURLWithSearchParams } from 'utils/helpers';

import useMessengerInstanceReadyEvent from 'hooks/useMessengerInstanceReadyEvent';
import { getUserRunningIntervals } from 'slices/user';
import { RootState } from 'slices';

import { ToastTheme } from 'enums/toast.enum';
import { SocketEvents } from 'enums/socket.enum';
import { BotLocaleStatus } from 'enums/aws/lex.enum';
import FormPage from './page';

import { getFormValidationSchema } from './validations';
import { PromptTypes, defaultFormData, promptDefaultOptions } from './constants';

function MessengersForm() {
  const [searchParams] = useSearchParams(); // read url params

  const dispatch = useDispatch();
  const userSlice = useSelector((state: RootState) => state.user);

  const { event: messengerInstanceEvent } = useMessengerInstanceReadyEvent();

  const [formData, setFormData] = useState<any>(defaultFormData);
  const [channelsList, setChannelsList] = useState([]);
  const [botLocaleBuildStatus, setBotLocaleBuildStatus] = useState(null);

  const [promptOptions, setPromptOptions] = useState(promptDefaultOptions);
  const [editMode, setEditMode] = useState(false);
  const [customLoading, setCustomLoading] = useState({
    formSubmit: false,
    activateAccount: false,
    toggleInstance: false,
    fetchForm: false,
    buildingBot: false,
  });

  const [triggerChild, setTriggerChild] = useState({ transactions: null, linkedChannels: null });
  const pageType = searchParams.get('md');
  const pageId = searchParams.get('i');

  const { register, handleSubmit, formState, control } = useForm<any>({
    resolver: useMemo(() => yupResolver(getFormValidationSchema(formData)), [formData]),
    values: useMemo(() => formData, [customLoading.fetchForm]),
  });

  const { errors } = formState;

  useEffect(() => {
    getChannelListData();
  }, []);

  useEffect(() => {
    if (!pageType) {
      updateURLWithSearchParams({ md: 'new' });
    }
    if (pageType && pageType === 'edit' && pageId) {
      setEditMode(true);
      fetchMessengerInstance(pageId);
      updateURLWithSearchParams({ md: 'edit', i: pageId });
    }
  }, []);

  /** Side Effect - when user still in the same page when the messenger instance get ready
   * This socket event gets triggered and show the build bot button,
   * and as well triggers the checkBotStatus function
   */
  useEffect(() => {
    if (
      messengerInstanceEvent?.data &&
      messengerInstanceEvent?.type === SocketEvents.MessengerInstanceStatus &&
      messengerInstanceEvent?.data?.messengerId === formData?._id
    ) {
      setFormData({ ...formData, isReady: true });
      checkBotStatus();
    }
  }, [messengerInstanceEvent]);

  useEffect(() => {
    if (!botLocaleBuildStatus && pageId && formData?.instanceType === 'Chatbot') {
      checkBotStatus();
    }
  }, [pageId, formData?.instanceType]);

  /**
   * Check and monitor the status of the bot locale.
   * and if the bot in the building status, this function continually trigger the api call
   * and get and update the latest state of the bot, until it gets build or failed
   * @async
   * @function
   * @returns {void}
   */
  const checkBotStatus = async () => {
    // not using in direct messaging bots
    if (formData?.instanceType !== 'Chatbot') {
      return;
    }

    // if already a interval running on the system, clear it
    if (userSlice?.intervalId) {
      clearInterval(userSlice?.intervalId);
      // eslint-disable-next-line no-console
      console.log('existing interval cleared', userSlice?.intervalId);
    }

    try {
      const { data }: any = await getInstanceBotLocaleStatus(pageId || formData?._id);
      const botLocalStatus = data.node?.bot?.botLocaleStatus;

      setBotLocaleBuildStatus(botLocalStatus || null);
      setCustomLoading((prevState) => ({ ...prevState, buildingBot: true }));

      if (
        botLocalStatus &&
        botLocalStatus !== BotLocaleStatus.Built &&
        botLocalStatus !== BotLocaleStatus.Failed &&
        botLocalStatus !== BotLocaleStatus.NotBuilt
      ) {
        const intervalId = setInterval(async () => {
          const { data: botStatus }: any = await getInstanceBotLocaleStatus(
            pageId || formData?._id,
          );
          const newBotLocalStatus = botStatus.node?.bot?.botLocaleStatus;
          setBotLocaleBuildStatus(newBotLocalStatus);
          // eslint-disable-next-line no-console
          console.log('interval started', intervalId);

          // once start the interval store the intervalId in the redux state
          dispatch(getUserRunningIntervals(intervalId));

          if (
            newBotLocalStatus === BotLocaleStatus.Built ||
            newBotLocalStatus === BotLocaleStatus.Failed ||
            newBotLocalStatus === BotLocaleStatus.NotBuilt
          ) {
            clearInterval(intervalId);
            setCustomLoading((prevState) => ({ ...prevState, buildingBot: false }));
            // eslint-disable-next-line no-console
            console.log('interval cleared', intervalId);
          }
        }, 5000); // Repeat every 5 seconds
      } else {
        setCustomLoading((prevState) => ({ ...prevState, buildingBot: false }));
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast(exception, { theme: ToastTheme.failure });
      setCustomLoading((prevState) => ({ ...prevState, buildingBot: false }));
    }
  };

  /** fetching the contacts, channels and agents for the filter dropdowns ***** */
  const getChannelListData = async () => {
    try {
      const { data } = await getChannelList({ limit: 0, status: 'active' });
      if (data.node?.docs) {
        const mappedData = data.node.docs.map((obj: { channelName: any; _id: any }) => ({
          label: obj.channelName,
          value: obj._id,
        }));
        setChannelsList(mappedData);
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast(exception, { theme: ToastTheme.failure });
    }
  };

  async function fetchMessengerInstance(id: string) {
    try {
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: true, fetchForm: true }));
      const { data } = await getMessengerInstanceById(id);
      if (data?.node) {
        const messengerInstance = data.node;
        const { messengerSetting } = messengerInstance;
        const mergedData = {
          ...messengerSetting,
          ...messengerInstance,
          attachments: messengerSetting.attachments || {},
        };
        setFormData((prevState: any) => ({ ...prevState, ...mergedData }));
      }
    } catch (e) {
      const { message: exception } = apiErrorHandler(e);
      toast(exception, { theme: ToastTheme.failure });
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: false, fetchForm: false }));
    }
  }

  // Handles the form submission
  const onSubmit = async () => {
    const toastTask = toast(`${editMode ? 'Updating' : 'Creating'} Instance ...`, {
      loading: true,
    });
    try {
      const payload = formData;
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: true }));
      if (!editMode) {
        const { data } = await createMessengerInstance(payload);
        if (data && data.node) {
          const { messenger, accessKey } = data.node;
          delete messenger.attachments;
          setFormData((prevState: any) => ({ ...prevState, ...messenger }));
          setEditMode(true);
          setTriggerChild((prevState) => ({
            ...prevState,
            transactions: uuid(),
            linkedChannels: uuid(),
          }));
          updateURLWithSearchParams({ md: 'edit', i: messenger._id });
          toastTask.update({
            message: 'Successfully Created Messenger Instance',
            duration: 5000,
            theme: ToastTheme.success,
            loading: false,
          });
        }
      } else {
        const { data } = await updateMessengerInstance(formData._id, payload);
        if (data) {
          if (data?.node) {
            const { messenger } = data.node;
            delete messenger.attachments;
            setFormData((prevState: any) => ({ ...prevState, ...messenger }));
            setTriggerChild((prevState) => ({
              ...prevState,
              transactions: uuid(),
              linkedChannels: uuid(),
            }));
            toastTask.update({
              message: 'Successfully Updated Messenger Instance',
              duration: 5000,
              theme: ToastTheme.success,
              loading: false,
            });
          }
        }
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toastTask.update({
        message: exception,
        duration: 5000,
        theme: 'failure',
        loading: false,
      });
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: false }));
    }
  };

  const handleToggleInstanceStatus = async () => {
    const newStatus = !formData.isActive;
    const toastTask = toast(`${newStatus ? 'Enabling' : 'Disabling'} Instance ...`, {
      loading: true,
    });
    try {
      setCustomLoading((prevState) => ({ ...prevState, toggleInstance: true }));
      const { _id } = formData;
      const { data } = await toggleInstanceStatus(_id, { status: newStatus });
      if (data?.node) {
        setFormData((prevState: any) => ({
          ...prevState,
          isActive: newStatus,
        }));
        setPromptOptions(promptDefaultOptions);
        setTriggerChild((prevState) => ({ ...prevState, transactions: uuid() }));
        toastTask.update({
          message: `Successfully ${newStatus ? 'Enabled' : 'Disabled'} Instance`,
          duration: 5000,
          theme: ToastTheme.success,
          loading: false,
        });
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toastTask.update({
        message: exception,
        duration: 5000,
        theme: 'failure',
        loading: false,
      });
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, toggleInstance: false }));
    }
  };

  const handleBuildInstanceBot = async () => {
    setCustomLoading((prevState) => ({ ...prevState, buildingBot: true }));
    try {
      setBotLocaleBuildStatus(BotLocaleStatus.Building);
      await buildInstanceBot(formData._id);
      checkBotStatus();
      setFormData((prevState) => ({ ...prevState, buildPending: false }));
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast(exception, { theme: ToastTheme.failure });
      setBotLocaleBuildStatus(BotLocaleStatus.Failed);
    }
    setCustomLoading((prevState) => ({ ...prevState, buildingBot: false }));
  };

  // handle the input text change
  const handleInputChange = (event: { target: { id: any; value: any } }) => {
    const { id, value } = event.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  // handle the input text change
  const handleInputNumberChange = (event: { target: { id: any; value: any } }) => {
    const { id } = event.target;
    let { value } = event.target;
    if (value === '') {
      value = 0;
    }
    setFormData({
      ...formData,
      [id]: value ? Number(value) : 0,
    });
  };

  // handle the checkbox
  const handleCheckChange = (event) => {
    const { id, checked } = event.target;
    setFormData({
      ...formData,
      [id]: checked,
    });
  };

  // handle select field change
  const handleSelectChange = (event: { value: any }, id: any) => {
    setFormData({
      ...formData,
      [id]: event?.value,
    });
  };

  // handle the checkbox
  const handleChatbotTypeChangeCheck = (event: { target: { id: any; checked: any } }) => {
    const { id, checked } = event.target;
    const method = id.split('.')[1];
    setFormData({
      ...formData,
      [method]: checked,
    });
  };

  const handleOnToggleInstanceStatus = () => {
    setPromptOptions({
      ...promptOptions,
      show: true,
      title: `${formData.isActive ? 'Disable' : 'Enable'} Messenger Instance ${
        formData.sequenceId
      }`,
      message: `Do you want to ${
        formData.isActive ? 'disable' : 'enable'
      } this messenger instance?`,
      onConfirm: () => handleToggleInstanceStatus(),
      type: PromptTypes.ToggleInstance,
      data: null,
    });
  };

  // used to get updated state for loading
  function loadDynamicLoadingProp() {
    if (promptOptions.type === PromptTypes.ToggleInstance) {
      return customLoading.toggleInstance;
    }
    return false;
  }

  return (
    <>
      <FormPage
        onSubmit={onSubmit}
        errors={errors}
        formData={formData}
        handleInputChange={handleInputChange}
        handleSelectChange={handleSelectChange}
        handleSubmit={handleSubmit}
        register={register}
        editMode={editMode}
        customLoading={customLoading}
        control={control}
        handleOnToggleInstanceStatus={handleOnToggleInstanceStatus}
        triggerChild={triggerChild}
        handleChatbotTypeChangeCheck={handleChatbotTypeChangeCheck}
        channelsList={channelsList}
        handleInputNumberChange={handleInputNumberChange}
        handleCheckChange={handleCheckChange}
        setFormData={setFormData}
        handleBuildInstanceBot={handleBuildInstanceBot}
        status={botLocaleBuildStatus}
      />

      {promptOptions.show && (
        <PromptModal
          onCancel={() => {
            setPromptOptions({ ...promptOptions, show: false });
          }}
          {...promptOptions}
          loading={loadDynamicLoadingProp()}
        />
      )}
    </>
  );
}

export default MessengersForm;
