import * as yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';

// Validations
export const clientAccountFormValidationSchema = yup.object({
  companyName: yup.string().required('Please enter Company name'),
  adminEmail: yup.string().email('Please enter valid email').required('Please enter email'),
  primaryContactName: yup.string().required('Please enter Contact name'),
  primaryContactNo: yup
    .string()
    .required('Please enter contact number')
    .test('invalid-primary-contact-no', 'Invalid contact number', (value) => {
      const isValid = isValidPhoneNumber(value);
      return isValid;
    }),
  secondaryContactNo: yup
    .string()
    .nullable()
    .notRequired()
    .test('invalid-primary-contact-no', 'Invalid contact number', (value) => {
      if (!value) return true; // Field is not required, so an empty value is valid
      const isValid = isValidPhoneNumber(value);
      return isValid;
    }),
  country: yup.string().required('Please select country'),
  streetAddress: yup.string().required('Please enter Street Address'),
  stateProvinceRegion: yup.string().required('Please enter State'),
  city: yup.string().required('Please enter City'),
  postalCodeZipCode: yup.string().required('Please enter Postal code'),
});
