import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { AddClient, Export, Search } from 'components/Icons';
import { CustomSelect, CustomTable, PrimaryButton } from 'components';
import { USER_STATUS, USER_TYPES } from 'constants/user.const';
import { selectCustomStylesForFilters } from 'styles/select';
import { PATHS } from 'routes/paths';

import { toast } from 'react-toastify';
import { getUserType } from 'utils/auth';
import { getUserStatusSelected, getUserTypeSelected } from './utils';

function ListPage(props) {
  const {
    userListData,
    clientsOptions,
    roles,
    handleFilterBy,
    handlePageChange,
    handleResultsPerPageChange,
    urlSearchParams,
    handleColumnSorting,
    getClientSelected,
    customLoading,
    setSearchByInput,
    searchByInput,
    handleExport,
    handleEditTableRow,
    userType,
  } = props;

  return (
    <div className="page">
      {/* Add Client Button */}
      <div className="mb-2 flex flex-row justify-between items-center">
        <Link to={PATHS.USER_MANAGEMENT.FORM.url}>
          <PrimaryButton
            text="Add User"
            btnColor="bg-spaceCadetBlue80"
            color="white"
            radius="2xl"
            btnHoverColor="hover:bg-spaceCadetBlue"
            icon={<AddClient />}
          />
        </Link>

        {/* Export Button */}
        <div className="">
          <PrimaryButton
            text="Export"
            btnColor="bg-spaceCadetBlue80"
            color="white"
            radius="2xl"
            btnHoverColor="hover:bg-spaceCadetBlue"
            icon={<Export />}
            disabled={customLoading.list}
            handleOnClick={handleExport}
          />
        </div>
      </div>
      <hr />

      {/* Filter and Export Section */}
      <div className="container mt-4 py-4">
        <form className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          {/* Row 1 */}
          <div className="mb-0">
            <div className="relative block sm:flex items-center mb-2 sm:mb-0">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2 mt-1">
                <svg className="h-5 w-5 mb-2 fill-slate-300" viewBox="0 0 20 20">
                  <Search color="#475574" />
                </svg>
              </span>
              <input
                className="sm:placeholder:text-sm placeholder:text-independenceBlue font-lato text-base block bg-white border border-gainsboroBlue rounded-lg py-2 pl-9 pr-3 h-11 shadow-sm focus:border-[#2684ff] focus:outline-none sm:text-sm w-full rounded-xl"
                placeholder="Search by Email"
                type="text"
                name="search"
                onChange={(event) => setSearchByInput(event.target.value)}
                value={searchByInput || ''}
              />
            </div>
          </div>
          <div className="mb-2">
            <CustomSelect
              placeholder="Select Client"
              customStyles={selectCustomStylesForFilters}
              options={clientsOptions}
              value={getClientSelected(urlSearchParams?.clientId)}
              isClearable
              onChange={(selected) => {
                const status = selected?.value;
                handleFilterBy(status, 'clientId');
              }}
            />
          </div>
          {userType && (
            <div className="mb-2">
              <CustomSelect
                placeholder="User Type"
                customStyles={selectCustomStylesForFilters}
                options={USER_TYPES}
                value={getUserTypeSelected(urlSearchParams?.userType)}
                isClearable
                onChange={(selected) => {
                  const status = selected?.value;
                  handleFilterBy(status, 'userType');
                }}
              />
            </div>
          )}
          <div className="mb-2">
            <CustomSelect
              placeholder="User Role"
              customStyles={selectCustomStylesForFilters}
              options={
                getUserType() === 'systemManagementUser' &&
                urlSearchParams?.clientId === undefined &&
                urlSearchParams?.userType === 'clientUser'
                  ? undefined
                  : roles
              }
              value={getClientSelected(urlSearchParams?.roleId)}
              isClearable
              onChange={(selected) => {
                const status = selected?.value;
                handleFilterBy(status, 'roleId');
              }}
              isLoading={customLoading.roles}
              onMenuOpen={() => {
                if (
                  getUserType() === 'systemManagementUser' &&
                  urlSearchParams.clientId === undefined &&
                  urlSearchParams.userType === 'clientUser'
                ) {
                  toast.error('Select a Client to view the client roles');
                }
              }}
            />
          </div>
          <div className="mb-2">
            <CustomSelect
              placeholder="User Status"
              customStyles={selectCustomStylesForFilters}
              options={USER_STATUS}
              value={getUserStatusSelected(urlSearchParams?.status)}
              isClearable
              onChange={(selected) => {
                const status = selected?.value;
                handleFilterBy(status, 'status');
              }}
            />
          </div>
        </form>
      </div>
      <CustomTable
        tableData={userListData}
        onPageChange={handlePageChange}
        handleResultsPerPageChange={handleResultsPerPageChange}
        handlers={{ onManage: handleEditTableRow }}
        isPaginate
        onSort={handleColumnSorting}
        loading={{ list: customLoading.list }}
        handleEdit={handleEditTableRow}
      />
    </div>
  );
}

ListPage.propTypes = {
  handleFilterBy: PropTypes.func,
  handlePageChange: PropTypes.func,
  handleColumnSorting: PropTypes.func,
  handleResultsPerPageChange: PropTypes.func,
  getClientSelected: PropTypes.func,
  setSearchByInput: PropTypes.func,
  handleEditTableRow: PropTypes.func,
  handleExport: PropTypes.func,
  searchByInput: PropTypes.string,
  urlSearchParams: PropTypes.shape({
    page: PropTypes.any,
    country: PropTypes.string,
    sort: PropTypes.string,
    status: PropTypes.string,
    searchBy: PropTypes.string,
    clientId: PropTypes.string,
    roleId: PropTypes.string,
    userType: PropTypes.string,
  }),
  userListData: PropTypes.shape({
    page: PropTypes.number,
  }),
  clientsOptions: PropTypes.array,
  customLoading: PropTypes.any,
  roles: PropTypes.any,
  userType: PropTypes.bool,
};

export default ListPage;
