import React from 'react';
import PropTypes from 'prop-types';

function SubMenuItem(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color = '#475574', width = '20', height = '20' } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <g
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        clipPath="url(#clip0_2325_2030)"
      >
        <path d="M7.333 6l2 2-2 2" />
        <path d="M2 8a6 6 0 1012 0A6 6 0 002 8z" />
      </g>
      <defs>
        <clipPath id="clip0_2325_2030">
          <path fill="#fff" d="M0 0H16V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

SubMenuItem.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default SubMenuItem;
