import _ from 'lodash';
import instance from 'api';
import { CASE } from 'api/apiEndpoints';
import UrlBuilder from 'api/urlQueryBuilder';
import { IContactViewURLQueryParams } from 'views/Contact/ContactView/interface';
import { ICasesListURLQueryParams } from 'views/CaseManagement/List/interface';
import { AxiosResponse } from 'axios';

/**
 * Get cases by contact id
 * @param {string} - Contact Id
 * @param {IContactViewURLQueryParams} params - URL query params for contact view
 * @returns case details
 */
export function getCaseListByContactId(id: string, params: IContactViewURLQueryParams) {
  const {
    page = 1,
    limit = 10,
    sort = 'createdAt',
    order = -1,
    status,
    searchBy,
    select,
    caseId,
    subject,
  } = params;

  // creates a new object with only the non-null, non-undefined values from the extracted variables
  const queryParams = _.pickBy({
    page,
    limit,
    sort,
    order,
    status,
    searchBy,
    select,
    caseId,
    subject,
  });

  const urlBuilder = UrlBuilder();

  const url = urlBuilder
    .setUrl(CASE.GET_CASE_LIST_BY_CONTACT_ID)
    .setPathParams({ id })
    .setQueryParams({ ...queryParams })
    .build();
  return instance.get(url);
}

/**
 * Fetch paginated and filtered case list data
 * @param {ICasesListURLQueryParams} params - Url query params for case list data)
 * @returns {} - Case list data and pagination data
 */
export const fetchCaseListData = (
  params: ICasesListURLQueryParams,
): Promise<AxiosResponse<any, any>> => {
  const {
    page = 1,
    limit,
    sort = 'createdAt',
    order = -1,
    status,
    searchBy,
    severity,
    category,
    lastUpdatedAt,
    contact,
    select,
    tags,
  } = params;
  // creates a new object with only the non-null, non-undefined values from the extracted variables
  const queryParams = _.pickBy({
    page,
    limit,
    sort,
    order,
    status,
    severity,
    category,
    lastUpdatedAt,
    contact,
    searchBy,
    select,
    tags,
  });
  const url = UrlBuilder()
    .setUrl(CASE.GET_CASES_ALL)
    .setQueryParams({ ...queryParams })
    .build();
  return instance.get(url);
};

/**
 * Create Case
 *
 * @param data - form data
 * @returns case details
 */
export function createCase(data) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(CASE.CREATE_CASE).build();
  return instance.post(url, data);
}

/**
 * Get case by Id
 *@param id - case id
 * @returns case details
 */
export function getCaseById(id: string) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(CASE.GET_CASES_BY_ID).setPathParams({ id }).build();
  return instance.get(url);
}

/**
 * Update case
 *@param id - case id
 * @param data - form data
 * @returns case details
 */
export function updateCase(data: any) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(CASE.UPDATE_CASE).setPathParams({ id: data._id }).build();
  return instance.patch(url, data);
}

export function linkUnlinkConversationService(id: string, data: any) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(CASE.LINK_UNLINK_CONVERSATION).setPathParams({ id }).build();
  return instance.post(url, data);
}
