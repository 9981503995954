import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-daisyui';
import { useForm, useController } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { CustomSelect, PrimaryButton } from 'components';
import { Save } from 'components/Icons';
import { getDefaultValueForSelect, getValueForSelect } from 'utils/helpers';
import { CollectCustomerFeedbackVia } from 'enums/messenger.enum';
import TextInput from 'components/Form/InputText';
import { customerFeedbackSettingsSchema } from '../validations/index';

function CustomerFeedbackSettingModal(props) {
  const { show, toggleModal, handleUpdate, setFormData, formData } = props;

  const [disabled, setDisabled] = useState(true);
  // form loading state
  const [customLoading, setCustomLoading] = useState({
    formSubmit: false,
  });

  const { register, handleSubmit, formState, control } = useForm({
    resolver: yupResolver(customerFeedbackSettingsSchema),
    values: useMemo(() => {
      const defaultValues = {
        ...formData,
      };
      return defaultValues;
    }, [formData]),
  });

  const { errors } = formState;

  const { field: collectFeedbackViaOptionField } = useController({
    name: 'collectCustomerFeedbackVia',
    control,
  });

  const {
    value: selectedCollectFeedbackViaOption,
    onChange: onCollectFeedbackViaOptionChange,
    ...restProps
  } = collectFeedbackViaOptionField;

  const collectFeedbackViaOptions = [
    { label: 'Form', value: CollectCustomerFeedbackVia.Form },
    { label: 'Conversation', value: CollectCustomerFeedbackVia.Conversation },
  ];

  useEffect(() => {
    setDisabled(!formData?.enableCustomerFeedbackCollection);
  }, []);

  const selectedCollectCustomerFeedbackViaOption = useMemo(
    () =>
      getDefaultValueForSelect(
        formData?.collectCustomerFeedbackVia,
        collectFeedbackViaOptions,
        'value',
      ),
    [formData?.collectCustomerFeedbackVia],
  );

  // handle the dropdown change
  const handleSelectChange = (event: { value: any; other: any }, id: string) => {
    if (id === 'collectCustomerFeedbackVia') {
      onCollectFeedbackViaOptionChange(event?.value);
    }
    setFormData({
      ...formData,
      [id]: event?.value || null,
    });
  };

  // handle the input field change
  const handleInputChange = (event: any, id: string) => {
    setFormData({
      ...formData,
      [id]: event?.target?.value || null,
    });
  };

  // handle the checkbox
  const handleCheckChange = (event) => {
    setDisabled((prevState) => !prevState);
    const { id, checked } = event.target;

    setFormData({
      ...formData,
      [id]: checked,
    });
  };

  const onSubmit = () => {
    setCustomLoading((prevState) => ({ ...prevState, formSubmit: true }));
    handleUpdate();
    setCustomLoading((prevState) => ({ ...prevState, formSubmit: false }));
    toggleModal();
  };

  return (
    <Modal
      open={show}
      className="top-0 left-0 right-0 p-0 border border-gainsboroBlue rounded-lg z-50 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(auto-1rem)] max-w-2xl max-h-full"
    >
      <Modal.Header className="font-bold pb-2 border-b p-4">
        <div className="flex justify-between items-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 w-fit flex-wrap items-center">
            <div className="text-base font-bold font-ptScanCap whitespace-nowrap">
              Customer Feedback Collection Settings
            </div>
          </div>
          <div className="text-sm font-normal bg-red">
            <Button
              size="xs"
              shape="circle"
              className="btn btn-xs btn-circle border-2 bg-white border-rasinBlack font-bold"
              onClick={toggleModal}
            >
              x
            </Button>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="px-2 justify-center items-center pb-3 -mt-6">
          <div className="p-4 flex flex-row items-center gap-5">
            <input
              id="enableCustomerFeedbackCollection"
              {...register('enableCustomerFeedbackCollection')}
              type="checkbox"
              checked={!disabled}
              className="checkbox"
              onChange={handleCheckChange}
            />
            <span className="text-[16px] font-semibold font-lato ">
              Enable Customer Feedback Collection
            </span>
            <br />
          </div>
          <div className="px-4 flex flex-col gap-4">
            <span className="text-lg font-normal font-lato">Collect the customer feedback as </span>
            <CustomSelect
              id="collectCustomerFeedbackVia"
              required
              isClearable
              placeholder=""
              options={collectFeedbackViaOptions}
              error={errors?.collectCustomerFeedbackVia}
              value={selectedCollectCustomerFeedbackViaOption}
              onChange={(event) => handleSelectChange(event, 'collectCustomerFeedbackVia')}
              isDisabled={disabled}
              {...restProps}
            />
          </div>

          <div className="px-4 flex flex-col gap-4 mt-2">
            <span className="text-lg font-normal font-lato">
              Expire the customer feedback collection if not received a feedback (in minutes){' '}
            </span>

            <TextInput
              elementId="customerFeedbackAutoExpireInMin"
              register={register}
              error={errors?.customerFeedbackAutoExpireInMin}
              type="text"
              placeholder=""
              className="input input-bordered focus:outline-none"
              value={formData?.messengerSetting?.customerFeedbackAutoExpireInMin || ''}
              onChange={(event) => handleInputChange(event, 'customerFeedbackAutoExpireInMin')}
              disabled={disabled}
            />
          </div>
        </div>
        <div className="w-full pt-5 pb-5 mt-3 bg-culturedGray flex flex-wrap justify-center items-center gap-3">
          <PrimaryButton
            text="Save"
            btnColor="bg-spaceCadetBlue80"
            btnHoverColor="hover:bg-spaceCadetBlue"
            color="white"
            icon={<Save />}
            radius="2xl"
            type="button"
            handleOnClick={handleSubmit(onSubmit)}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

CustomerFeedbackSettingModal.propTypes = {
  show: PropTypes.bool,
  toggleModal: PropTypes.func,
  setFormData: PropTypes.func,
  handleUpdate: PropTypes.func,
  formData: PropTypes.object,
};

export default CustomerFeedbackSettingModal;
