import React, { useCallback, useState } from 'react';
import { Button, Modal } from 'react-daisyui';
import PropTypes from 'prop-types';
import ReactDom from 'react-dom';
import { uniqueId } from 'lodash';

import { RootState } from 'slices';
import { addVariables } from 'slices/flow';
import PrimaryButton from 'components/Buttons/Primary';
import { getValueForSelect } from 'utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { reactSelectCustomStyles } from 'styles/select';
import { VARIABLE_TYPES } from '../constants';
import VariableDropdown from '../VariableDropdown';

/**
 * This modal use as Prompt
 * @param props
 * @returns {JSX.Element} - A JSX element representing the `Modal` component.
 */
function CreateVariableModal(props) {
  const {
    title = 'Create variable',
    show = false,
    onCancel,
    onConfirm,
    loading,
    setSelectedVariable,
  } = props;

  const { variables } = useSelector((state: RootState) => state.flow);

  const [errors, setErrors] = useState({
    isInvalid: false,
    msg: '',
  });

  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    variableName: '',
    variableType: 'string',
  });
  const [remainingCharacters, setRemainingCharacters] = useState(64);
  const [isFocused, setIsFocused] = useState(false);

  const handleInputFocus = useCallback((event) => {
    setIsFocused(event.type === 'focus');
  }, []);

  const handleInputChange = useCallback(
    (e) => {
      e.stopPropagation();
      const { value } = e.target;

      // Clear the error message if the input is not empty
      if (value.trim() !== '') {
        setErrors({
          isInvalid: false,
          msg: '',
        });
      }

      if (value.length <= 64) {
        setFormData((prevData) => ({ ...prevData, variableName: value }));
        setRemainingCharacters(64 - value.length);
      }
    },
    [setErrors],
  );

  const handleSelectChange = (event: { value: any; other: any }, id: string) => {
    setFormData({
      ...formData,
      [id]: event?.value || null,
    });
  };

  const handleConfirm = () => {
    if (formData?.variableName) {
      const existingLabel = variables.find((variable) => variable.label === formData.variableName);
      if (existingLabel) {
        setErrors({
          isInvalid: true,
          msg: 'Variable name already exists',
        });
      } else {
        const dispatchPayload = {
          id: uniqueId(),
          type: formData?.variableType,
          label: formData?.variableName,
          value: formData?.variableName,
        };

        dispatch(addVariables(dispatchPayload));

        if (setSelectedVariable) {
          setSelectedVariable({ type: dispatchPayload?.type, value: dispatchPayload?.value });
        }

        onCancel();
      }
    } else {
      setErrors({
        isInvalid: true,
        msg: 'Variable name is required',
      });
    }
  };
  return ReactDom.createPortal(
    <div>
      <Modal
        open={show}
        className="create-variable__modal top-0 left-0 right-0 p-5 z-50 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(auto-1rem)] max-w-2xl max-h-full"
      >
        <Modal.Header className="header">
          <div className="flex justify-between items-center">
            <div className="flex-wrap items-center">
              <span>{title}</span>
            </div>
            <div className="text-sm font-normal bg-red">
              <Button
                size="xs"
                shape="circle"
                className="btn btn-xs btn-circle border-2 bg-white border-rasinBlack font-bold"
                onClick={onCancel}
                disabled={loading}
              >
                x
              </Button>
            </div>
          </div>
        </Modal.Header>

        <Modal.Body className="modal-body">
          <div className="border-none grid grid-cols-1">
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">Variable name:</span>
                {isFocused && <span className="label-text-alt">{remainingCharacters}</span>}
              </label>
              <input
                name="variableName"
                type="text"
                placeholder=""
                className={`modal__input input input-bordered w-full ${
                  errors?.isInvalid ? 'border border-red-500' : ''
                }`}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
              />
              {errors?.isInvalid && <span className="text-xs text-red-500">{errors?.msg}</span>}
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">Variable type:</span>
              </label>
              <VariableDropdown
                options={VARIABLE_TYPES}
                variablesOnly
                onChange={(e) => handleSelectChange(e, 'variableType')}
                value={getValueForSelect(formData?.variableType, VARIABLE_TYPES)}
                customStyles={reactSelectCustomStyles}
              />
            </div>
          </div>
        </Modal.Body>
        <div className="w-full py-5 px-5 flex justify-end gap-3 items-center">
          <PrimaryButton
            text="Create"
            btnColor="bg-spaceCadetBlue80"
            color="white"
            radius="2xl"
            btnHoverColor="hover:bg-spaceCadetBlue"
            handleOnClick={handleConfirm}
            loading={loading}
            disabled={loading}
          />
          <PrimaryButton
            text="Cancel"
            btnColor="bg-begoniaRed"
            btnHoverColor="hover:bg-coralRed"
            color="white"
            radius="2xl"
            handleOnClick={onCancel}
            disabled={loading}
          />
        </div>
      </Modal>
    </div>,
    document.body,
  );
}

CreateVariableModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  actionButtons: PropTypes.object,
  show: PropTypes.bool,
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  setSelectedVariable: PropTypes.func,
};
export default CreateVariableModal;
