/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useState } from 'react';
import { omitBy, isUndefined } from 'lodash';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import { apiErrorHandler } from 'api/handler';
import { CASE_STATUS } from 'constants/cases.const';
import { ConversationStatus } from 'constants/conversation.const';
import { getCaseListByContactId } from 'services/case.service';
import { getContactById } from 'services/contact.service';
import { getNotesListByContactId } from 'services/note.service';
import { fetchConversationsList } from 'services/conversation.service';
import { PATHS } from 'routes/paths';

import { conversationTableHeaders } from 'views/CaseManagement/Form/containers/constants';
import { mapNotes } from './utils';
import { IContactViewURLQueryParams, IFormattedNote, INote } from './interface';
import { tableCasesDefaultValues, tableNotesDefaultValues } from './constants';
import ContactViewPage from './page';

const contactStatusOptions = CASE_STATUS.filter(
  (status) => ['open', 'completed', 'deleted', 'closed'].includes(status.value),
  // eslint-disable-next-line function-paren-newline
);

function ContactView() {
  const location = useLocation();
  const navigate = useNavigate();

  const [customLoading, setCustomLoading] = useState({ list: false });
  // show note
  const [isNoteVisible, setIsNoteVisible] = useState(false);
  // note data for drawer
  const [noteData, setNoteData] = useState<INote>();
  // url search param
  const [urlSearchParams, setUrlSearchParams] = useState(null);

  const [searchByInput, setSearchByInput] = React.useState<any>();
  // wait 500 milliseconds when searchInput changes
  const [debouncedSearchBy] = useDebounce(searchByInput, 500);

  // active tab
  const [activeTab, setActiveTab] = useState('cases');
  // dropdown status
  const [dropDownOpen, setDropDownOpen] = useState(false);

  // contact details
  const [contactDetails, setContactDetails]: any = useState({});
  // cases list data
  const [casesListData, setCasesListData] = useState(tableCasesDefaultValues);
  // chat list data
  const [chatListData, setChatListData] = useState({
    totalDocs: 0,
    totalPages: 0,
    limit: 5,
    page: 1,
    headers: conversationTableHeaders,
    docs: [],
    sort: null,
    order: -1,
  });
  // note list data
  const [noteListData, setNoteListData] = useState(tableNotesDefaultValues);

  const [triggerChild, setTriggerChild] = useState({ notes: null, cases: null, transaction: null });

  // show preview drawer
  const [visible, setVisible] = useState(false);
  // data for preview drawer
  const [botChatData, setBotChatData] = useState<any>();

  // Effect to initialize URL search parameters from location
  useEffect(() => {
    const urlSearch = Object.fromEntries(new URLSearchParams(location.search));
    if (!urlSearch.i) {
      // redirect to list view
      navigate(PATHS.CONTACT.LIST.url);
    }

    // fetch contact data by id
    fetchContactById(urlSearch.i);

    if (!urlSearch.new || !urlSearch.page) {
      replaceUrlParamsHandler({
        i: urlSearch.i,
        page: 1,
        limit: 10,
        tab: 'cases',
        new: urlSearch.new || 'false',
      });
      return;
    }

    setActiveTab(urlSearch.tab);
    setUrlSearchParams(urlSearch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  // when search box text changes
  useEffect(() => {
    if (debouncedSearchBy || debouncedSearchBy === '') {
      setUrlSearchParams({ ...urlSearchParams, searchBy: debouncedSearchBy });
      handleFilterBy(debouncedSearchBy, 'searchBy');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchBy]);

  // effect to fetch data whenever a url search param change
  useEffect(() => {
    if (urlSearchParams) {
      if (urlSearchParams.tab === 'cases') {
        fetchCaseListData();
      } else if (urlSearchParams.tab === 'chats') {
        fetchChatListData();
      } else if (urlSearchParams.tab === 'notes') {
        fetchNoteListData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlSearchParams, triggerChild]);

  /**
   * Function to replace URL search parameters
   * @param {IContactViewURLQueryParams} params -  Url search query params
   */
  const replaceUrlParamsHandler = (params: IContactViewURLQueryParams) => {
    const existingParams = { ...urlSearchParams };
    // Check if new parameter is present in the existing parameters
    const newParamPresent = Object.prototype.hasOwnProperty.call(existingParams, 'new');
    // Check if new parameter is present in the new parameters being passed
    const newParamInParams = Object.prototype.hasOwnProperty.call(params, 'new');

    // If new is present in both existing and new parameters, just replace its value
    if (newParamPresent && newParamInParams) {
      existingParams.new = params.new;
    } else if (!newParamPresent && newParamInParams) {
      // If new is present only in the new parameters, add it
      existingParams.new = params.new;
    } // If new is not present in the new parameters, do nothing

    const queryParams = omitBy(
      {
        ...existingParams, // Merge existing parameters
        i: params.i,
        page: params.page || '1',
        limit: params.limit,
        sort: params.sort,
        order: params.order,
        searchBy: params.searchBy,
        tab: params.tab,
        status: params.status,
        orderBy: params.orderBy,
      },
      isUndefined,
    );

    const searchParams = new URLSearchParams(queryParams);
    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  /**
   * Handle filtering by adding and removing url params using value from the element and passed id
   * @param {string} value - Value that used to search or filter the field eg: doe@abc.com
   * @param {string} id - Field or parameter that need to use for filtering. eg: adminEmail
   */
  const handleFilterBy = (value: string, id: string) => {
    // create a copy of url search param
    const updatedSearchParams = {
      ...urlSearchParams,
    };
    // add new param
    if (value) {
      updatedSearchParams[id] = value;
    } else {
      delete updatedSearchParams[id]; // Remove the key if value is undefined
    }
    // update url
    replaceUrlParamsHandler(updatedSearchParams);
  };

  /**
   * Fetch contact data by id
   * @param {string} id -  Contact Id
   */
  const fetchContactById = async (id: string) => {
    setCustomLoading((prevState) => ({ ...prevState, list: true }));
    try {
      const { data } = await getContactById(id);
      const { node } = data;
      setContactDetails(node);
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, list: false }));
    }
  };

  /**
   * Fetch case list data according to provided search params
   */
  const fetchCaseListData = async () => {
    setCustomLoading((prevState) => ({ ...prevState, list: true }));
    try {
      const { data } = await getCaseListByContactId(urlSearchParams?.i, urlSearchParams);
      const { node } = data;
      setCasesListData({ ...casesListData, ...node });
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, list: false }));
    }
  };
  /**
   * Fetch chat list data according to provided search params
   */
  const fetchChatListData = async () => {
    setCustomLoading((prevState) => ({ ...prevState, list: true }));
    try {
      const { data } = await fetchConversationsList({
        contactId: urlSearchParams?.i,
        searchBy: searchByInput || '',
        status: urlSearchParams?.status,
        fromContactView: true,
      });
      const { node } = data;
      setChatListData({ ...chatListData, ...node });
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, list: false }));
    }
  };

  /**
   * Fetch note list data according to provided search params
   */
  const fetchNoteListData = async () => {
    setCustomLoading((prevState) => ({ ...prevState, list: true }));
    try {
      const { data } = await getNotesListByContactId(urlSearchParams?.i, urlSearchParams);
      const { node } = data;
      if (node) {
        const docs = mapNotes(node?.docs);
        setNoteListData({ ...noteListData, ...node, docs });
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, list: false }));
    }
  };

  /**
   * Generates a 'mailto' link for sending an email.
   *
   * @returns {string} The 'mailto' link.
   */
  const sendEmail = () => {
    const emailAddress = contactDetails.email;
    const subject = '';
    const body = '';

    const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(
      subject,
    )}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;
  };

  /**
   * Navigate to edit case form on mange button click
   * @param {any} e - Event
   * @param {} row - Case table row
   */
  const handleManageCase = (e, row) => {
    navigate(`${PATHS.CASES_MANAGEMENT.FORM.url}?md=edit&i=${row._id}`);
  };

  /**
   * Handle page change in pagination by replacing the url search params
   * @param {number} pageNo - Page number user requesting to navigate
   */
  const handlePageChange = (pageNo: number) => {
    replaceUrlParamsHandler({
      ...urlSearchParams,
      page: pageNo,
    });
  };

  /**
   * A callback function that is triggered when the user changes the number of
   * results to display per page.
   * @param {number} limit - The new limit value (i.e. number of results per page).
   */
  const handleResultsPerPageChange = (limit: number) => {
    replaceUrlParamsHandler({
      ...urlSearchParams,
      page: 1,
      limit,
    });
  };

  /**
   * Whether the dropdown open
   */
  const handleDropDownChange = () => {
    setDropDownOpen((prevState) => !prevState);
  };

  /**
   * Handle tab change
   * @param {string} tab - Tab name
   */
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setDropDownOpen(false);

    replaceUrlParamsHandler({
      ...urlSearchParams,
      tab,
      page: 1,
    });
  };

  /**
   * Handles the note drawer visibility and data
   * @param {INote} note - Note data from clicked row
   */
  const showNote = (e: any, note: IFormattedNote) => {
    setNoteData(note);
    // toggle
    setIsNoteVisible((prevState) => !prevState);
  };

  /**
   * Handles the view conversation
   * @param {IContact} conversation - Note data from clicked row
   */
  const viewConversation = (e: any, conversation: any) => {
    const { status } = conversation;
    // conversation is ended open in drawer else open in new window
    if (status === ConversationStatus.ENDED) {
      setVisible(true);
      setBotChatData(conversation);
    } else {
      window.open(
        `${PATHS.CASES_MANAGEMENT.VIEW.url}?i=${conversation?._id}`,
        '_blank',
        'width=1280,height=1024',
      );
    }
  };

  /**
   * Close preview drawer
   */
  const closePreview = () => {
    setVisible(false);
    setBotChatData(null);
  };

  return (
    <ContactViewPage
      noteData={noteData}
      isNoteVisible={isNoteVisible}
      casesListData={casesListData}
      chatListData={chatListData}
      noteListData={noteListData}
      urlSearchParams={urlSearchParams}
      activeTab={activeTab}
      contactDetails={contactDetails}
      contactStatusOptions={contactStatusOptions}
      customLoading={customLoading}
      dropDownOpen={dropDownOpen}
      showNote={showNote}
      handleTabChange={handleTabChange}
      setSearchByInput={setSearchByInput}
      handleFilterBy={handleFilterBy}
      handlePageChange={handlePageChange}
      handleResultsPerPageChange={handleResultsPerPageChange}
      setIsNoteVisible={setIsNoteVisible}
      handleManageCase={handleManageCase}
      handleSendEmail={sendEmail}
      handleDropDownChange={handleDropDownChange}
      setTriggerChild={setTriggerChild}
      triggerChild={triggerChild}
      viewConversation={viewConversation}
      closePreview={closePreview}
      visible={visible}
      botChatData={botChatData}
      searchByInput={searchByInput}
    />
  );
}

export default ContactView;
