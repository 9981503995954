import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import AddCustomer from 'components/Icons/AddCustomer';
import GeneralButton from 'components/Buttons/GeneralButton';
import SearchBar from 'components/SearchBar';
import { PATHS } from 'routes/paths';
import UserProfileCard from './UserProfileCard';
import UserTemplate from './UserTemplate';

function UserLayout(props) {
  const { toggleTemplateModal, triggerChild, setChatFormData, contactData } = props;

  const navigate = useNavigate();

  return (
    <div className="m-4">
      <div className="flex flex-col justify-between items-center sm:flex-row sm:items-center">
        <div className="mb-2 md:mr-4 md:mb-0">
          <SearchBar placeholder="Type here to search customers" />
        </div>
        <div className="md:w-[140px]">
          <GeneralButton
            text="Add Contact"
            btnColor="bg-spaceCadetBlue80"
            color="white"
            radius="2xl"
            btnHoverColor="hover:bg-spaceCadetBlue"
            icon={<AddCustomer />}
            className="whitespace-nowrap"
            textSize="xs"
            handleOnClick={() => navigate(`${PATHS.CONTACT.FORM.url}?md=new`)}
          />
        </div>
      </div>
      <UserProfileCard contactData={contactData} />
      <UserTemplate
        toggleTemplateModal={toggleTemplateModal}
        triggerChild={triggerChild}
        setChatFormData={setChatFormData}
      />
    </div>
  );
}
UserLayout.propTypes = {
  toggleTemplateModal: PropTypes.func,
  triggerChild: PropTypes.object,
  setChatFormData: PropTypes.func,
  contactData: PropTypes.object,
};

export default UserLayout;
