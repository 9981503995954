import * as React from 'react';
import PropTypes from 'prop-types';

function Wizard(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color = '#000', width = 20, height = 20 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
      width={width}
      height={height}
      transform="rotate(270)"
      version="1.1"
      viewBox="0 0 512.003 512.003"
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M240.968 169.018H345.748V268.284H240.968z"
          transform="rotate(-45.001 293.357 218.656)"
        />
        <path d="M197.816 243.992L14.537 427.272c-19.383 19.382-19.381 50.809 0 70.194 19.383 19.38 50.81 19.383 70.194 0l183.279-183.28-70.194-70.194z" />
        <path
          d="M372.068 79.258H460.304V112.346H372.068z"
          transform="rotate(-45.001 416.187 95.807)"
        />
        <path d="M274.868 0.001H307.957V88.238H274.868z" />
        <path
          d="M150.076 51.693H183.165V139.929H150.076z"
          transform="rotate(-45.001 166.62 95.813)"
        />
        <path d="M70.809 204.049H159.04500000000002V237.138H70.809z" />
        <path d="M274.868 352.949H307.957V441.18600000000004H274.868z" />
        <path
          d="M399.659 301.272H432.747V389.507H399.659z"
          transform="rotate(-45.001 416.201 345.396)"
        />
        <path d="M423.767 204.049H512.003V237.137H423.767z" />
      </g>
    </svg>
  );
}
Wizard.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Wizard;
