import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { isUndefined, omitBy } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-simple-toasts';
import { v4 as uuid } from 'uuid';

import { IURLQueryParams } from 'interfaces/query.interface';
import { resetFlow } from 'slices/flow';
import { PATHS } from 'routes/paths';
import { CustomTable, InputText, PrimaryButton, PromptModal } from 'components';
import { AddIcon, Back, Manage } from 'components/Icons';
import GeneralButton from 'components/Buttons/GeneralButton';
import { apiErrorHandler } from 'api/handler';
import { updateURLWithSearchParams } from 'utils/helpers';
import {
  addIntentUtterance,
  deleteIntentUtterance,
  getIntentById,
  updateIntentUtterance,
} from 'services/messenger.service';
import { createMessengerFlow, getFlowByIntent } from 'services/messengerFlow.service';
import { ToastTheme } from 'enums/toast.enum';
import { DefaultIntents } from 'constants/intents';

import FormEditHeader from './containers/FormEditHeader';
import EditUtterance from './modals/EditUtterance';
import { addUtteranceValidationSchema } from '../validations/index';
import { ModalTypes, PromptTypes, modalDefaultOptions, utterancesTableHeaders } from '../constants';
import { loadUtterancesList } from './utils';
import IntentSuggestion from './components/IntentSuggestion';

/**
 * Messenger Intent Page
 * @param props
 * @returns {JSX.Element} - A JSX element representing the Page
 */
function MessengerIntentPage(): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [urlSearchParams, setUrlSearchParams] = useState(null); //  URL search parameters
  const dispatch = useDispatch();

  const [formData, setFormData] = useState<any>({
    utterance: '',
    intentSuggestion: '',
  });

  const [intentData, setIntentData] = useState<any>();

  const [utteranceList, setUtteranceList] = useState({
    docs: [],
    headers: utterancesTableHeaders,
    totalDocs: 0,
    totalPages: 0,
    limit: 10,
    page: 1,
    sort: null,
    order: -1,
    searchBy: null,
  });

  const [customLoading, setCustomLoading] = useState({
    fetchList: false,
    formSubmit: false,
  });
  const [editMode, setEditMode] = useState(false);

  const [promptOptions, setPromptOptions] = useState<any>({});
  const [modalOptions, setModalOptions] = useState(modalDefaultOptions);
  const [triggerChild, setTriggerChild] = useState({ transactions: null });

  // Get page type and Id
  const pageType = searchParams.get('md');
  const pageId = searchParams.get('i');

  const { register, handleSubmit, formState, control } = useForm<any>({
    resolver: useMemo(() => yupResolver(addUtteranceValidationSchema), [formData]),
    values: useMemo(() => formData, [customLoading.formSubmit]),
  });

  const { errors } = formState;

  useEffect(() => {
    const urlSearch = Object.fromEntries(new URLSearchParams(location.search));
    if (urlSearch && urlSearch.page) {
      replaceUrlParamsHandler({ ...urlSearchParams, ...urlSearch });
    } else {
      replaceUrlParamsHandler({ page: 1, limit: 10 });
    }
    // Reset the flow state when the path changes
    dispatch(resetFlow());
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (urlSearchParams) {
      loadUtterancesList(pageId, urlSearchParams, setUtteranceList, setCustomLoading);
    }
  }, [urlSearchParams]);

  useEffect(() => {
    if (!pageType) {
      updateURLWithSearchParams({ md: 'new' });
    }
    if (pageType && pageType === 'edit' && pageId) {
      loadIntentData(pageId);
      if (urlSearchParams) {
        loadUtterancesList(pageId, urlSearchParams, setUtteranceList, setCustomLoading);
      }
    }
  }, [triggerChild]);

  // loadUtterancesList function call
  useEffect(() => {
    if (urlSearchParams) {
      loadUtterancesList(pageId, urlSearchParams, setUtteranceList, setCustomLoading);
    }
  }, [urlSearchParams]);

  /**
   * Function to replace URL search parameters
   * @param { IClientListURLQueryParams } params -  Url search query params
   */
  const replaceUrlParamsHandler = (params: IURLQueryParams) => {
    const queryParams = omitBy(
      {
        page: params.page || '1',
        limit: params.limit || '10',
        sort: params.sort,
        order: params.order,
        searchBy: params.searchBy,
      },
      isUndefined,
    );

    const existingSearchParams = new URLSearchParams(location.search);
    const newSearchParams = new URLSearchParams({
      ...Object.fromEntries(existingSearchParams),
      ...queryParams,
    });
    setUrlSearchParams({ ...urlSearchParams, ...queryParams });

    navigate(`?${newSearchParams.toString()}`, { replace: true });
  };

  /**
   * Handle page change in pagination by replacing the url search params
   *
   * @param {number} pageNo - Page number user requesting to navigate
   */
  const handlePageChange = (pageNo: number) => {
    replaceUrlParamsHandler({
      ...urlSearchParams,
      page: pageNo,
    });
  };

  /**
   * A callback function that is triggered when the user changes the number of
   * results to display per page.
   * @param {number} limit - The new limit value (i.e. number of results per page).
   */
  const handleResultsPerPageChange = (limit: number) => {
    setUtteranceList((prevTableData) => ({ ...prevTableData, limit }));
    replaceUrlParamsHandler({
      page: utteranceList.page,
      limit,
    });
  };

  // handle the input text change
  const handleInputChange = (event: { target: { id: any; value: any } }) => {
    const { id, value } = event.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  // handle intent suggestion input text change
  const handleIntentSuggestionChange = (event: { target: { id: any; value: any } }) => {
    const { id, value } = event.target;
    setIntentData({
      ...intentData,
      [id]: value,
    });
  };

  // handle add and update utterance
  const handleUpdate = async (utteranceData) => {
    const toastTask = toast(`${editMode ? 'Updating' : 'Creating'} Utterance ...`, {
      loading: true,
    });
    try {
      const payload = {
        sampleUtterances: [
          {
            utterance: formData?.utterance,
          },
        ],
      };
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: true }));
      if (!editMode) {
        const { data } = await addIntentUtterance(pageId, payload);
        if (data) {
          if (data?.node) {
            setFormData((prevState: any) => ({
              ...prevState,
              utterance: null,
            }));
            setTriggerChild((prevState) => ({
              ...prevState,
              transactions: uuid(),
            }));
            setModalOptions({ ...modalOptions, show: false });
            toastTask.update({
              message: 'Successfully Added Utterance',
              duration: 5000,
              theme: ToastTheme.success,
              loading: false,
            });
          }
        }
      } else {
        const { modalData } = modalOptions as any;
        const { data: recordData } = modalData;

        const utterances = {
          existUtterance: recordData?.utterance,
          updatedUtterance: utteranceData?.utterance,
        };

        const { data } = await updateIntentUtterance(pageId, utterances);
        if (data) {
          if (data?.node) {
            setEditMode(false);
            setTriggerChild((prevState) => ({
              ...prevState,
              transactions: uuid(),
            }));
            setModalOptions({ ...modalOptions, show: false });
            toastTask.update({
              message: 'Successfully Updated utterance',
              duration: 5000,
              theme: ToastTheme.success,
              loading: false,
            });
          }
        }
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toastTask.update({
        message: exception,
        duration: 5000,
        theme: 'failure',
        loading: false,
      });
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: false }));
    }
  };

  const handleDelete = async (row: any) => {
    const toastTask = toast('Deleting Utterance', {
      loading: true,
    });
    try {
      const utterances = {
        existUtterance: row?.utterance,
      };
      const { data } = await deleteIntentUtterance(pageId, utterances);
      if (data) {
        if (data?.node) {
          setFormData((prevState: any) => ({ ...prevState, data }));
          setTriggerChild((prevState) => ({
            ...prevState,
            transactions: uuid(),
          }));
          setPromptOptions({ ...promptOptions, show: false });
          toastTask.update({
            message: 'Successfully Deleted utterance',
            duration: 5000,
            theme: ToastTheme.success,
            loading: false,
          });
        }
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast(exception, { theme: ToastTheme.failure });
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, fetchList: false }));
    }
  };

  const loadIntentData = async (id: string) => {
    try {
      const { data } = await getIntentById(id);
      if (data?.node) {
        // logic to create intent flow if missing
        {
          const flowData = await getFlowByIntent(id);
          if (!flowData?.data) {
            const { messengerId } = data.node;
            await createMessengerFlow({ messengerId, messengerIntentId: id });
          }
        }
        setIntentData({ ...data?.node });
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast(exception, { theme: ToastTheme.failure });
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, fetchList: false }));
    }
  };

  const onClickUtterance = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, row) => {
    event.preventDefault();
    setEditMode(true);
    setModalOptions((prevState) => ({
      ...prevState,
      show: true,
      actionStatus: false,
      type: ModalTypes.EditUtterance,
      modalData: { recordId: row._id, data: row },
    }));
  };

  const handleDeleteUtterancePrompt = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    row: any,
  ) => {
    setPromptOptions((prevState) => ({
      ...prevState,
      show: true,
      title: 'Delete Utterance',
      message: 'Do you want to delete the utterance?',
      onConfirm: () => handleDelete(row),
      type: PromptTypes.DeleteUtterance,
    }));
  };

  const handleNavigate = () => {
    navigate(`${PATHS.MESSENGERS.FLOW_BUILDER.url}?i=${pageId}`);
  };

  const handleKeyDown = (e, data) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (data.utterance !== null && data.utterance.trim() !== '') {
        handleUpdate(data);
      }
    }
  };

  const handleBack = (e: any) => {
    e.preventDefault();
    if (intentData?.messengerId) {
      navigate(`${PATHS.MESSENGERS.FORM.url}?md=edit&i=${intentData.messengerId}&page=1&limit=10`);
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="page">
      <FormEditHeader formData={intentData} setIntentData={setIntentData} />
      <hr className="my-2" />
      {intentData?.intentId === DefaultIntents.FallbackIntent.intentId && (
        <div role="alert" className="alert alert-info my-4 bg-honeydew">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className="stroke-current shrink-0 w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span>Fallback Intent Cannot Have Utterances</span>
        </div>
      )}
      <div className="p-8 bg-white drop-shadow rounded-xl mt-4 form_body">
        <div className="flex items-center justify-between">
          <span className="text-spaceCadetBlue font-semibold font-ptScanCap text-base srg:text-xl">
            Add Utterances
          </span>
          <div className="flex items-center gap-2">
            <GeneralButton
              text="Edit Flow"
              btnColor="bg-spaceCadetBlue80"
              color="white"
              radius="2xl"
              textSize="xs"
              btnHoverColor="hover:bg-spaceCadetBlue"
              icon={<Manage />}
              handleOnClick={() => handleNavigate()}
            />
            <GeneralButton
              text="Back"
              btnColor="bg-spaceCadetBlue80"
              color="white"
              radius="2xl"
              textSize="xs"
              btnHoverColor="hover:bg-spaceCadetBlue"
              icon={<Back />}
              handleOnClick={handleBack}
            />
          </div>
        </div>
        <hr className="my-2" />
        <form onSubmit={handleSubmit(handleUpdate)} noValidate>
          <div className="grid grid-cols-2 xs:grid-cols-1 rg:grid-cols-2 lg:grid-cols-2 gap-4">
            <div className="mb-4">
              <div className="mt-6">
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <InputText
                      type="text"
                      elementId="utterance"
                      placeholder="eg: How may I assist you?"
                      register={register}
                      error={errors?.utterance}
                      value={formData?.utterance}
                      onChange={(e: any) => {
                        field.onChange(e);
                        handleInputChange(e);
                      }}
                      onKeyDown={(e) => {
                        handleKeyDown(e, formData);
                      }}
                      disabled={intentData?.intentId === DefaultIntents.FallbackIntent.intentId}
                    />
                  )}
                />
              </div>
            </div>
          </div>

          {intentData?.intentId !== DefaultIntents.FallbackIntent.intentId && (
            <div className="mt-2 mb-4">
              <PrimaryButton
                text="Add"
                btnColor="bg-spaceCadetBlue80"
                color="white"
                radius="2xl"
                textSize="base"
                btnHoverColor="hover:bg-spaceCadetBlue"
                icon={<AddIcon />}
                disabled={customLoading.formSubmit}
                loading={customLoading.formSubmit}
                handleOnClick={handleSubmit}
              />
            </div>
          )}
        </form>
      </div>

      {/* Intent Suggestion Section */}
      <IntentSuggestion
        intentData={intentData}
        setIntentData={setIntentData}
        handleInputChange={handleIntentSuggestionChange}
      />
      <div className="p-8 mt-4 bg-white drop-shadow rounded-xl form_body">
        <span className="text-spaceCadetBlue font-semibold font-ptScanCap text-base srg:text-xl">
          Utterances
        </span>
        <hr className="my-3" />
        <div className="mt-8">
          <CustomTable
            tableData={utteranceList}
            isPaginate
            onPageChange={handlePageChange}
            handleResultsPerPageChange={handleResultsPerPageChange}
            onEdit={onClickUtterance}
            onDelete={handleDeleteUtterancePrompt}
            loading={{ list: customLoading.fetchList }}
          />
        </div>
      </div>
      {modalOptions.type === ModalTypes.EditUtterance && modalOptions.show && (
        <EditUtterance
          handleUpdate={handleUpdate}
          {...modalOptions}
          toggleModal={() => {
            setModalOptions({ ...modalOptions, show: false });
            setEditMode(false);
          }}
        />
      )}

      {promptOptions.show && (
        <PromptModal
          onCancel={() => {
            setPromptOptions({ ...promptOptions, show: false });
          }}
          {...promptOptions}
          loading={false}
        />
      )}
    </div>
  );
}

MessengerIntentPage.propTypes = {
  formData: PropTypes.any,
  setFormData: PropTypes.func,
  status: PropTypes.string,
};

export default MessengerIntentPage;
