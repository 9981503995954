/* eslint-disable react/forbid-prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import classNames from 'classnames';

import { Delete } from 'components/Icons';

function DeleteRoleBtn(props) {
  const { formData, handleOnDeleteRolePermission, loading } = props;

  return (
    <motion.button
      onClick={handleOnDeleteRolePermission}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.9 }}
      type="submit"
      className={classNames(
        'px-[20px] py-[10px] rounded-2xl text-white font-ptScanCap bg-begoniaRed hover:bg-coralRed',
      )}
    >
      {loading ? (
        <span className="loading loading-infinity loading-lg" />
      ) : (
        <span className="text-base font-semibold flex flex-row items-center ">
          <Delete width={24} color="white" /> &nbsp; Delete
        </span>
      )}
    </motion.button>
  );
}

DeleteRoleBtn.propTypes = {
  formData: PropTypes.any,
  handleOnDeleteRolePermission: PropTypes.func,
  loading: PropTypes.bool,
};

export default DeleteRoleBtn;
