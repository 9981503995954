import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import Wizard from '../tempIcons/Wizard';

function WizardButton(props) {
  const { getLayoutElements } = props;

  return (
    <div>
      <motion.button
        whileTap={{ scale: 0.9 }}
        type="button"
        className="bg-white px-[15px] py-[14px] rounded-3xl shadow-sm hover:shadow-md"
        onClick={() => getLayoutElements({ 'elk.algorithm': 'layered', 'elk.direction': 'RIGHT' })}
      >
        <div>
          <Wizard color="#688fa1" />
        </div>
      </motion.button>
    </div>
  );
}

WizardButton.propTypes = {
  getLayoutElements: PropTypes.func,
};

export default WizardButton;
