import React from 'react';
import PropTypes from 'prop-types';

function ImagePlaceholder(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color = '#262525' } = props;

  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M51.3332 44.3333C51.3332 45.571 50.8415 46.758 49.9663 47.6332C49.0912 48.5083 47.9042 49 46.6665 49H9.33317C8.09549 49 6.90851 48.5083 6.03334 47.6332C5.15817 46.758 4.6665 45.571 4.6665 44.3333V18.6667C4.6665 16.1 6.7665 14 9.33317 14H16.3332L20.9998 7H34.9998L39.6665 14H46.6665C47.9042 14 49.0912 14.4917 49.9663 15.3668C50.8415 16.242 51.3332 17.429 51.3332 18.6667V44.3333Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.9998 39.6667C33.1545 39.6667 37.3332 35.488 37.3332 30.3333C37.3332 25.1787 33.1545 21 27.9998 21C22.8452 21 18.6665 25.1787 18.6665 30.3333C18.6665 35.488 22.8452 39.6667 27.9998 39.6667Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

ImagePlaceholder.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default ImagePlaceholder;
