/* eslint-disable react/forbid-prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import classNames from 'classnames';

import { Disable, Enable } from 'components/Icons';

function ToggleStatusBtn(props) {
  const { formData, handleOnToggleInstanceStatus, loading } = props;

  if (formData.isActive) {
    return (
      <motion.button
        onClick={handleOnToggleInstanceStatus}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.9 }}
        type="submit"
        className={classNames(
          'pl-[10px] pr-[15px] py-[5px] w-auto rounded-2xl text-white font-ptScanCap bg-begoniaRed hover:bg-coralRed',
        )}
      >
        {loading ? (
          <span className="loading loading-infinity loading-lg" />
        ) : (
          <span className="text-xs font-semibold flex flex-row items-center ">
            <Disable width={24} className="mr-1" />
            Disable
          </span>
        )}
      </motion.button>
    );
  }

  if (!formData.isActive) {
    return (
      <motion.button
        onClick={handleOnToggleInstanceStatus}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.9 }}
        type="submit"
        className={classNames(
          'pl-[10px] pr-[15px] py-[5px] w-auto rounded-2xl text-white font-ptScanCap bg-spaceCadetBlue80 hover:bg-spaceCadetBlue',
        )}
      >
        {loading ? (
          <span className="loading loading-infinity loading-lg" />
        ) : (
          <span className="text-base font-semibold flex flex-row items-center">
            <Enable width={24} /> &nbsp; Enable
          </span>
        )}
      </motion.button>
    );
  }
}

ToggleStatusBtn.propTypes = {
  formData: PropTypes.any,
  handleOnToggleInstanceStatus: PropTypes.func,
  loading: PropTypes.bool,
};

export default ToggleStatusBtn;
