import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CopyBlock, dracula } from 'react-code-blocks';
import { PrimaryButton } from 'components';
import { CopyText } from 'components/Icons';
import { toast } from 'react-toastify';

function ScriptTagSnippet(props: any) {
  const { formData } = props;

  const { liveChatId, chatKey } = formData;

  const handleCopy = () => {
    toast.success('Copied To Clipboard');
  };

  const codeSnippet = `
<body>
    <script
    id="tasa-messenger-widget"
    src="https://messenger-widget-script-qa.s3.ap-southeast-1.amazonaws.com/widget-loader.js"
    messenger-chat-id="${liveChatId}:${chatKey}"
    ></script>
</body>`;

  return (
    <div className="p-5 bg-white drop-shadow rounded-xl form_body mt-5">
      <div className="text-xs overflow-x-auto bg-red code_snippet__container">
        <CopyBlock text={codeSnippet} language="html" theme={dracula} />
      </div>

      <CopyToClipboard text={codeSnippet} onCopy={handleCopy}>
        <PrimaryButton
          text="Copy"
          btnColor="bg-spaceCadetBlue80"
          btnHoverColor="hover:bg-spaceCadetBlue"
          color="white"
          icon={<CopyText color="#FFF" />}
          width="[100px]"
          textSize="sm font-bold font-ptScanCap"
          radius="2xl"
          className="py-[5px] pr-[15px] pl-[10px] mt-10"
          type="button"
        />
      </CopyToClipboard>
    </div>
  );
}

export default ScriptTagSnippet;
