/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { CustomSelect, InputText } from 'components';
import { reactSelectCustomStyles } from 'styles/select';
import { countries, getDefaultValueForVariableSelect, getValueForSelect } from 'utils/helpers';
import { GENDER, PREFERRED_CONTACT_METHOD, SALUTATIONS } from 'constants/contact.const';
import { useSelector } from 'react-redux';
import TextInput from 'components/Form/InputText';
import { buildVariableDropdownOptions } from 'views/Settings/FlowBuilder/utils';
import { RootState } from 'slices';
import VariableDropdown from '../../../VariableDropdown';
import { contactsValueTypes, contactsValueWithFormFieldTypes } from '../constants';
import { groupedOptions } from '../../../constants';

const salutationOptions = Object.keys(SALUTATIONS).map((key) => ({
  label: SALUTATIONS[key],
  value: SALUTATIONS[key],
}));

function FormField({
  label,
  id,
  isFieldEnabled,
  valueType,
  value,
  options,
  formData,
  handlers,
  isFormValid,
  requiredField,
}) {
  const {
    useSelectedValueType,
    useSelectedVariableValue,
    handleCheckChange,
    handleSelectChange,
    handleInputChange,
    handleSelectVariableChange,
  } = handlers;

  const flowData = useSelector((state: RootState) => state.flow);

  return (
    <div className="flow-contact__form-field">
      <div className="form-field__row grid grid-cols-12">
        <span className="font-lato col-span-4 pt-3 w-[130px]">
          {label}
          {requiredField && <span className="text-red-600 pl-1">*</span>}
        </span>
        {/* <input
          id={`is${id}`}
          type="checkbox"
          checked={isFieldEnabled}
          className="checkbox col-span-1"
          onChange={(event) => handleCheckChange(event, id)}
        /> */}

        <div className="col-span-4 mr-2">
          <CustomSelect
            id={`${id}ValueType`}
            placeholder=""
            isFormValid={isFormValid}
            required={
              id === 'salutation' ||
              id === 'firstName' ||
              id === 'lastName' ||
              id === 'email' ||
              id === 'primaryContactNo' ||
              id === 'preferredContactMethod' ||
              id === 'country'
            }
            // isDisabled={!isFieldEnabled}
            options={options}
            value={useSelectedValueType(
              formData?.[id]?.valueType,
              id === 'salutation' ||
                id === 'gender' ||
                id === 'country' ||
                id === 'preferredContactMethod'
                ? contactsValueWithFormFieldTypes
                : contactsValueTypes,
              'value',
            )}
            onChange={(event) => handleSelectChange(event, id, `${id}ValueType`)}
          />
        </div>
        <div className="col-span-4">
          {valueType === 'customText' && id === 'dateOfBirth' ? (
            <TextInput
              elementId={id}
              type="date"
              value={value || ''}
              onChange={(e) => handleInputChange(e, id)}
            />
          ) : valueType === 'customText' ? (
            <InputText
              type="text"
              elementId={id}
              required={
                id === 'salutation' ||
                id === 'firstName' ||
                id === 'lastName' ||
                id === 'email' ||
                id === 'primaryContactNo' ||
                id === 'preferredContactMethod' ||
                id === 'country'
              }
              // disabled={!isFieldEnabled}
              value={value || ''}
              onChange={(e) => handleInputChange(e, id)}
            />
          ) : valueType === 'formField' ? (
            <CustomSelect
              id={id}
              placeholder=""
              required={
                id === 'salutation' ||
                id === 'firstName' ||
                id === 'lastName' ||
                id === 'email' ||
                id === 'primaryContactNo' ||
                id === 'preferredContactMethod' ||
                id === 'country'
              }
              options={
                id === 'salutation'
                  ? salutationOptions
                  : id === 'gender'
                  ? GENDER
                  : id === 'country'
                  ? countries
                  : id === 'preferredContactMethod'
                  ? PREFERRED_CONTACT_METHOD
                  : null
              }
              customStyles={reactSelectCustomStyles}
              value={getValueForSelect(
                formData?.[id].value,
                id === 'salutation'
                  ? salutationOptions
                  : id === 'gender'
                  ? GENDER
                  : id === 'country'
                  ? countries
                  : id === 'preferredContactMethod'
                  ? PREFERRED_CONTACT_METHOD
                  : null,
              )}
              onChange={(event) => handleSelectVariableChange(event, id, id)}
            />
          ) : (
            <VariableDropdown
              id={id}
              required={
                id === 'salutation' ||
                id === 'firstName' ||
                id === 'lastName' ||
                id === 'email' ||
                id === 'primaryContactNo' ||
                id === 'preferredContactMethod' ||
                id === 'country'
              }
              placeholder=""
              variablesOnly
              options={buildVariableDropdownOptions(flowData?.variables || [])}
              // isDisabled={!isFieldEnabled}
              customStyles={reactSelectCustomStyles}
              value={getDefaultValueForVariableSelect(
                formData?.[id]?.value,
                groupedOptions,
                'value',
              )}
              onChange={(event) => handleSelectVariableChange(event, id, id)}
            />
          )}
        </div>
      </div>
    </div>
  );
}

FormField.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isFieldEnabled: PropTypes.bool.isRequired,
  valueType: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  options: PropTypes.array.isRequired,
  formData: PropTypes.any,
  handlers: PropTypes.any,

  useSelectedValueType: PropTypes.func,
  useSelectedVariableValue: PropTypes.func,
  handleCheckChange: PropTypes.func.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleSelectVariableChange: PropTypes.func.isRequired,
  isFormValid: PropTypes.bool,
  requiredField: PropTypes.bool,
};

export default FormField;
