import * as React from 'react';
import PropTypes from 'prop-types';

function NewTab(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color, width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 21 20">
      <g
        stroke="#AAA"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        clipPath="url(#clip0_223_2)"
      >
        <path d="M6.67 7.5h6.666M6.67 10.833h5M10.01 15.495L6.67 17.5V15H5.001a2.5 2.5 0 01-2.5-2.5V5.833a2.5 2.5 0 012.5-2.5h10a2.5 2.5 0 012.5 2.5v4.584M13.336 15.833h5M15.836 13.333v5" />
      </g>
      <defs>
        <clipPath id="clip0_223_2">
          <path fill="#fff" d="M0 0H20V20H0z" transform="translate(.002)" />
        </clipPath>
      </defs>
    </svg>
  );
}
NewTab.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default NewTab;
