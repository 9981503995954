export const reactSelectCustomStyles = {
  menuPortal: (provided) => ({ ...provided, zIndex: 99999 }),
  menu: (provided: any) => ({ ...provided, zIndex: 99999 }), // increase z-index
  option: (provided: any) => ({
    ...provided,
    fontSize: '14px',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: 48,
    fontSize: '16px',
    borderRadius: 10,
    borderColor: '#D9DEE8',
  }),
  container: (provided: any) => ({
    ...provided,
    flex: 1, // full width
    alignSelf: 'stretch', // full height
  }),
  singleValue: (provided: any, state: { isDisabled: any }) => {
    const opacity = state.isDisabled ? 0.9 : 1;
    return {
      ...provided,
      opacity,
      fontSize: '16px',
      maxWidth: 'max-content',
      color: 'black',
    };
  },
  theme: (provided: any) => ({
    ...provided,
    colors: {
      neutral50: '#fff',
    },
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    svg: {
      fill: '#262525',
    },
  }),
};

export const selectCustomStylesForFilters = {
  menu: (provided: any) => ({ ...provided, zIndex: 9999 }), // increase z-index
  option: (provided: any) => ({
    ...provided,
    fontSize: '16px',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: 43,
    fontSize: '16px',
    borderRadius: 10,
    borderColor: '#D9DEE8',
    boxShadow: 'none',
  }),
  container: (provided: any) => ({
    ...provided,
    flex: 1, // full width
    alignSelf: 'stretch', // full height
  }),
  singleValue: (provided: any, state: { isDisabled: any }) => {
    const opacity = state.isDisabled ? 0.9 : 1;
    return {
      ...provided,
      opacity,
      fontSize: '16px',
      maxWidth: 'max-content',
      color: 'black',
    };
  },
  placeholder: (provided: any) => ({
    ...provided,
    color: '#475574',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    svg: {
      fill: '#475574',
    },
  }),
};

export const reactSelectPhoneInputStyles = {
  menu: (provided: any) => ({ ...provided, zIndex: 9999 }), // increase z-index
  option: (provided: any) => ({
    ...provided,
    fontSize: '16px',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: 48,
    fontSize: '16px',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 0,
    borderColor: '#D9DEE8',
    minWidth: 80,
    padding: 0,
    backgroundColor: '#FBFBFB',
  }),
  container: (provided: any) => ({
    ...provided,
    flex: 1, // full width
    alignSelf: 'stretch', // full height
  }),
  singleValue: (provided: any, state: { isDisabled: any }) => {
    const opacity = state.isDisabled ? 0.9 : 1;
    return {
      ...provided,
      opacity,
      fontSize: '16px',
      maxWidth: 'max-content',
      color: 'black',
    };
  },
  theme: (provided: any) => ({
    ...provided,
    colors: {
      neutral50: '#fff',
    },
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    svg: {
      fill: '#262525',
    },
    padding: 0,
    paddingRight: 4,
  }),
};

export const reactSelectVariableDropdownStyles = {
  control: (provided: any) => ({
    ...provided,
    minHeight: '22px',
    fontSize: '12px',
    fontWeight: '800',
    borderRadius: '8px',
    height: '30px',
  }),
  menu: (provided: any) => ({
    ...provided,
    width: '250px',
    right: 0,
    position: 'absolute',
    zIndex: '9999999',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    svg: {
      fill: '#5c6a70',
    },
    alignItems: 'center',
    justifyItem: 'center',
    justifyContent: 'center',
    padding: '5px',
  }),
};
