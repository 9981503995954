import * as React from 'react';
import PropTypes from 'prop-types';

function AnyIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color = '#9554B8', width = 25, height = 16 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 25 16"
    >
      <circle cx="8" cy="8" r="7.5" fill="#9DB5C4" fillOpacity="0.5" stroke="#9DB5C4" />
      <circle cx="17" cy="8" r="7.5" fill="#9DB5C4" fillOpacity="0.5" stroke="#9DB5C4" />
      <path
        fill="#C1D0D8"
        d="M10.016 7.992c0-4.281 2.484-5.351 2.484-5.351s2.484 1.07 2.484 5.351-2.484 5.352-2.484 5.352-2.484-1.07-2.484-5.352z"
      />
    </svg>
  );
}
AnyIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default AnyIcon;
