export const initialNodes: any = [
  {
    id: '1',
    type: 'customNode',
    data: { heading: 'Start' },
    position: { x: 10, y: 10 },
  },
  {
    id: '2',
    type: 'customNode',
    data: { heading: 'Message', content: null },
    position: { x: 200, y: 100 },
  },
];

export const initialEdges: any = [
  {
    id: '1',
    source: '1',
    target: '2',
    style: { strokeWidth: 2, stroke: '#3B82F6' },
    markerEnd: { type: 'arrowclosed', color: '#3B82F6' },
  },
];

export const rfStyle = {
  backgroundColor: '#eaf9f9',
  maxHeight: '70vh',
  borderRadius: '10px',
};

export const rfStyleDrawerOpen = {
  backgroundColor: '#bec6c6',
  maxHeight: '70vh',
  borderRadius: '10px',
};

export const NodeHeaders = {
  MESSAGE: 'Message',
  FLOW: 'Flow',
  ACTION: 'Action',
  API_REQUEST: 'API Request',
  FILTER: 'Filter',
  ADD_CONTACT: 'Add Contact',
  ADD_CASE: 'Add Case',
  CAROUSEL: 'Carousel',
  LIVE_CHAT: 'Live Chat',
  End_CHAT: 'End Chat',
};
