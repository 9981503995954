import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Handle, Position } from 'reactflow';
import { uniqueId } from 'lodash';

import { ICarouselError } from 'interfaces/flow.interface';
import { RootState } from 'slices';
import { NODE_TYPES, nodeStyle } from '../../constants';
import { ICarouselSetting, ISlide } from '../../DrawerContents/MessageDrawer/interfaces';
import Error from '../../tempIcons/Error';

function CarouselNode(props) {
  const { id, data, selected } = props;

  const [carouselSettings, setCarouselSettings] = useState<ICarouselSetting>();
  // tracks the validity of the node and highlight invalid nodes
  const [isNodeValid, setIsNodeValid] = useState(true);
  const [nodeErrors, setNodeErrors] = useState<any>();

  const flowData = useSelector((state: RootState) => state.flow);

  useEffect(() => {
    // get selected carousel node data from flowData array
    const carouselObj = flowData.nodeConfigs.find((node) => node.nodeId === id) || {};

    if (carouselObj) {
      const { carouselSettings: carouselSettingsObj } = carouselObj;
      setCarouselSettings(carouselSettingsObj);

      if (carouselObj?.isValid !== undefined && carouselObj?.isValid === false) {
        setIsNodeValid(false);
        setNodeErrors(carouselObj?.errors);
      } else {
        setIsNodeValid(true);
        setNodeErrors([]);
      }
    }
  }, [flowData]);

  const renderSlides = () => {
    const slides = carouselSettings?.slides || [];

    if (slides) {
      return slides.map((slide: ISlide[], idx: number) => (
        <div className="carousel-node__slide" key={`slide_${uniqueId()}`}>
          {slide.map((slideItem: ISlide) => {
            const { id: slideItemId } = slideItem;

            const hasError = nodeErrors?.some(
              (error: ICarouselError) => error.slideId === slideItemId,
            );

            return (
              <div className="slide" key={slideItemId}>
                {hasError && (
                  <span className="mr-1">
                    <Error height={18} width={18} />
                  </span>
                )}
                {slideItem.name === 'title' && (
                  <div className="slide__heading">
                    <div className="slide-number  text-right">
                      {`Slide ${String(idx + 1).padStart(2, '0')}`}
                    </div>
                    <div className="slide-title  text-left">
                      <strong className="mr-1">Title: </strong>
                      {slideItem.text}
                    </div>
                  </div>
                )}
                {/* Render buttons if they exist */}
                {slideItem.buttons && slideItem.buttons.length > 0 && (
                  <div className="button-container">
                    {slideItem.buttons.map((button) => (
                      <div
                        key={`button_${button.id}`}
                        className="slide-button relative flex p-1 border rounded-lg mb-1"
                      >
                        {button.buttonText}
                        {button.buttonUrl === '' && (
                          <Handle
                            type="source"
                            position={Position.Right}
                            id={`carousel-btn-${button.id}`}
                            className="!bg-blue-500"
                            style={Position.Left && { ...nodeStyle, top: 15, right: 5 }}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ));
    }
    return null;
  };

  return (
    <div
      className={`carousel-node drop-shadow-lg opacity-80 ${
        selected ? 'border-2 border-[#E047]' : ''
      }${isNodeValid ? '' : 'border-2 border-red-500'} `}
    >
      <div>
        <div className="carousel-node__heading">{NODE_TYPES[data?.heading]?.label}</div>

        <div className="carousel-node__content">{renderSlides()}</div>
      </div>

      <div className="p-2 text-gainsboroBlue text-right">Continue</div>
      <Handle
        type="target"
        position={Position.Left}
        id={`carousel-start-${id}`}
        className="!bg-blue-500"
        style={Position.Left && { ...nodeStyle, top: 18, left: -10 }}
      />
      <Handle
        type="source"
        position={Position.Right}
        id={`carousel-end-${id}`}
        className="!bg-blue-500"
        style={{ ...nodeStyle, top: 'calc(100% - 18px)', right: -10 }}
      />
    </div>
  );
}

CarouselNode.propTypes = {
  id: PropTypes.string,
  data: PropTypes.any,
  selected: PropTypes.bool,
};

export default CarouselNode;
