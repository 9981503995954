/* eslint-disable indent */
import React, { forwardRef, useState } from 'react';
import Select, { GroupBase } from 'react-select';
// eslint-disable-next-line import/no-unresolved
import { SelectComponents } from 'react-select/dist/declarations/src/components';

import { ISelectOption } from 'interfaces/component.interface';
import { TRequestMethod } from 'views/Settings/FlowBuilder/components/DrawerContents/APIRequestDrawer/interfaces';
import { reactSelectCustomStyles } from '../../styles/select';

export interface ReactSelectFixedProps {
  onChange?: (val: any, actionMeta: any) => void;
  onChangeInput?: (val: any, actionMeta: any) => void;
  required?: boolean;
  value?: ISelectOption;
  placeholder?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  elementId?: string;
  isClearable?: boolean;
  options: any;
  customStyles?: any;
  register?: any;
  inputRef?: any;
  id?: string;
  error?: any;
  isNotDisplaySvg?: boolean;
  isMulti?: boolean;
  menuIsOpen?: boolean;
  applyEmptyValidation?: boolean;
  isFormValid?: boolean;
  onMenuOpen?: () => void;
  components?: Partial<
    SelectComponents<
      {
        value: string | TRequestMethod;
        label: string;
      },
      false,
      GroupBase<{
        value: string | TRequestMethod;
        label: string;
      }>
    >
  >;
  formatOptionLabel?: ({ value, label, type }: ISelectOption) => JSX.Element;
}

/**
 * @fileOverview React Select Updated
 * Handles React Select as Normal
 * Prevents Form From Submitting if Required Prop Passed
 * @param {*} props
 */
const CustomSelect = forwardRef((props: ReactSelectFixedProps, ref: React.RefObject<any>) => {
  const {
    id,
    required,
    onChange,
    onChangeInput,
    isClearable,
    placeholder,
    isLoading,
    value,
    isDisabled,
    options,
    isMulti,
    customStyles = reactSelectCustomStyles,
    error,
    isNotDisplaySvg = false,
    menuIsOpen,
    elementId,
    register,
    inputRef,
    onMenuOpen,
    components,
    formatOptionLabel,
    applyEmptyValidation = true,
    isFormValid = true,
  } = props;

  const [touched, setTouched] = useState(false);

  const isInvalid = applyEmptyValidation && required && touched && (!value || !!error);

  return (
    <div>
      <Select
        menuIsOpen={menuIsOpen}
        onBlur={() => setTouched(true)}
        id={id}
        name={id}
        menuPortalTarget={document.body} // added because of z index issue in channel size
        menuPosition="fixed" // added because of z index issue in channel size
        className={`select-input focus:outline-none rounded-xl placeholder:text-base font-normal text-base w-full max-w-2xl ${
          isInvalid ? 'border-red-500' : ''
        }`}
        styles={
          error
            ? {
                ...customStyles,
                control: (provided: any, state: any) => ({
                  ...provided,
                  minHeight: 48,
                  fontSize: '20px',
                  borderRadius: 5,
                  borderColor: '#FF7171', // begoniaRed
                  fontFamily: 'Lato',
                }),
                dropdownIndicator: (provided: any) => ({
                  ...provided,
                  svg: {
                    display: isNotDisplaySvg ? 'none' : 'block',
                  },
                }),
              }
            : {
                ...customStyles,
                dropdownIndicator: (provided: any) => ({
                  ...provided,
                  svg: {
                    display: isNotDisplaySvg ? 'none' : 'block',
                  },
                }),
              }
        }
        components={
          components || {
            IndicatorSeparator: () => null,
          }
        }
        value={value}
        placeholder={placeholder}
        required={required}
        onChange={onChange}
        onInputChange={onChangeInput}
        isClearable={isClearable}
        options={options}
        isLoading={isLoading}
        isDisabled={isDisabled}
        isMulti={isMulti}
        ref={inputRef}
        onMenuOpen={onMenuOpen}
        formatOptionLabel={formatOptionLabel}
      />
      {error && (
        <label className="label relative p-y-2 mt-2" htmlFor={id}>
          <span className="label-text-alt absolute text-error">{error.message.toString()}</span>
        </label>
      )}
      {/* {isInvalid && (
        <label className="label relative p-y-2 mt-2" htmlFor={id}>
          <span className="label-text-alt absolute text-error">This field is required</span>
        </label>
      )} */}
    </div>
  );
});

export default CustomSelect;
