/**
 * @public
 * @enum {string}
 */
export enum BotLocaleStatus {
  Building = 'Building',
  Built = 'Built',
  Creating = 'Creating',
  Deleting = 'Deleting',
  Failed = 'Failed',
  Importing = 'Importing',
  NotBuilt = 'NotBuilt',
  Processing = 'Processing',
  ReadyExpressTesting = 'ReadyExpressTesting',
}
