import { getAccessToken } from './auth';

export const getSocketSettings = () => {
  const token = getAccessToken();

  return {
    path: '/socket.io',
    transports: ['websocket'],
    query: { Authorization: token },
  };
};
