import * as React from 'react';
import PropTypes from 'prop-types';

function FilterIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color = '#FB9C32', width = 45, height = 32 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 45 32"
    >
      <path
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="1.853"
        d="M11.004 16.75a.926.926 0 010-1.5L21.6 7.57a.926.926 0 011.087 0l10.596 7.68c.51.37.51 1.13 0 1.5l-10.596 7.68a.926.926 0 01-1.087 0l-10.596-7.68z"
      />
      <path stroke={color} strokeMiterlimit="10" strokeWidth="2" d="M34 16h3a2 2 0 012 2v6" />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M22 3v3"
      />
      <path stroke={color} strokeMiterlimit="10" strokeWidth="2" d="M11.096 16h-3a2 2 0 00-2 2v6" />
      <path
        fill={color}
        stroke={color}
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M6.497 26.886l1.936-1.937a.5.5 0 00-.353-.853H4.207a.5.5 0 00-.353.853l1.936 1.937a.5.5 0 00.707 0zM39.297 26.886l1.936-1.937a.5.5 0 00-.353-.853h-3.873a.5.5 0 00-.354.853l1.937 1.937a.5.5 0 00.707 0z"
      />
    </svg>
  );
}
FilterIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default FilterIcon;
