/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import UpdateCustomer from 'components/Icons/UpdateCustomer';
import GeneralButton from 'components/Buttons/GeneralButton';
import { PATHS } from 'routes/paths';
import { S3_ASSETS } from 'constants/assets';

function UserProfileCard(props) {
  const { contactData } = props;

  const navigate = useNavigate();

  return (
    <div>
      <div className="user-account__profile-card font-ptScanCap text-center bg-white p-4 mt-4 rounded-xl drop-shadow-md">
        <div className="avatar mb-3">
          <div className="w-20 rounded-full">
            <img
              src={contactData.profilePicture?.location || S3_ASSETS.CHAT_PREVIEW_PLACEHOLDER.url}
              alt="avatar"
            />
          </div>
        </div>

        {contactData?.firstName && contactData?.lastName ? (
          <p className="text-rasinBlack font-bold text-sm">
            {contactData.firstName} {contactData.lastName}
          </p>
        ) : (
          <p className="text-rasinBlack font-bold text-sm">Unsaved Contact</p>
        )}
        <p className="text-quickSilver font-semibold text-xs">
          {contactData?.primaryContactNo ? contactData?.primaryContactNo : ''}
        </p>
        <p className="text-quickSilver font-light lg:text-xs xs:text-xss mb-2 md:mb-4 whitespace-break-spaces">
          {contactData?.email ? contactData?.email : ''}
        </p>
        {contactData?._id && (
          <div className="w-fit m-auto">
            <GeneralButton
              text="Update Customer"
              btnColor="bg-spaceCadetBlue80"
              color="white"
              radius="2xl"
              btnHoverColor="hover:bg-spaceCadetBlue"
              icon={<UpdateCustomer />}
              textSize="xs"
              handleOnClick={() =>
                navigate(`${PATHS.CONTACT.FORM.url}?md=edit&i=${contactData?._id}`)
              }
            />
          </div>
        )}
      </div>
    </div>
  );
}

UserProfileCard.propTypes = {
  contactData: PropTypes.object,
};

export default UserProfileCard;
