/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

import CHATBOT_LOGO from 'assets/images/Logo_XL 1.png';

import SenderChatBubble from 'components/Chat/SenderBubble';
import ReceiverChatBubble from 'components/Chat/ReceiverBubble';
import { Home, InputAttachment, InputSend } from 'components/Icons';

import Header from './Header';

function WebWidget(props) {
  const { formData } = props;

  return (
    <motion.div className="tasa__widget-container">
      <Header messengerData={formData} />

      <div className="tasa__widget-chat-container">
        <ReceiverChatBubble
          text="&nbsp;"
          consecutiveRenderCount={1}
          contactName="You"
          textHighlighter="p-20"
          date="12:45:16 PM"
          isSample
        />
        <br />
        <SenderChatBubble
          text="&nbsp;"
          consecutiveRenderCount={1}
          user={{ name: 'Agent Name' }}
          textHighlighter="p-16"
          date="12:45:16 PM"
          isSample
        />
      </div>

      <div className="tasa__widget-msg-input-container">
        <div className="tasa__widget-action-button-container">
          <textarea
            rows={3}
            cols={50}
            className="tasa__widget-textarea"
            placeholder="Type your message..."
            style={{ resize: 'none' }}
          />

          <div className="tasa__widget-send-btn">
            <motion.div
              whileHover={{ rotate: [0, 45] }}
              transition={{ duration: 0.25 }}
              className="tasa__widget-btn"
            >
              <InputSend />
            </motion.div>
          </div>
        </div>

        <div className="tasa__widget-secondary-button-container">
          <div>
            <label htmlFor="fileInput">
              <motion.div className="tasa__widget-home-btn">
                <Home />
              </motion.div>
            </label>
          </div>
          <motion.div
            whileHover={{ rotate: [0, 45] }}
            transition={{ duration: 0.25 }}
            className="tasa__widget-attachment-btn"
          >
            <InputAttachment />
          </motion.div>
        </div>
      </div>

      <div
        className={`tasa__widget-footer ${
          formData?.privacyPolicyLink && formData.showPrivacyPolicyLink
            ? 'tasa__widget-footer__privacy-policy'
            : 'tasa__widget-footer__no-privacy-policy'
        }`}
      >
        <div className="tasa__widget-powered-by">
          Powered by
          <img className="tasa__widget-footer-logo" src={CHATBOT_LOGO} alt="Logo" />
        </div>

        {formData?.privacyPolicyLink && formData.showPrivacyPolicyLink && (
          <div
            className="tasa__widget-privacy-policy-link"
            onClick={() => {
              const url = formData?.privacyPolicyLink;
              window.open(url, '_blank');
            }}
          >
            Privacy Policy
          </div>
        )}
      </div>
    </motion.div>
  );
}

WebWidget.propTypes = {
  formData: PropTypes.object,
};

export default WebWidget;
