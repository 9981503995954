/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable implicit-arrow-linebreak */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { isUndefined, omitBy } from 'lodash';

import { CustomSelect, CustomTable, PrimaryButton, PromptModal } from 'components';
import { Export, Search } from 'components/Icons';
import { selectCustomStylesForFilters } from 'styles/select';
import { getDefaultValueForSelect } from 'utils/helpers';

import { LAST_UPDATED_AT_OPTIONS, RATING_OPTIONS } from './constants';
import ChatPreview from '../containers/ChatPreview';
import {
  ModalTypes,
  PromptTypes,
  modalDefaultOptions,
  promptDefaultOptions,
} from '../containers/utils';
import QAReviewModal from '../containers/Modals/reviewModal';
import ContactModal from '../containers/Modals/contactModal';

function ListPage(props) {
  const {
    handleFilterBy,
    listData,
    setTriggerChild,
    handlePageChange,
    handleResultsPerPageChange,
    urlSearchParams,
    setSearchByInput,
    searchByInput,
    handleColumnSorting,
    customLoading,
    channelsList,
    agentsList,
    contactsList,
    handleExport,
  } = props;

  const navigate = useNavigate();
  const location = useLocation();

  // show preview model
  const [visible, setVisible] = useState(false);

  // modals
  const [modalOptions, setModalOptions] = useState<any>(modalDefaultOptions);
  // prompt modal
  const [promptOptions, setPromptOptions] = useState(promptDefaultOptions);

  // data for preview model
  const [conversationData, setConversationData] = useState<any>();

  // use in the chat preview
  const [qaReviewList, setQAReviewList] = useState([]);

  useEffect(() => {
    const newPageId = urlSearchParams?.i;

    if (newPageId && urlSearchParams?.page) {
      setConversationData({ ...conversationData, _id: newPageId });
      replaceUrlParamsHandler({ page: urlSearchParams?.page, limit: 10, i: newPageId });

      setVisible(true);
    } else if (newPageId && !urlSearchParams?.page) {
      setConversationData({ ...conversationData, _id: newPageId });
      replaceUrlParamsHandler({ page: urlSearchParams?.page, limit: 10, i: newPageId });

      setVisible(true);
    }
  }, [urlSearchParams?.i]);

  const selectedCustomerRating = useMemo(
    () => getDefaultValueForSelect(urlSearchParams?.customerRating, RATING_OPTIONS, 'value'),
    [urlSearchParams?.customerRating],
  );

  const selectedAIRating = useMemo(
    () => getDefaultValueForSelect(urlSearchParams?.aiRating, RATING_OPTIONS, 'value'),
    [urlSearchParams?.aiRating],
  );

  const selectedCompletedAt = useMemo(
    () => getDefaultValueForSelect(urlSearchParams?.completedAt, LAST_UPDATED_AT_OPTIONS, 'value'),
    [urlSearchParams?.completedAt],
  );

  const selectedContact = useMemo(
    () => getDefaultValueForSelect(urlSearchParams?.contact, contactsList, 'value'),
    [urlSearchParams?.contact],
  );

  const selectedAgent = useMemo(
    () => getDefaultValueForSelect(urlSearchParams?.agent, agentsList, 'value'),
    [urlSearchParams?.agent],
  );

  const selectedChannel = useMemo(
    () => getDefaultValueForSelect(urlSearchParams?.channel, channelsList, 'value'),
    [urlSearchParams?.channel],
  );

  const replaceUrlParamsHandler = (params) => {
    const queryParams = omitBy(
      {
        page: params?.page || '1',
        limit: params.limit,
        contact: params.contact,
        order: params.order,
        searchBy: params.searchBy,
        country: params.country,
        orderBy: params.orderBy,
        i: params.i,
      },
      isUndefined,
    );

    const searchParams = new URLSearchParams(queryParams);
    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  const urlSearch = Object.fromEntries(new URLSearchParams(location.search));
  /**
   * Handles the preview contact model visibility and data
   * @param {IContact} data - Contact data from clicked row
   */
  const showPreview = (event, data: any) => {
    setVisible(true);
    setConversationData(data);
    replaceUrlParamsHandler({ page: urlSearch.page, limit: 10, i: data._id });
  };

  const closePreview = () => {
    setVisible(false);
    setConversationData(null);
    setQAReviewList([]);
    replaceUrlParamsHandler({ page: urlSearch.page, limit: 10 });
  };

  // used to get updated state for loading
  function loadDynamicLoadingProp() {
    if (promptOptions.type === PromptTypes.deleteReview) {
      return customLoading.deleteReview;
    }
    return false;
  }

  return (
    <div className="page">
      <div className="flex flex-wrap mb-2 w-full items-center justify-between">
        <div className="flex items-center">
          <div className="pr-5 h-12 items-center flex justify-start">
            <span className="font-ptScanCap text-lg font-semibold">Completed Chats</span>
          </div>
        </div>
        {/* Export Button */}
        <div>
          <PrimaryButton
            text="Export"
            btnColor="bg-spaceCadetBlue80"
            color="white"
            radius="2xl"
            btnHoverColor="hover:bg-spaceCadetBlue"
            icon={<Export />}
            disabled={customLoading.list}
            handleOnClick={handleExport}
          />
        </div>
      </div>

      <div className="border-t border-slate-300 mt-5" />
      <div className="container mt-4 py-1">
        <form className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-4">
          {/* Row 1 */}
          <div className="mb-2">
            <div className="relative block sm:flex items-center mb-2 sm:mb-0">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2 mt-1">
                <svg className="h-5 w-5 mb-2 fill-slate-300" viewBox="0 0 20 20">
                  <Search color="#475574" />
                </svg>
              </span>
              <input
                className="placeholder:text-sm placeholder:text-independenceBlue font-lato text-sm bg-white border border-gainsboroBlue focus:border-[#2684ff] rounded-lg h-11 py-2 pl-9 pr-3 shadow-sm focus:outline-none sm:text-sm w-full rounded-xl"
                placeholder="Search by Chat Content"
                type="text"
                name="search"
                onChange={(event) => setSearchByInput(event.target.value)}
                value={searchByInput || ''}
              />
            </div>
          </div>
          <div className="mb-4">
            <CustomSelect
              placeholder="Channels"
              customStyles={selectCustomStylesForFilters}
              options={channelsList}
              value={selectedChannel}
              isClearable
              onChange={(selected) => {
                const value = selected?.value;
                handleFilterBy(value, 'channel');
              }}
            />
          </div>
          <div className="mb-4">
            <CustomSelect
              placeholder="AI Rating"
              customStyles={selectCustomStylesForFilters}
              options={RATING_OPTIONS}
              value={selectedAIRating}
              isClearable
              onChange={(selected) => {
                const value = selected?.value;
                handleFilterBy(value, 'aiRating');
              }}
            />
          </div>
          <div className="mb-4">
            <CustomSelect
              placeholder="Customer Rating"
              customStyles={selectCustomStylesForFilters}
              options={RATING_OPTIONS}
              value={selectedCustomerRating}
              isClearable
              onChange={(selected) => {
                const value = selected?.value;
                handleFilterBy(value, 'customerRating');
              }}
            />
          </div>
          <div className="mb-4">
            <CustomSelect
              placeholder="Completed At"
              customStyles={selectCustomStylesForFilters}
              options={LAST_UPDATED_AT_OPTIONS}
              value={selectedCompletedAt}
              isClearable
              onChange={(selected) => {
                const value = selected?.value;
                handleFilterBy(value, 'completedAt');
              }}
            />
          </div>
          <div className="mb-4">
            <CustomSelect
              placeholder="Agent"
              customStyles={selectCustomStylesForFilters}
              options={agentsList}
              value={selectedAgent}
              isClearable
              onChange={(selected) => {
                const value = selected?.value;
                handleFilterBy(value, 'agent');
              }}
            />
          </div>
          <div className="mb-4">
            <CustomSelect
              placeholder="Contact"
              customStyles={selectCustomStylesForFilters}
              options={contactsList}
              value={selectedContact}
              isClearable
              onChange={(selected) => {
                const value = selected?.value;
                handleFilterBy(value, 'contact');
              }}
            />
          </div>
        </form>
      </div>

      <CustomTable
        tableData={listData}
        onPageChange={handlePageChange}
        handleResultsPerPageChange={handleResultsPerPageChange}
        onSort={handleColumnSorting}
        loading={{ list: customLoading.list }}
        isPaginate
        onManage={showPreview}
      />

      {/* Preview chat model */}
      <ChatPreview
        visible={visible}
        toggleHide={closePreview}
        conversationId={conversationData?._id}
        setModalOptions={setModalOptions}
        setPromptOptions={setPromptOptions}
        setQAReviewList={setQAReviewList}
        qaReviewList={qaReviewList}
      />

      {modalOptions.type === ModalTypes.QAReviewModal && modalOptions.show && (
        <QAReviewModal
          {...modalOptions}
          customLoading={customLoading}
          toggleModal={() => setModalOptions({ ...modalOptions, show: false })}
        />
      )}

      {modalOptions.type === ModalTypes.ContactModal && modalOptions.show && (
        <ContactModal
          {...modalOptions}
          setTriggerChild={setTriggerChild}
          toggleModal={() => setModalOptions({ ...modalOptions, show: false })}
        />
      )}

      {promptOptions.show && (
        <PromptModal
          onCancel={() => {
            setPromptOptions({ ...promptOptions, show: false });
          }}
          {...promptOptions}
          loading={loadDynamicLoadingProp()}
        />
      )}
    </div>
  );
}

ListPage.propTypes = {
  handleFilterBy: PropTypes.func,
  handlePageChange: PropTypes.func,
  setSearchByInput: PropTypes.func,
  searchByInput: PropTypes.string,
  handleColumnSorting: PropTypes.func,
  handleResultsPerPageChange: PropTypes.func,
  urlSearchParams: PropTypes.shape({
    page: PropTypes.any,
    lastUpdatedAt: PropTypes.string,
    sort: PropTypes.string,
    customerRating: PropTypes.string,
    aiRating: PropTypes.string,
    completedAt: PropTypes.string,
    channel: PropTypes.string,
    contact: PropTypes.string,
    agent: PropTypes.string,
    i: PropTypes.string,
  }),
  listData: PropTypes.shape({
    page: PropTypes.number,
  }),
  customLoading: PropTypes.any,
  channelsList: PropTypes.array,
  agentsList: PropTypes.array,
  contactsList: PropTypes.array,
  setTriggerChild: PropTypes.func,
  handleExport: PropTypes.func,
  URLSearchParams: PropTypes.string,
};

export default ListPage;
