import * as React from 'react';
import PropTypes from 'prop-types';

function AddCustomer(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color = '#fff', width = 24, height = 24 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        clipPath="url(#clip0_695_14)"
      >
        <path d="M8 7a4 4 0 108 0 4 4 0 00-8 0zM16 19h6M19 16v6M6 21v-2a4 4 0 014-4h4" />
      </g>
      <defs>
        <clipPath id="clip0_695_14">
          <path fill="#fff" d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
AddCustomer.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default AddCustomer;
