import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { CustomSelect, CustomTable, PrimaryButton } from 'components';
import { PATHS } from 'routes/paths';
import { AddClient, Export, Search } from 'components/Icons';
import { selectCustomStylesForFilters } from 'styles/select';
import { CLIENT_STATUS } from 'constants/client.const';
import { countries } from 'utils/helpers';

import { getCountrySelected, getSortBySelected, getStatusSelected } from './utils';

function ListPage(props) {
  const {
    handleFilterBy,
    clientListData,
    handleEdit,
    handlePageChange,
    handleResultsPerPageChange,
    urlSearchParams,
    setSearchByInput,
    searchByInput,
    handleExport,
    handleColumnSorting,
    customLoading,
  } = props;

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="page">
      <div className="mb-2 flex flex-row justify-between items-center">
        {/* Add Client Button */}
        <Link to={PATHS.CLIENT_MANAGEMENT.FORM.url}>
          <PrimaryButton
            text="Add Client"
            btnColor="bg-spaceCadetBlue80"
            color="white"
            radius="2xl"
            btnHoverColor="hover:bg-spaceCadetBlue"
            icon={<AddClient />}
          />
        </Link>
        {/* Export Button */}
        <div className="mb-4">
          <div className="mt-4 sm:mt-0">
            <PrimaryButton
              text="Export"
              btnColor="bg-spaceCadetBlue80"
              color="white"
              radius="2xl"
              btnHoverColor="hover:bg-spaceCadetBlue"
              icon={<Export />}
              handleOnClick={handleExport}
              disabled={customLoading.list}
            />
          </div>
        </div>
      </div>
      <div className="border-t border-slate-300 " />

      <div className="container mt-4 py-4">
        <form className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-4">
          {/* Row 1 */}
          <div className="mb-2">
            <div className="relative block sm:flex items-center mb-2 sm:mb-0">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2 mt-1">
                <svg className="h-5 w-5 mb-2 fill-slate-300" viewBox="0 0 20 20">
                  <Search color="#475574" />
                </svg>
              </span>
              <input
                className="placeholder:text-sm placeholder:text-independenceBlue font-lato text-sm bg-white border border-gainsboroBlue focus:border-[#2684ff] rounded-lg h-11 py-2 pl-9 pr-3 shadow-sm focus:outline-none sm:text-sm w-full rounded-xl"
                placeholder="Search by Company Name or Admin Email"
                type="text"
                name="search"
                onChange={(event) => setSearchByInput(event.target.value)}
                value={searchByInput || ''}
              />
            </div>
          </div>
          <div className="mb-4">
            <CustomSelect
              placeholder="Select a Country"
              customStyles={selectCustomStylesForFilters}
              options={countries}
              value={getCountrySelected(urlSearchParams?.country)}
              isClearable
              onChange={(selected) => {
                const status = selected?.value?.toLowerCase();
                handleFilterBy(status, 'country');
              }}
            />
          </div>
          <div className="mb-4">
            <CustomSelect
              placeholder="Account Status"
              customStyles={selectCustomStylesForFilters}
              options={CLIENT_STATUS}
              value={getStatusSelected(urlSearchParams?.status)}
              isClearable
              onChange={(selected) => {
                const status = selected?.value?.toLowerCase();
                handleFilterBy(status, 'status');
              }}
            />
          </div>
        </form>
      </div>

      <CustomTable
        tableData={clientListData}
        onPageChange={handlePageChange}
        handleResultsPerPageChange={handleResultsPerPageChange}
        handlers={{ onManage: handleEdit }}
        onSort={handleColumnSorting}
        loading={{ list: customLoading.list }}
        isPaginate
        handleEdit={handleEdit}
      />
    </div>
  );
}

ListPage.propTypes = {
  handleFilterBy: PropTypes.func,
  handlePageChange: PropTypes.func,
  setSearchByInput: PropTypes.func,
  handleExport: PropTypes.func,
  searchByInput: PropTypes.string,
  handleEdit: PropTypes.func,
  handleColumnSorting: PropTypes.func,
  handleResultsPerPageChange: PropTypes.func,
  urlSearchParams: PropTypes.shape({
    page: PropTypes.any,
    country: PropTypes.string,
    sort: PropTypes.string,
    status: PropTypes.string,
  }),
  clientListData: PropTypes.shape({
    page: PropTypes.number,
  }),
  customLoading: PropTypes.any,
};

export default ListPage;
