import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChannelAssignedToConversationNode } from 'interfaces/sockets.interface';

export type EventState = {
  newConversation: any;
  newChannelLinkToConversationAlerts: ChannelAssignedToConversationNode[];
  chatTaken: boolean;
};

const initialState: EventState = {
  newConversation: null,
  newChannelLinkToConversationAlerts: [],
  chatTaken: false,
};

const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    newConversationReceived(state, { payload }: PayloadAction<any>) {
      state.newConversation = payload;
    },
    clearNewConversation(state) {
      state.newConversation = null;
    },
    newConversationAlertToChannel(state, { payload }: PayloadAction<any>) {
      // state.newChannelLinkToConversationAlerts = [
      //   ...state.newChannelLinkToConversationAlerts,
      //   payload,
      // ];
    },
    takenTheChat(state) {
      state.chatTaken = true;
    },
  },
});

export const {
  newConversationReceived,
  clearNewConversation,
  newConversationAlertToChannel,
  takenTheChat,
} = eventSlice.actions;

export default eventSlice.reducer;
