/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useController, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import 'react-phone-number-input/style.css';

import { RootState } from 'slices';

import { createContact, getContactById, updateContact } from 'services/contact.service';
import { SALUTATIONS } from 'constants/contact.const';
import { apiErrorHandler } from 'api/handler';
import { updateURLWithSearchParams } from 'utils/helpers';
import { PATHS } from 'routes/paths';
import { createContactValidationSchema } from './validations/index';
import { mapDoc } from './utils';
import FormPage from './page';

const salutationOptions = Object.keys(SALUTATIONS).map((key) => ({
  label: SALUTATIONS[key],
  value: SALUTATIONS[key],
}));

function ContactForm() {
  const locationData = useSelector((state: RootState) => state.user.locationData);
  const navigate = useNavigate();

  // read query string in the url
  const [searchParams] = useSearchParams();

  const [formData, setFormData] = useState<any>({});

  // mode `view` or `edit`
  const pageType = searchParams.get('md');
  // contact object `_id`
  const pageId = searchParams.get('i');
  // if form mode is edit mode
  const [editMode, setEditMode] = useState(false);
  // form loading state
  const [customLoading, setCustomLoading] = useState({
    formSubmit: false,
    fetchForm: false,
  });

  const { register, handleSubmit, formState, control } = useForm({
    resolver: yupResolver(createContactValidationSchema),
    // sets default values for the form and update upon state change
    values: useMemo(() => {
      const defaultValues = {
        dateOfBirth: null,
        ...formData,
        'preferredContactMethod.email': formData?.preferredContactMethod?.email,
        'preferredContactMethod.whatsApp': formData?.preferredContactMethod?.whatsApp,
        'preferredContactMethod.phoneCall': formData?.preferredContactMethod?.phoneCall,
      };
      return defaultValues;
    }, [customLoading.fetchForm, formData]),
  });

  // special yup validation fields
  const { field: salutationField } = useController({ name: 'salutation', control });
  const { field: genderField } = useController({ name: 'gender', control });
  const { field: countryField } = useController({ name: 'country', control });

  const { value: selectedGender, onChange: onGenderChange, ...restGenderProps } = genderField;
  const { value: selectCountry, onChange: onCountryChange, ...restCountryProps } = countryField;
  const {
    value: selectedSalutation,
    onChange: onSalutationChange,
    ...restSalutationProps
  } = salutationField;

  useEffect(() => {
    if (!pageType) {
      updateURLWithSearchParams({ md: 'new' });
    }
    if (pageType && pageType === 'edit' && pageId) {
      setEditMode(true);
      fetchContactData(pageId);
    }
  }, []);

  // TODO At the moment this function not used
  // set default location based values for new form
  // useEffect(() => {
  //   if (!editMode && locationData?.country_code) {
  //     const { country_code } = locationData;
  //     setFormData((prevState: any) => ({
  //       ...prevState,
  //       country: country_code,
  //     }));
  //   }
  // }, [locationData]);

  // yup validation errors
  const { errors } = formState;

  /**
   * Fetch contact by id and set the form status
   * @param {string} id - Contact object _id
   */
  const fetchContactData = async (id: string) => {
    setCustomLoading((prevState) => ({ ...prevState, formSubmit: true, fetchForm: true }));
    try {
      const { data } = await getContactById(pageId);

      if (data?.node) {
        const formattedData = mapDoc(data.node);
        setFormData((prevState) => ({ ...prevState, ...formattedData }));
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: false, fetchForm: false }));
    }
  };

  /**
   * Form submission
   */
  const onSubmit = async () => {
    setCustomLoading((prevState) => ({ ...prevState, formSubmit: true }));

    try {
      if (!editMode) {
        const { data } = await createContact(formData);
        if (data) {
          toast.success('Successfully Created Contact');
          const urlParams = new URLSearchParams(window.location.search);
          urlParams.set('new', 'true');
          const newUrl = `${PATHS.CONTACT.VIEW.url}/?i=${data.node._id}&${urlParams.toString()}`;
          navigate(newUrl);
        }
      } else {
        const { data } = await updateContact(pageId, formData);
        if (data) {
          const formattedData = mapDoc(data.node);
          setFormData((prevState) => ({ ...prevState, ...formattedData }));
          toast.success('Successfully Updated Contact');
        }
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: false }));
    }
  };

  // handle select field change
  const handleSelectChange = (event, id) => {
    if (id === 'salutation') {
      onSalutationChange(event?.value);
    } else if (id === 'gender') {
      onGenderChange(event?.value);
    } else if (id === 'country') {
      onCountryChange(event?.value);
    }
    setFormData({
      ...formData,
      [id]: event?.value,
    });
  };

  // handle the input text change
  const handleInputChange = (event: { target: { value: string; id: string } }) => {
    const { id, value } = event.target;
    const result =
      id === 'firstName' || id === 'lastName' ? event.target.value.replace(/[^a-z]/gi, '') : value;

    setFormData({
      ...formData,
      [id]: result,
    });
  };

  // handle the checkbox
  const handlePreferredMethodChange = (event) => {
    const { id, checked } = event.target;
    // `preferredContactMethod.phoneCall` -> method = phoneCall
    const method = id.split('.')[1];

    let preferredContactMethod = formData?.preferredContactMethod || {};
    preferredContactMethod = { ...preferredContactMethod, [method]: checked };

    setFormData({
      ...formData,
      preferredContactMethod,
    });
  };

  // handle phone number change
  const handlePhoneNoChange = (id: string, value: string) => {
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  return (
    <FormPage
      formData={formData}
      salutationOptions={salutationOptions}
      errors={errors}
      editMode={editMode}
      register={register}
      control={control}
      locationData={locationData}
      handlePhoneNoChange={handlePhoneNoChange}
      handlePreferredMethodChange={handlePreferredMethodChange}
      handleInputChange={handleInputChange}
      handleSelectChange={handleSelectChange}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      restGenderProps={restGenderProps}
      restCountryProps={restCountryProps}
      restSalutationProps={restSalutationProps}
      customLoading={customLoading}
    />
  );
}

export default ContactForm;
