import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { RootState } from 'slices';
import {
  IFlowPayload,
  addNewFilterCondition,
  addNewSegmentToFilterCondition,
  deleteFilterConditionSegment,
  updateFilterCondition,
  updateFilterConditionSegment,
} from 'slices/flow';

import { ICondition, ISegment } from './interfaces';
import Condition from './components/Condition';
import NotCondition from './components/NotCondition';
import { CONDITION_TYPES, SEGMENTATION_METHODS, defaultConditionList } from './constants';

interface IFilterDrawerProps {
  nodeId: string;
  toggleDrawer: () => void;
}

function Breaker() {
  return (
    <div className="if-not">
      <span>if not: </span>
    </div>
  );
}

const FilterDrawer = forwardRef(({ nodeId, toggleDrawer }: IFilterDrawerProps, ref) => {
  const dispatch = useDispatch();

  const flowData = useSelector((state: RootState) => state.flow);

  const [conditionList, setConditionList] = useState<any>([]);

  useEffect(() => {
    if (flowData?.nodeConfigs.length > 0) {
      // get selected filter node data from flowData array: condition list
      const selectedNode = flowData.nodeConfigs.find((node) => node.nodeId === nodeId);
      if (selectedNode) {
        const { filterSettings } = selectedNode;
        setConditionList(filterSettings);
      }
    }
  }, [flowData?.nodeConfigs]);

  /**
   * Add new condition before `NOT condition upon `Add new condition` btn click
   */
  const handleAddNewCondition = () => {
    // create a new if condition
    dispatch(addNewFilterCondition({ id: nodeId }));
  };

  /**
   * Adds a new segment to a specific condition in the condition list.
   * @param {string} conditionId - The unique identifier of the condition
   * to which the segment will be added.
   * @returns {void}
   */
  const handleAddNewSegmentCriteria = (conditionId: string): void => {
    dispatch(addNewSegmentToFilterCondition({ nodeId, conditionId }));
  };

  /**
   * Handles the update operation of a condition
   * @param {string} conditionId - Condition id
   * @param {Partial<ICondition>} updatedConditionData - New condition data
   * @returns {void}
   */
  const handleUpdateCondition = (
    conditionId: string,
    updatedConditionData: Partial<ICondition>,
  ): void => {
    dispatch(updateFilterCondition({ nodeId, conditionId, updatedConditionData }));
  };

  /**
   * Updates a segment within a specific condition in the condition list.
   * @param {string} conditionId - The unique identifier of the condition containing the segment.
   * @param {string} segmentId - The unique identifier of the segment to update.
   * @param {Partial<ISegment>} updatedSegmentData - Updated segment data to replace
   * the existing segment.
   * @returns {void}
   */
  const handleUpdateSegmentCriteria = (
    conditionId: string,
    segmentId: string,
    updatedSegmentData: Partial<ISegment>,
  ): void => {
    dispatch(updateFilterConditionSegment({ nodeId, conditionId, segmentId, updatedSegmentData }));
  };

  /**
   * Delete a segment within a specific condition in the condition list.
   * @param {string} conditionId - The unique identifier of the condition containing the segment.
   * @param {string} segmentId - The unique identifier of the segment to update.
   * @returns {void}
   */
  const handleDeleteSegmentCriteria = (conditionId: string, segmentId: string): void => {
    dispatch(deleteFilterConditionSegment({ nodeId, conditionId, segmentId }));
  };

  /**
   * Apply btn handler
   */
  const handleSubmit = () => {
    toggleDrawer();
  };

  // Expose the handleSubmit function to the parent component using useImperativeHandle
  useImperativeHandle(ref, () => ({
    handleSubmit,
  }));

  return (
    <div className="filter-node-drawer">
      {/* render conditions */}
      {conditionList.map((condition: ICondition) => {
        if (condition?.conditionType === CONDITION_TYPES.IF) {
          return (
            <React.Fragment key={`${condition?._id}`}>
              <Condition
                key={`condition-${condition?._id}`}
                conditionId={condition?._id}
                segmentationMethod={condition?.segmentationMethod}
                handleUpdateCondition={handleUpdateCondition}
                segments={condition?.segments || []}
                handleAddNewSegmentCriteria={handleAddNewSegmentCriteria}
                handleUpdateSegmentCriteria={handleUpdateSegmentCriteria}
                handleDeleteSegmentCriteria={handleDeleteSegmentCriteria}
              />
              <Breaker key={`breaker-${condition?._id}`} />
            </React.Fragment>
          );
        }
        return <NotCondition key={`not-condition-${condition?._id}`} />;
      })}

      <button type="button" className="add-component__btn btn" onClick={handleAddNewCondition}>
        Add new condition
      </button>
    </div>
  );
});

FilterDrawer.propTypes = {
  nodeId: PropTypes.string,
  toggleDrawer: PropTypes.func,
};

export default FilterDrawer;
