import _ from 'lodash';
import { AxiosResponse } from 'axios';

import instance, { postAttachments } from 'api';
import { CONTACT } from 'api/apiEndpoints';
import UrlBuilder from 'api/urlQueryBuilder';

import { IContactListURLQueryParams } from 'views/Contact/List/interface';

/**
 * Fetch paginated and filtered contacts list data
 * @param {IClientListURLQueryParams} params - Url query params for contacts list data)
 * @returns {} - Contacts list data and pagination data
 */
export const fetchContactListData = (
  params: IContactListURLQueryParams,
): Promise<AxiosResponse<any, any>> => {
  const {
    page = 1,
    limit = 10,
    sort = 'createdAt',
    order = -1,
    country,
    searchBy,
    select,
    lastUpdatedAt,
  } = params;

  // creates a new object with only the non-null, non-undefined values from the extracted variables
  const queryParams = _.pickBy({
    page,
    limit,
    sort,
    order,
    country,
    searchBy,
    select,
    lastUpdatedAt,
  });
  const url = UrlBuilder()
    .setUrl(CONTACT.GET_CONTACTS_ALL)
    .setQueryParams({ ...queryParams })
    .build();
  return instance.get(url);
};

/**
 * Create contact
 * @param data - form data
 * @returns contact details
 */
export function createContact(data) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(CONTACT.CREATE_CONTACT).build();
  return instance.post(url, data);
}

/**
 * Get contact by id
 * @param {string} id - Contact Id
 * @returns contact details
 */
export function getContactById(id: string) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(CONTACT.GET_CONTACT_BY_ID).setPathParams({ id }).build();
  return instance.get(url);
}

/**
 * Update contact
 * @param data - form data
 * @returns contact details
 */
export function updateContact(id: string, data) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(CONTACT.UPDATE_CONTACT).setPathParams({ id }).build();
  return instance.patch(url, data);
}

/**
 * Upload profile picture
 * @returns {Promise}
 */
export const uploadProfilePicture = (id: string, data: any) => {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(CONTACT.UPLOAD_PROFILE_PICTURE).setPathParams({ id }).build();
  return postAttachments(url, data);
};
