import { PERMISSIONS } from 'constants/permissions';

export const defaultTableHeaders = [
  {
    key: 'companyName',
    value: 'Company Name',
    sortingEnabled: true,
    type: 'clientAvatar',
    imageProp: 'companyLogoLocation',
  },
  {
    key: 'adminEmail',
    value: 'Admin Email',
    sortingEnabled: true,
  },
  {
    key: 'primaryContactName',
    value: 'Primary Contact Name',
    sortingEnabled: true,
  },
  {
    key: 'primaryContactNo',
    value: 'Primary Contact Number',
    sortingEnabled: true,
  },
  {
    key: 'country',
    value: 'Country',
    sortingEnabled: true,
  },
  {
    key: 'createdAt',
    value: 'Created At',
    type: 'dateTime',
    sortingEnabled: true,
  },
  {
    key: 'tier',
    value: 'Tier',
  },
  {
    key: 'status',
    value: 'Status',
    type: 'clientStatus',
    tdStyles: '',
    params: {
      active: {
        label: 'ACTIVE',
        color: 'white',
        bgColor: 'bg-northTexasGreen',
        borderColor: 'none',
      },
      inactive: {
        label: 'INACTIVE',
        color: 'white',
        bgColor: 'bg-statusRed',
        borderColor: 'none',
      },
      unapproved: {
        label: 'UNAPPROVED',
        color: 'rasinBlack',
        bgColor: 'bg-lotionLightGray',
        borderColor: 'gainsboroBlue',
      },
    },
  },
  {
    key: 'manage',
    value: 'Manage',
    type: 'manageBtn',
    permissions: [
      PERMISSIONS.CLIENT_MANAGEMENT.CLIENTS.EDIT_CLIENT,
      PERMISSIONS.CLIENT_MANAGEMENT.CLIENTS.VIEW_CLIENT,
    ],
  },
];

export const tableDefaultValues = {
  totalDocs: 0,
  totalPages: 0,
  limit: 10,
  page: 1,
  headers: defaultTableHeaders,
  docs: [],
  sort: null,
  order: -1,
  searchBy: null,
};

// create an array of options for sort by select
export const orderByOptions = defaultTableHeaders.map((header) => ({
  value: header.key,
  label: header.value,
}));

export const excelHeaders = [
  {
    key: 'companyName',
    value: 'Company Name',
  },
  {
    key: 'adminEmail',
    value: 'Admin Email',
  },
  {
    key: 'primaryContactName',
    value: 'Primary Contact Name',
  },
  {
    key: 'primaryContactNo',
    value: 'Primary Contact Number',
  },
  {
    key: 'country',
    value: 'Country',
  },
  {
    key: 'createdAt',
    value: 'Created At',
    type: 'dateTime',
  },
  {
    key: 'tier',
    value: 'Tier',
  },
  {
    key: 'status',
    value: 'Status',
    type: 'statusLabel',
    params: {
      active: {
        label: 'ACTIVE',
        color: 'white',
        bgColor: 'bg-northTexasGreen',
        borderColor: 'none',
      },
      inactive: {
        label: 'INACTIVE',
        color: 'white',
        bgColor: 'bg-statusRed',
        borderColor: 'none',
      },
      unapproved: {
        label: 'UNAPPROVED',
        color: 'rasinBlack',
        bgColor: 'bg-gainsboroBlue',
        borderColor: 'lotionLightGray',
      },
    },
  },
];
