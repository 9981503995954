/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

// #region assets | components
import { CustomSelect, CustomTable, PrimaryButton, TransactionTable } from 'components';
import {
  AddCase,
  Back,
  ChatIcon,
  ChevronDown,
  EnvelopIcon,
  NewCase,
  NoteIcon,
  NoteReadIcon,
  Search,
} from 'components/Icons';

// #region other imports
import { NOTE_STATUS } from 'constants/note';
import { PATHS } from 'routes/paths';
import { selectCustomStylesForFilters } from 'styles/select';
import Transaction from 'components/Icons/Transaction';

import { COLLECTIONS } from 'constants/db';
import ChatPreview from 'views/Inbox/BotChat/List/containers/ChatPreview';
import GeneralButton from 'components/Buttons/GeneralButton';
import AddNoteCard from '../components/NoteCard/AddNoteCard';
import ContactCard from '../components/ContactView/ContactCard';
import ContactDetailCard from '../components/ContactView/ContactDetailCard/ContactDetailCard';
import { CONVERSATION_STATUS, defaultTransactionTableHeaders } from './constants';

function ContactViewPage(props) {
  const {
    noteData,
    isNoteVisible,
    casesListData,
    chatListData,
    noteListData,
    urlSearchParams,
    activeTab,
    contactDetails,
    contactStatusOptions,
    customLoading,
    showNote,
    handleTabChange,
    setSearchByInput,
    handleFilterBy,
    handlePageChange,
    handleResultsPerPageChange,
    setIsNoteVisible,
    handleManageCase,
    handleSendEmail,
    dropDownOpen,
    handleDropDownChange,
    setTriggerChild,
    triggerChild,
    viewConversation,
    closePreview,
    visible,
    botChatData,
    searchByInput,
  } = props;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const newContact = searchParams.get('new');
  const pageId = searchParams.get('i');

  const handleBackNavigation = () => {
    if (newContact === 'true' && pageId) {
      navigate(`${PATHS.CONTACT.FORM.url}?md=edit&i=${pageId}`);
    } else {
      navigate(PATHS.CONTACT.LIST.url);
    }
  };

  return (
    <div className="contact-view__container">
      <div className="contact-view__content grid lg:grid-cols-3">
        {/* Contact detail column */}
        <div className="detail__container p-4 mr-2">
          <ContactCard contactDetails={contactDetails} isActionButtonsVisible={false} />
          <ContactDetailCard contactDetails={contactDetails} isPreviewContactBtnVisible={false} />
        </div>

        <div className="list-view__container lg:col-span-2">
          <div className="sm:flex">
            <div className="list-view_main__btns sm:flex">
              <div className="lg:w-auto">
                <PrimaryButton
                  id="newCase"
                  text="New Case"
                  btnColor="bg-aliceBlue"
                  className="btn__icon-color"
                  btnHoverColor="hover:bg-spaceCadetBlue"
                  radius="md"
                  textSize="xs"
                  icon={<NewCase color="#475574" />}
                  handleOnClick={() => navigate(PATHS.CASES_MANAGEMENT.FORM.url)}
                />
              </div>
              <div className="lg:w-auto">
                <PrimaryButton
                  id="sendMail"
                  text="Send Mail"
                  btnColor="bg-aliceBlue"
                  className="btns__icon-color"
                  btnHoverColor="hover:bg-spaceCadetBlue"
                  radius="md"
                  textSize="xs"
                  color="independenceBlue"
                  icon={<EnvelopIcon color="#475574" />}
                  handleOnClick={() => {
                    handleSendEmail();
                  }}
                />
              </div>
              <div className="lg:w-auto">
                <PrimaryButton
                  id="addNote"
                  text="Add Note"
                  btnColor="bg-aliceBlue"
                  className="btns__icon-color"
                  btnHoverColor="hover:bg-spaceCadetBlue"
                  radius="md"
                  textSize="xs"
                  color="independenceBlue"
                  icon={<NoteIcon color="#475574" />}
                  handleOnClick={(e) => showNote(e, { contactId: urlSearchParams?.id })}
                />
              </div>
            </div>
            <div className="ml-auto">
              <GeneralButton
                text="Back"
                btnColor="bg-spaceCadetBlue80"
                color="white"
                radius="2xl"
                textSize="sm"
                btnHoverColor="hover:bg-spaceCadetBlue"
                icon={<Back />}
                handleOnClick={handleBackNavigation}
              />
            </div>
          </div>

          {/* Tab List view */}
          <div className="border drop-shadow bg-white rounded-xl w-82 mt-4">
            <div className="sm:hidden tab__dropdown dropdown  p-2 bg-brightGray w-full">
              <label
                tabIndex={0}
                className="btn m-1 flex justify-center cursor-pointer text-white bg-spaceCadetBlue hover:bg-spaceCadetBlue"
                onClick={handleDropDownChange}
              >
                <span className="mr-auto">{activeTab}</span>
                <ChevronDown color="#fff" className="mt-1" />
              </label>
              <ul
                tabIndex={0}
                className={`dropdown-content z-[1] menu p-0 shadow-md bg-base-100 w-full rounded-md ${
                  dropDownOpen ? '' : 'hidden'
                }`}
              >
                <div>
                  <div className="menu_top_content" />

                  <motion.div
                    className="menu_item flex cursor-pointer"
                    onClick={() => handleTabChange('cases')}
                  >
                    <motion.span whileHover={{ scale: 1.03 }}>Cases</motion.span>
                  </motion.div>

                  <motion.div
                    className="menu_item flex cursor-pointer"
                    onClick={() => handleTabChange('chats')}
                  >
                    <motion.span whileHover={{ scale: 1.03 }}>Chats</motion.span>
                  </motion.div>

                  <motion.div
                    className="menu_item flex cursor-pointer"
                    onClick={() => handleTabChange('notes')}
                  >
                    <motion.span whileHover={{ scale: 1.03 }}>Notes</motion.span>
                  </motion.div>

                  <motion.div
                    className="menu_item flex cursor-pointer"
                    onClick={() => handleTabChange('transactions')}
                  >
                    <motion.span whileHover={{ scale: 1.03 }}>Transactions</motion.span>
                  </motion.div>

                  <div className="menu_bottom_content" />
                </div>
              </ul>
            </div>

            <div className="sm:block flex flex-wrap border border-gainsboroBlue drop-shadow h-auto p-2 items-center justify-between rounded-t-lg bg-brightGray">
              <div className="tabs gap-x-2 sm:gap-y-1">
                {/* Cases tab */}
                <PrimaryButton
                  id="cases"
                  text="Cases"
                  btnColor="bg-aliceBlue"
                  btnHoverColor="hover:bg-spaceCadetBlue"
                  textSize="xs"
                  className={`tab btns__icon-color w-screen border ${
                    activeTab === 'cases' ? 'tab-active' : ''
                  }  border-gainsboroBlue rounded hover:text-white srg:w-auto`}
                  color="independenceBlue"
                  icon={<AddCase color={activeTab === 'cases' ? 'white' : '#475574'} />}
                  handleOnClick={() => handleTabChange('cases')}
                />

                {/* Cases tab */}
                <PrimaryButton
                  id="chats"
                  text="Chats"
                  btnColor="bg-aliceBlue"
                  btnHoverColor="hover:bg-spaceCadetBlue"
                  textSize="xs"
                  className={`tab btns__icon-color w-screen ${
                    activeTab === 'chats' ? 'tab-active' : ''
                  } border border-gainsboroBlue rounded hover:text-white srg:w-auto`}
                  color="independenceBlue"
                  icon={<ChatIcon color={activeTab === 'chats' ? 'white' : '#475574'} />}
                  handleOnClick={() => handleTabChange('chats')}
                />

                {/* Notes tab */}
                <PrimaryButton
                  id="notes"
                  text="Notes"
                  btnColor="bg-aliceBlue"
                  btnHoverColor="hover:bg-spaceCadetBlue"
                  textSize="xs"
                  className={`tab btns__icon-color w-screen ${
                    activeTab === 'notes' ? 'tab-active' : ''
                  } border border-gainsboroBlue rounded hover:text-white srg:w-auto`}
                  radius="sm"
                  color="independenceBlue"
                  icon={<NoteReadIcon color={activeTab === 'notes' ? 'white' : '#475574'} />}
                  handleOnClick={() => handleTabChange('notes')}
                />

                {/* Transactions tab */}
                <PrimaryButton
                  id="transactions"
                  text="Transactions"
                  btnColor="bg-aliceBlue"
                  btnHoverColor="hover:bg-spaceCadetBlue"
                  textSize="xs"
                  className={`tab btns__icon-color w-screen border ${
                    activeTab === 'transactions' ? 'tab-active' : ''
                  }  border-gainsboroBlue rounded hover:text-white srg:w-auto`}
                  radius="sm"
                  color="independenceBlue"
                  icon={<Transaction color={activeTab === 'transactions' ? 'white' : '#475574'} />}
                  handleOnClick={() => handleTabChange('transactions')}
                />
              </div>
            </div>

            {urlSearchParams?.tab !== 'transactions' && (
              <div className="contact-view__filters">
                <div className="flex flex-wrap gap-2 md:w-5/6 lg:w-3/4">
                  {/* Search Input */}
                  <div className="flex-auto relative block sm:col-span-2 col-span-1">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                      <svg className="h-5 w-5 mb-2 fill-slate-300" viewBox="0 0 20 20">
                        <Search color="#475574" />
                      </svg>
                    </span>
                    <input
                      className="sm:placeholder:text-sm placeholder:text-independenceBlue font-lato text-sm block bg-white border border-gainsboroBlue rounded-lg h-11 py-2 pl-9 pr-3 shadow-sm focus:border-[#2684ff] focus:outline-none sm:text-sm w-full"
                      placeholder={
                        urlSearchParams?.tab === 'cases'
                          ? 'Search by Case ID, Subject'
                          : urlSearchParams?.tab === 'chats'
                          ? 'Chat ID, Chat content'
                          : 'Note ID, Subject or Description'
                      }
                      type="text"
                      name="search"
                      onChange={(event) => setSearchByInput(event.target.value)}
                      value={searchByInput || ''}
                    />
                  </div>

                  {/* Status Select */}
                  <div className="md:w-48">
                    <CustomSelect
                      placeholder="Select Status"
                      customStyles={selectCustomStylesForFilters}
                      options={
                        urlSearchParams?.tab === 'cases'
                          ? contactStatusOptions
                          : urlSearchParams?.tab === 'notes'
                          ? NOTE_STATUS
                          : urlSearchParams?.tab === 'chats'
                          ? CONVERSATION_STATUS
                          : null
                      }
                      value={NOTE_STATUS.find((option) => option.value === urlSearchParams?.status)}
                      isClearable
                      onChange={(selected) => {
                        const status = selected?.value;
                        handleFilterBy(status, 'status');
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            {/* List view */}
            <div className="contact-view__tables">
              {/* Cases table */}
              {urlSearchParams?.tab === 'cases' && (
                <CustomTable
                  tableData={casesListData}
                  onPageChange={handlePageChange}
                  handleResultsPerPageChange={handleResultsPerPageChange}
                  isPaginate
                  loading={{ list: customLoading.list }}
                  onManage={handleManageCase}
                />
              )}

              {/* Chat table */}
              {urlSearchParams?.tab === 'chats' && (
                <div className="">
                  <CustomTable
                    tableData={chatListData}
                    onPageChange={handlePageChange}
                    handleResultsPerPageChange={handleResultsPerPageChange}
                    onManage={viewConversation}
                    isPaginate
                    loading={{ list: customLoading.list }}
                  />
                </div>
              )}
              {/* Notes table */}
              {urlSearchParams?.tab === 'notes' && (
                <div className="">
                  <CustomTable
                    tableData={noteListData}
                    onPageChange={handlePageChange}
                    handleResultsPerPageChange={handleResultsPerPageChange}
                    onManage={showNote}
                    isPaginate
                    loading={{ list: customLoading.list }}
                  />
                </div>
              )}

              {/* Transaction table */}
              {urlSearchParams?.tab === 'transactions' && (
                <TransactionTable
                  recordId={contactDetails?._id}
                  collection={COLLECTIONS.Contact}
                  transactionTableHeaders={defaultTransactionTableHeaders}
                  isTitleVisible={false}
                  triggerChild={triggerChild?.transactions}
                />
              )}

              {/* // Note drawer */}
              <AddNoteCard
                isNoteVisible={isNoteVisible}
                setIsNoteVisible={setIsNoteVisible}
                noteId={noteData?._id}
                contactId={contactDetails?._id}
                showNote={showNote}
                setTriggerChild={setTriggerChild}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Preview chat model */}
      <ChatPreview visible={visible} toggleHide={closePreview} conversationData={botChatData} />
    </div>
  );
}

ContactViewPage.propTypes = {
  noteData: PropTypes.object,
  casesListData: PropTypes.shape({
    totalDocs: PropTypes.number,
    totalPages: PropTypes.number,
    limit: PropTypes.number,
    page: PropTypes.number,
    headers: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
        type: PropTypes.string,
        params: PropTypes.object,
      }),
    ),
    docs: PropTypes.arrayOf(PropTypes.object),
    sort: PropTypes.string,
    order: PropTypes.number,
    searchBy: PropTypes.string,
  }),
  chatListData: PropTypes.shape({
    totalDocs: PropTypes.number,
    totalPages: PropTypes.number,
    limit: PropTypes.number,
    page: PropTypes.number,
    headers: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
        type: PropTypes.string,
        params: PropTypes.object,
      }),
    ),
    docs: PropTypes.arrayOf(PropTypes.object),
    sort: PropTypes.string,
    order: PropTypes.number,
    searchBy: PropTypes.string,
  }),
  noteListData: PropTypes.shape({
    totalDocs: PropTypes.number,
    totalPages: PropTypes.number,
    limit: PropTypes.number,
    page: PropTypes.number,
    headers: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
        type: PropTypes.string,
        params: PropTypes.object,
      }),
    ),
    docs: PropTypes.arrayOf(PropTypes.object),
    sort: PropTypes.string,
    order: PropTypes.number,
    searchBy: PropTypes.string,
  }),
  transactionListData: PropTypes.shape({
    totalDocs: PropTypes.number,
    totalPages: PropTypes.number,
    limit: PropTypes.number,
    page: PropTypes.number,
    headers: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
        type: PropTypes.string,
        params: PropTypes.object,
      }),
    ),
    docs: PropTypes.arrayOf(PropTypes.object),
    sort: PropTypes.string,
    order: PropTypes.number,
    searchBy: PropTypes.string,
  }),
  urlSearchParams: PropTypes.shape({
    page: PropTypes.string,
    sort: PropTypes.string,
    status: PropTypes.string,
    searchBy: PropTypes.string,
    tab: PropTypes.string,
    id: PropTypes.string,
  }),
  contactDetails: PropTypes.object,
  contactStatusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  activeTab: PropTypes.string,
  customLoading: PropTypes.any,
  isNoteVisible: PropTypes.bool,
  dropDownOpen: PropTypes.bool,
  showNote: PropTypes.func,
  handleTabChange: PropTypes.func,
  setSearchByInput: PropTypes.func,
  handleFilterBy: PropTypes.func,
  handlePageChange: PropTypes.func,
  handleResultsPerPageChange: PropTypes.func,
  setIsNoteVisible: PropTypes.func,
  handleManageCase: PropTypes.func,
  handleSendEmail: PropTypes.func,
  handleDropDownChange: PropTypes.func,
  setTriggerChild: PropTypes.func,
  triggerChild: PropTypes.object,
  viewConversation: PropTypes.func,
  closePreview: PropTypes.func,
  visible: PropTypes.bool,
  botChatData: PropTypes.any,
  searchByInput: PropTypes.string,
};

export default ContactViewPage;
