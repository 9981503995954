import * as React from 'react';
import PropTypes from 'prop-types';

function Hide(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color = '#000', width = 33, height = 33 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 33 33"
    >
      <g
        stroke="#192A51"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        clipPath="url(#clip0_2433_2267)"
      >
        <path d="M28.875 12.375c-3.3 3.667-7.425 5.5-12.375 5.5s-9.075-1.833-12.375-5.5M4.125 20.625L7.563 15.4M28.875 20.592L25.448 15.4M12.375 23.375l.688-5.5M20.625 23.375l-.688-5.5" />
      </g>
      <defs>
        <clipPath id="clip0_2433_2267">
          <path fill="#fff" d="M0 0H33V33H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
Hide.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Hide;
