import * as React from 'react';
import PropTypes from 'prop-types';

function Active(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color, width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 21 20">
      <g
        stroke="#AAA"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        clipPath="url(#clip0_223_34)"
      >
        <path d="M3.336 17.5V6.667a2.5 2.5 0 012.5-2.5h8.333a2.5 2.5 0 012.5 2.5v5a2.5 2.5 0 01-2.5 2.5h-7.5L3.336 17.5zM10.002 9.167v.008M6.67 9.167v.008M13.336 9.167v.008" />
      </g>
      <defs>
        <clipPath id="clip0_223_34">
          <path fill="#fff" d="M0 0H20V20H0z" transform="translate(.002)" />
        </clipPath>
      </defs>
    </svg>
  );
}
Active.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Active;
