import React from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';

import { PrimaryButton } from 'components';
import { Back } from 'components/Icons';
import { PATHS } from 'routes/paths';
import moment from 'moment';

function FormEditHeader(props) {
  const { formData } = props;

  // styles for severity labels
  const severityStyles = {
    high: {
      className: 'bg-begoniaRed',
      text: 'HIGH',
    },
    medium: {
      className: 'bg-gargoyleGasYellow',
      text: 'MEDIUM',
    },
    critical: {
      className: 'bg-coralRed',
      text: 'CRITICAL',
    },
    low: {
      className: 'bg-chineseSilver',
      text: 'LOW',
    },
  };

  // styles for status labels
  const statusStyles = {
    open: {
      className: 'bg-lotionLightGray border border-gainsboroBlue text-black',
      text: 'OPEN',
    },
    completed: {
      className: 'bg-northTexasGreen text-white',
      text: 'COMPLETED',
    },
    deleted: {
      className: 'bg-coralRed text-white',
      text: 'DELETED',
    },
    closed: {
      className: 'bg-coralRed text-white',
      text: 'CLOSED',
    },
  };

  // data for severity and status
  const severity = formData?.severity;
  const status = formData?.status;

  const severityStyle = severityStyles[severity] || {};
  const statusStyle = statusStyles[status] || {};

  return (
    <div className="client-account__userHeader flex items-center justify-between mb-4">
      <div className="flex items-center">
        <div className="client-account__details items-center">
          <div className="flex">
            <h2 className="text-spaceCadetBlue font-bold font-ptScanCap text-base pb-2 srg:text-xl pr-5">
              View Case
            </h2>
            <div className="flex space-x-2">
              <div
                className={`flex items-center justify-center rounded-full w-[122px] h-7  ${
                  statusStyle.text === 'open' ? 'text-white' : 'text-black'
                } font-lato text-sm font-bold ${statusStyle.className}`}
              >
                {statusStyle.text}
              </div>

              <div
                className={`flex items-center justify-center rounded-full w-[122px] h-7 text-white font-lato text-sm font-bold ${severityStyle.className}`}
              >
                {severityStyle.text}
              </div>
            </div>
          </div>

          <div className="flex flex-wrap gap-x-4 items-center">
            <p className="text-spaceCadetBlue font-normal font-lato text-base rg:text-lg">
              Case ID : {formData?.sequenceId}
            </p>
            {formData.createdAt && (
              <p className="text-rasinBlack font-normal font-lato text-sm rg:text-base">
                Created At :{' '}
                {moment(formData?.createdAt, 'YYYY-MM-DD HH:mm:ss').format(
                  'YYYY-MM-DD - hh:mm:ss A',
                )}
              </p>
            )}
            {formData.updatedAt && (
              <p className="text-rasinBlack font-normal font-lato text-sm rg:text-base">
                Last Updated :{' '}
                {moment(formData?.updatedAt, 'YYYY-MM-DD HH:mm:ss').format(
                  'YYYY-MM-DD - hh:mm:ss A',
                )}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

FormEditHeader.propTypes = {
  formData: PropTypes.any,
};

export default FormEditHeader;
