import React from 'react';
import PropTypes from 'prop-types';

import GeneralButton from 'components/Buttons/GeneralButton';
import { Complete } from 'components/Icons';

function CompleteChatBubble(props: any) {
  const { message, handleCompleteMsg, conversationData } = props;

  let modifiedMessage = message;

  if (!conversationData?.isCompleted) {
    modifiedMessage = `${message} Click below button to complete the Chat.`;
  }

  return (
    <div className="m-1 max-w-sm flex justify-start">
      <div className="text-sm font-ptScanCap bg-culturedGray p-3 max-w-fit rounded-tr-xl rounded-bl-2xl rounded-br-2xl  border border-[#C7C7C7]">
        <span className="text-spaceCadetBlue">{modifiedMessage}</span>
        <div className="mt-4 -mb-4">
          {!conversationData?.isCompleted && (
            <GeneralButton
              text="Complete"
              btnColor="bg-spaceCadetBlue80"
              color="white"
              radius="2xl"
              textSize="xs"
              btnHoverColor="hover:bg-spaceCadetBlue"
              handleOnClick={() => handleCompleteMsg()}
              icon={<Complete />}
              disabled={conversationData?.isCompleted}
            />
          )}
        </div>
        {!conversationData?.isCompleted && <br />}
      </div>
    </div>
  );
}

CompleteChatBubble.propTypes = {
  message: PropTypes.string,
  handleCompleteMsg: PropTypes.func,
  conversationData: PropTypes.object,
};

export default CompleteChatBubble;
