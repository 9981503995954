import * as React from 'react';
import PropTypes from 'prop-types';

function Home(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color = '#475574', width = 24, height = 24 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2749_934)">
        <path
          d="M5.6751 12H3.7251L12.5001 3L21.2751 12H19.3251"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.67505 12V19C5.67505 19.5304 5.88049 20.0391 6.24619 20.4142C6.61189 20.7893 7.10788 21 7.62505 21H17.375C17.8922 21 18.3882 20.7893 18.7539 20.4142C19.1196 20.0391 19.325 19.5304 19.325 19V12"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.55 12H14.45V16H10.55V12Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2749_934">
          <rect width="23.4" height="24" fill="white" transform="translate(0.800049)" />
        </clipPath>
      </defs>
    </svg>
  );
}
Home.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Home;
