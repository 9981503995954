import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isUndefined, omitBy } from 'lodash';
import { useDebounce } from 'use-debounce';

import { ISelectOption } from 'interfaces/component.interface';
import { fetchUserListData } from 'services/user.service';
import { fetchClientListData } from 'services/client.service';
import { apiErrorHandler } from 'api/handler';
import { fetchRolesListData } from 'services/roles.service';
import { PATHS } from 'routes/paths';

import { getUserType } from 'utils/auth';
import { defaultListViewData } from './constants';
import ListPage from './page';
import { IUserListURLQueryParams } from './interface';
import { handleExportExcel, mapListViewDocs } from './utils';

/**
 * UserAccountListView component displays the user account list.
 * It provides filtering, sorting, and pagination functionality.
 *
 * @returns JSX element representing the user account list view.
 */
function UserAccountListView() {
  const location = useLocation();
  const navigate = useNavigate();

  const [searchByInput, setSearchByInput] = React.useState();
  const [debouncedSearchBy] = useDebounce(searchByInput, 500);

  const [urlSearchParams, setUrlSearchParams] = useState(null); //  URL search parameters
  const [customLoading, setCustomLoading] = useState({ list: false, roles: false });

  const [clientsOptions, setClientsOptions] = useState([]);
  const [roles, setRoles] = useState([]);
  const [userListData, setUserListData] = useState(defaultListViewData);
  // ---------------------------------------------
  const [loginUserType, setLoginUserType] = useState(false);

  useEffect(() => {
    checkLoginUserType();
    getAllClients();
  }, []);

  useEffect(() => {
    getAllRoles();
  }, [urlSearchParams?.userType, urlSearchParams?.clientId]);

  // effect to initialize URL search parameters from location
  useEffect(() => {
    const urlSearch = Object.fromEntries(new URLSearchParams(location.search));
    if (urlSearch && urlSearch.page) {
      replaceUrlParamsHandler({ ...urlSearchParams, ...urlSearch });
    } else {
      replaceUrlParamsHandler({ page: 1, limit: 10 });
    }
  }, []);

  // effect to fetch user list data when URL search parameters change
  useEffect(() => {
    if (urlSearchParams) {
      getUserListData({
        ...userListData,
        ...urlSearchParams,
      });
    }
  }, [urlSearchParams]);

  useEffect(() => {
    if (debouncedSearchBy || debouncedSearchBy === '') {
      handleFilterBy(debouncedSearchBy, 'searchBy');
    }
  }, [debouncedSearchBy]);

  /**
   * Checks the user type stored in encrypted local storage and sets the
   * login user type accordingly.
   * @async
   */
  // !TODO: FIXME - Code, State and Naming is not effective (cannot understand the purpose of this)
  const checkLoginUserType = async () => {
    // Retrieve the user data from encrypted local storage
    if (getUserType() === 'systemManagementUser') {
      setLoginUserType(true);
    } else {
      setLoginUserType(false);
    }
  };

  const getAllClients = async () => {
    try {
      const params = { select: 'companyName', limit: 1000 };
      const { data } = await fetchClientListData(params);
      if (data?.docs) {
        const options = data?.docs?.map(({ _id, companyName }) => ({
          label: companyName,
          value: _id,
        }));
        setClientsOptions(options);
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    }
  };

  const getAllRoles = async () => {
    let params;
    try {
      setCustomLoading((prevState) => ({ ...prevState, roles: true }));

      if (getUserType() === 'systemManagementUser') {
        params = {
          userType: urlSearchParams?.userType || getUserType(),
          clientId: urlSearchParams?.clientId,
        };
      } else {
        params = {
          userType: urlSearchParams?.userType,
          clientId: urlSearchParams?.clientId,
        };
      }

      const { data } = await fetchRolesListData(params);

      if (data) {
        const options = data?.docs?.map(({ _id, name }) => ({
          label: name,
          value: _id,
        }));
        setRoles(options);
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, roles: false }));
    }
  };

  /**
   * Fetch user list data based on provided query parameters
   *
   * @param {IUserListURLQueryParams} params - Url search params
   */
  const getUserListData = async (params: IUserListURLQueryParams) => {
    try {
      setCustomLoading((prevState) => ({ ...prevState, list: true }));
      const { data } = await fetchUserListData(params);
      if (data) {
        setUserListData({ ...userListData, ...data, docs: mapListViewDocs(data.docs) });
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, list: false }));
    }
  };

  /**
   * Function to replace URL search parameters
   * @param { IClientListURLQueryParams } params -  Url search query params
   */
  const replaceUrlParamsHandler = (params: IUserListURLQueryParams) => {
    const queryParams = omitBy(
      {
        page: params.page || '1',
        limit: params.limit || '10',
        sort: params.sort,
        order: params.order,
        searchBy: params.searchBy,
        userType: params.userType,
        status: params.status,
        clientId: params.clientId,
        roleId: params.roleId,
      },
      isUndefined,
    );
    const searchParams = new URLSearchParams(queryParams);
    setUrlSearchParams({ ...urlSearchParams, ...queryParams });
    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  /**
   * Handle page change in pagination by replacing the url search params
   *
   * @param {number} pageNo - Page number user requesting to navigate
   */
  const handlePageChange = (pageNo: number) => {
    replaceUrlParamsHandler({
      ...urlSearchParams,
      page: pageNo,
    });
  };

  /**
   * A callback function that is triggered when the user changes the number of
   * results to display per page.
   * @param {number} limit - The new limit value (i.e. number of results per page).
   */
  const handleResultsPerPageChange = (limit: number) => {
    setUserListData((prevTableData) => ({ ...prevTableData, limit }));
    replaceUrlParamsHandler({
      page: userListData.page,
      limit,
    });
  };

  /**
   * Handle filtering by adding and removing url params using value from the element and passed id
   * @param {string} value - Value that used to search or filter the field eg: doe@abc.com
   * @param {string} id - Field or parameter that need to use for filtering. eg: adminEmail
   */
  const handleFilterBy = (value: string, id: string) => {
    // create a copy of url search param
    const updatedSearchParams = urlSearchParams;
    // add new param
    if (value) {
      updatedSearchParams[id] = value;
    } else {
      delete updatedSearchParams[id]; // Remove the key if value is undefined
    }
    // update url
    replaceUrlParamsHandler(updatedSearchParams);
  };

  /**
   * Set selected item for merchant select filter
   * @param {string} value - Selected value from url params(merchantId)
   * @returns {ISelectOption} - Selected merchant object
   */
  const getClientSelected = (value: string): ISelectOption => {
    const selectedClient = clientsOptions.find((clientOption) => clientOption.value === value);
    return selectedClient;
  };

  /**
   * Based on the passed column set url query params for column wise sorting
   * @param {boolean} isDescending - If true sorting is descending order else ascending order
   * @param {string} column - Which column need to be used
   */
  const handleColumnWiseSorting = (isDescending: boolean, column: string) => {
    replaceUrlParamsHandler({
      sort: column,
      order: isDescending ? -1 : 1,
    });
  };

  const handleEditTableRow = (e, row: any) => {
    navigate(`${PATHS.USER_MANAGEMENT.FORM.url}?md=edit&i=${row._id}`);
  };

  return (
    <ListPage
      userListData={userListData}
      clientsOptions={clientsOptions}
      handleFilterBy={handleFilterBy}
      handlePageChange={handlePageChange}
      handleResultsPerPageChange={handleResultsPerPageChange}
      urlSearchParams={urlSearchParams}
      handleColumnSorting={handleColumnWiseSorting}
      getClientSelected={getClientSelected}
      customLoading={customLoading}
      handleExport={() => handleExportExcel(userListData.docs)}
      searchByInput={searchByInput}
      setSearchByInput={setSearchByInput}
      roles={roles}
      handleEditTableRow={handleEditTableRow}
      userType={loginUserType}
    />
  );
}

export default UserAccountListView;
