/* eslint-disable react/jsx-no-bind */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { toast } from 'react-toastify';

import { fetchUserListData, updateUser } from 'services/user.service';
import { apiErrorHandler } from 'api/handler';
import { updateURLWithSearchParams } from 'utils/helpers';

import { useDebounce } from 'use-debounce';
import { createChannel, getChannelDataById, updateChannel } from 'services/channel.service';
import { PromptModal } from 'components';
import { getClientId } from 'utils/auth';
import { caseFormValidationSchema } from './validations';
import {
  ModalTypes,
  PromptTypes,
  customLoadingOptions,
  modalDefaultOptions,
  promptDefaultOptions,
  userTableHeaders,
} from './constants';
import FormPage from './page';
import AddUserModal from './components/modals/AddUserModal';

function ChannelForm() {
  const [searchParams] = useSearchParams(); // read url params

  const [formData, setFormData] = useState<any>({});

  // users loads into the dropdown
  const [usersList, setUsersList] = useState([]);

  // channel users
  const [channelUsersList, setChannelUsersList] = useState({
    totalDocs: 0,
    totalPages: 0,
    limit: 0,
    page: 1,
    headers: userTableHeaders,
    docs: [],
    sort: null,
    order: -1,
  });

  const [searchByInput, setSearchByInput] = useState();
  const [debouncedSearchBy] = useDebounce(searchByInput, 500);

  const [editMode, setEditMode] = useState(false);
  const [customLoading, setCustomLoading] = useState(customLoadingOptions);
  const [triggerChild, setTriggerChild] = useState({ users: null, transactions: null });

  const [modalOptions, setModalOptions] = useState(modalDefaultOptions);
  const [promptOptions, setPromptOptions] = useState(promptDefaultOptions);

  const pageType = searchParams.get('md');
  const pageId = searchParams.get('i');

  const { register, handleSubmit, formState } = useForm<any>({
    resolver: yupResolver(caseFormValidationSchema),
    values: useMemo(() => formData, [formData]),
  });

  const { errors } = formState;

  useEffect(() => {
    const clientId = getClientId();
    setFormData({ ...formData, clientId });
    if (!pageType || pageType === 'new') {
      updateURLWithSearchParams({ md: 'new' });
    }
    if (pageType && pageType === 'edit' && pageId) {
      setEditMode(true);
      fetchChannelData();
    }
    fetchUsersList();
  }, []);

  useEffect(() => {
    if (debouncedSearchBy || debouncedSearchBy === '') {
      fetchUsersList();
    }
  }, [debouncedSearchBy]);

  async function fetchUsersList() {
    try {
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: true, fetchForm: true }));
      const { data } = await fetchUserListData({
        searchBy: searchByInput,
        clientId: getClientId(),
      });
      if (data) {
        const mappedData = data?.docs.map((obj) => ({
          label: obj?.name,
          value: obj?._id,
        }));
        setUsersList(mappedData);
      }
    } catch (e) {
      const { message: exception } = apiErrorHandler(e);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: false, fetchForm: false }));
    }
  }

  async function fetchChannelData() {
    try {
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: true, fetchForm: true }));
      const { data } = await getChannelDataById(pageId || formData?._id);
      if (data?.node[0]) {
        const mappedData = {
          ...data?.node[0],
          channelId: data?.node[0].sequenceId,
          status: data?.node[0].isActive ? 'active' : 'disabled',
        };
        setFormData(mappedData);
        if (data?.node[0]?.users.length > 0) {
          if (data?.node[0]?.users[0]?.userId.length === 0) {
            setChannelUsersList({ ...channelUsersList, docs: [] });
            return;
          }
          const mappedListData = data?.node[0]?.users.map((obj) => ({
            name: obj?.userId[0]?.name ? obj?.userId[0]?.name : '',
            _id: obj?.userId[0]?._id ? obj?.userId[0]?._id : '',
            email: obj?.userId[0]?.email ? obj?.userId[0]?.email : '',
            role: obj?.role ? obj?.role : '',
            addedAt: obj?.addedAt ? obj?.addedAt : '',
            addedBy: obj?.addedBy[0] || '',
          }));
          setChannelUsersList({ ...channelUsersList, docs: mappedListData });
        }
      }
    } catch (e) {
      const { message: exception } = apiErrorHandler(e);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: false, fetchForm: false }));
    }
  }

  async function updateUserData(userData: any) {
    try {
      const payload = {
        userId: userData.userId,
        isAddUser: true,
      };
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: true, fetchForm: true }));
      const { data } = await updateChannel(formData?._id, payload);
      if (data) {
        setModalOptions({ ...modalOptions, show: false });
        fetchChannelData();
        setTriggerChild((prevState) => ({ ...prevState, transactions: uuid() }));
        toast.success('Successfully Added a User');
      }
    } catch (e) {
      const { message: exception } = apiErrorHandler(e);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: false, fetchForm: false }));
    }
  }

  // Handles the form submission
  const onSubmit = async () => {
    try {
      const payload = formData;
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: true, fetchForm: true }));
      if (!editMode) {
        const { data } = await createChannel(payload);
        if (data?.node[0]) {
          const mappedData = {
            ...data?.node[0],
            channelId: data?.node[0].sequenceId,
            status: data?.node[0].isActive ? 'active' : 'disabled',
          };
          if (data?.node[0]?.users.length > 0 && data?.node[0]?.users[0]?.userId.length !== 0) {
            const mappedListData = data?.node[0]?.users.map((obj) => ({
              name: obj?.userId[0]?.name ? obj?.userId[0]?.name : '',
              _id: obj?.userId[0]?._id ? obj?.userId[0]?._id : '',
              email: obj?.userId[0]?.email ? obj?.userId[0]?.email : '',
              role: obj?.role ? obj?.role : '',
              addedAt: obj?.addedAt ? obj?.addedAt : '',
              addedBy: obj?.addedBy[0] || '',
            }));
            setChannelUsersList({ ...channelUsersList, docs: mappedListData });
          } else {
            setChannelUsersList({ ...channelUsersList, docs: [] });
          }
          setFormData({ ...payload, ...mappedData });
          setEditMode(true);
          updateURLWithSearchParams({ md: 'edit', i: data?.node[0]?._id });
          toast.success('Successfully Created a Channel');
          setTriggerChild((prevState) => ({ ...prevState, transactions: uuid() }));
        }
      } else {
        const updatePayload = {
          ownerId: formData?.ownerId,
          channelName: formData?.channelName,
          description: formData?.description,
        };
        const { data } = await updateChannel(formData._id, updatePayload);
        if (data?.node) {
          setFormData({ ...payload, ...data.node });
          toast.success('Successfully Updated a Channel');
          setTriggerChild((prevState) => ({ ...prevState, transactions: uuid() }));
        }
      }
    } catch (e) {
      const { message: exception } = apiErrorHandler(e);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: false, fetchForm: false }));
    }
  };

  // handle the input text change
  const handleInputChange = (event: { target: { id: any; value: any } }) => {
    const { id, value } = event.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  // handle the dropdown change
  const handleSelectChange = (event: any, id: string) => {
    setFormData({
      ...formData,
      [id]: event?.value,
    });
  };

  const handleAddUser = () => {
    setModalOptions((prevState) => ({
      ...prevState,
      show: true,
      actionStatus: false,
      type: ModalTypes.AddUsers,
      data: formData,
    }));
  };

  const handleRemoveUser = (event, row) => {
    handleOnPromptModel(row?._id);
  };

  const removeUserFromChannel = async (userId: string) => {
    try {
      const payload = {
        userId,
        isRemoveUser: true,
      };
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: true, fetchForm: true }));
      const { data } = await updateChannel(formData._id, payload);
      if (data) {
        setPromptOptions({ ...promptOptions, show: false });
        fetchChannelData();
        setTriggerChild((prevState) => ({ ...prevState, transactions: uuid() }));
        toast.success('Successfully Removed a User');
      }
    } catch (e) {
      const { message: exception } = apiErrorHandler(e);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: false, fetchForm: false }));
    }
  };

  // Closed or Complete case modal triggering function
  const handleOnPromptModel = (userId: string) => {
    setPromptOptions({
      show: true,
      title: 'Are You Sure?',
      message: `Do you want to Remove this user from channel ${formData?.channelName}`,
      onConfirm: () => removeUserFromChannel(userId),
      type: PromptTypes.ToggleAccount,
    });
  };

  const handleChannelDisableOnPromptModel = () => {
    setPromptOptions({
      show: true,
      title: 'Are You Sure?',
      message: `Do you want to ${formData?.isActive ? 'Disable' : 'Enable'} this channel ${
        formData?.channelName
      }`,
      onConfirm: () => handleDisableChannel(),
      type: PromptTypes.ToggleAccount,
    });
  };

  const handleDisableChannel = async () => {
    try {
      const payload = {
        isDisable: formData?.isActive,
      };
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: true, fetchForm: true }));
      const { data } = await updateChannel(formData._id, payload);
      if (data?.node) {
        const mappedData = {
          ...data?.node,
          channelId: data?.node.sequenceId,
          status: data?.node.isActive ? 'active' : 'disabled',
        };
        setFormData({ ...payload, ...mappedData });
        setPromptOptions({ ...promptOptions, show: false });
        setTriggerChild((prevState) => ({ ...prevState, transactions: uuid() }));
        toast.success(`Successfully ${formData?.isActive ? 'Disabled' : 'Enabled'} the Channel`);
      }
    } catch (e) {
      const { message: exception } = apiErrorHandler(e);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: false, fetchForm: false }));
    }
  };

  function loadDynamicLoadingProp() {
    if (promptOptions.type === PromptTypes.ToggleAccount) {
      return customLoading.toggleAccount;
    }
    return false;
  }

  return (
    <>
      <FormPage
        onSubmit={onSubmit}
        errors={errors}
        formData={formData}
        handleInputChange={handleInputChange}
        handleSubmit={handleSubmit}
        customLoading={customLoading}
        editMode={editMode}
        register={register}
        triggerChild={triggerChild}
        handleSelectChange={handleSelectChange}
        usersList={usersList}
        handleAddUser={handleAddUser}
        handleRemoveUser={handleRemoveUser}
        disableChannel={handleChannelDisableOnPromptModel}
        channelUsersList={channelUsersList}
      />

      {modalOptions.type === ModalTypes.AddUsers && modalOptions.show && (
        <AddUserModal
          {...modalOptions}
          usersList={usersList}
          channelUsersList={channelUsersList}
          handleUpdate={updateUserData}
          toggleModal={() => setModalOptions({ ...modalOptions, show: false })}
        />
      )}

      {promptOptions.show && (
        <PromptModal
          onCancel={() => {
            setPromptOptions({ ...promptOptions, show: false });
          }}
          {...promptOptions}
          loading={loadDynamicLoadingProp()}
        />
      )}
    </>
  );
}

export default ChannelForm;
