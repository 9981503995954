export function mapListViewDocs(docs = []) {
  if (!Array.isArray(docs)) {
    return [];
  }

  const formattedDocs = docs.map((obj) => ({
    ...formatListViewObject(obj),
  }));

  return formattedDocs;
}

export function formatListViewObject(doc: any) {
  return {
    ...doc,
    contactName: doc?.contact
      ? `${doc?.contact?.firstName || ''} ${doc?.contact?.lastName || ''}`
      : '',
    status: doc?.status ? 'open' : 'completed',
  };
}
