import React from 'react';
import PropTypes from 'prop-types';

function TransactionUserCell(props) {
  const { data = {} } = props;

  const { email, name } = data;

  return (
    <div className="flex flex-col text-start font-lato">
      <span className="text-base">{name}</span>
      <span className="text-xs">{email}</span>
    </div>
  );
}

TransactionUserCell.propTypes = {
  data: PropTypes.any,
};

export default TransactionUserCell;
