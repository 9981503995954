export const FIELD_TYPES = [
  { value: 'text', label: 'Text' },
  { value: 'dropDown', label: 'DropDown' },
  { value: 'checkbox', label: 'Checkbox' },
  { value: 'date', label: 'Date' },
  { value: 'dateTime', label: 'DateTime' },
];
export enum SALUTATIONS {
  MR = 'Mr.',
  MRS = 'Mrs.',
  MS = 'Ms.',
  MISS = 'Miss.',
  SIR = 'Sir',
  DR = 'Dr. (Doctor)',
  PROF = 'Prof. (Professor)',
  REV = 'Rev. (Reverend)',
  OTHER = 'Other',
}

export const GENDER = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
  { label: 'Other', value: 'other' },
  { label: 'Prefer Not to Say', value: 'prefer not to say' },
];

export const PREFERRED_CONTACT_METHOD = [
  { label: 'Phone Call', value: 'phoneCall' },
  { label: 'WhatsApp', value: 'whatsApp' },
  { label: 'Email', value: 'email' },
];

export const LAST_UPDATED_AT = [
  { label: 'Today', value: 'today' },
  { label: 'Past 24 hours', value: '24hours' },
  { label: 'This Week', value: 'week' },
];
