const v1 = 'v1';

export const AUTH = {
  LOGIN: `/${v1}/auth/login`,
  REGISTER: '',
  VERIFY_EMAIL: '',
  GET_AUTHORIZED_USER: `/${v1}/auth/user/{id}/authorized/get`,
  USER_AUTHORIZED: '',
  GENERATE_TEMP_PASSWORD: `/${v1}/auth/user/{id}/generate-temp-password`, // GET
  SETUP_PASSWORD: `/${v1}/auth/setup-password`, // POST
  FORGET_PASSWORD: `/${v1}/auth/forget-password/{email}`, // GET
};

export const PROFILE = {
  MANAGE: `/${v1}/profile/manage`, // POST
};

export const CLIENT = {
  GET_CLIENTS_ALL: `/${v1}/clients/list`, // GET
  GET_CLIENT_BY_ID: `/${v1}/clients/{id}/get`, // GET
  CREATE_CLIENT: `/${v1}/clients/create`, // POST
  UPDATE_CLIENT: `/${v1}/clients/{id}/update`, // PATCH
  ACTIVATE_CLIENT: `/${v1}/clients/{id}/activate`, // POST
  TOGGLE_ACCOUNT_STATUS: `/${v1}/clients/{id}/toggle-status`, // POST
  UPLOAD_COMPANY_LOGO: `/${v1}/clients/{id}/attachment/client-logo/upload`, // POST
};

export const USER = {
  GET_USERS_ALL: `/${v1}/users/list`, // GET
  GET_USER_BY_ID: `/${v1}/users/{id}/get`, // GET
  CREATE_USER: `/${v1}/users/create`, // POST
  UPDATE_USER: `/${v1}/users/{id}/update`, // PATCH
  APPROVE_USER: `/${v1}/users/{id}/approve`, // POST
  UPLOAD_PROFILE_PIC: `/${v1}/users/{id}/attachment/profile-pic/upload`, // POST
  TOGGLE_ACCOUNT_DISABLE: `/${v1}/users/{id}/toggle-disable`, // POST
  SEND_ACCOUNT_FOR_APPROVAL: `/${v1}/users/{id}/account/send-for-approval`, // POST
  GET_USERS_WITH_PERMISSION: `/${v1}/users/permission/{key}/all`, // GET
  SET_AGENT_STATUS: `/${v1}/users/agent-status`, // PATCH
  GET_AGENT_STATUS: `/${v1}/users/agent-status`, // GET
};

export const CONTACT = {
  CREATE_CONTACT: `/${v1}/contacts/create`, // POST
  GET_CONTACTS_ALL: `/${v1}/contacts/list`, // GET
  GET_CONTACT_BY_ID: `/${v1}/contacts/get/{id}`, // GET
  UPDATE_CONTACT: `/${v1}/contacts/{id}/update`, // PATCH
  UPLOAD_PROFILE_PICTURE: `/${v1}/contacts/{id}/attachment/contact-profile-picture/upload`, // POST
};

export const CASE = {
  GET_CASE_LIST_BY_CONTACT_ID: `/${v1}/cases/list/{id}`, // GET
  GET_CASES_ALL: `/${v1}/cases/list`, // GET
  GET_CASES_BY_ID: `/${v1}/cases/get/{id}`, // GET
  CREATE_CASE: `/${v1}/cases/create`, // POST
  UPDATE_CASE: `/${v1}/cases/{id}/update`, // PATCH
  LINK_UNLINK_CONVERSATION: `/${v1}/cases/{id}/link-unlink-conversation`, // PATCH
};

export const MSG_TEMPLATES = {
  GET_TEMPLATE_LIST: `/${v1}/msg-templates/list`, // GET
  CREATE_TEMPLATE: `/${v1}/msg-templates/create`, // POST
  UPDATE_TEMPLATE: `/${v1}/msg-templates/update/{id}`, // PATCH
};

export const AUDIT_HISTORY = {
  FETCH_LIST_BY_COLLECTION_AND_RECORD: `/${v1}/audit-history/{collection}/{id}`, // GET
};

export const NOTE = {
  GET_NOTES_BY_CONTACT_ID: `/${v1}/notes/list/{id}`, // GET
  CREATE_NOTE: `/${v1}/notes/create`, // POST
  UPDATE_NOTE: `/${v1}/notes/update/{id}`, // PATCH
  DELETE_NOTE: `/${v1}/notes/delete/{id}`, // DELETE
  GET_NOTE_BY_ID: `/${v1}/notes/{id}/get`, // GET
};

export const ROLES = {
  GET_ROLES_LIST: `/${v1}/roles/list`, // GET
  CREATE_ROLE: `/${v1}/roles/create`, // POST
  GET_ROLE_BY_ID: `/${v1}/roles/{id}`, // GET
  UPDATE_ROLE_BY_ID: `/${v1}/roles/{id}`, // PATCH
  DELETE_ROLE_BY_ID: `/${v1}/roles/{id}/delete`, // PATCH
  ADD_ROLE_PERMISSION: `/${v1}/roles/{id}/permission`, // PATCH
};

export const PERMISSIONS = {
  GET_PERMISSIONS_LIST: `/${v1}/permissions/list`, // GET
};

export const CONVERSATIONS = {
  GET_CONVERSATION_LIST: `/${v1}/conversations/list`, // GET
  GET_CONVERSATION_COUNT: `/${v1}/conversations/count`, // GET
  UPDATE_CONVERSATION: `/${v1}/conversations/update/{id}`, // PATCH
  GET_CONVERSATION_LIST_BOT_CHATS: `/${v1}/conversations/bot-chats/list`, // GET
  GET_COMPLETED_CONVERSATION_LIST: `/${v1}/conversations/list/completed-chats`, // GET
  TAKE_CHAT: `/${v1}/conversations/take-chat/{id}`, // PATCH
  COMPLETE_CONVERSATION: `/${v1}/conversations/{id}/complete`, // PATCH
  ADD_CONTACT_TO_CONVERSATION: `/${v1}/conversations/{id}/add-contact`, // PATCH
  GET_CONVERSATION_BY_ID: `/${v1}/conversations/get/{id}`, // GET
  GET_CONVERSATION_DATA_BY_ID: `/${v1}/conversations/completed-chats/{id}/preview`, // GET
  GET_PREVIEW_DATA_BOT_CHATS_BY_ID: `/${v1}/conversations/bot-chats/{id}/preview`, // GET
};

export const MESSAGES = {
  GET_MESSAGES_LIST: `/${v1}/messages/list/{id}`, // GET
  CREATE_MESSAGES: `/${v1}/messages/create/{id}`, // POST
  CREATE_ATTACHMENT: `/${v1}/messages/attachment/upload/{id}`, // POST
};

export const CHANNELS = {
  GET_CHANNELS_LIST: `/${v1}/channel/list`, // GET
  GET_CHANNELS: `/${v1}/channel/{id}`, // GET
  GET_CHANNEL_USERS: `/${v1}/channel/users/list/{id}`, // GET
  CREATE_CHANNEL: `/${v1}/channel/create`, // POST
  UPDATE_CHANNEL: `/${v1}/channel/update/{id}`, // PATCH
};

export const MESSENGER = {
  GET_LIST: `/${v1}/messengers/instance`, // GET
  GET_DATA_BY_ID: `/${v1}/messengers/instance/{id}/get`, // GET
  CREATE_MESSENGER: `/${v1}/messengers/instance/create`, // POST
  UPDATE_MESSENGER: `/${v1}/messengers/instance/{id}/update`, // PATCH
  UPDATE_MESSENGER_INTENT_SETTINGS: `/${v1}/messengers/instance/{id}/settings`, // PATCH
  TOGGLE_STATUS: `/${v1}/messengers/instance/{id}/toggle-status`, // POST
  UPLOAD_BOT_LOGO: `/${v1}/messengers/instance/{id}/attachment/bot-logo/upload`, // POST
  GET_ATTACHMENT: `/${v1}/messengers/instance/{id}/attachment/get`, // GET
  GET_LINKED_CHANNELS: `/${v1}/messengers/instance/{id}/linked-channels`, // GET
  GET_UN_LINKED_CHANNELS: `/${v1}/messengers/instance/{id}/unlinked-channels`, // GET
  LINK_CHANNEL_TO_MESSENGER: `/${v1}/messengers/instance/{id}/link-channels`, // POST
  UNLINK_CHANNEL_FROM_MESSENGER: `/${v1}/messengers/instance/{id}/unlink-channel`, // POST
  BUILD_BOT: `/${v1}/messengers/instance/{id}/build-bot`, // POST
  GET_INSTANCE_BOT_LOCALE_STATUS: `/${v1}/messengers/instance/{id}/bot/get-locale-status`, // GET
  GET_MESSENGER_INTENTS: `/${v1}/messenger/intent/{id}/list`, // GET
  GET_MESSENGER_INTENT_BY_ID: `/${v1}/messenger/intent/{id}/get`, // GET
  CREATE_MESSENGER_INTENT: `/${v1}/messenger/intent/create`, // POST
  CREATE_ATTACHMENT: `/${v1}/messengers/instance/create/attachment`, // POST
  UPDATE_MESSENGER_INTENT: `/${v1}/messenger/intent/{id}/update`, // PATCH
  DELETE_MESSENGER_INTENT: `/${v1}/messenger/intent/{id}/delete`, // DELETE
  DISABLE_MESSENGER_INTENT: `/${v1}/messenger/intent/{id}/disable`, // Patch
  GET_MESSENGER_INTENT_UTTERANCES: `/${v1}/messenger/intent/{id}/utterances`, // GET
  ADD_MESSENGER_INTENT_UTTERANCE: `/${v1}/messenger/intent/{id}/utterance/add`, // PATCH
  UPDATE_MESSENGER_INTENT_UTTERANCE: `/${v1}/messenger/intent/{id}/utterance/update`, // PATCH
  DELETE_MESSENGER_INTENT_UTTERANCE: `/${v1}/messenger/intent/{id}/utterance/delete`, // PATCH
  UPDATE_MESSENGER_FLOW: `/${v1}/messenger/flow-builder/update`, // POST
  GET_ALL_MESSENGER_FLOW: `/${v1}/messenger/flow-builder/{id}/list`, // GET
  GET_MESSENGER_FLOW: `/${v1}/messenger/flow-builder/{id}`, // GET
  GET_MESSENGER_FLOW_BY_INTENT_ID: `/${v1}/messenger/flow-builder/intent/{id}`, // GET
  CREATE_MESSENGER_FLOW: `/${v1}/messenger/flow-builder`, // POST
  UPLOAD_FLOW_ATTACHMENT: `/${v1}/messenger/flow-builder/attachment`, // POST
  DELETE_FLOW_ATTACHMENT: `/${v1}/messenger/flow-builder/{intentId}/attachment/{id}`, // DELETE
  UPLOAD_KNOWLEDGE_BASE_FILE: `/${v1}/messengers/instance/{id}/knowledge-base/file/upload`, // POST
  GET_KNOWLEDGE_BASES: `/${v1}/messengers/instance/{id}/knowledge-base`, // GET
  DELETE_KNOWLEDGE_BASE: `/${v1}/messengers/instance/{id}/knowledge-base`, // DELETE
};

export const CONVERSATIONS_REVIEWS = {
  GET_CONVERSATION_REVIEWS_LIST: `/${v1}/conversations-ratings/list/{id}`, // GET
  GET_CONVERSATION_REVIEWS_BY_ID: `/${v1}/conversations-ratings/get/{id}`, // GET
  UPDATE_CONVERSATION_REVIEWS_BY_ID: `/${v1}/conversations-ratings/update/{id}`, // GET
  CREATE_CONVERSATION_REVIEWS_BY_ID: `/${v1}/conversations-ratings/create`, // GET
};

export const KNOWLEDGE_BASE = {
  GET: `/${v1}/knowledge-bases`, // GET
  CREATE: `/${v1}/knowledge-bases/{id}`, // POST
  GET_BY_ID: `/${v1}/knowledge-bases/{id}`, // GET
  UPDATE: `/${v1}/knowledge-bases/{id}`, // PATCH
};
