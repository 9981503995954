import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';

import { updateAPIRequestBody } from 'slices/flow';
import { buildVariableDropdownOptions } from 'views/Settings/FlowBuilder/utils';
import { RootState } from 'slices';
import VariableDropdown from '../../../VariableDropdown';
import { IAPIRequestData } from '../interfaces';

function RequestBody(props) {
  const { nodeId, body } = props;

  const flowData = useSelector((state: RootState) => state.flow);

  const dispatch = useDispatch();

  const bodyRef = useRef(null); // Ref for the input field

  const [isRequestBodyValid, setIsRequestBodyValid] = useState(true);
  // Track cursor position
  const [cursorPosition, setCursorPosition] = useState(0);
  const [bodyText, setBodyText] = useState(body);

  /**
   * Validate and set request body change
   * @param { string}  - Text area content
   */
  const handleRequestBodyChange = (updatedBody: string) => {
    setBodyText(updatedBody);
    // JSON validation logic
    try {
      JSON.parse(updatedBody);
      setIsRequestBodyValid(true);
    } catch (error) {
      setIsRequestBodyValid(false);
    }
  };

  /**
   * When focus changed from body add to redux state
   */
  const handleBlur = () => {
    // Trigger API request body update when focus leaves the text area
    dispatch(updateAPIRequestBody({ nodeId, body: bodyText }));
  };

  /**
   * Handle inserting the value at the cursor position
   * @param {string} selectedValue - selected variable
   */
  const insertValueAtCursorPosition = (selectedValue: string) => {
    const currentValue = bodyRef.current.value;
    const wrappedValue = `{{${selectedValue}}}`;

    const newValue =
      currentValue.substring(0, cursorPosition) +
      wrappedValue +
      currentValue.substring(cursorPosition);
    bodyRef.current.value = newValue;
    setCursorPosition(cursorPosition + selectedValue.length);
    handleRequestBodyChange(newValue); // Optionally, trigger input change handler
  };

  return (
    <div className="request-body__container">
      <div className="heading__text">
        Request body
        <span className="heading__text--muted">
          &nbsp;RAW <small>(application/json)</small>
        </span>
      </div>
      <div className="request-body flex-grow flex items-center relative">
        <textarea
          ref={bodyRef}
          className="request-body__textarea flow-builder__input"
          value={bodyText || ''}
          onChange={(e) => handleRequestBodyChange(e.target.value)}
          onSelect={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            const cursorCurrentPosition = e.target.selectionStart;
            setCursorPosition(cursorCurrentPosition); // track cursor position
          }}
          onBlur={handleBlur}
        />
        <div className="absolute right-1 top-1">
          <VariableDropdown
            id="apiRequestRequestBody"
            options={buildVariableDropdownOptions(flowData.variables || [])}
            onChange={(selectedOption) => {
              if (selectedOption) {
                insertValueAtCursorPosition(selectedOption?.value);
              }
            }}
          />
        </div>
      </div>
      {!isRequestBodyValid && <small className="text-error">Invalid JSON</small>}
    </div>
  );
}

RequestBody.propTypes = {
  nodeId: propTypes.string,
  body: propTypes.string,
};

export default RequestBody;
