import { useEffect } from 'react';
import toast from 'react-simple-toasts';

import useMessengerInstanceReadyEvent from 'hooks/useMessengerInstanceReadyEvent';

import { SocketEvents } from 'enums/socket.enum';
import { ToastTheme } from 'enums/toast.enum';

function AuthorizedView() {
  const { event } = useMessengerInstanceReadyEvent();

  useEffect(() => {
    if (event?.data && event?.type === SocketEvents.MessengerInstanceStatus) {
      toast(`${event?.data?.messengerName} Message Instance is Ready to Test`, {
        theme: ToastTheme.success,
      });
    }
  }, [event]);

  // Return null instead of an empty fragment
  return null;
}

export default AuthorizedView;
