export const linkConversationTableHeaders = [
  {
    key: 'sequenceId',
    value: 'Chat ID',
  },
  {
    key: 'status',
    value: 'Status',
    type: 'statusLabel',
    params: {
      active: {
        label: 'ACTIVE',
        color: 'white',
        bgColor: 'bg-lotionLightGray',
        borderColor: 'bg-gainsboroBlue',
      },
      new: {
        label: 'NEW',
        color: 'white',
        bgColor: 'bg-lotionLightGray',
        borderColor: 'bg-gainsboroBlue',
      },
      ended: {
        label: 'COMPLETED',
        color: 'white',
        bgColor: 'bg-northTexasGreen',
        borderColor: 'bg-gainsboroBlue',
      },
    },
  },
  {
    key: 'createdAt',
    value: 'Conversation Date and Time',
    type: 'dateTime',
  },
  {
    key: 'user',
    value: 'user/Agent',
    type: 'transactionUser',
  },
  {
    key: 'isChecked',
    value: 'Select',
    type: 'isChecked',
  },
];
