/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';

import { FileUploadModal, PrimaryButton, StatusLabel } from 'components';
import { Back, ImagePlaceholder } from 'components/Icons';
import { PATHS } from 'routes/paths';
import { apiErrorHandler } from 'api/handler';
import { uploadProfilePic } from 'services/user.service';
import { formatDateTime } from 'utils/dates';

function ProfileSideBar(props) {
  const { formData } = props;

  const [profilePic, setProfilePic] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [fileUploadModalOptions, setFileUploadModalOptions] = useState({ show: false });

  const [customLoading, setCustomLoading] = useState({
    fileUpload: false,
  });

  useEffect(() => {
    if (formData?.attachments?.profilePic) {
      const { location } = formData.attachments.profilePic;
      setProfilePic(location);
    }
  }, [formData?.attachments]);

  async function uploadLogo(file: any) {
    try {
      setCustomLoading((prevState) => ({ ...prevState, fileUpload: true }));

      // Create FormData and append the selected file
      const payload = new FormData();
      payload.append('file', file);
      const { data } = await uploadProfilePic(formData._id, payload);
      if (data?.node) {
        const { attachment } = data.node;
        const { location } = attachment;
        setProfilePic(location);
        toast.success('User profile photo has been Updated');
      }
    } catch (e) {
      const { message: exception } = apiErrorHandler(e);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, fileUpload: false }));
    }
  }

  function handleOnClickFileUpload() {
    setFileUploadModalOptions({ ...fileUploadModalOptions, show: true });
  }

  const handleCloseFileUploadModal = () => {
    setFileUploadModalOptions({ ...fileUploadModalOptions, show: false });
  };

  // Function to handle image file selection
  const handleOnFileSelected = (file) => {
    if (file) {
      // You can perform additional checks/validation on the selected image here
      setSelectedImage(file);
      uploadLogo(file);
    }
    handleCloseFileUploadModal();
  };

  return (
    <>
      <div className="client-account__userHeader flex items-center justify-center mb-4">
        <div className="flex flex-col items-center">
          {profilePic || selectedImage ? (
            <img
              className="w-60 h-60 rounded-full p-2 cursor-pointer mb-8"
              src={selectedImage ? URL.createObjectURL(selectedImage) : profilePic}
              alt="CL"
              onClick={handleOnClickFileUpload}
            />
          ) : (
            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={handleOnClickFileUpload}
              aria-disabled={customLoading.fileUpload}
              className="w-32 h-32 rounded-full p-2 bg-brightGray flex justify-center items-center cursor-pointer"
            >
              <ImagePlaceholder width={60} height={60} />
            </motion.div>
          )}

          <div className="client-account__details items-center text-center">
            <div className="gap-x-4 items-center">
              <p className="text-rasinBlack font-semibold text-sm tracking-wide">
                {formData?.name}
              </p>
              {formData.primaryContactNo && (
                <div className="text-gray-600 font-bold text-xs">{formData?.primaryContactNo}</div>
              )}
              <p className="text-gray-600 font-normal text-xs">{formData?.email}</p>
            </div>
          </div>
        </div>
      </div>

      <FileUploadModal
        show={fileUploadModalOptions.show}
        onClose={handleCloseFileUploadModal}
        onFileSelected={handleOnFileSelected}
        type="image"
        title="Upload User profile photo"
      />
    </>
  );
}

ProfileSideBar.propTypes = {
  formData: PropTypes.any,
};

export default ProfileSideBar;
