export const contactsValueTypes = [
  {
    label: 'Variable',
    value: 'variable',
  },
  {
    label: 'Custom Text',
    value: 'customText',
  },
];

export const contactsValueWithFormFieldTypes = [
  {
    label: 'Variable',
    value: 'variable',
  },
  {
    label: 'Custom Text',
    value: 'customText',
  },
  {
    label: 'Form Field',
    value: 'formField',
  },
];
