import * as React from 'react';
import PropTypes from 'prop-types';

function Transaction(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color = '#475574', width = 24, height = 24 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 25 24"
    >
      <g
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        clipPath="url(#clip0_3796_873)"
      >
        <path d="M12.5 8v4l2 2" />
        <path d="M3.55 11a9 9 0 11.5 4m-.5 5v-5h5" />
      </g>
      <defs>
        <clipPath id="clip0_3796_873">
          <path fill="#fff" d="M0 0H24V24H0z" transform="translate(.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
Transaction.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Transaction;
