import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

import { FileUploadModal, PrimaryButton, StatusLabel } from 'components';
import { Back, ImagePlaceholder } from 'components/Icons';
import { PATHS } from 'routes/paths';
import { formatDateTime } from 'utils/helpers';
import { uploadProfilePicture } from 'services/contact.service';
import { toast } from 'react-toastify';
import { apiErrorHandler } from 'api/handler';

function FormEditHeader(props) {
  const { formData } = props;

  const [profilePictureUrl, setProfilePictureUrl] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [fileUploadModalOptions, setFileUploadModalOptions] = useState({ show: false });

  const [customLoading, setCustomLoading] = useState({
    fileUpload: false,
  });

  // onload set profile picture
  useEffect(() => {
    if (formData?.profilePicture) {
      const { location } = formData.profilePicture;
      setProfilePictureUrl(location);
    }
  }, [formData?.profilePicture]);

  async function updateProfilePicture(file: any) {
    try {
      setCustomLoading((prevState) => ({ ...prevState, fileUpload: true }));

      // Create FormData and append the selected file
      const payload = new FormData();
      payload.append('file', file);
      const { data } = await uploadProfilePicture(formData._id, payload);
      if (data?.node) {
        const { profilePicture } = data.node;
        const { location } = profilePicture;
        setProfilePictureUrl(location);
        toast.success('Contact profile photo has been Updated');
      }
    } catch (e) {
      const { message: exception } = apiErrorHandler(e);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, fileUpload: false }));
    }
  }

  /**
   * Upload profile pic
   */
  function handleOnClickFileUpload() {
    setFileUploadModalOptions({ ...fileUploadModalOptions, show: true });
  }

  /**
   * Closing upload modal
   */
  const handleCloseFileUploadModal = () => {
    setFileUploadModalOptions({ ...fileUploadModalOptions, show: false });
  };

  // Function to handle image file selection
  const handleOnFileSelected = (file) => {
    if (file) {
      // You can perform additional checks/validation on the selected image here
      setSelectedImage(file);
      updateProfilePicture(file);
    }
    handleCloseFileUploadModal();
  };

  return (
    <>
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center">
          {profilePictureUrl || selectedImage ? (
            <img
              className="w-24 h-24 rounded-full p-2 mr-5 cursor-pointer"
              src={selectedImage ? URL.createObjectURL(selectedImage) : profilePictureUrl}
              alt="CL"
              onClick={handleOnClickFileUpload}
            />
          ) : (
            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={handleOnClickFileUpload}
              className="w-20 h-20 rounded-full p-2 mr-5 bg-brightGray flex justify-center items-center cursor-pointer"
            >
              <ImagePlaceholder width={40} height={40} />
            </motion.div>
          )}

          <div className="items-center">
            <div className="flex">
              <h2 className="text-spaceCadetBlue font-bold font-ptScanCap text-lg pb-2 mr-2 srg:text-xl">
                Edit Contact
              </h2>
            </div>

            <div className="flex flex-wrap gap-x-4 items-center">
              <div className="text-spaceCadetBlue font-medium font-lato text-base rg:text-lg">
                Contact ID : {formData?.sequenceId}
              </div>
              <div className="text-spaceCadetBlue font-medium font-lato text-base rg:text-lg">
                {formData?.company}
              </div>
              <div className="text-rasinBlack font-medium font-lato text-sm rg:text-base">
                Created At: {formatDateTime(formData?.createdAt)}
              </div>
              <div className="text-rasinBlack font-medium font-lato text-sm rg:text-base">
                Updated At: {formatDateTime(formData?.updatedAt)}
              </div>
            </div>
          </div>
        </div>
      </div>

      <FileUploadModal
        show={fileUploadModalOptions.show}
        onClose={handleCloseFileUploadModal}
        onFileSelected={handleOnFileSelected}
        type="image"
        title="Upload Contact profile photo"
      />
    </>
  );
}

FormEditHeader.propTypes = {
  formData: PropTypes.any,
};

export default FormEditHeader;
