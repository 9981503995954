/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-else-return */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import SenderChatBubble from 'components/Chat/SenderBubble';
import ReceiverChatBubble from 'components/Chat/ReceiverBubble';
import SystemChatBubble from 'components/Chat/SystemBubble';

function Conversation(props) {
  const { chatList = [], messagesLoading } = props;

  let consecutiveSenderCount = 0;
  let consecutiveReceiverCount = 0;
  let consecutiveUserReceiverCount = 0;
  let startOfTodaysMessages = false;

  const divRef: any = useRef(null);
  const chatBubbleRef = useRef(null);

  const [onMount, setOnMount] = useState(true);

  const [scrollIndex, setScrollIndex] = useState(-1);

  // Use useEffect to set the scroll position
  useEffect(() => {
    if (onMount) {
      // Position the scroll bar at the bottom initially
      divRef.current.scrollTop = divRef.current.scrollHeight;
      setScrollIndex(-1);
    } else {
      scrollToChatBubble();
      setOnMount(false);
    }
  }, [chatList]);

  // handling the scroll function
  const scrollToChatBubble = () => {
    if (chatBubbleRef.current) {
      chatBubbleRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div>
      <div
        className="chat-preview_custom-scrollbar max-h-[60%] overflow-y-auto p-2 min-w-[400px]"
        ref={divRef}
      >
        <hr className="my-4" />
        {messagesLoading ? (
          <div className="flex items-center justify-center">
            <span className="loading loading-infinity loading-lg" />
          </div>
        ) : (
          <>
            {chatList.map((obj, index) => {
              const messageDate = new Date(obj.createdAt);
              const isToday = messageDate.toDateString() === new Date().toDateString();

              if (obj?.createdBy === 'Bot') {
                consecutiveReceiverCount = 0;
                consecutiveUserReceiverCount = 0;
                consecutiveSenderCount += 1;
                return (
                  <>
                    {isToday && !startOfTodaysMessages && (
                      <div key="divider" className="divider bg-[#435171] h-[1px]">
                        {(() => {
                          startOfTodaysMessages = true;
                          consecutiveSenderCount = 1;
                          return (
                            <div className="bg-[#435171] justify-center items-center w-auto h-auto px-6 py-1 text-xs text-center text-white font-lato font-bold rounded-3xl">
                              Today
                            </div>
                          );
                        })()}
                      </div>
                    )}
                    <div
                      key={obj._id}
                      ref={index === scrollIndex ? chatBubbleRef : null}
                      className={`${consecutiveSenderCount === 1 ? 'mt-5' : ''}`}
                    >
                      <SenderChatBubble
                        key={obj._id}
                        consecutiveRenderCount={
                          isToday && !startOfTodaysMessages ? 1 : consecutiveSenderCount
                        }
                        {...obj}
                      />
                    </div>
                  </>
                );
              } else if (obj?.createdBy === 'Customer') {
                consecutiveSenderCount = 0; // Reset count when a receiver message is encountered
                consecutiveUserReceiverCount = 0;
                consecutiveReceiverCount += 1;
                return (
                  <>
                    {isToday && !startOfTodaysMessages && (
                      <div key="divider" className="divider bg-[#435171] h-[1px]">
                        {(() => {
                          startOfTodaysMessages = true;
                          consecutiveReceiverCount = 1;
                          return (
                            <div className="bg-[#435171] justify-center items-center w-auto h-auto px-6 py-1 text-xs text-center text-white font-lato font-bold rounded-3xl">
                              Today
                            </div>
                          );
                        })()}
                      </div>
                    )}
                    <div
                      key={obj._id}
                      ref={index === scrollIndex ? chatBubbleRef : null}
                      className={`${consecutiveReceiverCount === 1 ? 'mt-5' : ''}`}
                    >
                      <ReceiverChatBubble
                        key={obj._id}
                        consecutiveRenderCount={
                          isToday && !startOfTodaysMessages ? 1 : consecutiveReceiverCount
                        }
                        {...obj}
                      />
                    </div>
                  </>
                );
              } else if (obj?.createdBy === 'User') {
                consecutiveSenderCount = 0; // Reset count when a receiver message is encountered
                consecutiveReceiverCount = 0;
                consecutiveUserReceiverCount += 1;
                return (
                  <>
                    {isToday && !startOfTodaysMessages && (
                      <div key="divider" className="divider bg-[#435171] h-[1px]">
                        {(() => {
                          startOfTodaysMessages = true;
                          consecutiveUserReceiverCount = 1;
                          return (
                            <div className="bg-[#435171] justify-center items-center w-auto h-auto px-6 py-1 text-xs text-center text-white font-lato font-bold rounded-3xl">
                              Today
                            </div>
                          );
                        })()}
                      </div>
                    )}
                    <div
                      key={obj._id}
                      ref={index === scrollIndex ? chatBubbleRef : null}
                      className={`${consecutiveUserReceiverCount === 1 ? 'mt-5' : ''}`}
                    >
                      <SenderChatBubble
                        key={obj._id}
                        consecutiveRenderCount={
                          isToday && !startOfTodaysMessages ? 1 : consecutiveUserReceiverCount
                        }
                        textHighlighter={index === scrollIndex ? 'bg-[#FFE249]' : ''}
                        {...obj}
                      />
                    </div>
                  </>
                );
              } else if (
                obj?.isTransaction &&
                (obj.transactionType === 'ChatExpired' || obj.transactionType === 'CustomerEndChat')
              ) {
                consecutiveSenderCount = 0;
                consecutiveReceiverCount = 0;
                consecutiveUserReceiverCount = 0;
                return (
                  <div key={obj._id} ref={index === scrollIndex ? chatBubbleRef : null}>
                    <br />
                    <SystemChatBubble
                      key={obj._id}
                      {...obj}
                      conversationData={{ isCompleted: true }}
                    />
                  </div>
                );
              }
            })}
          </>
        )}
      </div>
    </div>
  );
}

Conversation.propTypes = {
  chatList: PropTypes.array,
  messagesLoading: PropTypes.bool,
};
export default Conversation;
