/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useDebounce } from 'use-debounce';

import { fetchAuditHistory } from 'services/auditHistory.service';
import { apiErrorHandler } from 'api/handler';
import SearchBar from 'components/SearchBar';
import CustomTable from '../CustomTable';

function Transactions(props) {
  const {
    recordId,
    collection,
    transactionTableHeaders,
    triggerChild,
    isTitleVisible = true,
  } = props;

  //  state with table headers and sample data for the client list.
  const [listData, setListData] = useState({
    totalDocs: 0,
    totalPages: 0,
    limit: 5,
    page: 1,
    headers: transactionTableHeaders,
    docs: [],
    sort: null,
    order: -1,
    searchByText: null,
  });

  const [searchByInput, setSearchByInput] = React.useState();
  const [debouncedSearchBy] = useDebounce(searchByInput, 500);

  const [customLoading, setCustomLoading] = useState({ list: false });

  /**
   * Handle page change in pagination by replacing the url search params
   *
   * @param {number} pageNo - Page number user requesting to navigate
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handlePageChange = (pageNo: number) => {};

  useEffect(() => {
    if (recordId && collection) {
      fetchListData();
    }
  }, [recordId, collection, triggerChild, listData?.searchByText, listData.page]);

  useEffect(() => {
    if (typeof debouncedSearchBy === 'string') {
      setListData((prevState) => ({
        ...prevState,
        searchByText: debouncedSearchBy,
        page: 1,
      }));
    }
  }, [debouncedSearchBy]);

  async function fetchListData() {
    try {
      const { page } = listData;
      setCustomLoading((prevState) => ({ ...prevState, list: true }));
      const query = { ...listData, searchText: searchByInput, page };
      delete query.docs;
      const { data } = await fetchAuditHistory(collection, recordId, query);
      if (data) {
        if (data.page === 1) {
          setListData({ ...listData, ...data });
        } else {
          setListData({ ...listData, docs: [...listData.docs, ...data.docs] });
        }
      }
    } catch (e) {
      const { message: exception } = apiErrorHandler(e);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, list: false }));
    }
  }

  const handleOnSearch = async (event) => {
    const { value } = event.target;
    setSearchByInput(value);
  };

  /**
   * Trigger when scroll bar reach the bottom
   */
  const handleOnReachEnd = () => {
    setListData((prevState) => ({
      ...prevState,
      searchByText: debouncedSearchBy,
      page: prevState.page === prevState.totalPages ? prevState.page : prevState.page + 1,
    }));
  };

  return (
    <div className="my-2">
      <div className="flex flex-row mb-3 items-center gap-5">
        {isTitleVisible && (
          <h2 className="text-spaceCadetBlue font-bold font-ptScanCap text-base">
            System Transactions
          </h2>
        )}
        <div className="w-[400px]">
          <SearchBar
            placeholder="Search transaction ID or description"
            searchByInput={searchByInput}
            onSearch={handleOnSearch}
          />
        </div>
      </div>
      <hr className="my-4" />
      <CustomTable
        tableData={listData}
        onPageChange={handlePageChange}
        handleEdit={() => {}}
        loading={{ list: customLoading.list }}
        showPageLimit={false}
        fixedHeight
        onReachEnd={handleOnReachEnd}
        isPaginate={false}
      />
    </div>
  );
}

Transactions.propTypes = {
  recordId: PropTypes.string,
  collection: PropTypes.string,
  transactionTableHeaders: PropTypes.array,
  triggerChild: PropTypes.any,
  isTitleVisible: PropTypes.bool,
};

export default Transactions;
