export const CLIENT_STATUS = [
  {
    value: 'active',
    label: 'Active',
  },
  {
    value: 'inactive',
    label: 'Inactive',
  },
  {
    value: 'unapproved',
    label: 'Unapproved',
  },
];
