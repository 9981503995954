import React from 'react';
import PropTypes from 'prop-types';

import DeleteBtn from '../../common/DeleteBtn';
import { IUrlHeader } from '../interfaces';
import VariableInputField from '../../common/VariableInputField';

interface IUrlHeaderProps extends IUrlHeader {
  handleDeleteHeader: (headerId: string) => void;
  handleUpdateHeader: (headerId: string, updatedHeaderData: Partial<IUrlHeader>) => void;
}

function RequestHeader(props: IUrlHeaderProps) {
  const { _id, headerKey, value, handleDeleteHeader, handleUpdateHeader } = props;

  const handleHeaderValueUpdate = (updatedValue: string) => {
    handleUpdateHeader(_id, { value: updatedValue });
  };

  const handleHeaderKeyUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedKey = e.target.value;
    handleUpdateHeader(_id, { headerKey: updatedKey });
  };

  return (
    <div className="flex items-center gap-x-2">
      <div className="flex-grow">
        <input
          className="flow-builder__input w-1/2 mr-2"
          type="text"
          placeholder="key"
          value={headerKey || ''}
          onChange={handleHeaderKeyUpdate}
        />
      </div>

      <VariableInputField value={value} handleInputChange={handleHeaderValueUpdate} />

      <DeleteBtn handleOnClick={() => handleDeleteHeader(_id)} />
    </div>
  );
}

RequestHeader.propTypes = {
  headerKey: PropTypes.string,
  value: PropTypes.string,
  handleDeleteHeader: PropTypes.func,
  handleUpdateHeader: PropTypes.func,
};

export default RequestHeader;
