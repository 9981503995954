import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { RootState } from 'slices';
import { reactSelectCustomStyles } from 'styles/select';
import VariableDropdown from '../../../VariableDropdown';
import DeleteBtn from '../../common/DeleteBtn';
import { IMappingSetting, IVariableOption } from '../interfaces';

interface IMappingSettingProps extends IMappingSetting {
  handleDeleteMappingSetting: (headerId: string) => void;
  handleUpdateMappingSetting: (
    mappingSettingId: string,
    updatedMappingSettingData: Partial<IMappingSetting>,
  ) => void;
}

function ResponseField(props: IMappingSettingProps) {
  const { _id, mapKey, variable, handleDeleteMappingSetting, handleUpdateMappingSetting } = props;
  const { variables } = useSelector((state: RootState) => state.flow);

  /**
   * Handle mapping key change
   * @param { React.ChangeEvent<HTMLInputElement>} e - Onchange event
   */
  const handleUpdateMappingKey = (e: React.ChangeEvent<HTMLInputElement>) => {
    const mappingKey = e.target.value;
    handleUpdateMappingSetting(_id, { mapKey: mappingKey });
  };

  /**
   * Handle variable change
   * @param {string} updatedVariable - onchange event
   */
  const handleUpdateVariable = (updatedVariableOption: IVariableOption) => {
    const { value } = updatedVariableOption;
    handleUpdateMappingSetting(_id, { variable: value });
  };

  return (
    <div className="flex items-center gap-x-2">
      <div className="flex-grow">
        <input
          className="api-request__input flow-builder__input"
          type="text"
          placeholder="$[somekey]"
          value={mapKey || ''}
          onChange={handleUpdateMappingKey}
        />
      </div>

      <div className="w-1/2">
        <VariableDropdown
          id={_id}
          options={variables}
          placeholder="Select variable"
          variablesOnly
          value={variables.find((option) => option.value === variable)}
          onChange={handleUpdateVariable}
          customStyles={reactSelectCustomStyles}
        />
      </div>

      <DeleteBtn handleOnClick={() => handleDeleteMappingSetting(_id)} />
    </div>
  );
}

ResponseField.propTypes = {
  _id: PropTypes.string,
  mapKey: PropTypes.string,
  variable: PropTypes.string,
  handleDeleteMappingSetting: PropTypes.func,
  handleUpdateMappingSetting: PropTypes.func,
};

export default ResponseField;
