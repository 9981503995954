import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-daisyui';

import { PrimaryButton } from 'components';
import { Confirm, NewMessageIcon } from 'components/Icons';
import { S3_ASSETS } from 'constants/assets';

function NewMessage(props) {
  const { contact, messages, handleTakeChat, handleClose, sequenceId, _id } = props;

  return (
    <div className="top-0 left-0 right-0 p-0 border border-gainsboroBlue rounded-lg z-50 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(auto-1rem)] w-[479px] max-h-full">
      <div className="font-bold pb-2 mb-2 p-4 bg-white">
        <div className="flex justify-between items-center">
          <div className="w-fit flex items-center">
            <NewMessageIcon />
            <div className="text-xl text-independenceBlue pl-2 font-bold font-ptScanCap">
              New Message
            </div>
          </div>
          <div className="text-sm font-normal bg-red cursor-pointer">
            <Button
              size="xs"
              shape="circle"
              onClick={handleClose}
              className="btn btn-xs btn-circle border-2 bg-white border-rasinBlack font-bold"
            >
              x
            </Button>
          </div>
        </div>
        <hr className="mt-4" />
      </div>
      <div>
        <div className="px-4 py-3 -mt-4 bg-white">
          <div className="flex items-center justify-start p-1">
            <div className="avatar max-w-[80px] max-h-[80px] px-2">
              <img
                src={contact?.profilePic?.location || S3_ASSETS.CHAT_PREVIEW_PLACEHOLDER.url}
                alt="avatar"
                style={{ maxWidth: '100%', maxHeight: '100%' }}
              />
            </div>
            <div className="px-4 items-center justify-center">
              {contact?.firstName || contact?.lastName ? (
                <p className="text-lg text-rasinBlack font-bold">
                  {contact?.firstName || ''} {contact?.lastName || ''}
                </p>
              ) : (
                <p className="text-lg text-rasinBlack font-bold">Unsaved Contact</p>
              )}
              <p className="pt-2 text-sm text-rasinBlack max-w-[50vh] overflow-hidden">
                {messages?.text}
              </p>
            </div>
          </div>
        </div>

        <div className="w-full pt-5 pb-5 border-t bg-culturedGray flex flex-wrap justify-center items-center gap-3">
          <PrimaryButton
            text="Take Chat"
            btnColor="bg-spaceCadetBlue80"
            btnHoverColor="hover:bg-spaceCadetBlue"
            color="white"
            icon={<Confirm />}
            handleOnClick={(event) => {
              event.preventDefault();
              handleTakeChat({ sequenceId, conversationId: _id });
            }}
            textSize="base"
            radius="2xl"
          />
        </div>
      </div>
    </div>
  );
}

NewMessage.propTypes = {
  contact: PropTypes.any,
  messages: PropTypes.any,
  handleTakeChat: PropTypes.func,
  handleClose: PropTypes.func,
  sequenceId: PropTypes.string,
  _id: PropTypes.string,
};

export default NewMessage;
