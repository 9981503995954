import { defaultTransactionTableHeaders } from 'components/Table/Transactions/constants';
import { COLLECTIONS } from 'constants/db';
import { MODULES } from 'constants/modules';
import {
  CollectCustomerFeedbackVia,
  InstanceType,
  MessengerConnectedVia,
  WelcomeMessageOption,
} from 'enums/messenger.enum';

export const transactionTableHeaders = [
  ...defaultTransactionTableHeaders,
  {
    key: 'updatedValues',
    value: 'Data',
    type: 'transactionUpdatedValues',
    module: COLLECTIONS.Messenger,
  },
];

export const promptDefaultOptions = {
  show: false,
  title: '',
  message: '',
  onConfirm: null,
  type: null,
  data: null,
  loading: false,
};

export const promptModalDefaultOptions = {
  show: false,
  title: '',
  message: '',
  onConfirm: null,
  type: null,
};

export const modalDefaultOptions = {
  show: false,
  actionStatus: false,
  type: null,
  modalData: {},
};

export enum ModalTypes {
  LinkChannel = 'LinkChannel',
  ContactInformationSetting = 'ContactInformationSetting',
  ContactInfoForm = 'ContactInfoForm',
  CustomerFeedbackSetting = 'CustomerFeedbackSetting',
  CustomerFeedbackForm = 'CustomerFeedbackForm',
  AddIntentForm = 'AddIntentForm',
  EditIntentForm = 'EditIntentForm',
  EditUtterance = 'EditUtterance',
  IntentSettings = 'IntentSetting',
}
export enum PromptTypes {
  ToggleInstance = 'ToggleInstance',
  UnlinkChannel = 'UnlinkChannel',
  DeleteUtterance = 'DeleteUtterance',
  ToggleAccount = 'ToggleAccount',
  DeleteKnowledgeBase = 'DeleteKnowledgeBase',
}

export const defaultFormPlaceholders = {
  name: 'Hi, This is {ChatBot Name}',
  tagline: 'How may I assist you today? ',
  displayName: 'Chat Assistant',
  conversationEndMessage: 'Conversation End Message',
  defaultWelcomeMessage: '',
  description: '',
  autoExpireConversationInMin: '5',
  conversationExpiryMessageDisplayTimeout: '3',
  privacyPolicyLink: 'https://tasa.com.my/privacy-policy',
  customerFeedbackAutoExpireInMin: '15',
};

export const defaultFormData = {
  color: '#192A51',
  contactInfoForm: {},
  description: '',
  welcomeMessageOption: WelcomeMessageOption.DoNotSend,
  autoExpireConversationInMin: 5,
  conversationExpiryMessageDisplayTimeout: 3,
  connectedVia: MessengerConnectedVia.WebsiteWidget,
};
export const customLoadingOptions = {
  formSubmit: false,
  fetchRoles: false,
  toggleAccount: false,
  fetchForm: false,
  fetchClients: false,
  approveAccount: false,
  sendForApproval: false,
};

export const INSTANCE_TYPES = [
  {
    value: InstanceType.DirectMessaging,
    label: 'Direct Messaging',
  },
  {
    value: InstanceType.Chatbot,
    label: 'Chatbot',
  },
];

export const SEND_WELCOME_MESSAGE_OPTIONS = [
  {
    value: WelcomeMessageOption.DoNotSend,
    label: 'Don’t Send',
  },
  {
    value: WelcomeMessageOption.Send,
    label: 'Send Welcome Message',
  },
];

export const linkedChannelsTableHeaders = [
  {
    key: 'channelName',
    value: 'Channel Name',
  },
  {
    key: 'addedAt',
    value: 'Added At',
    type: 'dateTime',
  },
  {
    key: 'addedBy',
    value: 'Added By',
    type: 'transactionUser',
  },
  {
    key: '_id',
    value: 'Manage',
    type: 'customBtn',
    module: MODULES.SETTINGS.MESSENGERS.FORM_LINKED_CHANNELS,
    permissions: [],
  },
];

export const intentsTableHeaders = [
  {
    key: 'intentName',
    value: 'Intent Name',
    sortingEnabled: true,
  },
  {
    key: 'createdAt',
    value: 'Added At',
    type: 'dateTime',
    sortingEnabled: true,
  },
  {
    key: 'updatedAt',
    value: 'Updated At',
    type: 'dateTime',
    sortingEnabled: true,
  },
  {
    key: 'createdBy',
    value: 'Added by',
    type: 'transactionUser',
  },
  {
    key: '_id',
    value: 'Actions',
    type: 'intentActions',
  },
];
export const utterancesTableHeaders = [
  {
    key: 'utterance',
    value: 'Utterances',
  },
  {
    key: 'createdAt',
    value: 'Created At',
    type: 'dateTime',
  },
  {
    key: 'createdBy',
    value: 'Added by',
  },
  {
    key: '_id',
    value: 'Actions',
    type: 'actionButtons',
  },
];

export const knowledgeBaseTableHeaders = [
  {
    key: 'fileName',
    value: 'File Name',
    sortingEnabled: true,
  },
  {
    key: 'createdAt',
    value: 'Uploaded At',
    type: 'dateTime',
    sortingEnabled: true,
  },
  {
    key: 'createdBy',
    value: 'Added by',
    type: 'transactionUser',
  },
  {
    key: '_id',
    value: 'Actions',
    type: 'deleteBtn',
  },
];

export const collectFeedbackViaOptions = [
  { label: 'Form', value: CollectCustomerFeedbackVia.Form },
  { label: 'Conversation', value: CollectCustomerFeedbackVia.Conversation },
];
