let ENV = process.env.REACT_APP_ENV || '';

if (ENV) {
  ENV = ENV.trim();
  ENV = ENV.toLowerCase();
}

export const getBaseUrl = () => {
  if (ENV === 'production') {
    // return 'http://ec2-18-142-253-126.ap-southeast-1.compute.amazonaws.com:4001';
    return 'https://staging.api.portal.messenger.tasa.com.my';
  }
  if (ENV === 'qa') {
    // return 'http://ec2-18-142-253-126.ap-southeast-1.compute.amazonaws.com:4001';
    return 'https://staging.api.portal.messenger.tasa.com.my';
  }
  if (ENV === 'beta') {
    // return 'http://ec2-18-142-253-126.ap-southeast-1.compute.amazonaws.com:4001';
    return 'https://staging.api.portal.messenger.tasa.com.my';
  }
  // return 'https://staging.api.portal.messenger.tasa.com.my';
  // return 'http://ec2-18-142-253-126.ap-southeast-1.compute.amazonaws.com:4001';
  return 'http://localhost:4001';
};
