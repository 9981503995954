import { COLLECTIONS } from 'constants/db';

export const categories = [{ value: 'general', label: 'General' }];

export const Severities = [
  { value: 'high', label: 'High' },
  { value: 'medium', label: 'Medium' },
  { value: 'critical', label: 'Critical' },
  { value: 'low', label: 'Low' },
];

export const statusOptions = [
  { value: 'open', label: 'Open' },
  { value: 'completed', label: 'Completed' },
  { value: 'deleted', label: 'Deleted' },
  { value: 'closed', label: 'Closed' },
  { value: 'reopened', label: 'Re-opened' },
  { value: 'fixed', label: 'Fixed' },
];

export const tags = [];

export enum ModalTypes {
  UpdateContact = 'UpdateContact',
  ReopenCase = 'ReopenCase',
  LinkChat = 'LinkChat',
  UnlinkChat = 'UnlinkChat',
}

export const transactionTableHeaders = [
  {
    key: 'sequenceId',
    value: 'Transaction ID',
  },
  {
    key: 'description',
    value: 'Transaction Description',
  },
  {
    key: 'createdAt',
    value: 'Transaction Date and Time',
    type: 'dateTime',
  },
  {
    key: 'createdBy',
    value: 'User',
    type: 'transactionUser',
  },
  {
    key: 'updatedValues',
    value: 'Data',
    type: 'transactionUpdatedValues',
    module: COLLECTIONS.Cases,
  },
];

export const modalDefaultOptions = {
  show: false,
  actionStatus: false,
  type: null,
  data: {},
};

export const promptDefaultOptions = {
  show: false,
  title: '',
  message: '',
  onConfirm: null,
  type: null,
};

export enum PromptTypes {
  ToggleAccount = 'ToggleAccount',
}
