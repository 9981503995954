import { uniqueId } from 'lodash';
import { ISlide } from '../../MessageDrawer/interfaces';

export const newSlide: ISlide[] = [
  {
    id: uniqueId(),
    isEnabled: false,
    name: 'title',
    position: 0,
    text: '',
  },
  {
    id: uniqueId(),
    isEnabled: false,
    name: 'subTitle',
    position: 1,
    text: '',
    buttons: [],
  },
  {
    id: uniqueId(),
    isEnabled: false,
    name: 'image',
    position: 2,
    url: '',
  },
];
