/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { CustomSelect, CustomTable, PrimaryButton } from 'components';
import GeneralButton from 'components/Buttons/GeneralButton';
import { PATHS } from 'routes/paths';
import { Export, Search, AddCase, Settings } from 'components/Icons';
import { selectCustomStylesForFilters } from 'styles/select';
import {
  CASE_STATUS,
  CASE_CATEGORY,
  CASE_SEVERITY,
  CASE_LAST_UPDATED_AT,
} from 'constants/cases.const';

import { getStatusSelected, getSeveritySelected, getLastUpdateSelected } from './utils';

function ListPage(props) {
  const {
    handleFilterBy,
    clientListData,
    handleEdit,
    handlePageChange,
    handleResultsPerPageChange,
    urlSearchParams,
    setSearchByInput,
    searchByInput,
    handleExport,
    customLoading,
    contactList,
    contactSelectedFilter,
    tagsInput,
    setTagsInput,
  } = props;

  return (
    <div className="page">
      <div className="flex flex-wrap mb-2 w-full items-center justify-between">
        <div className="flex items-center">
          <div className="pr-5 h-12 items-center flex justify-start">
            <span className="font-ptScanCap text-lg font-semibold">Case Management</span>
          </div>
          <Link to={PATHS.CASES_MANAGEMENT.FORM.url}>
            <GeneralButton
              text="Add Case"
              btnColor="bg-spaceCadetBlue80"
              color="white"
              radius="2xl"
              textSize="sm"
              btnHoverColor="hover:bg-spaceCadetBlue"
              icon={<AddCase />}
            />
          </Link>
        </div>
        <div className="grid grid-cols-1">
          <div className="mt-4 sm:mt-0">
            <PrimaryButton
              text="Export"
              btnColor="bg-spaceCadetBlue80"
              color="white"
              width="[140px]"
              radius="2xl"
              btnHoverColor="hover:bg-spaceCadetBlue"
              icon={<Export />}
              handleOnClick={handleExport}
              disabled={customLoading.list}
            />
          </div>
        </div>
      </div>
      <div className="border-t border-slate-300 mt-5" />
      <div className="container mt-4 py-1">
        <form className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-4">
          <div className="mb-0">
            <div className="relative block sm:flex items-center mb-2 sm:mb-0">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2 mt-1">
                <svg className="h-5 w-5 mb-2 fill-slate-300" viewBox="0 0 20 20">
                  <Search color="#475574" />
                </svg>
              </span>
              <input
                className="placeholder:text-sm placeholder:text-independenceBlue font-lato text-sm bg-white border border-gainsboroBlue rounded-lg h-11 py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-[#2684ff] sm:text-sm w-full rounded-xl"
                placeholder="Search by Case Subject, Description, Contact Name"
                type="text"
                name="search"
                onChange={(event) => setSearchByInput(event.target.value)}
                value={searchByInput || urlSearchParams?.searchBy || ''}
              />
            </div>
          </div>
          <div className="mb-0">
            <CustomSelect
              placeholder="Severity"
              customStyles={selectCustomStylesForFilters}
              options={CASE_SEVERITY}
              value={getSeveritySelected(urlSearchParams?.severity)}
              isClearable
              onChange={(selected) => {
                const severity = selected?.value?.toLowerCase();
                handleFilterBy(severity, 'severity');
              }}
            />
          </div>
          <div className="mb-0">
            <CustomSelect
              placeholder="Category"
              customStyles={selectCustomStylesForFilters}
              options={CASE_CATEGORY}
              // value={getStatusSelected(urlSearchParams?.status)}
              isClearable
              onChange={(selected) => {
                const category = selected?.value?.toLowerCase();
                handleFilterBy(category, 'category');
              }}
            />
          </div>
          <div className="mb-0">
            <CustomSelect
              placeholder="Status"
              customStyles={selectCustomStylesForFilters}
              options={CASE_STATUS}
              value={getStatusSelected(urlSearchParams?.status)}
              isClearable
              onChange={(selected) => {
                const status = selected?.value;
                handleFilterBy(status, 'status');
              }}
            />
          </div>
          <div className="mb-0">
            <CustomSelect
              placeholder="Last Updated at"
              customStyles={selectCustomStylesForFilters}
              options={CASE_LAST_UPDATED_AT}
              value={getLastUpdateSelected(urlSearchParams?.lastUpdatedAt)}
              isClearable
              onChange={(selected) => {
                const status = selected?.value;
                handleFilterBy(status, 'lastUpdatedAt');
              }}
            />
          </div>
          <div className="mb-0">
            <CustomSelect
              placeholder="Contact"
              customStyles={selectCustomStylesForFilters}
              options={contactList}
              value={contactSelectedFilter(urlSearchParams?.contact)}
              isClearable
              onChange={(selected) => {
                const contact = selected?.value;
                handleFilterBy(contact, 'contact');
              }}
            />
          </div>
          <div className="mb-0">
            <input
              className="placeholder:text-sm placeholder:text-independenceBlue font-lato text-sm bg-white border border-gainsboroBlue rounded-lg h-11 py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-[#2684ff] sm:text-sm w-full rounded-xl"
              placeholder="Search by tags"
              type="text"
              name="search"
              onChange={(event) => setTagsInput(event.target.value)}
              value={tagsInput || urlSearchParams?.tags || ''}
            />
          </div>
        </form>
      </div>

      <CustomTable
        tableData={clientListData}
        onPageChange={handlePageChange}
        handleResultsPerPageChange={handleResultsPerPageChange}
        handlers={{ onManage: handleEdit }}
        loading={{ list: customLoading.list }}
      />
    </div>
  );
}

ListPage.propTypes = {
  handleFilterBy: PropTypes.func,
  handlePageChange: PropTypes.func,
  setSearchByInput: PropTypes.func,
  handleExport: PropTypes.func,
  searchByInput: PropTypes.string,
  handleEdit: PropTypes.func,
  handleResultsPerPageChange: PropTypes.func,
  tagsInput: PropTypes.string,
  setTagsInput: PropTypes.func,
  urlSearchParams: PropTypes.shape({
    page: PropTypes.any,
    country: PropTypes.string,
    sort: PropTypes.string,
    status: PropTypes.string,
    severity: PropTypes.string,
    category: PropTypes.string,
    lastUpdatedAt: PropTypes.string,
    contact: PropTypes.string,
    searchBy: PropTypes.string,
    tags: PropTypes.string,
  }),
  clientListData: PropTypes.shape({
    page: PropTypes.number,
  }),
  contactList: PropTypes.any,
  customLoading: PropTypes.any,
  contactSelectedFilter: PropTypes.func,
};

export default ListPage;
