/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { CustomSelect } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { updateActionSettings } from 'slices/flow';
import { RootState } from 'slices';
import { getValueForSelect } from 'utils/helpers';
import TextInput from 'components/Form/InputText';
import { fetchUserListData } from 'services/user.service';
import { getClientId } from 'utils/auth';
import { apiErrorHandler } from 'api/handler';
import { toast } from 'react-toastify';
import InfoIcon from './components/tempIcons/Info';
import CreateVariableModal from '../../Modals/CreateVariableModal';
import VariableDropdown from '../../VariableDropdown';
import { groupedOptions } from '../../constants';
import { IAssignUser, IPausedHours } from './interfaces';
import {
  isDateValid,
  isDatetimeValid,
  isEmailValid,
  isNotEmpty,
  isNumberValid,
  isPhoneNumberValid,
  isTimeValid,
  isUrlValid,
} from './utils';

interface IActionDrawerProps {
  nodeId: string;
  toggleDrawer: () => void;
}

const ActionDrawer = forwardRef(({ toggleDrawer, nodeId }: IActionDrawerProps, ref) => {
  const dispatch = useDispatch();
  const flowData = useSelector((state: RootState) => state.flow);

  const inputRef = useRef(null);

  const [selectedOption, setSelectedOption] = useState('openChat');
  const [webhookSettings, setWebhookSettings] = useState({
    title: '',
    url: '',
  });
  const [openChatSettings, setOpenChatSettings] = useState({
    enableChangeAssignee: false,
    enablePausedHours: false,
  });

  const [variableList, setVariableList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [variableValue, setVariableValue] = useState('');
  const [selectedVariable, setSelectedVariable] = useState<any>({
    type: '',
    value: '',
  });
  const [selectedDeleteVariable, setSelectedDeleteVariable] = useState(null);
  const [pausedHours, setPausedHours] = useState<IPausedHours>();
  const [assignedUser, setAssignedUser] = useState<IAssignUser>();

  const [showTimeMenu, setShowTimeMenu] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors] = useState({ isValid: false, variableType: null });
  const [webhookErrors, setWebhookErrors] = useState({
    url: { isValid: true, error: '' },
    title: { isValid: true, error: '' },
  });

  useEffect(() => {
    const actionFlowObj = flowData.nodeConfigs.find((obj) => obj.nodeId === nodeId);
    getUserListData();
    if (actionFlowObj) {
      setSelectedOption(actionFlowObj?.action);
      setWebhookSettings({ title: actionFlowObj?.title || '', url: actionFlowObj?.url || '' });
      setOpenChatSettings({
        enableChangeAssignee: actionFlowObj?.enableChangeAssignee || false,
        enablePausedHours: actionFlowObj?.enablePausedHours || false,
      });
      setPausedHours(actionFlowObj?.pausedHours);
      setSelectedVariable({ type: actionFlowObj?.variableType, value: actionFlowObj?.variable });
      setVariableValue(actionFlowObj?.value);
      setSelectedDeleteVariable(actionFlowObj?.variable);
      setAssignedUser(actionFlowObj?.assignedUser);
    }
  }, []);

  useEffect(() => {
    if (flowData?.variables.length > 0) {
      setVariableList(flowData?.variables);
    }
  }, [flowData?.variables]);

  // handle `variableValue` text field focus while typing
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [variableValue]);

  /** get the user list for assign chat dropdown */
  const getUserListData = async () => {
    try {
      const { data: userData } = await fetchUserListData({
        isExceptCurrentUser: 1,
        clientId: getClientId(),
      });
      if (userData) {
        const mappedData = userData?.docs.map((obj) => ({
          id: obj?._id,
          label: obj?.name,
          value: obj?._id,
        }));
        setUserList(mappedData);
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    }
  };

  const handleRadioChange = (event) => {
    const { value } = event.target;
    setSelectedOption(value);
  };

  /**
   * Handle dropdown based field changes
   */
  const handleChange = (event) => {
    const { id, value } = event.target;
    switch (selectedVariable?.type) {
      case 'email':
        setErrors({ isValid: isEmailValid(value), variableType: selectedVariable?.type });
        break;
      case 'number':
        setErrors({ isValid: isNumberValid(value), variableType: selectedVariable?.type });
        break;
      case 'datetime':
        setErrors({ isValid: isDatetimeValid(value), variableType: selectedVariable?.type });
        break;
      case 'date':
        setErrors({ isValid: isDateValid(value), variableType: selectedVariable?.type });
        break;
      case 'time':
        setErrors({ isValid: isTimeValid(value), variableType: selectedVariable?.type });
        break;
      case 'phone':
        setErrors({ isValid: isPhoneNumberValid(value), variableType: selectedVariable?.type });
        break;
      case 'url':
        setErrors({ isValid: isUrlValid(value), variableType: selectedVariable?.type });
        break;
      default:
        break;
    }

    if (id === 'variableValue') {
      setVariableValue(value);
    } else {
      setWebhookSettings((prevSettings) => ({
        ...prevSettings,
        [id]: value,
      }));
    }
  };

  const handleSubmit = () => {
    if (
      (selectedOption === 'setVariable' && (!selectedVariable?.value || !variableValue)) ||
      (selectedOption === 'deleteVariable' && !selectedDeleteVariable)
    ) {
      return;
    }
    if (selectedOption === 'webhook') {
      if (!isNotEmpty(webhookSettings.title)) {
        setWebhookErrors((prev) => ({
          ...prev,
          title: { isValid: false, error: 'Title is required' },
        }));
        return;
      }
      if (!isNotEmpty(webhookSettings.url)) {
        setWebhookErrors((prev) => ({
          ...prev,
          url: { isValid: false, error: 'Url is required' },
        }));
        return;
      }
      if (!isUrlValid(webhookSettings.url)) {
        setWebhookErrors((prev) => ({ ...prev, url: { isValid: false, error: 'Invalid url' } }));
        return;
      }
      setWebhookErrors({
        title: { isValid: true, error: '' },
        url: { isValid: true, error: '' },
      });
    } else if (
      !errors?.isValid &&
      selectedOption !== 'deleteVariable' &&
      selectedOption !== 'setVariable' &&
      selectedOption !== 'webhook'
    ) {
      toast.error(`Invalid ${errors?.variableType}`);
      return;
    }

    const actionSettingsMap = {
      openChat: {
        action: 'openChat',
        ...openChatSettings,
        pausedHours: pausedHours || {},
        assignedUser: assignedUser || {},
      },
      setVariable: {
        action: 'setVariable',
        variableType: selectedVariable?.type,
        variable: selectedVariable?.value,
        value: variableValue,
      },
      deleteVariable: { action: 'deleteVariable', variable: selectedDeleteVariable },
      webhook: { action: 'webhook', ...webhookSettings },
    };

    const mappedData = actionSettingsMap[selectedOption];

    dispatch(
      updateActionSettings({
        nodeId,
        newData: { ...mappedData },
      }),
    );
    toggleDrawer();
  };

  const handleSelectValue = (e, obj) => {
    setPausedHours(obj);
    setShowTimeMenu(!showTimeMenu);
    e.stopPropagation();
  };

  const handleSelectUserValue = (e, obj) => {
    setAssignedUser(obj);
    setShowUserMenu(!showUserMenu);
    e.stopPropagation();
  };

  const handleCheckChange = (event) => {
    const { id, checked } = event.target;
    setOpenChatSettings({
      ...openChatSettings,
      [id]: checked,
    });
  };

  const handleSelectChange = (event, id: string) => {
    if (id === 'deleteVariable') {
      setSelectedDeleteVariable(event?.value || '');
    }
    if (id === 'setVariable') {
      setVariableValue('');
      setSelectedVariable({ type: event?.type || '', value: event?.value || '' });
    }
  };

  const insertValueAtCursorPosition = (value: string) => {
    const currentInputValue = variableValue || '';

    const updatedText = `${currentInputValue} {{${value}}}`;
    setVariableValue(updatedText);
  };

  function VariableFieldsBuilder({ variableType }) {
    switch (variableType) {
      case 'string':
        return (
          <div className="relative">
            <input
              ref={inputRef}
              className="w-full rounded-md border-[1px] focus:outline-none p-2  relative z-10 flex-grow focus:border-blue-500"
              value={variableValue}
              id="variableValue"
              placeholder="String"
              onChange={handleChange}
            />
            <div className="absolute top-1 right-1 z-[99]">
              <VariableDropdown
                id="url"
                options={groupedOptions}
                onChange={(selectOption) => {
                  if (selectOption) {
                    insertValueAtCursorPosition(selectOption.value);
                  }
                }}
              />
            </div>
          </div>
        );
      case 'number': {
        const isInvalid = !errors?.isValid && errors?.variableType === variableType;

        return (
          <div className="relative">
            <input
              ref={inputRef}
              className={`w-full rounded-md border-[1px] focus:outline-none p-2  relative z-10 flex-grow ${
                isInvalid ? 'border-red-500' : 'focus:border-blue-500'
              }`}
              value={variableValue}
              id="variableValue"
              placeholder="Number"
              type="number"
              onChange={handleChange}
            />
            {isInvalid && (
              <div className="flex justify-end text-xs text-red-500">Invalid number</div>
            )}
          </div>
        );
      }
      case 'datetime': {
        const isInvalid = !errors?.isValid && errors?.variableType === variableType;

        return (
          <TextInput
            elementId="variableValue"
            type="datetime-local"
            placeholder="Date and Time"
            value={variableValue}
            onChange={handleChange}
            error={isInvalid ? { message: 'Invalid date time format, use YYYY-MM-DDTHH:MM' } : null}
          />
        );
      }
      case 'date': {
        const isInvalid = !errors?.isValid && errors?.variableType === variableType;

        return (
          <TextInput
            elementId="variableValue"
            type="date"
            placeholder="Date"
            value={variableValue}
            onChange={handleChange}
            error={isInvalid ? { message: 'Invalid date format, use YYYY-MM-DD' } : null}
          />
        );
      }
      case 'time': {
        const isInvalid = !errors?.isValid && errors?.variableType === variableType;

        return (
          <TextInput
            elementId="variableValue"
            type="time"
            placeholder="Time"
            value={variableValue}
            onChange={handleChange}
            error={isInvalid ? { message: 'Invalid time format, use HH:MM' } : null}
          />
        );
      }
      case 'email': {
        const isInvalid = !errors?.isValid && errors?.variableType === variableType;

        return (
          <div className="relative">
            <input
              ref={inputRef}
              className={`w-full rounded-md border-[1px] focus:outline-none p-2  relative z-10 flex-grow ${
                isInvalid ? 'border-red-500' : ' focus:border-blue-500'
              }`}
              value={variableValue}
              id="variableValue"
              placeholder="eg: johndoe@gmail.com"
              type="email"
              onChange={handleChange}
            />
            {isInvalid && (
              <div className="flex justify-end text-xs text-red-500">Invalid email</div>
            )}
          </div>
        );
      }
      case 'url': {
        const isInvalid = !errors?.isValid && errors?.variableType === variableType;

        return (
          <div className="relative">
            <input
              ref={inputRef}
              className={`w-full rounded-md border-[1px] focus:outline-none p-2  relative z-10 flex-grow ${
                isInvalid ? 'border-red-500' : ' focus:border-blue-500'
              }`}
              value={variableValue}
              id="variableValue"
              placeholder="eg: https://example.com"
              type="email"
              onChange={handleChange}
            />
            {isInvalid && <div className="flex justify-end text-xs text-red-500">Invalid url</div>}
          </div>
        );
      }
      case 'phone': {
        const isInvalid = !errors?.isValid && errors?.variableType === variableType;

        return (
          <div className="relative">
            <input
              ref={inputRef}
              className={`w-full rounded-md border-[1px] focus:outline-none p-2 relative z-10 flex-grow ${
                isInvalid ? 'border-red-500' : 'focus:border-blue-500'
              }`}
              value={variableValue}
              id="variableValue"
              placeholder="Phone number"
              onChange={(event) => {
                const { value } = event.target;

                // Sanitize the input value to allow only numerical digits and '+'
                const sanitizedValue = value.replace(/[^0-9+]/g, '');

                // Update the state with the sanitized value
                handleChange({ target: { id: 'variableValue', value: sanitizedValue } });
              }}
            />

            <div className="absolute top-1 right-1 z-[99]">
              <VariableDropdown
                id="url"
                options={groupedOptions}
                onChange={(selectOption) => {
                  if (selectOption) {
                    insertValueAtCursorPosition(selectOption.value);
                  }
                }}
              />
            </div>
            {isInvalid && (
              <div className="flex justify-end text-xs text-red-500">Invalid phone number</div>
            )}
          </div>
        );
      }
      default:
        return null;
    }
  }

  // Expose the handleSubmit function to the parent component using useImperativeHandle
  useImperativeHandle(ref, () => ({
    handleSubmit,
  }));

  return (
    <>
      <span className="text-base font-semibold">Choose an action</span>
      {/* <div className="flex flex-col items-start bg-white w-full
      rounded-lg cursor-pointer mt-2 p-4">
        <div className="flex items-center">
          <input
            type="radio"
            name="option"
            value="openChat"
            checked={selectedOption === 'openChat'}
            onChange={handleRadioChange}
          />
          <span className="ml-2">Open the chat</span>
        </div>
        <p className="text-xs text-gray-500 pl-5">Escalate the chat to an admin</p>
        {selectedOption === 'openChat' && (
          <>
            <div className="flex items-center mt-2 pl-5">
              <input
                type="checkbox"
                id="enableChangeAssignee"
                onChange={handleCheckChange}
                checked={openChatSettings.enableChangeAssignee}
              />
              <label htmlFor="changeChat" className="ml-2 flex items-center relative">
                Change the chat assignee to{' '}
                {openChatSettings?.enableChangeAssignee && (
                  <>
                    <button
                      type="button"
                      className={`rounded-md bg-none drop-shadow-xl ml-1 flex items-center ${
                        showUserMenu ? 'text-red-600' : 'text-spaceCadetBlue'
                      }`}
                      onClick={() => {
                        setShowUserMenu(!showUserMenu);
                      }}
                    >
                      {assignedUser?.label}&nbsp;
                      {showUserMenu ? (
                        <span style={{ fontSize: '0.7em' }}>&#9650;</span>
                      ) : (
                        <span style={{ fontSize: '0.7em' }}>&#9660;</span>
                      )}
                    </button>
                    {showUserMenu && (
                      <div className="absolute -right-[1%] z-50 top-4
                      mt-2 transition-transform duration-300 ease-in drop-shadow-lg
                      bg-white w-[150px] h-auto flex flex-wrap justify-between rounded-md">
                        {userList.map(({ id, label, value }) => (
                          <button
                            key={id}
                            className="hover:bg-green-50 bg-white w-full
                            h-auto cursor-pointer text-start p-2"
                            id={id.toString()}
                            type="button"
                            onClick={(e) => handleSelectUserValue(e, { id, label, value })}
                          >
                            <span className="mt-2">{label}</span>
                          </button>
                        ))}
                      </div>
                    )}
                  </>
                )}
              </label>
            </div>
            <div className="relative mt-1 pl-5 flex items-center">
              <input
                type="checkbox"
                id="enablePausedHours"
                onChange={handleCheckChange}
                checked={openChatSettings.enablePausedHours}
                className="mr-2"
              />
              <label htmlFor="pauseAutomation" className="flex items-center relative">
                Pause bot automation for{' '}
                {openChatSettings?.enablePausedHours && (
                  <>
                    <button
                      type="button"
                      className={`rounded-md bg-none drop-shadow-xl ml-1 flex items-center ${
                        showTimeMenu ? 'text-red-600' : 'text-spaceCadetBlue'
                      }`}
                      onClick={() => {
                        setShowTimeMenu(!showTimeMenu);
                      }}
                    >
                      {pausedHours.label}&nbsp;
                      {showTimeMenu ? (
                        <span style={{ fontSize: '0.7em' }}>&#9650;</span>
                      ) : (
                        <span style={{ fontSize: '0.7em' }}>&#9660;</span>
                      )}
                    </button>
                    {showTimeMenu && (
                      <div className="absolute -right-[30%]
                      top-4 mt-2 transition-transform duration-300 ease-in drop-shadow-lg
                      bg-white w-[150px] h-auto flex flex-wrap justify-between rounded-md">
                        {[
                          { id: 1, label: 'an hour', value: '1h' },
                          { id: 2, label: '3 hours', value: '3h' },
                          { id: 3, label: '6 hours', value: '6h' },
                          { id: 4, label: 'day', value: '24h' },
                        ].map(({ id, label, value }) => (
                          <button
                            key={id}
                            className="hover:bg-green-50
                            bg-white w-full h-auto cursor-pointer text-start p-2"
                            id={id.toString()}
                            type="button"
                            onClick={(e) => handleSelectValue(e, { id, label, value })}
                          >
                            <span className="mt-2">{label}</span>
                          </button>
                        ))}
                      </div>
                    )}
                  </>
                )}
              </label>
            </div>
          </>
        )}
      </div> */}

      <div className="flex flex-col items-start bg-white w-full rounded-lg cursor-pointer mt-2 p-4">
        <div className="flex items-center">
          <input
            type="radio"
            name="option"
            value="setVariable"
            checked={selectedOption === 'setVariable'}
            onChange={handleRadioChange}
          />
          <span className="ml-2">Set variable</span>
        </div>
        <p className="text-xs text-gray-500 pl-5">Update a variable&apos;s value</p>
        {selectedOption === 'setVariable' && (
          <>
            <div className="px-4 w-full">
              <div className="form-control">
                <label className="label justify-between" htmlFor="category">
                  <span className="label-text font-bold text-sm font-lato">Select variable</span>
                  <button
                    type="button"
                    className="rounded-md bg-none drop-shadow-xl mt-2 text-[#52b7c9] text-xs relative z-50"
                    onClick={() => {
                      setShowModal(!showModal);
                    }}
                  >
                    Create variable
                  </button>
                </label>
              </div>
              <CustomSelect
                id="setVariable"
                options={variableList}
                value={getValueForSelect(selectedVariable?.value, variableList)}
                onChange={(e) => handleSelectChange(e, 'setVariable')}
                applyEmptyValidation
                required
              />
            </div>
            {selectedVariable?.type && (
              <div className="px-4 w-full">
                <div className="form-control">
                  <label className="label" htmlFor="category">
                    <span className="label-text font-bold text-sm font-lato flex items-center gap-2">
                      Set variable value
                    </span>
                  </label>
                </div>

                <VariableFieldsBuilder variableType={selectedVariable?.type} />
              </div>
            )}
          </>
        )}
      </div>
      <div className="flex flex-col items-start bg-white w-full rounded-lg cursor-pointer mt-2 p-4">
        <div className="flex items-center">
          <input
            type="radio"
            name="option"
            value="deleteVariable"
            checked={selectedOption === 'deleteVariable'}
            onChange={handleRadioChange}
          />
          <span className="ml-2">Delete variable</span>
        </div>
        {selectedOption === 'deleteVariable' && (
          <div className="px-4 w-full">
            <div className="form-control">
              <label className="label" htmlFor="category">
                <span className="label-text font-bold text-sm font-lato flex items-center gap-2">
                  Select variable
                </span>
              </label>
            </div>
            <CustomSelect
              id="deleteVariable"
              required
              isClearable
              options={variableList}
              value={getValueForSelect(selectedDeleteVariable, variableList)}
              onChange={(e) => handleSelectChange(e, 'deleteVariable')}
            />
          </div>
        )}
      </div>
      <div className="flex flex-col items-start bg-white w-full rounded-lg cursor-pointer mt-2 p-4">
        <div className="flex items-center">
          <input
            type="radio"
            name="option"
            value="webhook"
            checked={selectedOption === 'webhook'}
            onChange={handleRadioChange}
          />
          <span className="ml-2">Send webhook</span>
        </div>
        <p className="text-xs text-gray-500 pl-5">
          Send a POST request with subscribers’ data to an external URL
        </p>
        {selectedOption === 'webhook' && (
          <>
            <div className="px-4 w-full">
              <div className="form-control">
                <label className="label justify-between" htmlFor="category">
                  <span className="label-text font-bold text-sm font-lato flex items-center">
                    Webhook title
                    <div
                      className="tooltip-container"
                      data-tooltip="Key 'title' of the request body"
                    >
                      <InfoIcon />
                    </div>
                  </span>
                </label>
              </div>
              <input
                className={`w-full rounded-md border-[1px] focus:outline-none p-2 relative z-10 flex-grow ${
                  !webhookErrors.title.isValid ? 'border-red-500' : 'focus:border-blue-500'
                }`}
                id="title"
                value={webhookSettings.title}
                onChange={handleChange}
              />
              {!webhookErrors.title.isValid && (
                <div className="text-red-500 text-xs italic">{webhookErrors.title.error}</div>
              )}
            </div>
            <div className="px-4 w-full">
              <div className="form-control">
                <label className="label justify-between" htmlFor="category">
                  <span className="label-text font-bold text-sm font-lato">URL</span>
                </label>
              </div>
              <input
                className={`w-full rounded-md border-[1px] focus:outline-none p-2 relative z-10 flex-grow ${
                  !webhookErrors.url.isValid ? 'border-red-500' : 'focus:border-blue-500'
                }`}
                value={webhookSettings.url}
                id="url"
                onChange={handleChange}
                placeholder="https://example.com/handler.php"
              />
              {!webhookErrors.url.isValid && (
                <div className="text-red-500 text-xs italic">{webhookErrors.url.error}</div>
              )}
            </div>
          </>
        )}
      </div>
      {showModal && (
        <CreateVariableModal
          show={showModal}
          onCancel={() => {
            setShowModal(!showModal);
          }}
          setSelectedVariable={setSelectedVariable}
        />
      )}
    </>
  );
});

ActionDrawer.propTypes = {
  toggleDrawer: PropTypes.func,
  nodeId: PropTypes.string,
};

export default ActionDrawer;
