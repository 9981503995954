import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { RootState } from 'slices';
import { markInvalidNodes, clearHistory, redo, undo, handleFlowSaveStatus } from 'slices/flow';
import { ArrowLeft, Confirm, Redo, Save, Undo } from 'components/Icons';
import { PrimaryButton } from 'components';
import { updateMessengerFlow } from 'services/messengerFlow.service';
import { apiErrorHandler } from 'api/handler';
import { PATHS } from 'routes/paths';

import FlowPrompt from '../components/Modals/FlowPrompt';

function FlowBuilderHeader(props) {
  const { intentId, headerData } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const flowData = useSelector((state: RootState) => state.flow);

  const [promptOptions, setPromptOptions] = useState<any>({});
  const [customLoading, setCustomLoading] = useState({ publish: false, save: false });

  const pageId = searchParams.get('i');

  const handleConfirm = async (isPublished: boolean) => {
    try {
      setCustomLoading((prevState) => ({ ...prevState, publish: isPublished, save: !isPublished }));
      const payload = {
        isSave: !isPublished,
        isPublish: isPublished,
        intentId,
      };
      const { data } = await updateMessengerFlow(payload);
      if (data?.node) {
        const { isValid, errors } = data.node;

        if (isValid) {
          toast.success(`Successfully ${isPublished ? 'saved and published' : 'saved'}`);
          dispatch(clearHistory());
          dispatch(handleFlowSaveStatus(true)); // Set flowIsSaved to true after successful save
        } else {
          dispatch(markInvalidNodes({ errors }));
          toast.error('Incorrect data. Check the highlighted blocks');
        }
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setPromptOptions({ ...promptOptions, show: false });
      setCustomLoading((prevState) => ({
        ...prevState,
        publish: false,
        save: false,
      }));
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    if (pageId) {
      navigate(`${PATHS.MESSENGERS.INTENTS.url}?md=edit&i=${pageId}&page=1&limit=10`);
    } else {
      navigate(-1);
    }
  };

  const handleUndo = (e) => {
    e.preventDefault();
    dispatch(undo());
  };

  const handleRedo = (e) => {
    e.preventDefault();
    dispatch(redo());
  };

  // handle flow prompt
  const handleFlowPrompt = (isPublished: boolean) => {
    setPromptOptions({
      show: true,
      title: !isPublished ? 'Save' : 'Publish',
      message: !isPublished
        ? 'Are you sure you want to finish editing?'
        : 'Are you sure you want to publish the changes?',
      actionButtons: { firstButton: !isPublished ? 'Save' : 'Publish', secondButton: 'Close' },
      onConfirm: () => handleConfirm(isPublished),
    });
  };

  return (
    <>
      {/* header */}
      <div className="p-4">
        <div className="header container flex flex-col md:flex-row">
          <div className="flex md:items-center justify-between w-full">
            <div className="flex flex-col items-start">
              <h1 className="bot-details">{`${headerData?.intentName}`}</h1>
              {headerData?.updatedAt && headerData?.updatedBy && (
                <>
                  <h6 className="ml-[1rem]">
                    Last Updated By : {`${headerData?.updatedBy || '----'}`}
                  </h6>
                  <h6 className="ml-[1rem]">
                    Last Updated At : {`${headerData?.updatedAt || '----'}`}
                  </h6>
                </>
              )}
            </div>
          </div>
          <div className="action-btns flex space-x-4 md:justify-end justify-between w-full">
            <div className="flex space-x-4">
              <button
                className={`hover:opacity-25 ${flowData.history.length === 0 ? 'opacity-25' : ''}`}
                type="button"
                onClick={handleUndo}
                disabled={flowData.history.length === 0}
              >
                <Undo />
              </button>
              <button
                className={`hover:opacity-25 ${
                  flowData.redoHistory.length === 0 ? 'opacity-25' : ''
                }`}
                type="button"
                onClick={handleRedo}
                disabled={flowData.redoHistory.length === 0}
              >
                <Redo />
              </button>
            </div>
            <div className="flex space-x-2">
              <PrimaryButton
                text="Save"
                btnColor="bg-spaceCadetBlue80"
                color="white"
                radius="2xl"
                textSize="base"
                btnHoverColor="hover:bg-spaceCadetBlue"
                icon={<Save />}
                handleOnClick={() => {
                  handleFlowPrompt(false);
                }}
                disabled={customLoading.publish || customLoading.save}
                loading={customLoading.save}
              />
              <PrimaryButton
                text="Publish changes"
                btnColor="bg-spaceCadetBlue80"
                color="white"
                radius="2xl"
                textSize="base"
                btnHoverColor="hover:bg-spaceCadetBlue"
                icon={<Confirm />}
                handleOnClick={() => {
                  handleFlowPrompt(true);
                }}
                disabled={customLoading.publish || customLoading.save}
                loading={customLoading.publish}
              />

              <div className="hidden md:flex">
                <PrimaryButton
                  text="Back"
                  btnColor="bg-spaceCadetBlue80"
                  color="white"
                  radius="2xl"
                  textSize="base"
                  btnHoverColor="hover:bg-spaceCadetBlue"
                  icon={<ArrowLeft />}
                  handleOnClick={(e) => handleBack(e)}
                />
              </div>
            </div>
          </div>
        </div>

        <hr className="mt-4" />

        {promptOptions.show && (
          <FlowPrompt
            onCancel={() => {
              setPromptOptions({ ...promptOptions, show: false });
            }}
            {...promptOptions}
            loading={customLoading?.publish || customLoading?.save}
          />
        )}
      </div>
    </>
  );
}

FlowBuilderHeader.propTypes = {
  botName: PropTypes.string,
  intentName: PropTypes.string,
  intentId: PropTypes.string,
  headerData: PropTypes.object,
};

export default FlowBuilderHeader;
