import { REQUEST_METHODS } from './constants';
import { TRequestMethod } from './interfaces';

// Helper function to convert Headers to plain object
const convertHeadersToObject = (headers: Headers) => {
  const headersObject: Record<string, string> = {};
  headers.forEach((value, key) => {
    headersObject[key] = value;
  });
  return headersObject;
};

/**
 * Sends a test request to the specified URL.
 * @param url - The URL to send the request to.
 * @param requestMethod - The HTTP request method (default: 'GET').
 * @param body - The request body (default: null).
 * @returns A promise that resolves to an object containing data from the response and its headers.
 * @throws If the URL is invalid or the request fails.
 */
export const sendTestRequest = async (
  url: string,
  requestMethod: TRequestMethod = REQUEST_METHODS.GET,
  body: any = null,
): Promise<{ data: any; headers: Record<string, string> } | null> => {
  let requestOptions: any = {
    method: requestMethod,
    headers: { 'Content-Type': 'application/json' },
  };
  if (url) {
    if (requestMethod) {
      if (['PUT', 'PATCH', 'POST'].includes(requestMethod)) {
        requestOptions = {
          ...requestOptions,
          body: body ? JSON.stringify(JSON.parse(body)) : null,
        };
      }
    }

    const response = await fetch(url, requestOptions);

    if (response.ok) {
      try {
        const data = await response.json();
        const headers = convertHeadersToObject(response.headers);
        return { data, headers };
      } catch (err) {
        throw new Error('No json data in response');
      }
    }
    if (response.status === 404) {
      throw new Error('Url not found');
    } else {
      throw new Error('Something went wrong ');
    }
  }
  throw new Error('Invalid URL');
};
