import React from 'react';
import { Outlet } from 'react-router-dom';

export function PublicLayout() {
  return (
    <div className="page-public_layouts__container">
      <Outlet />
    </div>
  );
}

export function Footer() {
  return (
    <footer className="bg-gray-100">
      <div className="py-2 px-4">Footer</div>
    </footer>
  );
}
