/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable prettier/prettier */
import React, { useEffect, useMemo, useState } from 'react';
import ReactDom from 'react-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import { Drawer, Textarea, Toggle } from 'react-daisyui';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// #region assets | components
import { Save, Close, DeleteIcon } from 'components/Icons';
import TextInput from 'components/Form/InputText';

import { INote } from 'views/Contact/ContactView/interface';
// #region imports
import PrimaryButton from 'components/Buttons/Primary';
import { noteValidationSchema } from 'views/Contact/components/NoteCard/validations';
import { createNote, deleteNote, getNoteById, updateNote } from 'services/note.service';
import { apiErrorHandler } from 'api/handler';
import { toast } from 'react-toastify';

function AddNoteCard(props) {
  const {
    noteId,
    contactId,
    isNoteVisible,
    noteData,
    showNote,
    isNotShowDelete = false,
    setTriggerChild,
  } = props;

  const [formData, setFormData] = useState<INote>({ isPrivate: false });
  const [customLoading, setCustomLoading] = useState({
    formSubmit: false,
  });

  const { register, handleSubmit, formState } = useForm<any>({
    resolver: yupResolver(noteValidationSchema),
    // set form initial values, whenever`formData` changes values will be set
    values: useMemo(() => formData, [formData, isNoteVisible]),
  });

  const { errors } = formState;

  // resets the form
  useEffect(
    () => () => {
      setFormData({ isPrivate: false });
    },
    [isNoteVisible],
  );

  useEffect(() => {
    if (noteId) {
      fetchNoteById();
    }
    return () => {
      setFormData({ isPrivate: false });
    };
  }, [noteId]);

  const fetchNoteById = async () => {
    setCustomLoading((prevState) => ({ ...prevState, formSubmit: true }));
    try {
      const { data } = await getNoteById(noteId);
      if (data?.node) {
        setFormData({ ...formData, ...data.node });
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: false }));
    }
  };

  // handle the input text change
  const handleFieldInputChange = (event) => {
    // Use the functional form of setFormData to ensure you're working with the latest state
    const { value, id, checked } = event.target;
    let updatedFormData = {};
    if (id === 'isPrivate') {
      updatedFormData = { ...formData, [id]: checked };
    } else {
      updatedFormData = { ...formData, [id]: value };
    }
    setFormData(updatedFormData);
  };

  /**
   * Update Note
   * @param {INote} editedNote - Edited note data
   */
  const handleEditNote = async (id: string, editedNote: INote) => {
    try {
      const { data } = await updateNote(id, editedNote);
      if (data?.node) {
        setFormData(data?.node);
        toast.success('Note Updated Successfully');
        setTriggerChild((prevState) => ({ ...prevState, notes: uuid(), transactions: uuid() }));
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    }
  };

  /**
   * Delete Note
   * @param {string} id - Note Id
   */
  const handleDeleteNote = async (id: string) => {
    try {
      const { data } = await deleteNote(id);
      if (data?.node) {
        toast.success('Note Deleted Successfully');
        setTriggerChild((prevState) => ({ ...prevState, notes: uuid(), transactions: uuid() }));
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    }
  };

  /**
   * Create Note
   * @param {INote} editedNote - Edited note data
   */
  const handleCreateNote = async (note: INote) => {
    try {
      const { data } = await createNote(note);
      setFormData(data?.node);
      toast.success('Note Created Successfully');
      setTriggerChild((prevState) => ({ ...prevState, notes: uuid(), transactions: uuid() }));
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    }
  };

  /**
   * Handle Note update and save
   */
  const onSubmit = () => {
    setCustomLoading((prevState) => ({ ...prevState, formSubmit: true }));
    const { _id, subject, description, isPrivate } = formData;

    const payload = {
      subject,
      description,
      isPrivate,
      contactId,
    };

    // if note data is passed means edit mode
    if (noteId || formData?._id) {
      handleEditNote(_id, payload);
    } else {
      // form data contains the contactId
      handleCreateNote(payload);
    }
    setCustomLoading((prevState) => ({ ...prevState, formSubmit: false }));
  };

  /**
   * Handle note delete
   */
  const handleDelete = () => {
    setCustomLoading((prevState) => ({ ...prevState, formSubmit: true }));
    handleDeleteNote(formData?._id);
    setCustomLoading((prevState) => ({ ...prevState, formSubmit: false }));
    showNote();
  };

  return ReactDom.createPortal(
    <Drawer
      open={isNoteVisible}
      onClickOverlay={showNote}
      end
      className="drawer__note-card z-[999]"
      side={
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <div className="min-h-screen w-full sm:w-fit bg-culturedGray justify-center items-center">
            <div className="w-full h-auto p-[27px] bg-primary flex flex-wrap justify-between items-center">
              <span className="font-ptScanCap text-sm font-semibold text-white">
                {(formData?._id && formData?.subject) || 'Add a note'}
              </span>
              <div className="cursor-pointer">
                <Close width={26} height={26} onClick={showNote} />
              </div>
            </div>
            <div className="flex items-center justify-center w-[450px] px-4 py-2">
              <div className="h-fit bg-white rounded-[10px] w-full card__container">
                <div className="w-full px-5 py-3 flex items-center justify-end">
                  <div className="form-control">
                    <label className="label cursor-pointer gap-2">
                      <span className="text-lg font-lato">
                        {`${formData?.isPrivate ? 'Private' : 'Public'}`}
                      </span>
                      <Toggle
                        className={`${formData?.isPrivate ? 'toggle-private' : 'toggle'}`}
                        id="isPrivate"
                        checked={formData?.isPrivate}
                        onChange={handleFieldInputChange}
                      />
                    </label>

                    {errors?.isPrivate && (
                      <label className="label relative mt-2" htmlFor="isPrivate">
                        <span className="label-text-alt absolute text-error">
                          {errors?.isPrivate?.message.toString()}
                        </span>
                      </label>
                    )}
                  </div>
                </div>
                <div className="p-3">
                  <TextInput
                    elementId="subject"
                    register={register}
                    error={errors?.subject}
                    label="Subject"
                    type="text"
                    placeholder=""
                    onChange={handleFieldInputChange}
                    value={formData?.subject || ''}
                  />
                </div>
                <div className="form-control p-3">
                  <label className="label" htmlFor="description">
                    <span className="text-sm font-lato">Note</span>
                  </label>
                  <Textarea
                    id="description"
                    {...register('description')}
                    className={`w-full h-48 mt-1 text-sm font-lato ${
                      errors?.description ? 'border border-begoniaRed' : ''
                    }`}
                    value={formData?.description || ''}
                    onChange={handleFieldInputChange}
                  />
                  {errors?.description && (
                    <label className="label relative mt-2" htmlFor="description">
                      <span className="label-text-alt absolute text-error">
                        {errors?.description?.message.toString()}
                      </span>
                    </label>
                  )}
                </div>
                <div className="p-3 flex flex-wrap gap-4 mb-32">
                  <PrimaryButton
                    text={`${noteId || formData?._id ? 'Update' : 'Save'}`}
                    btnColor="bg-spaceCadetBlue80"
                    color="white"
                    radius="2xl"
                    btnHoverColor="hover:bg-spaceCadetBlue"
                    icon={<Save />}
                    handleOnClick={handleSubmit}
                    disabled={customLoading.formSubmit || formData.isDeleted}
                    loading={customLoading.formSubmit}
                  />
                  {!isNotShowDelete && formData?._id && (
                    <PrimaryButton
                      text="Delete"
                      type="button"
                      btnColor="bg-begoniaRed"
                      color="white"
                      radius="2xl"
                      btnHoverColor="hover:bg-statusRed"
                      icon={<DeleteIcon />}
                      handleOnClick={handleDelete}
                      disabled={customLoading.formSubmit || !formData?._id || formData.isDeleted}
                      loading={customLoading.formSubmit}
                    />
                  )}
                </div>
                <div className="w-full h-fit pt-2 pb-2 rounded-b-lg bg-brightGray mt-14">
                  {formData?._id && (
                    <>
                      <div className="w-full items-center justify-center flex">
                        <span className="text-xs font-lato">
                          {`Note added by ${formData?.creator?.name || ''} at ${
                            moment(formData?.createdAt).format('DD-MM-YYYY hh:mm A') || ''
                          }`}
                        </span>
                      </div>
                      <div className="w-full items-center justify-center flex">
                        <span className="text-xs font-lato text-rasinBlack">
                          {`Note updated by ${formData?.editor?.name || ''} at ${
                            moment(formData?.updatedAt).format('DD-MM-YYYY hh:mm A') || ''
                          }`}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      }
    />,
    document.body,
  );
}

AddNoteCard.propTypes = {
  noteId: PropTypes.string,
  contactId: PropTypes.string,
  isNoteVisible: PropTypes.bool,
  showNote: PropTypes.func,
  setIsNoteVisible: PropTypes.func,
  isNotShowDelete: PropTypes.bool,
  setTriggerChild: PropTypes.func,
  noteData: PropTypes.any,
};

export default AddNoteCard;
