import { COLLECTIONS } from 'constants/db';
import { UserType } from 'constants/user.const';
import { getUserType } from 'utils/auth';

const currentUserType = getUserType();

export const userTypes = [
  { value: UserType.SystemManagementUser, label: 'System Management User' },
  { value: UserType.ClientUser, label: 'Client User' },
  // { value: UserType.SuperUser, label: 'Super User' }, // super user is predefined & seeded
];

export const clients = [
  { value: 'client1', label: 'Client 1' },
  { value: 'client2', label: 'Client 2' },
];

export const userRole = [
  { value: 'client1', label: 'System Management Account roles for System Management Accounts' },
  { value: 'client2', label: 'Respective client roles for client users' },
];

export const defaultFormData = {
  userType: currentUserType === UserType.ClientUser ? UserType.ClientUser : null,
};

export const transactionTableHeaders = [
  {
    key: 'sequenceId',
    value: 'Transaction ID',
  },
  {
    key: 'description',
    value: 'Transaction Description',
  },
  {
    key: 'createdAt',
    value: 'Transaction Date and Time',
    type: 'dateTime',
  },
  {
    key: 'createdBy',
    value: 'User',
    type: 'transactionUser',
  },
  {
    key: 'updatedValues',
    value: 'Data',
    type: 'transactionUpdatedValues',
    module: COLLECTIONS.User,
  },
];

export const modalDefaultOptions = {
  show: false,
  actionStatus: false,
  type: null,
  data: {},
};

export const promptDefaultOptions = {
  show: false,
  title: '',
  message: '',
  onConfirm: null,
  type: null,
};

export const customLoadingOptions = {
  formSubmit: false,
  fetchRoles: false,
  toggleAccount: false,
  fetchForm: false,
  fetchClients: false,
  approveAccount: false,
  sendForApproval: false,
};

export enum PromptTypes {
  ToggleAccount = 'ToggleAccount',
}

export enum ModalTypes {
  AccountCreationSuccess = 'AccountCreationSuccess',
  SendForApproval = 'SendForApproval',
  ActivateAccountModal = 'ActivateAccountModal',
}
