/* eslint-disable no-plusplus */
import * as ExcelJS from 'exceljs/dist/exceljs';
import { saveAs } from 'file-saver';

/**
 * Export Single Sheet Data to Excel
 * Table -> Excel
 * @param {Object} data - The data to export to Excel.
 *   - data.worksheetName {string} - The name of the worksheet.
 *   - data.columns {Array} - An array of column names or keys.
 *   - data.rows {Array} - An array of data rows.
 *   - data.fileName {string} - (Optional) The custom file name for the exported file.
 */
export const exportSingleSheetToExcel = async (data: any) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(data.worksheetName);

  worksheet.columns = data.columns;

  // Set header row style
  const headerRow = worksheet.getRow(1);
  headerRow.eachCell((cell) => {
    cell.font = { bold: true };
  });

  // This ensures that only the relevant data is retained for the columns specified
  // in the data.columns array.
  const filteredRows = data.rows.map((x) => {
    const filteredRow = {}; // Create an empty object to store the filtered row data
    // Iterate over each column in the data.columns array
    data.columns.forEach((column) => {
      const { key } = column; // Get the key of the current column
      // Check if the current row (x) has a property with the key
      // eslint-disable-next-line no-prototype-builtins
      if (x.hasOwnProperty(key)) {
        // Assign the value of the property to the corresponding key in the filteredRow object
        filteredRow[key] = x[key];
      }
    });
    return filteredRow; // Return the filtered row object
  });

  // Add data rows to the worksheet
  for (let i = 0; i < filteredRows.length; i++) {
    worksheet.addRow(filteredRows[i]);
  }

  // Generate the Excel file buffer
  const buffer = await workbook.xlsx.writeBuffer();

  // Determine the file name
  const fileName = `${data.fileName}.xlsx`;

  // Save the file using the FileSaver library
  saveAs(new Blob([buffer]), fileName);
};

/**
 * Removes columns with empty labels from an array of Excel columns.
 *
 * @param {Array} columns - The array of Excel columns to be cleaned.
 * @returns {Array} - The cleaned array of Excel columns.
 */
export function cleanExcelColumns(columns: Array<any>): Array<any> {
  // Create a copy of the columns array
  let cleanColumns = columns;

  // Filter out columns with empty labels
  cleanColumns = cleanColumns.filter((x) => x.header !== '');

  // Return the cleaned columns array
  return cleanColumns;
}
