/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useRef, useState } from 'react';

import { SOCKET_EVENTS } from 'constants/events';
import { useSocket } from 'contexts/socket';
import { SocketEvents } from 'enums/socket.enum';

export default function useMessengerInstanceReadyEvent() {
  const _isMounted = useRef(true);

  const socket = useSocket();

  const [event, setEvent] = useState<{ type: SocketEvents; data: any }>({
    type: SocketEvents.MessengerInstanceStatus,
    data: null,
  });

  useEffect(
    () => () => {
      _isMounted.current = false;
    },
    [],
  );

  useEffect(() => {
    if (socket) {
      const handleBotDefaultSettingsCreated = (res: any) => {
        const { node } = res;
        if (node && _isMounted.current) {
          const { messengerName, messengerId } = node;
          setEvent({ ...event, data: { messengerName, messengerId } });
        }
      };

      socket.on(
        SOCKET_EVENTS.SERVER.MESSENGER_INSTANCE.BOT_DEFAULT_SETTINGS_CREATED,
        handleBotDefaultSettingsCreated,
      );

      return () => {
        // Cleanup: Remove the event listener when the component unmounts or when socket changes
        socket.off(
          SOCKET_EVENTS.SERVER.MESSENGER_INSTANCE.BOT_DEFAULT_SETTINGS_CREATED,
          handleBotDefaultSettingsCreated,
        );
      };
    }
  }, [socket]);

  return { event };
}
