import _ from 'lodash';

import instance from 'api';
import { NOTE } from 'api/apiEndpoints';
import UrlBuilder from 'api/urlQueryBuilder';
import { IContactViewURLQueryParams, INote } from 'views/Contact/ContactView/interface';

/**
 * Get notes by contact id
 * @param {string} - Contact Id
 * @param {IContactViewURLQueryParams} params - URL query params for contact view
 * @returns Note list
 */
export function getNotesListByContactId(id: string, params: IContactViewURLQueryParams) {
  const {
    page = 1,
    limit = 10,
    sort = 'createdAt',
    order = -1,
    status,
    searchBy,
    select,
    caseId,
    subject,
    noteId,
    description,
  } = params;

  // creates a new object with only the non-null, non-undefined values from the extracted variables
  const queryParams = _.pickBy({
    page,
    limit,
    sort,
    order,
    status,
    searchBy,
    select,
    caseId,
    subject,
    noteId,
    description,
  });
  const urlBuilder = UrlBuilder();
  const url = urlBuilder
    .setUrl(NOTE.GET_NOTES_BY_CONTACT_ID)
    .setPathParams({ id })
    .setQueryParams({ ...queryParams })
    .build();
  return instance.get(url);
}

/**
 * Create Note
 *
 * @param {INote} data - form data
 * @returns note details
 */
export function createNote(data: INote) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(NOTE.CREATE_NOTE).build();
  return instance.post(url, data);
}

/**
 * Update Note
 *
 * @param {string} id - Note Id
 * @param {INote} data - form data
 * @returns note details
 */
export async function updateNote(id: string, data: INote) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(NOTE.UPDATE_NOTE).setPathParams({ id }).build();
  return instance.patch(url, data);
}

/**
 * Delete Note
 *
 * @param {string} id - Note Id
 * @returns note details
 */
export async function deleteNote(id: string) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(NOTE.DELETE_NOTE).setPathParams({ id }).build();
  return instance.delete(url);
}

/**
 * Get Note by Id
 *
 * @param {string} id - Note Id
 * @returns note details
 */
export async function getNoteById(id: string) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(NOTE.GET_NOTE_BY_ID).setPathParams({ id }).build();
  return instance.get(url);
}
