/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Delete } from 'components/Icons';
import UpIcon from './tempIcons/Up';
import { IMessageSetting } from '../interfaces';
import InfoIcon from './tempIcons/Info';

interface IDelayComponentProps {
  settingsArray: IMessageSetting[];
  componentData: any;
  setSettingsArr: React.Dispatch<React.SetStateAction<IMessageSetting[]>>;
}
function DelayComponent(props: IDelayComponentProps) {
  const { settingsArray, componentData, setSettingsArr } = props;

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleChange = useCallback(
    (event) => {
      const delay = event.target.value;
      if (/^(0*[1-9]|[1-2][0-9]|30)$/.test(delay) || delay === '') {
        setSettingsArr((prev) => {
          const updatedArray = prev.map((item) =>
            (item.id === componentData.id ? { ...item, delay } : item));

          return updatedArray;
        });
      }
    },
    [componentData.id, setSettingsArr],
  );

  const handleDelete = () => {
    setSettingsArr((prev) => {
      if (prev.length === 1) {
        return prev;
      }
      // Filter out the item to be deleted
      const updatedArray = prev.filter((component) => component.id !== componentData.id);

      // Update positions based on the new array
      const newArrayWithUpdatedPositions = updatedArray.map((component, index) => ({
        ...component,
        position: index + 1, // Update the position based on the new index
      }));

      return newArrayWithUpdatedPositions;
    });
  };

  const handleUp = () => {
    setSettingsArr((prev) => {
      // Find the index of the item to move up
      const indexToMoveUp = prev.findIndex((component) => component.id === componentData.id);

      // Check if the item is not already at the top
      if (indexToMoveUp > 0) {
        // Swap positions with the item above it
        const updatedArray = [...prev];
        [updatedArray[indexToMoveUp - 1], updatedArray[indexToMoveUp]] = [
          updatedArray[indexToMoveUp],
          updatedArray[indexToMoveUp - 1],
        ];

        // Update positions based on the new array
        const newArrayWithUpdatedPositions = updatedArray.map((component, index) => ({
          ...component,
          position: index + 1, // Update the position based on the new index
        }));

        return newArrayWithUpdatedPositions;
      }

      // If the item is already at the top, return the original array
      return prev;
    });
  };
  const handleDown = () => {
    setSettingsArr((prev) => {
      // Find the index of the item to move down
      const indexToMoveDown = prev.findIndex((component) => component.id === componentData.id);

      // Check if the item is not already at the bottom
      if (indexToMoveDown < prev.length - 1 && indexToMoveDown !== -1) {
        // Swap positions with the item below it
        const updatedArray = [...prev];
        [updatedArray[indexToMoveDown], updatedArray[indexToMoveDown + 1]] = [
          updatedArray[indexToMoveDown + 1],
          updatedArray[indexToMoveDown],
        ];

        // Update positions based on the new array
        const newArrayWithUpdatedPositions = updatedArray.map((component, index) => ({
          ...component,
          position: index + 1, // Update the position based on the new index
        }));

        return newArrayWithUpdatedPositions;
      }

      // If the item is already at the bottom, return the original array
      return prev;
    });
  };

  return (
    <div className="relative" onMouseEnter={handleMouseEnter}>
      <div className="flex flex-col bg-white w-full h-[120px] rounded-lg cursor-pointer">
        <span className="text-black font-semibold pt-3 pl-5">Show the input indicator</span>
        <div className="flex items-center mt-3 pl-5">
          <input
            id="delay"
            name="delay"
            type="text"
            required
            className="border border-gray-300 p-2 rounded-s-md h-[38px] w-28"
            pattern="^(0*[0-9]|[1-2][0-9]|30)$"
            onChange={handleChange}
            value={componentData?.delay}
            disabled={componentData?.position === settingsArray?.length}
          />
          <span className="bg-[#f7f7f9] border border-gray-300 text-gray-800 px-2 py-1 rounded-e-md h-[38px]">
            sec
          </span>
        </div>
        {componentData?.position === settingsArray?.length && (
          <div className="flex items-center mt-1 pl-5">
            <InfoIcon />
            <span className="text-xs text-gray-300 ml-1">
              {' '}
              Delay block cant be the last element
            </span>
          </div>
        )}
      </div>
      <div className="absolute top-1/2 -right-6 transform -translate-y-1/2 flex flex-col justify-center items-center z-20">
        <button
          type="button"
          className={`rounded-md bg-none drop-shadow-xl ${isHovered ? 'visible' : 'hidden'}`}
          onClick={(e) => {
            e.stopPropagation();
            handleDelete();
          }}
        >
          <Delete id="node-delete-icon" color="#FF7171" width="20" height="20" />
        </button>
        <button
          type="button"
          className={`rounded-md bg-none drop-shadow-xl mt-2 ${
            isHovered && componentData?.position !== 1 ? 'visible' : 'hidden'
          }`}
          onClick={(e) => {
            e.stopPropagation();
            handleUp();
          }}
        >
          <UpIcon width="20" height="20" />
        </button>
        <button
          type="button"
          className={`rounded-md bg-none drop-shadow-xl ${
            isHovered && componentData?.position !== settingsArray?.length
              ? 'visible'
              : isHovered && settingsArray?.length === 1
              ? 'visible'
              : 'hidden'
          }`}
          onClick={(e) => {
            e.stopPropagation();
            handleDown();
          }}
        >
          <div style={{ transform: 'rotateX(180deg)' }}>
            <UpIcon width="20" height="20" />
          </div>
        </button>
      </div>
    </div>
  );
}

DelayComponent.propTypes = {
  componentData: PropTypes.object,
  settingsArray: PropTypes.array,
  setSettingsArr: PropTypes.func,
};

export default DelayComponent;
