/* eslint-disable default-param-last */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-no-useless-fragment */

import React, { useEffect, useMemo, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { SideBarMenuItem } from 'routes/sideBar.routes';
import { checkMenuPermission } from 'utils/auth';
import { isUrlMatch } from 'utils/helpers';

import SubMenuContainer from './SubMenu';

interface IMenuItems {
  menuItems: SideBarMenuItem[];
}

function MenuItems(props: IMenuItems) {
  const { menuItems } = props;

  const { pathname } = useLocation();

  const [selectedMenu, setSelectedMenu] = useState(null);

  function findMenuByPath() {
    let selectedItem = null;

    menuItems.map((val) => {
      if (val.link === pathname) {
        selectedItem = val.key;
      } else if (val.subMenu) {
        val.subMenu.map((subItem) => {
          if (subItem.link === pathname) {
            selectedItem = val.key;
          } else if (subItem.paths.includes(pathname)) {
            selectedItem = val.key;
          }
          return subItem;
        });
      }
      return val;
    });

    if (selectedItem) {
      setSelectedMenu(selectedItem);
    }
  }

  useEffect(() => {
    findMenuByPath();
  }, [pathname]);

  const menuContent = useMemo(
    () => (
      <>
        {menuItems.map((item) => (
          <div key={item.key}>
            {checkMenuPermission(item) && (
              <div key={item.key}>
                {!item.subMenu ? (
                  <MenuNavItem
                    setSelectedMenu={setSelectedMenu}
                    itemKey={item.key}
                    link={item.link}
                    selectedMenu={selectedMenu}
                    paths={item.paths}
                    pathname={pathname}
                    label={item.label}
                    icon={item.icon}
                  />
                ) : (
                  <SubMenuContainer
                    data={item}
                    setSelectedMenu={setSelectedMenu}
                    selectedMenu={selectedMenu}
                  />
                )}
              </div>
            )}
          </div>
        ))}
      </>
    ),
    [menuItems, pathname, selectedMenu],
  );

  return menuContent;
}

function MenuNavItem(props: any) {
  const { setSelectedMenu, itemKey, link, selectedMenu, paths, pathname, label, icon, subMenu } =
    props;

  return (
    <li
      key={itemKey}
      onClick={() => {
        setSelectedMenu(itemKey);
      }}
    >
      <NavLink
        to={link}
        className={classNames('nav_link menu_common', {
          is_active: isUrlMatch(paths, pathname),
        })}
      >
        <div className={classNames('icon nav_content')}>{icon && icon}</div>
        <div className={classNames('text-sm nav-text nav_content')}>{label}</div>
      </NavLink>
    </li>
  );
}

export default MenuItems;
