import { useEffect, useState } from 'react';
import axios from 'axios';

import { ILocationData } from 'slices/user';

export function useIp() {
  const [ipData, setIpData] = useState<ILocationData>(null);

  useEffect(() => {
    axios
      .get('https://ipapi.co/json/')
      .then((response) => {
        const { data } = response;
        if (data) {
          setIpData(data);
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }, []);

  return { ...ipData };
}
