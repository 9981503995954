import moment from 'moment';
import { toast } from 'react-toastify';

import { cleanExcelColumns, exportSingleSheetToExcel } from 'utils/excel';
import { getUserTypeDisplay } from 'utils/user';

import { UserType } from 'constants/user.const';
import { ROLE_STATUS, excelHeaders } from './constants';

export const handleExportExcel = async (docs: any[]) => {
  if (docs.length === 0) {
    toast.warning('No records found');
    return;
  }

  const mappedColumns = excelHeaders.map((x) => ({
    header: x.value,
    key: x.key,
    width: 25,
  }));

  // Clean the columns by removing those with empty labels
  const cleanedColumns = cleanExcelColumns(mappedColumns);
  const rows = docs; // Get the data rows

  const mappedRowData = rows.map((x) => ({
    ...x,
    createdAt: x.createdAt ? moment(x.createdAt).fromNow() : 'N/A',
  }));

  try {
    await exportSingleSheetToExcel({
      worksheetName: 'role-permission-list', // Name of the worksheet in the Excel file
      columns: cleanedColumns, // Cleaned columns for the Excel file
      rows: mappedRowData, // Mapped and formatted row data for the Excel file
      fileName: 'Role Permission List', // Name of the exported Excel file
    });
  } catch (e) {
    toast.error('Something went wrong, Try again');
  }
};

export const mapDocs = (docs: any[]) => {
  const formattedData = docs.map((doc) => {
    let status = ROLE_STATUS.ACTIVE;
    if (doc?.deleted) {
      status = ROLE_STATUS.DELETED;
    }
    if (doc?.userType) {
      doc.userType = getUserTypeDisplay(doc.userType);
    }
    return { ...doc, status };
  });
  return formattedData;
};
