/* eslint-disable no-nested-ternary */
import React, { useCallback, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { deleteFlowAttachment, uploadFlowAttachment } from 'services/messengerFlow.service';
import { Close } from 'components/Icons';
import UpIcon from '../../MessageDrawer/components/tempIcons/Up';
import ImageIcon from '../../MessageDrawer/components/tempIcons/Image';
import { ICarouselSetting, ISlide } from '../../MessageDrawer/interfaces';

interface ICarouselImageAttachmentProps {
  slideNumber: number;
  componentData: ISlide;
  carouselSettings: ICarouselSetting;
  setCarouselSettings: React.Dispatch<React.SetStateAction<ICarouselSetting>>;
}

function CarouselImageAttachment(props: ICarouselImageAttachmentProps) {
  const { slideNumber, componentData, carouselSettings, setCarouselSettings } = props;

  const location = useLocation();

  const inputRef = useRef(null);
  const fileInputRef = useRef(null);

  const [isHovered, setIsHovered] = useState(false);
  const [showFileMenu, setShowFileMenu] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState('File');
  const [isTyping, setIsTyping] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleChange = (event) => {
    const url = event.target.value;

    setCarouselSettings((prev) => {
      const updatedSlides = prev.slides.map((slidesArray, index) => {
        if (index === slideNumber) {
          return slidesArray.map((slide) => {
            if (slide.id === componentData.id) {
              return { ...slide, url };
            }
            return slide;
          });
        }
        return slidesArray;
      });

      return { ...prev, slides: updatedSlides };
    });
  };

  const handleFocus = () => {
    setIsTyping(true);
  };

  const handleBlur = () => {
    setIsTyping(false);
  };

  /**
   * Add image to attachment model and update carouselSettings
   * @param {any} file - Image file object
   */
  const addImage = async (file: any) => {
    // Create FormData and append the selected file
    const payload = new FormData();
    payload.append('file', file);

    let data: any;

    try {
      const responseObj = await uploadFlowAttachment(payload);
      data = responseObj?.data;
    } catch (error) {
      toast.error('Something went wrong while uploading image');
    }

    if (data?.node) {
      const { attachment } = data.node;

      setCarouselSettings((prev) => {
        // iterate through slides
        const updatedSlides = prev.slides.map((slidesArray, index) => {
          // find relevant slide ID
          if (index === slideNumber) {
            // iterate through slides
            return slidesArray.map((slide) => {
              // find the relevant slide by comparing the slide ID
              if (slide.id === componentData.id) {
                return { ...slide, url: attachment?.location, attachmentId: attachment?._id };
              }
              return slide;
            });
          }
          return slidesArray;
        });

        return { ...prev, slides: updatedSlides };
      });
    }
  };

  /**
   * Remove attachment from attachment model and update carouselSettings
   */
  const removeImage = async () => {
    // find the attachmentId
    const attachmentId = carouselSettings.slides[slideNumber].find(
      (slide: ISlide) => slide.id === componentData.id,
    )?.attachmentId;

    if (attachmentId) {
      const urlQueryPrams = Object.fromEntries(new URLSearchParams(location.search));
      const intentId = urlQueryPrams.i;
      // remove attachment from attachment model
      await deleteFlowAttachment(intentId, attachmentId);
    }

    setCarouselSettings((prev) => {
      // iterate through slides
      const updatedSlides = prev.slides.map((slidesArray, index) => {
        // find relevant slide ID
        if (index === slideNumber) {
          // iterate through slides
          return slidesArray.map((slide) => {
            // find the relevant slide by comparing the slide ID
            if (slide.id === componentData.id) {
              return { ...slide, url: '', attachmentId: null };
            }
            return slide;
          });
        }
        return slidesArray;
      });

      return { ...prev, slides: updatedSlides };
    });
  };

  const handleClearSelectedFile = () => {
    removeImage();
  };

  /**
   * Handle moving the Image Attachment component up
   */
  const handleUp = () => {
    setCarouselSettings((prev) => {
      const { slides } = prev;
      const slideIndex = slideNumber;
      const indexToMoveUp = slides[slideIndex]?.findIndex(
        (component) => component.id === componentData.id,
      );

      if (indexToMoveUp > 0) {
        const updatedSlides = slides.map((slide, index) => {
          if (index === slideIndex) {
            const slideCopy = [...slide];
            const slideToMove = slideCopy.splice(indexToMoveUp, 1)[0];
            slideCopy.splice(indexToMoveUp - 1, 0, slideToMove);
            return slideCopy.map((item, idx) => ({ ...item, position: idx + 1 }));
          }
          return slide;
        });

        return { ...prev, slides: updatedSlides };
      }

      return prev;
    });
  };

  /**
   * Handle moving the Image Attachment component down
   */
  const handleDown = () => {
    setCarouselSettings((prev) => {
      const { slides } = prev;
      const slideIndex = slideNumber;
      // Find the index of the item to move down
      const indexToMoveDown = slides[slideIndex]?.findIndex(
        (component) => component.id === componentData.id,
      );

      const numberOfSlideItems = slides[slideIndex]?.length || 3; // will have only 3 items

      // Check if the item is not already at the bottom
      if (indexToMoveDown < numberOfSlideItems - 1 && indexToMoveDown !== -1) {
        const updatedSlides = slides.map((slide, index) => {
          if (index === slideIndex) {
            const slideCopy = [...slide];
            // Swap positions with the item below it
            const slideToMove = slideCopy.splice(indexToMoveDown, 1)[0];
            slideCopy.splice(indexToMoveDown + 1, 0, slideToMove);
            return slideCopy.map((item, idx) => ({ ...item, position: idx + 1 }));
          }
          return slide;
        });

        return { ...prev, slides: updatedSlides };
      }

      return prev;
    });
  };

  const handleDropdownItemClick = (item) => {
    setSelectedOption(item);
    setShowDropdown(false);
    // You can add more logic here based on the selected item
  };

  const handleFileChange = (event) => {
    // Handle the selected file here
    const selectedFile = event.target.files[0];

    // check if the selected file is a valid image type
    if (selectedFile && (selectedFile.type === 'image/png' || selectedFile.type === 'image/jpeg')) {
      addImage(selectedFile);
    } else {
      toast.error('Please select a valid image file (PNG or JPEG).');
    }
  };

  const handleOnClickFileUpload = () => {
    // Trigger the click event on the hidden file input
    fileInputRef.current.click();
  };

  const getBorderColor = () => {
    const { isEnabled, url } = componentData;
    if (url === '' && !isTyping && isEnabled) {
      return 'border-red-300'; // Red border for empty text area
    }
    if (isTyping) {
      return 'border-green-300'; // Green border when typing
    }
    return 'border-gray-300'; // Gray border for non-empty and not typing
  };

  /**
   * Handle Enable Title Change
   */
  const handleCheckboxChange = useCallback(() => {
    setCarouselSettings((prev) => {
      const updatedSlides = prev.slides.map((slidesArray, index) => {
        if (index === slideNumber) {
          return slidesArray.map((slide) => {
            if (slide.id === componentData.id) {
              return { ...slide, isEnabled: !slide.isEnabled };
            }
            return slide;
          });
        }
        return slidesArray;
      });

      return { ...prev, slides: updatedSlides };
    });
  }, [componentData.id, setCarouselSettings, slideNumber]);

  // add image via url
  if (componentData?.url) {
    return (
      <>
        <div className="flex title items-center mb-3">
          <input
            type="checkbox"
            className="checkbox"
            checked={componentData.isEnabled}
            onChange={handleCheckboxChange}
          />
          <span className="title-text">Add Photo</span>
        </div>

        <div className="relative" onMouseEnter={handleMouseEnter}>
          <div className="flex flex-col items-center justify-center bg-[#F9FEFF] hover:bg-[#F0FDFF] w-full h-[150px] border-dashed border-[1px] border-[#0b99b2] rounded-lg cursor-pointer">
            <div className="relative">
              <img className="max-h-[148px]" src={componentData?.url} alt="Selected" />
              <div className="absolute top-0 right-0 m-2">
                <button title="clear" type="button" onClick={handleClearSelectedFile}>
                  <Close color="#000" />
                </button>
              </div>
            </div>
          </div>
          <div className="absolute top-1/2 -right-6 transform -translate-y-1/2 flex flex-col justify-center items-center z-20">
            <button
              type="button"
              className={`rounded-md bg-none drop-shadow-xl mt-2 ${
                isHovered && componentData?.position !== 1 ? 'visible' : 'hidden'
              }`}
              onClick={(e) => {
                e.stopPropagation();
                handleUp();
              }}
            >
              <UpIcon width={20} height={20} />
            </button>
            <button
              type="button"
              className={`rounded-md bg-none drop-shadow-xl 
             ${isHovered && componentData?.position !== 2 ? 'visible' : 'hidden'}
            `}
              onClick={(e) => {
                e.stopPropagation();
                handleDown();
              }}
            >
              <div style={{ transform: 'rotateX(180deg)' }}>
                <UpIcon width={20} height={20} />
              </div>
            </button>
          </div>
        </div>
      </>
    );
  }

  // add image via file
  return (
    <>
      <div className="flex title items-center mb-3">
        <input
          type="checkbox"
          className="checkbox"
          checked={componentData?.isEnabled}
          onChange={handleCheckboxChange}
        />
        <span className="title-text">Add Photo</span>
      </div>

      <div className="relative" onMouseEnter={handleMouseEnter}>
        <button
          type="button"
          className={`absolute top-0 left-0 text-xs bg-none drop-shadow-xl mt-2 flex items-center pl-3 ${
            showFileMenu ? 'text-red-600' : 'text-spaceCadetBlue'
          }`}
          onClick={() => {
            setShowFileMenu(!showFileMenu);
            setShowDropdown(!showDropdown);
          }}
          disabled={!componentData?.isEnabled}
        >
          {selectedOption}&nbsp;
          {showFileMenu ? (
            <span style={{ fontSize: '0.7em' }}>&#9650;</span>
          ) : (
            <span style={{ fontSize: '0.7em' }}>&#9660;</span>
          )}
        </button>
        {/* Dropdown */}
        {showDropdown && (
          <div className="absolute top-8 left-3 bg-white border rounded-md p-2 z-50">
            <button
              type="button"
              className="block w-full text-left p-2 hover:bg-gray-200"
              onClick={() => handleDropdownItemClick('File')}
              disabled={!componentData?.isEnabled}
            >
              File
            </button>
            <button
              type="button"
              className="block w-full text-left p-2 hover:bg-gray-200"
              onClick={() => handleDropdownItemClick('Image URL')}
              disabled={!componentData?.isEnabled}
            >
              Image URL
            </button>
          </div>
        )}
        <div className="flex flex-col items-center justify-center bg-[#F9FEFF] hover:bg-[#F0FDFF] w-full h-[150px] border-dashed border-[1px] border-[#0b99b2] rounded-lg cursor-pointer">
          {selectedOption === 'Image URL' ? (
            <div className="w-[90%]">
              <input
                ref={inputRef}
                className={`w-full rounded-md border-[1px] focus:outline-none ${getBorderColor()} p-2 relative z-10 flex-grow`}
                value={componentData?.url || ''}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder="Type your message"
                disabled={!componentData?.isEnabled}
              />
            </div>
          ) : (
            <>
              <ImageIcon />
              <button
                type="button"
                className="bg-[#0B99B2] m-2 w-auto text-white p-2 text-xs"
                onClick={handleOnClickFileUpload}
                disabled={!componentData?.isEnabled}
              >
                Select file...
              </button>
              {/* Hidden file input */}
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
              <span className="text-[#0B99B2] text-xs">or drag and drop image here (1MB)</span>
            </>
          )}
        </div>

        <div className="absolute top-1/2 -right-6 transform -translate-y-1/2 flex flex-col justify-center items-center z-20">
          <button
            type="button"
            className={`rounded-md bg-none drop-shadow-xl mt-2 ${
              isHovered && componentData?.position !== 1 ? 'visible' : 'hidden'
            }`}
            onClick={(e) => {
              e.stopPropagation();
              handleUp();
            }}
            disabled={!componentData?.isEnabled}
          >
            <UpIcon width={20} height={20} />
          </button>

          <button
            type="button"
            className="rounded-md bg-none drop-shadow-xl"
            onClick={(e) => {
              e.stopPropagation();
              handleDown();
            }}
            disabled={!componentData?.isEnabled}
          >
            <div style={{ transform: 'rotateX(180deg)' }}>
              <UpIcon width={20} height={20} />
            </div>
          </button>
        </div>
      </div>
    </>
  );
}

CarouselImageAttachment.propTypes = {
  slideNumber: PropTypes.number,
  componentData: PropTypes.object,
  carouselSettings: PropTypes.object,
  setCarouselSettings: PropTypes.func,
};

export default CarouselImageAttachment;
