/* eslint-disable indent */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable prettier/prettier */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';
import PropTypes from 'prop-types';
import { Toggle } from 'react-daisyui';
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';

import { RootState } from 'slices';
import { CustomSelect } from 'components';
import { EmojiIcon } from 'components/Icons';
import { getValueForSelect } from 'utils/helpers';
import { buildVariableDropdownOptions } from 'views/Settings/FlowBuilder/utils';
import { removeSubscriberResponseEdge } from 'slices/flow';
import { IQuickReply, ISubscriberSetting, ISelectedQuickReplyId } from '../interfaces';
import VariableDropdown from '../../../VariableDropdown';
import { QuickReplyBuilder } from '../helpers/QuickReplyBuilder';
import AddQuickRepliesModal from './AddQuickRepliesModal';
import AddVariablePopUp from './AddVariablePopUp';
import { VALIDATE_AS } from './constants';
import FlowPrompt from '../../../Modals/FlowPrompt';

interface ISubscriberResponseProps {
  expand: boolean;
  setExpand: React.Dispatch<React.SetStateAction<boolean>>;
  setQuickReplies: React.Dispatch<React.SetStateAction<IQuickReply>>;
  quickReplies: IQuickReply;
  setSubscriberSettingsArr: React.Dispatch<React.SetStateAction<ISubscriberSetting>>;
  subscriberSettingsArr: ISubscriberSetting;
  setUpdatedQuickReply: React.Dispatch<React.SetStateAction<IQuickReply>>;
  selectedQuickReplyId: ISelectedQuickReplyId;
  showModal: boolean;
  handleUpdateQuickReplies: React.Dispatch<React.SetStateAction<IQuickReply>>;
  isQuickReplyEditMode: boolean;
  setIsQuickReplyEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSubscriberQuickReplyModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

function SubscriberResponse(props: ISubscriberResponseProps) {
  const {
    expand,
    setExpand,
    setQuickReplies,
    quickReplies,
    setSubscriberSettingsArr,
    subscriberSettingsArr,
    setUpdatedQuickReply,
    selectedQuickReplyId,
    showModal,
    handleUpdateQuickReplies,
    isQuickReplyEditMode,
    setIsQuickReplyEditMode,
    setShowSubscriberQuickReplyModal,
    setShowModal,
  } = props;

  const textareaRef = useRef(null);
  const emojiPickerRef = useRef(null);
  const buttonRef = useRef(null);

  const dispatch = useDispatch();

  const flowData = useSelector((state: RootState) => state.flow);

  const [isTyping, setIsTyping] = useState(false);
  const [showQuickRepliesModal, setShowQuickRepliesModal] = useState(false);
  const [isPopupVisible, setPopupVisible] = useState(false);

  const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState(false);

  const [validateAsList, setValidateAsList] = useState(VALIDATE_AS);

  const [variableList, setVariableList] = useState([]);

  const [promptOptions, setPromptOptions] = useState<any>({});
  const [messageText, setMessageText] = useState(subscriberSettingsArr?.message || '');
  // Debounce the text value here
  const [debouncedText] = useDebounce(messageText, 1000); // 1000 milliseconds debounce time

  const [onInit, setOnInit] = useState(false);
  const [showEditQuickRepliesModal, setShowEditQuickRepliesModal] = useState(false);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (flowData.variables.length > 0) {
      const filteredList = flowData.variables.filter(
        (obj) => obj.type === subscriberSettingsArr?.validateAs,
      );
      setVariableList(filteredList);
    }
  }, [flowData.variables, subscriberSettingsArr?.validateAs]);

  useEffect(() => {
    setMessageText(subscriberSettingsArr?.message || '');
  }, [subscriberSettingsArr?.message]);

  useEffect(() => {
    if (onInit) {
      setSubscriberSettingsArr((prev) => ({
        ...prev,
        message: messageText,
      }));
    }
    setOnInit(true);
  }, [debouncedText]);

  const handleClickOutside = (event: MouseEvent) => {
    // Check if the clicked element is outside the emoji button and picker
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target) &&
      !buttonRef.current.contains(event.target)
    ) {
      setIsEmojiPickerVisible(false);
    }
  };

  /**
   * Handle emoji selection and insert it into the input
   * Get the current value of the input
   * Add the selected emoji to the input value
   */
  const handleEmojiSelection = (emoji: EmojiClickData) => {
    const currentInputValue = subscriberSettingsArr?.message || '';
    const updatedInputValue = currentInputValue + emoji.emoji;
    setSubscriberSettingsArr((prev) => ({
      ...prev,
      message: updatedInputValue,
    }));

    // Close the emoji picker
    setIsEmojiPickerVisible(false);
  };

  const insertValueAtCursorPosition = (value: string) => {
    const currentInputValue = subscriberSettingsArr?.message || '';

    const updatedText = `${currentInputValue} {{${value}}}`;
    setSubscriberSettingsArr((prev) => ({
      ...prev,
      message: updatedText,
    }));
  };

  const toggleRepliesModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // Check if the click target is the button itself
    if (e.target === e.currentTarget) {
      setShowQuickRepliesModal(!showQuickRepliesModal);
      setShowEditQuickRepliesModal(!showEditQuickRepliesModal);
    }
  };

  const handleAddButtonClick = (e) => {
    setPopupVisible((prev) => !prev);
  };

  const handleFocus = () => {
    setIsTyping(true);
  };

  const handleBlur = () => {
    setIsTyping(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;

    if (name === 'expiresIn') {
      if (/^(0*([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-9]{2}|300))$/.test(value) || value === '') {
        setSubscriberSettingsArr((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    } else {
      setMessageText(value);
    }
  };

  const handleSelectChange = (event: { value: any; other: any }, id: string) => {
    if (id === 'validateAs') {
      setSubscriberSettingsArr((prev) => ({
        ...prev,
        answerSaveVariable: '',
      }));
    }
    setSubscriberSettingsArr((prev) => ({
      ...prev,
      [id]: event?.value || null,
    }));
  };

  const getBorderColor = () => {
    if (subscriberSettingsArr?.message === '' && !isTyping) {
      return 'border-gray-300'; // Red border for empty text area
    }
    if (isTyping) {
      return 'border-green-300'; // Green border when typing
    }
    return 'border-gray-300'; // Gray border for non-empty and not typing
  };

  const handleToggleSubscriber = (event) => {
    const { checked } = event.target;
    if (checked) {
      handleFlowPrompt();
    } else {
      setExpand(checked);
      setSubscriberSettingsArr((prev) => {
        const { nodeId } = prev;
        // remove associated edge
        dispatch(removeSubscriberResponseEdge({ nodeId }));
        return {
          ...prev,
          isEnabled: checked,
        };
      });
    }
  };

  const handleFlowPrompt = () => {
    setPromptOptions({
      show: true,
      title: 'Wait for User Reply',
      message:
        'This element must be the last in the sequence. Any subsequent quick replies will be deleted.',
      actionButtons: { firstButton: 'Yes', secondButton: 'No' },
      onConfirm: () => handleConfirm(),
      onCancel: () => setPromptOptions({ ...promptOptions, show: false }),
    });
  };

  const handleConfirm = () => {
    setExpand(true);
    setSubscriberSettingsArr((prev) => ({
      ...prev,
      isEnabled: true,
    }));
    // setQuickReplies((prev) => ({
    //   ...prev,
    //   replies: [],
    // }));
    setPromptOptions({ ...promptOptions, show: false });
  };

  return (
    <>
      <div className="flex flex-col bg-white w-full h-auto rounded-lg cursor-pointer flow_builder_messenger-drawer">
        <div className="flex gap-3 p-4">
          <Toggle
            className={`${expand ? 'toggle-expand' : 'toggle'}`}
            id="isPrivate"
            checked={expand}
            onChange={handleToggleSubscriber}
          />
          <span className="text-sm font-lato text-[#0b99b2]">
            WAIT FOR THE SUBSCRIBERS RESPONSE
          </span>
        </div>
        {expand ? (
          <div className="pt-3 pb-5">
            <div className="px-4">
              <div className="form-control">
                <label className="label" htmlFor="validateAs">
                  <span className="label-text font-bold text-sm font-lato">Validate as</span>
                </label>
              </div>
              <CustomSelect
                id="validateAs"
                required
                options={validateAsList}
                value={getValueForSelect(subscriberSettingsArr?.validateAs, validateAsList)}
                onChange={(e) => handleSelectChange(e, 'validateAs')}
              />
            </div>
            <div className="px-4">
              <div className="form-control">
                <label className="label" htmlFor="category">
                  <span className="label-text font-bold text-sm font-lato">
                    Show message if the input is not valid
                  </span>
                </label>
              </div>
              <div className="relative">
                <textarea
                  ref={textareaRef}
                  name="message"
                  className={`w-full rounded-t-md border-[1px] focus:outline-none ${getBorderColor()} p-2 relative z-10 flex-grow`}
                  style={{
                    paddingRight: '80px',
                  }}
                  value={messageText || ''}
                  onChange={handleInputChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  placeholder="Type your message"
                />
                <div className="absolute top-1 right-10 z-[99]">
                  <VariableDropdown
                    id="subscriberResponseErrorMessage"
                    options={buildVariableDropdownOptions(variableList)}
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        insertValueAtCursorPosition(selectedOption.value);
                      }
                    }}
                  />
                </div>
                <button
                  type="button"
                  ref={buttonRef}
                  onClick={() => setIsEmojiPickerVisible(!isEmojiPickerVisible)}
                  className="absolute top-[10px] right-3 z-[99] cursor-pointer"
                >
                  <EmojiIcon />
                </button>
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    maxHeight: '150px',
                    zIndex: '99',
                  }}
                  ref={emojiPickerRef}
                >
                  {isEmojiPickerVisible && <EmojiPicker onEmojiClick={handleEmojiSelection} />}
                </div>
              </div>
            </div>
            {subscriberSettingsArr?.validateAs !== 'imageVideo' && (
              <div className="px-4">
                <div className="form-control">
                  <label className="label justify-between" htmlFor="category">
                    <span className="label-text font-bold text-sm font-lato">
                      Save answer to variable
                    </span>
                    <div className="relative z-50">
                      <button
                        type="button"
                        className="rounded-md bg-none drop-shadow-xl mt-2 text-[#52b7c9] text-xs relative"
                        onClick={handleAddButtonClick}
                      >
                        Create variable
                      </button>
                      {/* Popup component */}
                      {isPopupVisible && (
                        <div onClick={(e) => e.stopPropagation()}>
                          <AddVariablePopUp
                            variableType={subscriberSettingsArr?.validateAs || ''}
                            setPopupVisible={setPopupVisible}
                            setVariableList={setVariableList}
                            setSubscriberSettingsArr={setSubscriberSettingsArr}
                          />
                        </div>
                      )}
                    </div>
                  </label>
                </div>
                <CustomSelect
                  id="answerSaveVariable"
                  required
                  isClearable
                  options={variableList}
                  value={getValueForSelect(subscriberSettingsArr?.answerSaveVariable, variableList)}
                  onChange={(e) => handleSelectChange(e, 'answerSaveVariable')}
                />
              </div>
            )}
            <div className="px-4">
              <div className="form-control">
                <label className="label" htmlFor="category">
                  <span className="label-text font-bold text-sm font-lato flex items-center">
                    Response Waiting Time
                    <span className="text-slate-500 font-light mr-2 ml-1">(sec)</span>{' '}
                    <div className="badge badge-error badge-xs" />
                  </span>
                </label>
              </div>
              {/* <CustomSelect
                id="expiresIn"
                required
                isClearable
                options={expireTimeList}
                value={getValueForSelect(subscriberSettingsArr?.expiresIn, expireTimeList)}
                onChange={(e) => handleSelectChange(e, 'expiresIn')}
              /> */}
              <input
                id="expiresIn"
                name="expiresIn"
                type="text"
                required
                className="border border-gray-300 focus:border-green-300 px-2 py-1 rounded-md w-full"
                pattern="^(0*([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-9]{2}|300))$" // max waiting time 300secs(5min)
                title="Please enter a number between 1 and 30."
                placeholder="Response waiting timeout in seconds (Max : 300 sec)"
                onChange={handleInputChange}
                value={subscriberSettingsArr?.expiresIn}
              />
            </div>
            {expand &&
              (subscriberSettingsArr?.validateAs === 'string' ||
                subscriberSettingsArr?.validateAs === 'number') && (
                <>
                  <br />
                  <div className="justify-center items-center flex gap-3 px-4">
                    <QuickReplyBuilder
                      components={quickReplies?.replies || []}
                      setQuickReplies={setQuickReplies}
                      isUserInputReply
                      handleUpdateQuickReplies={handleUpdateQuickReplies}
                    />
                  </div>
                </>
              )}
            {(subscriberSettingsArr?.validateAs === 'string' ||
              subscriberSettingsArr?.validateAs === 'number') && (
              <div className="relative mt-5 ml-5 mr-5 ">
                <button
                  type="button"
                  className="rounded-xl bg-[#e8f6f8] w-[100px] h-[30px] hover:shadow-md p-1 text-sm text-[#52b7c9]"
                  onClick={toggleRepliesModal}
                >
                  Quick replies
                </button>
                {(showQuickRepliesModal || showModal) && (
                  <AddQuickRepliesModal
                    onClose={toggleRepliesModal}
                    origin="user_input"
                    setQuickReplies={setQuickReplies}
                    quickReplies={quickReplies}
                    setShowQuickRepliesModal={setShowQuickRepliesModal}
                    setUpdatedQuickReply={setUpdatedQuickReply}
                    selectedQuickReplyId={selectedQuickReplyId}
                    isQuickReplyEditMode={isQuickReplyEditMode}
                    setIsQuickReplyEditMode={setIsQuickReplyEditMode}
                    setShowSubscriberQuickReplyModal={setShowSubscriberQuickReplyModal}
                    setShowModal={setShowModal}
                  />
                )}
              </div>
            )}
          </div>
        ) : (
          <div />
        )}
      </div>
      {promptOptions.show && <FlowPrompt {...promptOptions} loading={false} />}
    </>
  );
}
SubscriberResponse.propTypes = {
  expand: PropTypes.bool,
  setExpand: PropTypes.func,
  setQuickReplies: PropTypes.func,
  quickReplies: PropTypes.object,
  setSubscriberSettingsArr: PropTypes.func,
  subscriberSettingsArr: PropTypes.object,
  setUpdatedQuickReply: PropTypes.func,
  selectedQuickReplyId: PropTypes.string,
  showModal: PropTypes.bool,
  handleUpdateQuickReplies: PropTypes.func,
  isQuickReplyEditMode: PropTypes.bool,
  setIsQuickReplyEditMode: PropTypes.func,
  setShowEditQuickRepliesModal: PropTypes.func,
};

export default SubscriberResponse;
