import _ from 'lodash';
import { AxiosResponse } from 'axios';

import instance, { postAttachments } from 'api';
import { CLIENT } from 'api/apiEndpoints';
import UrlBuilder from 'api/urlQueryBuilder';
import { IClientListURLQueryParams } from 'views/ClientManagement/List/interface';

/**
 * Fetch paginated and filtered client list data
 * @param {IClientListURLQueryParams} params - Url query params for client list data)
 * @returns {AxiosResponse<any, any>} - Client list data and pagination data
 */
export const fetchClientListData = (params: IClientListURLQueryParams) => {
  const {
    page = 1,
    limit = 10,
    sort = 'createdAt',
    order = -1,
    country,
    status,
    searchBy,
    select,
  } = params;

  // creates a new object with only the non-null, non-undefined values from the extracted variables
  const queryParams = _.pickBy({
    page,
    limit,
    sort,
    order,
    country,
    status,
    searchBy,
    select,
  });
  const url = UrlBuilder()
    .setUrl(CLIENT.GET_CLIENTS_ALL)
    .setQueryParams({ ...queryParams })
    .build();
  return instance.get(url);
};

/**
 * Create Client
 *
 * @param data - form data
 * @returns client details
 */
export function createClient(data) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(CLIENT.CREATE_CLIENT).build();
  return instance.post(url, data);
}

/**
 * Update Client
 *@param id - client id
 * @param data - form data
 * @returns client details
 */
export function updateClient(id: string, data: any) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(CLIENT.UPDATE_CLIENT).setPathParams({ id }).build();
  return instance.patch(url, data);
}

/**
 * Get client by Id
 *@param id - client id
 * @param data - form data
 * @returns client details
 */
export function getClientById(id: string) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(CLIENT.GET_CLIENT_BY_ID).setPathParams({ id }).build();
  return instance.get(url);
}

/**
 * Activate Client Account
 * @param id - client id
 * @returns client details
 */
export function activateAccount(id) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(CLIENT.ACTIVATE_CLIENT).setPathParams({ id }).build();
  return instance.post(url);
}

/**
 * Toggle Account Status
 * @param id - client id
 * @returns client details
 */
export function toggleAccountStatus(id, body) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(CLIENT.TOGGLE_ACCOUNT_STATUS).setPathParams({ id }).build();
  return instance.post(url, body);
}

/**
 * Upload Company Logo
 * @returns {Promise}
 */
export const uploadCompanyLogo = (id: string, data: any) => {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(CLIENT.UPLOAD_COMPANY_LOGO).setPathParams({ id }).build();
  return postAttachments(url, data);
};
