import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type LayoutState = {
  sideBarOpen: boolean;
};

const initialState: LayoutState = {
  sideBarOpen: true,
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    toggleSideBar(state) {
      state.sideBarOpen = !state.sideBarOpen;
    },
    hideSideBar(state) {
      state.sideBarOpen = false;
    },
    showSideBar(state) {
      state.sideBarOpen = true;
    },
  },
});

export const { hideSideBar, showSideBar, toggleSideBar } = layoutSlice.actions;

export default layoutSlice.reducer;
