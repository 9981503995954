import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { ISelectOption } from 'interfaces/component.interface';
import { updateFlowSettings, updateLiveChatSettings } from 'slices/flow';
import { RootState } from 'slices';
import { CustomSelect } from 'components';
import { getDefaultValueForSelect } from 'utils/helpers';
import { IDrawerCoreData } from 'views/Settings/FlowBuilder/interfaces';
import { getLinkedChannels } from 'services/messenger.service';
import { toast } from 'react-toastify';
import { apiErrorHandler } from 'api/handler';
import { ILiveChatDrawerData, ILiveChatOptions } from './interfaces';

interface ILiveChatDrawerProps {
  node: IDrawerCoreData;
  messengerId: string;
  toggleDrawer: (formData: ILiveChatDrawerData, nodeId: string) => void;
}

const LiveChatDrawer = forwardRef(
  ({ toggleDrawer, node, messengerId }: ILiveChatDrawerProps, ref) => {
    const dispatch = useDispatch();

    const [channelsOptions, setChannelsOptions] = useState<ILiveChatOptions[]>();

    const flowData = useSelector((state: RootState) => state.flow);
    const [formData, setFormData] = useState<ILiveChatDrawerData>();

    useEffect(() => {
      const flowObj = flowData.nodeConfigs.find((nodes) => nodes?.nodeId === node?.id);
      if (flowObj) {
        setFormData(flowObj?.liveChatSettings);
      }
    }, []);

    const selectedDefaultDataType = useMemo(
      () => getDefaultValueForSelect(formData?.channelName, channelsOptions, 'value'),
      [formData?.channelName],
    );

    // handle select field change
    const handleSelectChange = (option: ISelectOption, id: string) => {
      setFormData({
        ...formData,
        [id]: option?.value,
      });
      const nodePayload = {
        id: node?.id,
        data: { heading: node?.title, content: option },
      };
      dispatch(updateLiveChatSettings(nodePayload));
    };

    const handleSubmit = () => {
      toggleDrawer(formData, node?.id);
    };

    // Expose the handleSubmit function to the parent component using useImperativeHandle
    useImperativeHandle(ref, () => ({
      handleSubmit,
    }));

    const fetchLinkedChannels = async (id: string) => {
      try {
        const { data: channels } = await getLinkedChannels(id);
        const mappedData = channels?.nodes?.map((channel) => ({
          value: channel?.channel?._id || '',
          label: `${channel?.channel?.channelName || ''}`,
        }));
        setChannelsOptions(mappedData);
      } catch (error) {
        const { message: exception } = apiErrorHandler(error);
        toast.error(exception);
      }
    };

    return (
      <div className="flow-drawer__container">
        <form>
          <div className="mb-4">
            <div className="form-control">
              <label className="label" htmlFor="flow">
                <span className="label-text text-base font-medium font-lato">Select a Channel</span>
              </label>
            </div>
            <CustomSelect
              id="channelName"
              required
              placeholder="Select Channel"
              options={channelsOptions}
              value={selectedDefaultDataType}
              onChange={(event) => handleSelectChange(event, 'channelName')}
              onMenuOpen={() => fetchLinkedChannels(messengerId)}
            />
          </div>
        </form>
      </div>
    );
  },
);

LiveChatDrawer.propTypes = {
  node: PropTypes.any,
  messengerId: PropTypes.string,
};

export default LiveChatDrawer;
