import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

import MALE_PROFILE_PIC_LOCATION from 'assets/images/profile_male.png';
import { Close } from 'components/Icons';

import { defaultFormPlaceholders } from '../../constants';

function Header(props: any) {
  const { messengerData } = props;

  const {
    displayName,
    headerColor,
    attachments,
    defaultWelcomeMessage = '',
    tagline,
    color,
  } = messengerData;

  return (
    <div
      className="tasa__widget-header"
      style={{
        backgroundColor: headerColor || color || '#192A51',
      }}
    >
      {/* avatar */}
      {attachments?.botAvatar?.location ? (
        <img src={attachments.botAvatar.location} className="tasa__widget-avatar" alt="avatar" />
      ) : (
        <img src={MALE_PROFILE_PIC_LOCATION} className="tasa__widget-avatar" alt="avatar" />
      )}

      {/* header text */}
      <div className="tasa__widget-header-text">
        <div className="tasa__widget-header-main-text font-ptScanCap">
          {displayName || defaultFormPlaceholders.name}
        </div>
        <div className="tasa__widget-header-sub-text mt-1 font-lato">
          {tagline || defaultFormPlaceholders.tagline}
        </div>
      </div>

      {/* close button */}
      <motion.div
        whileHover={{ scale: 1.1 }}
        transition={{ duration: 0.25 }}
        className="tasa__widget-header-close-btn"
      >
        <Close width={30} height={30} />
      </motion.div>
    </div>
  );
}

Header.propTypes = {
  messengerData: PropTypes.object,
};

export default Header;
