import _ from 'lodash';

import instance, { postAttachments } from 'api';
import { USER } from 'api/apiEndpoints';
import UrlBuilder from 'api/urlQueryBuilder';
import {
  IUserListURLQueryParams,
  IUsersWithPermissionURLQueryParams,
} from 'views/UserManagement/List/interface';

/**
 * Fetch paginated and filtered user list data
 * @param {IUserListURLQueryParams} params - Url query params for user list data)
 * @returns {} - User list data and pagination data
 */
export const fetchUserListData = (params: IUserListURLQueryParams) => {
  const {
    page = 1,
    limit = 10,
    sort = 'createdAt',
    order = -1,
    clientId,
    roleId,
    channelId,
    userType,
    status,
    searchBy,
    isExceptCurrentUser,
  } = params;

  // creates a new object with only the non-null, non-undefined values from the extracted variables
  const queryParams = _.pickBy({
    page,
    limit,
    sort,
    order,
    clientId,
    roleId,
    channelId,
    userType,
    status,
    searchBy,
    isExceptCurrentUser,
  });
  const url = UrlBuilder()
    .setUrl(USER.GET_USERS_ALL)
    .setQueryParams({ ...queryParams })
    .build();
  return instance.get(url);
};

/**
 * Create User
 *
 * @param data - form data
 * @returns user details
 */
export function createUser(data) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(USER.CREATE_USER).build();
  return instance.post(url, data);
}
/**
 * Update User
 *@param id - user id
 * @param data - form data
 * @returns user details
 */
export function updateUser(id, data) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(USER.UPDATE_USER).setPathParams({ id }).build();
  return instance.patch(url, data);
}

/**
 * Get User by Id
 *@param id - user id
 * @param data - form data
 * @returns user details
 */
export function getUserById(id) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(USER.GET_USER_BY_ID).setPathParams({ id }).build();
  return instance.get(url);
}

/**
 * Approve User
 * @param id - User id
 * @returns User details
 */
export function approveUser(id) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(USER.APPROVE_USER).setPathParams({ id }).build();
  return instance.get(url);
}

/**
 * Upload Profile Pic
 * @returns {Promise}
 */
export const uploadProfilePic = (id: string, data: any) => {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(USER.UPLOAD_PROFILE_PIC).setPathParams({ id }).build();
  return postAttachments(url, data);
};

/**
 * Toggle Account Status
 * @param id - client id
 * @returns client details
 */
export function toggleAccountDisable(id, body) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(USER.TOGGLE_ACCOUNT_DISABLE).setPathParams({ id }).build();
  return instance.post(url, body);
}

export function sendUserAccountForApproval(id: string, selectedUserId: string) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(USER.SEND_ACCOUNT_FOR_APPROVAL).setPathParams({ id }).build();
  return instance.post(url, { selectedUserId });
}

export const fetchUsersWithPermission = (
  permissionKey: string,
  params: IUsersWithPermissionURLQueryParams,
) => {
  const { clientId, userType } = params;
  const queryParams = _.pickBy({
    clientId,
    userType,
  });
  const url = UrlBuilder()
    .setUrl(USER.GET_USERS_WITH_PERMISSION)
    .setPathParams({ key: permissionKey })
    .setQueryParams(queryParams)
    .build();
  return instance.get(url);
};

/**
 * Change agent status
 * @returns user details
 */
export function updateAgentStatus(body) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(USER.SET_AGENT_STATUS).build();
  return instance.patch(url, body);
}

/**
 * get agent status
 * @returns user details
 */
export function getAgentStatus() {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(USER.GET_AGENT_STATUS).build();
  return instance.get(url);
}
