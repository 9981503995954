import React from 'react';
import PropTypes, { object } from 'prop-types';

import { Dropdown } from 'react-daisyui';
import { Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import PhoneInput from 'react-phone-number-input';

import 'react-phone-number-input/style.css';
import { reactSelectCustomStyles } from 'styles/select';

import TextInput from 'components/Form/InputText';
import Save from 'components/Icons/Save';
import CustomSelect from 'components/Form/CustomSelect';
import GeneralButton from 'components/Buttons/GeneralButton';
import { PrimaryButton } from 'components';
import { PATHS } from 'routes/paths';
import { Back, Settings } from 'components/Icons';

import { GENDER } from 'constants/contact.const';
import { countries, getValueForSelect } from 'utils/helpers';
import FormEditHeader from './containers/FormEditHeader';

function FormPage(props) {
  const {
    formData,
    salutationOptions,
    errors,
    editMode,
    register,
    control,
    locationData,
    handlePhoneNoChange,
    handlePreferredMethodChange,
    handleInputChange,
    handleSelectChange,
    handleSubmit,
    onSubmit,
    restGenderProps,
    restCountryProps,
    restSalutationProps,
    customLoading,
  } = props;

  const navigate = useNavigate();

  return (
    <div className="page contact_form">
      <div className="flex justify-between items-center">
        <div>
          {!editMode ? (
            <h2 className="text-spaceCadetBlue font-bold text-lg font-ptScanCap">
              Create a Contact
            </h2>
          ) : (
            <FormEditHeader formData={formData} />
          )}
        </div>
        <div>
          <GeneralButton
            text="Back"
            btnColor="bg-spaceCadetBlue80"
            color="white"
            radius="2xl"
            textSize="sm"
            btnHoverColor="hover:bg-spaceCadetBlue"
            icon={<Back />}
            handleOnClick={() => navigate(-1)}
          />
        </div>
      </div>
      <hr className="my-3" />

      {/* User form */}
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 md:grid-cols-3 client-account__form bg-white p-6 drop-shadow rounded-xl mt-6">
          <div className="grid grid-cols-1 md:grid-cols-2 col-span-2 gap-x-4 gap-y-1">
            {/* Settings  */}
            {editMode && (
              <div className="flex justify-end z-4 absolute z-50 right-0 top-0 m-2 md:m-4 p-2">
                <Dropdown.Details horizontal="left" vertical="bottom" className="p-0">
                  <Dropdown.Details.Toggle className="bg-transparent hover:bg-transparent border-none p-0">
                    <div className="rounded-full border border-quickSilver bg-aliceBlue hover:bg-spaceCadetBlue p-2 more_settings">
                      <Settings />
                    </div>
                  </Dropdown.Details.Toggle>
                  <Dropdown.Menu className="rounded-md drop-shadow-md w-52 p-0">
                    <Dropdown.Item className="rounded-none border-b-2 text-base py-4">
                      Add form fields
                    </Dropdown.Item>
                    <Dropdown.Item className="rounded-none border-b-2 text-base py-4">
                      Modify form fields
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown.Details>
              </div>
            )}
            {/* Salutation */}
            <div className="mb-2 mt-4">
              <label className="label" htmlFor="S=salutation">
                <span className="label-text font-normal text-base font-lato">Salutation</span>
              </label>
              <CustomSelect
                id="salutation"
                isClearable
                options={salutationOptions}
                error={errors?.salutation}
                customStyles={reactSelectCustomStyles}
                value={getValueForSelect(formData?.salutation, salutationOptions)}
                onChange={(event) => handleSelectChange(event, 'salutation')}
                {...restSalutationProps}
              />
            </div>

            <div />

            {/* First name */}
            <TextInput
              elementId="firstName"
              label="First Name"
              type="text"
              placeholder="eg: John"
              register={register}
              error={errors?.firstName}
              value={formData?.firstName}
              onChange={handleInputChange}
            />
            {/* Last name */}
            <TextInput
              elementId="lastName"
              label="Last Name"
              type="text"
              placeholder="eg: Doe"
              register={register}
              error={errors?.lastName}
              value={formData?.lastName}
              onChange={handleInputChange}
            />
            {/* Email address */}
            <TextInput
              elementId="email"
              label="Email Address"
              type="email"
              placeholder="eg: johndoe@gmail.com"
              register={register}
              error={errors?.email}
              value={formData?.email}
              onChange={handleInputChange}
            />

            {/* Primary contact number */}
            <div className="form-control">
              <label className="label" htmlFor="primaryContactNo">
                <span className="label-text text-base font-base font-lato">
                  Primary Contact Number
                </span>
              </label>
              <Controller
                name="primaryContactNo"
                control={control}
                render={({ field }) => (
                  <PhoneInput
                    className={`input input-bordered focus:outline-none ${
                      errors?.primaryContactNo ? 'border border-begoniaRed' : ''
                    }`}
                    defaultCountry={locationData?.country_code}
                    value={formData?.primaryContactNo}
                    control={control}
                    limitMaxLength
                    id="primaryContactNo"
                    onChange={(e) => {
                      field.onChange(e);
                      handlePhoneNoChange('primaryContactNo', e);
                    }}
                  />
                )}
              />
              {errors?.primaryContactNo && (
                <label className="label relative mt-2" htmlFor="primaryContactNo">
                  <span className="label-text-alt absolute text-error">
                    {errors?.primaryContactNo?.message?.toString()}
                  </span>
                </label>
              )}
            </div>

            {/* Secondary contact number */}
            <div className="form-control">
              <label className="label" htmlFor="secondaryContactNo">
                <span className="label-text text-base font-base font-lato">
                  Secondary Contact Number
                </span>
              </label>
              <Controller
                name="secondaryContactNo"
                control={control}
                render={({ field }) => (
                  <PhoneInput
                    className={`input input-bordered focus:outline-none ${
                      errors?.secondaryContactNo ? 'border border-begoniaRed' : ''
                    }`}
                    defaultCountry={locationData?.country_code}
                    value={formData?.secondaryContactNo}
                    control={control}
                    limitMaxLength
                    id="secondaryContactNo"
                    onChange={(e) => {
                      field.onChange(e);
                      handlePhoneNoChange('secondaryContactNo', e);
                    }}
                  />
                )}
              />
              {errors?.secondaryContactNo && (
                <label className="label relative mt-2" htmlFor="secondaryContactNo">
                  <span className="label-text-alt absolute text-error">
                    {errors?.secondaryContactNo?.message?.toString()}
                  </span>
                </label>
              )}
            </div>

            {/* Preferred contact method */}
            <div className="md:col-span-2">
              <div className="form-control">
                <label className="label text-base font-base font-lato">
                  Preferred Contact Method
                </label>
                <div className="grid grid-col-1 sm:grid-cols-3 gap-x-4 gap-y-4 content-center">
                  <div className="flex items-center" id="contactMethod">
                    <Controller
                      name="preferredContactMethod.phoneCall"
                      control={control}
                      render={({ field }) => (
                        <>
                          <input
                            id="preferredContactMethod.phoneCall"
                            type="checkbox"
                            className={`checkbox ${
                              errors?.preferredContactMethod ? 'border border-begoniaRed' : ''
                            } mr-2`}
                            checked={formData?.preferredContactMethod?.phoneCall}
                            onClick={(e) => {
                              field.onChange(e);
                              handlePreferredMethodChange(e);
                            }}
                          />
                          <span className="label-text text-base font-lato">Phone Call</span>
                        </>
                      )}
                    />
                  </div>
                  <div className="flex items-center">
                    <Controller
                      name="preferredContactMethod.whatsApp"
                      control={control}
                      render={({ field }) => (
                        <>
                          <input
                            id="preferredContactMethod.whatsApp"
                            type="checkbox"
                            className={`checkbox ${
                              errors?.preferredContactMethod ? 'border border-begoniaRed' : ''
                            } mr-2`}
                            checked={formData?.preferredContactMethod?.whatsApp}
                            onClick={(e) => {
                              field.onChange(e);
                              handlePreferredMethodChange(e);
                            }}
                          />
                          <span className="label-text text-base font-lato">WhatsApp</span>
                        </>
                      )}
                    />
                  </div>
                  <div className="flex items-center">
                    <Controller
                      name="preferredContactMethod.email"
                      control={control}
                      render={({ field }) => (
                        <>
                          <input
                            id="preferredContactMethod.email"
                            type="checkbox"
                            className={`checkbox ${
                              errors?.preferredContactMethod ? 'border border-begoniaRed' : ''
                            } mr-2`}
                            checked={formData?.preferredContactMethod?.email}
                            onClick={(e) => {
                              field.onChange(e);
                              handlePreferredMethodChange(e);
                            }}
                          />
                          <span className="label-text text-base font-lato">Email</span>
                        </>
                      )}
                    />
                  </div>
                </div>
                <label className="label relative mt-2" htmlFor="contactMethod">
                  <span className="label-text-alt absolute text-error">
                    {errors?.preferredContactMethod?.message?.toString()}
                  </span>
                </label>
              </div>
            </div>

            {/* Gender */}
            <div className="mb-2">
              <label className="label" htmlFor="gender">
                <span className="label-text text-base font-lato">Gender</span>
              </label>
              <CustomSelect
                id="gender"
                isClearable
                options={GENDER}
                error={errors?.gender}
                customStyles={reactSelectCustomStyles}
                value={getValueForSelect(formData?.gender, GENDER)}
                onChange={(event) => handleSelectChange(event, 'gender')}
                {...restGenderProps}
              />
            </div>

            {/* Date of birth */}
            <TextInput
              elementId="dateOfBirth"
              label="Date of Birth"
              type="date"
              error={errors?.dateOfBirth}
              value={formData?.dateOfBirth}
              register={register}
              onChange={handleInputChange}
            />
            {/* Designation */}
            <TextInput
              elementId="designation"
              label="Designation"
              type="text"
              placeholder="eg: Sales Manager"
              error={errors?.designation}
              value={formData?.designation}
              register={register}
              onChange={handleInputChange}
            />
            {/* Company */}
            <TextInput
              elementId="company"
              label="Company"
              type="text"
              placeholder="eg: ABC Company"
              error={errors?.company}
              value={formData?.company}
              onChange={handleInputChange}
            />
            {/* Company name */}
            <div className="mb-2">
              <div className="form-control">
                <label className="label" htmlFor="country">
                  <span className="label-text text-base font-lato">Country</span>
                </label>
              </div>
              <CustomSelect
                id="country"
                isClearable
                options={countries}
                error={errors?.country}
                customStyles={reactSelectCustomStyles}
                value={getValueForSelect(formData?.country, countries)}
                onChange={(event) => handleSelectChange(event, 'country')}
                {...restCountryProps}
              />
            </div>

            <div />

            {/* Street address */}
            <TextInput
              elementId="streetAddress"
              label="Street Address"
              type="text"
              placeholder=""
              error={errors?.streetAddress}
              value={formData?.streetAddress}
              onChange={handleInputChange}
            />
            {/* City */}
            <TextInput
              elementId="city"
              label="City"
              type="text"
              placeholder=""
              error={errors?.city}
              value={formData?.city}
              onChange={handleInputChange}
            />
            {/* State/ Province/ Region */}
            <TextInput
              elementId="stateProvinceRegion"
              label="State/ Province/ Region"
              type="text"
              placeholder=""
              error={errors?.stateProvinceRegion}
              value={formData?.stateProvinceRegion}
              onChange={handleInputChange}
            />
            {/* Zip code */}
            <TextInput
              elementId="postalCodeZipCode"
              label="Zip Code/ Postal Code"
              type="text"
              placeholder=""
              error={errors?.postalCodeZipCode}
              value={formData?.postalCodeZipCode}
              onChange={handleInputChange}
            />

            {/* Save button */}
            <div className="mt-4 mb-4">
              <PrimaryButton
                text={`${editMode ? 'Update' : 'Save'}`}
                btnColor="bg-spaceCadetBlue80"
                color="white"
                btnHoverColor="hover:bg-spaceCadetBlue"
                radius="2xl"
                icon={<Save />}
                disabled={customLoading.fetchForm || customLoading.formSubmit}
                loading={customLoading.fetchForm || customLoading.formSubmit}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

FormPage.propTypes = {
  formData: PropTypes.object,
  locationData: PropTypes.object,
  salutationOptions: PropTypes.arrayOf(object),
  errors: PropTypes.any,
  editMode: PropTypes.bool,
  register: PropTypes.any,
  control: PropTypes.any,
  handlePhoneNoChange: PropTypes.func,
  handlePreferredMethodChange: PropTypes.func,
  handleInputChange: PropTypes.func,
  handleSelectChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  restGenderProps: PropTypes.any,
  restCountryProps: PropTypes.any,
  restSalutationProps: PropTypes.any,
  customLoading: PropTypes.any,
};

export default FormPage;
