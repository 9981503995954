/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { toast } from 'react-toastify';

import { Star } from 'components/Icons';
import { updateConversation } from 'services/conversation.service';
import { apiErrorHandler } from 'api/handler';
import TakeChatIcon from 'components/Icons/TakeChatIcon';
import { S3_ASSETS } from 'constants/assets';

function UserCard(props: any) {
  const {
    contactName,
    lastText,
    channelName,
    unReadMessages,
    date,
    _id,
    handleOpen,
    isPinned = false,
    setTriggerChild,
    status,
    ringColor,
    takeChat,
    contact,
    setContactData,
    selected,
  } = props;

  const [chatIsPinned, setChatIsPinned] = useState(isPinned);

  const togglePin = (event) => {
    event.preventDefault();
    setChatIsPinned(!chatIsPinned);
    handlePinChat(!chatIsPinned);
    event.stopPropagation();
  };

  const handlePinChat = async (pinStatus) => {
    try {
      const { data } = await updateConversation(_id, { isPinned: pinStatus });
      if (data.status) {
        setTriggerChild((prevState) => ({ ...prevState, conversations: uuid() }));
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    }
  };

  return (
    <div
      className="mb-2 !cursor-pointer mr-2"
      onClick={() => {
        handleOpen(_id);
        if (contact) {
          setContactData({ ...contact });
        } else {
          setContactData({});
        }
      }}
    >
      <div
        className={`user-account__card cursor-pointer p-3 border border-spaceCadetBlue rounded-xl bg-[#FBFBFB] hover:bg-aliceBlue ${
          selected ? 'border-b-4' : ''
        } hover:border-b-4 relative1 max-w overflow-hidden`}
      >
        <div className="user-account__card-info flex">
          <div className="avatar">
            <div
              className={`w-10 h-10 rounded-full ring ring-${ringColor} ring-offset-base-100 mt-3`}
            >
              <img
                src={contact?.profilePicture?.location || S3_ASSETS.CHAT_PREVIEW_PLACEHOLDER.url}
                alt="avatar"
              />
            </div>
          </div>
          <div className="flex flex-grow">
            <div className="p-2 ml-1 max-w-[75%]">
              <p className="font-lato text-[#003249] font-extrabold text-sm mb-1">
                {contactName || 'Unsaved Contact'}
              </p>
              <p className="text-[#979797] text-xs font-lato mb-1 text-justify user-cards-line-clamp-3 overflow-x-auto">
                {lastText}
              </p>
            </div>
          </div>
        </div>
        {unReadMessages && (
          <div className="bg-spaceCadetBlue text-xs text-white py-1 px-2 items-center rounded-lg w-fit h-fit font-lato absolute top-2 right-2">
            {unReadMessages}
          </div>
        )}

        <div className="flex justify-between ml-[45px] items-center">
          {channelName ? (
            <div className="badge text-white font-semibold p-3 text-center items-center text-[9px] bg-spaceCadetBlue mb-2 rg:mb-0 overflow-hidden whitespace-nowrap">
              {channelName}
            </div>
          ) : (
            <div />
          )}
          <div className="flex items-center">
            <span className="text-rasinBlack font-lato text-[11px] mr-3">{date}</span>
            {status !== 'new' && (
              <button type="button" onClick={(event) => togglePin(event)}>
                <Star
                  color={chatIsPinned ? '#FFE249' : '#192A51'}
                  fill={chatIsPinned ? '#FFE249' : 'none'}
                  width={18}
                />
              </button>
            )}
          </div>
        </div>
        {status === 'new' ? (
          <>
            <br />
            <div className="flex justify-between ml-[45px] items-center">
              <div>
                <button
                  type="button"
                  className="flex bg-spaceCadetBlue80 font-ptScanCap rounded-2xl items-center px-3 py-2 text-center hover:bg-spaceCadetBlue"
                  onClick={() => takeChat(_id)}
                >
                  <TakeChatIcon />
                  <span className="text-white capitalize text-xs pl-1 font-bold">Take Chat</span>
                </button>
              </div>
            </div>
          </>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
}
UserCard.propTypes = {
  contactName: PropTypes.string,
  _id: PropTypes.string,
  date: PropTypes.string,
  lastText: PropTypes.string,
  channelName: PropTypes.string,
  unReadMessages: PropTypes.number,
  handleOpen: PropTypes.func,
  isPinned: PropTypes.bool,
  setTriggerChild: PropTypes.func,
  ringColor: PropTypes.string,
  status: PropTypes.string,
  takeChat: PropTypes.func,
  contact: PropTypes.any,
  setContactData: PropTypes.func,
  selected: PropTypes.bool,
};
export default UserCard;
