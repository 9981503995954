import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { RootState } from 'slices';
import VariableDropdown from '../../VariableDropdown';
import { systemVariableOptions } from '../../constants';

interface IVariableInputFieldProps {
  value?: string;
  handleInputChange?: (newValue: string) => void;
}

function VariableInputField(props: IVariableInputFieldProps) {
  const { value, handleInputChange } = props;
  const [cursorPosition, setCursorPosition] = useState(0); // Track cursor position
  const inputRef = useRef(null); // Ref for the input field

  const { variables } = useSelector((state: RootState) => state.flow);

  /**
   * Handle variable input field change
   * @param {React.ChangeEvent<HTMLElement>} e - Change event
   */
  const handleVariableInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    handleInputChange(newValue);
  };

  /**
   * Handle inserting the value at the cursor position
   * @param {string} selectedValue - selected variable
   */
  const insertValueAtCursorPosition = (selectedValue: string) => {
    const currentValue = inputRef.current.value;
    const wrappedValue = `{{${selectedValue}}}`;

    const newValue =
      currentValue.substring(0, cursorPosition) +
      wrappedValue +
      currentValue.substring(cursorPosition);
    inputRef.current.value = newValue;
    setCursorPosition(cursorPosition + selectedValue.length);
    handleInputChange(newValue); // Optionally, trigger input change handler
  };

  const variableOptions = [
    {
      label: 'Select variable',
      options: variables,
    },
    {
      label: 'System variables',
      options: systemVariableOptions,
    },
  ];

  return (
    <div className="variable-input-wrapper flex-grow flex items-center relative">
      <input
        className="flow-builder__input flow-builder__input"
        type="text"
        value={value || ''}
        onChange={handleVariableInputChange}
        ref={inputRef}
        onSelect={(e: React.ChangeEvent<HTMLInputElement>) => {
          const cursorCurrentPosition = e.target.selectionStart;
          setCursorPosition(cursorCurrentPosition); // track cursor position
        }}
        style={{
          paddingRight: '60px',
        }}
      />
      <div className="absolute right-1">
        <VariableDropdown
          id="url"
          options={variableOptions}
          onChange={(selectedOption) => {
            if (selectedOption) {
              insertValueAtCursorPosition(selectedOption.value);
            }
          }}
        />
      </div>
    </div>
  );
}

VariableInputField.propTypes = {
  value: PropTypes.string,
  handleInputChange: PropTypes.func,
};

export default VariableInputField;
