import React from 'react';
import PropTypes from 'prop-types';

import FirstBubble from 'components/ChatBubbles/FirstBubble';
import SecondBubbleOnwards from 'components/ChatBubbles/SecondBubbleOnwards';
import { S3_ASSETS } from 'constants/assets';

function ReceiverChatBubble(props) {
  const {
    text,
    date,
    consecutiveRenderCount,
    contactName,
    contact,
    attachment,
    textHighlighter,
    isSample,
  } = props;

  return (
    <div>
      {/* Receiver */}
      <div className="flex justify-end">
        <div className="mr-2 relative">
          {consecutiveRenderCount === 1 ? (
            <p
              className="text-sm text-raisnBlack font-lato font-semibold whitespace-nowrap"
              style={{ position: 'absolute', top: 0, right: 0 }}
            >
              {contactName || 'customer'}
              <span className="text-[12px] ml-2 text-independenceBlue">{date}</span>
            </p>
          ) : (
            <div />
          )}

          <div className={`${consecutiveRenderCount === 1 ? 'mt-6' : 'mt-2'}`}>
            {consecutiveRenderCount === 1 ? (
              <FirstBubble
                message={text}
                isAgent={false}
                isSender
                attachment={attachment}
                textHighlighter={textHighlighter}
                isSample={isSample}
              />
            ) : (
              <div className="">
                <SecondBubbleOnwards
                  message={text}
                  isAgent={false}
                  isSender
                  attachment={attachment}
                  textHighlighter={textHighlighter}
                  isSample={isSample}
                />
              </div>
            )}
          </div>
        </div>
        {consecutiveRenderCount === 1 ? (
          <div className="avatar">
            <div className="w-10 h-10 rounded-full">
              <img
                src={contact?.profilePicture?.location || S3_ASSETS.CHAT_PREVIEW_PLACEHOLDER.url}
                alt="avatar"
              />
            </div>
          </div>
        ) : (
          <div className="avatar none">
            <div className="w-10 h-10 rounded-full" />
          </div>
        )}
      </div>
    </div>
  );
}
ReceiverChatBubble.propTypes = {
  text: PropTypes.string,
  date: PropTypes.string,
  consecutiveRenderCount: PropTypes.number,
  contactName: PropTypes.string,
  attachment: PropTypes.any,
  textHighlighter: PropTypes.string,
  isSample: PropTypes.bool,
  contact: PropTypes.any,
};

export default ReceiverChatBubble;
