import * as React from 'react';
import PropTypes from 'prop-types';

function Preview(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color = '#fff' } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <g
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        clipPath="url(#clip0_1758_72)"
      >
        <path d="M10 12a2 2 0 104 0 2 2 0 00-4 0z" />
        <path d="M21 12c-2.4 4-5.4 6-9 6-3.6 0-6.6-2-9-6 2.4-4 5.4-6 9-6 3.6 0 6.6 2 9 6z" />
      </g>
      <defs>
        <clipPath id="clip0_1758_72">
          <path fill="#fff" d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
Preview.propTypes = {
  color: PropTypes.string,
};

export default Preview;
