/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useDebounce } from 'use-debounce';
import { useNavigate } from 'react-router-dom';

import { ConversationStatus } from 'constants/conversation.const';
import { CustomTable, SearchBar } from 'components';
import { PATHS } from 'routes/paths';
import GeneralButton from 'components/Buttons/GeneralButton';
import { apiErrorHandler } from 'api/handler';
import ChatPreview from 'views/Inbox/BotChat/List/containers/ChatPreview';
import CreateChannel from 'components/Icons/createChannelIcon';
import UnlinkChatIcon from 'components/Icons/UnlinkChats';
import { fetchConversationsList } from 'services/conversation.service';
import { conversationTableHeaders } from './constants';

function ConversationList(props) {
  const { recordId, triggerChild, handleOnLinkChat, handleOnUnlinkChat } = props;

  //  state with table headers and sample data for the client list.
  const [listData, setListData] = useState({
    totalDocs: 0,
    totalPages: 0,
    limit: 5,
    page: 1,
    headers: conversationTableHeaders,
    docs: [],
    sort: null,
    order: -1,
  });

  const [nextPage, setNextPage] = useState(1);

  const [searchByInput, setSearchByInput] = React.useState();
  const [debouncedSearchBy] = useDebounce(searchByInput, 500);

  const [customLoading, setCustomLoading] = useState({ list: false });
  // show preview drawer
  const [visible, setVisible] = useState(false);
  // data for preview drawer
  const [botChatData, setBotChatData] = useState<any>();

  /**
   * Handle page change in pagination by replacing the url search params
   *
   * @param {number} pageNo - Page number user requesting to navigate
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handlePageChange = (pageNo: number) => {};

  useEffect(() => {
    if (recordId) {
      fetchListData();
    }
  }, [recordId, triggerChild, debouncedSearchBy, nextPage]);

  async function fetchListData(page = 1) {
    try {
      setCustomLoading((prevState) => ({ ...prevState, list: true }));
      const { data } = await fetchConversationsList({
        caseId: recordId,
        searchText: searchByInput || '',
        fromCases: 1,
      });
      if (data?.node?.docs) {
        if (data.node.page === 1) {
          setListData({ ...listData, ...data.node, docs: data.node.docs });
        } else {
          setListData({ ...listData, docs: [...listData.docs, ...data.node.docs] });
        }
      }
    } catch (e) {
      const { message: exception } = apiErrorHandler(e);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, list: false }));
    }
  }

  const handleOnSearch = async (event) => {
    const { value } = event.target;
    setSearchByInput(value);
  };

  /**
   * Trigger when scroll bar reach the bottom
   */
  const handleOnReachEnd = () => {
    setNextPage((prevState) => prevState + 1);
  };

  /**
   * Handles the view conversation
   * @param {IContact} conversation - Note data from clicked row
   */
  const viewConversation = (e: any, conversation: any) => {
    const { status } = conversation;
    // conversation is ended open in drawer else open in new window
    if (status === ConversationStatus.ENDED) {
      setVisible(true);
      setBotChatData(conversation);
    } else {
      window.open(
        `${PATHS.CASES_MANAGEMENT.VIEW.url}?id=${conversation?._id}`,
        '_blank',
        'width=1280,height=1024',
      );
    }
  };

  /**
   * Close preview drawer
   */
  const closePreview = () => {
    setVisible(false);
    setBotChatData(null);
  };
  return (
    <div className="my-2">
      <div className="flex flex-col sm:flex-row mb-3 items-center justify-between">
        <div className="w-full sm:w-1/2 mb-2 sm:mb-0">
          <SearchBar
            placeholder="Search by Chat Contents"
            searchByInput={searchByInput}
            onSearch={handleOnSearch}
          />
        </div>
        <div className="flex flex-col sm:flex-row gap-2">
          <GeneralButton
            text="Link Chat"
            btnColor="bg-spaceCadetBlue80"
            color="white"
            radius="2xl"
            textSize="xs"
            btnHoverColor="hover:bg-spaceCadetBlue"
            icon={<CreateChannel color="white" />}
            handleOnClick={handleOnLinkChat}
          />
          <GeneralButton
            text="Unlink Chat"
            btnColor="bg-begoniaRed"
            color="white"
            radius="2xl"
            textSize="xs"
            btnHoverColor="hover:bg-begoniaRed"
            // className="whitespace-nowrap"
            icon={<UnlinkChatIcon />}
            handleOnClick={handleOnUnlinkChat}
          />
        </div>
      </div>

      <CustomTable
        tableData={listData}
        onPageChange={handlePageChange}
        handleEdit={() => {}}
        loading={{ list: customLoading.list }}
        showPageLimit={false}
        fixedHeight
        onReachEnd={handleOnReachEnd}
        isPaginate={false}
        onManage={viewConversation}
      />

      {/* Preview chat model */}
      <ChatPreview visible={visible} toggleHide={closePreview} conversationData={botChatData} />
    </div>
  );
}

ConversationList.propTypes = {
  recordId: PropTypes.string,
  triggerChild: PropTypes.any,
  handleOnLinkChat: PropTypes.func,
  handleOnUnlinkChat: PropTypes.func,
};

export default ConversationList;
