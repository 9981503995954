import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CountryCode } from 'libphonenumber-js/core';

interface User {
  _id: string;
  name: string;
  email: string;
  gender: string;
  profilePicLocation: string;
  agentStatus: string;
}

export interface ILocationData {
  ip: string | null;
  city: string | null;
  country: string | null;
  country_name: string | null;
  country_code: CountryCode | null;
  postal_code: number | null;
  timezone: string | null;
  latitude: number | null;
  longitude: number | null;
  utc_offset?: string | null;
  country_calling_code: string | null;
}

export type UserState = {
  user: User;
  loading: boolean;
  userToken: string;
  locationData: ILocationData;
  intervalId: any;
};

const initialState: UserState = {
  user: {
    _id: '',
    name: '',
    email: '',
    gender: '',
    agentStatus: '',
    profilePicLocation: '',
  },
  userToken: null,
  loading: false,
  locationData: {
    ip: '',
    city: 'Colombo',
    country: 'LK',
    country_name: 'Sri Lanka',
    country_code: 'LK',
    postal_code: 0,
    timezone: '',
    latitude: 0,
    longitude: 0,
    utc_offset: '',
    country_calling_code: '',
  },
  intervalId: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUserRequest(state) {
      state.loading = true;
    },
    getUserSuccess(state, { payload }: PayloadAction<User>) {
      state.user.name = payload.name;
      state.loading = false;
    },
    getUserFailure(state) {
      state.loading = false;
    },
    getUserToken(state, { payload }: PayloadAction<string>) {
      state.userToken = payload;
    },
    updateUserLocationData(state, { payload }: PayloadAction<ILocationData>) {
      state.locationData = payload;
    },
    updateUser(state, { payload }: PayloadAction<any>) {
      state.user = { ...state.user, ...payload };
    },
    getUserRunningIntervals(state, { payload }: PayloadAction<any>) {
      state.intervalId = payload;
    },
  },
});

export const {
  getUserRequest,
  getUserFailure,
  getUserSuccess,
  getUserToken,
  updateUserLocationData,
  updateUser,
  getUserRunningIntervals,
} = userSlice.actions;

export default userSlice.reducer;
