import * as React from 'react';
import PropTypes from 'prop-types';

function ImageIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color, width = 38, height = 24 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 3H26V19H10V3Z" stroke="#0B99B2" strokeWidth="2" />
      <path
        d="M10.1975 16.0059L14.753 11.5888L18.0793 13.8618L21.3972 10.3403L25.8805 13.8093"
        stroke="#0B99B2"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1875 9.3125C16.1195 9.3125 16.875 8.55698 16.875 7.625C16.875 6.69302 16.1195 5.9375 15.1875 5.9375C14.2555 5.9375 13.5 6.69302 13.5 7.625C13.5 8.55698 14.2555 9.3125 15.1875 9.3125Z"
        fill="#0B99B2"
      />
    </svg>
  );
}
ImageIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default ImageIcon;
