import * as React from 'react';
import PropTypes from 'prop-types';

function FileIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color, width = 38, height = 22 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.8102 7.31156L14.5787 14.4518C14.0081 15.0151 14.0081 15.9285 14.5787 16.4918V16.4918C15.1492 17.0552 16.0743 17.0552 16.6448 16.4918L26.1492 7.10755C27.2903 5.98085 27.2903 4.15412 26.1492 3.02743V3.02743C25.0081 1.90073 23.158 1.90073 22.0168 3.02743L12.0993 12.8197C10.3876 14.5098 10.3876 17.2499 12.0993 18.9399V18.9399C13.8109 20.6299 16.5861 20.6299 18.2978 18.9399L23.4632 13.8398"
        stroke="#0B99B2"
        strokeWidth="2"
      />
    </svg>
  );
}
FileIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default FileIcon;
