import * as yup from 'yup';

import { UserType } from 'constants/user.const';
import { getUserType } from 'utils/auth';

const rolePermissionFormValidationSchema = {
  name: yup.string().required('Please Enter Role Name'),
  description: yup.string().required('Please Provide a Description'),
};

export const getFormValidationSchema = (formData: any) => {
  const userType = getUserType();

  let validationSchema: any = rolePermissionFormValidationSchema;

  // If user is super user then clientId is required
  if (userType === UserType.SuperUser) {
    validationSchema = {
      ...validationSchema,
      clientId: yup.string().required('Please Select a client'),
    };
  }

  const schema = yup.object(validationSchema);
  return schema;
};
