import React, { useState } from 'react';
import { Drawer } from 'react-daisyui';
import ReactDom from 'react-dom';
import PropTypes from 'prop-types';

// #region assets | components
import { IFormattedNote, INote } from 'views/Contact/ContactView/interface';
import { Close } from 'components/Icons';

// #region utils
import ContactCard from './ContactCard';
import ContactDetailCard from './ContactDetailCard/ContactDetailCard';

function ContactView(props) {
  const { visible, setVisible, toggleVisible, contactDetails, isActionButtonsVisible } = props;

  return ReactDom.createPortal(
    <Drawer
      end
      open={visible}
      onClickOverlay={toggleVisible}
      side={
        <div className="bg-culturedGray justify-center min-h-screen w-auto">
          <div className="w-full h-auto p-[27px] bg-primary flex flex-wrap justify-between items-center">
            <span className="font-ptScanCap text-md font-semibold text-white">Preview Contact</span>
            <div className="cursor-pointer">
              <Close width={26} height={26} onClick={toggleVisible} />
            </div>
          </div>
          <div className="p-3">
            <ContactCard
              setVisible={setVisible}
              contactDetails={contactDetails}
              isActionButtonsVisible={isActionButtonsVisible}
            />
            <ContactDetailCard contactDetails={contactDetails} />
          </div>
        </div>
      }
    />,
    document.body,
  );
}

ContactView.propTypes = {
  visible: PropTypes.bool,
  toggleVisible: PropTypes.func,
  handleEditUser: PropTypes.func,
  contactDetails: PropTypes.object,
  isActionButtonsVisible: PropTypes.bool,
  setVisible: PropTypes.func,
};

export default ContactView;
