/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { PATHS } from 'routes/paths';
import AddTemplate from 'components/Icons/AddTemplate';
import Copy from 'components/Icons/Copy';
import NewCase from 'components/Icons/NewCase';
import Template from 'components/Icons/Template';
import SearchBar from 'components/SearchBar';
import { apiErrorHandler } from 'api/handler';
import { toast } from 'react-toastify';
import { fetchMessageTemplateList } from 'services/templates.service';
import { useDebounce } from 'use-debounce';
import { AddCase } from 'components/Icons';
import GeneralButton from 'components/Buttons/GeneralButton';

function UserTemplate(props: {
  toggleTemplateModal: any;
  triggerChild: any;
  setChatFormData: any;
}) {
  const { toggleTemplateModal, triggerChild, setChatFormData } = props;

  const [templateList, setTemplateList] = useState([]);
  const [searchBy, setSearchBy] = useState('');

  const [debouncedSearchBy] = useDebounce(searchBy, 500);

  const navigate = useNavigate();

  useEffect(() => {
    fetchTemplates();
  }, [triggerChild, debouncedSearchBy]);

  async function fetchTemplates() {
    try {
      const { data } = await fetchMessageTemplateList({ limit: 10, searchBy });
      if (data?.node) {
        setTemplateList(data?.node.docs);
      }
    } catch (e) {
      const { message: exception } = apiErrorHandler(e);
      toast.error(exception);
    }
  }

  const handleOnSearch = (event: { target: { value: any } }) => {
    const { value } = event.target;
    setSearchBy(value);
  };

  const msgCopyToClipBoard = (obj: any) => {
    setChatFormData((prevData: any) => ({
      ...prevData,
      chatInputText: obj?.messageBody,
    }));
    toast.success('Successfully Copied the Template');
  };

  return (
    <div className="mt-2 drop-shadow-md w-auto">
      <div className="bg-[#EAECF0] p-3 rounded-t-md h-auto">
        <div className="user-Template__btn-row flex flex-grow gap-2 justify-center items-center">
          <button
            type="button"
            className="template-btn__icon-color flex min-w-[80px] w-full justify-center
              font-ptScanCap capitalize
              rounded-md items-center btn-sm space-x-2 px-4 py-2
              bg-spaceCadetBlue text-white"
          >
            <Template />
            <span className="text-xs whitespace-nowrap">Templates</span>
          </button>
          <button
            type="button"
            className=" btn__icon-color flex min-w-[80px] w-full justify-center font-ptScanCap
               text-independenceBlue capitalize rounded-md items-center btn-sm
                space-x-2 px-4 py-2 bg-aliceBlue hover:bg-spaceCadetBlue hover:text-white"
            onClick={() => navigate(`${PATHS.CASES_MANAGEMENT.FORM.url}?md=new`)}
          >
            <NewCase />
            <span className="text-xs whitespace-nowrap">New Case</span>
          </button>
          <button
            type="button"
            className="case-btn__icon-color flex min-w-[80px] w-full justify-center
               font-ptScanCap text-independenceBlue capitalize
                rounded-md items-center btn-sm space-x-2
                 px-4 py-2 bg-aliceBlue hover:bg-spaceCadetBlue hover:text-white"
            onClick={() => navigate(`${PATHS.CASES_MANAGEMENT.LIST.url}`)}
          >
            <AddCase color="#475574" />
            <span className="text-xs whitespace-nowrap">Case</span>
          </button>
        </div>
      </div>
      <div className="bg-white p-2 rounded-b-md">
        <div className="flex flex-col lg:flex-row justify-between mb-4 items-center mt-2">
          <div className="mb-2 lg:mb-0 lg:mr-4">
            <SearchBar
              placeholder="Search Templates"
              searchByInput={searchBy}
              onSearch={handleOnSearch}
            />
          </div>
          <div className="flex flex-col lg:flex-row items-center">
            <div className="mb-2 lg:mb-0">
              <GeneralButton
                text="Add Template"
                btnColor="bg-spaceCadetBlue80"
                color="white"
                width="full lg:w-auto"
                radius="2xl"
                btnHoverColor="hover:bg-spaceCadetBlue"
                icon={<AddTemplate />}
                textSize="xs"
                handleOnClick={toggleTemplateModal}
              />
            </div>
          </div>
        </div>
        {templateList.map((obj) => (
          <div
            className="bg-lotionLightGray text-rasinBlack rounded-md p-2 mb-3 flex border border-[#E3E3E3] cursor-pointer hover:bg-culturedGray"
            key={obj._id}
            onDoubleClick={(event) => {
              event.preventDefault();
              toggleTemplateModal({ ...obj, isEdit: true });
            }}
          >
            <p className="font-lato text-xs w-full pr-2">{obj?.messageBody}</p>
            <CopyToClipboard text={obj?.messageBody} onCopy={() => msgCopyToClipBoard(obj)}>
              <button type="button" className="hover:opacity-50">
                <Copy />
              </button>
            </CopyToClipboard>
          </div>
        ))}
      </div>
    </div>
  );
}

UserTemplate.propTypes = {
  toggleTemplateModal: PropTypes.func,
  triggerChild: PropTypes.object,
  setChatFormData: PropTypes.func,
};

export default UserTemplate;
