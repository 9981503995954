/* eslint-disable import/no-extraneous-dependencies */
import { isValidPhoneNumber } from 'react-phone-number-input';
import * as yup from 'yup';

// Validations
export const createContactValidationSchema = yup.object({
  salutation: yup.string().required('Salutation is required'),
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string().email('Please enter valid email').required('Please enter email'),
  primaryContactNo: yup
    .string()
    .required('Please enter contact number')
    .test('invalid-primary-contact-no', 'Invalid contact number', (value) => {
      const isValid = isValidPhoneNumber(value);
      return isValid;
    }),

  secondaryContactNo: yup
    .string()
    .test('invalid-primary-contact-no', 'Invalid contact number', (value) => {
      if (!value) return true; // Field is not required, so an empty value is valid
      const isValid = isValidPhoneNumber(value);
      return isValid;
    }),

  preferredContactMethod: yup
    .object({
      phoneCall: yup.boolean(),
      whatsApp: yup.boolean(),
      email: yup.boolean(),
    })
    .test(
      'at-least-one-method',
      'At least one contact method must be selected',
      (value) => value.phoneCall || value.whatsApp || value.email,
    ),
  gender: yup.string().required('Gender is required'),
  dateOfBirth: yup
    .date()
    .notRequired()
    .test('invalid-date-of-birth', 'Invalid date of birth', (value) => {
      if (!value) return true; // Field is not required, so an empty value is valid
      // You can add your custom date of birth validation logic here
      // For example, you can check if the date is in the past and not in the future
      const currentDate = new Date();
      return value <= currentDate;
    }),
  designation: yup.string().notRequired(),
  company: yup.string().notRequired(),
  country: yup.string().required(),
  streetAddress: yup.string().notRequired(),
  city: yup.string().notRequired(),
  stateProvinceRegion: yup.string().notRequired(),
  postalCodeZipCode: yup.string().notRequired(),
});
