import React from 'react';
import { motion } from 'framer-motion';
import { useReactFlow } from 'reactflow';
import ZoomIn from '../tempIcons/ZoomIn';
import ZoomOut from '../tempIcons/ZoomOut';

function ZoomButtons() {
  const { zoomIn, zoomOut } = useReactFlow();

  return (
    <div>
      <motion.button
        whileTap={{ scale: 0.9 }}
        type="button"
        className="bg-white pl-[18px] pr-[15px] py-[10px] rounded-l-3xl shadow-sm hover:shadow-md"
        onClick={() => zoomIn({ duration: 200 })}
      >
        <div>
          <ZoomIn color="#688fa1" />
        </div>
      </motion.button>
      <motion.button
        whileTap={{ scale: 0.9 }}
        type="button"
        className="bg-white pr-[18px] pl-[15px] py-[10px] rounded-r-3xl shadow-sm hover:shadow-md border-l"
        onClick={() => zoomOut({ duration: 200 })}
      >
        <div>
          <ZoomOut color="#688fa1" />
        </div>
      </motion.button>
    </div>
  );
}

export default ZoomButtons;
