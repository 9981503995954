import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { Delete, Edit } from 'components/Icons';
import GeneralButton from 'components/Buttons/GeneralButton';
import { S3_ASSETS } from 'constants/assets';
import { isDateMoreThan30DaysAgo } from '../utils';

function QAFeedbackCard(props) {
  const {
    userName,
    date,
    reason: feedback,
    profilePic,
    rating = '4',
    triggerQAReviewModal,
    _id,
    handleOnToggleDeleteReview,
    completedAt,
  } = props;

  return (
    <div className="mb-2">
      <div className="user-account__card p-3 rounded-xl bg-[#FBFBFB] border-gainsboroBlue border-[1px] hover:bg-aliceBlue">
        <div className="flex pl-3">
          <div className="avatar mt-2">
            <div className="w-10 h-10 rounded-full ring-offset-base-100">
              <img src={profilePic || S3_ASSETS.CHAT_PREVIEW_PLACEHOLDER.url} alt="avatar" />
            </div>
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="p-2 ml-1">
              <p className="font-lato text-[#003249] font-extrabold text-sm">{userName}</p>
            </div>
            <div className="flex justify-center items-center">
              <p className="font-lato text-xs mr-3">
                {' '}
                {moment(date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD - hh:mm:ss A')}
              </p>
              {rating ? (
                <div className="flex h-14 w-14 cursor-pointer items-center text-white justify-center rounded-full bg-spaceCadetBlue border-[#D9DEE8] text-xl font-bold">
                  {rating}
                </div>
              ) : (
                <div />
              )}
            </div>
          </div>
        </div>

        <hr className="mt-2 mb-3" />
        {feedback && (
          <span className="h-auto pl-3 pr-3 font-bold text-lg text-spaceCadetBlue">Feedback</span>
        )}

        <div className="flex pl-3 text-[13px] w-[600px] justify-between items-center mt-2 mb-4 overflow-hidden">
          {feedback}
        </div>

        <div className="space-x-4 pl-3">
          {!isDateMoreThan30DaysAgo(completedAt) && (
            <>
              <GeneralButton
                text="Edit"
                btnColor="bg-spaceCadetBlue80"
                color="white"
                radius="2xl"
                textSize="xs"
                handleOnClick={(event) => triggerQAReviewModal(event, { isEdit: true, _id })}
                btnHoverColor="hover:bg-spaceCadetBlue"
                icon={<Edit color="#fff" />}
              />
              <GeneralButton
                text="Delete"
                btnColor="bg-begoniaRed"
                color="white"
                radius="2xl"
                textSize="xs"
                handleOnClick={(event) => handleOnToggleDeleteReview(event, { _id })}
                btnHoverColor="hover:bg-begoniaRed"
                icon={<Delete color="#FFF" />}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
QAFeedbackCard.propTypes = {
  userName: PropTypes.string,
  profilePic: PropTypes.string,
  date: PropTypes.string,
  reason: PropTypes.string,
  rating: PropTypes.number,
  triggerQAReviewModal: PropTypes.func,
  _id: PropTypes.string,
  handleOnToggleDeleteReview: PropTypes.func,
  completedAt: PropTypes.any,
};

export default QAFeedbackCard;
