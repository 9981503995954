/* eslint-disable default-param-last */
/* eslint-disable no-restricted-syntax */
import moment from 'moment';

import { ISelectOption } from 'interfaces/component.interface';
import countriesData from 'assets/json/countries_meta.json';
import { ALL_PROPERTIES } from 'constants/properties';
import { COLLECTIONS } from 'constants/db';
import { SideBarMenuItem } from 'routes/sideBar.routes';

export function isUpperCase(str: string) {
  if (!str) return null;
  const isUpper = str === str.toUpperCase();
  return {
    text: str,
    isUpper,
  };
}

export const countryCodesList = countriesData.map((country) => ({
  value: country.dial_code,
  label: country.dial_code,
}));

export const countries = countriesData.map((country) => ({
  value: country.code.toLowerCase(),
  label: country.name,
}));

export const countryCodesArray = countriesData.map((country) => country.dial_code);

// get selected value for Select field
export const getValueForSelect = (value: string, options: any[]): ISelectOption => {
  const relevantDropdownItem = options.find((item: { value: string }) => item.value === value);
  return relevantDropdownItem;
};

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

/**
 * Updates the URL with new search parameters.
 * @param {Object} searchParams - The object containing the search parameters to update in the URL.
 */
export function updateURLWithSearchParams(searchParams) {
  const queryString = new URLSearchParams(searchParams).toString();

  if (isObjEmpty(searchParams)) {
    window.history.replaceState(null, '', window.location.pathname);
  } else {
    // Update the URL with the new search parameters
    window.history.replaceState(null, '', `?${queryString}`);
  }
}
/**
 * Format date time to human readable format
 * @param {string} dateTime - date time ISO string
 * @returns
 */
export const formatDateTime = (dateTime: string) => moment(dateTime).format('DD-MM-YYYY hh:mm A');

export function extractInitials(name: string): string {
  if (!name) {
    return '';
  }

  // Remove leading and trailing spaces, if any
  const trimmedName = name.trim();

  // Split the name into words
  const words = trimmedName.split(' ');

  // Initialize an empty string to store the initials
  let initials = '';

  // Loop through the words and add the first character of each word to the initials
  for (const word of words) {
    if (word.length > 0) {
      initials += word[0];
    }
  }

  // Return the first two characters of the initials or the whole string if it's shorter
  return initials.slice(0, 2);
}

export function isUrlMatch(
  patterns: string[] = [],
  url: string,
  subMenu?: SideBarMenuItem[],
): boolean {
  let currentPath = url;

  if (subMenu) {
    patterns = subMenu.map((val) => val.link);
  }

  if (currentPath.endsWith('/')) {
    currentPath = currentPath.slice(0, -1);
  }
  if (patterns.includes(currentPath)) {
    return true;
  }
  return false;
}

/**
 * Format data from object and arrays
 * @param {any} data - object
 * @param {string} key - module and key of the property
 * @returns
 */
export function formattedValues(data: any, key: string) {
  let formattedData = '';
  if (key === ALL_PROPERTIES[COLLECTIONS.Contact].preferredContactMethod) {
    const contactMethod = data || {};

    const methodDisplayNames = {
      email: 'Email',
      phoneCall: 'Phone Call',
      whatsApp: 'WhatsApp',
    };
    formattedData = Object.keys(contactMethod)
      .filter((method) => contactMethod[method] === true)
      .map((method) => methodDisplayNames[method])
      .join(', ');
  } else if (key === ALL_PROPERTIES[COLLECTIONS.Role].permissions) {
    formattedData = data.join(', ');
  }

  return formattedData;
}

export const getDefaultValueForSelect = (data: any, array: any[] = null, id = null) => {
  if (data && array && id) {
    const relevantDropdownItem = array.find((x) => x.value === data);
    if (relevantDropdownItem) {
      return { label: relevantDropdownItem?.label || data, value: data };
    }
    return { label: data, value: data };
  }
  if (data) {
    return { label: data, value: data };
  }
  return null;
};

export const getDefaultValueForVariableSelect = (data: any, array: any[] = null, id = null) => {
  if (data && array && id) {
    for (const group of array) {
      const relevantDropdownItem = group.options.find((x) => x.value === data);
      if (relevantDropdownItem) {
        return { label: relevantDropdownItem.label || data, value: data };
      }
    }
    return { label: data, value: data };
  }
  if (data) {
    return { label: data, value: data };
  }
  return null;
};
