import React from 'react';
import PropTypes from 'prop-types';

import { MODULES } from 'constants/modules';
import PrimaryButton from 'components/Buttons/Primary';
import { RemoveIcon } from 'components/Icons';

function CustomBtn(props) {
  const { module, handlers, value, row } = props;
  const { onUnlinkChannel } = handlers;

  // Check if the module matches and it's not the default channel
  if (module === MODULES.SETTINGS.MESSENGERS.FORM_LINKED_CHANNELS) {
    return (
      <PrimaryButton
        text="Unlink"
        btnColor="bg-begoniaRed"
        btnHoverColor="hover:bg-coralRed"
        color="white"
        icon={<RemoveIcon />}
        textSize="xs font-bold font-ptScanCap"
        radius="2xl"
        handleOnClick={() => onUnlinkChannel(row)}
        className="px-[20px] py-[10px]"
      />
    );
  }
}

CustomBtn.propTypes = {
  module: PropTypes.string,
  handlers: PropTypes.object,
  value: PropTypes.any,
  row: PropTypes.object,
};

export default CustomBtn;
