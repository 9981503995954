/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { omitBy, isUndefined } from 'lodash';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';

import { fetchContactListData } from 'services/contact.service';
import { apiErrorHandler } from 'api/handler';
import { PATHS } from 'routes/paths';
import {
  fetchConversationsListOfBotChats,
  fetchPreviewDataOfBotChatsById,
  takeChat,
} from 'services/conversation.service';
import { PromptModal } from 'components';
import { RootState } from 'slices';
import { clearNewConversation } from 'slices/event';
import { InstanceType } from 'enums/messenger.enum';
import { PromptTypes, tableDefaultValues } from './constants';
import ListPage from './page';
import { formatListViewObject, mapListViewDocs } from './utils';
import ChatPreview from './containers/ChatPreview';

function BotChatListView() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { agentStatus } = useSelector((state: RootState) => state.user.user);
  const { newConversation } = useSelector((state: RootState) => state.event);

  // state to store URL search parameters
  const [urlSearchParams, setUrlSearchParams] = useState(null);
  const [triggerChild, setTriggerChild] = useState({ botChats: null });

  const [contactListData, setContactListData] = useState([]);
  const [botMessageListData, setBotMessageListData] = useState(tableDefaultValues);

  const [promptOptions, setPromptOptions] = useState<any>({});
  const [visible, setVisible] = useState(false);
  const [botChatData, setBotChatData] = useState({});

  // Effect to initialize URL search parameters from location
  useEffect(() => {
    const urlSearch = Object.fromEntries(new URLSearchParams(location.search));
    if (urlSearch && urlSearch.page) {
      replaceUrlParamsHandler({ ...urlSearchParams, ...urlSearch });
    } else if (urlSearch && !urlSearch.page) {
      replaceUrlParamsHandler({ page: urlSearch.page, limit: 10, i: urlSearch.i });
    } else {
      replaceUrlParamsHandler({ page: urlSearch.page, limit: 10 });
    }
    setUrlSearchParams(urlSearch);
  }, []);

  useEffect(() => {
    if (urlSearchParams && botMessageListData.docs.length > 0) {
      getBotChatPreviewDataById({ ...botMessageListData, ...urlSearchParams });
    }
  }, [urlSearchParams, botMessageListData]);

  useEffect(() => {
    getContactListData();
  }, []);

  useEffect(() => {
    if (urlSearchParams) {
      getBotConversationList({ ...botMessageListData, ...urlSearchParams });
    }
  }, [urlSearchParams, triggerChild.botChats]);

  useEffect(() => {
    if (newConversation) {
      const { messengerInstance } = newConversation;
      if (messengerInstance && messengerInstance.instanceType === InstanceType.Chatbot) {
        const updatedDocs = botMessageListData.docs.slice(0, -1);
        updatedDocs.unshift(formatListViewObject(newConversation));
        setBotMessageListData({
          ...botMessageListData,
          docs: updatedDocs,
        });
      }

      dispatch(clearNewConversation());
    }
  }, [newConversation]);

  const getContactListData = async () => {
    try {
      const { data } = await fetchContactListData({ limit: 100 });

      const docs = (data?.docs || []).map((doc) => ({
        value: doc?._id,
        label: `${doc?.firstName} ${doc?.lastName}\n${doc?.email}\n${doc?.primaryContactNo}`,
      }));
      setContactListData(docs);
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    }
  };

  const getBotConversationList = async (params: any) => {
    try {
      const { data } = await fetchConversationsListOfBotChats(params);
      if (data.node.docs) {
        const docs = mapListViewDocs(data.node.docs);
        setBotMessageListData({ ...botMessageListData, ...data.node, docs });
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    }
  };
  // Preview data of bot chats
  const getBotChatPreviewDataById = async (params: any) => {
    try {
      const id = params.i;
      if (id) {
        const { data } = await fetchPreviewDataOfBotChatsById(id);
        if (data.node) {
          setBotChatData(data.node);
          setVisible(true);
        }
      }
    } catch (error) {
      closePreview();
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    }
  };

  /**
   * Function to replace URL search parameters
   */
  const replaceUrlParamsHandler = (params) => {
    const queryParams = omitBy(
      {
        page: params?.page || '1',
        limit: params.limit || '10',
        contact: params.contact,
        order: params.order,
        searchBy: params.searchBy,
        country: params.country,
        orderBy: params.orderBy,
        contactId: params.contactId,
        i: params.i,
      },
      isUndefined,
    );
    const searchParams = new URLSearchParams(queryParams);
    setUrlSearchParams(queryParams);
    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  /**
   * Handle page change in pagination by replacing the url search params
   *
   * @param {number} pageNo - Page number user requesting to navigate
   */
  const handlePageChange = (pageNo: number) => {
    replaceUrlParamsHandler({
      ...urlSearchParams,
      page: pageNo,
    });
  };

  /**
   * A callback function that is triggered when the user changes the number of
   * results to display per page.
   * @param {number} limit - The new limit value (i.e. number of results per page).
   */
  const handleResultsPerPageChange = (limit: number) => {
    setBotMessageListData((prevTableData) => ({ ...prevTableData, limit }));
    replaceUrlParamsHandler({
      page: botMessageListData.page,
      limit,
    });
  };

  /**
   * Handle filtering by adding and removing url params using value from the element and passed id
   * @param {string} value - Value that used to search or filter the field eg: doe@abc.com
   * @param {string} id - Field or parameter that need to use for filtering. eg: adminEmail
   */
  const handleFilterBy = (value: string, i: string) => {
    // create a copy of url search param
    const updatedSearchParams = {
      ...urlSearchParams,
    };
    // add new param
    if (value) {
      updatedSearchParams[i] = value;
    } else {
      delete updatedSearchParams[i]; // Remove the key if value is undefined
    }
    // update url
    replaceUrlParamsHandler(updatedSearchParams);
  };

  const handleTakeChatPrompt = (row: any) => {
    if (agentStatus === 'offline') {
      setPromptOptions({
        show: true,
        title: `Take Chat ${row?.sequenceId}`,
        message: 'Do you want to Open the Inbox and Take this Chat?',
        error: 'Unable to Take chats when you are not active in inbox.',
        onConfirm: () => handleUpdateTakeChat(row?._id, true),
        type: PromptTypes.TakeChat,
      });
    } else {
      handleUpdateTakeChat(row?._id);
    }
  };

  const handleUpdateTakeChat = async (conversationId: string, gotoInbox = false) => {
    try {
      const { data } = await takeChat(conversationId);

      if (data.status) {
        if (gotoInbox) {
          window.open(`${PATHS.INBOX.DEFAULT.url}`, '_blank');
        }
        setPromptOptions({
          show: false,
          ...promptOptions,
        });
        setTriggerChild((prevState) => ({ ...prevState, botChats: uuid() }));
        toast.success('Successfully took the chat');
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    }
  };

  const handleTakeChat = (event, row) => {
    setPromptOptions({
      show: true,
      title: `Take Chat ${row?.sequenceId}`,
      message: 'Do you want to take this chat?',
      onConfirm: () => handleTakeChatPrompt(row),
      type: PromptTypes.TakeChat,
    });
  };
  const urlSearch = Object.fromEntries(new URLSearchParams(location.search));
  const closePreview = () => {
    setVisible(false);
    setBotChatData(null);
    replaceUrlParamsHandler({ page: urlSearch.page, limit: 10 });
  };

  return (
    <>
      <ListPage
        contactListData={contactListData}
        handleTakeChat={handleTakeChat}
        handleFilterBy={handleFilterBy}
        handlePageChange={handlePageChange}
        handleResultsPerPageChange={handleResultsPerPageChange}
        botMessageListData={botMessageListData}
      />

      <ChatPreview
        visible={visible}
        toggleHide={closePreview}
        conversationData={botChatData}
        handleTakeChat={handleTakeChat}
      />

      {promptOptions.show && (
        <PromptModal
          onCancel={() => {
            setPromptOptions({ ...promptOptions, show: false });
          }}
          {...promptOptions}
          loading={false}
        />
      )}
    </>
  );
}

export default BotChatListView;
