import React from 'react';
import PropTypes from 'prop-types';

import CompleteChatBubble from 'components/ChatBubbles/CompleteChatBubble';

import settingsAvatar from 'assets/images/settings.png';

function SystemChatBubble(props) {
  const { text, date, handleCompleteConversation, conversationData } = props;
  return (
    <div>
      {/* system */}
      <div className="flex">
        <div className="avatar">
          <div className="w-10 h-10 rounded-full">
            <img src={settingsAvatar} alt="avatar" />
          </div>
        </div>

        <div className="mt-0 ml-2">
          <p className="text-xs text-raisnBlack font-lato font-semibold ml-1">
            System
            <span className="text-[10px] ml-2 text-independenceBlue">{date}</span>
          </p>

          <CompleteChatBubble
            message={text}
            handleCompleteMsg={handleCompleteConversation}
            conversationData={conversationData}
          />
        </div>
      </div>
    </div>
  );
}

SystemChatBubble.propTypes = {
  handleCompleteConversation: PropTypes.func,
  text: PropTypes.string,
  date: PropTypes.string,
  conversationData: PropTypes.object,
};

export default SystemChatBubble;
