/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getUserById } from 'services/user.service';
import { apiErrorHandler } from 'api/handler';
import { getUserType } from 'utils/auth';

import { UserType } from 'constants/user.const';
import UserProfilePage from './page';

function UserProfile() {
  // getting the current user type logged into the system
  const currentUserType = getUserType();

  const defaultFormData = {
    userType: currentUserType === UserType.ClientUser ? UserType.ClientUser : null,
  };

  const [searchParams] = useSearchParams(); // read url params

  const [formData, setFormData] = useState<any>(defaultFormData);

  const [attachments, setAttachments] = useState<any>({ profilePic: null });

  const pageId = searchParams.get('i');

  useEffect(() => {
    // Check if pageId is available
    if (pageId) {
      // Fetch user data using the fetchUserData function
      fetchUserData(pageId);
    }
  }, [pageId]);

  async function fetchUserData(id: string) {
    try {
      const { data } = await getUserById(id);

      if (data?.node) {
        const { roles } = data.node;
        setFormData((prevState) => ({
          ...prevState,
          ...data.node,
          roles: roles ? roles.map((val) => ({ ...val, label: val.name, value: val._id })) : [],
        }));
        if (data.node.attachments) {
          setAttachments((prevState) => ({ ...prevState, ...data.node.attachments }));
        }
      }
    } catch (e) {
      const { message: exception } = apiErrorHandler(e);
      toast.error(exception);
    }
  }

  return <UserProfilePage formData={formData} />;
}

export default UserProfile;
