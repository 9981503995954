/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { Close, Delete } from 'components/Icons';
import { deleteFlowAttachment, uploadFlowAttachment } from 'services/messengerFlow.service';
import { IMessageSetting } from '../interfaces';
import ImageIcon from './tempIcons/Image';
import UpIcon from './tempIcons/Up';

interface IImageAttachmentComponentProps {
  settingsArray: IMessageSetting[];
  componentData: any;
  setSettingsArr: React.Dispatch<React.SetStateAction<IMessageSetting[]>>;
}

function ImageAttachmentComponent(props: IImageAttachmentComponentProps) {
  const { settingsArray, componentData, setSettingsArr } = props;

  const location = useLocation();

  const inputRef = useRef(null);
  const fileInputRef = useRef(null);

  const [showFileMenu, setShowFileMenu] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const [selectedOption, setSelectedOption] = useState('File');

  const [text, setText] = useState('');
  const [isTyping, setIsTyping] = useState(false);

  const [isHovered, setIsHovered] = useState(false);

  const handleOnClickFileUpload = () => {
    // Trigger the click event on the hidden file input
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    // Handle the selected file here
    const selectedFile = event.target.files[0];

    // check if the selected file is a valid image type
    if (selectedFile && (selectedFile.type === 'image/png' || selectedFile.type === 'image/jpeg')) {
      addImage(componentData.id, selectedFile);
    } else {
      toast.error('Please select a valid image file (PNG or JPEG).');
    }
  };

  const handleClearSelectedFile = () => {
    removeImage(componentData.id);
  };

  const handleDropdownItemClick = (item) => {
    setSelectedOption(item);
    setShowDropdown(false);
    // You can add more logic here based on the selected item
  };

  const handleChange = (event) => {
    const newText = event.target.value;
    setText(newText);
  };

  const handleFocus = () => {
    setIsTyping(true);
  };

  const handleBlur = () => {
    setIsTyping(false);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const addImage = async (parentId: string, file: any) => {
    // Create FormData and append the selected file
    const payload = new FormData();
    payload.append('file', file);

    let data: any;

    try {
      const responseObj = await uploadFlowAttachment(payload);
      data = responseObj?.data;
    } catch (error) {
      toast.error('Something went wrong while uploading image');
    }

    if (data?.node) {
      const { attachment } = data.node;

      setSettingsArr((prev) => {
        // Find the parent object by its id
        const parentObjectIndex = prev.findIndex((item) => item.id === parentId);

        // If the parent object is found
        if (parentObjectIndex !== -1) {
          // Clone the array to avoid mutating the original state
          const updatedArray = [...prev];

          // Clone the parent object and update the buttons array with the new button
          updatedArray[parentObjectIndex] = {
            ...updatedArray[parentObjectIndex],
            url: attachment?.location,
            attachmentId: attachment?._id,
          };

          return updatedArray;
        }

        // If the parent object is not found, return the original array
        return prev;
      });
    }
  };

  const removeImage = async (parentId: string) => {
    const nodeIndex = settingsArray?.findIndex((item) => item.id === parentId);

    if (nodeIndex !== -1) {
      const { attachmentId } = settingsArray[nodeIndex];
      const urlQueryPrams = Object.fromEntries(new URLSearchParams(location.search));
      const intentId = urlQueryPrams.i;

      // remove attachment from attachment model
      await deleteFlowAttachment(intentId, attachmentId);
    }

    // update state by removing attachment data
    setSettingsArr((prev) => {
      // Find the parent object by its id
      const parentObjectIndex = prev.findIndex((item) => item.id === parentId);

      // If the parent object is found
      if (parentObjectIndex !== -1) {
        // Clone the array to avoid mutating the original state
        const updatedArray = [...prev];

        // Clone the parent object and update the buttons array with the new button
        updatedArray[parentObjectIndex] = {
          ...updatedArray[parentObjectIndex],
          url: '',
          attachmentId: null,
        };

        return updatedArray;
      }

      // If the parent object is not found, return the original array
      return prev;
    });
  };

  const getBorderColor = () => {
    if (text === '' && !isTyping) {
      return 'border-red-300'; // Red border for empty text area
    }
    if (isTyping) {
      return 'border-green-300'; // Green border when typing
    }
    return 'border-gray-300'; // Gray border for non-empty and not typing
  };

  const handleDelete = () => {
    setSettingsArr((prev) => {
      if (prev.length === 1) {
        return prev;
      }
      // Filter out the item to be deleted
      const updatedArray = prev.filter((component) => component.id !== componentData.id);

      // Update positions based on the new array
      const newArrayWithUpdatedPositions = updatedArray.map((component, index) => ({
        ...component,
        position: index + 1, // Update the position based on the new index
      }));

      return newArrayWithUpdatedPositions;
    });
  };
  const handleUp = () => {
    setSettingsArr((prev) => {
      // Find the index of the item to move up
      const indexToMoveUp = prev.findIndex((component) => component.id === componentData.id);

      // Check if the item is not already at the top
      if (indexToMoveUp > 0) {
        // Swap positions with the item above it
        const updatedArray = [...prev];
        [updatedArray[indexToMoveUp - 1], updatedArray[indexToMoveUp]] = [
          updatedArray[indexToMoveUp],
          updatedArray[indexToMoveUp - 1],
        ];

        // Update positions based on the new array
        const newArrayWithUpdatedPositions = updatedArray.map((component, index) => ({
          ...component,
          position: index + 1, // Update the position based on the new index
        }));

        return newArrayWithUpdatedPositions;
      }

      // If the item is already at the top, return the original array
      return prev;
    });
  };
  const handleDown = () => {
    setSettingsArr((prev) => {
      // Find the index of the item to move down
      const indexToMoveDown = prev.findIndex((component) => component.id === componentData.id);

      // Check if the item is not already at the bottom
      if (indexToMoveDown < prev.length - 1 && indexToMoveDown !== -1) {
        // Swap positions with the item below it
        const updatedArray = [...prev];
        [updatedArray[indexToMoveDown], updatedArray[indexToMoveDown + 1]] = [
          updatedArray[indexToMoveDown + 1],
          updatedArray[indexToMoveDown],
        ];

        // Update positions based on the new array
        const newArrayWithUpdatedPositions = updatedArray.map((component, index) => ({
          ...component,
          position: index + 1, // Update the position based on the new index
        }));

        return newArrayWithUpdatedPositions;
      }

      // If the item is already at the bottom, return the original array
      return prev;
    });
  };

  if (componentData?.url) {
    return (
      <div className="relative" onMouseEnter={handleMouseEnter}>
        <div className="flex flex-col items-center justify-center bg-[#F9FEFF] hover:bg-[#F0FDFF] w-full h-[150px] border-dashed border-[1px] border-[#0b99b2] rounded-lg cursor-pointer">
          <div className="relative">
            <img className="max-h-[148px]" src={componentData?.url} alt="Selected" />
            <div className="absolute top-0 right-0 m-2">
              <button title="clear" type="button" onClick={handleClearSelectedFile}>
                <Close color="#000" />
              </button>
            </div>
          </div>
        </div>
        <div className="absolute top-1/2 -right-6 transform -translate-y-1/2 flex flex-col justify-center items-center z-20">
          <button
            type="button"
            className={`rounded-md bg-none drop-shadow-xl ${isHovered ? 'visible' : 'hidden'}`}
            onClick={(e) => {
              e.stopPropagation();
              handleDelete();
            }}
          >
            <Delete id="node-delete-icon" color="#FF7171" width={20} height={20} />
          </button>
          <button
            type="button"
            className={`rounded-md bg-none drop-shadow-xl mt-2 ${
              isHovered && componentData?.position !== 1 ? 'visible' : 'hidden'
            }`}
            onClick={(e) => {
              e.stopPropagation();
              handleUp();
            }}
          >
            <UpIcon width={20} height={20} />
          </button>
          <button
            type="button"
            className={`rounded-md bg-none drop-shadow-xl ${
              isHovered && componentData?.position !== settingsArray?.length
                ? 'visible'
                : isHovered && settingsArray?.length === 1
                ? 'visible'
                : 'hidden'
            }`}
            onClick={(e) => {
              e.stopPropagation();
              handleDown();
            }}
          >
            <div style={{ transform: 'rotateX(180deg)' }}>
              <UpIcon width={20} height={20} />
            </div>
          </button>
        </div>
      </div>
    );
  }
  return (
    <div className="relative" onMouseEnter={handleMouseEnter}>
      <button
        type="button"
        className={`absolute top-0 left-0 text-xs bg-none drop-shadow-xl mt-2 flex items-center pl-3 ${
          showFileMenu ? 'text-red-600' : 'text-spaceCadetBlue'
        }`}
        onClick={() => {
          setShowFileMenu(!showFileMenu);
          setShowDropdown(!showDropdown);
        }}
      >
        {selectedOption}&nbsp;
        {showFileMenu ? (
          <span style={{ fontSize: '0.7em' }}>&#9650;</span>
        ) : (
          <span style={{ fontSize: '0.7em' }}>&#9660;</span>
        )}
      </button>
      {/* Dropdown */}
      {showDropdown && (
        <div className="absolute top-8 left-3 bg-white border rounded-md p-2 z-50">
          <button
            type="button"
            className="block w-full text-left p-2 hover:bg-gray-200"
            onClick={() => handleDropdownItemClick('File')}
          >
            File
          </button>
          <button
            type="button"
            className="block w-full text-left p-2 hover:bg-gray-200"
            onClick={() => handleDropdownItemClick('Image URL')}
          >
            Image URL
          </button>
        </div>
      )}
      <div className="flex flex-col items-center justify-center bg-[#F9FEFF] hover:bg-[#F0FDFF] w-full h-[150px] border-dashed border-[1px] border-[#0b99b2] rounded-lg cursor-pointer">
        {selectedOption === 'Image URL' ? (
          <div className="w-[90%]">
            <input
              ref={inputRef}
              className={`w-full rounded-md border-[1px] focus:outline-none ${getBorderColor()} p-2 relative z-10 flex-grow`}
              value={text}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              placeholder="Type your message"
            />
          </div>
        ) : (
          <>
            <ImageIcon />
            <button
              type="button"
              className="bg-[#0B99B2] m-2 w-auto text-white p-2 text-xs"
              onClick={handleOnClickFileUpload}
            >
              Select file...
            </button>
            {/* Hidden file input */}
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            <span className="text-[#0B99B2] text-xs">or drag and drop image here (1MB)</span>
          </>
        )}
      </div>
      <div className="absolute top-1/2 -right-6 transform -translate-y-1/2 flex flex-col justify-center items-center z-20">
        <button
          type="button"
          className={`rounded-md bg-none drop-shadow-xl ${isHovered ? 'visible' : 'hidden'}`}
          onClick={(e) => {
            e.stopPropagation();
            handleDelete();
          }}
        >
          <Delete id="node-delete-icon" color="#FF7171" width={20} height={20} />
        </button>

        <button
          type="button"
          className={`rounded-md bg-none drop-shadow-xl mt-2 ${
            isHovered && componentData?.position !== 1 ? 'visible' : 'hidden'
          }`}
          onClick={(e) => {
            e.stopPropagation();
            handleUp();
          }}
        >
          <UpIcon width={20} height={20} />
        </button>
        <button
          type="button"
          className={`rounded-md bg-none drop-shadow-xl ${
            isHovered && componentData?.position !== settingsArray?.length
              ? 'visible'
              : isHovered && settingsArray?.length === 1
              ? 'visible'
              : 'hidden'
          }`}
          onClick={(e) => {
            e.stopPropagation();
            handleDown();
          }}
        >
          <div style={{ transform: 'rotateX(180deg)' }}>
            <UpIcon width={20} height={20} />
          </div>
        </button>
      </div>
    </div>
  );
}

ImageAttachmentComponent.propTypes = {
  componentData: PropTypes.object,
  settingsArray: PropTypes.array,
  setSettingsArr: PropTypes.func,
};

export default ImageAttachmentComponent;
