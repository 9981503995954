/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';

import { CustomSelect, InputText, PrimaryButton, TransactionTable } from 'components';
import { getValueForSelect } from 'utils/helpers';
import { Back, Disable, Save } from 'components/Icons';

import { reactSelectCustomStyles } from 'styles/select';
import GeneralButton from 'components/Buttons/GeneralButton';
import { useNavigate } from 'react-router-dom';
import { COLLECTIONS } from 'constants/db';

import { transactionTableHeaders } from './constants';
import FormEditHeader from './containers/FormEditHeader';
import UserTable from './components/UserTable';

function FormPage(props) {
  const {
    handleSubmit,
    onSubmit,
    register,
    errors,
    handleInputChange,
    formData,
    editMode,
    customLoading,
    triggerChild,
    handleSelectChange,
    usersList,
    handleAddUser,
    handleRemoveUser,
    disableChannel,
    channelUsersList,
  } = props;

  const navigate = useNavigate();

  return (
    <div className="m-8">
      <div className="flex justify-between items-center">
        <div>
          {!editMode ? (
            <span className="text-spaceCadetBlue font-bold text-lg font-ptScanCap pb-4">
              Create a New Channel
            </span>
          ) : (
            <FormEditHeader formData={formData} />
          )}
        </div>
        <div className="flex gap-2">
          {editMode && (
            <PrimaryButton
              text={formData?.isActive ? 'Disable' : 'Enable'}
              btnColor={formData?.isActive ? 'bg-begoniaRed' : 'bg-green-500'}
              color="white"
              width="[140px]"
              radius="2xl"
              btnHoverColor={formData?.isActive ? 'hover:bg-begoniaRed' : 'hover:bg-green-500'}
              icon={<Disable />}
              handleOnClick={() => disableChannel()}
            />
          )}

          <GeneralButton
            text="Back"
            btnColor="bg-spaceCadetBlue80"
            color="white"
            radius="2xl"
            textSize="sm"
            btnHoverColor="hover:bg-spaceCadetBlue"
            icon={<Back />}
            handleOnClick={() => navigate(-1)}
          />
        </div>
      </div>
      <hr className="my-3" />
      <div className="p-8 bg-white drop-shadow rounded-xl mt-6">
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="grid grid-cols-1 xs:grid-cols-1 rg:grid-cols-2 lg:grid-cols-3 gap-4">
            <div className="mb-2">
              <InputText
                type="text"
                elementId="channelName"
                label="Channel Name"
                placeholder="eg: Accounts Department"
                register={register}
                error={errors?.channelName}
                value={formData?.channelName}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 xs:grid-cols-1 rg:grid-cols-2 lg:grid-cols-3 gap-4">
            <div className="mb-2">
              <InputText
                type="text"
                elementId="description"
                label="Channel Description"
                placeholder="Type a Description"
                register={register}
                error={errors?.description}
                value={formData?.description}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="grid grid-cols-3 xs:grid-cols-3 rg:grid-cols-2 lg:grid-cols-3 gap-4">
            <div className="mb-4">
              <div className="form-control">
                <label className="label" htmlFor="status">
                  <span className="label-text font-normal text-base font-lato">Owner</span>
                </label>
              </div>
              <CustomSelect
                id="ownerId"
                customStyles={reactSelectCustomStyles}
                options={usersList}
                isLoading={customLoading.fetchForm}
                error={errors?.ownerId}
                value={getValueForSelect(formData?.ownerId, usersList)}
                onChange={(event) => handleSelectChange(event, 'ownerId')}
              />
            </div>
          </div>

          <div className="mt-4 mb-4">
            <PrimaryButton
              text={editMode ? 'Update' : 'Save'}
              btnColor="bg-spaceCadetBlue80"
              color="white"
              width="[140px]"
              radius="2xl"
              btnHoverColor="hover:bg-spaceCadetBlue"
              icon={<Save />}
              disabled={customLoading.formSubmit}
              loading={customLoading.formSubmit}
            />
          </div>
        </form>
      </div>
      {editMode && formData._id && (
        <div className="container mx-auto p-8 bg-white drop-shadow rounded-xl mt-6">
          <UserTable
            handleRemoveUser={handleRemoveUser}
            handleAddUser={handleAddUser}
            listData={channelUsersList}
          />
        </div>
      )}
      {editMode && formData._id && (
        <div className="container mx-auto p-8 bg-white drop-shadow rounded-xl mt-6">
          <TransactionTable
            recordId={formData._id}
            collection={COLLECTIONS.Channel}
            transactionTableHeaders={transactionTableHeaders}
            triggerChild={triggerChild.transactions}
          />
        </div>
      )}
    </div>
  );
}

FormPage.propTypes = {
  handleSubmit: PropTypes.func,
  customLoading: PropTypes.any,
  onSubmit: PropTypes.func,
  register: PropTypes.func,
  errors: PropTypes.any,
  editMode: PropTypes.bool,
  formData: PropTypes.any,
  triggerChild: PropTypes.any,
  handleInputChange: PropTypes.func,
  handleSelectChange: PropTypes.func,
  usersList: PropTypes.array,
  handleAddUser: PropTypes.func,
  handleRemoveUser: PropTypes.func,
  disableChannel: PropTypes.func,
  channelUsersList: PropTypes.object,
};

export default FormPage;
