import * as React from 'react';
import PropTypes from 'prop-types';

function SortBy(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color, width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={width}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g
        stroke="#475574"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        clipPath="url(#clip0_139_863)"
      >
        <path d="M3 9l4-4m0 0l4 4M7 5v14M21 15l-4 4m0 0l-4-4m4 4V5" />
      </g>
      <defs>
        <clipPath id="clip0_139_863">
          <path fill="#fff" d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
SortBy.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default SortBy;
