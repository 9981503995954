import * as React from 'react';
import PropTypes from 'prop-types';

function InputSend(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color = '#475574', width = 24, height = 24 } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2749_84)">
        <path
          d="M10.55 14L21.2751 3"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.275 3L14.9375 21C14.8947 21.0957 14.826 21.1769 14.7396 21.2338C14.6532 21.2906 14.5526 21.3209 14.45 21.3209C14.3473 21.3209 14.2468 21.2906 14.1603 21.2338C14.0739 21.1769 14.0052 21.0957 13.9625 21L10.55 14L3.72496 10.5C3.63161 10.4561 3.55251 10.3857 3.49705 10.2971C3.44159 10.2084 3.41211 10.1053 3.41211 10C3.41211 9.89468 3.44159 9.79158 3.49705 9.70295C3.55251 9.61431 3.63161 9.54387 3.72496 9.5L21.275 3Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2749_84">
          <rect width="23.4" height="24" fill="white" transform="translate(0.800049)" />
        </clipPath>
      </defs>
    </svg>
  );
}
InputSend.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default InputSend;
