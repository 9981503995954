import * as React from 'react';
import PropTypes from 'prop-types';

function ArrowDown(props: { color: any; width: any; height: any }) {
  const { color = '#fff', width = 8, height = 16 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 10 16"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M9 11l-4 4m0 0l-4-4m4 4V1"
      />
    </svg>
  );
}
ArrowDown.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default ArrowDown;
