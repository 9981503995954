import * as React from 'react';
import PropTypes from 'prop-types';

function TextIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color, width = 38, height = 22 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 3C9 2.44772 9.44772 2 10 2H26C26.5523 2 27 2.44772 27 3V3C27 3.55228 26.5523 4 26 4H10C9.44772 4 9 3.55228 9 3V3Z"
        fill="#0B99B2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 11C9 10.4477 9.44772 10 10 10H26C26.5523 10 27 10.4477 27 11V11C27 11.5523 26.5523 12 26 12H10C9.44772 12 9 11.5523 9 11V11Z"
        fill="#0B99B2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 19C9 18.4477 9.44772 18 10 18H26C26.5523 18 27 18.4477 27 19V19C27 19.5523 26.5523 20 26 20H10C9.44772 20 9 19.5523 9 19V19Z"
        fill="#0B99B2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 7C9 6.44772 9.44772 6 10 6H22C22.5523 6 23 6.44772 23 7V7C23 7.55228 22.5523 8 22 8H10C9.44772 8 9 7.55228 9 7V7Z"
        fill="#0B99B2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 15C9 14.4477 9.44772 14 10 14H18.1818C18.7341 14 19.1818 14.4477 19.1818 15V15C19.1818 15.5523 18.7341 16 18.1818 16H10C9.44771 16 9 15.5523 9 15V15Z"
        fill="#0B99B2"
      />
    </svg>
  );
}
TextIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default TextIcon;
