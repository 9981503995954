import _ from 'lodash';

import instance from 'api';
import { ROLES, USER } from 'api/apiEndpoints';
import UrlBuilder from 'api/urlQueryBuilder';
import { ICreateRole, IRolesListURLQueryParams, IUpdateRole } from 'interfaces/roles.interface';

export const fetchRolesListData = (params: IRolesListURLQueryParams) => {
  const {
    page = 1,
    limit = 10,
    sort = 'createdAt',
    order = -1,
    clientId,
    userType,
    searchBy,
    status,
    lastUpdatedAt,
  } = params;

  // creates a new object with only the non-null, non-undefined values from the extracted variables
  const queryParams = _.pickBy({
    page,
    limit,
    sort,
    order,
    clientId,
    userType,
    searchBy,
    status,
    lastUpdatedAt,
  });
  const url = UrlBuilder()
    .setUrl(ROLES.GET_ROLES_LIST)
    .setQueryParams({ ...queryParams })
    .build();
  return instance.get(url);
};

export const createRole = (data: ICreateRole) => {
  const url = UrlBuilder().setUrl(ROLES.CREATE_ROLE).build();
  return instance.post(url, data);
};

export const getRolePermissionById = (id: string) => {
  const url = UrlBuilder().setUrl(ROLES.GET_ROLE_BY_ID).setPathParams({ id }).build();
  return instance.get(url);
};

export const updateRolePermissionById = (id: string, data: IUpdateRole) => {
  const url = UrlBuilder().setUrl(ROLES.UPDATE_ROLE_BY_ID).setPathParams({ id }).build();
  return instance.patch(url, data);
};

export const deleteRolePermissionById = (id: string) => {
  const url = UrlBuilder().setUrl(ROLES.DELETE_ROLE_BY_ID).setPathParams({ id }).build();
  return instance.patch(url);
};

export const addRolePermissionsById = (id: string, data: { permissions: string[] }) => {
  const url = UrlBuilder().setUrl(ROLES.ADD_ROLE_PERMISSION).setPathParams({ id }).build();
  return instance.patch(url, data);
};
