import * as React from 'react';
import PropTypes from 'prop-types';

function DelayIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color, width = 38, height = 22 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 3H20C24.4183 3 28 6.58172 28 11C28 15.4183 24.4183 19 20 19H15C10.5817 19 7 15.4183 7 11V4C7 3.44772 7.44772 3 8 3Z"
        stroke="#0B99B2"
        strokeWidth="2"
      />
      <circle cx="12.5" cy="8.5" r="1.5" fill="#0B99B2" />
      <circle cx="17.5" cy="10.5" r="1.5" fill="#0B99B2" />
      <circle cx="22.5" cy="10.5" r="1.5" fill="#0B99B2" />
    </svg>
  );
}
DelayIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default DelayIcon;
