import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';

import { authRegister } from 'services/auth.service';
import { apiErrorHandler } from 'api/handler';

function SignUpPage() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await authRegister(formData);
      // if (data) {
      // } else {
      //   toast.error('Something went wrong, Please try again');
      // }
    } catch (err) {
      const { message } = apiErrorHandler(err);
      toast.error(message);
    }
  };

  return (
    <div className="bg-gray-200 min-h-screen flex justify-center items-center">
      <motion.div
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -20 }}
        className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md"
      >
        <h2 className="text-2xl font-bold mb-4">Sign Up</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <motion.label
              htmlFor="name"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              className="block text-gray-700 font-bold mb-2"
            >
              Name
            </motion.label>
            <motion.input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full border rounded-md py-2 px-3"
              placeholder="Enter your name"
              required
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
            />
          </div>
          <div className="mb-4">
            <motion.label
              htmlFor="email"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              className="block text-gray-700 font-bold mb-2"
            >
              Email
            </motion.label>
            <motion.input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full border rounded-md py-2 px-3"
              placeholder="Enter your email"
              required
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
            />
          </div>
          <div className="mb-4">
            <motion.label
              htmlFor="password"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              className="block text-gray-700 font-bold mb-2"
            >
              Password
            </motion.label>
            <motion.input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="w-full border rounded-md py-2 px-3"
              placeholder="Enter your password"
              required
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
            />
          </div>
          <div className="mb-4">
            <motion.label
              htmlFor="confirmPassword"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              className="block text-gray-700 font-bold mb-2"
            >
              Retype Password
            </motion.label>
            <motion.input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              className="w-full border rounded-md py-2 px-3"
              placeholder="Retype your password"
              required
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
            />
          </div>
          <div className="mb-4">
            <motion.button
              type="submit"
              className="bg-blue-dark text-white py-2 px-4 rounded-md w-full hover:bg-blue-600 transition-colors duration-300"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
            >
              Sign Up
            </motion.button>
          </div>
        </form>
      </motion.div>
    </div>
  );
}

export default SignUpPage;
