import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { ReactFlow, ReactFlowProvider } from 'reactflow';

import { rfStyle, rfStyleDrawerOpen } from '../constants';
import ToolBar from './ToolBar';
import CustomNode from '../components/CustomNode';
import QuickActions from '../components/QuickActionButtons/QuickActions';
import EdgeDeleteModal from '../components/Modals/EdgeDeleteModal';

export const nodeTypes = { customNode: CustomNode };

function FlowBuilder(props) {
  const {
    nodes,
    edges,
    onNodesChange,
    onEdgesChange,
    onConnect,
    onInit,
    onDrop,
    onDragOver,
    proOptions,
    addNewNode,
    reactFlowWrapperRef,
    handleNodeClick,
    onNodeDragStop,
    onEdgeClick,
    visible,
    selectEdge,
    onDeleteEdge,
    handleCanvasOnClick,
    handlePaneClick,
    showDrawer,
    setVisible,
  } = props;

  const dispatch = useDispatch();

  // Use useMemo to memoize the ToolBar component
  const toolBarComponent = useMemo(() => <ToolBar addNewNode={addNewNode} />, [addNewNode]);

  return (
    <div className="container p-4 min-w-full">
      <div className="builder flex">
        <div className="flex-none w-[110px]">{toolBarComponent}</div>
        <ReactFlowProvider>
          <div className="builder-container flex-auto">
            <div className="reactflow-wrapper" ref={reactFlowWrapperRef}>
              <ReactFlow
                nodes={nodes}
                edges={edges}
                nodeTypes={nodeTypes}
                onNodeClick={handleNodeClick}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onEdgeClick={onEdgeClick}
                onConnect={onConnect}
                onInit={onInit}
                onDrop={onDrop}
                onDragOver={onDragOver}
                onNodeDragStop={onNodeDragStop}
                attributionPosition="top-right"
                proOptions={proOptions}
                style={showDrawer?.show ? rfStyleDrawerOpen : rfStyle}
                nodeDragThreshold={1}
                onClick={handleCanvasOnClick}
                onPaneClick={handlePaneClick}
                // fitView
              >
                <QuickActions />
                {visible && (
                  <EdgeDeleteModal
                    selectEdge={selectEdge}
                    onDeleteEdge={onDeleteEdge}
                    setVisible={setVisible}
                  />
                )}
              </ReactFlow>
            </div>
          </div>
        </ReactFlowProvider>
      </div>
    </div>
  );
}

FlowBuilder.propTypes = {
  nodes: PropTypes.array,
  addNewNode: PropTypes.func,
  onNodesChange: PropTypes.func,
  edges: PropTypes.array,
  onEdgesChange: PropTypes.func,
  reactFlowWrapperRef: PropTypes.any,
  onConnect: PropTypes.func,
  onInit: PropTypes.func,
  onDrop: PropTypes.func,
  onDragOver: PropTypes.func,
  proOptions: PropTypes.shape({
    hideAttribution: PropTypes.bool,
  }),
  handleNodeClick: PropTypes.func,
  onNodeDragStop: PropTypes.func,
  onEdgeClick: PropTypes.func,
  visible: PropTypes.bool,
  selectEdge: PropTypes.object,
  onDeleteEdge: PropTypes.func,
  handleCanvasOnClick: PropTypes.func,
  showDrawer: PropTypes.object,
  handlePaneClick: PropTypes.func,
  setVisible: PropTypes.func,
};

export default FlowBuilder;
