import React from 'react';
import PropTypes from 'prop-types';

function AngleBottom(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color = '#000' } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={13} width={13} viewBox="0 0 448 512" {...props}>
      <path
        fill={color}
        d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
      />
    </svg>
  );
}

AngleBottom.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default AngleBottom;
