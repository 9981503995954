/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { fetchConversationsCount, fetchConversationsList } from 'services/conversation.service';
import { apiErrorHandler } from 'api/handler';
import useSocketEvent from 'hooks/useSocketEvent';
import { SocketEvents } from 'enums/socket.enum';
import { __DEV__ } from 'utils/runtime';
import { constructDateAndTime } from 'views/Inbox/Chat/utils';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'slices';
import { takenTheChat } from 'slices/event';

function Tabs(props) {
  const {
    tabs,
    setConversationList,
    debouncedSearchBy,
    searchBy,
    triggerChild,
    setIsNewChat,
    selectedTab,
    setSelectedTab,
    setAllMessages,
    setUrlSearchParams,
    replaceUrlParamsHandler,
    setContactData,
    setLoadedConversationId,
    setCustomLoading,
    customLoading,
  } = props;
  const firstBtnRef = useRef();

  const { event } = useSocketEvent();

  const { chatTaken } = useSelector((state: RootState) => state.event);
  const dispatch = useDispatch();

  const [onInit, setOnInit] = useState(true);

  /** earlier fetchConversations triggers 3 times in a single useEffect
   * so it broken into separate useEffects based on conditions
   */
  useEffect(() => {
    if (!onInit) {
      fetchConversations();
    }
  }, [debouncedSearchBy]);

  useEffect(() => {
    if (chatTaken && selectedTab === 0) {
      fetchConversations();
      dispatch(takenTheChat());
    } else if (chatTaken && selectedTab !== 0) {
      setSelectedTab(0);
      dispatch(takenTheChat());
    }
  }, [chatTaken]);

  useEffect(() => {
    if (event?.type === SocketEvents.ChannelAssignedToConversation && !onInit) {
      fetchConversations();
    }
  }, [event?.type === SocketEvents.ChannelAssignedToConversation]);

  useEffect(() => {
    if ([0, 1, 2].includes(selectedTab)) {
      fetchConversations();
      setOnInit(false);
    }
  }, [selectedTab]);

  useEffect(() => {
    if (triggerChild.conversations) {
      fetchConversations();
    }
  }, [triggerChild]);

  /** useEffect does not applicable for here */
  useLayoutEffect(() => {
    fetchConversationCount();
  }, [
    selectedTab,
    triggerChild.conversations,
    event?.type === SocketEvents.ChannelAssignedToConversation,
    chatTaken,
  ]);

  async function fetchConversations() {
    try {
      let status = 'active';
      setCustomLoading((prevState) => ({ ...prevState, conversationsLoading: true }));

      if (selectedTab === 0) {
        status = 'active';
        setIsNewChat(false);
      } else if (selectedTab === 1) {
        status = 'new';
        setIsNewChat(true);
        setAllMessages([]); // when moved to a new tab, clear all messages
        replaceUrlParamsHandler({}); // when move to a new tab, clear url params in address bar
        setUrlSearchParams({}); // when move to a new tab, clear url params
        setContactData({}); // when move to a new tab, clear selected contact data
        setLoadedConversationId(null); // when move to a new tab, clear selected conversationId
      } else if (selectedTab === 2) {
        status = 'ended';
        setIsNewChat(false);
        setAllMessages([]);
        replaceUrlParamsHandler({});
        setUrlSearchParams({});
        setContactData({});
        setLoadedConversationId(null);
      }
      const { data } = await fetchConversationsList({ searchText: searchBy, status });
      if (data?.node.docs.length > 0) {
        const mappedData = data?.node.docs.map((obj) => ({
          ...obj,
          contactName:
            obj?.contact?.firstName || obj?.contact?.lastName
              ? `${obj?.contact?.firstName || ''} ${obj?.contact?.lastName || ''}`
              : 'Unsaved Contact',
          date: obj?.lastMessageAt ? constructDateAndTime(obj?.lastMessageAt) : '',
          channelName: obj?.channel?.channelName,
          unReadMessages: obj?.NumberOfUnreadMsg,
          lastText: obj?.lastMsg,
          _id: obj?._id,
        }));
        setConversationList(mappedData);
      } else {
        setConversationList([]);
      }
    } catch (e) {
      const { message: exception } = apiErrorHandler(e);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, conversationsLoading: false }));
    }
  }

  // getting the conversation counts for tabs
  async function fetchConversationCount() {
    try {
      const { data } = await fetchConversationsCount();
      if (data?.node.length > 0) {
        updateItemsWithStatusCounts(data?.node);
      }
    } catch (e) {
      const { message: exception } = apiErrorHandler(e);
      toast.error(exception);
    }
  }

  // Function to modify the count in the tabs array based on status
  function updateItemsWithStatusCounts(statusCounts) {
    for (const item of tabs) {
      const matchingStatus = statusCounts.find(
        (statusObj) => statusObj.status.toLowerCase() === item.title.toLowerCase(),
      );

      if (matchingStatus) {
        item.count = matchingStatus.count;
      } else {
        item.count = 0;
      }
    }
  }

  return (
    <div className="flex justify-center items-center">
      <div className="max-w-lg flex flex-col gap-y-2 w-full">
        <div className="flex flex-wrap font-semibold text-[#AAA] text-[11px] font-ptScanCap">
          {tabs.map((item, index) => (
            <button
              key={`k_${index}`}
              type="button"
              ref={index === 0 ? firstBtnRef : null}
              onClick={() => setSelectedTab(index)}
              disabled={customLoading.conversationsLoading}
              className={`flex items-center justify-center outline-none p-2 border border-chineseSilver rounded-tl-xl rounded-tr-xl text-center w-full  ${
                selectedTab === index ? 'bg-spaceCadetBlue text-white active_tab' : ''
              } `}
              style={{ flex: 1 }}
            >
              {item.icon}
              <span className="pl-1">
                {item.title}
                <span className="pl-1">({item.count})</span>
              </span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  setConversationList: PropTypes.func,
  debouncedSearchBy: PropTypes.any,
  searchBy: PropTypes.string,
  triggerChild: PropTypes.any,
  setIsNewChat: PropTypes.func,
  selectedTab: PropTypes.number,
  setSelectedTab: PropTypes.func,
  setAllMessages: PropTypes.func,
  setUrlSearchParams: PropTypes.func,
  replaceUrlParamsHandler: PropTypes.func,
  setContactData: PropTypes.func,
  setLoadedConversationId: PropTypes.func,
  setCustomLoading: PropTypes.func,
  customLoading: PropTypes.object,
};

export default Tabs;
