import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-simple-toasts';

import { InputText, PrimaryButton } from 'components';
import { DefaultIntents } from 'constants/intents';
import { updateIntent } from 'services/messenger.service';
import { AddIcon } from 'components/Icons';
import { ToastTheme } from 'enums/toast.enum';
import { apiErrorHandler } from 'api/handler';
import { addIntentSuggestionValidationSchema } from '../../validations/index';

/**
 * Intent Suggestion Component
 * @param props
 * @returns {JSX.Element} - A JSX element representing the Page
 */
function IntentSuggestion(props) {
  const { intentData, handleInputChange, setIntentData } = props;

  const [customLoading, setCustomLoading] = useState({
    formSubmit: false,
  });

  const { register, handleSubmit, formState, control } = useForm<any>({
    resolver: useMemo(() => yupResolver(addIntentSuggestionValidationSchema), [intentData]),
    values: useMemo(() => intentData, [customLoading.formSubmit]),
  });

  const { errors } = formState;

  // handle the checkbox
  const handleCheckChange = (event) => {
    const { id, checked } = event.target;
    setIntentData({
      ...intentData,
      [id]: checked,
    });
  };

  // Handling Update intent Suggestions
  const handleIntentSuggestion = async () => {
    const toastTask = toast('Updating intent suggestions ...', {
      loading: true,
    });

    const { intentName, intentSuggestion, showSuggestionInAiChat } = intentData;

    try {
      const payload = {
        intentName,
        intentSuggestion,
        showSuggestionInAiChat,
      };
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: true }));
      const { data } = await updateIntent(intentData?._id, payload);
      if (data) {
        if (data?.node) {
          setIntentData((prevState: any) => ({ ...prevState, data }));
          toastTask.update({
            message: 'Successfully Updated Intent Suggestion',
            duration: 5000,
            theme: ToastTheme.success,
            loading: false,
          });
        }
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toastTask.update({
        message: exception,
        duration: 5000,
        theme: 'failure',
        loading: false,
      });
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: false }));
    }
  };

  return (
    <div>
      <div className="p-8 bg-white drop-shadow rounded-xl mt-4 form_body">
        <div className="flex items-center justify-between">
          <span className="text-spaceCadetBlue font-semibold font-ptScanCap text-base srg:text-xl">
            Intent Suggestion
          </span>
        </div>
        <hr className="my-2" />
        <form onSubmit={handleSubmit(handleIntentSuggestion)} noValidate>
          <div className="grid grid-cols-2 xs:grid-cols-1 rg:grid-cols-2 lg:grid-cols-2 gap-4">
            <div className="mb-4">
              <div className="py-4 flex flex-row items-center gap-5">
                <input
                  id="showSuggestionInAiChat"
                  type="checkbox"
                  className="checkbox"
                  onChange={handleCheckChange}
                  checked={intentData?.showSuggestionInAiChat}
                  disabled={intentData?.intentId === DefaultIntents.FallbackIntent.intentId}
                />
                <span className="text-[16px] font-lato ">
                  Show This Suggestion In AI Based Chat
                </span>
                <br />
              </div>
              <div className="mt-4">
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <InputText
                      type="text"
                      elementId="intentSuggestion"
                      placeholder="eg: Transfer to Agent?"
                      register={register}
                      error={errors?.intentSuggestion}
                      value={intentData?.intentSuggestion}
                      onChange={(e: any) => {
                        field.onChange(e);
                        handleInputChange(e);
                      }}
                      disabled={!intentData?.showSuggestionInAiChat}
                    />
                  )}
                />
              </div>
            </div>
          </div>

          {intentData?.intentId !== DefaultIntents.FallbackIntent.intentId && (
            <div className="mt-2 mb-4">
              <PrimaryButton
                text="Update"
                btnColor="bg-spaceCadetBlue80"
                color="white"
                radius="2xl"
                textSize="base"
                btnHoverColor="hover:bg-spaceCadetBlue"
                icon={<AddIcon />}
                disabled={customLoading.formSubmit || !intentData?.intentSuggestion}
                loading={customLoading.formSubmit}
                handleOnClick={handleSubmit}
              />
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

IntentSuggestion.propTypes = {
  intentData: PropTypes.any,
  setIntentData: PropTypes.func,
  handleInputChange: PropTypes.func,
};

export default IntentSuggestion;
