import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Textarea } from 'react-daisyui';
import { useNavigate } from 'react-router-dom';

import { CustomSelect, PrimaryButton } from 'components';
import { Close, Confirm } from 'components/Icons';

function ReopenCaseModal(props) {
  const { show, toggleModal, handleUpdate, actionStatus = false, data } = props;

  const [formData, setFormData] = useState<any>({});

  // handle the input text change
  const handleInputChange = (event: { target: { id: any; value: any } }) => {
    const { id, value } = event.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  return (
    <Modal
      open={show}
      className="top-0 left-0 right-0 p-0 border border-gainsboroBlue rounded-lg z-50 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(auto-1rem)] max-w-3xl max-h-full"
    >
      <Modal.Header className="font-bold pb-2 mb-2 border-b p-4">
        <div className="flex justify-between items-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 w-fit flex-wrap items-center">
            <div className="text-base font-bold font-ptScanCap">
              Do you want to reopen the case?
            </div>
          </div>
          <div className="text-sm font-normal bg-red">
            <Button
              size="xs"
              shape="circle"
              className="btn btn-xs btn-circle border-2 bg-white border-rasinBlack font-bold"
              onClick={toggleModal}
            >
              x
            </Button>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div>
          {!actionStatus && (
            <div className="p-4 mb-5 grid grid-cols-1">
              <span className="text-sm font-normal font-lato pb-3 pl-1">Reason</span>
              <Textarea
                id="reason"
                className="w-full h-48 mt-1 text-sm font-lato"
                value={formData.reason}
                onChange={handleInputChange}
              />
            </div>
          )}
        </div>

        <div className="w-full pt-5 pb-5 mt-3 bg-culturedGray flex flex-wrap justify-center items-center gap-3">
          <PrimaryButton
            text="Yes"
            btnColor="bg-spaceCadetBlue80"
            btnHoverColor="hover:bg-spaceCadetBlue"
            color="white"
            icon={<Confirm />}
            width="[100px]"
            textSize="xs font-bold font-ptScanCap"
            radius="2xl"
            handleOnClick={() => handleUpdate(formData?.reason)}
          />
          <PrimaryButton
            text="No"
            btnColor="bg-begoniaRed"
            btnHoverColor="hover:bg-begoniaRed"
            color="white"
            icon={<Close />}
            width="[100px]"
            textSize="xs font-bold font-ptScanCap"
            radius="2xl"
            handleOnClick={toggleModal}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

ReopenCaseModal.propTypes = {
  show: PropTypes.bool,
  actionStatus: PropTypes.bool,
  toggleModal: PropTypes.func,
  handleUpdate: PropTypes.func,
  data: PropTypes.any,
};

export default ReopenCaseModal;
