import * as React from 'react';
import PropTypes from 'prop-types';

function Password(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color = '#fff', width = 24, height = 24 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 36 36"
    >
      <g
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        clipPath="url(#clip0_2433_2239)"
      >
        <path d="M7.5 19.5a3 3 0 013-3h15a3 3 0 013 3v9a3 3 0 01-3 3h-15a3 3 0 01-3-3v-9z" />
        <path d="M16.5 24a1.5 1.5 0 103 0 1.5 1.5 0 00-3 0zM12 16.5v-6a6 6 0 1112 0v6" />
      </g>
      <defs>
        <clipPath id="clip0_2433_2239">
          <path fill="#fff" d="M0 0H36V36H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
Password.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Password;
