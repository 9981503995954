import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { PATHS } from 'routes/paths';
import { IFormattedNote, INote } from 'views/Contact/ContactView/interface';
import {
  ReportUserIcon,
  UpdateCustomer,
  Template,
  ViewCase,
  EnvelopIcon,
  NewCase,
} from 'components/Icons';
import GeneralButton from 'components/Buttons/GeneralButton';
import SecondaryButton from 'components/Buttons/Secondary';

import AddNoteCard from '../../NoteCard/AddNoteCard';

function ContactCard(props: any) {
  const { contactDetails, isActionButtonsVisible = true, setVisible } = props;
  const navigate = useNavigate();

  // note data for drawer
  const [noteData, setNoteData] = useState<INote>();
  // note visibility
  const [isNoteVisible, setIsNoteVisible] = useState(false);

  /**
   * Handles the note drawer visibility and data
   * @param {INote} note - Note data from clicked row
   */
  const showNote = (e: any, note: IFormattedNote) => {
    setNoteData(note);
    setVisible(false);
    // toggle
    setIsNoteVisible((prevState) => !prevState);
  };
  // navigate to edit form
  const handleUpdateContact = () => {
    navigate(`${PATHS.CONTACT.FORM.url}?md=edit&i=${contactDetails?._id}`);
  };

  return (
    <div className="h-fit w-full">
      <div className="max-w-full mx-auto bg-white border rounded-lg drop-shadow-sm">
        <div className="w-full h-auto justify-end items-center flex pt-2 pr-3">
          <ReportUserIcon width={28} height={28} />
        </div>

        {/* profile picture */}
        <div className="w-full h-auto justify-center items-center flex">
          <div className="avatar placeholder">
            {contactDetails?.profilePicture?.location ? (
              <div className="w-24 rounded-full border border-primary">
                <img
                  src={contactDetails.profilePicture.location}
                  alt={`${contactDetails?.firstName || ''} ${contactDetails?.lastName || ''}`}
                />
              </div>
            ) : (
              <div className="bg-neutral-focus text-neutral-content rounded-full w-16">
                <span className="text-2xl">
                  {`${
                    contactDetails?.firstName?.charAt(0) ||
                    contactDetails?.lastName?.charAt(0) ||
                    ''
                  }`}
                </span>
              </div>
            )}
          </div>
        </div>

        <h2 className="text-center text-base text-rasinBlack font-ptScanCap font-semibold mt-2">
          {`${contactDetails?.firstName || ''} ${contactDetails?.lastName || ''}`}
        </h2>
        <p className="text-center text-xs font-medium text-quickSilver mt-1">
          {contactDetails?.primaryContactNo || ''}
        </p>
        <p className="text-center text-quickSilver text-xs font-normal">
          {contactDetails?.email || ''}
        </p>
        <div className="flex w-full justify-center items-center pt-3 pb-6">
          <GeneralButton
            text="Update Contact"
            btnColor="bg-spaceCadetBlue80"
            btnHoverColor="hover:bg-spaceCadetBlue"
            color="white"
            icon={<UpdateCustomer />}
            textSize="xs"
            radius="2xl"
            handleOnClick={handleUpdateContact}
          />
        </div>

        {/* Action buttons */}
        {isActionButtonsVisible && (
          <div className="previewBtns flex flex-wrap gap-4 h-auto p-3 pb-3 items-center justify-center bg-brightGray">
            <SecondaryButton
              text="New Case"
              btnColor="bg-aliceBlue"
              hoverTxtcolor="hover:text-white"
              btnHoverColor="hover:bg-spaceCadetBlue"
              icon={<NewCase />}
              btnSize="sm"
              textSize="xs"
              className="btns__icon-color"
              handleOnClick={() => navigate(`${PATHS.CASES_MANAGEMENT.FORM.url}?md=new`)}
            />
            <a href={`mailto:${contactDetails?.email}`}>
              <SecondaryButton
                text="Send Email"
                btnColor="bg-aliceBlue"
                hoverTxtcolor="hover:text-white"
                btnHoverColor="hover:bg-spaceCadetBlue"
                icon={<EnvelopIcon />}
                btnSize="sm"
                textSize="xs"
                className="btns__icon-color"
              />
            </a>
            <SecondaryButton
              text="Add Note"
              btnColor="bg-aliceBlue"
              hoverTxtcolor="hover:text-white"
              btnHoverColor="hover:bg-spaceCadetBlue"
              className="btns__icon-color"
              icon={<ViewCase />}
              btnSize="sm"
              textSize="xs"
              handleOnClick={(e) => showNote(e, { contactId: contactDetails?._id })}
            />
          </div>
        )}
      </div>

      <AddNoteCard
        isNoteVisible={isNoteVisible}
        setIsNoteVisible={setIsNoteVisible}
        noteId={noteData?._id}
        noteData={noteData}
        contactId={contactDetails?._id}
        showNote={showNote}
      />
    </div>
  );
}

ContactCard.propTypes = {
  contactDetails: PropTypes.any,
  isActionButtonsVisible: PropTypes.bool,
  setVisible: PropTypes.func,
};

export default ContactCard;
