/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import PropTypes from 'prop-types';
import { Pdf } from 'components/Icons';

interface AttachmentProps {
  message: string;
  attachment?: any;
  textHighlighter?: string;
}

function Attachment(props: AttachmentProps) {
  const { message, attachment, textHighlighter } = props;

  return (
    <div className="flex items-center gap-4 cursor-pointer">
      <Pdf />
      <a href={attachment.location} target="blank">
        <div className="no-underline hover:underline">
          <span className="font-medium">{attachment.originalname}</span>
          <div className="text-[#000000a3]">{`${Math.ceil(attachment.size / 1024)} KB`}</div>
        </div>
      </a>
    </div>
  );
}

Attachment.propTypes = {
  message: PropTypes.string,
  attachment: PropTypes.any,
  textHighlighter: PropTypes.string,
};

export default Attachment;
