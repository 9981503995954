export function mapListViewDocs(docs = []) {
  if (!Array.isArray(docs)) {
    return [];
  }

  const formattedDocs = docs.map((obj) => ({
    ...obj,
    channelId: obj?.sequenceId,
    creatorId: obj?.creatorId?.name ? obj?.creatorId?.name : '',
    status: obj?.isActive ? 'active' : 'disabled',
  }));

  return formattedDocs;
}
