import React from 'react';
import PropTypes from 'prop-types';

import { CustomSelect } from 'components';
import { selectCustomStylesForFilters } from 'styles/select';

function RoleCard(props) {
  const {
    moduleName,
    featureKeys,
    allPermissions = [],
    givenPermissions,
    handleSelectChange,
  } = props;

  return (
    <div className="role-permission__container mb-2">
      <div className="role-permission__card bg-white">
        <div className="flex">
          <div className="flex items-center justify-between w-full">
            <div className="">
              <p className="module-name">{moduleName}</p>
            </div>
          </div>
        </div>

        <hr className="mt-2" />

        {featureKeys.map((featureKey: { name: string; featureKey: string }, idx: number) => (
          <div
            key={`${featureKey.name}`}
            className="role__card grid grid-cols-2 xs:grid-cols-1 rg:grid-cols-2 lg:grid-cols-3 gap-4"
          >
            <div className="role-type flex items-center">{featureKey.name}</div>
            <div>
              <CustomSelect
                placeholder=""
                customStyles={selectCustomStylesForFilters}
                options={allPermissions[featureKey.featureKey]}
                value={givenPermissions[featureKey.featureKey]}
                isClearable
                isMulti
                onChange={(selectedData) => handleSelectChange(selectedData, featureKey.featureKey)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
RoleCard.propTypes = {
  moduleName: PropTypes.string,
  featureKeys: PropTypes.array,
  allPermissions: PropTypes.object,
  givenPermissions: PropTypes.object,
  handleSelectChange: PropTypes.func,
};

export default RoleCard;
