import React from 'react';
import PropTypes from 'prop-types';

import FirstBubble from 'components/ChatBubbles/FirstBubble';
import SecondBubbleOnwards from 'components/ChatBubbles/SecondBubbleOnwards';
import { S3_ASSETS } from 'constants/assets';

function SenderChatBubble(props) {
  const {
    text,
    date,
    consecutiveRenderCount,
    profilePic,
    user,
    attachment,
    textHighlighter,
    isSample = false,
  } = props;

  return (
    <div>
      {/* Sender */}
      <div className="flex">
        {consecutiveRenderCount === 1 ? (
          <div className="avatar">
            <div className="w-10 h-10 rounded-full">
              <img
                src={profilePic?.location || S3_ASSETS.CHAT_PREVIEW_PLACEHOLDER.url}
                alt="avatar"
              />
            </div>
          </div>
        ) : (
          <div className="avatar none">
            <div className="w-10 h-10 rounded-full" />
          </div>
        )}

        <div className={consecutiveRenderCount === 1 ? 'mt-0 ml-2' : 'ml-2 -mt-1 '}>
          {consecutiveRenderCount === 1 ? (
            <p className="text-sm text-raisnBlack font-lato font-semibold ml-1">
              {user?.name || 'Bot'}
              <span className="text-[12px] ml-2 text-independenceBlue">{date}</span>
            </p>
          ) : (
            <div />
          )}

          {consecutiveRenderCount === 1 ? (
            <FirstBubble
              message={text}
              isAgent
              isSender={false}
              attachment={attachment}
              textHighlighter={textHighlighter}
              isSample={isSample}
            />
          ) : (
            <SecondBubbleOnwards
              message={text}
              isAgent
              isSender={false}
              attachment={attachment}
              textHighlighter={textHighlighter}
              isSample={isSample}
            />
          )}
        </div>
      </div>
    </div>
  );
}

SenderChatBubble.propTypes = {
  text: PropTypes.string,
  date: PropTypes.string,
  consecutiveRenderCount: PropTypes.number,
  profilePic: PropTypes.object,
  user: PropTypes.object,
  attachment: PropTypes.object,
  textHighlighter: PropTypes.string,
  isSample: PropTypes.bool,
};

export default SenderChatBubble;
