import _ from 'lodash';

import { IURLQueryParams } from 'interfaces/query.interface';
import instance, { postAttachments } from 'api';
import { MESSENGER } from 'api/apiEndpoints';
import UrlBuilder from 'api/urlQueryBuilder';
import {
  IMessengerListURLQueryParams,
  IUnlinkedChannelsQueryParams,
} from 'views/Settings/Messengers/List/interface';

export const fetchMessengerListData = (params: IMessengerListURLQueryParams) => {
  const {
    page = 1,
    limit = 10,
    sort = 'createdAt',
    order = -1,
    lastUpdatedAt,
    status,
    searchBy,
    select,
  } = params;

  const queryParams = _.pickBy({
    page,
    limit,
    sort,
    order,
    lastUpdatedAt,
    status,
    searchBy,
    select,
  });
  const url = UrlBuilder()
    .setUrl(MESSENGER.GET_LIST)
    .setQueryParams({ ...queryParams })
    .build();
  return instance.get(url);
};

export function createMessengerInstance(data: any) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(MESSENGER.CREATE_MESSENGER).build();
  return instance.post(url, data);
}

export function updateMessengerInstance(id: string, data: any) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(MESSENGER.UPDATE_MESSENGER).setPathParams({ id }).build();
  return instance.patch(url, data);
}

export function updateMessengerInstanceSettings(id: string, data: any) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder
    .setUrl(MESSENGER.UPDATE_MESSENGER_INTENT_SETTINGS)
    .setPathParams({ id })
    .build();
  return instance.patch(url, data);
}

export function getMessengerInstanceById(id: string) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(MESSENGER.GET_DATA_BY_ID).setPathParams({ id }).build();
  return instance.get(url);
}

export function getInstanceBotLocaleStatus(id: string) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder
    .setUrl(MESSENGER.GET_INSTANCE_BOT_LOCALE_STATUS)
    .setPathParams({ id })
    .build();
  return instance.get(url);
}

export function toggleInstanceStatus(id: string, body: any) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(MESSENGER.TOGGLE_STATUS).setPathParams({ id }).build();
  return instance.post(url, body);
}

export const uploadBotLogo = (id: string, data: any) => {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(MESSENGER.UPLOAD_BOT_LOGO).setPathParams({ id }).build();
  return postAttachments(url, data);
};

export function getLinkedChannels(id: string) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(MESSENGER.GET_LINKED_CHANNELS).setPathParams({ id }).build();
  return instance.get(url);
}

export function linkChannelsToMessenger(id: string, data: any) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(MESSENGER.LINK_CHANNEL_TO_MESSENGER).setPathParams({ id }).build();
  return instance.post(url, data);
}

export function unlinkChannelFromMessenger(id: string, channelId: string) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder
    .setUrl(MESSENGER.UNLINK_CHANNEL_FROM_MESSENGER)
    .setPathParams({ id })
    .build();
  return instance.post(url, { channelId });
}

export const fetchUnlinkedChannelsList = (id: string, params: IUnlinkedChannelsQueryParams) => {
  const { page = 1, limit = 10, sort = 'createdAt', order = -1, searchBy, select } = params;
  const queryParams = _.pickBy({
    page,
    limit,
    sort,
    order,
    searchBy,
    select,
  });
  const url = UrlBuilder()
    .setUrl(MESSENGER.GET_UN_LINKED_CHANNELS)
    .setPathParams({ id })
    .setQueryParams({ ...queryParams })
    .build();
  return instance.get(url);
};

export function buildInstanceBot(id: string) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(MESSENGER.BUILD_BOT).setPathParams({ id }).build();
  return instance.post(url);
}

export function getIntentList(id: string, params) {
  const { sort = 'createdAt', order = -1, searchBy } = params;
  const queryParams = _.pickBy({
    sort,
    searchBy,
    order,
  });
  const urlBuilder = UrlBuilder();
  const url = urlBuilder
    .setUrl(MESSENGER.GET_MESSENGER_INTENTS)
    .setPathParams({ id })
    .setQueryParams({ ...queryParams })
    .build();
  return instance.get(url);
}

export function createIntent(data: any) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(MESSENGER.CREATE_MESSENGER_INTENT).build();
  return instance.post(url, data);
}

export function getIntentById(id: string) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(MESSENGER.GET_MESSENGER_INTENT_BY_ID).setPathParams({ id }).build();
  return instance.get(url);
}

export function updateIntent(id: string, data: any) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(MESSENGER.UPDATE_MESSENGER_INTENT).setPathParams({ id }).build();
  return instance.patch(url, data);
}

export function disableIntent(id: string, data: any) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(MESSENGER.DISABLE_MESSENGER_INTENT).setPathParams({ id }).build();
  return instance.patch(url, data);
}
export function deleteIntent(id: string) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(MESSENGER.DELETE_MESSENGER_INTENT).setPathParams({ id }).build();
  return instance.delete(url);
}

export function addIntentUtterance(id: string, data: any) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder
    .setUrl(MESSENGER.ADD_MESSENGER_INTENT_UTTERANCE)
    .setPathParams({ id })
    .build();
  return instance.patch(url, data);
}

export function updateIntentUtterance(id: string, data: any) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder
    .setUrl(MESSENGER.UPDATE_MESSENGER_INTENT_UTTERANCE)
    .setPathParams({ id })
    .build();
  return instance.patch(url, data);
}

export function deleteIntentUtterance(id: string, data: any) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder
    .setUrl(MESSENGER.DELETE_MESSENGER_INTENT_UTTERANCE)
    .setPathParams({ id })
    .build();
  return instance.patch(url, data);
}

export function getUtterancesByIntentId(id: string, params: IURLQueryParams) {
  const { page = 1, limit = 10, sort = 'createdAt', order = -1, searchBy } = params;
  // creates a new object with only the non-null, non-undefined values from the extracted variables
  const queryParams = _.pickBy({
    page,
    limit,
    sort,
    order,
    searchBy,
  });

  const urlBuilder = UrlBuilder();
  const url = urlBuilder
    .setUrl(MESSENGER.GET_MESSENGER_INTENT_UTTERANCES)
    .setPathParams({ id })
    .setQueryParams({ ...queryParams })
    .build();
  return instance.get(url);
}

export const uploadKnowledgeBaseFile = (id: string, data: any) => {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(MESSENGER.UPLOAD_KNOWLEDGE_BASE_FILE).setPathParams({ id }).build();
  return postAttachments(url, data);
};

export const getMessengerKnowledgeBases = (id: string, params: IMessengerListURLQueryParams) => {
  const { page = 1, limit = 10, sort = 'createdAt', order = -1 } = params;
  const queryParams = _.pickBy({
    page,
    limit,
    sort,
    order,
  });
  const url = UrlBuilder()
    .setUrl(MESSENGER.GET_KNOWLEDGE_BASES)
    .setPathParams({ id })
    .setQueryParams({ ...queryParams })
    .build();
  return instance.get(url);
};

export const deleteKnowledgeBase = (id: string) => {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(MESSENGER.DELETE_KNOWLEDGE_BASE).setPathParams({ id }).build();
  return instance.delete(url);
};
