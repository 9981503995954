/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-daisyui';

import { CustomSelect, PrimaryButton } from 'components';
import { Confirm } from 'components/Icons';
import { getValueForSelect } from 'utils/helpers';
import { getChannelList, getChannelUsersByConversationId } from 'services/channel.service';
import { apiErrorHandler } from 'api/handler';
import { toast } from 'react-toastify';
import { fetchUserListData } from 'services/user.service';
import { getClientId } from 'utils/auth';
import { forwardOptions } from './constants';

function ForwardMsgModal(props) {
  const { show, toggleModal, handleUpdate, data, customLoading } = props;

  const { loadedConversationId } = data;

  const [formData, setFormData] = useState<any>({ forwardTo: 'user' });

  const [channelUserOptions, setChannelUserOptions] = useState<any>([]);

  useEffect(() => {
    getForwardOptions();
  }, [formData?.forwardTo]);

  // handle the dropdown change
  const handleSelectChange = (event: any, id: string) => {
    setFormData({
      ...formData,
      [id]: event?.value,
    });
    if (id === 'forwardTo') {
      setChannelUserOptions([]);
    }
  };

  const getForwardOptions = async () => {
    try {
      if (formData?.forwardTo === 'user') {
        const { data: userData } = await fetchUserListData({
          isExceptCurrentUser: 1,
          clientId: getClientId(),
        });
        if (userData) {
          const mappedData = userData?.docs.map((obj) => ({
            label: obj?.name,
            value: obj?._id,
          }));
          setChannelUserOptions(mappedData);
        } else {
          setChannelUserOptions([]);
        }
      } else if (formData?.forwardTo === 'channel') {
        const { data: channelData } = await getChannelList({ limit: 0, isExceptCurrentChannel: 1 });
        if (channelData?.node) {
          const mappedData = channelData?.node.docs.map((obj) => ({
            label: obj?.channelName,
            value: obj?._id,
          }));
          setChannelUserOptions(mappedData);
        }
      } else {
        setChannelUserOptions([]);
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    }
  };

  return (
    <Modal
      open={show}
      className="top-0 left-0 right-0 p-0 border border-gainsboroBlue rounded-lg z-50 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(auto-1rem)] max-w-2xl max-h-full"
    >
      <Modal.Header className="font-bold pb-2 mb-2 p-4">
        <div className="flex justify-between items-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 w-fit flex-wrap items-center">
            <div className="text-base font-bold font-ptScanCap">Forward Message</div>
          </div>
          <div className="text-sm font-normal bg-red">
            <Button
              size="xs"
              shape="circle"
              className="btn btn-xs btn-circle border-2 bg-white border-rasinBlack font-bold"
              onClick={toggleModal}
            >
              x
            </Button>
          </div>
        </div>
        <hr className="mt-4" />
      </Modal.Header>
      <Modal.Body>
        <div className="px-4 -mt-4">
          <div className="p-4 grid grid-cols-1">
            <span className="text-sm font-normal font-lato py-3">Forward to</span>
            <CustomSelect
              id="forwardTo"
              required
              options={forwardOptions}
              isClearable
              value={getValueForSelect(formData?.forwardTo, forwardOptions)}
              onChange={(event) => handleSelectChange(event, 'forwardTo')}
            />
          </div>
          <div className="p-4 -mt-2 mb-5 grid grid-cols-1">
            <span className="text-sm font-normal font-lato py-3">
              {formData?.forwardTo === 'user' ? 'User' : 'Select a Channel'}
            </span>
            <CustomSelect
              id={formData?.forwardTo === 'user' ? 'user' : 'channel'}
              options={channelUserOptions}
              isClearable
              value={getValueForSelect(
                formData?.forwardTo === 'user' ? formData?.userId : formData?.channelId,
                channelUserOptions,
              )}
              onChange={(event) =>
                handleSelectChange(event, formData?.forwardTo === 'user' ? 'userId' : 'channelId')
              }
            />
          </div>
        </div>

        <div className="w-full pt-5 pb-5 mt-3 border-t bg-culturedGray flex flex-wrap justify-center items-center gap-3">
          <PrimaryButton
            text="Confirm"
            btnColor="bg-spaceCadetBlue80"
            btnHoverColor="hover:bg-spaceCadetBlue"
            color="white"
            icon={<Confirm />}
            textSize="base font-bold font-ptScanCap"
            radius="2xl"
            loading={customLoading?.forward}
            handleOnClick={() => handleUpdate(formData)}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

ForwardMsgModal.propTypes = {
  show: PropTypes.bool,
  toggleModal: PropTypes.func,
  handleUpdate: PropTypes.func,
  data: PropTypes.any,
  customLoading: PropTypes.object,
};

export default ForwardMsgModal;
