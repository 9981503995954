import React, { useEffect, useMemo } from 'react';
import { Edge, EdgeChange, Node, NodeChange } from 'reactflow';
import PropTypes from 'prop-types';

import FlowBuilderHeader from './containers/FlowBuilderHeader';
import FlowBuilder from './containers/FlowBuilder';
import FlowBuilderDrawer from './containers/FlowBuilderDrawer';
import { IShowDrawer, OnChange, TNodeType } from './interfaces';

interface IFlowBuilderPageProps {
  intentId: string;
  headerData: {
    intentName: string;
    updatedAt: string;
    updatedBy: string;
  };
  drawerKey: number;
  nodes: Node<any, string>[];
  addNewNode: (nodeType: TNodeType) => void;
  onNodesChange: OnChange<NodeChange>;
  edges: Edge<any>[];
  onEdgesChange: OnChange<EdgeChange>;
  onConnect: (params: any) => void;
  reactFlowWrapperRef: React.MutableRefObject<any>;
  onInit: (flowInstance: any) => void;
  onDrop: (event: any) => void;
  onDragOver: (event: any) => void;
  proOptions: {
    hideAttribution: boolean;
  };
  showDrawer: IShowDrawer;
  toggleDrawer: () => void;
  handleNodeClick: (e: React.MouseEvent, node: Node) => void;
  onNodeDragStop: (e: React.MouseEvent, node: Node) => void;
  onEdgeClick: (e: React.MouseEvent, edge: Edge) => void;
  visible: boolean;
  selectEdge: any;
  onDeleteEdge: (event: any) => void;
  handleCanvasOnClick: any;
  handlePaneClick: (event: any) => void;
  setVisible: any;
}

function FlowBuilderPage(props: IFlowBuilderPageProps) {
  const {
    nodes,
    addNewNode,
    onNodesChange,
    edges,
    onEdgesChange,
    onConnect,
    reactFlowWrapperRef,
    onInit,
    onDrop,
    onDragOver,
    proOptions,
    showDrawer,
    toggleDrawer,
    handleNodeClick,
    onNodeDragStop,
    intentId,
    headerData,
    drawerKey,
    onEdgeClick,
    visible,
    selectEdge,
    onDeleteEdge,
    handleCanvasOnClick,
    handlePaneClick,
    setVisible,
  } = props;

  // Scroll to the top of the page when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // flow builder header component
  const flowBuilderHeaderComponent = useMemo(
    () => <FlowBuilderHeader intentId={intentId} headerData={headerData} />,
    [headerData],
  );

  const flowBuilderProps = {
    nodes,
    addNewNode,
    onNodesChange,
    edges,
    onEdgesChange,
    onConnect,
    reactFlowWrapperRef,
    onInit,
    onDrop,
    onDragOver,
    proOptions,
    handleNodeClick,
    onNodeDragStop,
    onEdgeClick,
    visible,
    selectEdge,
    onDeleteEdge,
    handleCanvasOnClick,
    showDrawer,
    handlePaneClick,
    setVisible,
  };

  return (
    <div className="flow-builder flex flex-col relative">
      {flowBuilderHeaderComponent}

      <FlowBuilder {...flowBuilderProps} />

      <FlowBuilderDrawer
        showDrawer={showDrawer}
        toggleDrawer={toggleDrawer}
        drawerKey={drawerKey}
        headerData={headerData}
      />
    </div>
  );
}

FlowBuilderPage.propTypes = {
  botName: PropTypes.string,
  intentName: PropTypes.string,
  nodes: PropTypes.array,
  addNewNode: PropTypes.func,
  onNodesChange: PropTypes.func,
  edges: PropTypes.array,
  onEdgesChange: PropTypes.func,
  reactFlowWrapperRef: PropTypes.any,
  onConnect: PropTypes.func,
  onInit: PropTypes.func,
  onDrop: PropTypes.func,
  onDragOver: PropTypes.func,
  proOptions: PropTypes.shape({
    hideAttribution: PropTypes.bool,
  }),
  showDrawer: PropTypes.object,
  toggleDrawer: PropTypes.func,
  handleNodeClick: PropTypes.func,
  onNodeDragStop: PropTypes.func,
  intentId: PropTypes.string,
  headerData: PropTypes.object,
  drawerKey: PropTypes.number,
  onEdgeClick: PropTypes.func,
  visible: PropTypes.bool,
  selectEdge: PropTypes.object,
  onDeleteEdge: PropTypes.func,
  handleCanvasOnClick: PropTypes.func,
  handlePaneClick: PropTypes.func,
  setVisible: PropTypes.func,
};

export default FlowBuilderPage;
