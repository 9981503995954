/* eslint-disable implicit-arrow-linebreak */
import moment from 'moment';

// Define an enum for valid time units
enum TimeUnit {
  Years = 'years',
  Months = 'months',
  Days = 'days',
  Hours = 'hours',
  Minutes = 'minutes',
  Seconds = 'seconds',
}

export function getCurrentDateTime(date?: any) {
  return moment(date || new Date()).format('DD-MM-YYYY hh:mm');
}

export const moreThanOneHourAgo = (date: any) =>
  moment(date).isBefore(moment().subtract(1, 'hours'));
export const lessThanOneHourAgo = (date: any) =>
  moment(date).isAfter(moment().subtract(1, 'hours'));

export function formatDate(date: any, format: string) {
  return moment(date).format(format);
}

export function calculateDateDifference(date1: any, date2: any, unit: TimeUnit) {
  return moment(date1).diff(moment(date2), unit);
}

export function isInFuture(date: any) {
  return moment(date).isAfter(moment());
}

export function isInPast(date: any) {
  return moment(date).isBefore(moment());
}

export function areDatesEqual(date1: any, date2: any) {
  return moment(date1).isSame(moment(date2));
}

export function addTimeToDate(date: any, amount: number, unit: TimeUnit) {
  return moment(date).add(amount, unit);
}

export function subtractTimeFromDate(date: any, amount: number, unit: TimeUnit) {
  return moment(date).subtract(amount, unit);
}

export function isDateInRange(date: any, startDate: any, endDate: any) {
  return moment(date).isBetween(startDate, endDate);
}

/**
 * Format date time to human readable format
 * @param {string} dateTime - date time ISO string
 * @returns
 */
export const formatDateTime = (dateTime: string) => moment(dateTime).format('DD-MM-YYYY hh:mm A');
