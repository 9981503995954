/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-daisyui';
import toast from 'react-simple-toasts';
import _ from 'lodash';

import { CustomSelect, PrimaryButton } from 'components';
import { Save } from 'components/Icons';
import { getDefaultValueForSelect } from 'utils/helpers';
import { updateMessengerInstanceSettings } from 'services/messenger.service';
import { apiErrorHandler } from 'api/handler';
import { ToastTheme } from 'enums/toast.enum';
import { DefaultIntents } from 'constants/intents';

function IntentSettingsModal(props) {
  const { show, toggleModal, listData, instanceData, setInstanceData } = props;

  const [formData, setFormData] = useState<any>(instanceData);

  const [intentOptions, setIntentOptions] = useState([]);
  const [customLoading, setCustomLoading] = useState({ save: false });

  useEffect(() => {
    getIntentList();
  }, []);

  const getIntentList = () => {
    const { docs } = listData;
    const filterStrings = [DefaultIntents.FallbackIntent.displayIntentName];

    const filteredIntents = _.filter(
      docs,
      (val) => !_.some(filterStrings, (filterString) => val.intentName === filterString),
    );

    const intentValues = filteredIntents.map((val) => ({
      label: val.intentName,
      value: val._id,
    }));
    setIntentOptions(intentValues);
  };

  // handle the dropdown change
  const handleSelectChange = (event: { value: any; other: any }, id: string) => {
    setFormData({
      ...formData,
      [id]: event?.value || null,
    });
  };

  // handle the checkbox
  const handleCheckChange = (event) => {
    const { id, checked } = event.target;
    setFormData({
      ...formData,
      [id]: checked,
    });
  };

  const onSubmit = async () => {
    const toastTask = toast('Saving Settings', {
      loading: true,
    });
    try {
      if (formData.initiateFlowAuto && !formData.primaryIntentId) {
        toastTask.update({
          message: 'Select Primary Intent Id',
          duration: 5000,
          theme: 'warning',
          loading: false,
        });
        return;
      }
      setCustomLoading((prevState) => ({ ...prevState, save: true }));

      const { data } = await updateMessengerInstanceSettings(formData._id, formData);
      if (data?.node) {
        const { initiateFlowAuto, primaryIntentId, updatedAt } = data.node;
        const body = {
          initiateFlowAuto,
          primaryIntentId,
          updatedAt,
        };
        setFormData((prevState: any) => ({
          ...prevState,
          ...body,
        }));
        setInstanceData((prevState: any) => ({
          ...prevState,
          ...body,
        }));
        toastTask.update({
          message: 'Successfully Updated',
          duration: 5000,
          theme: ToastTheme.success,
          loading: false,
        });
      }
      toggleModal();
    } catch (e) {
      const { message: exception } = apiErrorHandler(e);
      toastTask.update({
        message: exception,
        duration: 5000,
        theme: ToastTheme.failure,
        loading: false,
      });
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, save: false }));
    }
  };

  return (
    <Modal
      open={show}
      className="top-0 left-0 right-0 p-0 border border-gainsboroBlue rounded-lg z-50 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(auto-1rem)] max-w-2xl max-h-full"
    >
      <Modal.Header className="font-bold pb-2 border-b p-4">
        <div className="flex justify-between items-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 w-fit flex-wrap items-center">
            <div className="text-base font-bold font-ptScanCap whitespace-nowrap">
              Intent Settings
            </div>
          </div>
          <div className="text-sm font-normal bg-red">
            <Button
              size="xs"
              shape="circle"
              className="btn btn-xs btn-circle border-2 bg-white border-rasinBlack font-bold"
              onClick={toggleModal}
            >
              x
            </Button>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="px-2 justify-center items-center pb-3 -mt-6">
          <div className="p-4 flex flex-row items-center gap-5">
            <input
              id="initiateFlowAuto"
              type="checkbox"
              className="checkbox"
              onChange={handleCheckChange}
              checked={formData.initiateFlowAuto}
            />
            <span className="text-[16px] font-semibold font-lato ">
              Initiate the Flow Automatically
            </span>
            <br />
          </div>
          <div className="px-4 flex flex-col gap-4">
            <span className="text-sm font-semibold font-lato">Primary Intent</span>
            <CustomSelect
              id="primaryIntentId"
              required
              isClearable
              placeholder="Search By Intent Name"
              options={intentOptions}
              value={getDefaultValueForSelect(
                formData?.primaryIntentId,
                intentOptions,
                'primaryIntentIdId',
              )}
              onChange={(event) => handleSelectChange(event, 'primaryIntentId')}
              isDisabled={!formData.initiateFlowAuto}
            />
          </div>
        </div>
        <div className="w-full pt-5 pb-5 mt-3 bg-culturedGray flex flex-wrap justify-center items-center gap-3">
          <PrimaryButton
            text="Save Settings"
            btnColor="bg-spaceCadetBlue80"
            btnHoverColor="hover:bg-spaceCadetBlue"
            color="white"
            icon={<Save />}
            radius="2xl"
            type="button"
            handleOnClick={onSubmit}
            disabled={customLoading.save}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

IntentSettingsModal.propTypes = {
  show: PropTypes.bool,
  toggleModal: PropTypes.func,
  setInstanceData: PropTypes.func,
  listData: PropTypes.any,
  instanceData: PropTypes.object,
};

export default IntentSettingsModal;
