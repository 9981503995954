import React from 'react';
import PropTypes from 'prop-types';
import { TYPE } from './constants';

function RatingCards(props) {
  const { title = 'AI Based Rating', rating, feedback, type } = props;

  const renderFeedback = () => {
    if (type === TYPE.CUSTOMER_RATING) {
      return (
        <ul className="list-disc">
          {feedback.map((item, index) => (
            <li key={item.id || index}>
              <p className="pl-5 pr-3 pt-2 text-[14px]">
                <strong>Question:</strong> {item?.question}
              </p>
              <p className="pl-5 pr-3 pb-2 text-[14px]">
                <strong>Answer:</strong> {item?.answer}
              </p>
            </li>
          ))}
        </ul>
      );
    }
    return <p className="pl-5 pr-3 pt-2 text-[13px]">{feedback}</p>;
  };

  return (
    <div className="h-auto w-full mt-3">
      <div className="bg-white border rounded-xl w-82 justify-between">
        <div className="flex h-auto p-4 items-center justify-between rounded-t-lg bg-brightGray gap-11">
          <span className="text-center text-xl text-spaceCadetBlue font-workSans font-bold">
            {title}
          </span>
          {(rating || rating === 0) && (
            <div className="flex h-14 w-14 cursor-pointer items-center text-white justify-center rounded-full bg-spaceCadetBlue border-[#D9DEE8] text-xl font-bold">
              {rating}
            </div>
          )}
        </div>
        <div className="w-full pt-3">
          <div className="w-full h-auto justify-start items-center overflow-hidden">
            <span className="h-auto pl-5 pr-3 font-bold text-lg text-spaceCadetBlue">Feedback</span>
            <div className="w-[600px] mb-11">{renderFeedback()}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

RatingCards.propTypes = {
  title: PropTypes.string,
  rating: PropTypes.number,
  feedback: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.object)]),
  type: PropTypes.oneOf(Object.values(TYPE)),
};

export default RatingCards;
