import * as React from 'react';
import PropTypes from 'prop-types';

function ArrowUp(props: { color: any; width: any; height: any }) {
  const { color = '#475574', width = 8, height = 16 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 10 16"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M1 5l4-4m0 0l4 4M5 1v14"
      />
    </svg>
  );
}
ArrowUp.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default ArrowUp;
