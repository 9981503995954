import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-daisyui';

import { InputText, PrimaryButton } from 'components';
import { Save } from 'components/Icons';

function ContactFormModal(props) {
  const { show, toggleModal, handleUpdate, setFormData, formData } = props;

  const [submitted, setSubmitted] = useState(false);

  // handle the checkbox
  const handleCheckChange = (event) => {
    const { id, checked } = event.target;
    setFormData({
      ...formData,
      contactInfoForm: {
        ...formData.contactInfoForm,
        [id]: checked,
      },
    });
  };

  const handleInputChange = (event: { target: { id: any; value: any } }) => {
    const { id, value } = event.target;
    setFormData({
      ...formData,
      contactInfoForm: {
        ...formData.contactInfoForm,
        [id]: value,
      },
    });
  };

  const onSubmit = () => {
    setSubmitted(true);

    if (formData.collectContactInfoAtStart && !formData.collectContactInfoVia) {
      return;
    }

    handleUpdate();
    setSubmitted(false);
    toggleModal();
  };

  return (
    <Modal
      open={show}
      className="top-0 left-0 right-0 p-0 border border-gainsboroBlue rounded-lg z-50 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(auto-1rem)] max-w-2xl max-h-full"
    >
      <Modal.Header className="font-bold pb-2 border-b p-4">
        <div className="flex justify-between items-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 w-fit flex-wrap items-center">
            <div className="text-base font-bold font-ptScanCap whitespace-nowrap">
              Contact Information Collection
            </div>
          </div>
          <div className="text-sm font-normal bg-red">
            <Button
              size="xs"
              shape="circle"
              className="btn btn-xs btn-circle border-2 bg-white border-rasinBlack font-bold"
              onClick={toggleModal}
            >
              x
            </Button>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="px-2 justify-center items-center">
          <div className="flex-row items-center mt-6 grid grid-cols-12 px-4">
            <input
              id="collectFirstName"
              type="checkbox"
              checked={formData.contactInfoForm.collectFirstName}
              className="checkbox col-span-1"
              onChange={handleCheckChange}
            />
            <span className="text-[16px] font-semibold font-lato col-span-3">First Name</span>
            <div className="col-span-8">
              <InputText
                type="text"
                elementId="firstName"
                placeholder="Question or the Field Name"
                value={formData?.contactInfoForm.firstName || ''}
                onChange={(e: any) => {
                  handleInputChange(e);
                }}
              />
            </div>
          </div>
          <hr className="my-6 border-gainsboroBlue" />

          <div className="flex-row items-center mt-6 grid grid-cols-12 px-4">
            <input
              id="collectLastName"
              type="checkbox"
              checked={formData.contactInfoForm.collectLastName}
              className="checkbox col-span-1"
              onChange={handleCheckChange}
            />
            <span className="text-[16px] font-semibold font-lato col-span-3">Last Name</span>
            <div className="col-span-8">
              <InputText
                type="text"
                elementId="lastName"
                placeholder="Question or the Field Name"
                value={formData?.contactInfoForm.lastName || ''}
                onChange={(e: any) => {
                  handleInputChange(e);
                }}
              />
            </div>
          </div>
          <hr className="my-6 border-gainsboroBlue" />

          <div className="flex-row items-center mt-6 grid grid-cols-12 px-4">
            <input
              id="collectPhoneNumber"
              type="checkbox"
              checked={formData.contactInfoForm.collectPhoneNumber}
              className="checkbox col-span-1"
              onChange={handleCheckChange}
            />
            <span className="text-[16px] font-semibold font-lato col-span-3">Phone Number</span>
            <div className="col-span-8">
              <InputText
                type="text"
                elementId="phoneNumber"
                placeholder="Question or the Field Name"
                value={formData?.contactInfoForm.phoneNumber || ''}
                onChange={(e: any) => {
                  handleInputChange(e);
                }}
              />
            </div>
          </div>
          <hr className="my-6 border-gainsboroBlue" />

          <div className="flex-row items-center mt-6 grid grid-cols-12 px-4">
            <input
              id="collectEmail"
              type="checkbox"
              checked={formData.contactInfoForm.collectEmail}
              className="checkbox col-span-1"
              onChange={handleCheckChange}
            />
            <span className="text-[16px] font-semibold font-lato col-span-3">Email Address</span>
            <div className="col-span-8">
              <InputText
                type="text"
                elementId="email"
                placeholder="Question or the Field Name"
                value={formData?.contactInfoForm.email || ''}
                onChange={(e: any) => {
                  handleInputChange(e);
                }}
              />
            </div>
          </div>
          <hr className="my-6 border-gainsboroBlue" />

          <div className="flex-row items-center mt-6 grid grid-cols-12 px-4">
            <input
              id="collectAddress"
              type="checkbox"
              checked={formData.contactInfoForm.collectAddress}
              className="checkbox col-span-1"
              onChange={handleCheckChange}
            />
            <span className="text-[16px] font-semibold font-lato col-span-3">Address</span>
            <div className="col-span-8">
              <InputText
                type="text"
                elementId="address"
                placeholder="Question or the Field Name"
                value={formData?.contactInfoForm.address || ''}
                onChange={(e: any) => {
                  handleInputChange(e);
                }}
              />
            </div>
          </div>
          <hr className="my-6 border-gainsboroBlue" />
        </div>

        <div className="w-full pt-5 pb-5 mt-3 bg-culturedGray flex flex-wrap justify-center items-center gap-3">
          <PrimaryButton
            text="Save"
            btnColor="bg-spaceCadetBlue80"
            btnHoverColor="hover:bg-spaceCadetBlue"
            color="white"
            icon={<Save />}
            radius="2xl"
            type="button"
            handleOnClick={onSubmit}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

ContactFormModal.propTypes = {
  show: PropTypes.bool,
  toggleModal: PropTypes.func,
  setFormData: PropTypes.func,
  handleUpdate: PropTypes.func,
  formData: PropTypes.object,
};

export default ContactFormModal;
