import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { AllIcon, AnyIcon } from 'components/Icons';
import Segment from './Segment';
import { ISegment } from '../interfaces';
import { SEGMENTATION_METHODS } from '../constants';

interface IConditionProps {
  conditionId: string;
  segmentationMethod: string;
  segments: ISegment[];
  handleUpdateCondition: (
    conditionId: string,
    updatedConditionData: { segmentationMethod?: string },
  ) => void;
  handleAddNewSegmentCriteria: (conditionId: string) => void;
  handleUpdateSegmentCriteria: (
    conditionId: string,
    segmentId: string,
    updatedSegmentData: Partial<ISegment>,
  ) => void;
  handleDeleteSegmentCriteria: (conditionId: string, segmentId: string) => void;
}

function Condition(props: IConditionProps) {
  const {
    conditionId,
    segmentationMethod,
    segments = [],
    handleUpdateCondition,
    handleAddNewSegmentCriteria,
    handleUpdateSegmentCriteria,
    handleDeleteSegmentCriteria,
  } = props;

  const haveMultipleSegments: boolean = segments?.length > 1;

  const handleSelectSegmentMethodChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newSegmentationMethod = event.target.value;
    handleUpdateCondition(conditionId, { segmentationMethod: newSegmentationMethod });
  };

  return (
    <div className="condition">
      {/* need to show only if the condition have multiple segments */}
      {haveMultipleSegments && (
        <div className="flex segment-criteria-heading justify-between">
          <div>
            Contacts match{' '}
            <select
              className="segment-method-dropdown"
              value={segmentationMethod}
              onChange={handleSelectSegmentMethodChange}
            >
              <option value="or">any</option>
              <option value="and">all</option>
            </select>{' '}
            of the following criteria:
          </div>

          {segmentationMethod === SEGMENTATION_METHODS.ANY ? <AnyIcon /> : <AllIcon />}
        </div>
      )}

      {/* renders segments */}
      {segments?.map((segment: ISegment) => {
        const { _id, criteria, condition, textPhrase } = segment;
        return (
          <Segment
            key={`segment_${_id}`}
            conditionId={conditionId}
            segmentId={_id}
            criteria={criteria}
            condition={condition}
            textPhrase={textPhrase}
            handleUpdateSegmentCriteria={handleUpdateSegmentCriteria}
            handleDeleteSegmentCriteria={handleDeleteSegmentCriteria}
          />
        );
      })}

      <button
        type="button"
        className="add-item__link"
        onClick={() => handleAddNewSegmentCriteria(conditionId)}
      >
        Add the list segmentation criteria
      </button>
    </div>
  );
}

Condition.propTypes = {
  conditionId: PropTypes.string.isRequired,
  segmentationMethod: PropTypes.string,
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      criteria: PropTypes.string,
      condition: PropTypes.string,
      textPhrase: PropTypes.string,
    }),
  ),
  handleUpdateCondition: PropTypes.func,
  handleAddNewSegmentCriteria: PropTypes.func,
  handleUpdateSegmentCriteria: PropTypes.func,
  handleDeleteSegmentCriteria: PropTypes.func,
};

export default Condition;
