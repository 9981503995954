/* eslint-disable linebreak-style */
import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { apiErrorHandler } from 'api/handler';
import { Confirm, Hide, Show } from 'components/Icons';
import Password from 'components/Icons/Password';
import { updatePasswordService } from 'services/profile.service';

function UpdatePassword() {
  const [formData, setFormData] = useState({
    currentPassword: '',
    newPassword: '',
    passwordConfirmation: '',
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);

  const [alertMessage, setAlertMessage] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    // Hide the alert when the user updates any property
    setAlertMessage(null);
  };

  const togglePasswordVisibility = (field: string) => {
    if (field === 'currentPassword') {
      setShowCurrentPassword(!showCurrentPassword);
    } else if (field === 'confirmPassword') {
      setShowConfirmPassword(!showConfirmPassword);
    } else if (field === 'password') {
      setShowPassword(!showPassword);
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      if (formData.passwordConfirmation !== formData.newPassword) {
        setAlertMessage('Passwords do not match');
        return;
      }
      setSubmitLoading(true);
      const { data } = await updatePasswordService(formData);
      if (data?.status) {
        toast.success('Password successfully updated');
        setFormData({
          currentPassword: '',
          newPassword: '',
          passwordConfirmation: '',
        });
      }
    } catch (err) {
      const { message } = apiErrorHandler(err);
      if (message) {
        setAlertMessage(message);
      }
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <div className="grid grid-cols-4 rg:grid-cols-2 justify-between h-full items-center font-lato">
      {/* <div className="left_side-component hidden rg:block">
        <p>{}</p>
      </div> */}

      <form onSubmit={handleSubmit}>
        <div className="mt-auto mb-4">
          <div className="relative">
            <span className="absolute inset-y-0 left-0 flex items-center pl-2 mt-1">
              <svg className="h-5 w-5 mb-2 fill-slate-300" viewBox="0 0 21 22">
                <Password color="#192A51" />
              </svg>
            </span>
            <input
              type={showCurrentPassword ? 'text' : 'password'}
              id="currentPassword"
              name="currentPassword"
              value={formData.currentPassword}
              onChange={handleChange}
              className="placeholder:text-independenceBlue border rounded-xl py-3 pl-9 pr-3 focus:outline-none w-full"
              placeholder="Current Password"
              required
            />
            <button
              type="button"
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-blue-dark"
              onClick={() => togglePasswordVisibility('currentPassword')}
            >
              {showCurrentPassword ? <Show /> : <Hide />}
            </button>
          </div>
        </div>
        <div className="my-4">
          <div className="relative">
            <span className="absolute inset-y-0 left-0 flex items-center pl-2 mt-1">
              <svg className="h-5 w-5 mb-2 fill-slate-300" viewBox="0 0 21 22">
                <Password color="#192A51" />
              </svg>
            </span>
            <input
              type={showPassword ? 'text' : 'password'}
              id="newPassword"
              name="newPassword"
              value={formData.newPassword}
              onChange={handleChange}
              className="placeholder:text-independenceBlue border rounded-xl py-3 pl-9 pr-3 focus:outline-none w-full"
              placeholder="New Password"
              required
            />
            <button
              type="button"
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-blue-dark"
              onClick={() => togglePasswordVisibility('password')}
            >
              {showPassword ? <Show /> : <Hide />}
            </button>
          </div>
        </div>
        <div className="my-4">
          <div className="relative">
            <span className="absolute inset-y-0 left-0 flex items-center pl-2 mt-1">
              <svg className="h-5 w-5 mb-2 fill-slate-300" viewBox="0 0 21 22">
                <Password color="#192A51" />
              </svg>
            </span>
            <input
              type={showConfirmPassword ? 'text' : 'password'}
              id="passwordConfirmation"
              name="passwordConfirmation"
              value={formData.passwordConfirmation}
              onChange={handleChange}
              className="placeholder:text-independenceBlue border rounded-xl py-3 pl-9 pr-3 focus:outline-none w-full"
              placeholder="Retype Password"
              required
            />
            <button
              type="button"
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-blue-dark"
              onClick={() => togglePasswordVisibility('confirmPassword')}
            >
              {showConfirmPassword ? <Show /> : <Hide />}
            </button>
          </div>
        </div>
        {alertMessage && <div className="w-full text-red-500 text-center mb-3">{alertMessage}</div>}
        <div className="mb-4">
          <button
            type="submit"
            className={`bg-blue-dark flex justify-center items-center text-white py-3 px-4 rounded-2xl hover:opacity-70 transition-all duration-300 ${
              submitLoading ? 'cursor-not-allowed opacity-70' : ''
            }`}
            disabled={submitLoading}
          >
            {submitLoading ? (
              <div className="flex items-center justify-center">
                <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white mr-2" />
                Loading...
              </div>
            ) : (
              <>
                <Confirm />
                <p className="pl-2 text-sm font-semibold">Update Password</p>
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  );
}

export default UpdatePassword;
