import _ from 'lodash';

import instance from 'api';
import { CHANNELS } from 'api/apiEndpoints';
import UrlBuilder from 'api/urlQueryBuilder';
import { IContactViewURLQueryParams } from 'views/Contact/ContactView/interface';

export function getChannelList(params: IContactViewURLQueryParams | any) {
  const {
    page = 1,
    limit = 10,
    sort = 'createdAt',
    order = -1,
    status,
    searchBy,
    select,
    caseId,
    subject,
    noteId,
    description,
    isExceptCurrentChannel,
  } = params;

  // creates a new object with only the non-null, non-undefined values from the extracted variables
  const queryParams = _.pickBy({
    page,
    limit,
    sort,
    order,
    status,
    searchBy,
    select,
    caseId,
    subject,
    noteId,
    description,
    isExceptCurrentChannel,
  });
  const urlBuilder = UrlBuilder();
  const url = urlBuilder
    .setUrl(CHANNELS.GET_CHANNELS_LIST)
    .setQueryParams({ ...queryParams })
    .build();
  return instance.get(url);
}

export function getChannelUsersByConversationId(id: string) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(CHANNELS.GET_CHANNEL_USERS).setPathParams({ id }).build();
  return instance.get(url);
}

export function getChannelDataById(id: string) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(CHANNELS.GET_CHANNELS).setPathParams({ id }).build();
  return instance.get(url);
}

export function createChannel(data: any) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(CHANNELS.CREATE_CHANNEL).build();
  return instance.post(url, data);
}

export async function updateChannel(id: string, data: any) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(CHANNELS.UPDATE_CHANNEL).setPathParams({ id }).build();
  return instance.patch(url, data);
}
