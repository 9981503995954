import * as React from 'react';
import PropTypes from 'prop-types';

function Complete(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color, width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <g
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        clipPath="url(#clip0_695_223)"
      >
        <path d="M8 9h8M8 13h6M10.99 19.206L8 21v-3H6a3 3 0 01-3-3V7a3 3 0 013-3h12a3 3 0 013 3v6M15 19l2 2 4-4" />
      </g>
      <defs>
        <clipPath id="clip0_695_223">
          <path fill="#fff" d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
Complete.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Complete;
