/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import ReactDom from 'react-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Drawer } from 'react-daisyui';
import { toast } from 'react-toastify';
import { isUndefined, omitBy } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Close, ViewContact } from 'components/Icons';
import { PrimaryButton } from 'components';
import TakeChatIcon from 'components/Icons/TakeChatIcon';
import { fetchMessageList } from 'services/message.service';
import { apiErrorHandler } from 'api/handler';
import { PATHS } from 'routes/paths';
import { S3_ASSETS } from 'constants/assets';
import { useSocket } from 'contexts/socket';
import { SOCKET_EVENTS } from 'constants/events';
import {
  INewBotMessageSocketEvent,
  INewCustomerMessageSocketEvent,
} from 'interfaces/sockets.interface';
import { RootState } from 'slices';

import { constructDateAndTime } from 'views/Inbox/Chat/utils';
import Conversation from './Conversation';

function ChatPreview(props) {
  const { visible, toggleHide, conversationData, handleTakeChat } = props;
  const { socketConnected } = useSelector((state: RootState) => state.auth);

  const _isMounted = useRef(true);
  const socketClient = useSocket();
  const navigate = useNavigate();

  const [messageList, setMessageList] = useState([]);
  const [customLoading, setCustomLoading] = useState({ loadMessages: false });

  // State to store URL search parameters
  const [urlSearchParams, setUrlSearchParams] = useState(null);

  // Function to replace URL parameters and navigate with the updated query
  const replaceUrlParamsHandler = (params) => {
    // Omit undefined parameters using lodash's omitBy function
    const queryParams = omitBy(
      {
        page: params?.page || '1',
        limit: params.limit,
        contact: params.contact,
        order: params.order,
        searchBy: params.searchBy,
        country: params.country,
        orderBy: params.orderBy,
        i: params.i,
      },
      isUndefined,
    );

    const searchParams = new URLSearchParams(queryParams);
    setUrlSearchParams(queryParams);
    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  useEffect(
    () => () => {
      _isMounted.current = false;
    },
    [],
  );

  useEffect(() => {
    if (conversationData?._id) {
      fetchMessageData();
    }
  }, [conversationData]);

  useEffect(() => {
    const sharedObject = {
      node: {
        mount: true,
        conversationId: conversationData?._id || null, // Use null or a default value
      },
    };

    if (socketClient && sharedObject.node.conversationId && socketConnected) {
      socketClient.emit(SOCKET_EVENTS.CLIENT.WATCH_CHAT_PREVIEW, sharedObject);
    }

    return () => {
      // Modify the mount property for unmounting
      sharedObject.node.mount = false;

      if (socketClient && sharedObject.node.conversationId) {
        socketClient.emit(SOCKET_EVENTS.CLIENT.WATCH_CHAT_PREVIEW, sharedObject);
      }
    };
  }, [socketClient, conversationData?._id, socketConnected]);

  useEffect(() => {
    const handleNewBotMessage = (res: INewBotMessageSocketEvent) => {
      if (_isMounted.current) {
        const { message } = res.node;
        if (conversationData?._id === message?.conversationId) {
          pushMessageToArray(message);
        }
      }
    };

    socketClient?.on(SOCKET_EVENTS.SERVER.NEW_BOT_MESSAGE, handleNewBotMessage);

    return () => {
      // Unsubscribe from the socket event when the component unmounts
      socketClient?.off(SOCKET_EVENTS.SERVER.NEW_BOT_MESSAGE, handleNewBotMessage);
    };
  }, [socketClient, conversationData]);

  useEffect(() => {
    const handleNewCustomerMessage = (res: INewCustomerMessageSocketEvent) => {
      if (_isMounted.current) {
        const { message } = res.node;
        if (conversationData?._id === message?.conversationId) {
          pushMessageToArray(message);
        }
      }
    };

    socketClient?.on(SOCKET_EVENTS.SERVER.NEW_CUSTOMER_MESSAGE, handleNewCustomerMessage);

    return () => {
      // Unsubscribe from the socket event when the component unmounts
      socketClient?.off(SOCKET_EVENTS.SERVER.NEW_CUSTOMER_MESSAGE, handleNewCustomerMessage);
    };
  }, [socketClient, conversationData]);

  function pushMessageToArray(message: any) {
    const formattedMessage = {
      ...message,
      contactName:
        message?.contact?.firstName || message?.contact?.lastName
          ? `${message?.contact?.firstName || ''} ${message?.contact?.lastName || ''}`
          : 'Customer',
      date: moment(message?.createdAt).format('h:mm A'),
    };
    setMessageList((prevState) => [...prevState, formattedMessage]);
  }

  const fetchMessageData = async () => {
    try {
      setCustomLoading((prevState) => ({ ...prevState, loadMessages: true }));
      const { data } = await fetchMessageList(conversationData?._id, { limit: 1000 });
      if (data?.node?.docs.length > 0) {
        const mappedData = data?.node.docs.map((obj: any) => ({
          ...obj,
          contactName:
            obj?.contact?.firstName || obj?.contact?.lastName
              ? `${obj?.contact?.firstName || ''} ${obj?.contact?.lastName || ''}`
              : 'Customer',
          date: constructDateAndTime(obj?.createdAt),
        }));
        setMessageList(mappedData);
      }
    } catch (error) {
      toggleHide();
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
      replaceUrlParamsHandler({ page: 1, limit: 10 });
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, loadMessages: false }));
    }
  };

  return ReactDom.createPortal(
    <Drawer
      end
      open={visible}
      onClickOverlay={toggleHide}
      side={
        <div className="bg-[#F9F9F9] min-h-screen w-auto justify-center chat_preview max-h-full">
          <div className="w-full p-[15px] bg-primary preview__header">
            <div className="flex flex-wrap justify-between items-center">
              <span className="pt-1 pb-1 font-ptScanCap text-base font-semibold text-white">
                Chat Preview {conversationData?.sequenceId}
              </span>
              <div className="cursor-pointer">
                <Close width={26} height={26} onClick={toggleHide} />
              </div>
            </div>
            <span className="font-ptScanCap text-xs font-light text-white">
              Started At:{' '}
              {moment(conversationData?.createdAt, 'YYYY-MM-DD HH:mm:ss').format(
                'YYYY-MM-DD - hh:mm:ss A',
              )}
            </span>
          </div>
          <div className="preview__body">
            <div className="flex w-auto bg-white justify-between items-center preview_contact__header">
              <div className="contact_class flex items-center pl-[20px] py-[15px]">
                <div className="avatar">
                  <div className="w-12 h-12 rounded-full ring-offset-base-100">
                    <img
                      src={
                        conversationData?.contact?.profilePicture?.location ||
                        S3_ASSETS.CHAT_PREVIEW_PLACEHOLDER.url
                      }
                      alt="avatar"
                    />
                  </div>
                </div>
                <div className="pl-[21px] justify-center items-center">
                  {conversationData?.contact ? (
                    <div>
                      <span className="font-ptScanCap text-rasinBlack font-bold text-sm">
                        {`${conversationData?.contact?.firstName || ''} ${
                          conversationData?.contact?.lastName || ''
                        }`}
                      </span>
                    </div>
                  ) : (
                    <div>
                      <span className="font-ptScanCap text-rasinBlack font-bold text-sm">
                        Unsaved Contact
                      </span>
                    </div>
                  )}
                  <div className="-mt-1">
                    <span className="font-ptScanCap text-quickSilver font-bold text-sm">
                      {conversationData?.contact?.primaryContactNo}
                    </span>
                  </div>
                  <div className="-mt-1">
                    <span className="font-ptScanCap text-quickSilver font-light text-xs">
                      {conversationData?.contact?.email}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex items-center p-3">
                {conversationData?.contact && (
                  <PrimaryButton
                    text="View Contact"
                    btnColor="bg-spaceCadetBlue80"
                    btnHoverColor="hover:bg-spaceCadetBlue"
                    color="white"
                    icon={<ViewContact />}
                    width="90px"
                    textSize="xs font-medium font-ptScanCap"
                    radius="2xl"
                    handleOnClick={() =>
                      navigate(`${PATHS.CONTACT.VIEW.url}?i=${conversationData?.contact?._id}`)
                    }
                  />
                )}
              </div>
            </div>
            <div className="h-[calc(100vh-300px)] preview__message_body">
              <div className="col-span-1 bg-[#F9F9F9] md:col-span-1 px-4">
                <div className="bg-white rounded-[10px] shadow-md">
                  {/* Messages component */}
                  <Conversation
                    chatList={messageList}
                    messagesLoading={customLoading.loadMessages}
                  />
                  <div className="m-0 bg-lotionLightGray border flex justify-center rounded-bl-[10px] rounded-br-[10px]">
                    <div className="py-[21px]">
                      {/* Chat input-like component */}
                      {handleTakeChat && (
                        <PrimaryButton
                          text="Take Chat"
                          btnColor="bg-spaceCadetBlue80"
                          btnHoverColor="hover:bg-spaceCadetBlue"
                          color="white"
                          icon={<TakeChatIcon />}
                          width="90px"
                          textSize="xs font-medium font-ptScanCap"
                          radius="2xl"
                          handleOnClick={(event) => {
                            toggleHide();
                            handleTakeChat(event, conversationData);
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    />,
    document.body,
  );
}

ChatPreview.propTypes = {
  visible: PropTypes.bool,
  toggleShow: PropTypes.func,
  toggleHide: PropTypes.func,
  handleTakeChat: PropTypes.func,
  conversationData: PropTypes.object,
};

export default ChatPreview;
