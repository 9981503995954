/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';

import { FileUploadModal, PrimaryButton, StatusLabel } from 'components';
import { Back, ImagePlaceholder } from 'components/Icons';
import { PATHS } from 'routes/paths';
import { apiErrorHandler } from 'api/handler';
import { uploadCompanyLogo } from 'services/client.service';
import { formatDateTime } from 'utils/dates';

function FormEditHeader(props) {
  const { formData } = props;

  const [companyLogoUrl, setCompanyLogoUrl] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const [fileUploadModalOptions, setFileUploadModalOptions] = useState({ show: false });
  const [customLoading, setCustomLoading] = useState({
    fileUpload: false,
  });

  useEffect(() => {
    if (formData?.attachments?.companyLogo) {
      const { location } = formData.attachments.companyLogo;
      setCompanyLogoUrl(location);
    }
  }, [formData?.attachments]);

  async function uploadLogo(file: any) {
    try {
      setCustomLoading((prevState) => ({ ...prevState, fileUpload: true }));

      // Create FormData and append the selected file
      const payload = new FormData();
      payload.append('file', file);
      const { data } = await uploadCompanyLogo(formData._id, payload);
      if (data?.node) {
        const { attachment } = data.node;
        const { location } = attachment;
        setCompanyLogoUrl(location);
        toast.success('Client profile photo has been Updated');
      }
    } catch (e) {
      const { message: exception } = apiErrorHandler(e);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, fileUpload: false }));
    }
  }

  function handleOnClickFileUpload() {
    setFileUploadModalOptions({ ...fileUploadModalOptions, show: true });
  }

  const handleCloseFileUploadModal = () => {
    setFileUploadModalOptions({ ...fileUploadModalOptions, show: false });
  };

  // Function to handle image file selection
  const handleOnFileSelected = (file) => {
    if (file) {
      // You can perform additional checks/validation on the selected image here
      setSelectedImage(file);
      uploadLogo(file);
    }
    handleCloseFileUploadModal();
  };

  return (
    <>
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center">
          {companyLogoUrl || selectedImage ? (
            <img
              className="w-24 h-24 rounded-full p-2 mr-5 cursor-pointer"
              src={selectedImage ? URL.createObjectURL(selectedImage) : companyLogoUrl}
              alt="CL"
              onClick={handleOnClickFileUpload}
            />
          ) : (
            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={handleOnClickFileUpload}
              aria-disabled={customLoading.fileUpload}
              className="w-20 h-20 rounded-full p-2 mr-5 bg-brightGray flex justify-center items-center cursor-pointer"
            >
              <ImagePlaceholder width={40} height={40} />
            </motion.div>
          )}

          <div className="client-account__details items-center">
            <div className="flex flex-row gap-2 items-center pb-2">
              <h2 className="text-spaceCadetBlue font-bold font-lato text-lg srg:text-xl">
                View Client
              </h2>
              <div>
                {formData.status && (
                  <StatusLabel type={formData?.status.toLowerCase()} label={formData?.status} />
                )}
              </div>
            </div>
            <div className="flex flex-wrap gap-x-4 items-center">
              <p className="text-spaceCadetBlue font-normal font-lato text-base rg:text-lg">
                Client ID : {formData?.sequenceId}
              </p>
              <p className="text-spaceCadetBlue font-normal font-lato text-base rg:text-lg">
                {formData?.companyName}
              </p>

              {formData.createdAt && (
                <div className="text-rasinBlack font-normal font-lato text-sm rg:text-base">
                  Created At: {formatDateTime(formData?.createdAt)}
                </div>
              )}
              {formData.updatedAt && (
                <div className="text-rasinBlack font-normal font-lato text-sm rg:text-base">
                  Updated At: {formatDateTime(formData?.updatedAt)}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <FileUploadModal
        show={fileUploadModalOptions.show}
        onClose={handleCloseFileUploadModal}
        onFileSelected={handleOnFileSelected}
        type="image"
        title="Upload Client profile photo"
      />
    </>
  );
}

FormEditHeader.propTypes = {
  formData: PropTypes.any,
};

export default FormEditHeader;
