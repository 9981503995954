import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AddButtonPopUp from './AddingButtonsPopUp';

function MessageButton(props) {
  const { id, buttonText, buttonUrl, setEditPopupVisible, type, componentData, setSettingsArr } =
    props;
  const [isPopupVisible, setPopupVisible] = useState(false);

  const handleAddButtonClick = () => {
    setPopupVisible(!isPopupVisible);
    setEditPopupVisible((prev) => !prev);
  };

  return (
    <div className="relative">
      <button
        type="button"
        className="group relative border-gray-300 hover:bg-[#F2F2F3] border-2 text-left p-3 w-full text-[#0b99b2] text-sm -mt-[1px]"
        onClick={handleAddButtonClick}
      >
        <span>{buttonText}</span>
        <br />
        {buttonUrl && <span className="text-black mt-1">{buttonUrl}</span>}
        <span className="absolute text-gray-400 right-3 top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
          edit
        </span>
      </button>

      {/* Popup component */}
      {isPopupVisible && (
        <AddButtonPopUp
          setPopupVisible={setPopupVisible}
          setEditPopupVisible={setEditPopupVisible}
          componentData={componentData}
          buttonId={id}
          buttonText={buttonText}
          buttonUrl={buttonUrl}
          type={type}
          setSettingsArr={setSettingsArr}
          editMode
        />
      )}
    </div>
  );
}

MessageButton.propTypes = {
  id: PropTypes.any,
  buttonText: PropTypes.string,
  buttonUrl: PropTypes.string,
  type: PropTypes.string,
  setEditPopupVisible: PropTypes.func,
  componentData: PropTypes.object,
  setSettingsArr: PropTypes.func,
};

export default MessageButton;
