import React, { useState } from 'react';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import AddQuickRepliesModal from './AddQuickRepliesModal';
import TextIcon from './tempIcons/Text';
import DelayIcon from './tempIcons/Delay';
import ImageIcon from './tempIcons/Image';
import FileIcon from './tempIcons/File';

function AddAndQuickReplies(props) {
  const {
    quickReplies,
    setSettingsArr,
    setQuickReplies,
    expand,
    showModal,
    setUpdatedQuickReply,
    selectedQuickReplyId,
    isQuickReplyEditMode,
    setIsQuickReplyEditMode,
    setShowModal,
    setShowSubscriberQuickReplyModal,
  } = props;

  const [showAddMenu, setShowAddMenu] = useState(false);
  const [showQuickRepliesModal, setShowQuickRepliesModal] = useState(false);

  const toggleRepliesModal = (e) => {
    // Check if the click target is the button itself
    if (e.target === e.currentTarget) {
      setShowQuickRepliesModal(!showQuickRepliesModal);
    }
  };

  const addElements = (component) => {
    switch (component) {
      case 'text':
        setSettingsArr((prev) => [
          ...prev,
          {
            id: uniqueId(),
            position: prev.length + 1,
            name: 'message_text',
            text: '',
            buttons: [],
          },
        ]);

        break;
      case 'image':
        setSettingsArr((prev) => [
          ...prev,
          {
            id: uniqueId(),
            position: prev.length + 1,
            name: 'image_attachment',
            url: '',
            type: 'file',
          },
        ]);

        break;
      case 'file':
        setSettingsArr((prev) => [
          ...prev,
          {
            id: uniqueId(),
            position: prev.length + 1,
            name: 'file_attachment',
            url: '',
            type: 'file',
          },
        ]);
        break;
      case 'delay':
        setSettingsArr((prev) => [
          ...prev,
          {
            id: uniqueId(),
            position: prev.length + 1,
            name: 'delay',
            delay: '',
          },
        ]);
        toast.warn('The Delay Block Cant Be The Last Element', {});
        break;
      default:
        break;
    }
    setShowAddMenu(false);
  };

  return (
    <div>
      <div className="flex justify-between">
        <button
          type="button"
          className={`rounded-md bg-none drop-shadow-xl mt-2 flex items-center ${
            showAddMenu ? 'text-red-600' : 'text-spaceCadetBlue'
          }`}
          onClick={() => {
            setShowAddMenu(!showAddMenu);
          }}
        >
          Add...&nbsp;
          {showAddMenu ? (
            <span style={{ fontSize: '0.7em' }}>&#9650;</span>
          ) : (
            <span style={{ fontSize: '0.7em' }}>&#9660;</span>
          )}
        </button>
        <div className={`relative mt-2 ${expand ? ' ml-5 mr-5' : ''} `}>
          <button
            type="button"
            className={`rounded-xl w-auto text-[#52b7c9] ${
              !expand ? 'hover:text-[#52b7c9] hover:shadow-md bg-white' : 'bg-gray-200'
            } p-2 text-xs`}
            onClick={(e) => {
              if (!expand) toggleRepliesModal(e);
            }}
          >
            Quick replies
          </button>
          {(showQuickRepliesModal || showModal) && (
            <AddQuickRepliesModal
              onClose={toggleRepliesModal}
              setQuickReplies={setQuickReplies}
              quickReplies={quickReplies}
              setShowQuickRepliesModal={setShowQuickRepliesModal}
              setUpdatedQuickReply={setUpdatedQuickReply}
              selectedQuickReplyId={selectedQuickReplyId}
              isQuickReplyEditMode={isQuickReplyEditMode}
              setIsQuickReplyEditMode={setIsQuickReplyEditMode}
              setShowModal={setShowModal}
              setShowSubscriberQuickReplyModal={setShowSubscriberQuickReplyModal}
            />
          )}
        </div>
      </div>
      {showAddMenu && (
        <div className="transition-transform duration-300 ease-in drop-shadow-lg bg-white w-[75%] h-auto flex flex-wrap justify-between p-5 rounded-md">
          {[
            { type: 'text', icon: <TextIcon /> },
            { type: 'image', icon: <ImageIcon /> },
            { type: 'file', icon: <FileIcon /> },
            { type: 'delay', icon: <DelayIcon /> },
          ].map(({ type, icon }) => (
            <button
              key={type}
              className="hover:drop-shadow-md shadow-md bg-white w-[30%] h-[80px] flex flex-col items-center justify-center rounded-md p-1 mb-1 cursor-pointer"
              id={type}
              type="button"
              onClick={() => addElements(type)}
            >
              {icon}
              <span className="mt-2">{type.charAt(0).toUpperCase() + type.slice(1)}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

AddAndQuickReplies.propTypes = {
  quickReplies: PropTypes.object,
  setSettingsArr: PropTypes.func,
  setQuickReplies: PropTypes.func,
  expand: PropTypes.bool,
  showModal: PropTypes.bool,
  setUpdatedQuickReply: PropTypes.func,
  selectedQuickReplyId: PropTypes.string,
  isQuickReplyEditMode: PropTypes.bool,
  setIsQuickReplyEditMode: PropTypes.func,
  setShowModal: PropTypes.func,
  setShowSubscriberQuickReplyModal: PropTypes.func,
};

export default AddAndQuickReplies;
