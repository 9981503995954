import moment from 'moment';
import { toast } from 'react-toastify';

import { CLIENT_STATUS } from 'constants/client.const';
import { ISelectOption } from 'interfaces/component.interface';
import { countryCodesList } from 'utils/helpers';
import { cleanExcelColumns, exportSingleSheetToExcel } from 'utils/excel';

import { excelHeaders, orderByOptions } from './constants';

/**
 * Set selected item for account status select filter
 * @param {string} value - Selected value from url params(status)
 * @returns {ISelectOption} - Selected status object
 */
export const getStatusSelected = (value: string): ISelectOption => {
  const selectedStatus = CLIENT_STATUS.find((statusOption) => statusOption.value === value);
  return selectedStatus;
};

/**
 * Set selected item for country select filter
 * @param {string} value - Selected value from url params(country)
 * @returns {ISelectOption} - Selected country object
 */
export const getCountrySelected = (value: string): ISelectOption => {
  const selectedCountry = countryCodesList.find(
    (countryOption) => countryOption.value === value?.toUpperCase(),
  );
  return selectedCountry;
};

/**
 * Set selected item for account sort select filter
 * @param {string} value - Selected value from url params(sort)
 * @returns {ISelectOption} - Selected sort object
 */
export const getSortBySelected = (value: string): ISelectOption => {
  const selectedSortBy = orderByOptions.find((sortByOption) => sortByOption.value === value);
  return selectedSortBy;
};

export const handleExportExcel = async (docs: any[]) => {
  if (docs.length === 0) {
    toast.warning('No records found');
    return;
  }

  const mappedColumns = excelHeaders.map((x) => ({
    header: x.value,
    key: x.key,
    width: 25,
  }));

  // Clean the columns by removing those with empty labels
  const cleanedColumns = cleanExcelColumns(mappedColumns);
  const rows = docs; // Get the data rows

  const mappedRowData = rows.map((x) => ({
    ...x,
    createdAt: x.createdAt ? moment(x.createdAt).fromNow() : 'N/A',
  }));

  try {
    await exportSingleSheetToExcel({
      worksheetName: 'clients-list', // Name of the worksheet in the Excel file
      columns: cleanedColumns, // Cleaned columns for the Excel file
      rows: mappedRowData, // Mapped and formatted row data for the Excel file
      fileName: 'Clients List', // Name of the exported Excel file
    });
  } catch (e) {
    toast.error('Something went wrong, Try again');
  }
};
