import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { RootState } from 'slices';

import { updateMessageSettings } from 'slices/flow';

import { isEqual } from 'lodash';
import MessageInputField from './components/MessageInputField';

import ImageAttachmentComponent from './components/ImageAttachmentComponent';
import FileAttachmentComponent from './components/FileAttachmentComponent';
import { QuickReplyBuilder } from './helpers/QuickReplyBuilder';
import AddAndQuickReplies from './components/AddAndQuickReplies';
import SubscriberResponse from './components/SubscriberResponse';
import { IMessageSetting, IQuickReply, ISubscriberSetting } from './interfaces';
import DelayComponent from './components/Delay';

interface IMessageDrawerProps {
  nodeId: string;
  toggleDrawer: () => void;
}

const MessageDrawer = forwardRef(({ toggleDrawer, nodeId }: IMessageDrawerProps, ref) => {
  const dispatch = useDispatch();
  const flowData = useSelector((state: RootState) => state.flow);

  const [onInit, setOnInit] = useState(false);

  const [expand, setExpand] = useState(false);

  // normal quick reply popup
  const [showModal, setShowModal] = useState(false);
  // normal subscriber quick reply popup
  const [showSubscriberQuickReplyModal, setShowSubscriberQuickReplyModal] = useState(false);

  const [updatedQuickReply, setUpdatedQuickReply] = useState<any>();

  const [isQuickReplyEditMode, setIsQuickReplyEditMode] = useState(false);

  // state for all the message drawer settings
  const [settingsArr, setSettingsArr] = useState<IMessageSetting[]>([]);
  // state for all the quick replies
  const [quickReplies, setQuickReplies] = useState<IQuickReply>();
  // state for all the subscriber settings
  const [subscriberSettingsArr, setSubscriberSettingsArr] = useState<ISubscriberSetting>();

  const [selectedQuickReplyId, setSelectedQuickReplyId] = useState();

  const msgFlowObj = flowData.nodeConfigs.find((obj) => obj.nodeId === nodeId);

  useEffect(() => {
    if (msgFlowObj) {
      setSettingsArr(msgFlowObj?.messageSettings);
      setQuickReplies(msgFlowObj?.quickReplies);
      setSubscriberSettingsArr(msgFlowObj?.subscriberSettings);

      if (msgFlowObj?.subscriberSettings?.isEnabled) {
        setExpand(true);
      }
    }
  }, []);

  useEffect(() => {
    if (onInit) {
      const isSettingsArrEqual = isEqual(msgFlowObj?.messageSettings, settingsArr);
      const isQuickRepliesEqual = isEqual(msgFlowObj?.quickReplies, quickReplies);
      const isSubscriberSettingsArrEqual = isEqual(
        msgFlowObj?.subscriberSettings,
        subscriberSettingsArr,
      );
      if (!isSettingsArrEqual || !isQuickRepliesEqual || !isSubscriberSettingsArrEqual) {
        dispatch(
          updateMessageSettings({
            nodeId,
            newData: {
              messageSettings: settingsArr,
              quickReplies,
              subscriberSettings: subscriberSettingsArr,
            },
          }),
        );
      }
    }
    setOnInit(true);
  }, [settingsArr, quickReplies, subscriberSettingsArr]);

  const handleSubmit = () => {
    toggleDrawer();
  };

  // normal quick replies
  const handleUpdateQuickReplies = (id) => {
    setSelectedQuickReplyId(id);
    setShowModal(!showModal);
    setIsQuickReplyEditMode(true);
  };

  // subscriber quick replies
  const handleUpdateSubscriberResponseQuickReplies = (id) => {
    setSelectedQuickReplyId(id);
    setShowSubscriberQuickReplyModal(!showSubscriberQuickReplyModal);
    setIsQuickReplyEditMode(true);
  };

  // building the text message input, image input and file input components based on the priority
  const ComponentBuilder = useMemo(
    () =>
      function componentBuilder({ components }) {
        const sortedComponents = [...components].sort((a, b) => a.position - b.position);
        const hasManyComponents = sortedComponents.length > 1;

        const renderedComponents = sortedComponents.map((component, index) => {
          switch (component.name) {
            case 'message_text': {
              const { text } = component;
              const isEmpty =
                hasManyComponents && (text === '' || text === null || text === undefined);
              return (
                <React.Fragment key={component.id}>
                  <MessageInputField
                    key={component.id}
                    settingsArray={components}
                    componentData={component}
                    setSettingsArr={setSettingsArr}
                    isEmpty={isEmpty}
                  />
                  <br />
                </React.Fragment>
              );
            }
            case 'image_attachment':
              return (
                <React.Fragment key={component.id}>
                  <ImageAttachmentComponent
                    key={component.id}
                    settingsArray={components}
                    componentData={component}
                    setSettingsArr={setSettingsArr}
                  />
                  <br />
                </React.Fragment>
              );
            case 'file_attachment':
              return (
                <React.Fragment key={component.id}>
                  <FileAttachmentComponent
                    key={component.id}
                    settingsArray={components}
                    componentData={component}
                    setSettingsArr={setSettingsArr}
                  />
                  <br />
                </React.Fragment>
              );
            case 'delay':
              return (
                <React.Fragment key={component.id}>
                  <DelayComponent
                    key={component.id}
                    settingsArray={components}
                    componentData={component}
                    setSettingsArr={setSettingsArr}
                  />
                  <br />
                </React.Fragment>
              );
            default:
              return null;
          }
        });

        return <div>{renderedComponents}</div>;
      },
    [],
  );

  // number of quick replies before subscriber options expands
  const quickRepliesBeforeExpand =
    quickReplies?.replies && quickReplies.replies.length > 0
      ? quickReplies.replies.filter((obj) => obj.isUserInputReply === false)
      : [];

  // Expose the handleSubmit function to the parent component using useImperativeHandle
  useImperativeHandle(ref, () => ({
    handleSubmit,
  }));

  return (
    <>
      {/* build the message text input, file input and image input components */}
      <ComponentBuilder components={settingsArr} />

      {!expand && (
        <div className="justify-center items-center flex gap-3">
          <QuickReplyBuilder
            components={quickReplies?.replies || []}
            setQuickReplies={setQuickReplies}
            isUserInputReply={false}
            handleUpdateQuickReplies={handleUpdateQuickReplies}
          />
        </div>
      )}
      {/* if SubscriberResponse modal is open and there is old quick replies
      , show this component */}
      {expand && quickRepliesBeforeExpand.length !== 0 && (
        <div className="flex flex-col text-center justify-center w-full border-dashed border-[1px] border-red-300 rounded-lg cursor-pointer">
          <span className="text-red-400 text-xs px-10 py-2">
            &ldquo;This is a quote&rdquo; needs to be the last element. The following quick replies
            will not be sent.
          </span>
          <QuickReplyBuilder
            components={quickReplies?.replies || []}
            setQuickReplies={setQuickReplies}
            isUserInputReply={false}
            old
            handleUpdateQuickReplies={handleUpdateQuickReplies}
          />
          <br />
        </div>
      )}
      <AddAndQuickReplies
        setSettingsArr={setSettingsArr}
        setQuickReplies={setQuickReplies}
        quickReplies={quickReplies}
        expand={expand}
        showModal={showModal}
        setUpdatedQuickReply={setUpdatedQuickReply}
        selectedQuickReplyId={selectedQuickReplyId}
        isQuickReplyEditMode={isQuickReplyEditMode}
        setIsQuickReplyEditMode={setIsQuickReplyEditMode}
        setShowModal={setShowModal}
        setShowSubscriberQuickReplyModal={setShowSubscriberQuickReplyModal}
      />
      <br />
      <SubscriberResponse
        expand={expand}
        setExpand={setExpand}
        setQuickReplies={setQuickReplies}
        quickReplies={quickReplies}
        setSubscriberSettingsArr={setSubscriberSettingsArr}
        subscriberSettingsArr={subscriberSettingsArr}
        showModal={showSubscriberQuickReplyModal}
        setUpdatedQuickReply={setUpdatedQuickReply}
        selectedQuickReplyId={selectedQuickReplyId}
        handleUpdateQuickReplies={handleUpdateSubscriberResponseQuickReplies}
        isQuickReplyEditMode={isQuickReplyEditMode}
        setIsQuickReplyEditMode={setIsQuickReplyEditMode}
        setShowSubscriberQuickReplyModal={setShowSubscriberQuickReplyModal}
        setShowModal={setShowModal}
      />
    </>
  );
});

MessageDrawer.propTypes = {
  nodeId: PropTypes.string,
  toggleDrawer: PropTypes.func,
};

export default MessageDrawer;
