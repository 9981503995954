import * as React from 'react';
import PropTypes from 'prop-types';

function ChangeContactIcon(props: { color: any; width: any; height: any }) {
  const { color = 'white', width = '24', height = '24' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4132_102)">
        <path
          d="M8 7C8 8.06087 8.42143 9.07828 9.17157 9.82843C9.92172 10.5786 10.9391 11 12 11C13.0609 11 14.0783 10.5786 14.8284 9.82843C15.5786 9.07828 16 8.06087 16 7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 21V19C6 17.9391 6.42143 16.9217 7.17157 16.1716C7.92172 15.4214 8.93913 15 10 15H13.5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.42 15.6102C18.615 15.4152 18.8465 15.2605 19.1013 15.155C19.3561 15.0494 19.6292 14.9951 19.905 14.9951C20.1808 14.9951 20.4539 15.0494 20.7087 15.155C20.9635 15.2605 21.195 15.4152 21.39 15.6102C21.585 15.8052 21.7397 16.0368 21.8452 16.2915C21.9508 16.5463 22.0051 16.8194 22.0051 17.0952C22.0051 17.371 21.9508 17.6441 21.8452 17.8989C21.7397 18.1537 21.585 18.3852 21.39 18.5802L18 22.0002H15V19.0002L18.42 15.6102Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4132_102">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
ChangeContactIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default ChangeContactIcon;
