export const DefaultIntents = {
  TransferToAgent: {
    id: 'TransferToAgent',
    description: 'system generated - transfer chat to agent',
    displayIntentName: 'Transfer to Agent',
  },
  Greet: {
    id: 'Greet',
    description: 'system generated - greet message',
    displayIntentName: 'Greet',
  },
  EndConversation: {
    id: 'EndConversation',
    description: 'system generated - end conversation',
    displayIntentName: 'End Conversation',
  },
  FallbackIntent: {
    intentId: 'FALLBCKINT',
    id: 'FallbackIntent',
    description: 'aws generated - Default intent when no other intent matches',
    displayIntentName: 'Fallback Intent',
  },
};
