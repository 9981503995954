export const modalDefaultOptions = {
  show: false,
  actionStatus: false,
  type: null,
  data: {},
};

export enum ModalTypes {
  ForwardMsgModal = 'ForwardMsgModal',
  CompleteMsgModal = 'CompleteMsgModal',
}
