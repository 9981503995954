import * as React from 'react';
import PropTypes from 'prop-types';

function Contact(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color = '#fff', width = 24, height = 24 } = props;
  return (
    <svg
      fill="#000000"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      id="contact-book"
      data-name="Flat Line"
      xmlns="http://www.w3.org/2000/svg"
      className="icon flat-line"
    >
      <path
        id="secondary"
        d="M18,3H4A1,1,0,0,0,3,4V20a1,1,0,0,0,1,1H18a1,1,0,0,0,1-1V4A1,1,0,0,0,18,3ZM14,16H8V15a3,3,0,0,1,3-3,2,2,0,1,1,2-2,2,2,0,0,1-2,2,3,3,0,0,1,3,3Z"
        style={{ fill: 'rgb(44, 169, 188)', strokeWidth: 2 }}
      />
      <path
        id="primary"
        d="M21,9H19V7h2Zm0,4H19v2h2ZM11,8a2,2,0,1,0,2,2A2,2,0,0,0,11,8Zm3,7a3,3,0,0,0-3-3h0a3,3,0,0,0-3,3v1h6Zm5,5V4a1,1,0,0,0-1-1H4A1,1,0,0,0,3,4V20a1,1,0,0,0,1,1H18A1,1,0,0,0,19,20Z"
        style={{
          fill: 'none',
          stroke: 'rgb(0, 0, 0)',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: 2,
        }}
      />
    </svg>
  );
}
Contact.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Contact;
