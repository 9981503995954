/* eslint-disable no-confusing-arrow */
/* eslint-disable function-paren-newline */
import React, { useEffect, useRef, useState } from 'react';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import EnterIcon from './tempIcons/Enter';
import { IQuickReply, ISelectedQuickReplyId } from '../interfaces';

interface IAddQuickRepliesModalProps {
  onClose: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  origin?: string;
  setQuickReplies: React.Dispatch<React.SetStateAction<IQuickReply>>;
  quickReplies: IQuickReply;
  setShowQuickRepliesModal: React.Dispatch<React.SetStateAction<boolean>>;
  setUpdatedQuickReply: React.Dispatch<React.SetStateAction<IQuickReply>>;
  selectedQuickReplyId: ISelectedQuickReplyId;
  isQuickReplyEditMode: boolean;
  setIsQuickReplyEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSubscriberQuickReplyModal: React.Dispatch<React.SetStateAction<boolean>>;
}

function AddQuickRepliesModal(props: IAddQuickRepliesModalProps) {
  const {
    onClose,
    origin,
    setQuickReplies,
    quickReplies,
    setShowQuickRepliesModal,
    setUpdatedQuickReply,
    selectedQuickReplyId,
    isQuickReplyEditMode,
    setIsQuickReplyEditMode,
    setShowModal,
    setShowSubscriberQuickReplyModal,
  } = props;

  const modalRef = useRef(null);

  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        setShowQuickRepliesModal(false);
        setShowModal(false);
        setShowSubscriberQuickReplyModal(false);
      }
    };

    const handleInputKeyDown = (e) => {
      const { key } = e;
      if (key === ' ') {
        e.preventDefault();
        setInputValue((prevState) => `${prevState} `);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleInputKeyDown);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleInputKeyDown);
    };
  }, [onClose]);

  const handleInputChange = (event: { target: { id: any; value: any } }) => {
    const { id, value } = event.target;
    setInputValue(value);
    setUpdatedQuickReply(value);
  };
  const submitQuickReplies = (e, selectedId) => {
    if (e.target === e.currentTarget) {
      let filteredComponents = [];

      if (origin === 'user_input') {
        filteredComponents = quickReplies.replies.filter((obj) => obj.isUserInputReply === true);
      } else {
        filteredComponents = quickReplies.replies.filter((obj) => obj.isUserInputReply === false);
      }

      // Quick replies limited to 5
      if (filteredComponents.length < 5 || isQuickReplyEditMode) {
        if (selectedId && isQuickReplyEditMode) {
          const selectedQuickReplyIndex = quickReplies.replies.findIndex(
            (obj) => obj.id === selectedId,
          );
          if (selectedQuickReplyIndex !== -1) {
            // Create a new object for the selectedQuickReply with the updated text
            const selectedQuickReply = {
              ...quickReplies.replies[selectedQuickReplyIndex],
              text: inputValue,
            };

            // Update the state with the modified selectedQuickReply
            setQuickReplies((prev) => ({
              ...prev,
              replies: prev.replies.map((reply, index) =>
                index === selectedQuickReplyIndex ? selectedQuickReply : reply,
              ),
            }));
            setIsQuickReplyEditMode(false);
          }
        } else {
          setQuickReplies((prev) => ({
            ...prev,
            isUserInput: origin === 'user_input',
            replies: [
              ...prev.replies,
              {
                id: uniqueId(), // Ensure this function returns a string
                text: inputValue,
                isUserInputReply: origin === 'user_input',
              },
            ],
          }));
        }

        // Close the modals when adding or editing quick replies
        setShowQuickRepliesModal(false);
        setShowModal(false);

        setShowSubscriberQuickReplyModal(false);
      } else {
        toast.error('Cannot add more than 5 quick replies');
      }
    }
  };

  return (
    <div
      ref={modalRef}
      className={`absolute -top-1 ${
        origin === 'user_input' ? 'left-0' : '-right-2'
      } flex items-center shadow-md bg-white w-[250px] h-[50px] rounded-3xl z-40`}
    >
      <input
        type="text"
        className="w-[80%] h-full rounded-s-3xl border-[3px] border-green-400 focus:outline-none focus:border-green-400 px-2"
        placeholder={origin === 'user_input' ? 'Button text' : 'Quick replies'}
        value={inputValue}
        onChange={handleInputChange}
        maxLength={25}
        // onKeyDown={handleInputKeyDown}
      />
      <button
        type="button"
        className="h-full w-[20%] bg-green-400 text-white rounded-e-3xl flex items-center justify-center"
        onClick={(e) => submitQuickReplies(e, selectedQuickReplyId)}
        disabled={inputValue === ''}
      >
        <EnterIcon onClick={(e) => submitQuickReplies(e, selectedQuickReplyId)} />
      </button>
    </div>
  );
}

AddQuickRepliesModal.propTypes = {
  onClose: PropTypes.func,
  origin: PropTypes.string,
  setQuickReplies: PropTypes.func,
  setShowQuickRepliesModal: PropTypes.func,
  quickReplies: PropTypes.object,
  setUpdatedQuickReply: PropTypes.func,
  selectedQuickReplyId: PropTypes.string,
  isQuickReplyEditMode: PropTypes.bool,
  setIsQuickReplyEditMode: PropTypes.func,
  setShowModal: PropTypes.func,
  setShowSubscriberQuickReplyModal: PropTypes.func,
};

export default AddQuickRepliesModal;
