import React from 'react';
import PropTypes from 'prop-types';
import Search from '../Icons/Search';

function SearchBar(props) {
  const { placeholder, onSearch, searchByInput } = props;

  return (
    <div className="relative w-auto flex items-center">
      <span className="absolute inset-y-0 left-0 flex items-center pl-2">
        <svg className="h-5 w-5 fill-slate-300" viewBox="0 0 20 20">
          <Search color="#BFBFBF" />
        </svg>
      </span>
      <input
        type="text"
        name="search"
        className="py-2 px-4 rounded-3xl focus:outline-none w-full border-2 placeholder:text-xs font-light font-Lato pl-8 focus:border-[#D9DEE8]"
        placeholder={placeholder}
        onChange={(event) => onSearch(event)}
        value={searchByInput || ''}
      />
    </div>
  );
}

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  searchByInput: PropTypes.string,
  onSearch: PropTypes.func,
};

export default SearchBar;
