/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';

const toolOptions = [
  {
    title: 'Add Message',
    toolName: 'Message',
    icon: 'Message',
  },
  {
    title: 'Flow',
    toolName: 'Flow',
    icon: 'Flow',
  },
  {
    title: 'Action',
    toolName: 'Action',
    icon: 'Action',
  },
];

function ToolBar(props) {
  const { addNewNode } = props;

  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <div>
      <div className="tool-bar__container bg-gray-50 h-screen">
        {toolOptions.map((option, key) => {
          return (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={key}
              onDragStart={(event) => onDragStart(event, 'node')}
              draggable
              className="p-4 m-2 bg-white text-sm cursor-pointer drop-shadow-md hover:drop-shadow-lg rounded-md"
              onClick={() => addNewNode(option?.toolName)}
            >
              <span>{option.title}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

ToolBar.propTypes = {
  addNewNode: PropTypes.func,
};

export default ToolBar;
