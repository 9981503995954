import instance from 'api';
import { AUTH } from 'api/apiEndpoints';
import UrlBuilder from 'api/urlQueryBuilder';

/**
 * Represents the authentication login data.
 */
export interface IAuthLogin {
  email: string;
  password: string;
}

/**
 * Performs an authentication login using the provided credentials.
 *
 * @param data - The authentication login data.
 * @returns A promise that resolves to the authentication response.
 */
export function authLogin(data: IAuthLogin) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(AUTH.LOGIN).build();
  return instance.post(url, data);
}

/**
 * Represents the user registration data.
 */
export interface IAuthRegister {
  /** User's name. */
  name: string;
  /** User's email address. */
  email: string;
  /** User's password. */
  password: string;
}

/**
 * Performs user registration using the provided registration data.
 *
 * @param data - The user registration data.
 * @returns A promise that resolves to the registration response.
 */
export function authRegister(data: IAuthRegister) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(AUTH.REGISTER).build();
  return instance.post(url, data);
}

/**
 * Performs email verification using the provided verification token.
 *
 * @param token - The email verification token.
 * @returns A promise that resolves to the verification response.
 */
export function verifyEmail(token: string) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(AUTH.VERIFY_EMAIL).build();
  const data = { token };
  return instance.post(url, data);
}

export function generateTemporaryUserPassword(id: string) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(AUTH.GENERATE_TEMP_PASSWORD).setPathParams({ id }).build();
  return instance.get(url);
}

export function getAuthorizedUser(id: string) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(AUTH.GET_AUTHORIZED_USER).setPathParams({ id }).build();
  return instance.get(url);
}

export function setupNewPasswordService(body) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(AUTH.SETUP_PASSWORD).build();
  return instance.post(url, body);
}

export function forgetPassword(email: string) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(AUTH.FORGET_PASSWORD).setPathParams({ email }).build();
  return instance.get(url);
}
