import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { Delete, Edit } from 'components/Icons';
import PrimaryButton from 'components/Buttons/Primary';
import Expand from 'components/Icons/Expand';
import GeneralButton from 'components/Buttons/GeneralButton';
import { S3_ASSETS } from 'constants/assets';

function NoteCard(props) {
  const { name, noteData, handleDeleteNote, date, note, location, setNoteData, showNote } = props;

  const [textBodyHeight, setTextBodyHeight] = useState('6');
  const [currentState, setCurrentState] = useState('expand');

  const handleExpand = () => {
    if (currentState === 'expand') {
      setTextBodyHeight('100');
      setCurrentState('collapse');
    } else if (currentState === 'collapse') {
      setTextBodyHeight('6');
      setCurrentState('expand');
    }
  };

  const handleSetNoteDat = () => {
    setNoteData({
      ...noteData,
    });
    showNote();
  };

  return (
    <div className="mb-2">
      <div className="user-account__card p-3 rounded-xl bg-[#FBFBFB] border-brightGray border-[1px] hover:bg-aliceBlue hover:border-b-4">
        <div className="user-account__card-info flex">
          <div className="avatar placeholder">
            <div className="w-10 h-10 rounded-full">
              <img src={location || S3_ASSETS.CHAT_PREVIEW_PLACEHOLDER.url} alt="avatar" />
            </div>
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="p-2 ml-1">
              <p className="font-lato text-[#003249] font-extrabold text-sm mb-1">{name}</p>
            </div>
            <div>
              <p className="font-lato text-xs mb-1">
                {' '}
                {moment(date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD - hh:mm:ss A')}
              </p>
            </div>
          </div>
        </div>

        <hr className="mt-2" />
        {textBodyHeight && (
          <div
            className="flex justify-between items-center mt-2 mb-4 overflow-hidden"
            style={{ maxHeight: `${textBodyHeight}%` }}
          >
            {note}
          </div>
        )}
        <div className="space-x-4">
          <GeneralButton
            text="Edit"
            btnColor="bg-spaceCadetBlue80"
            color="white"
            radius="2xl"
            textSize="xs"
            handleOnClick={handleSetNoteDat}
            btnHoverColor="hover:bg-spaceCadetBlue"
            icon={<Edit color="#fff" />}
          />
          <GeneralButton
            text={currentState}
            btnColor="bg-spaceCadetBlue80"
            color="white"
            radius="2xl"
            textSize="xs"
            handleOnClick={handleExpand}
            btnHoverColor="hover:bg-spaceCadetBlue"
            icon={
              <div className={currentState === 'collapse' ? 'transform scale-y-[-1]' : ''}>
                <Expand color="#fff" />
              </div>
            }
          />
          <GeneralButton
            text="Delete"
            btnColor="bg-begoniaRed"
            color="white"
            radius="2xl"
            textSize="xs"
            handleOnClick={() => handleDeleteNote(noteData)}
            btnHoverColor="hover:bg-begoniaRed"
            icon={<Delete color="#FFF" />}
          />
        </div>
      </div>
    </div>
  );
}
NoteCard.propTypes = {
  name: PropTypes.string,
  location: PropTypes.string,
  date: PropTypes.string,
  note: PropTypes.string,
  noteData: PropTypes.object,
  setNoteData: PropTypes.func,
  showNote: PropTypes.func,
  handleDeleteNote: PropTypes.func,
};

export default NoteCard;
