/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';

// #region import

// #region assets | components
import {
  PhoneIcon,
  EmailIcon,
  AddressIcon,
  CompanyIcon,
  DesignationIcon,
  ViewContact,
  Settings,
} from 'components/Icons';
import PrimaryButton from 'components/Buttons/Primary';
import Status from 'components/Labels/StatusLabel';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'routes/paths';
import GeneralButton from 'components/Buttons/GeneralButton';
import WhatsAppIcon from 'components/Icons/WhatsAppIcon';

function ContactDetailCard(props) {
  const { contactDetails, isPreviewContactBtnVisible = true } = props;

  const navigate = useNavigate();

  // navigate to contact view
  const handleViewContact = () => {
    navigate(`${PATHS.CONTACT.VIEW.url}/?md=edit&i=${contactDetails._id}`);
  };

  return (
    <div className="h-fit w-82 mt-3">
      <div className="bg-white border rounded-xl w-82 justify-between  pb-3">
        <div className="flex h-auto p-4 items-center justify-between rounded-t-lg bg-brightGray gap-11">
          <span className="text-center text-sm text-spaceCadetBlue font-ptScanCap font-semibold">
            About this contact
          </span>
          {isPreviewContactBtnVisible && (
            <GeneralButton
              text="View Contact"
              btnColor="bg-spaceCadetBlue80"
              btnHoverColor="hover:bg-spaceCadetBlue"
              color="white"
              icon={<ViewContact />}
              textSize="xs"
              radius="2xl"
              handleOnClick={handleViewContact}
            />
          )}
        </div>
        {/* -------------------------------------------- */}
        <div className="w-fit mt-2">
          <div className="w-fit h-16 flex flex-wrap justify-start items-center">
            <div className="h-8  pl-5 pr-3">
              <PhoneIcon width={20} height={20} />
            </div>
            <div className="col-span-5 md:col-span-4 ">
              <p className="text-rasinBlack font-bold text-sm">Contact Number</p>
              <p className="text-gray-600 font-normal text-xs">
                {contactDetails?.primaryContactNo || ''}
              </p>
            </div>
            {/* -------------------------------------------- */}
            {contactDetails?.preferredContactMethod?.phoneCall && (
              <div className="w-fit h-12 ml-4 ">
                <Status
                  bgColor="bg-spaceCadetBlue"
                  label="Preferred"
                  color="white"
                  borderColor="none"
                  className="font-lato font-extrabold mt-1"
                  icon={<PhoneIcon color="#ffff" width={16} height={16} />}
                />
              </div>
            )}

            {contactDetails?.preferredContactMethod?.whatsApp && (
              <div className="w-fit h-12 ml-4 ">
                <Status
                  bgColor="bg-spaceCadetBlue"
                  label="Preferred"
                  color="white"
                  borderColor="none"
                  className="font-lato font-extrabold mt-1"
                  icon={<WhatsAppIcon width={15} height={15} />}
                />
              </div>
            )}
          </div>
        </div>
        {/* -------------------------------------------- */}
        <div className="w-fit">
          <div className="w-fit h-16 flex flex-grow justify-start items-center">
            <div className="h-8  pl-5 pr-3">
              <EmailIcon width={20} height={20} />
            </div>
            <div className="col-span-5 md:col-span-4 ">
              <p className="text-rasinBlack font-bold text-sm">Email Address</p>
              <p className="text-gray-600 font-normal text-xs">{contactDetails?.email || ''}</p>
            </div>
            {contactDetails?.preferredContactMethod?.email && (
              <div className="w-fit h-12 ml-1">
                <Status
                  bgColor="bg-spaceCadetBlue"
                  label="Preferred"
                  color="white"
                  borderColor="none"
                  className="font-lato font-extrabold mt-1"
                />
              </div>
            )}
          </div>
        </div>
        {/* -------------------------------------------- */}

        {(contactDetails?.streetAddress || contactDetails?.city) && (
          <div className="flex pl-5">
            <div className="">
              <AddressIcon width={20} height={20} />
            </div>
            <div className="flex flex-col pl-2">
              <p className="text-rasinBlack font-bold text-sm">Address</p>
              <p className="text-gray-600 font-normal text-xs">
                {contactDetails?.streetAddress
                  ? `${contactDetails?.city}, ${contactDetails?.streetAddress}`
                  : contactDetails?.address
                  ? `${contactDetails?.address}`
                  : ''}
              </p>
            </div>
          </div>
        )}

        {/* -------------------------------------------- */}
        {contactDetails?.company && (
          <div className="w-fit">
            <div className="w-fit h-16 flex flex-wrap justify-start items-center">
              <div className="h-8  pl-5 pr-3">
                <CompanyIcon width={20} height={20} />
              </div>
              <div className="col-span-5 md:col-span-4 ">
                <p className="text-rasinBlack font-bold text-sm">Company</p>
                <p className="text-gray-600 font-normal text-xs">{contactDetails?.company || ''}</p>
              </div>
            </div>
          </div>
        )}

        {/* -------------------------------------------- */}
        {contactDetails?.designation && (
          <div className="w-fit">
            <div className="w-fit h-16 flex flex-wrap justify-start items-center">
              <div className="h-8  pl-5 pr-3">
                <DesignationIcon width={20} height={20} />
              </div>
              <div className="col-span-5 md:col-span-4 ">
                <p className="text-rasinBlack font-bold text-sm">Designation</p>
                <p className="text-gray-600 font-normal text-xs">
                  {contactDetails?.designation || ''}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

ContactDetailCard.propTypes = {
  // contactDetails: {
  //   primaryContactNo: PropTypes.string,
  //   email: PropTypes.string,
  //   streetAddress: PropTypes.string,
  //   company: PropTypes.string,
  //   designation: PropTypes.string,
  //   preferredContactMethod: {
  //     phoneCall: PropTypes.bool,
  //     email: PropTypes.bool,
  //     whatsApp: PropTypes.bool,
  //   },
  // },
  contactDetails: PropTypes.any,
  isPreviewContactBtnVisible: PropTypes.bool,
};

export default ContactDetailCard;
