export const defaultTableHeaders = [
  {
    key: 'sequenceId',
    value: 'Channel ID',
  },
  {
    key: 'channelName',
    value: 'Channel Name',
  },
  {
    key: 'description',
    value: 'Description',
  },
  {
    key: 'status',
    value: 'Status',
    type: 'statusLabel',
    params: {
      active: {
        label: 'ACTIVE',
        color: 'white',
        bgColor: 'bg-lotionLightGray',
        borderColor: 'bg-gainsboroBlue',
      },
      disabled: {
        label: 'DISABLED',
        color: 'white',
        bgColor: 'bg-begoniaRed',
        borderColor: 'bg-gainsboroBlue',
      },
    },
  },
  {
    key: 'creatorId',
    value: 'Created By',
  },
  {
    key: 'createdAt',
    value: 'Created At',
    type: 'dateTime',
  },
  {
    key: 'updatedAt',
    value: 'Last Updated At',
    type: 'dateTime',
  },
  {
    key: 'manage',
    value: 'Manage',
    type: 'manageBtn',
    params: {
      buttonLabel: 'View',
      customActionHandlerName: 'displayModal',
    },
  },
];

export const defaultListViewData = {
  totalDocs: 0,
  totalPages: 0,
  limit: 10,
  page: 1,
  headers: defaultTableHeaders,
  docs: [],
  sort: null,
  order: -1,
  searchBy: null,
};
