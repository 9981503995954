export { default as Search } from './Search';
export { default as AddClient } from './AddClient';
export { default as ActiveIcon } from './ActiveIcon';
export { default as NewTab } from './NewTab';
export { default as EndedTab } from './EndedTab';
export { default as Manage } from './Manage';
export { default as Export } from './Export';
export { default as SortBy } from './SortBy';
export { default as Back } from './Back';
export { default as Save } from './Save';
export { default as Star } from './Star';
export { default as AddCustomer } from './AddCustomer';
export { default as UpdateCustomer } from './UpdateCustomer';
export { default as Attachment } from './Attachment';
export { default as UploadImage } from './UploadImage';
export { default as Forward } from './Forward';
export { default as Complete } from './Complete';
export { default as Confirm } from './Confirm';
export { default as ViewClient } from './ViewClient';
export { default as OpenList } from './OpenList';
export { default as UserApprove } from './UserApprove';
export { default as SendApprove } from './SendApprove';
export { default as ApproveLater } from './ApproveLater';
export { default as Enable } from './Enable';
export { default as AddTemplate } from './AddTemplate';
export { default as NewCase } from './NewCase';
export { default as Template } from './Template';
export { default as ViewCase } from './ViewCase';
export { default as Activate } from './Activate';
export { default as Disable } from './Disable';
export { default as Close } from './Close';
export { default as AngleBottom } from './AngleBottom';
export { default as Menu } from './Menu';
export { default as ViewCustomerIcon } from './ViewCustomerIcon';
export { default as ListViewIcon } from './ListViewIcon';
export { default as Settings } from './Settings';
export { default as ReportUserIcon } from './ReportUserIcon';
export { default as PhoneIcon } from './PhoneIcon';
export { default as EmailIcon } from './EmailIcon';
export { default as AddressIcon } from './AddressIcon';
export { default as CompanyIcon } from './CompanyIcon';
export { default as DesignationIcon } from './DesignationIcon';
export { default as ViewContact } from './ViewContact';
export { default as AddIcon } from './AddIcon';
export { default as Preview } from './Preview';
export { default as Edit } from './Edit';
export { default as Delete } from './Delete';
export { default as ArrowUp } from './ArrowUp';
export { default as ArrowDown } from './ArrowDown';
export { default as EnvelopIcon } from './EnvelopIcon';
export { default as NoteIcon } from './NoteIcon';
export { default as ChatIcon } from './ChatIcon';
export { default as NoteReadIcon } from './NoteReadIcon';
export { default as DeleteIcon } from './DeleteIcon';
export { default as ImagePlaceholder } from './ImagePlaceholder';
export { default as AddCase } from './AddCase';
export { default as ReopenIcon } from './Reopen';
export { default as Dashboard } from './Dashboard';
export { default as Inbox } from './Inbox';
export { default as AddUser } from './AddUser';
export { default as SubMenuArrow } from './SubMenuArrow';
export { default as SubMenuItem } from './SubMenuItem';
export { default as Password } from './Password';
export { default as ChevronDown } from './ChevronDown';
export { default as Logout } from './Logout';
export { default as Show } from './Show';
export { default as Hide } from './Hide';
export { default as Send } from './Send';
export { default as Messenger } from './Messenger';
export { default as ChangeContactIcon } from './ChangeContact';
export { default as ManageForm } from './ManageForm';
export { default as RemoveIcon } from './RemoveIcon';
export { default as Home } from './Home';
export { default as InputAttachment } from './InputAttachment';
export { default as InputSend } from './InputSend';
export { default as CopyText } from './CopyText';
export { default as NewMessageIcon } from './NewMessageIcon';
export { default as AddReviewIcon } from './AddReview';
export { default as Pdf } from './Pdf';
export { default as Undo } from './Undo';
export { default as Redo } from './Redo';
export { default as ArrowLeft } from './ArrowLeft';
export { default as AddMessageIcon } from './AddMessageIcon';
export { default as FlowIcon } from './FlowIcon';
export { default as APIRequestIcon } from './APIRequestIcon';
export { default as ActionIcon } from './ActionIcon';
export { default as FilterIcon } from './FilterIcon';
export { default as EmojiIcon } from './EmojiIcon';
export { default as AnyIcon } from './AnyIcon';
export { default as AllIcon } from './AllIcon';
export { default as OpenInNewTabIcon } from './OpenInNewTabIcon';
export { default as ChevronRight } from './ChevronRight';
export { default as ChevronLeft } from './ChevronLeft';
export { default as whatsApp } from './WhatsAppIcon';
export { default as Contact } from './Contact';
