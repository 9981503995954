import React from 'react';
import PropTypes from 'prop-types';

function ClientStatus(props: { label: any }) {
  const { label } = props;

  const statusType = label?.toLowerCase() || '';

  function formatLabel(text: string) {
    if (text?.toLowerCase() === 'unapproved') return 'UNAPPROVED';
    if (text?.toLowerCase() === 'inactive') return 'INACTIVE';
    if (text?.toLowerCase() === 'active') return 'ACTIVE';
    return '';
  }

  if (statusType === 'unapproved') {
    return (
      <div className="badge bg-lotionLightGray text-rasinBlack border border-gainsboroBlue font-extrabold font-lato p-3 text-center items-center text-xs">
        {formatLabel(label)}
      </div>
    );
  }

  if (statusType === 'inactive') {
    return (
      <div className="badge bg-coralRed text-white font-extrabold font-lato p-3 text-center items-center text-xs border-none">
        {formatLabel(label)}
      </div>
    );
  }

  if (statusType === 'active') {
    return (
      <div className="badge bg-northTexasGreen text-white font-extrabold font-lato p-3 text-center items-center text-xs border-none">
        {formatLabel(label)}
      </div>
    );
  }

  return <div />;
}

ClientStatus.propTypes = {
  label: PropTypes.string,
};

export default ClientStatus;
