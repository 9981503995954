import React from 'react';

import {
  APIRequestIcon,
  ActionIcon,
  AddMessageIcon,
  ChatIcon,
  FilterIcon,
  FlowIcon,
  ImagePlaceholder,
  NewCase,
  ViewContact,
} from 'components/Icons';

export const NODE_TYPES = {
  Message: {
    id: 'Message',
    label: 'Message',
  },
  Flow: { id: 'Flow', label: 'Flow' },
  Action: { id: 'Action', label: 'Action' },
  APIRequest: { id: 'APIRequest', label: 'API Request' },
  Filter: { id: 'Filter', label: 'Filter' },
  AddContact: { id: 'AddContact', label: 'Add Contact' },
  AddCase: { id: 'AddCase', label: 'Add Case' },
  Carousel: { id: 'Carousel', label: 'Carousel' },
  LiveChat: { id: 'LiveChat', label: 'Live Chat' },
  EndChat: { id: 'EndChat', label: 'End Chat' },
};

export const toolOptions = [
  {
    title: 'Add Message',
    toolName: 'Message',
    icon: <AddMessageIcon />,
  },
  {
    title: 'Flow',
    toolName: 'Flow',
    icon: <FlowIcon />,
  },
  {
    title: 'Action',
    toolName: 'Action',
    icon: <ActionIcon />,
  },
  {
    title: 'API Request',
    toolName: 'APIRequest',
    icon: <APIRequestIcon />,
  },
  {
    title: 'Filter',
    toolName: 'Filter',
    icon: <FilterIcon color="#F1C40F" />,
  },
  {
    title: 'Add Contact',
    toolName: 'AddContact',
    icon: <ViewContact color="#32a852" width={40} height={32} />,
  },
  {
    title: 'Add Case',
    toolName: 'AddCase',
    icon: <NewCase color="#91b352" width={40} height={32} />,
  },
  {
    title: 'Carousel',
    toolName: 'Carousel',
    icon: <ImagePlaceholder color="#E047" width={40} height={32} />,
  },
  {
    title: 'Live Chat',
    toolName: 'LiveChat',
    icon: <ChatIcon color="#F39C12" width={40} height={32} />,
  },
  {
    title: 'End Chat',
    toolName: 'EndChat',
    icon: <ChatIcon color="#D81B60" width={40} height={32} />,
  },
];

// Created variable options
export const createdVariableOptions = [
  { value: 'contactId', label: 'Contact ID' },
  { value: 'firstName', label: 'First Name' },
  { value: 'lastMessage', label: 'Last message' },
  { value: 'greeting', label: 'Greeting' },
  { value: 'name', label: 'Name' },
];

// System variable options
export const systemVariableOptions = [
  { value: 'botId', label: 'Bot ID' },
  { value: 'botName', label: 'Bot Name' },
  { value: 'currentDate', label: 'Current Date' },
  { value: 'currentDateTime', label: 'Current Date and Time' },
];

// Variable Groups
export const groupedOptions = [
  {
    label: 'Select variable',
    options: [],
  },
  {
    label: 'System variables',
    options: systemVariableOptions,
  },
];

export const startNodeStyle = {
  right: 10,
  width: 20,
  height: 20,
  border: '4px solid',
  boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.2)',
};

export const nodeStyle = {
  width: 20,
  height: 20,
  border: '4px solid white',
  boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.2)',
};

// flow options
export const flowOptions = [
  { label: 'Flow 10.10.9.53', value: '10.10.9.53' },
  { label: 'Flow 11.10.9.22', value: '11.10.9.22' },
];

export const messageButtonsNodeStyle = {
  width: 20,
  height: 20,
  border: '4px solid white',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.2)',
};
// styles for variable dropdown options
export const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

export const dropdownCommonStyles = {
  placeholder: (provided) => ({
    ...provided,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
};

export const VARIABLE_TYPES = [
  { value: 'string', label: 'String' },
  { value: 'number', label: 'Number' },
  { value: 'date', label: 'Date' },
  { value: 'phone', label: 'Phone' },
  { value: 'email', label: 'Email' },
  { value: 'url', label: 'URL' },
  { value: 'datetime', label: 'Date and Time' },
  { value: 'time', label: 'Time' },
];
