import { COLLECTIONS } from 'constants/db';

export const defaultCasesTableHeaders = [
  {
    key: 'sequenceId',
    value: 'Case ID',
  },
  {
    key: 'status',
    value: 'Status',
    type: 'statusLabel',
    params: {
      open: {
        label: 'OPEN',
        color: 'black',
        bgColor: 'bg-lotionLightGray',
        borderColor: 'bg-gainsboroBlue',
      },
      completed: {
        label: 'COMPLETED',
        color: 'black',
        bgColor: 'bg-lotionLightGray',
        borderColor: 'bg-gainsboroBlue',
      },
      deleted: {
        label: 'DELETED',
        color: 'black',
        bgColor: 'bg-lotionLightGray',
        borderColor: 'bg-gainsboroBlue',
      },
      closed: {
        label: 'CLOSED',
        color: 'black',
        bgColor: 'bg-lotionLightGray',
        borderColor: 'bg-gainsboroBlue',
      },
    },
  },
  {
    key: 'aging',
    value: 'Aging',
  },
  {
    key: 'subject',
    value: 'Subject',
  },
  {
    key: 'createdAt',
    value: 'Created At',
    type: 'dateTime',
  },
  {
    key: 'manage',
    value: 'Manage',
    type: 'manageBtn',
  },
];

export const defaultNotesTableHeaders = [
  {
    key: 'subject',
    value: 'Subject',
  },
  {
    key: 'status',
    value: 'Status',
    type: 'statusLabel',
    params: {
      deleted: {
        label: 'DELETED',
        color: 'white',
        bgColor: 'bg-coralRed',
        borderColor: 'none',
      },
      open: {
        label: 'OPEN',
        color: 'rasinBlack',
        bgColor: 'bg-lotionLightGray',
        borderColor: 'gainsboroBlue',
      },
    },
  },
  {
    key: 'createdAt',
    value: 'Created At',
    type: 'dateTime',
  },
  {
    key: 'manage',
    value: 'Manage',
    type: 'manageBtn',
  },
];

export const defaultTransactionTableHeaders = [
  {
    key: 'sequenceId',
    value: 'Transaction ID',
  },
  {
    key: 'description',
    value: 'Transaction Description',
  },
  {
    key: 'createdAt',
    value: 'Transaction Date and Time',
    type: 'dateTime',
  },
  {
    key: 'createdBy',
    value: 'User',
    type: 'nameWithEmail',
  },
  {
    key: 'updatedValues',
    value: 'Data',
    type: 'transactionUpdatedValues',
    module: COLLECTIONS.Contact,
  },
];

export const tableCasesDefaultValues = {
  totalDocs: 0,
  totalPages: 0,
  limit: 10,
  page: 1,
  headers: defaultCasesTableHeaders,
  docs: [],
  sort: null,
  order: -1,
  searchBy: null,
};

export const tableNotesDefaultValues = {
  totalDocs: 0,
  totalPages: 0,
  limit: 10,
  page: 1,
  headers: defaultNotesTableHeaders,
  docs: [],
  sort: null,
  order: -1,
  searchBy: null,
};

// !TODO remove this
export const sampleCaseListData = [
  {
    sequenceId: 'CS1002',
    status: 'open',
    aging: '2 Days',
    subject: 'Account Reset',
    createdAt: '2023-09-02T07:58:02.933+00:00',
  },
  {
    sequenceId: 'CS1002',
    status: 'completed',
    aging: '2 Days',
    subject: 'Account Reset',
    createdAt: '2023-09-02T07:58:02.933+00:00',
  },
];

// !TODO remove this
export const sampleNoteListData = [
  {
    subject: 'Lorem ipsum',
    status: 'open',
    createdAt: '2023-09-02T07:58:02.933+00:00',
  },
  {
    subject: 'Lorem ipsum',
    status: 'deleted',
    createdAt: '2023-09-02T07:58:02.933+00:00',
  },
];

// !TODO remove this
export const sampleTransactionListData = [
  {
    sequenceId: '10001CA',
    description: 'Account Created',
    createdAt: '2023-09-02T07:58:02.933+00:00',
    creatorId: {
      fullName: 'Thiwanka Nimalarathna',
      email: 'thiwanka@tasa.com.my',
    },
    data: 'sample data',
  },
  {
    sequenceId: '10002CA',
    description: 'Account Approved',
    createdAt: '2023-09-02T07:58:02.933+00:00',
    creatorId: {
      fullName: 'Thiwanka Nimalarathna',
      email: 'thiwanka@tasa.com.my',
    },
    data: 'sample data',
  },
];

export const CONVERSATION_STATUS = [
  { label: 'Active', value: 'active' },
  { label: 'New', value: 'new' },
  { label: 'Ended', value: 'ended' },
];
