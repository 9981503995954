import { combineReducers } from '@reduxjs/toolkit';

import userReducer from './user';
import authReducer from './auth';
import layoutReducer from './layout';
import eventReducer from './event';
import flowReducer from './flow';

function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    user: userReducer,
    auth: authReducer,
    layout: layoutReducer,
    event: eventReducer,
    flow: flowReducer,
    ...injectedReducers,
  });
  return rootReducer;
}
const rootReducer = createReducer();
export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
