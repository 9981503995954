import { ConversationStatus } from 'constants/conversation.const';

export const conversationTableHeaders = [
  {
    key: 'sequenceId',
    value: 'Chat ID',
  },
  {
    key: 'status',
    value: 'Status',
    type: 'statusLabel',
    params: {
      [ConversationStatus.ACTIVE]: {
        label: 'ACTIVE',
        color: 'white',
        bgColor: 'bg-northTexasGreen',
        borderColor: 'bg-gainsboroBlue',
      },
      [ConversationStatus.NEW]: {
        label: 'NEW',
        color: 'white',
        bgColor: 'bg-northTexasGreen',
        borderColor: 'bg-gainsboroBlue',
      },
      [ConversationStatus.ENDED]: {
        label: 'COMPLETED',
        color: 'white',
        bgColor: 'bg-northTexasGreen',
        borderColor: 'bg-gainsboroBlue',
      },
    },
  },
  {
    key: 'createdAt',
    value: 'Conversation Date and Time',
    type: 'dateTime',
  },
  {
    key: 'user',
    value: 'user/Agent',
    type: 'transactionUser',
  },
  {
    key: 'manage',
    value: 'Manage',
    type: 'viewBtn',
    params: {
      buttonLabel: 'View',
      customActionHandlerName: 'displayModal',
    },
  },
];

export enum ModalTypes {
  LinkChat = 'LinkChat',
  UnlinkChat = 'UnlinkChat',
}
