/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { toast } from 'react-toastify';

import {
  approveUser,
  createUser,
  getUserById,
  sendUserAccountForApproval,
  toggleAccountDisable,
  updateUser,
} from 'services/user.service';
import { PromptModal } from 'components';
import { apiErrorHandler } from 'api/handler';
import { updateURLWithSearchParams } from 'utils/helpers';
import { fetchClientListData } from 'services/client.service';
import { getClientId, getUserType } from 'utils/auth';
import { fetchRolesListData } from 'services/roles.service';

import { UserType } from 'constants/user.const';
import { getUserAccountValidationSchema } from './validations';
import {
  ModalTypes,
  PromptTypes,
  customLoadingOptions,
  modalDefaultOptions,
  promptDefaultOptions,
} from './constants';
import FormPage from './page';
import AccountCreateSuccess from './components/modals/AccountCreateSuccess';
import SendForApprovalModal from './components/modals/SendForApproval';

function UserAccountForm() {
  // getting the current user type logged into the system
  const currentUserType = getUserType();

  const defaultFormData = {
    userType: currentUserType === UserType.ClientUser ? UserType.ClientUser : null,
  };

  const [searchParams] = useSearchParams(); // read url params

  const [formData, setFormData] = useState<any>(defaultFormData);

  const [attachments, setAttachments] = useState<any>({ profilePic: null });

  const [clientsOptions, setClientsOptions] = useState([]);
  const [rolesOptions, setRolesOptions] = useState([]);

  const [editMode, setEditMode] = useState(false);
  const [customLoading, setCustomLoading] = useState(customLoadingOptions);
  const [triggerChild, setTriggerChild] = useState({ transactions: null });

  const [modalOptions, setModalOptions] = useState(modalDefaultOptions);
  const [promptOptions, setPromptOptions] = useState(promptDefaultOptions);

  const pageType = searchParams.get('md');
  const pageId = searchParams.get('i');

  const { register, handleSubmit, formState, control } = useForm<any>({
    // Dynamically resolve the validation schema based on formData
    resolver: useMemo(
      () => yupResolver(getUserAccountValidationSchema(formData)),
      [formData.clientId, formData.userType, formData.enableNickName],
    ),
    // resolver: yupResolver(getUserAccountValidationSchema(formData)),
    values: useMemo(() => formData, [customLoading.fetchForm]),
  });

  const { errors } = formState;

  useEffect(() => {
    if (!pageType || pageType === 'new') {
      updateURLWithSearchParams({ md: 'new' });
      const clientId = getClientId();
      // setFormData((prevState: any) => ({ ...prevState, clientId: '5aa1c2c35ef7a4e97b5e995c' }));
      setFormData((prevState: any) => ({ ...prevState, clientId }));
    }
    if (pageType && pageType === 'edit' && pageId) {
      setEditMode(true);
      fetchUserData(pageId);
    }
  }, []);

  useEffect(() => {
    getAllClients();
  }, []);

  useEffect(() => {
    getAllRoles();
  }, [formData?.clientId, formData.userType]);

  const getAllClients = async () => {
    try {
      setCustomLoading((prevState) => ({ ...prevState, fetchClients: true }));
      const params = { limit: 1000 };
      const { data } = await fetchClientListData(params);
      if (data?.docs) {
        const options = data?.docs?.map(({ _id, companyName }) => ({
          label: companyName,
          value: _id,
        }));
        setClientsOptions(options);
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, fetchClients: false }));
    }
  };

  const getAllRoles = async () => {
    try {
      setCustomLoading((prevState) => ({ ...prevState, fetchRoles: true }));
      const params = {
        limit: 1000,
        userType: formData?.userType,
        clientId: formData?.clientId,
      };
      const { data } = await fetchRolesListData(params);

      if (data) {
        const options = data?.node?.docs?.map(({ _id, name }) => ({
          label: name,
          value: _id,
          _id,
        }));

        if (formData.userType === 'systemManagementUser') {
          setRolesOptions(options);
        } else if (formData.userType === 'clientUser' && formData.clientId !== null) {
          setRolesOptions(options);
        } else {
          setRolesOptions([]);
        }
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, fetchRoles: false }));
    }
  };

  async function fetchUserData(id: string) {
    try {
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: true, fetchForm: true }));
      const { data } = await getUserById(id);

      if (data?.node) {
        const { roles } = data.node;
        setFormData((prevState) => ({
          ...prevState,
          ...data.node,
          roles: roles ? roles.map((val) => ({ ...val, label: val.name, value: val._id })) : [],
        }));
        if (data.node.attachments) {
          setAttachments((prevState) => ({ ...prevState, ...data.node.attachments }));
        }
      }
    } catch (e) {
      const { message: exception } = apiErrorHandler(e);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: false, fetchForm: false }));
    }
  }

  // Handles the form submission
  const onSubmit = async () => {
    try {
      const payload = formData;
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: true, fetchForm: true }));
      if (!editMode) {
        const { data } = await createUser(payload);
        if (data) {
          delete data.node.roles;
          delete data.node.attachments;
          setFormData({ ...payload, ...data.node });
          setEditMode(true);
          handleOnApproveAccount(data.node, ModalTypes.AccountCreationSuccess);
          setTriggerChild((prevState) => ({ ...prevState, transactions: uuid() }));
        }
      } else {
        const { data } = await updateUser(formData._id, payload);
        if (data) {
          delete data.node.roles;
          delete data.node.attachments;
          setFormData({ ...payload, ...data.node });
          setTriggerChild((prevState) => ({ ...prevState, transactions: uuid() }));
          toast.success('Successfully Updated User');
        }
      }
    } catch (e) {
      const { message: exception } = apiErrorHandler(e);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: false, fetchForm: false }));
    }
  };

  // When click the confirm button client account activate
  const handleApproveAccount = async () => {
    try {
      setCustomLoading((prevState) => ({ ...prevState, activateAccount: true }));
      const { data } = await approveUser(formData._id);
      if (data?.node) {
        setFormData({ ...formData, isApproved: true });
        toast.success('Successfully Approved Account');
        setModalOptions(modalDefaultOptions);
        setTriggerChild((prevState) => ({ ...prevState, transactions: uuid() }));
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, activateAccount: false }));
    }
  };

  const handleSendForApproval = async (selectedUserId: string) => {
    try {
      setCustomLoading((prevState) => ({ ...prevState, sendForApproval: true }));
      const { data } = await sendUserAccountForApproval(formData._id, selectedUserId);
      if (data?.node) {
        setModalOptions(modalDefaultOptions);
        toast.success('Successfully Sent For Approval');
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, sendForApproval: false }));
    }
  };

  const handleToggleAccountDisable = async () => {
    try {
      setCustomLoading((prevState) => ({ ...prevState, toggleAccount: true }));
      const { _id } = formData;
      const { data } = await toggleAccountDisable(_id, { isDisabled: !formData.isDisabled });
      if (data?.node) {
        toast.success(`Successfully ${formData.isDisabled ? 'Enabled' : 'Disabled'} Account`);
        setFormData((prevState) => ({
          ...prevState,
          isDisabled: !formData.isDisabled,
        }));
        setPromptOptions(promptDefaultOptions);
        setTriggerChild((prevState) => ({ ...prevState, transactions: uuid() }));
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, toggleAccount: false }));
    }
  };

  // handle the input text change
  const handleInputChange = (event: { target: { id: any; value: any } }) => {
    const { id, value } = event.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  // handle phone number change
  const handlePhoneNoChange = (id: string, value: string) => {
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  // handle the dropdown change
  const handleSelectChange = (event: any, id: string) => {
    if (id === 'roles') {
      setFormData({
        ...formData,
        [id]: event,
      });
    } else {
      setFormData({
        ...formData,
        [id]: event?.value || null,
      });
    }
  };

  // handle the checkbox
  const handleCheckChange = (event) => {
    const { id, checked } = event.target;
    setFormData({
      ...formData,
      [id]: checked,
    });
  };

  const handleOnApproveAccount = (userData: any, modalType: string) => {
    setModalOptions((prevState) => ({
      ...prevState,
      show: true,
      actionStatus: false,
      type: modalType,
      data: userData,
    }));
  };

  // Approve account model
  const handleOnSendForApproval = () => {
    setModalOptions((prevState) => ({
      ...prevState,
      show: true,
      actionStatus: false,
      type: ModalTypes.SendForApproval,
      data: formData,
    }));
  };

  const handleOnToggleAccountDisable = () => {
    setPromptOptions({
      show: true,
      title: `${formData.isDisabled ? 'Enable' : 'Disable'} Account`,
      message: `Do you want to confirm ${
        formData.isDisabled ? 'enabling' : 'disabling'
      } the Account?`,
      onConfirm: () => handleToggleAccountDisable(),
      type: PromptTypes.ToggleAccount,
    });
  };

  // used to get updated state for loading
  function loadDynamicLoadingProp() {
    if (promptOptions.type === PromptTypes.ToggleAccount) {
      return customLoading.toggleAccount;
    }
    return false;
  }

  return (
    <>
      <FormPage
        onSubmit={onSubmit}
        errors={errors}
        formData={formData}
        handleInputChange={handleInputChange}
        handleSelectChange={handleSelectChange}
        handleSubmit={handleSubmit}
        clientsOptions={clientsOptions}
        control={control}
        customLoading={customLoading}
        editMode={editMode}
        handleCheckChange={handleCheckChange}
        register={register}
        rolesOptions={rolesOptions}
        triggerChild={triggerChild}
        handleOnToggleAccountDisable={handleOnToggleAccountDisable}
        handleOnApproveAccount={handleOnApproveAccount}
        handlePhoneNoChange={handlePhoneNoChange}
      />

      {(modalOptions.type === ModalTypes.AccountCreationSuccess ||
        ModalTypes.ActivateAccountModal) &&
        modalOptions.show && (
          <AccountCreateSuccess
            handleApproveAccount={handleApproveAccount}
            {...modalOptions}
            loading={customLoading.approveAccount}
            toggleModal={() => setModalOptions({ ...modalOptions, show: false })}
            handleOnSendForApproval={handleOnSendForApproval}
          />
          // eslint-disable-next-line indent
        )}

      {modalOptions.type === ModalTypes.SendForApproval && modalOptions.show && (
        <SendForApprovalModal
          handleSendForApproval={handleSendForApproval}
          {...modalOptions}
          formData={modalOptions.data}
          loading={customLoading.sendForApproval}
          toggleModal={() => setModalOptions({ ...modalOptions, show: false })}
        />
      )}

      {promptOptions.show && (
        <PromptModal
          onCancel={() => {
            setPromptOptions({ ...promptOptions, show: false });
          }}
          {...promptOptions}
          loading={loadDynamicLoadingProp()}
        />
      )}
    </>
  );
}

export default UserAccountForm;
