import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'slices';

import { getDefaultValueForSelect, getDefaultValueForVariableSelect } from 'utils/helpers';
import { updateContactSettings } from 'slices/flow';
import AddContactForm from './form';
import { IContactForm } from './interfaces';

interface IAddContactDrawerProps {
  handleContactDrawerSubmit: (formData: IContactForm, nodeId: string) => void;
  nodeId: string;
  isFormValid: boolean;
  setIsFormValid: any;
}

/**
 * This is Add contact drawer component for flow builder
 */
const AddContactDrawer = forwardRef(
  (
    { handleContactDrawerSubmit, nodeId, isFormValid, setIsFormValid }: IAddContactDrawerProps,
    ref,
  ) => {
    const dispatch = useDispatch();
    const flowData = useSelector((state: RootState) => state.flow);

    const [formData, setFormData] = useState<IContactForm>();
    const [onInit, setOnInit] = useState(false);

    useEffect(() => {
      const contactObj = flowData.nodeConfigs.find((node) => node.nodeId === nodeId);
      if (contactObj) {
        setFormData(contactObj?.contactSettings?.data);
      }
    }, []);

    useEffect(() => {
      if (onInit) {
        dispatch(
          updateContactSettings({
            data: formData,
            nodeId,
          }),
        );
      }
      setOnInit(true);
    }, [formData]);

    // selected value based on a specified value type
    const useSelectedValueType = (valueType: string, array: any[], id: string) =>
      useMemo(() => getDefaultValueForSelect(valueType, array, id), [valueType]);

    // selected value based grouped array options
    const useSelectedVariableValue = (valueType: string, array: any[], id: string) =>
      useMemo(() => getDefaultValueForVariableSelect(valueType, array, id), [valueType]);

    // handle the checkbox
    const handleCheckChange = (event: any, section: string) => {
      const { checked } = event.target;
      if (!checked) {
        // If checked is false, clear values for the relevant section
        setFormData({
          ...formData,
          [section]: {
            ...formData?.[section],
            value: null,
            valueType: null,
            isFieldEnabled: checked,
          },
        });
      } else {
        // If checked is true, update isFieldEnabled only
        setFormData({
          ...formData,
          [section]: {
            ...formData?.[section],
            isFieldEnabled: checked,
          },
        });
      }
    };

    // handle select field change
    const handleSelectChange = (event: any, section: string) => {
      setFormData({
        ...formData,
        [section]: {
          ...formData?.[section],
          valueType: event?.value,
          value: null,
        },
      });
      if (event?.value) {
        setIsFormValid(true);
      }
    };

    const handleSelectVariableChange = (event: any, section: string) => {
      setFormData({
        ...formData,
        [section]: {
          ...formData?.[section],
          value: event?.value,
        },
      });
    };

    // handle the input text change
    const handleInputChange = (event: { target: { id: any; value: any } }, section: string) => {
      const { value } = event.target;
      setFormData({
        ...formData,
        [section]: {
          ...formData?.[section],
          value,
        },
      });
    };

    const handleSubmit = () => {
      handleContactDrawerSubmit(formData, nodeId);
    };

    // Expose the handleSubmit function to the parent component using useImperativeHandle
    useImperativeHandle(ref, () => ({
      handleSubmit,
    }));

    return (
      <div className="add-contact__container">
        <AddContactForm
          formData={formData}
          useSelectedValueType={useSelectedValueType}
          useSelectedVariableValue={useSelectedVariableValue}
          handleCheckChange={handleCheckChange}
          handleSelectChange={handleSelectChange}
          handleInputChange={handleInputChange}
          handleSelectVariableChange={handleSelectVariableChange}
          isFormValid={isFormValid}
        />
      </div>
    );
  },
);

AddContactDrawer.propTypes = {
  nodeId: PropTypes.string,
  isFormValid: PropTypes.bool,
  setIsFormValid: PropTypes.func,
};

export default AddContactDrawer;
