import * as React from 'react';
import PropTypes from 'prop-types';

function ChatIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color = '#475574"', width = 24, height = 24 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1877_206)">
        <path
          d="M9.63623 9.81836H18.3635"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.63623 14.1816H16.1817"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.5458 4.36377C21.4137 4.36377 22.2462 4.70857 22.8599 5.32233C23.4737 5.93608 23.8185 6.76852 23.8185 7.6365V16.3638C23.8185 17.2318 23.4737 18.0642 22.8599 18.6779C22.2462 19.2917 21.4137 19.6365 20.5458 19.6365H15.0912L9.63668 22.9092V19.6365H7.45486C6.58688 19.6365 5.75444 19.2917 5.14069 18.6779C4.52693 18.0642 4.18213 17.2318 4.18213 16.3638V7.6365C4.18213 6.76852 4.52693 5.93608 5.14069 5.32233C5.75444 4.70857 6.58688 4.36377 7.45486 4.36377H20.5458Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1877_206">
          <rect width="26.1818" height="26.1818" fill="white" transform="translate(0.90918)" />
        </clipPath>
      </defs>
    </svg>
  );
}
ChatIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default ChatIcon;
