/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-console */
import axios from 'axios';

import { getAccessToken, handleBrowserLogout } from 'utils/auth';

import { getBaseUrl } from './config';

const instance = axios.create({
  baseURL: getBaseUrl(),
  timeout: 60000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use((request) => {
  try {
    const token = getAccessToken();
    if (token) {
      request.headers.authorization = `Bearer ${token}`;
    }
  } catch (e) {
    console.log(e);
  }
  return request;
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    try {
      if (error.response) {
        const { status } = error.response;
        if (status === 401) {
          handleBrowserLogout();
          window.location.href = '/login';
          return Promise.resolve(true);
        }
      }
      return Promise.reject(error.response);
    } catch (e) {
      console.info(e);
      return Promise.reject(error);
    }
  },
);

export default instance;

export const postAttachments = (url: string, data: any, onUploadProgressHandler = () => {}) => {
  const cancelTokenSource = axios.CancelToken.source();
  return instance.post(url, data, {
    headers: { 'Content-type': 'multipart/form-data' },
    cancelToken: cancelTokenSource.token,
    onUploadProgress: onUploadProgressHandler,
  });
};
