/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { CustomSelect, InputText, PrimaryButton } from 'components';
import { getDefaultValueForSelect } from 'utils/helpers';
import { AddIcon, ArrowDown, Send } from 'components/Icons';
import ReactSelect from 'react-select';
import EmojiPicker from 'emoji-picker-react';
import Emoji from 'components/Icons/Emoji';

const dataTypes = [
  {
    value: 'string',
    label: 'String',
  },
  {
    value: 'number',
    label: 'Number',
  },
  {
    value: 'date',
    label: 'Date',
  },
];

const variables = [
  {
    value: 'name',
    label: 'name',
  },
];

const dropdownCustomStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    width: 72,
    height: 35,
    fontSize: '12px',
    fontWeight: '800',
  }),
};

export default function EditMessage({
  textRef,
  nodeName,
  setNodeName,
  handleVariable,
  isSelected,
}) {
  const [formData, setFormData] = useState<any>();
  const [replyButton, setReplyButton] = useState<any>();
  const [toggleVariable, setToggleVariable] = useState(false);
  const [toggleReply, setToggleReply] = useState(false);

  const [emojiVisible, setEmojiVisible] = useState(false);

  const selectedDefaultVariable = useMemo(
    () => getDefaultValueForSelect(formData?.variableName, variables, 'value'),
    [formData?.variableName],
  );
  const selectedDefaultDataType = useMemo(
    () => getDefaultValueForSelect(formData?.validateAs, dataTypes, 'value'),
    [formData?.validateAs],
  );

  // handle select field change
  const handleSelectChange = (event: { value: any }, id: any) => {
    setFormData({
      ...formData,
      [id]: event?.value,
    });
  };
  // handle text field change
  const handleChange = (event) => {
    const { id, value } = event.target;
    setReplyButton({
      ...formData,
      [id]: value,
    });
  };

  return (
    <div className="m-4 p-2 items-center">
      <label className="mr-4 font-bold text-base text-spaceCadetBlue">MESSAGE</label>
      <hr className="mt-2 border-2 border-spaceCadetBlue" />
      <div className="items-center mt-4 gap-2">
        <div className="relative">
          <textarea
            className="p-2 mt-2 border-2 rounded-md h-[75px] w-full outline-none"
            placeholder="Type your message"
            value={nodeName !== null ? nodeName.toString() : ''}
            onChange={(evt) => setNodeName(evt.target.value)}
            ref={textRef}
          />
          <div className="absolute top-2 right-10 mt-2">
            <CustomSelect
              id="validateAs"
              placeholder="{ }"
              customStyles={dropdownCustomStyles}
              options={variables}
              value={selectedDefaultVariable}
              onChange={(event) => handleSelectChange(event, 'validateAs')}
            />
          </div>
          <div className="absolute top-4 right-2 mt-2 z-10">
            <div onClick={() => setEmojiVisible(!emojiVisible)}>
              <Send color="#c2c2c2" />
              {/* <Emoji /> */}
            </div>
            {emojiVisible && <EmojiPicker />}
          </div>
        </div>
        <div className="text-center">
          <button
            type="button"
            className="p-3 border-2 border-dotted min-w-full text-gray-600 flex text-center items-center justify-center gap-2 cursor-pointer"
          >
            <AddIcon color="#000" />
            Add button
          </button>
        </div>
        <div className="flex mt-4 justify-between">
          <button
            className="bg-green-200 rounded-2xl px-4 py-2 text-sm font-semibold text-green-500 my-2"
            type="button"
          >
            Add Delay
          </button>
          <button
            className="bg-blue-200 rounded-2xl px-4 py-2 font-semibold text-sm text-blue-500 my-2"
            type="submit"
          >
            Quick replies
          </button>
        </div>
      </div>
      <div className="bg-white p-4 rounded-md mt-4">
        <div className="flex items-center gap-2 ">
          <input
            id="isResponse"
            type="checkbox"
            checked={formData?.isResponse}
            onClick={() => setToggleVariable(!toggleVariable)}
            className="toggle toggle-sm border-quickSilver"
          />
          <p className="font-semibold">Wait for User Reply</p>
        </div>
        {toggleVariable && (
          <div>
            <p className="mt-4 font-semibold">Applicable Validation</p>
            <div className="mt-4 text-base">
              <CustomSelect
                id="validateAs"
                placeholder="Validate as"
                options={dataTypes}
                value={selectedDefaultDataType}
                isClearable
                onChange={(event) => handleSelectChange(event, 'validateAs')}
              />
            </div>

            <p className="mt-4 font-semibold">Create variable</p>

            <div className="mt-4 text-base">
              <CustomSelect
                id="variableName"
                placeholder="Variable"
                options={variables}
                value={selectedDefaultVariable}
                isClearable
                onChange={(event) => handleSelectChange(event, 'variableName')}
              />
              <div className="mt-4">
                <PrimaryButton
                  text="Create variable"
                  btnColor="bg-spaceCadetBlue80"
                  btnHoverColor="hover:bg-spaceCadetBlue"
                  color="white"
                  handleOnClick={() => handleVariable(formData)}
                />
              </div>
              <CustomSelect
                id="variableName"
                placeholder="Variable"
                options={variables}
                value={selectedDefaultVariable}
                isClearable
                onChange={(event) => handleSelectChange(event, 'variableName')}
              />
              <div className="mt-2">
                <button
                  className="bg-blue-200 rounded-2xl px-4 py-2 text-sm text-blue-500 my-2"
                  type="submit"
                  onClick={() => setToggleReply(!toggleReply)}
                >
                  Quick replies
                </button>
                {toggleReply && (
                  <div className="mt-1 flex gap-2">
                    <input
                      id="replyButtonValue"
                      className="p-2 mt-2 border border-2 rounded-xl h-[48px]"
                      value={replyButton?.replyButtonValue}
                      onChange={(event) => handleChange(event.target.value)}
                    />
                    <PrimaryButton
                      btnColor="bg-spaceCadetBlue80"
                      btnHoverColor="hover:bg-spaceCadetBlue"
                      color="white"
                      handleOnClick={() => handleVariable(formData)}
                      icon={<ArrowDown />}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      {/* <div className="mt-4">
        <PrimaryButton
          text="Apply"
          btnColor="bg-green-500"
          color="white"
          btnHoverColor="bg-green-500"
        />
      </div> */}
    </div>
  );
}

EditMessage.propTypes = {
  textRef: PropTypes.any,
  nodeName: PropTypes.string,
  setNodeName: PropTypes.any,
  handleVariable: PropTypes.func,
  isSelected: PropTypes.bool,
};
