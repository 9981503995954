export enum SocketEvents {
  ChannelAssignedToConversation = 'ChannelAssignedToConversation',
  MessengerInstanceStatus = 'MessengerInstanceStatus',
}

export enum NODE_NAMES {
  MESSAGE = 'message',
  FLOW = 'flow',
  ACTION = 'action',
  API_REQUEST = 'apiRequest',
  FILTER = 'filter',
  CONTACT = 'contact',
  CASE = 'case',
  CAROUSEL = 'carousel',
  LIVE_CHAT = 'liveChat',
  END_CHAT = 'endChat',
}
