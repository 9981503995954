export enum UserType {
  SuperUser = 'superUser',
  SystemManagementUser = 'systemManagementUser',
  ClientUser = 'clientUser',
}

export const ALL_USERS = [
  { label: 'Super User', value: UserType.SuperUser },
  { label: 'Client Users', value: UserType.ClientUser },
  { label: 'System Management Users', value: UserType.SystemManagementUser },
];

export const USER_TYPES = [
  { label: 'All', value: 'all' },
  { label: 'Client Users', value: UserType.ClientUser },
  { label: 'System Management Users', value: UserType.SystemManagementUser },
];

export const USER_STATUS = [
  { label: 'Active', value: 'active' },
  { label: 'Unapproved', value: 'unapproved' },
  { label: 'Inactive', value: 'inactive' },
  { label: 'Deleted', value: 'deleted' },
  { label: 'Expired', value: 'expired' },
];

export enum UserAgentStatus {
  Online = 'online',
  Offline = 'offline',
  InBreak = 'inBreak',
}
