/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-daisyui';

import { CustomSelect, PrimaryButton } from 'components';
import { Confirm } from 'components/Icons';
import { getValueForSelect } from 'utils/helpers';

function AddUserModal(props) {
  const { show, toggleModal, handleUpdate, data, usersList, channelUsersList } = props;

  const { channelName } = data;

  const [formData, setFormData] = useState<any>({});

  const [modifiedUserList, setModifiedUserList] = useState<any>(usersList);

  useEffect(() => {
    if (usersList.length > 0) {
      const uniqueArr = usersList.filter(
        (obj2) => !channelUsersList.docs.some((value) => value._id === obj2.value),
      );
      setModifiedUserList(uniqueArr);
    } else {
      setModifiedUserList([]);
    }
  }, [usersList]);

  // handle the dropdown change
  const handleSelectChange = (event: any, id: string) => {
    setFormData({
      ...formData,
      [id]: event?.value,
    });
  };

  return (
    <Modal
      open={show}
      className="top-0 left-0 right-0 p-0 border border-gainsboroBlue rounded-lg z-50 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(auto-1rem)] max-w-3xl max-h-full"
    >
      <Modal.Header className="font-bold pb-2 mb-2 border-b p-4">
        <div className="flex justify-between items-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 w-fit flex-wrap items-center">
            <div className="text-base font-bold font-ptScanCap">Add a User to - {channelName}</div>
          </div>
          <div className="text-sm font-normal bg-red">
            <Button
              size="xs"
              shape="circle"
              className="btn btn-xs btn-circle border-2 bg-white border-rasinBlack font-bold"
              onClick={toggleModal}
            >
              x
            </Button>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="pl-6 justify-center items-center">
          <div className="p-4 grid grid-cols-1">
            <span className="text-sm font-normal font-lato pb-3 pl-1">Select a User</span>
            <CustomSelect
              id="userId"
              required
              options={modifiedUserList}
              isClearable
              value={getValueForSelect(formData?.userId, usersList)}
              onChange={(event) => handleSelectChange(event, 'userId')}
            />
          </div>
          <div className="p-4 -mt-4 mb-5 grid grid-cols-1">
            <span className="text-sm font-normal font-lato pb-3 pl-1">Select the Role</span>
            <CustomSelect
              id="role"
              options={[]} // !TODO Add Roles List
              isClearable
              value={getValueForSelect(formData?.roles, [])}
              onChange={(event) => handleSelectChange(event, 'roles')}
            />
          </div>
        </div>

        <div className="w-full pt-5 pb-5 mt-3 bg-culturedGray flex flex-wrap justify-center items-center gap-3">
          <PrimaryButton
            text="Confirm"
            btnColor="bg-spaceCadetBlue80"
            btnHoverColor="hover:bg-spaceCadetBlue"
            color="white"
            icon={<Confirm />}
            radius="2xl"
            handleOnClick={() => handleUpdate(formData)}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

AddUserModal.propTypes = {
  show: PropTypes.bool,
  usersList: PropTypes.array,
  toggleModal: PropTypes.func,
  handleUpdate: PropTypes.func,
  data: PropTypes.any,
  channelUsersList: PropTypes.object,
};

export default AddUserModal;
