import * as React from 'react';
import PropTypes from 'prop-types';

function Forward(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color = 'white', width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <g
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        clipPath="url(#clip0_695_114)"
      >
        <path d="M4 21V8a3 3 0 013-3h10a3 3 0 013 3v6a3 3 0 01-3 3H8l-4 4z" />
        <path d="M13 9l2 2-2 2M15 11H9" />
      </g>
      <defs>
        <clipPath id="clip0_695_114">
          <path fill="#fff" d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
Forward.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Forward;
