import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import CHATBOT_LOGO from 'assets/images/Logo_XL 1.png';
import { apiErrorHandler } from 'api/handler';
import { setupNewPasswordService } from 'services/auth.service';
import { Confirm, Hide, Show } from 'components/Icons';
import Password from 'components/Icons/Password';

function SetupPasswordPage() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const userId = searchParams.get('i');

  const [formData, setFormData] = useState({ confirmPassword: '', password: '' });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [alertMessage, setAlertMessage] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    // Hide the alert when the user updates any property
    setAlertMessage(null);
  };

  const togglePasswordVisibility = (field: string) => {
    if (field === 'password') {
      setShowPassword(!showPassword);
    } else if (field === 'confirmPassword') {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      if (formData.confirmPassword !== formData.password) {
        setAlertMessage('Passwords do not match');
        return;
      }
      setSubmitLoading(true);
      const { data } = await setupNewPasswordService({ ...formData, userId });
      if (data) {
        if (data.status) {
          toast.success('Successfully updated password');
          setTimeout(() => {
            window.location.href = '/login';
          }, 1000);
        }
      }
    } catch (err) {
      const { message } = apiErrorHandler(err);
      if (message) {
        setAlertMessage(message);
      }
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <div className="grid grid-cols-1 rg:grid-cols-2 justify-between p-4 h-screen bg-gradient-to-b from-white via-gray-100 to-gray-200 items-center font-lato">
      <div className="left_side-component hidden rg:block">
        <p>{}</p>
      </div>
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        className="p-8 mx-auto rounded-lg items-center bg-white shadow-xl w-full max-w-md relative" // Added relative class
      >
        <div className="mb-6 flex justify-center">
          <img src={CHATBOT_LOGO} width={180} alt="chatbot-logo" />
        </div>
        <h2 className="text-xl text-spaceCadetBlue font-bold mb-6">Setup New Password</h2>
        <form onSubmit={handleSubmit}>
          <div className="my-4">
            <div className="relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2 mt-1">
                <svg className="h-5 w-5 mb-2 fill-slate-300" viewBox="0 0 21 22">
                  <Password color="#192A51" />
                </svg>
              </span>
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="placeholder:text-independenceBlue border rounded-xl py-3 pl-9 pr-3 focus:outline-none w-full"
                placeholder="New Password"
                required
              />
              <button
                type="button"
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-blue-dark"
                onClick={() => togglePasswordVisibility('password')}
              >
                {showPassword ? <Show /> : <Hide />}
              </button>
            </div>
          </div>
          <div className="my-4">
            <div className="relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2 mt-1">
                <svg className="h-5 w-5 mb-2 fill-slate-300" viewBox="0 0 21 22">
                  <Password color="#192A51" />
                </svg>
              </span>
              <input
                type={showConfirmPassword ? 'text' : 'password'}
                id="confirmPassword"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                className="placeholder:text-independenceBlue border rounded-xl py-3 pl-9 pr-3 focus:outline-none w-full"
                placeholder="Retype Password"
                required
              />
              <button
                type="button"
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-blue-dark"
                onClick={() => togglePasswordVisibility('confirmPassword')}
              >
                {showConfirmPassword ? <Show /> : <Hide />}
              </button>
            </div>
          </div>
          {alertMessage && (
            <div className="w-full text-red-500 text-center mb-3">{alertMessage}</div>
          )}
          <div className="mb-4">
            <button
              type="submit"
              className={`bg-blue-dark flex justify-center items-center text-white py-3 px-4 rounded-2xl w-full hover:opacity-70 transition-all duration-300 ${
                submitLoading ? 'cursor-not-allowed opacity-70' : ''
              }`}
              disabled={submitLoading}
            >
              {submitLoading ? (
                <div className="flex items-center justify-center">
                  <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white mr-2" />
                  Loading...
                </div>
              ) : (
                <>
                  <Confirm />
                  <p className="pl-2 text-sm font-semibold">Confirm</p>
                </>
              )}
            </button>

            <p className="text-center text-sm mt-6">© 2023 TASA Solutions</p>
          </div>
        </form>
      </motion.div>
    </div>
  );
}

export default SetupPasswordPage;
