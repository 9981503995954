export const defaultTableHeaders = [
  {
    key: 'sequenceId',
    value: 'Chat Id',
  },
  {
    key: '',
    value: 'Preview',
    type: 'previewButton',
  },
  {
    key: 'status',
    value: 'Status',
    type: 'statusLabel',
    params: {
      open: {
        label: 'OPEN',
        color: '#262525',
        bgColor: '#FBFBFB',
        borderColor: '#D9DEE8',
      },
    },
  },
  {
    key: 'subject',
    value: 'Subject',
  },
  {
    key: 'contactName',
    value: 'Contact',
  },
  {
    key: 'location',
    value: 'Location',
  },
  {
    key: 'createdAt',
    value: 'Started At',
    type: 'dateTime',
  },
  {
    key: 'lastMessageAt',
    value: 'Last Updated',
    type: 'dateTime',
  },
  {
    key: 'manage',
    value: 'Manage',
    type: 'TakeChatBtn',
    params: {
      buttonLabel: 'View',
      customActionHandlerName: 'displayModal',
    },
  },
];

export const tableDefaultValues = {
  totalDocs: 0,
  totalPages: 0,
  limit: 10,
  page: 1,
  headers: defaultTableHeaders,
  docs: [],
  sort: null,
  order: -1,
  searchBy: null,
};

export enum PromptTypes {
  TakeChat = 'TakeChat',
}
