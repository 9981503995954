/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';

function TabsSection(props) {
  const { activeTab, handleTabClick } = props;
  return (
    <div className="tabs">
      <a
        className={`tab tab-bordered w-[126px] hover:bg-brightGray hover:border-spaceCadetBlue bg-aliceBlue font-bold rounded-tl-lg rounded-tr-lg ${
          activeTab === 'Conversations'
            ? 'text-spaceCadetBlue bg-brightGray border-spaceCadetBlue'
            : ''
        }`}
        onClick={() => handleTabClick('Conversations')}
      >
        Conversations
      </a>
      <a
        className={`tab tab-bordered w-[126px] hover:bg-brightGray hover:border-spaceCadetBlue bg-aliceBlue font-bold rounded-tl-lg rounded-tr-lg ml-[1px] ${
          activeTab === 'Notes' ? 'text-spaceCadetBlue bg-brightGray border-spaceCadetBlue' : ''
        }`}
        onClick={() => handleTabClick('Notes')}
      >
        Notes
      </a>
      <a
        className={`tab tab-bordered w-[126px] hover:bg-brightGray hover:border-spaceCadetBlue bg-aliceBlue font-bold rounded-tl-lg rounded-tr-lg ml-[1px] ${
          activeTab === 'Transactions'
            ? 'text-spaceCadetBlue bg-brightGray border-spaceCadetBlue'
            : ''
        }`}
        onClick={() => handleTabClick('Transactions')}
      >
        Transactions
      </a>
    </div>
  );
}

TabsSection.propTypes = {
  activeTab: PropTypes.string,
  handleTabClick: PropTypes.func,
};

export default TabsSection;
