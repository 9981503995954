import instance from 'api';
import { CONVERSATIONS } from 'api/apiEndpoints';
import UrlBuilder from 'api/urlQueryBuilder';
import _ from 'lodash';
import { ICompleteConversation } from 'views/Inbox/Chat/interface';

export const fetchConversationsList = (params: any) => {
  const {
    page = 1,
    limit = 100,
    sort = 'createdAt',
    order = -1,
    country,
    status,
    searchBy,
    select,
    caseId,
    contactId,
    avoidAlreadyLinkedToCase,
    searchText,
    fromContactView,
    fromCases,
    fromLinkUnlink,
  } = params;

  // creates a new object with only the non-null, non-undefined values from the extracted variables
  const queryParams = _.pickBy({
    page,
    limit,
    sort,
    order,
    country,
    status,
    searchBy,
    searchText,
    select,
    caseId,
    contactId,
    avoidAlreadyLinkedToCase,
    fromContactView,
    fromCases,
    fromLinkUnlink,
  });
  const url = UrlBuilder()
    .setUrl(CONVERSATIONS.GET_CONVERSATION_LIST)
    .setQueryParams({ ...queryParams })
    .build();
  return instance.get(url);
};

export const fetchConversationsListOfBotChats = (params: any) => {
  const {
    page = 1,
    limit = 100,
    sort = 'createdAt',
    order = -1,
    contactId,
    searchBy,
    select,
  } = params;

  // creates a new object with only the non-null, non-undefined values from the extracted variables
  const queryParams = _.pickBy({
    page,
    limit,
    sort,
    order,
    contactId,
    searchBy,
    select,
  });
  const url = UrlBuilder()
    .setUrl(CONVERSATIONS.GET_CONVERSATION_LIST_BOT_CHATS)
    .setQueryParams({ ...queryParams })
    .build();
  return instance.get(url);
};
// Fetch bot chats Preview Data
export const fetchPreviewDataOfBotChatsById = (id: string) => {
  const url = UrlBuilder()
    .setUrl(CONVERSATIONS.GET_PREVIEW_DATA_BOT_CHATS_BY_ID)
    .setPathParams({ id })
    .build();
  return instance.get(url);
};

export const fetchConversationsCount = () => {
  const url = UrlBuilder().setUrl(CONVERSATIONS.GET_CONVERSATION_COUNT).build();
  return instance.get(url);
};

export async function updateConversation(id: string, data: any) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(CONVERSATIONS.UPDATE_CONVERSATION).setPathParams({ id }).build();
  return instance.patch(url, data);
}

export const fetchCompletedConversationsList = (params: any) => {
  const {
    page = 1,
    limit = 10,
    sort = 'createdAt',
    order = -1,
    contactId,
    messages,
    channel,
    contact,
    agent,
    customerRating,
    aiRating,
    completedAt,
  } = params;

  // creates a new object with only the non-null, non-undefined values from the extracted variables
  const queryParams = _.pickBy({
    page,
    limit,
    sort,
    order,
    contactId,
    messages,
    channel,
    contact,
    agent,
    customerRating,
    aiRating,
    completedAt,
  });
  const url = UrlBuilder()
    .setUrl(CONVERSATIONS.GET_COMPLETED_CONVERSATION_LIST)
    .setQueryParams({ ...queryParams })
    .build();
  return instance.get(url);
};

export async function takeChat(id: string) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(CONVERSATIONS.TAKE_CHAT).setPathParams({ id }).build();
  return instance.patch(url);
}

export async function completeConversation(id: string, data: ICompleteConversation) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(CONVERSATIONS.COMPLETE_CONVERSATION).setPathParams({ id }).build();
  return instance.patch(url, data);
}

export async function addContactToConversationService(id: string, data: any) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder
    .setUrl(CONVERSATIONS.ADD_CONTACT_TO_CONVERSATION)
    .setPathParams({ id })
    .build();
  return instance.patch(url, data);
}

export async function getConversationService(id: string) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(CONVERSATIONS.GET_CONVERSATION_BY_ID).setPathParams({ id }).build();
  return instance.get(url);
}

/**
 * Get Conversation Data by Id
 *
 * @param {string} id - Conversation Id
 * @returns conversation data
 */
export async function fetchConversationDataById(id: string) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder
    .setUrl(CONVERSATIONS.GET_CONVERSATION_DATA_BY_ID)
    .setPathParams({ id })
    .build();
  return instance.get(url);
}
