import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { selectCustomStylesForFilters } from 'styles/select';
import { CustomSelect, CustomTable, PrimaryButton } from 'components';
import { AddCustomer, Export, Search } from 'components/Icons';
import { LAST_UPDATED_AT } from 'constants/contact.const';
import { PATHS } from 'routes/paths';

import { IContact } from './interface';
import countriesData from '../../../assets/json/countries_meta.json';
import ContactView from '../components/ContactView/ContactView';
import AddNoteCard from '../components/NoteCard/AddNoteCard';

const countries = countriesData.map((country) => ({ value: country.code, label: country.name }));

function ListPage(props) {
  const {
    contactListData,
    handleEditUser,
    handleFilterBy,
    handlePageChange,
    handleResultsPerPageChange,
    urlSearchParams,
    getCountrySelected,
    getStatusSelected,
    handleColumnSorting,
    handleExport,
  } = props;

  // show preview model
  const [visible, setVisible] = useState(false);

  // data for preview model
  const [contactData, setContactData] = useState<IContact>();

  /**
   * Handles the preview contact model visibility and data
   * @param {IContact} data - Contact data from clicked row
   */
  const showPreview = (data: IContact) => {
    setVisible(!visible);
    setContactData(data);
  };

  return (
    <div className="page">
      <div className="flex justify-between">
        {/* Add Client Button */}
        <div className="mb-4">
          <Link to={PATHS.CONTACT.FORM.url}>
            <PrimaryButton
              text="Add Contact"
              btnColor="bg-spaceCadetBlue80"
              color="white"
              width="[140px]"
              radius="2xl"
              btnHoverColor="hover:bg-spaceCadetBlue"
              icon={<AddCustomer />}
            />
          </Link>
        </div>
        <div className="flex gap-2">
          {/* Import Button */}
          <div className="mt-4 sm:mt-0">
            <PrimaryButton
              text="Import"
              btnColor="bg-spaceCadetBlue80"
              color="white"
              width="[140px]"
              radius="2xl"
              btnHoverColor="hover:bg-spaceCadetBlue"
              icon={<Export />}
            />
          </div>
          {/* Export Button */}
          <div className="mt-4 sm:mt-0">
            <PrimaryButton
              text="Export"
              btnColor="bg-spaceCadetBlue80"
              color="white"
              width="[140px]"
              radius="2xl"
              btnHoverColor="hover:bg-spaceCadetBlue"
              icon={<Export />}
              handleOnClick={handleExport}
            />
          </div>
        </div>
      </div>
      <div className="border-t border-slate-300 " />

      {/* Filter and Export Section */}
      <div className="container mt-4 py-4">
        <form className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          {/* Search Input */}
          <div className="mb-2">
            <div className="relative block sm:flex items-center mb-2 sm:mb-0">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2 mt-1">
                <svg className="h-5 w-5 mb-2 fill-slate-300" viewBox="0 0 20 20">
                  <Search color="#475574" />
                </svg>
              </span>
              <input
                className="sm:placeholder:text-sm placeholder:text-independenceBlue font-lato text-sm block bg-white border border-gainsboroBlue rounded-lg h-11 py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-[#2684ff] sm:text-sm w-full rounded-xl"
                placeholder="Search by Contact Name, Email or Phone Number"
                type="text"
                name="search"
                onChange={(event) => handleFilterBy(event.target.value, 'searchBy')}
                value={urlSearchParams?.searchBy}
              />
            </div>
          </div>

          {/* Countries Select */}
          <div className="mr-2 col-span-1 mb-4 srg:mt-0">
            <CustomSelect
              placeholder="Select a Country"
              customStyles={selectCustomStylesForFilters}
              options={countries}
              value={getCountrySelected(urlSearchParams?.country)}
              isClearable
              onChange={(selected) => {
                const status = selected?.value?.toLowerCase();
                handleFilterBy(status, 'country');
              }}
            />
          </div>

          {/* Last Updated at Select */}
          <div className="mr-2 col-span-1 mb-4 md:mt-0">
            <CustomSelect
              placeholder="Last Updated at"
              customStyles={selectCustomStylesForFilters}
              options={LAST_UPDATED_AT}
              value={getStatusSelected(urlSearchParams?.lastUpdatedAt)}
              isClearable
              onChange={(selected) => {
                const status = selected?.value?.toLowerCase();
                handleFilterBy(status, 'lastUpdatedAt');
              }}
            />
          </div>
        </form>
      </div>

      <CustomTable
        tableData={contactListData}
        onPageChange={handlePageChange}
        handleResultsPerPageChange={handleResultsPerPageChange}
        isPaginate
        onSort={handleColumnSorting}
        onPreviewAction={showPreview}
        onManage={handleEditUser}
      />

      {/* Preview contact model */}
      <ContactView
        visible={visible}
        setVisible={setVisible}
        toggleVisible={showPreview}
        contactDetails={contactData}
        handleEditUser={handleEditUser}
      />
    </div>
  );
}

ListPage.propTypes = {
  handleFilterBy: PropTypes.func,
  handlePageChange: PropTypes.func,
  handleEditUser: PropTypes.func,
  handleColumnSorting: PropTypes.func,
  handleResultsPerPageChange: PropTypes.func,
  getCountrySelected: PropTypes.func,
  getStatusSelected: PropTypes.func,
  handleExport: PropTypes.func,
  urlSearchParams: PropTypes.shape({
    page: PropTypes.any,
    country: PropTypes.string,
    sort: PropTypes.string,
    searchBy: PropTypes.string,
    lastUpdatedAt: PropTypes.string,
  }),
  contactListData: PropTypes.shape({
    page: PropTypes.number,
  }),
};

export default ListPage;
