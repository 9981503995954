import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'react-daisyui';
import { extractInitials } from 'utils/helpers';

function ClientAvatar(props) {
  const { imageUrl, value } = props;

  return (
    <div className="flex items-center">
      {imageUrl && <Avatar src={imageUrl} shape="circle" size="xs" />}
      {!imageUrl && (
        <Avatar
          borderColor="primary"
          color="primary"
          letters={extractInitials(value)}
          shape="circle"
          size="xs"
        />
      )}
      <span className="ml-2"> {value}</span>
    </div>
  );
}

ClientAvatar.propTypes = {
  imageUrl: PropTypes.string,
  value: PropTypes.string,
};

export default ClientAvatar;
