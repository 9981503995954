import * as React from 'react';
import PropTypes from 'prop-types';

function InfoIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color, width = 15, height = 15 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />

      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />

      <g id="SVGRepo_iconCarrier">
        {' '}
        <rect width="24" height="24" fill="white" />{' '}
        <circle
          cx="12"
          cy="12"
          r="9"
          stroke="#ff990a"
          strokeLinecap="round"
          strokeLinejoin="round"
        />{' '}
        <path d="M12 11V17" stroke="#ff990a" strokeLinecap="round" strokeLinejoin="round" />{' '}
        <path
          d="M11.75 8V7H12.25V8H11.75Z"
          stroke="#ff990a"
          strokeLinecap="round"
          strokeLinejoin="round"
        />{' '}
      </g>
    </svg>
  );
}
InfoIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default InfoIcon;
