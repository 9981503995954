import * as React from 'react';
import PropTypes from 'prop-types';

function CancelIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color, width = 18, height = 18 } = props;
  return (
    <svg
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform="matrix(-1, 0, 0, 1, 0, 0)"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
      <g id="SVGRepo_iconCarrier">
        {' '}
        <circle cx="12" cy="12" r="9" stroke="#b3b3b3" strokeWidth="2" />{' '}
        <path d="M18 18L6 6" stroke="#b3b3b3" strokeWidth="2" />{' '}
      </g>
    </svg>
  );
}
CancelIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default CancelIcon;
