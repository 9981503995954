import React from 'react';
import { Outlet } from 'react-router-dom';

import NavBar from 'layouts/VerticalLayout/NavBar';
import Sidebar from 'layouts/SideBar';
import AuthorizedView from 'views/Main/Authorized';

function PrivateLayout() {
  return (
    <div className="layout">
      <NavBar />
      <div className="main-container">
        <Sidebar />
        <main className="content">
          <AuthorizedView />
          <Outlet />
        </main>
      </div>
    </div>
  );
}

export default PrivateLayout;
