/* eslint-disable function-paren-newline */
/* eslint-disable no-plusplus */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'slices/';

import GeneralModal from 'components/modals/General';
import ApprovalModal from 'components/modals/Approval';
import SampleForm from 'components/Form/SampleForm';

// ReactFlow
import ReactFlow, {
  Background,
  Controls,
  applyEdgeChanges,
  applyNodeChanges,
  useNodesState,
  useEdgesState,
  addEdge,
  MarkerType,
  ReactFlowProvider,
} from 'reactflow';
import 'reactflow/dist/style.css';

import SideBar from './components/SideBar';
import Node from './components/CustomNode';
import ToolBar from './components/ToolBar';

const nodeTypes = { node: Node };

const initialNodes: any = [
  {
    id: '1',
    type: 'node',
    data: { heading: 'Start' },
    position: { x: 10, y: 10 },
  },
  {
    id: '2',
    type: 'node',
    data: { heading: 'Message', content: null },
    position: { x: 200, y: 100 },
  },
];

const initialEdges: any = [
  {
    id: 'e1-2',
    source: '1',
    target: '2',
    style: { strokeWidth: 2, stroke: '#3B82F6' },
    markerEnd: { type: 'arrowclosed', color: '#3B82F6' },
  },
];

const rfStyle = {
  backgroundColor: '#eaf9f9',
};

let id = 0;
const getId = () => `dndnode_${id++}`;

function TestComponentPage() {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);

  // current theme
  const [theme, setTheme] = useState(null);

  // ReactFlow
  const reactFlowWrapper = useRef(null);
  const textRef = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [nodes, setNodes, onNodesChange] = useNodesState<any>(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [selectedNode, setSelectedNode] = useState(null);
  const [isSelected, setIsSelected] = useState(false);

  const [variable, setVariable] = useState<any>();
  const [nodeName, setNodeName] = useState('Node 1');

  // show preview drawer
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const node = nodes?.filter((nodeObj) => {
      if (nodeObj.selected) return true;
      return false;
    });
    if (node[0]) {
      setSelectedNode(node[0]);
      setIsSelected(true);
    } else {
      setSelectedNode('');
      setIsSelected(false);
    }
  }, [nodes]);

  useEffect(() => {
    setNodeName(selectedNode?.data?.content || null);
  }, [selectedNode]);

  useEffect(() => {
    textRef?.current?.focus();
  }, [selectedNode]);

  useEffect(() => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === selectedNode?.id) {
          node.data = {
            ...node.data,
            content: nodeName || ' ',
            isSelected,
          };
        }
        return node;
      }),
    );
  }, [nodeName, setNodes]);

  const onInit = (rFInstance) => setReactFlowInstance(rFInstance);

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  };

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData('application/reactflow');
      // check if the dropped element is valid
      if (typeof type === 'undefined' || !type) {
        return;
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      // const newNode = {
      //   id: getId(),
      //   type,
      //   position,
      //   data: { label: `${type} node` },
      // };
      const newNode = {
        id: (nodes.length + 1).toString(), // Generate a unique ID for the new node
        // id: getId(),
        type,
        data: {
          heading: 'Message',
          content: '',
          onDelete: handleDelete,
          onCopy: handleCopy,
        },
        position,
      };

      // setNodes((nds) => nds.concat(newNode));
      setNodes((prevNodes) => [...prevNodes, newNode]);
    },
    [reactFlowInstance],
  );

  const handleDelete = (nodeId) => {
    // Remove the current node from the nodes array
    setNodes((prevNodes) => prevNodes.filter((node) => node.id !== nodeId));
  };

  const handleCopy = (data, nodeId) => {
    // Create a copy of the current node
    const copyNode = {
      id: nodeId + 1, // Generate a unique ID for the new node
      type: 'node',
      data: {
        heading: data?.heading,
        content: data?.content,
        onDelete: handleDelete,
        onCopy: handleCopy,
      },
      position: { x: 100, y: 100 },
    };

    // Update the nodes array with the new node
    setNodes((prevNodes) => [...prevNodes, copyNode]);
  };

  const handleVariable = (data) => {
    setVariable(data);
    if (selectedNode) {
      selectedNode.data = {
        ...selectedNode.data,
        ...data,
      };
    }
    setNodes((prevNodes) => [...prevNodes, ...nodes, selectedNode]);
  };

  // Function to add a new node
  const addNewNode = (nodeType) => {
    const newNode = {
      id: (nodes.length + 1).toString(), // Generate a unique ID for the new node
      type: 'node',
      data: {
        heading: nodeType,
        content: null,
        onDelete: handleDelete,
        onCopy: handleCopy,
      },
      position: { x: 100, y: 100 }, // Set the initial position of the new node
    };

    // Update the nodes array with the new node
    setNodes((prevNodes) => [...prevNodes, newNode]);
  };

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge(
          {
            ...params,
            markerEnd: { type: 'arrowclosed', color: '#3B82F6' },
            style: { strokeWidth: 2, stroke: '#3B82F6' },
          },
          eds,
        ),
      ),
    [setEdges],
  );

  // sets the theme based on the user's preferred color scheme
  useEffect(() => {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      setTheme('light');
    } else {
      setTheme('light');
    }
  }, []);

  // updates the HTML document's class to reflect the selected theme (dark or light)
  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [theme]);

  const proOptions = { hideAttribution: true };

  /**
   * Close preview drawer
   */
  const closePreview = () => {
    setVisible(false);
    setIsSelected(false);
  };

  return (
    <div className="grid grid-cols-12 h-screen">
      <div className="grid col-span-1">
        <ToolBar addNewNode={addNewNode} />
      </div>
      <ReactFlowProvider>
        <div className="grid col-span-7">
          <div className="reactflow-wrapper" ref={reactFlowWrapper}>
            <ReactFlow
              nodes={nodes}
              edges={edges}
              nodeTypes={nodeTypes}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              onInit={onInit}
              onDrop={onDrop}
              onDragOver={onDragOver}
              attributionPosition="top-right"
              proOptions={proOptions}
              style={rfStyle}
              // fitView
            >
              <Controls />
            </ReactFlow>
          </div>
        </div>
        <div className="">
          <SideBar
            isSelected={isSelected}
            textRef={textRef}
            nodeName={nodeName}
            setNodeName={setNodeName}
            handleVariable={handleVariable}
            visible={visible}
            toggleHide={closePreview}
            selectedNode={selectedNode}
          />
        </div>
      </ReactFlowProvider>
    </div>
  );
}

export default TestComponentPage;
