/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { CustomSelect, InputText } from 'components';
import { reactSelectCustomStyles } from 'styles/select';
import { getDefaultValueForVariableSelect, getValueForSelect } from 'utils/helpers';
import { buildVariableDropdownOptions } from 'views/Settings/FlowBuilder/utils';
import { Severities, categories, statusOptions } from 'views/CaseManagement/Form/constants';
import { useSelector } from 'react-redux';
import { RootState } from 'slices';
import VariableDropdown from '../../../VariableDropdown';
import { caseValueTypes, caseValueWithFormFieldTypes } from '../constants';
import { groupedOptions } from '../../../constants';

function FormField({
  label,
  id,
  isFieldEnabled,
  valueType,
  value,
  options,
  formData,
  handlers,
  requiredField,
}) {
  const {
    useSelectedValueType,
    useSelectedVariableValue,
    handleCheckChange,
    handleSelectChange,
    handleInputChange,
    handleSelectVariableChange,
  } = handlers;

  const flowData = useSelector((state: RootState) => state.flow);

  return (
    <div className="flow-contact__form-field">
      <div className="form-field__row grid grid-cols-12">
        <span className="font-lato col-span-4 pt-3 w-[130px]">
          <span>{label}</span>
          {requiredField && <span className="text-red-600 pl-1">*</span>}
          {/* {`${label}${requiredField ? ' *' : ''}`} */}
        </span>
        {/* <input
          id={`is${id}`}
          type="checkbox"
          checked={isFieldEnabled}
          className="checkbox col-span-1"
          onChange={(event) => handleCheckChange(event, id)}
        /> */}

        <div className="col-span-4 mr-2">
          <CustomSelect
            id={`${id}ValueType`}
            placeholder=""
            required={
              id === 'contact' ||
              id === 'primaryContactNo' ||
              id === 'status' ||
              id === 'category' ||
              id === 'subject'
            }
            // isDisabled={!isFieldEnabled}
            options={options}
            value={useSelectedValueType(
              formData?.[id]?.valueType,
              id === 'category' || id === 'severity' || id === 'status'
                ? caseValueWithFormFieldTypes
                : caseValueTypes,
              'value',
            )}
            onChange={(event) => handleSelectChange(event, id, `${id}ValueType`)}
          />
        </div>
        <div className="col-span-4">
          {valueType === 'customText' ? (
            <InputText
              type="text"
              required={
                id === 'contact' ||
                id === 'primaryContactNo' ||
                id === 'status' ||
                id === 'category' ||
                id === 'subject'
              }
              elementId={id}
              // disabled={!isFieldEnabled}
              value={value || ''}
              onChange={(e) => handleInputChange(e, id)}
            />
          ) : valueType === 'formField' ? (
            <CustomSelect
              id={id}
              placeholder=""
              required={
                id === 'contact' ||
                id === 'primaryContactNo' ||
                id === 'status' ||
                id === 'category' ||
                id === 'subject'
              }
              options={
                id === 'category'
                  ? categories
                  : id === 'severity'
                  ? Severities
                  : id === 'status'
                  ? statusOptions
                  : null
              }
              customStyles={reactSelectCustomStyles}
              value={getValueForSelect(
                formData?.[id].value,
                id === 'category'
                  ? categories
                  : id === 'severity'
                  ? Severities
                  : id === 'status'
                  ? statusOptions
                  : null,
              )}
              onChange={(event) => handleSelectVariableChange(event, id, id)}
            />
          ) : (
            <VariableDropdown
              id={id}
              placeholder=""
              required={
                id === 'contact' ||
                id === 'primaryContactNo' ||
                id === 'status' ||
                id === 'category' ||
                id === 'subject'
              }
              variablesOnly
              options={buildVariableDropdownOptions(flowData?.variables || [])}
              // isDisabled={!isFieldEnabled}
              customStyles={reactSelectCustomStyles}
              value={getDefaultValueForVariableSelect(
                formData?.[id]?.value,
                groupedOptions,
                'value',
              )}
              onChange={(event) => handleSelectVariableChange(event, id, id)}
            />
          )}
        </div>
      </div>
    </div>
  );
}

FormField.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isFieldEnabled: PropTypes.bool.isRequired,
  valueType: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  options: PropTypes.array.isRequired,
  formData: PropTypes.any,
  handlers: PropTypes.any,

  useSelectedValueType: PropTypes.func,
  useSelectedVariableValue: PropTypes.func,
  handleCheckChange: PropTypes.func.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleSelectVariableChange: PropTypes.func.isRequired,
  requiredField: PropTypes.bool,
};

export default FormField;
