import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Handle, Position } from 'reactflow';

import { useSelector } from 'react-redux';
import { RootState } from 'slices';
import { NODE_TYPES, nodeStyle } from '../constants';

function EndChatNode(props) {
  const { data, selected, id } = props;

  const flowData = useSelector((state: RootState) => state?.flow);

  // tracks the validity of the node and highlight invalid nodes
  const [isNodeValid, setIsNodeValid] = useState(true);
  const [nodeErrors, setNodeErrors] = useState<any>();

  useEffect(() => {
    // Find the flow object in the flowData array based on nodeId
    const flowObj = flowData.nodeConfigs.find((node) => node.nodeId === id);
    if (flowObj) {
      if (flowObj?.isValid !== undefined && flowObj?.isValid === false) {
        setIsNodeValid(false);
        setNodeErrors(flowObj?.errors);
      } else {
        setIsNodeValid(true);
        setNodeErrors([]);
      }
    }
  }, [flowData]);

  return (
    <div
      className={`bg-white p-1 rounded-md w-[250px] drop-shadow-lg opacity-80 ${
        selected ? 'border-2 border-[#D81B60]' : ''
      } ${isNodeValid ? '' : 'border-2 border-red-500'} `}
    >
      <div>
        <div className="bg-pink-300 rounded-md px-2 py-1 text-center text-pink-600 font-semibold">
          {NODE_TYPES[data?.heading]?.label}
        </div>

        <div className="flow-node__content p-2 justify-center text-center">
          <span className="text-gray-500">End Conversation</span>
        </div>
      </div>
      <div className="p-1 text-gainsboroBlue text-right" />
      <Handle
        type="target"
        position={Position.Left}
        id={`end-chat-start-${id}`}
        className="!bg-blue-500"
        style={Position.Left && { ...nodeStyle, top: 18, left: -10 }}
      />
    </div>
  );
}

EndChatNode.propTypes = {
  data: PropTypes.any,
  id: PropTypes.any,
  selected: PropTypes.bool,
};

export default EndChatNode;
