import { v4 as uuidv4 } from 'uuid';

import { ICondition, TConditionTypeValue, TSegmentationMethodValue } from '../interfaces';

export const selectConditionOptions = [
  { value: 'contains', label: 'contains' },
  { value: 'doesNotContain', label: 'does not contain' },
  { value: 'equals', label: 'equals' },
  { value: 'doesntEquals', label: "doesn't equals" },
  { value: 'startsWith', label: 'starts with' },
  { value: 'endsWith', label: 'ends with' },
  { value: 'empty', label: 'empty' },
  { value: 'notEmpty', label: 'not empty' },
];

export const CONDITION_TYPES: { [key: string]: TConditionTypeValue } = {
  IF: 'if',
  NOT: 'not',
};

export const SEGMENTATION_METHODS: { [key: string]: TSegmentationMethodValue } = {
  ANY: 'or',
  AND: 'and',
};

export const defaultConditionList: ICondition[] = [
  {
    _id: uuidv4(),
    conditionType: CONDITION_TYPES.IF,
    segmentationMethod: 'or',
    segments: [
      {
        _id: uuidv4(),
        criteria: 'email',
        condition: 'contains',
        textPhrase: '@',
      },
      {
        _id: uuidv4(),
        criteria: 'email',
        condition: 'contains',
        textPhrase: 'gmail',
      },
    ],
  },
  {
    _id: uuidv4(),
    conditionType: CONDITION_TYPES.NOT,
    segmentationMethod: 'or',
    segments: [],
  },
];

export const segmentStyles = {
  criteriaStyles: {
    menuPortal: (provided) => ({ ...provided, zIndex: 99999 }),
    control: (provided: any) => ({
      ...provided,
      height: '48px',
      width: '100px',
      borderRadius: '10px',
      fontSize: '13px',
    }),
    option: (provided: any) => ({
      ...provided,
      fontSize: '14px',
    }),
    menu: (provided: any) => ({
      ...provided,
      width: '220px',
      overflow: 'auto',
      zIndex: 99999,
    }),
    menuList: (provided: any) => ({
      ...provided,
      scrollbarWidth: 'thin',
      scrollbarColor: 'var(--scrollbar-thumb-color) var(--scrollbar-track-color)',
      '&::-webkit-scrollbar': {
        width: '4px', // Width of the vertical scrollbar
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'var(--scrollbar-thumb-color)',
        borderRadius: '10px',
      },
      '--scrollbar-thumb-color': 'rgba(25, 42, 81, 0.7)',
      '--scrollbar-track-color': '#f0f0f0',
    }),
  },
  conditionStyles: {
    control: (provided: any) => ({
      ...provided,
      height: '48px',
      width: '100px',
      borderRadius: '10px',
    }),
    menu: (provided: any) => ({
      ...provided,
      width: '220px',
    }),
  },
};
