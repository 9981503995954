/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { apiErrorHandler } from 'api/handler';
import { authLogin } from 'services/auth.service';
import { loginAction } from 'slices/auth';
import { storeAccessToken } from 'utils/auth';
import { LoginResponse } from 'interfaces/auth.interface';
import { encryptedLocalStorage } from 'utils/local-storage';
import { LG_PATH, USER_NODE, USER_PERMISSIONS } from 'constants/local-storage.const';
import { PATHS } from 'routes/paths';
import { Confirm, Hide, Password, Show, ViewContact } from 'components/Icons';
import CHATBOT_LOGO from 'assets/images/Logo_XL 1.png';
import { RootState } from 'slices';

function LoginPage() {
  const dispatch = useDispatch();

  // get location data
  const locationData = useSelector((state: RootState) => state.user.locationData);

  /**
   * Get the path that user was stayed when session timeout was happened
   * @returns {string} The path.
   */
  const storedPathname = encryptedLocalStorage.getItem(LG_PATH);

  const [formData, setFormData] = useState({ email: '', password: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Hide the alert when the user updates any property
    setAlertMessage(null);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);

    try {
      const payload = { ...formData, locationData }; // passing locationData
      const { data }: { data: LoginResponse } = await authLogin(payload);
      if (data && data.node) {
        const { authToken, user, firstLogin, isPasswordReset } = data.node;

        const { roles, permissions = [], name, _id } = data.node.user;
        if (firstLogin || isPasswordReset) {
          window.location.href = `${PATHS.AUTH.SETUP_PASSWORD.url}/?i=${_id}`;
          return;
        }
        if (storedPathname) {
          // Redirect the user to the last visited page before the session expired.
          window.location.href = storedPathname;

          encryptedLocalStorage.removeItem(LG_PATH);
        }
        dispatch(loginAction({ user: { name, _id }, permissions, roles }));
        storeAccessToken(authToken);
        encryptedLocalStorage.setItem(USER_PERMISSIONS, permissions);
        encryptedLocalStorage.setItem(USER_NODE, user);
      } else {
        toast.error('Something went wrong, Please try again');
      }
    } catch (err) {
      const { message } = apiErrorHandler(err);
      if (message) {
        setAlertMessage(message);
      }
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <div className="public_page">
      <div className="grid grid-cols-1 rg:grid-cols-2 justify-between p-4 bg-gradient-to-b from-white via-gray-100 to-gray-200 items-center font-lato h-full">
        <div className="left_side-component hidden rg:flex justify-center">
          <motion.img
            initial={{ opacity: 0, x: -200 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0 }}
            transition={{ delay: 1, duration: 0.3 }}
            src="https://messenger-assets-public.s3.ap-southeast-1.amazonaws.com/portal-client/chatbot-login-wp.png"
            alt="Chatbot"
            className="max-w-sm sm:max-w-md lg:max-w-lg"
          />
        </div>
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          className="p-8 mx-auto rounded-2xl items-center bg-white shadow-xl w-full max-w-md relative" // Added relative class
        >
          <div className="mb-6 flex justify-center">
            <img src={CHATBOT_LOGO} width={180} alt="chatbot-logo" />
          </div>
          <h2 className="text-xl text-spaceCadetBlue font-bold mb-6">Login</h2>
          <form onSubmit={handleSubmit}>
            <div className="relative block sm:flex items-center mb-2 sm:mb-0">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2 mt-1">
                <svg className="h-5 w-5 mb-2 fill-slate-300" viewBox="0 0 21 22">
                  <ViewContact color="#192A51" />
                </svg>
              </span>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="placeholder:text-independenceBlue border rounded-xl py-3 pl-9 pr-3 focus:outline-none w-full"
                placeholder="Email"
                required
              />
            </div>
            <div className="my-4">
              <div className="relative">
                <span className="absolute inset-y-0 left-0 flex items-center pl-2 mt-1">
                  <svg className="h-5 w-5 mb-2 fill-slate-300" viewBox="0 0 21 22">
                    <Password color="#192A51" />
                  </svg>
                </span>
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  className="placeholder:text-independenceBlue border rounded-xl py-3 pl-9 pr-3 focus:outline-none w-full"
                  placeholder="Password"
                  required
                />
                {formData.password.length > 0 && (
                  <button
                    type="button"
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-blue-dark"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <Show /> : <Hide />}
                  </button>
                )}
              </div>
              <Link to={PATHS.AUTH.RESET_PASSWORD.url}>
                <p className="text-independenceBlue text-sm mt-4 mb-4 pl-2">
                  Forgot Password? Reset your password here.
                </p>
              </Link>
            </div>
            {alertMessage && (
              <div className="w-full text-red-500 text-center mb-3">{alertMessage}</div>
            )}
            <div className="mb-4">
              <button
                id="loginBtn"
                type="submit"
                className={`bg-blue-dark flex justify-center items-center text-white py-3 px-4 rounded-2xl w-full hover:opacity-70 transition-all duration-300 ${
                  submitLoading ? 'cursor-not-allowed opacity-70' : ''
                }`}
                disabled={submitLoading}
              >
                {submitLoading ? (
                  <div className="flex items-center justify-center">
                    <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white mr-2" />
                    Loading...
                  </div>
                ) : (
                  <>
                    <Confirm />
                    <p className="pl-2 text-sm font-semibold">Login</p>
                  </>
                )}
              </button>

              <p className="text-center text-sm mt-6">© 2023 TASA Solutions</p>
            </div>
          </form>
        </motion.div>
      </div>
    </div>
  );
}

export default LoginPage;
