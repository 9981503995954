/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useRef, useState } from 'react';

import { SOCKET_EVENTS } from 'constants/events';
import { useSocket } from 'contexts/socket';
import { SocketEvents } from 'enums/socket.enum';

export default function useSocketEvent() {
  const _isMounted = useRef(true);

  const socket = useSocket();

  const [event, setEvent] = useState<{ type: SocketEvents; data: any }>({
    type: null,
    data: null,
  });

  useEffect(
    () => () => {
      _isMounted.current = false;
    },
    [],
  );

  useEffect(() => {
    if (socket) {
      socket.on(SOCKET_EVENTS.SERVER.CHANNELS.CHANNEL_ASSIGNED_TO_CONVERSATION, (res: any) => {
        const { node } = res;
        if (node && _isMounted.current) {
          const { eventType, data } = node;
          setEvent({ type: eventType, data });
        }
      });
    }
  }, [socket]);

  return { event };
}
