import React from 'react';
import { Button, Modal } from 'react-daisyui';
import PropTypes from 'prop-types';
import ReactDom from 'react-dom';

import PrimaryButton from 'components/Buttons/Primary';

/**
 * This modal use as Prompt
 * @param props
 * @returns {JSX.Element} - A JSX element representing the `Modal` component.
 */
function FlowPrompt(props) {
  const { title, message, show = false, onCancel, onConfirm, loading, actionButtons } = props;

  return ReactDom.createPortal(
    <div>
      <Modal
        open={show}
        className="top-0 left-0 right-0 p-0 border border-gainsboroBlue rounded-lg z-50 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(auto-1rem)] max-w-2xl max-h-full"
      >
        <Modal.Header className="font-bold pb-2 mb-2 p-4">
          <div className="flex justify-between items-center">
            <div className="flex-wrap items-center">
              <span className="text-xl pl-2 font-bold font-ptScanCap">{title}</span>
            </div>
            <div className="text-sm font-normal bg-red">
              <Button
                size="xs"
                shape="circle"
                className="btn btn-xs btn-circle border-2 bg-white border-rasinBlack font-bold"
                onClick={onCancel}
                disabled={loading}
              >
                x
              </Button>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="p-4">
          <div className="border-none py-2 grid grid-cols-1">
            <span className="text-base pl-2 font-normal font-lato text-left">{message}</span>
          </div>
        </Modal.Body>
        <div className="w-full py-5 px-5 flex justify-end gap-3 items-center">
          <PrimaryButton
            text={actionButtons?.firstButton}
            btnColor="bg-spaceCadetBlue80"
            color="white"
            radius="2xl"
            btnHoverColor="hover:bg-spaceCadetBlue"
            handleOnClick={onConfirm}
            loading={loading}
            disabled={loading}
          />
          <PrimaryButton
            text={actionButtons?.secondButton}
            btnColor="bg-begoniaRed"
            btnHoverColor="hover:bg-coralRed"
            color="white"
            radius="2xl"
            handleOnClick={onCancel}
            disabled={loading}
          />
        </div>
      </Modal>
    </div>,
    document.body,
  );
}

FlowPrompt.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  actionButtons: PropTypes.object,
  show: PropTypes.bool,
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};
export default FlowPrompt;
