/* eslint-disable no-nested-ternary */
import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';

import { EmojiIcon } from 'components/Icons';
import VariableDropdown from '../../../VariableDropdown';
import { groupedOptions } from '../../../constants';
import MessageButton from '../../MessageDrawer/components/MessageButton';
import AddCarouselButtonPopUp from './AddCarouselButtonPopUp';
import { ICarouselSetting, ISlide } from '../../MessageDrawer/interfaces';
import CarouselButton from './CarouselButton';

interface ICarouselSubTitleInputFieldProps {
  slideNumber: number;
  componentData: ISlide;
  setCarouselSettings: React.Dispatch<React.SetStateAction<ICarouselSetting>>;
}

function CarouselSubTitleInputField(props: ICarouselSubTitleInputFieldProps) {
  const { slideNumber, componentData, setCarouselSettings } = props;

  const textareaRef = useRef(null);
  const emojiPickerRef = useRef(null);
  const buttonRef = useRef(null);

  const [isTyping, setIsTyping] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState(false);
  const [isEditPopupVisible, setEditPopupVisible] = useState(false);
  const [isPopupVisible, setPopupVisible] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  /**
   * Handle Variable Dropdown Value Adding
   * @param {string} value - input text
   */
  const insertValueAtCursorPosition = (value: string) => {
    const currentInputValue = componentData?.text || '';

    const updatedText = `${currentInputValue} {{${value}}}`;

    setCarouselSettings((prev) => {
      const updatedSlides = prev.slides.map((slidesArray, index) => {
        if (index === slideNumber) {
          return slidesArray.map((slide) => {
            if (slide.id === componentData.id) {
              return { ...slide, text: updatedText };
            }
            return slide;
          });
        }
        return slidesArray;
      });

      return { ...prev, slides: updatedSlides };
    });
  };

  const handleFocus = () => {
    setIsTyping(true);
  };

  const handleBlur = () => {
    setIsTyping(false);
  };

  const handleAddButtonClick = () => {
    setPopupVisible(!isPopupVisible);
  };

  /**
   * Handle the text field border colors
   * @returns
   */
  const getBorderColor = () => {
    const { text, isEnabled } = componentData;

    if (text === '' && !isTyping && isEnabled) {
      return 'border-red-300'; // Red border for empty text area
    }
    if (isTyping) {
      return 'border-green-300'; // Green border when typing
    }
    return 'border-gray-300'; // Gray border for non-empty and not typing
  };

  /**
   * Handle emoji selection and insert it into the input
   * Get the current value of the input
   * Add the selected emoji to the input value
   */
  const handleEmojiSelection = (emoji: EmojiClickData) => {
    const currentInputValue = componentData?.text || '';
    const updatedInputValue = currentInputValue + emoji.emoji;

    setCarouselSettings((prev) => {
      const updatedSlides = prev.slides.map((slidesArray, index) => {
        if (index === slideNumber) {
          return slidesArray.map((slide) => {
            if (slide.id === componentData.id) {
              return { ...slide, text: updatedInputValue };
            }
            return slide;
          });
        }
        return slidesArray;
      });

      return { ...prev, slides: updatedSlides };
    });

    // Close the emoji picker
    setIsEmojiPickerVisible(false);
  };

  /**
   * Handle Enable Title Change
   */
  const handleCheckboxChange = useCallback(() => {
    setCarouselSettings((prev) => {
      const updatedSlides = prev.slides.map((slidesArray, index) => {
        if (index === slideNumber) {
          return slidesArray.map((slide) => {
            if (slide.id === componentData.id) {
              return { ...slide, isEnabled: !slide.isEnabled };
            }
            return slide;
          });
        }
        return slidesArray;
      });

      return { ...prev, slides: updatedSlides };
    });

    setIsEmojiPickerVisible(false);
  }, [componentData.id, setCarouselSettings, slideNumber]);

  /**
   * Handle Slide Title change
   */
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const newText = event.target.value;

      setCarouselSettings((prev) => {
        const updatedSlides = prev.slides.map((slidesArray, index) => {
          if (index === slideNumber) {
            return slidesArray.map((slide) => {
              if (slide.id === componentData.id) {
                return { ...slide, text: newText };
              }
              return slide;
            });
          }
          return slidesArray;
        });

        return { ...prev, slides: updatedSlides };
      });
    },
    [componentData.id, setCarouselSettings, slideNumber],
  );

  return (
    <>
      <div className="flex title items-center">
        <input
          type="checkbox"
          className="checkbox"
          checked={componentData.isEnabled}
          onChange={handleCheckboxChange}
        />
        <span className="title-text">Slide SubTitle</span>
      </div>

      <div className="title-msg slide-subtitle-msg">
        <div className="w-full flex flex-col relative" onMouseEnter={handleMouseEnter}>
          <div className="flex justify-end items-center">
            <div className="flex-grow" />
            {isTyping && (
              <div className="text-green-500 text-xs pr-1">
                {2000 - (componentData?.text?.length || 0)}
              </div>
            )}
          </div>
          <div className="relative">
            <div className="relative">
              <textarea
                ref={textareaRef}
                className={`w-full rounded-t-md border-[1px] focus:outline-none ${getBorderColor()} p-2 relative flex-grow`}
                value={componentData?.text || ''}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder="Type your message"
                disabled={!componentData?.isEnabled}
              />
              <div className="absolute top-1 right-10">
                <VariableDropdown
                  id="url"
                  options={groupedOptions}
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      insertValueAtCursorPosition(selectedOption.value);
                    }
                  }}
                  isDisabled={!componentData?.isEnabled}
                />
              </div>
              <button
                type="button"
                ref={buttonRef}
                onClick={() => {
                  const show = !isEmojiPickerVisible && componentData?.isEnabled;
                  setIsEmojiPickerVisible(show);
                }}
                className="absolute top-[10px] right-3 z-[99] cursor-pointer"
              >
                <EmojiIcon />
              </button>
              <div
                style={{ position: 'absolute', top: 0, right: 0, maxHeight: '150px', zIndex: '99' }}
                ref={emojiPickerRef}
              >
                {isEmojiPickerVisible && <EmojiPicker onEmojiClick={handleEmojiSelection} />}
              </div>
            </div>
          </div>
          {componentData?.buttons &&
            componentData?.buttons?.length > 0 &&
            componentData?.buttons.map((obj, index) => (
              <CarouselButton
                key={obj.id}
                {...obj}
                slideNumber={slideNumber}
                componentData={componentData}
                setEditPopupVisible={setEditPopupVisible}
                setCarouselSettings={setCarouselSettings}
              />
            ))}
          <div className="relative">
            {componentData?.buttons?.length < 3 && (
              <button
                type="button"
                className={`border-gray-300 hover:bg-[#F2F2F3] border-dashed border-2 justify-center text-center p-3 rounded-b-md w-full ${
                  !componentData.isEnabled ? 'text-[#9c9c9b]' : 'border-t-0 text-[#0b99b2]'
                } text-sm -mt-[1px]`}
                onClick={handleAddButtonClick}
                disabled={!componentData?.isEnabled}
              >
                + Add Button
              </button>
            )}
            {/* Popup component */}
            {isPopupVisible && (
              <AddCarouselButtonPopUp
                slideNumber={slideNumber}
                setPopupVisible={setPopupVisible}
                componentData={componentData}
                setCarouselSettings={setCarouselSettings}
              />
            )}
            {(isPopupVisible || isEditPopupVisible) && <div className="mb-32" />}
          </div>
        </div>
      </div>
    </>
  );
}

CarouselSubTitleInputField.propTypes = {
  slideNumber: PropTypes.number,
  componentData: PropTypes.object,
  setCarouselSettings: PropTypes.func,
};

export default CarouselSubTitleInputField;
