/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import {
  activateAccount,
  createClient,
  getClientById,
  toggleAccountStatus,
  updateClient,
} from 'services/client.service';
import { apiErrorHandler } from 'api/handler';
import { RootState } from 'slices';
import { updateURLWithSearchParams } from 'utils/helpers';
import { PromptModal } from 'components';
import { ClientStatus } from 'enums/client.enum';

import { clientAccountFormValidationSchema } from './validations';
import FormPage from './page';
import { ModalTypes, PromptTypes, modalDefaultOptions, promptDefaultOptions } from './constants';
import AccountCreateSuccess from './components/modals/AccountCreateSuccess';

function ClientAccountForm() {
  const userSlice = useSelector((state: RootState) => state.user);
  const locationData = useSelector((state: RootState) => state.user.locationData);
  const [searchParams] = useSearchParams(); // read url params

  const [formData, setFormData] = useState<any>({
    country: locationData.country_code.toLowerCase(),
  });
  const [attachments, setAttachments] = useState<any>({ companyLogo: null });

  const [modalOptions, setModalOptions] = useState(modalDefaultOptions);
  const [promptOptions, setPromptOptions] = useState(promptDefaultOptions);
  const [editMode, setEditMode] = useState(false);
  const [customLoading, setCustomLoading] = useState({
    formSubmit: false,
    activateAccount: false,
    toggleAccount: false,
    fetchForm: false,
  });
  const [triggerChild, setTriggerChild] = useState({ transactions: null });
  const pageType = searchParams.get('md');
  const pageId = searchParams.get('i');

  const { register, handleSubmit, formState, control } = useForm<any>({
    resolver: yupResolver(clientAccountFormValidationSchema),
    values: useMemo(() => formData, [customLoading.fetchForm]),
  });

  const { field: countryField } = useController({ name: 'country', control });

  const { value: selectedCountry, onChange: onCountryChange, ...restCountryProps } = countryField;

  const { errors } = formState;

  useEffect(() => {
    if (!pageType) {
      updateURLWithSearchParams({ md: 'new' });
    }
    if (pageType && pageType === 'edit' && pageId) {
      setEditMode(true);
      fetchClientData(pageId);
    }
  }, []);

  // set default location based values for new form
  useEffect(() => {
    if (!editMode && userSlice.locationData?.country_code) {
      const { country_code, country_calling_code } = userSlice.locationData;
      setFormData((prevState: any) => ({
        ...prevState,
        country: country_code,
        dialCode: country_calling_code,
      }));
    }
  }, [userSlice.locationData]);

  /**
   * Fetch client data by id and set form data
   * @param {string} id - Client object _id
   */
  async function fetchClientData(id: string) {
    try {
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: true, fetchForm: true }));
      const { data } = await getClientById(id);
      if (data?.node) {
        setFormData((prevState) => ({ ...prevState, ...data.node }));
        if (data.node.attachments) {
          setAttachments((prevState) => ({ ...prevState, ...data.node.attachments }));
        }
      }
    } catch (e) {
      const { message: exception } = apiErrorHandler(e);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: false, fetchForm: false }));
    }
  }

  // Handles the form submission
  const onSubmit = async () => {
    try {
      const payload = formData;
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: true }));

      if (!editMode) {
        const { data } = await createClient(payload);
        if (data) {
          setFormData({ ...payload, ...data.node });
          handleOnActivateAccount(data?.node, ModalTypes.AccountCreationSuccess);
          setEditMode(true);
          setTriggerChild((prevState) => ({ ...prevState, transactions: uuid() }));
        }
      } else {
        const { data } = await updateClient(formData._id, payload);
        if (data) {
          setFormData({ ...payload, ...data.node });
          setTriggerChild((prevState) => ({ ...prevState, transactions: uuid() }));
          toast.success('Successfully Updated Client');
        }
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: false }));
    }
  };

  // When click the confirm button client account activate
  const handleActivateAccount = async () => {
    try {
      setCustomLoading((prevState) => ({ ...prevState, activateAccount: true }));
      const { data } = await activateAccount(formData._id);
      if (data?.node) {
        toast.success('Client Account has been Approved');
        setFormData({ ...formData, status: ClientStatus.ACTIVE, isApproved: true });
        setModalOptions({ ...modalOptions, actionStatus: true });
        setTriggerChild((prevState) => ({ ...prevState, transactions: uuid() }));
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, activateAccount: false }));
    }
  };

  const handleToggleAccountStatus = async () => {
    try {
      setCustomLoading((prevState) => ({ ...prevState, toggleAccount: true }));
      const { _id } = formData;
      const status = formData.status !== ClientStatus.ACTIVE;
      const { data } = await toggleAccountStatus(_id, { status });
      if (data?.node) {
        toast.success(
          `Successfully ${
            formData.status === ClientStatus.ACTIVE ? 'Disabled' : 'Enabled'
          } Account`,
        );
        setFormData((prevState) => ({
          ...prevState,
          status: status ? ClientStatus.ACTIVE : ClientStatus.INACTIVE,
        }));
        setPromptOptions(promptDefaultOptions);
        setTriggerChild((prevState) => ({ ...prevState, transactions: uuid() }));
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, toggleAccount: false }));
    }
  };

  // handle the input text change
  const handleInputChange = (event: { target: { id: any; value: any } }) => {
    const { id, value } = event.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  // handle select field change
  const handleSelectChange = (event, id) => {
    if (id === 'country') {
      onCountryChange(event?.value);
    }
    setFormData({
      ...formData,
      [id]: event?.value,
    });
  };

  // handle phone number change
  const handlePhoneNoChange = (id: string, value: string) => {
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const handleOnToggleAccountStatus = () => {
    setPromptOptions({
      show: true,
      title: `${formData.status === ClientStatus.ACTIVE ? 'Disable' : 'Enable'} the Account`,
      message: `Do you want to confirm ${
        formData.status === ClientStatus.ACTIVE ? 'disabling' : 'enabling'
      } the Account?`,
      onConfirm: () => handleToggleAccountStatus(),
      type: PromptTypes.ToggleAccount,
    });
  };

  // Activate account model toggle
  const handleOnActivateAccount = (clientData: any, modalType: string) => {
    setModalOptions((prevState) => ({
      ...prevState,
      show: true,
      actionStatus: false,
      type: modalType,
      data: clientData,
    }));
  };

  // used to get updated state for loading
  function loadDynamicLoadingProp() {
    if (promptOptions.type === PromptTypes.ToggleAccount) {
      return customLoading.toggleAccount;
    }
    return false;
  }

  return (
    <>
      <FormPage
        onSubmit={onSubmit}
        errors={errors}
        formData={formData}
        handleInputChange={handleInputChange}
        handleSelectChange={handleSelectChange}
        handleSubmit={handleSubmit}
        register={register}
        editMode={editMode}
        customLoading={customLoading}
        control={control}
        handlePhoneNoChange={handlePhoneNoChange}
        handleOnToggleAccountStatus={handleOnToggleAccountStatus}
        handleOnActivateAccount={handleOnActivateAccount}
        triggerChild={triggerChild}
        restCountryProps={restCountryProps}
      />

      {(modalOptions.type === ModalTypes.AccountCreationSuccess ||
        ModalTypes.ActivateAccountModal) &&
        modalOptions.show && (
          <AccountCreateSuccess
            handleActivateAccount={handleActivateAccount}
            {...modalOptions}
            loading={customLoading.activateAccount}
            toggleModal={() => setModalOptions({ ...modalOptions, show: false })}
          />
          // eslint-disable-next-line indent
        )}

      {promptOptions.show && (
        <PromptModal
          onCancel={() => {
            setPromptOptions({ ...promptOptions, show: false });
          }}
          {...promptOptions}
          loading={loadDynamicLoadingProp()}
        />
      )}
    </>
  );
}

export default ClientAccountForm;
