import { uniqueId } from 'lodash';
import { NODE_NAMES } from 'enums/socket.enum';
import {
  CONDITION_TYPES,
  SEGMENTATION_METHODS,
} from 'views/Settings/FlowBuilder/components/DrawerContents/FilterDrawer/constants';
import { REQUEST_METHODS } from 'views/Settings/FlowBuilder/components/DrawerContents/APIRequestDrawer/constants';
import {
  IMessageButton,
  IReply,
  ISlide,
} from 'views/Settings/FlowBuilder/components/DrawerContents/MessageDrawer/interfaces';
import {
  IMappingSetting,
  IUrlHeader,
} from 'views/Settings/FlowBuilder/components/DrawerContents/APIRequestDrawer/interfaces';

export const generateInitialFilterSettings = () => {
  const initialSettings = {
    name: NODE_NAMES.FILTER,
    position: {},
    filterSettings: [
      // initial if condition
      {
        _id: uniqueId(),
        conditionType: CONDITION_TYPES.IF,
        segmentationMethod: SEGMENTATION_METHODS.ANY,
        segments: [
          {
            _id: uniqueId(),
            criteria: null,
            condition: null,
            textPhrase: null,
          },
        ],
      },
      // not condition
      {
        _id: uniqueId(),
        conditionType: CONDITION_TYPES.NOT,
        segmentationMethod: SEGMENTATION_METHODS.ANY,
        segments: [],
      },
    ],
  };

  return initialSettings;
};

export const generateInitialMessageSettings = () => {
  const initialSettings = {
    name: NODE_NAMES.MESSAGE,
    position: {},
    messageSettings: [
      {
        id: uniqueId(),
        position: 1,
        name: 'message_text',
        text: '',
        buttons: [],
      },
    ],
    quickReplies: {
      nodeId: '',
      isUserInput: false, // true: subscriber reply, false: normal reply
      replies: [],
    },
    subscriberSettings: {
      nodeId: '',
      isEnabled: false,
      validateAs: 'string',
      message: '',
      expiresIn: 30,
    },
    isValid: true,
  };

  return initialSettings;
};

export const generateInitialCarouselFlowSettings = () => {
  const initialSettings = {
    name: NODE_NAMES.CAROUSEL,
    position: {},
    carouselSettings: {
      name: 'message_text',
      text: '',
      isEnabled: false,
      slides: [
        [
          {
            id: uniqueId(),
            isEnabled: false,
            name: 'title',
            position: 0,
            text: '',
          },
          {
            id: uniqueId(),
            isEnabled: false,
            name: 'subTitle',
            position: 1,
            text: '',
            buttons: [],
          },
          {
            id: uniqueId(),
            isEnabled: false,
            name: 'image',
            position: 2,
            url: '',
          },
        ],
      ],
    },
  };

  return initialSettings;
};

export const generateInitialActionFlowSettings = () => {
  const initialSettings = {
    name: NODE_NAMES.ACTION,
    action: 'openChat',
    position: {},
    enableChangeAssignee: false,
    enablePausedHours: false,
    pausedHours: { id: 1, label: 'an hour', value: '1h' },
  };

  return initialSettings;
};

export const generateInitialFlowNodeSettings = () => {
  const initialSettings = {
    name: NODE_NAMES.FLOW,
    position: {},
    flowSettings: [
      // initial if condition
      {
        _id: uniqueId(),
        flowName: null,
      },
    ],
  };

  return initialSettings;
};

export const generateInitialAPIRequestFlowNodeSettings = () => {
  const initialSettings = {
    name: NODE_NAMES.API_REQUEST,
    position: {},
    apiRequestSettings: {
      requestMethod: REQUEST_METHODS.GET,
      url: '',
      body: '',
      headers: [
        {
          _id: uniqueId(),
          headerKey: 'Authorization',
          value: '[your API key]',
        },
      ],
      mappingSettings: [
        {
          _id: uniqueId(),
          mapKey: 'map_key',
          variable: 'firstName',
        },
      ],
    },
  };

  return initialSettings;
};

export const generateInitialAddContactFlowNodeSettings = () => {
  const initialSettings = {
    name: NODE_NAMES.CONTACT,
    position: {},
    contactSettings:
      // initial if condition
      {},
  };

  return initialSettings;
};

export const generateInitialAddCaseFlowNodeSettings = () => {
  const initialSettings = {
    name: NODE_NAMES.CASE,
    position: {},
    caseSettings:
      // initial if condition
      {},
  };

  return initialSettings;
};

export const generateInitialLiveChatFlowNodeSettings = () => {
  const InitialLiveChatConfig = {
    name: NODE_NAMES.LIVE_CHAT,
    position: {},
    liveChatSettings: [
      {
        _id: uniqueId(),
        channelName: null,
      },
    ],
  };

  return InitialLiveChatConfig;
};

export const generateInitialEndChatFlowNodeSettings = () => {
  const InitialEndChatConfig = {
    name: NODE_NAMES.END_CHAT,
    position: {},
  };

  return InitialEndChatConfig;
};

/**
 * Duplicate node and assign unique ids for sub component(eg: segment id)
 * @param nodeConfig - filter node configs
 * @returns
 */
const duplicateFilterNodeSettings = (nodeConfig: any) => {
  const { filterSettings } = nodeConfig;

  const updatedFilterSettings = filterSettings.map((filterSetting: any) => {
    const { segments = [] } = filterSetting;

    const updatedSegments = segments.map((segment: any) => ({ ...segment, _id: uniqueId() }));

    return {
      ...filterSetting,
      _id: uniqueId(),
      segments: updatedSegments,
    };
  });

  return { ...nodeConfig, filterSettings: updatedFilterSettings };
};

/**
 * Duplicate message node and assign unique ids for sub components(eg: button ids)
 * @param nodeConfig - message node configs
 * @param newNodeId
 * @returns
 */
const duplicateMessageNodeSettings = (nodeConfig: any, newNodeId: string) => {
  const { messageSettings, quickReplies, subscriberSettings } = nodeConfig;
  // new nodeId need to be there in subscriberSettings

  const updatedMessageSettings = messageSettings.map((messageSetting: any) => {
    const { buttons = [] } = messageSetting;

    const updatedButtons = buttons.map((button: IMessageButton) => ({ ...button, id: uniqueId() }));

    return { ...messageSetting, buttons: updatedButtons, id: uniqueId() };
  });

  const { replies } = quickReplies;

  const updatedReplies = replies.map((reply: IReply) => ({
    ...reply,
    id: uniqueId(),
  }));

  const updatedQuickReplies = { ...quickReplies, replies: updatedReplies, nodeId: newNodeId };

  const updatedSubscriberSettings = {
    ...subscriberSettings,
    nodeId: newNodeId,
  };

  return {
    ...nodeConfig,
    messageSettings: updatedMessageSettings,
    quickReplies: updatedQuickReplies,
    subscriberSettings: updatedSubscriberSettings,
  };
};

/**
 * Duplicate APIRequest node and assign unique ids for sub
 * components(eg: header ids, mapping key ids)
 *
 * @param nodeConfig - APIRequest node configs
 * @returns
 */
const duplicateAPIRequestNodeSettings = (nodeConfig: any) => {
  const { apiRequestSettings } = nodeConfig;
  const { headers = [], mappingSettings = [] } = apiRequestSettings;

  const updatedHeaders = headers.map((header: IUrlHeader) => ({
    ...header,
    _id: uniqueId(),
  }));

  const updatedMappingSettings = mappingSettings.map((mappingSetting: IMappingSetting) => ({
    ...mappingSetting,
    _id: uniqueId(),
  }));

  return {
    ...nodeConfig,
    apiRequestSettings: {
      ...apiRequestSettings,
      headers: updatedHeaders,
      mappingSettings: updatedMappingSettings,
    },
  };
};

/**
 * Duplicate Carousel node and assign unique ids for
 * sub components(eg: button ids, slide component ids)
 * @param nodeConfig
 * @returns
 */
const duplicateCarouselNodeSettings = (nodeConfig: any) => {
  const { carouselSettings } = nodeConfig;
  const { slides = [] } = carouselSettings;

  const updatedSlides = slides.map((slide: ISlide[]) => {
    const updatedSlide = slide.map((slideComponent: ISlide) => {
      const { buttons } = slideComponent;

      if (buttons) {
        const updatedButtons = buttons.map((button: IMessageButton) => ({
          ...button,
          id: uniqueId(),
        }));

        return { ...slideComponent, id: uniqueId(), buttons: updatedButtons };
      }
      return { ...slideComponent, id: uniqueId() };
    });

    return updatedSlide;
  });

  return { ...nodeConfig, carouselSettings: { ...carouselSettings, slides: updatedSlides } };
};

/**
 * Duplicate nodes and assign unique ids for sub components
 * @param nodeConfig - node configs
 * @param newNodeId - newly assigned id for the copy
 * @returns
 */
export const getDuplicateNode = (nodeConfig: any, newNodeId: string) => {
  let duplicateConfig = nodeConfig;

  const { name } = nodeConfig;

  if (name === NODE_NAMES.FILTER) {
    duplicateConfig = duplicateFilterNodeSettings(nodeConfig);
  } else if (name === NODE_NAMES.MESSAGE) {
    duplicateConfig = duplicateMessageNodeSettings(nodeConfig, newNodeId);
  } else if (name === NODE_NAMES.ACTION) {
    // NOTE: currently no unique id is used except for the nodeId
    duplicateConfig = nodeConfig;
  } else if (name === NODE_NAMES.API_REQUEST) {
    duplicateConfig = duplicateAPIRequestNodeSettings(nodeConfig);
  } else if (name === NODE_NAMES.FLOW) {
    // NOTE: currently no unique id is used except for the nodeId
    duplicateConfig = nodeConfig;
  } else if (name === NODE_NAMES.CASE) {
    // NOTE: currently no unique id is used except for the nodeId
    duplicateConfig = nodeConfig;
  } else if (name === NODE_NAMES.CONTACT) {
    // NOTE: currently no unique id is used except for the nodeId
    duplicateConfig = nodeConfig;
  } else if (name === NODE_NAMES.CAROUSEL) {
    duplicateConfig = duplicateCarouselNodeSettings(nodeConfig);
  } else if (name === NODE_NAMES.LIVE_CHAT) {
    // NOTE: currently no unique id is used except for the nodeId
  }
  return duplicateConfig;
};
