/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';

import { ALL_PROPERTIES } from 'constants/properties';
import { getCurrentDateTime } from 'utils/dates';
import { formattedValues } from 'utils/helpers';

function TransactionUpdatedValueCell(props) {
  const { data, module } = props;
  const maxPropertiesToShow = 3; // Set the maximum number of properties to show
  const entries = Object.entries(data);
  const filteredEntries = entries.filter((entry) => {
    const key = entry[0];
    const value = entry[1];
    // Filter out entries with null values and isDeleted entries with value false
    return value !== null && !(key === 'isDeleted' && value === false);
  });
  const displayedEntries = filteredEntries.slice(0, maxPropertiesToShow);

  return (
    <>
      {displayedEntries.map(([key, valueProp]: [string, any]) => (
        <div key={key}>
          <span>{ALL_PROPERTIES[module][key] || ALL_PROPERTIES.common[key] || ''}:</span>
          &nbsp;
          {typeof valueProp === 'boolean' ? (
            <span>{valueProp ? 'True' : 'False'}</span>
          ) : typeof valueProp === 'object' && valueProp !== null && '$date' in valueProp ? (
            <span>{getCurrentDateTime(valueProp?.$date)}</span>
          ) : typeof valueProp === 'object' ? (
            <span>{formattedValues(valueProp, ALL_PROPERTIES[module][key])}</span>
          ) : (
            <span>{valueProp !== null ? valueProp : ''}</span>
          )}
        </div>
      ))}
      {entries.length > maxPropertiesToShow && <div>...</div>}
    </>
  );
}

TransactionUpdatedValueCell.propTypes = {
  data: PropTypes.any,
  module: PropTypes.string,
};

export default TransactionUpdatedValueCell;
