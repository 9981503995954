import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { Modal, Button } from 'react-daisyui';

import { CustomSelect, PrimaryButton } from 'components';
import { Confirm } from 'components/Icons';
import { getValueForSelect } from 'utils/helpers';
import { IContactListURLQueryParams } from 'views/Contact/List/interface';
import { fetchContactListData } from 'services/contact.service';
import { apiErrorHandler } from 'api/handler';
import { toast } from 'react-toastify';
import { addContactToConversationService, updateConversation } from 'services/conversation.service';

function ContactModal(props) {
  const { show, toggleModal, data, setTriggerChild } = props;

  const { conversationId, sequenceId, isEdit } = data;

  // data
  const [formData, setFormData] = useState<any>({});
  const [contactListData, setContactListData] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getContactListData();
  }, []);

  const getContactListData = async (params?: IContactListURLQueryParams) => {
    setLoading(true);
    try {
      const { data: contactList } = await fetchContactListData({ limit: 0 });
      const docs = (contactList?.docs || []).map((doc) => ({
        value: doc?._id,
        label: `${doc?.firstName || ''} ${doc?.lastName || ''} ${doc?.email || ''} ${
          doc?.primaryContactNo || ''
        }`,
      }));

      setContactListData(docs);
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (!formData?.contactId) {
      return;
    }
    try {
      if (isEdit) {
        const { data: addContactData } = await updateConversation(conversationId, {
          contactId: formData?.contactId,
        });
        if (addContactData?.node) {
          toast.success('Successfully Changed the Contact');
          setTriggerChild((prevState) => ({ ...prevState, contact: uuid() }));
        }
      } else {
        const { data: updateContactData } = await addContactToConversationService(conversationId, {
          contactId: formData?.contactId,
        });
        if (updateContactData?.node) {
          toast.success('Successfully Added a Contact');
          setTriggerChild((prevState) => ({ ...prevState, contact: uuid() }));
        }
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setLoading(false);
      toggleModal();
    }
  };

  // handle the dropdown change
  const handleSelectChange = (event: { value: any; other: any }, id: string) => {
    setFormData({
      ...formData,
      [id]: event?.value || null,
    });
  };

  return (
    <Modal
      open={show}
      className="top-0 left-0 right-0 p-0 border border-gainsboroBlue rounded-lg z-50 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(auto-1rem)] max-w-3xl max-h-full"
    >
      <Modal.Header className="font-bold pb-2 border-b p-4">
        <div className="flex justify-between items-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 w-fit flex-wrap items-center">
            {isEdit ? (
              <div className="text-base font-bold font-ptScanCap whitespace-nowrap">
                Change the Contact for Chat - {sequenceId}
              </div>
            ) : (
              <div className="text-base font-bold font-ptScanCap whitespace-nowrap">
                Assign Contact for Chat - {sequenceId}
              </div>
            )}
          </div>
          <div className="text-sm font-normal bg-red">
            <Button
              size="xs"
              shape="circle"
              className="btn btn-xs btn-circle border-2 bg-white border-rasinBlack font-bold"
              onClick={toggleModal}
            >
              x
            </Button>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="pl-6 justify-center items-center -mt-6">
          <div className="p-4 grid grid-cols-1">
            <span className="text-sm font-normal font-lato pb-3 pl-1">
              Search and Select a Contact
            </span>
            <CustomSelect
              id="contactId"
              required
              isClearable
              isLoading={loading}
              placeholder="Type name, phone number, email to search a contact"
              options={contactListData}
              value={getValueForSelect(formData?.contactId, contactListData)}
              onChange={(event) => handleSelectChange(event, 'contactId')}
            />
          </div>
        </div>

        <div className="w-full pt-5 pb-5 mt-3 bg-culturedGray flex flex-wrap justify-center items-center gap-3">
          <PrimaryButton
            text="Confirm"
            btnColor="bg-spaceCadetBlue80"
            btnHoverColor="hover:bg-spaceCadetBlue"
            color="white"
            icon={<Confirm />}
            radius="2xl"
            disabled={loading}
            handleOnClick={() => handleSubmit()}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

ContactModal.propTypes = {
  show: PropTypes.bool,
  toggleModal: PropTypes.func,
  data: PropTypes.any,
  setTriggerChild: PropTypes.func,
};

export default ContactModal;
