export const defaultTableHeaders = [
  {
    key: 'contactName',
    value: 'Contact Name',
    avatarEnabled: true,
    sortingEnabled: true,
    type: 'previewButton',
  },
  {
    key: 'preview',
    value: 'Preview',
    type: 'previewButton',
  },
  {
    key: 'email',
    value: 'Email Address',
    sortingEnabled: true,
  },
  {
    key: 'primaryContactNo',
    value: 'Primary Contact Number',
    sortingEnabled: true,
  },
  {
    key: 'country',
    value: 'Country',
    sortingEnabled: true,
  },
  {
    key: 'updatedAt',
    value: 'Last Updated At',
    type: 'dateTime',
    sortingEnabled: true,
  },
  {
    key: 'preferredContactMethod',
    value: 'Preferred Contact Method',
  },
  {
    key: 'manage',
    value: 'Manage',
    type: 'manageBtn',
    params: {
      buttonLabel: 'View',
      customActionHandlerName: 'displayModal',
    },
  },
];

export const tableDefaultValues = {
  totalDocs: 0,
  totalPages: 0,
  limit: 2,
  page: 1,
  headers: defaultTableHeaders,
  docs: [],
  sort: null,
  order: -1,
  searchBy: null,
};

// Excel sheet headers
export const excelHeaders = [
  {
    key: 'contactName',
    value: 'Contact Name',
  },
  {
    key: 'email',
    value: 'Email Address',
  },
  {
    key: 'primaryContactNo',
    value: 'Primary Contact Number',
  },
  {
    key: 'country',
    value: 'Country',
  },
  {
    key: 'updatedAt',
    value: 'Last Updated At',
    type: 'dateTime',
  },
  {
    key: 'preferredContactMethod',
    value: 'Preferred Contact Method',
  },
];
