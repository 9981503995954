/* eslint-disable operator-linebreak */
import React from 'react';
import PropTypes from 'prop-types';

import { motion } from 'framer-motion';

interface SendButtonProps {
  text: string;
  btnColor: string;
  radius?: string;
  btnSize?: string;
  textSize?: string;
  handleSendChat?: any;
  disabled?: any;
}

function SendButton(props: SendButtonProps) {
  const { text, btnColor, radius, btnSize, textSize, handleSendChat, disabled } = props;

  return (
    <motion.button
      onClick={handleSendChat}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.9 }}
      type="submit"
      disabled={disabled}
      className={`btn-${btnSize} ${btnColor} font-lato font-normal text-white capitalize w-[110px] rounded-md`}
    >
      <span className="text-[11px]">{text}</span>
    </motion.button>
  );
}

SendButton.propTypes = {
  text: PropTypes.string,
  btnColor: PropTypes.string,
  btnSize: PropTypes.string,
  textSize: PropTypes.string,
  handleSendChat: PropTypes.func,
  disabled: PropTypes.any,
};

export default SendButton;
