import React from 'react';
import PropTypes from 'prop-types';

import { BotLocaleStatus } from 'enums/aws/lex.enum';

/**
 * LexBotStatus component displays a badge based on the lex bot building status.
 * @param {Object} props - The component's props.
 * @param {string} props.status - The bot's status.
 * @param {boolean} props.isReady - Whether the bot is ready/created.
 * @returns {JSX.Element|null} The JSX element for the badge or null if no match.
 */
function LexBotStatus(props: { status: string; isReady: boolean }): JSX.Element | null {
  const { status, isReady } = props;

  if (status === BotLocaleStatus.Built) {
    return (
      <div className="badge bg-northTexasGreen text-white font-extrabold font-lato p-3 text-center items-center text-xs border-none">
        BUILT
      </div>
    );
  }
  if (status === BotLocaleStatus.Failed) {
    return (
      <div className="badge bg-begoniaRed text-white font-extrabold font-lato p-3 text-center items-center text-xs border-none">
        FAILED
      </div>
    );
  }
  if (
    status === BotLocaleStatus.Building ||
    status === BotLocaleStatus.ReadyExpressTesting ||
    status === BotLocaleStatus.Processing
  ) {
    return (
      <div className="badge bg-spaceCadetBlue text-white font-extrabold font-lato p-3 text-center items-center text-xs border-none">
        BUILDING
      </div>
    );
  }
  if (status === BotLocaleStatus.NotBuilt) {
    return (
      <div className="badge bg-spaceCadetBlue text-white font-extrabold font-lato p-3 text-center items-center text-xs border-none">
        NOT BUILT
      </div>
    );
  }
  if (!isReady) {
    return (
      <div className="badge bg-spaceCadetBlue text-white font-extrabold font-lato p-3 text-center items-center text-xs border-none">
        PREPARING
      </div>
    );
  }
  return null;
}

LexBotStatus.propTypes = {
  status: PropTypes.string,
  isReady: PropTypes.bool,
};

export default LexBotStatus;
