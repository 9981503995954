import React, { useCallback, useRef, useState } from 'react';
import ReactDom from 'react-dom';
import { Button, Modal } from 'react-daisyui';
import PropTypes from 'prop-types';

// #region assets | components
import PrimaryButton from 'components/Buttons/Primary';
import {
  Confirm,
  ViewClient,
  OpenList,
  UserApprove,
  SendApprove,
  ApproveLater,
} from 'components/Icons';

/**
 * This model use to send user account for the approval
 * @param props
 * @returns {JSX.Element} - A JSX element representing the `Modal` component.
 */
function SuccessModal(props) {
  const {
    title,
    type,
    topRightMsg,
    message,
    clientName,
    userName,
    id,
    onConfirm,
    show,
    navigateForFirstButton,
    navigateForSecondButton,
    navigateForApproveModal,
    onClose,
  } = props;

  const ref = useRef<HTMLDialogElement>(null);

  // confirm/approve button disable or enable
  const [isConfirmEnable, setIsConfirmEnable] = useState(true);

  /** This function use to view and hide the model */
  const handleShow = useCallback(() => {
    ref.current?.showModal();
  }, [ref]);

  /**
   * Check whether the confirmation field text is correct
   * @param {string} value - Text in confirmation field
   */
  const handleInputChange = (value: string) => {
    if ((type === 'client' && value === 'confirm') || (type === 'user' && value === 'approve')) {
      setIsConfirmEnable(false);
    } else {
      setIsConfirmEnable(true); // Reset to true if the value is not 'confirm' or 'approve'
    }
  };
  // render out side the parent components hierarchy(in the body)
  return ReactDom.createPortal(
    <div>
      <Modal
        open={show}
        className="top-0 left-0 right-0 p-0 border border-gainsboroBlue rounded-lg z-50 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(auto-1rem)] max-w-3xl max-h-full"
      >
        <Modal.Header className="font-bold pb-2 mb-2 border-b p-4">
          <div className="flex justify-between items-center">
            <div className="grid grid-cols-1 sm:grid-cols-2 w-fit flex-wrap items-center">
              <div className="text-xl font-bold font-ptScanCap">{title}</div>
              <span className="text-sm font-normal font-lato text-left ml-0 sm:ml-36">
                {topRightMsg}
              </span>
            </div>
            <div className="text-sm font-normal bg-red">
              <Button
                size="xs"
                shape="circle"
                className="btn btn-xs btn-circle border-2 bg-white border-rasinBlack font-bold"
                onClick={onClose}
              >
                x
              </Button>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="p-4">
          <div className="text-center mt-1">
            <p className="font-lato font-normal text-sm">
              {type === 'client' ? clientName : userName}
            </p>
            <p className="font-lato font-normal text-sm">
              {type === 'client' ? 'Client ID' : 'User ID'}: {id}
            </p>
          </div>
          <div className="border border-northTexasGreen bg-milkGreen rounded-xl p-4 mt-5 grid grid-cols-1">
            <span className="text-sm font-normal font-lato p-3">{message}</span>

            <input
              type="text"
              placeholder="Type here"
              className="input border-quickSilver h-10 focus:outline-none rounded-xl font-normal text-sm w-full max-w-2xl mt-3 mb-4"
              onChange={(event) => handleInputChange(event.target.value)}
            />

            <PrimaryButton
              text={`${type === 'client' ? 'Confirm' : 'Approve User'}`}
              btnColor="bg-spaceCadetBlue80"
              btnHoverColor="hover:bg-spaceCadetBlue"
              color="white"
              icon={type === 'client' ? <Confirm /> : <UserApprove />}
              width="[100px]"
              textSize="sm font-bold font-ptScanCap"
              radius="2xl"
              disabled={isConfirmEnable}
              handleOnClick={onConfirm}
            />
          </div>
        </Modal.Body>
        <div className="w-full pt-5 pb-5 mt-3 bg-culturedGray flex flex-wrap justify-center gap-3 items-center">
          <PrimaryButton
            text={`${type === 'client' ? 'View Client' : 'Send for Approval'}`}
            btnColor="bg-spaceCadetBlue80"
            color="white"
            width="[140px]"
            radius={type === 'client' ? '3xl' : '2xl'}
            btnHoverColor="hover:bg-spaceCadetBlue"
            icon={type === 'client' ? <ViewClient /> : <SendApprove />}
            textSize={
              type === 'client' ? 'xs font-bold font-ptScanCap' : 'md font-bold font-ptScanCap'
            }
            disabled={isConfirmEnable}
            handleOnClick={type === 'client' ? navigateForFirstButton : navigateForApproveModal}
          />
          <PrimaryButton
            text={`${type === 'client' ? 'Open List View' : 'Approve Later'}`}
            btnColor="bg-spaceCadetBlue80"
            btnHoverColor="hover:bg-spaceCadetBlue"
            color="white"
            icon={type === 'client' ? <OpenList /> : <ApproveLater />}
            width="[100px]"
            textSize={
              type === 'client' ? 'xs font-bold font-ptScanCap' : 'md font-bold font-ptScanCap'
            }
            radius={type === 'client' ? '3xl' : '2xl'}
            handleOnClick={type === 'client' ? navigateForSecondButton : onClose}
          />
        </div>
      </Modal>
    </div>,
    document.body, // render out side the parent components hierarchy(in the body)
  );
}

SuccessModal.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  clientName: PropTypes.string,
  userName: PropTypes.string,
  message: PropTypes.string,
  id: PropTypes.string,
  topRightMsg: PropTypes.string,
  btnText: PropTypes.string,
  btnBgColor: PropTypes.string,
  btnTextColor: PropTypes.string,
  btnHoverColor: PropTypes.string,
  radius: PropTypes.string,
  onConfirm: PropTypes.func,
  navigateForFirstButton: PropTypes.func,
  navigateForSecondButton: PropTypes.func,
  navigateForApproveModal: PropTypes.func,
  onClose: PropTypes.func,
  btnIcon: PropTypes.element,
  show: PropTypes.bool,
};
export default SuccessModal;
