/* eslint-disable function-paren-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';
import PropTypes from 'prop-types';
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';

import { Delete, EmojiIcon } from 'components/Icons';
import { buildVariableDropdownOptions } from 'views/Settings/FlowBuilder/utils';
import { useSelector } from 'react-redux';
import { RootState } from 'slices';
import VariableDropdown from '../../../VariableDropdown';
import { IMessageSetting } from '../interfaces';
import MessageButton from './MessageButton';
import UpIcon from './tempIcons/Up';
import AddButtonPopUp from './AddingButtonsPopUp';

interface IMessageInputFieldProps {
  settingsArray: IMessageSetting[];
  componentData: any;
  setSettingsArr: React.Dispatch<React.SetStateAction<IMessageSetting[]>>;
  isEmpty: boolean;
}
function MessageInputField(props: IMessageInputFieldProps) {
  const { settingsArray, componentData, setSettingsArr, isEmpty } = props;

  const flowData = useSelector((state: RootState) => state.flow);

  const textareaRef = useRef(null);
  const emojiPickerRef = useRef(null);
  const buttonRef = useRef(null);

  const [messageText, setMessageText] = useState(componentData?.text || '');
  // Debounce the text value here
  const [debouncedText] = useDebounce(messageText, 1000); // 1000 milliseconds debounce time

  const [isPopupVisible, setPopupVisible] = useState(false);
  const [isEditPopupVisible, setEditPopupVisible] = useState(false);

  const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState(false);

  const [isTyping, setIsTyping] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    setPopupVisible(false);
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setSettingsArr((prev) => {
      const updatedArray = prev.map((item) =>
        item.id === componentData.id ? { ...item, text: debouncedText } : item,
      );

      return updatedArray;
    });
  }, [debouncedText, setSettingsArr]);

  /**
   * Handle message change
   * @param {React.ChangeEvent<HTMLTextAreaElement>} e - Change event
   */
  const handleMessageTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessageText(e.target.value);
  };

  const handleFocus = () => {
    setIsTyping(true);
  };

  const handleClickOutside = (event) => {
    // Check if the clicked element is outside the emoji button and picker
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target) &&
      !buttonRef.current.contains(event.target)
    ) {
      // Close the emoji picker
      setIsEmojiPickerVisible(false);
    }
  };

  /**
   * Handle emoji selection and insert it into the input
   * Get the current value of the input
   * Add the selected emoji to the input value
   */
  const handleEmojiSelection = (emoji: EmojiClickData) => {
    const currentInputValue = componentData?.text || '';
    const updatedInputValue = currentInputValue + emoji.emoji;
    setSettingsArr((prev) => {
      const updatedArray = prev.map((item) =>
        item.id === componentData.id ? { ...item, text: updatedInputValue } : item,
      );

      return updatedArray;
    });

    // Close the emoji picker
    setMessageText(updatedInputValue);
    setIsEmojiPickerVisible(false);
  };

  const handleBlur = () => {
    setIsTyping(false);
  };

  const handleAddButtonClick = () => {
    setPopupVisible(!isPopupVisible);
  };

  const insertValueAtCursorPosition = (value) => {
    const currentInputValue = componentData?.text || '';

    const updatedText = `${currentInputValue} {{${value}}}`;
    setSettingsArr((prev) => {
      const updatedArray = prev.map((item) =>
        item.id === componentData.id ? { ...item, text: updatedText } : item,
      );

      return updatedArray;
    });
    setMessageText(updatedText);
  };

  const getBorderColor = () => {
    if (isEmpty) {
      return 'border-red-300'; // Red border for empty text area
    }
    if (isTyping) {
      return 'border-green-300'; // Green border when typing
    }
    return 'border-gray-300'; // Gray border for non-empty and not typing
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleDelete = () => {
    setSettingsArr((prev) => {
      if (prev.length === 1) {
        return prev;
      }
      // Filter out the item to be deleted
      const updatedArray = prev.filter((component) => component.id !== componentData.id);

      // Update positions based on the new array
      const newArrayWithUpdatedPositions = updatedArray.map((component, index) => ({
        ...component,
        position: index + 1, // Update the position based on the new index
      }));

      return newArrayWithUpdatedPositions;
    });
  };
  const handleUp = () => {
    setSettingsArr((prev) => {
      // Find the index of the item to move up
      const indexToMoveUp = prev.findIndex((component) => component.id === componentData.id);

      // Check if the item is not already at the top
      if (indexToMoveUp > 0) {
        // Swap positions with the item above it
        const updatedArray = [...prev];
        [updatedArray[indexToMoveUp - 1], updatedArray[indexToMoveUp]] = [
          updatedArray[indexToMoveUp],
          updatedArray[indexToMoveUp - 1],
        ];

        // Update positions based on the new array
        const newArrayWithUpdatedPositions = updatedArray.map((component, index) => ({
          ...component,
          position: index + 1, // Update the position based on the new index
        }));

        return newArrayWithUpdatedPositions;
      }

      // If the item is already at the top, return the original array
      return prev;
    });
  };
  const handleDown = () => {
    setSettingsArr((prev) => {
      // Find the index of the item to move down
      const indexToMoveDown = prev.findIndex((component) => component.id === componentData.id);

      // Check if the item is not already at the bottom
      if (indexToMoveDown < prev.length - 1 && indexToMoveDown !== -1) {
        // Swap positions with the item below it
        const updatedArray = [...prev];
        [updatedArray[indexToMoveDown], updatedArray[indexToMoveDown + 1]] = [
          updatedArray[indexToMoveDown + 1],
          updatedArray[indexToMoveDown],
        ];

        // Update positions based on the new array
        const newArrayWithUpdatedPositions = updatedArray.map((component, index) => ({
          ...component,
          position: index + 1, // Update the position based on the new index
        }));

        return newArrayWithUpdatedPositions;
      }

      // If the item is already at the bottom, return the original array
      return prev;
    });
  };

  return (
    <div className="w-full flex flex-col relative" onMouseEnter={handleMouseEnter}>
      <div className="flex justify-end items-center">
        <div className="flex-grow" />
        {isTyping && (
          <div className="text-green-500 text-xs pr-1">
            {2000 - (componentData?.text?.length || 0)}
          </div>
        )}
      </div>
      <div className="relative">
        <div className="relative">
          <textarea
            ref={textareaRef}
            className={`w-full rounded-t-md border-[1px] focus:outline-none ${getBorderColor()} p-2 relative flex-grow`}
            value={messageText || ''}
            onChange={handleMessageTextChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder="Type your message"
            rows={3}
            style={{
              paddingRight: '80px',
            }}
          />
          <div className="absolute top-1 right-10">
            <VariableDropdown
              id="messageInputVariableDropdown"
              options={buildVariableDropdownOptions(flowData?.variables || [])}
              onChange={(selectedOption) => {
                if (selectedOption) {
                  insertValueAtCursorPosition(selectedOption.value);
                }
              }}
            />
          </div>
          <button
            type="button"
            ref={buttonRef}
            onClick={() => setIsEmojiPickerVisible(!isEmojiPickerVisible)}
            className="absolute top-[10px] right-3 z-[99] cursor-pointer"
          >
            <EmojiIcon />
          </button>
          <div
            style={{ position: 'absolute', top: 0, right: 0, maxHeight: '150px', zIndex: '99' }}
            ref={emojiPickerRef}
          >
            {isEmojiPickerVisible && <EmojiPicker onEmojiClick={handleEmojiSelection} />}
          </div>
        </div>

        <div className="absolute top-1/2 -right-6 transform -translate-y-1/2 flex flex-col justify-center items-center z-20">
          <button
            type="button"
            className={`rounded-md bg-none drop-shadow-xl ${isHovered ? 'visible' : 'hidden'}`}
            onClick={(e) => {
              e.stopPropagation();
              handleDelete();
            }}
          >
            <Delete id="node-delete-icon" color="#FF7171" width={20} height={20} />
          </button>
          <button
            type="button"
            className={`rounded-md bg-none drop-shadow-xl mt-2 ${
              isHovered && componentData?.position !== 1 ? 'visible' : 'hidden'
            }`}
            onClick={(e) => {
              e.stopPropagation();
              handleUp();
            }}
          >
            <UpIcon width={20} height={20} />
          </button>
          <button
            type="button"
            className={`rounded-md bg-none drop-shadow-xl ${
              isHovered && componentData?.position !== settingsArray?.length
                ? 'visible'
                : isHovered && settingsArray?.length === 1
                ? 'visible'
                : 'hidden'
            }`}
            onClick={(e) => {
              e.stopPropagation();
              handleDown();
            }}
          >
            <div style={{ transform: 'rotateX(180deg)' }}>
              <UpIcon width={20} height={20} />
            </div>
          </button>
        </div>
      </div>
      {componentData?.buttons &&
        componentData?.buttons?.length > 0 &&
        componentData?.buttons.map((obj, index) => (
          <MessageButton
            key={obj.id}
            {...obj}
            setEditPopupVisible={setEditPopupVisible}
            componentData={componentData}
            setSettingsArr={setSettingsArr}
          />
        ))}
      <div className="relative">
        {componentData?.buttons.length < 10 && (
          <button
            type="button"
            className={`${
              isEmpty ? 'border-red-300' : 'border-gray-300'
            } hover:bg-[#F2F2F3] border-t-0 border-dashed border-2 justify-center text-center p-3 rounded-b-md w-full text-[#0b99b2] text-sm -mt-[1px]`}
            onClick={handleAddButtonClick}
          >
            + Add Button
          </button>
        )}
        {/* Popup component */}
        {isPopupVisible && (
          <AddButtonPopUp
            setPopupVisible={setPopupVisible}
            componentData={componentData}
            setSettingsArr={setSettingsArr}
            setEditPopupVisible={setEditPopupVisible}
          />
        )}
        {(isPopupVisible || isEditPopupVisible) && <div className="mb-32" />}
      </div>

      {isEmpty && (
        <div className="text-red-400 text-xs">
          This message is empty. Please provide some text or remove the text field
        </div>
      )}
    </div>
  );
}

MessageInputField.propTypes = {
  isHovered: PropTypes.bool,
  settingsArray: PropTypes.array,
  componentData: PropTypes.object,
  setSettingsArr: PropTypes.func,
  isEmpty: PropTypes.bool,
};

export default MessageInputField;
