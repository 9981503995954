/* eslint-disable react/forbid-prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import classNames from 'classnames';

import { ClientStatus } from 'enums/client.enum';
import { Disable, Enable } from 'components/Icons';

function ToggleDisableBtn(props) {
  const { formData, handleOnToggleAccountDisable, loading, disabled = false } = props;

  if (!formData.isDisabled) {
    return (
      <motion.button
        onClick={handleOnToggleAccountDisable}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.9 }}
        type="submit"
        className={classNames(
          'px-[20px] py-[10px] rounded-2xl text-white font-ptScanCap bg-begoniaRed hover:bg-coralRed',
        )}
      >
        {loading ? (
          <span className="loading loading-infinity loading-lg" />
        ) : (
          <span className="text-sm font-semibold flex flex-row items-center">
            <Disable width={22} /> &nbsp; Disable
          </span>
        )}
      </motion.button>
    );
  }

  if (formData.isDisabled) {
    return (
      <motion.button
        onClick={handleOnToggleAccountDisable}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.9 }}
        type="submit"
        className={classNames(
          'px-[20px] py-[10px] rounded-2xl text-white font-ptScanCap bg-spaceCadetBlue80 hover:bg-spaceCadetBlue',
          {
            'bg-spaceCadetBlue80': disabled,
            'bg-spaceCadetBlue': !disabled,
          },
        )}
      >
        {loading ? (
          <span className="loading loading-infinity loading-lg" />
        ) : (
          <span className="text-sm font-semibold flex flex-row items-center">
            <Enable width={22} /> &nbsp; Enable
          </span>
        )}
      </motion.button>
    );
  }
}

ToggleDisableBtn.propTypes = {
  formData: PropTypes.any,
  handleOnToggleAccountDisable: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default ToggleDisableBtn;
