import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RootState } from 'slices';

import { apiErrorHandler } from 'api/handler';
import { createTemplate, updateTemplate } from 'services/templates.service';
import Messages from 'views/Inbox/Chat/containers/Messages';
import { ModalTypes, modalDefaultOptions } from 'views/Inbox/Chat/constants';
import AddTemplateModal from 'views/Inbox/Chat/containers/UserLayout/modals/AddTemplateModal';
import InboxContactPreview from 'views/Inbox/components/ContactPreview';
import CompleteMsgModal from 'views/Inbox/components/modals/completeMsgModal';
import ForwardMsgModal from 'views/Inbox/components/modals/forwardMsgModal';
import DrawerIcon from 'components/Icons/DrawerIcon';
import { completeConversation, updateConversation } from 'services/conversation.service';
import { useSearchParams } from 'react-router-dom';

function ChatView() {
  const sideBarOpen = useSelector((state: RootState) => state.layout.sideBarOpen);

  // read query string in the url
  const [searchParams] = useSearchParams();

  const [visible, setVisible] = useState(false); // show preview model
  const [promptOptions, setPromptOptions] = useState<any>({});
  const [triggerChild, setTriggerChild] = useState<any>({
    transactions: null,
    messages: null,
    conversations: null,
  });

  // get conversation id from url search params
  const [loadedConversationId, setLoadedConversationId] = useState(searchParams.get('id'));
  const [modalOptions, setModalOptions] = useState(modalDefaultOptions);
  const [contactData, setContactData] = useState<any>({});

  const [selectedImage, setSelectedImage] = useState(null); // chat image attachment
  const [isNewChat, setIsNewChat] = useState(false); //  when user move to the new tab

  const [chatFormData, setChatFormData] = useState<any>({});
  const [allMessages, setAllMessages] = useState([]); // chat msg list
  const [selectedTab, setSelectedTab] = useState(0);

  const [customLoading, setCustomLoading] = useState({
    submitMessage: false,
    fileUploading: false,
    complete: false,
    forward: false,
  });

  const triggerForwardMsgModal = () => {
    setModalOptions((prevState) => ({
      ...prevState,
      show: true,
      actionStatus: false,
      type: ModalTypes.ForwardMsgModal,
      data: { loadedConversationId },
    }));
  };

  const triggerCompleteMsgModal = () => {
    setModalOptions((prevState) => ({
      ...prevState,
      show: true,
      actionStatus: false,
      type: ModalTypes.CompleteMsgModal,
      data: { loadedConversationId },
    }));
  };

  // side bar of the contact, which is closed default
  const toggleContactPreview = () => {
    setVisible(!visible);
  };

  // contact update modal triggering function
  const handleOnUpdateContact = (data = {}) => {
    setVisible(false);
    setPromptOptions((prevState) => ({
      ...prevState,
      show: !promptOptions.show,
      data: { ...data },
    }));
  };

  const handleCreateTemplate = async (data: any) => {
    try {
      const payload: any = {
        isPrivate: data?.isPrivate,
        messageBody: data?.messageBody,
      };
      if (data?.isEdit) {
        if (data?.deleted) {
          payload.deleted = data?.deleted;
          await updateTemplate(data?._id, payload);
          toast.success('Successfully Deleted the Message Template');
        } else {
          await updateTemplate(data?._id, payload);
          toast.success('Successfully Updated the Message Template');
        }
      } else {
        await createTemplate(payload);
        toast.success('Successfully Created a Message Template');
      }
      setTriggerChild((prevState) => ({ ...prevState, transactions: uuid() }));
      handleOnUpdateContact();
    } catch (e) {
      const { message: exception } = apiErrorHandler(e);
      toast.error(exception);
    }
  };

  // forward conversation
  const forwardConversation = async (formData) => {
    try {
      setCustomLoading((prevState) => ({ ...prevState, forward: true }));
      const { data } = await updateConversation(loadedConversationId, {
        channelId: formData?.channelId,
        userId: formData?.userId,
      });
      if (data.status) {
        setTriggerChild((prevState) => ({ ...prevState, conversations: uuid() }));
        setModalOptions({ ...modalOptions, show: false });
        setSelectedTab(0);
        setAllMessages([]);
        setLoadedConversationId(null);
        toast.success('Successfully Forward the Chat');
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, forward: false }));
    }
  };

  // forward conversation
  const handleCompleteConversation = async (formData) => {
    try {
      setCustomLoading((prevState) => ({ ...prevState, complete: true }));
      const { data } = await completeConversation(loadedConversationId, {
        rate: formData?.rating,
        note: formData?.note,
      });
      if (data.status) {
        setTriggerChild((prevState) => ({ ...prevState, conversations: uuid() }));
        setModalOptions({ ...modalOptions, show: false });
        setSelectedTab(0);
        setAllMessages([]);
        setLoadedConversationId(null);
        toast.success('Successfully Completed the Chat');
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, complete: false }));
    }
  };

  return (
    <div className="xs:w-screen sm:w-full grid grid-cols-1 w-full h-full bg-[#F4F4F4]">
      <div className="bg-brightGray md:col-span-2">
        <Messages
          handleForwardMsg={triggerForwardMsgModal}
          handleCompleteMsg={triggerCompleteMsgModal}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          // handleSendAndGoNext={handleSendAndGoNext}
          isNewChat={isNewChat}
          selectedConversationId={loadedConversationId}
          customLoading={customLoading}
          setCustomLoading={setCustomLoading}
          setChatFormData={setChatFormData}
          chatFormData={chatFormData}
          allMessages={allMessages}
          setAllMessages={setAllMessages}
        />
      </div>

      {sideBarOpen && loadedConversationId && (
        <div className="fixed flex items-center justify-center right-0 top-1/2 text-white transform rotate-90 -mr-16">
          <button
            type="button"
            className="flex items-center justify-center bg-spaceCadetBlue80 hover:bg-spaceCadetBlue w-auto h-auto px-8 py-[1vh] text-lg font-ptScanCap font-bold rounded-b-2xl"
            onClick={toggleContactPreview}
          >
            <span className="transform -rotate-180 text-white">Contact</span>
            <div className="-rotate-90 ml-1">
              <DrawerIcon width="28" height="28" />
            </div>
          </button>
        </div>
      )}

      {promptOptions?.show && (
        <AddTemplateModal
          {...promptOptions}
          toggleModal={handleOnUpdateContact}
          handleUpdate={handleCreateTemplate}
        />
      )}
      {modalOptions.type === ModalTypes.ForwardMsgModal && modalOptions.show && (
        <ForwardMsgModal
          {...modalOptions}
          handleUpdate={forwardConversation}
          customLoading={customLoading}
          toggleModal={() => setModalOptions({ ...modalOptions, show: false })}
        />
      )}
      {modalOptions.type === ModalTypes.CompleteMsgModal && modalOptions.show && (
        <CompleteMsgModal
          {...modalOptions}
          handleUpdate={handleCompleteConversation}
          customLoading={customLoading}
          toggleModal={() => setModalOptions({ ...modalOptions, show: false })}
        />
      )}

      <InboxContactPreview
        visible={visible}
        toggleVisible={toggleContactPreview}
        handleOnUpdateContact={handleOnUpdateContact}
        triggerChild={triggerChild}
        setChatFormData={setChatFormData}
        contactData={contactData}
      />
    </div>
  );
}

export default ChatView;
