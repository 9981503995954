import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AuthSlice = {
  logged: boolean;
  user: UserAuth;
  socketConnected: boolean;
};

type UserAuth = {
  email: string;
  name: string;
};

const initialState: AuthSlice = {
  logged: false,
  user: null,
  socketConnected: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginAction: (state, action) => {
      state.logged = true;
      state.user = action.payload.user;
    },
    logoutAction: (state) => {
      state.logged = false;
      state.user = null;
    },
    toggleSocketStatus(state, { payload }: PayloadAction<boolean>) {
      state.socketConnected = payload;
    },
  },
});

export const { loginAction, logoutAction, toggleSocketStatus } = authSlice.actions;

export default authSlice.reducer;
