/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-bitwise */
/* eslint-disable radix */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';
import PropTypes from 'prop-types';

function SketchColorPicker({ value, handleChange }) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState<any>();

  useEffect(() => {
    if (value) {
      setColor(hexToRgb(value));
    } else {
      setColor(hexToRgb('#192A51'));
    }
  }, [value]);

  function hexToRgb(hex: string) {
    // Remove the '#' character if it's present
    hex = hex.replace(/^#/, '');

    // Parse the hex values to integers
    const bigint = parseInt(hex, 16);

    // Extract the RGB components
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    // Set the alpha (a) component to 255 (fully opaque)
    const a = 255;

    // Return an RGBA object
    return { r, g, b, a };
  }

  const rgbToHex = (r, g, b) =>
    `#${((1 << 24) + (parseInt(r) << 16) + (parseInt(g) << 8) + parseInt(b))
      .toString(16)
      .slice(1)
      .toUpperCase()}`;

  const styles = reactCSS({
    default: {
      color: {
        width: '36px',
        height: '36px',
        borderRadius: '2px',
        background: color ? `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})` : '#FFF',
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  });

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleColorChange = (newColor) => {
    setColor(newColor.rgb);
    if (handleChange) {
      const hexColor = rgbToHex(newColor.rgb.r, newColor.rgb.g, newColor.rgb.b);
      handleChange(hexColor);
    }
  };

  return (
    <div>
      <div style={styles.swatch} onClick={handleClick}>
        <div style={styles.color} />
      </div>
      {displayColorPicker ? (
        // @ts-ignore
        <div style={styles.popover}>
          {
            // @ts-ignore
            <div style={styles.cover} onClick={handleClose} />
          }
          {color && <SketchPicker color={color} onChangeComplete={handleColorChange} />}
        </div>
      ) : null}
    </div>
  );
}

// PropTypes definitions
SketchColorPicker.propTypes = {
  value: PropTypes.string,
  handleChange: PropTypes.func,
};

export default SketchColorPicker;
