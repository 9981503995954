import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { isUndefined, omitBy } from 'lodash';
import PropTypes from 'prop-types';

import { useDebounce } from 'use-debounce';
import { selectCustomStylesForFilters } from 'styles/select';
import { CustomSelect, CustomTable } from 'components';
import { Search } from 'components/Icons';
import { getValueForSelect } from 'utils/helpers';

import ChatPreview from './containers/ChatPreview';

function ListPage(props) {
  const {
    contactListData,
    handleTakeChat,
    handleFilterBy,
    handlePageChange,
    handleResultsPerPageChange,
    botMessageListData,
  } = props;

  // show preview model
  const [visible, setVisible] = useState(false);
  const [urlSearchParams, setUrlSearchParams] = useState(null);

  // data for preview model
  const [botChatData, setBotChatData] = useState<any>();

  const [searchByInput, setSearchByInput] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const [debouncedHandleFilterBy] = useDebounce(handleFilterBy, 500);

  const handleInputChange = (event) => {
    const { value } = event.target;
    setSearchByInput(value);
    debouncedHandleFilterBy(value, 'searchBy');
  };

  const replaceUrlParamsHandler = (params) => {
    const queryParams = omitBy(
      {
        page: params?.page || '1',
        limit: params.limit,
        contact: params.contact,
        order: params.order,
        searchBy: params.searchBy,
        country: params.country,
        orderBy: params.orderBy,
        i: params.i,
      },
      isUndefined,
    );
    const searchParams = new URLSearchParams(queryParams);
    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  const urlSearch = Object.fromEntries(new URLSearchParams(location.search));

  /**
   * Handles the preview contact model visibility and data
   * @param {IContact} data - Contact data from clicked row
   */
  const showPreview = (data: any) => {
    setVisible(true);
    setBotChatData(data);
    replaceUrlParamsHandler({ page: urlSearch.page, limit: 10, i: data._id });
  };
  const closePreview = () => {
    setVisible(false);
    setBotChatData(null);
    replaceUrlParamsHandler({ page: urlSearch.page, limit: 10 });
  };

  return (
    <div className="page">
      <div className="mb-2 flex flex-row align-top">
        <span className="text-spaceCadetBlue font-bold text-xl font-ptScanCap pr-4">
          Ongoing Bot Chats
        </span>
      </div>
      <div className="border-t border-slate-300" />

      {/* Filter and Export Section */}
      <div className="container mt-4 py-4">
        <form className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          {/* Search Input */}
          <div className="mb-4">
            <div className="relative block sm:flex items-center mb-2 sm:mb-0">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2 mt-1">
                <svg className="h-5 w-5 mb-2 fill-slate-300" viewBox="0 0 20 20">
                  <Search color="#475574" />
                </svg>
              </span>
              <input
                className="sm:placeholder:text-sm placeholder:text-independenceBlue font-lato text-sm block bg-white border border-gainsboroBlue rounded-lg h-11 py-2 pl-9 pr-3 shadow-sm focus:border-[#2684ff] focus:outline-none sm:text-sm w-full"
                placeholder="Search by Case Subject, Contents"
                type="text"
                name="search"
                onChange={handleInputChange}
                value={searchByInput || ''}
              />
            </div>
          </div>

          {/* contact Select */}
          <div className="mr-2 col-span-1  mb-4 srg:mt-0">
            <CustomSelect
              placeholder="Contact"
              customStyles={selectCustomStylesForFilters}
              options={contactListData}
              value={getValueForSelect(searchByInput, contactListData)}
              isClearable
              onChange={(selected) => {
                const status = selected?.value?.toLowerCase();
                handleFilterBy(status, 'contactId');
              }}
            />
          </div>
        </form>
      </div>

      <CustomTable
        tableData={botMessageListData}
        onPageChange={handlePageChange}
        handleResultsPerPageChange={handleResultsPerPageChange}
        isPaginate
        onPreviewAction={showPreview}
        onManage={handleTakeChat}
      />

      {/* Preview chat model */}
      <ChatPreview
        visible={visible}
        toggleHide={closePreview}
        conversationData={botChatData}
        handleTakeChat={handleTakeChat}
      />
    </div>
  );
}

ListPage.propTypes = {
  handleFilterBy: PropTypes.func,
  handlePageChange: PropTypes.func,
  handleTakeChat: PropTypes.func,
  handleResultsPerPageChange: PropTypes.func,
  contactListData: PropTypes.array,
  botMessageListData: PropTypes.object,
};

export default ListPage;
