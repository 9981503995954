/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Controller, useController } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { CustomSelect, InputText, PrimaryButton, TransactionTable } from 'components';
import GeneralButton from 'components/Buttons/GeneralButton';
import { Back, Confirm, Save } from 'components/Icons';
import { getValueForSelect } from 'utils/helpers';
import { COLLECTIONS } from 'constants/db';
import { getUserType } from 'utils/auth';
import { UserType } from 'constants/user.const';
import { PATHS } from 'routes/paths';
import FormEditHeader from './containers/FormEditHeader';
import DeleteRoleBtn from './Components/DeleteRoleBtn';
import { PERMISSION_GROUPS, defaultFormPlaceholders, transactionTableHeaders } from './constants';
import RoleCard from './Components/RoleCard';

function FormPage(props: any) {
  const {
    handleSubmit,
    onSubmit,
    register,
    errors,
    handleInputChange,
    initialFormData,
    formData,
    editMode,
    customLoading,
    control,
    handleOnDeleteRolePermission,
    triggerChild,
    setFormData,
    allPermissions,
    handleSelectPermissionChange,
    handleSelectChange,
    handleAddPermission,
    givenPermissions,
    clientsOptions,
  } = props;

  const navigate = useNavigate();
  const userType = getUserType();

  const { field: clientIdField } = useController({ name: 'clientId', control });

  useEffect(() => {
    if (formData.clientId) {
      const { onChange } = clientIdField;
      onChange(formData.clientId);
    }
  }, [formData?.clientId]);

  const handleBack = (e: any) => {
    e.preventDefault();
    navigate(`${PATHS.USER_MANAGEMENT.ROLE.LIST.url}?page=1&limit=10`);
  };

  return (
    <div className="page role_permission__form">
      <div className="flex justify-between items-center">
        <div>
          {!editMode ? (
            <span className="main-heading">Create a Role</span>
          ) : (
            <FormEditHeader formData={initialFormData} />
          )}
        </div>
        <div className="gap-2 flex">
          {editMode && formData._id && !formData.deleted && (
            <DeleteRoleBtn
              formData={formData}
              handleOnDeleteRolePermission={handleOnDeleteRolePermission}
              loading={customLoading.toggleInstance}
            />
          )}

          <GeneralButton
            text="Back"
            btnColor="bg-spaceCadetBlue80"
            color="white"
            radius="2xl"
            textSize="xs"
            btnHoverColor="hover:bg-spaceCadetBlue"
            icon={<Back />}
            handleOnClick={handleBack}
          />
        </div>
      </div>
      <hr className="my-3" />

      <div className="grid page_body">
        <div className="col-span-2">
          <div className="p-8 bg-white drop-shadow rounded-xl form_body">
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <div className="grid grid-cols-2 xs:grid-cols-1 rg:grid-cols-2 lg:grid-cols-2 gap-4">
                <div className="mb-4">
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        type="text"
                        elementId="name"
                        label="Role Name"
                        placeholder={defaultFormPlaceholders.name}
                        register={register}
                        error={errors?.name}
                        value={formData?.name || ''}
                        onChange={(e: any) => {
                          field.onChange(e);
                          handleInputChange(e);
                        }}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 xs:grid-cols-1 rg:grid-cols-2 lg:grid-cols-2 gap-4">
                <div className="mb-4">
                  <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        type="text"
                        elementId="description"
                        label="Description"
                        rows={2}
                        placeholder={defaultFormPlaceholders.description}
                        register={register}
                        error={errors?.description}
                        value={formData?.description || ''}
                        onChange={(e: any) => {
                          field.onChange(e);
                          handleInputChange(e);
                        }}
                      />
                    )}
                  />
                </div>
              </div>

              {userType === UserType.SuperUser && (
                <div className="grid grid-cols-2 xs:grid-cols-1 rg:grid-cols-2 lg:grid-cols-2 gap-4">
                  <div className="mb-2">
                    <div className="mb-2">
                      <div className="form-control">
                        <label className="label" htmlFor="client">
                          <span className="label-text font-normal text-base font-lato">Client</span>
                        </label>
                      </div>
                      <Controller
                        control={control}
                        name="clientId"
                        render={({ field }) => (
                          <CustomSelect
                            options={clientsOptions}
                            error={errors.clientId}
                            value={getValueForSelect(field.value, clientsOptions)}
                            onChange={(val) => {
                              field.onChange(val?.value || null);
                              handleSelectChange(val, 'clientId');
                            }}
                            id="clientId"
                            register={register}
                            inputRef={field.ref}
                            isLoading={customLoading.fetchClients}
                            isDisabled={editMode}
                            isClearable
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="mt-4 mb-4">
                <PrimaryButton
                  text={editMode ? 'Update Role' : 'Create Role'}
                  btnColor="bg-spaceCadetBlue80"
                  color="white"
                  radius="2xl"
                  textSize="base"
                  btnHoverColor="hover:bg-spaceCadetBlue"
                  icon={editMode ? <Save /> : <Confirm />}
                  disabled={customLoading.formSubmit}
                  loading={customLoading.formSubmit}
                />
              </div>
            </form>
          </div>

          {editMode && formData._id && (
            <>
              <div className="mt-4">
                <span className="permission-heading">Roles</span>
                <hr className="my-4" />
                {PERMISSION_GROUPS.map((permissionGroup) => (
                  <RoleCard
                    key={permissionGroup.moduleName}
                    moduleName={permissionGroup.moduleName}
                    featureKeys={permissionGroup.featureKeys}
                    allPermissions={allPermissions}
                    givenPermissions={givenPermissions}
                    handleSelectChange={handleSelectPermissionChange}
                  />
                ))}

                <div className="mt-4 mb-4">
                  <PrimaryButton
                    text="Update Role"
                    btnColor="bg-spaceCadetBlue80"
                    color="white"
                    radius="2xl"
                    textSize="base"
                    btnHoverColor="hover:bg-spaceCadetBlue"
                    icon={<Save />}
                    disabled={customLoading.formSubmit}
                    loading={customLoading.formSubmit}
                    handleOnClick={handleAddPermission}
                  />
                </div>
              </div>
              <div className="p-8 bg-white drop-shadow rounded-xl mt-6">
                <TransactionTable
                  recordId={formData._id}
                  collection={COLLECTIONS.Role}
                  transactionTableHeaders={transactionTableHeaders}
                  triggerChild={triggerChild.transactions}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

FormPage.propTypes = {
  setFormData: PropTypes.func,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  register: PropTypes.func,
  errors: PropTypes.any,
  formData: PropTypes.any,
  initialFormData: PropTypes.any,
  control: PropTypes.any,
  handleInputChange: PropTypes.func,
  editMode: PropTypes.bool,
  customLoading: PropTypes.shape({
    formSubmit: PropTypes.bool,
    toggleInstance: PropTypes.bool,
    activateAccount: PropTypes.bool,
  }),
  triggerChild: PropTypes.any,
  handleOnDeleteRolePermission: PropTypes.func,
  allPermissions: PropTypes.object,
  handleSelectPermissionChange: PropTypes.func,
  handleSelectChange: PropTypes.func,
  handleAddPermission: PropTypes.func,
  givenPermissions: PropTypes.object,
  clientsOptions: PropTypes.array,
};

export default FormPage;
