import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import GeneralButton from 'components/Buttons/GeneralButton';
import { uniqueId } from 'lodash';
import { ICarouselSetting, ISlide } from '../../MessageDrawer/interfaces';

interface IAddCarouselButtonPopUp {
  buttonId?: string;
  slideNumber: number;
  editMode?: boolean;
  buttonText?: string;
  buttonUrl?: string;
  type?: string;
  componentData: ISlide;
  setPopupVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setCarouselSettings: React.Dispatch<React.SetStateAction<ICarouselSetting>>;
  setEditPopupVisible?: any;
}
function AddCarouselButtonPopUp(props: IAddCarouselButtonPopUp) {
  const {
    buttonId,
    slideNumber,
    editMode = false,
    buttonText,
    buttonUrl,
    type,
    componentData,
    setPopupVisible,
    setCarouselSettings,
    setEditPopupVisible,
  } = props;

  const inputRef = useRef(null);
  const inputUrlRef = useRef(null);

  const [popupText, setPopupText] = useState('');
  const [selectedOption, setSelectedOption] = useState('continue');
  const [urlInputVisible, setUrlInputVisible] = useState(false);
  const [urlInputValue, setUrlInputValue] = useState('');
  const [urlInputError, setUrlInputError] = useState(false);
  const [buttonTextError, setButtonTextError] = useState(false);

  useEffect(() => {
    // Focus on the input field when the component mounts
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    // Focus on the input field when the component mounts
    if (inputUrlRef.current && selectedOption === 'url') {
      inputUrlRef.current.focus();
    }
  }, [selectedOption]);

  useEffect(() => {
    if (editMode) {
      if (type) setSelectedOption(type);
      if (buttonText) setPopupText(buttonText);
      if (buttonUrl) {
        setUrlInputVisible(true);
        setUrlInputValue(buttonUrl);
      }
    }
  }, [editMode]);

  const handleOptionChange = (event) => {
    const { value } = event.target;
    setSelectedOption(value);

    // Show/hide the URL input box based on the selected option
    setUrlInputVisible(value === 'url');
  };

  const editButton = () => {
    setCarouselSettings((prev: ICarouselSetting) => {
      //  iterate through the slides
      const updatedSlides = prev.slides.map((slidesArray, index) => {
        // find the slide based on the slide number
        if (index === slideNumber) {
          // iterate through the slides' buttons
          return slidesArray.map((slide) => {
            // find the specific button by its ID
            if (slide.id === componentData.id) {
              // update the button's properties if its ID matches the specified buttonId
              const updatedButtons = slide.buttons.map((button) => {
                if (button.id === buttonId) {
                  return {
                    ...button,
                    buttonText: popupText,
                    buttonUrl: urlInputValue,
                  };
                }
                return button;
              });
              // return the slide with updated buttons
              return { ...slide, buttons: updatedButtons };
            }
            return slide;
          });
        }

        // return the complete slides array
        return slidesArray;
      });

      // return the carousel settings with the updated slides
      return { ...prev, slides: updatedSlides };
    });
  };

  const handlePopupSubmit = () => {
    // Validate URL and button text
    if (selectedOption === 'url' && (!urlInputValue || !validateURL(urlInputValue))) {
      setUrlInputError(true);
      return;
    }
    setUrlInputError(false);

    if (!popupText) {
      setButtonTextError(true);
      return;
    }
    setButtonTextError(false);

    if (editMode) {
      editButton();
    } else {
      addNewButton();
    }
    setPopupText('');
    setUrlInputValue('');
    setUrlInputVisible(false);
    setPopupVisible(false);
  };

  const addNewButton = () => {
    setCarouselSettings((prev: ICarouselSetting) => {
      const updatedSlides = prev.slides.map((slidesArray, index) => {
        if (index === slideNumber) {
          return slidesArray.map((slide) => {
            if (slide.id === componentData.id) {
              const newButton = {
                id: uniqueId(),
                position: 1,
                buttonText: popupText,
                buttonUrl: urlInputValue,
              };

              return { ...slide, buttons: [...slide.buttons, newButton] };
            }
            return slide;
          });
        }
        return slidesArray;
      });

      return { ...prev, slides: updatedSlides };
    });
  };

  const handleDelete = (e) => {
    e.preventDefault();
    setCarouselSettings((prev: ICarouselSetting) => {
      const updatedSlides = prev.slides.map((slidesArray, index) => {
        if (index === slideNumber) {
          return slidesArray.map((slide) => {
            if (slide.id === componentData.id) {
              // Filter out the button that needs to be deleted
              const updatedButtons = slide.buttons.filter((button) => button.id !== buttonId);
              return { ...slide, buttons: updatedButtons };
            }
            return slide;
          });
        }
        return slidesArray;
      });

      return { ...prev, slides: updatedSlides };
    });
  };

  const validateURL = (url: string): boolean => {
    // Regular expression to validate URL format
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlPattern.test(url);
  };

  const handleButtonTextChange = (text: string) => {
    setButtonTextError(text === '');
    setPopupText(text);
  };

  const handleUrlTextChange = (text: string) => {
    setUrlInputError(!validateURL(urlInputValue) && urlInputValue !== '');
    setUrlInputValue(text);
  };

  return (
    <div className="absolute top-0 left-0 w-full h-auto flex z-20">
      <div className="bg-white p-5 rounded-md shadow-md w-full h-full">
        <input
          type="text"
          ref={inputRef}
          id="popupText"
          className={`w-full rounded-md h-10 border-[2px] focus:outline-none focus:ring-[1px] focus:ring-green-300 pl-4 mb-2 ${
            buttonTextError ? 'border-red-300' : ''
          }`}
          value={popupText}
          placeholder="Button Text"
          onChange={(e) => handleButtonTextChange(e.target.value)}
        />

        <div className="flex items-center mb-5">
          <label className="mr-4">
            <input
              type="radio"
              name="flowType"
              value="continue"
              checked={selectedOption === 'continue'}
              onChange={handleOptionChange}
              disabled={editMode}
            />
            <span className="ml-2">Continue flow</span>
          </label>
          <label>
            <input
              type="radio"
              name="flowType"
              value="url"
              checked={selectedOption === 'url'}
              onChange={handleOptionChange}
              disabled={editMode}
            />
            <span className="ml-2">URL</span>
          </label>
        </div>

        {urlInputVisible && (
          <div className="mb-5 flex items-center">
            <select
              id="urlSelect"
              className="w-1/4 rounded-md h-10 border-[1px] focus:outline-none focus:ring-[1px] focus:ring-green-300 pl-4"
              value="URL"
              disabled
              onChange={(e) => {
                // Handle select change logic if needed
              }}
            >
              <option value="URL">URL</option>
            </select>
            <input
              type="text"
              id="urlInput"
              ref={inputUrlRef}
              className={`w-3/4 rounded-md h-10 border-[1px] focus:outline-none focus:ring-[1px] focus:ring-green-300 pl-4 ${
                urlInputError ? 'border-red-300' : ''
              }`}
              placeholder="https://example.com"
              value={urlInputValue}
              onChange={(e) => handleUrlTextChange(e.target.value)}
            />
          </div>
        )}

        <div className="flex justify-between">
          <div className="gap-2">
            <GeneralButton
              text={editMode ? 'Save' : 'Add'}
              btnColor="bg-spaceCadetBlue80"
              color="white"
              radius="2xl"
              textSize="xs"
              btnHoverColor="hover:bg-spaceCadetBlue"
              handleOnClick={handlePopupSubmit}
            />
            {editMode && (
              <GeneralButton
                text="Delete"
                btnColor="bg-begoniaRed"
                color="white"
                radius="2xl"
                textSize="xs"
                btnHoverColor="hover:bg-begoniaRed"
                handleOnClick={(e) => {
                  handleDelete(e);
                }}
              />
            )}
          </div>
          <button
            type="button"
            className="rounded-md bg-none drop-shadow-xl mt-2 text-spaceCadetBlue hover:text-spaceCadetBlue80"
            onClick={(e) => {
              setPopupVisible(false);
              setEditPopupVisible(false);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

AddCarouselButtonPopUp.propTypes = {
  buttonId: PropTypes.string,
  slideNumber: PropTypes.number,
  editMode: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonUrl: PropTypes.string,
  type: PropTypes.string,
  componentData: PropTypes.object,
  setPopupVisible: PropTypes.func,
  setCarouselSettings: PropTypes.func,
  setEditPopupVisible: PropTypes.func,
};

export default AddCarouselButtonPopUp;
