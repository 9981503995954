import { isEqual } from 'lodash';
import { updateMessengerFlow } from 'services/messengerFlow.service';
import { handleFlowSaveStatus } from './flow';

const stateChangeMiddleware = (store) => (next) => async (action) => {
  const prevState = store.getState();
  const result = next(action);

  const nextState = store.getState();

  // Check if nodeConfigs, variables, or flowPath have changed
  const isFlowConfigsEqual = isEqual(prevState.flow.flowPath, nextState.flow.flowPath);
  const isVariablesEqual = isEqual(prevState.flow.variables, nextState.flow.variables);
  const isNodeConfigsEqual = isEqual(prevState.flow.nodeConfigs, nextState.flow.nodeConfigs);

  if (!isNodeConfigsEqual || !isVariablesEqual || !isFlowConfigsEqual) {
    // destructure the object to remove isValid and errors properties
    const cleanedNodeConfigs = nextState.flow.nodeConfigs.map((nodeConfig: any) => {
      const { isValid, errors, ...rest } = nodeConfig;
      return { ...rest };
    });

    // build the draft payload
    const payload = {
      intentId: nextState.flow.intentId,
      isDraft: true,
      flowBuilderData: {
        nodeConfigs: cleanedNodeConfigs,
        variables: nextState.flow.variables,
        flowPath: nextState.flow.flowPath,
      },
    };

    if (payload.intentId) {
      await updateMessengerFlow(payload);
      store.dispatch(handleFlowSaveStatus(false));
    }
  }
};

export default stateChangeMiddleware;
