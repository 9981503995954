import * as yup from 'yup';

export const customerFeedbackSettingsSchema = yup.object({
  enableCustomerFeedbackCollection: yup.bool().notRequired(),
  collectCustomerFeedbackVia: yup.string().when('enableCustomerFeedbackCollection', (value) => {
    if (value[0]) {
      return yup.string().required('Select option to confirm');
    }
    return yup.string().notRequired();
  }),
  customerFeedbackAutoExpireInMin: yup
    .string()
    .when('enableCustomerFeedbackCollection', (value) => {
      if (value[0]) {
        return yup
          .string()
          .test('is-valid', 'Expiration time should be in 5-59 minutes', (val) => {
            const intValue = parseInt(val, 10);
            return intValue >= 5 && intValue < 60;
          })
          .required('Expiration time should be in 5-60 minutes');
      }
      return yup.string().notRequired();
    }),
});

export const customerFeedbackSettingsValidationSchema = yup.object({
  enableStarRating: yup.bool().notRequired(),
  starRatingMessage: yup.string().when('enableStarRating', (value) => {
    if (value[0]) {
      return yup.string().required('Message is required');
    }
    return yup.string().notRequired();
  }),
  enableCustomQuestionOne: yup.bool().notRequired(),
  customQuestionOne: yup.string().when('enableCustomQuestionOne', (value) => {
    if (value[0]) {
      return yup.string().required('Question is required');
    }
    return yup.string().notRequired();
  }),
  enableCustomQuestionTwo: yup.bool().notRequired(),
  customQuestionTwo: yup.string().when('enableCustomQuestionTwo', (value) => {
    if (value[0]) {
      return yup.string().required('Question is required');
    }
    return yup.string().notRequired();
  }),
  enableCustomQuestionThree: yup.bool().notRequired(),
  customQuestionThree: yup.string().when('enableCustomQuestionThree', (value) => {
    if (value[0]) {
      return yup.string().required('Question is required');
    }
    return yup.string().notRequired();
  }),
});

export const addUtteranceValidationSchema = yup.object({
  utterance: yup.string().required('Utterance is required'),
});

export const addIntentSuggestionValidationSchema = yup.object({
  intentSuggestion: yup.string().max(40, 'Maximum character length is 40'),
});
