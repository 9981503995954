/* eslint-disable function-paren-newline */
import React from 'react';

/**
 * Render cleaned message text. If just a a text show as a text if its a link show as a link
 * @param {string} text - Message text
 * @returns
 */
export function renderTextWithLinksAndEmojis(text: string) {
  if (!text) {
    return null;
  }

  const parts = text.split(/(https?:\/\/\S+|\n|:\S+:)/); // Split by URLs, newlines, and emojis

  if (!parts) {
    return null;
  }

  return parts.map((part, index) => {
    const key = `${part}_${index}`; // Create a unique key for each part
    if (part.match(/^https?:\/\/\S+/)) {
      return (
        <a
          key={key}
          href={part}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'blue', textDecoration: 'underline' }}
        >
          {part}
        </a>
      );
    }
    if (part.match(/:\S+:/)) {
      return <span key={key}>{convertUnicodeToEmoji(part)}</span>;
    }
    if (part === '\n') {
      return <br key={key} />;
    }
    return <span key={key}>{part}</span>;
  });
}

function convertUnicodeToEmoji(text: string) {
  const regex = /\\u([\d\w]{4})/gi; // Regular expression to match Unicode escape sequences
  const convertedText = text.replace(regex, (match, grp) =>
    String.fromCodePoint(parseInt(grp, 16)),
  );
  return convertedText;
}
