import * as React from 'react';
import PropTypes from 'prop-types';

function Edit(props: { color: any; width: any; height: any }) {
  const { color = '#262525', width = 25, height = 25 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 35 35"
    >
      <g
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        clipPath="url(#clip0_1416_4356)"
      >
        <path d="M10.16 10.209H8.731c-.757 0-1.483.307-2.018.854a2.95 2.95 0 00-.836 2.062V26.25c0 .774.3 1.516.836 2.063a2.824 2.824 0 002.018.854h12.844c.757 0 1.483-.308 2.018-.854a2.95 2.95 0 00.836-2.063v-1.458" />
        <path d="M29.26 9.603a3.097 3.097 0 00.879-2.165c0-.813-.316-1.592-.878-2.166a2.965 2.965 0 00-2.12-.897c-.794 0-1.557.323-2.119.897L13.013 17.5v4.375h4.282L29.26 9.603zM23.003 7.292l4.281 4.374" />
      </g>
      <defs>
        <clipPath id="clip0_1416_4356">
          <path fill="#fff" d="M0 0H34.25V35H0z" transform="translate(.17)" />
        </clipPath>
      </defs>
    </svg>
  );
}
Edit.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Edit;
