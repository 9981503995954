import * as React from 'react';
import PropTypes from 'prop-types';

function Delete(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color = '#262525', width = '24', height = '24' } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 36 35"
    >
      <g
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        clipPath="url(#clip0_1416_4347)"
      >
        <path d="M6.678 10.209h22.834M8.105 10.209l1.427 17.5c0 .773.301 1.515.836 2.062a2.823 2.823 0 002.018.854h11.417c.757 0 1.483-.307 2.018-.854a2.95 2.95 0 00.836-2.063l1.427-17.5M13.814 10.208V5.833c0-.386.15-.757.418-1.03.267-.274.63-.428 1.009-.428h5.708c.379 0 .741.154 1.01.427.267.274.417.645.417 1.031v4.375M15.24 17.5l5.71 5.833m0-5.833l-5.71 5.833" />
      </g>
      <defs>
        <clipPath id="clip0_1416_4347">
          <path fill="#fff" d="M0 0H34.25V35H0z" transform="translate(.97)" />
        </clipPath>
      </defs>
    </svg>
  );
}
Delete.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Delete;
