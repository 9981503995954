/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';

import { apiErrorHandler } from 'api/handler';
import { generateTemporaryUserPassword } from 'services/auth.service';

function TempPasswordSetupPage() {
  const [searchParams] = useSearchParams();

  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(true);

  const userId = searchParams.get('i');

  useEffect(() => {
    if (userId) {
      generateTemporaryPassword(userId);
    } else {
      toast.error('Invalid Link');
      setLoading(false);
      setIsError(true);
      setErrorMessage('Invalid Link');
    }
  }, []);

  const generateTemporaryPassword = async (id) => {
    try {
      const { data } = await generateTemporaryUserPassword(id);
      if (data) {
        if (data.status) {
          setIsSuccess(true);
          setTimeout(() => {
            window.location.href = '/login';
          }, 2000);
        }
      }
    } catch (e) {
      setIsSuccess(false);
      const { message: exception } = apiErrorHandler(e);
      toast.error(exception);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-secondary min-h-screen flex justify-center items-center">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md relative" // Added relative class
      >
        <h2 className="text-2xl font-bold mb-4">Verifying Account</h2>

        <div className="mt-2">
          {loading && <span className="loading loading-infinity loading-lg" />}
          {isSuccess && (
            <div className="w-full text-green-800 text-center mb-3">
              You&lsquo;re account has been verified. A Temporary password has been sent to
              you&lsquo;re email
            </div>
          )}
          {errorMessage && (
            <div className="w-full text-red-900 -500 text-center mb-3">{errorMessage}</div>
          )}
        </div>
      </motion.div>
    </div>
  );
}

export default TempPasswordSetupPage;
