/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { ManageForm, Settings } from 'components/Icons';
import { PrimaryButton } from 'components';

import { ModalTypes, modalDefaultOptions, promptDefaultOptions } from '../constants';
import ContactSettingModal from './ContactSettingModal';
import ContactFormModal from './ContactFormModal';
import CustomerFeedbackSettingModal from './CustomerFeedbackSettingsModal';
import CustomerFeedbackFormModal from './CustomerFeedbackFormModal';

function MoreSettings(props) {
  const { formData, setFormData, onSubmit } = props;

  const [modalOptions, setModalOptions] = useState(modalDefaultOptions);

  const handleOnClickContactInformationSettings = () => {
    setModalOptions((prevState) => ({
      ...prevState,
      show: true,
      actionStatus: false,
      type: ModalTypes.ContactInformationSetting,
    }));
  };

  const handleOnClickManageContactInfoForm = () => {
    setModalOptions((prevState) => ({
      ...prevState,
      show: true,
      actionStatus: false,
      type: ModalTypes.ContactInfoForm,
    }));
  };

  /**
   * Handle `Settings` btn in customer feedback settings
   */
  const handleOnClickCustomerFeedbackSettings = () => {
    setModalOptions((prevState) => ({
      ...prevState,
      show: true,
      actionStatus: false,
      type: ModalTypes.CustomerFeedbackSetting,
    }));
  };

  /**
   * Handle `Manage Form` btn in customer feedback settings
   */
  const handleOnClickManageCustomerFeedbackForm = () => {
    setModalOptions((prevState) => ({
      ...prevState,
      show: true,
      actionStatus: false,
      type: ModalTypes.CustomerFeedbackForm,
    }));
  };

  return (
    <>
      <div className="p-5 bg-white drop-shadow rounded-xl mt-6">
        <div className="flex flex-row justify-between items-center">
          <h2 className="text-spaceCadetBlue font-bold font-ptScanCap text-base srg:text-xl">
            More Settings
          </h2>
        </div>
        <hr className="my-4 border-quickSilver" />
        <div className="flex flex-row justify-between items-center">
          <p>Contact Information Collection</p>

          <div className="gap-3 flex">
            <PrimaryButton
              text="Settings"
              btnColor="bg-spaceCadetBlue80"
              btnHoverColor="hover:bg-spaceCadetBlue"
              color="white"
              icon={<Settings strokeColor="#FFF" />}
              width="[100px]"
              textSize="sm font-bold font-ptScanCap"
              radius="2xl"
              handleOnClick={handleOnClickContactInformationSettings}
              className="py-[5px] pr-[15px] pl-[10px]"
              type="button"
            />
            <PrimaryButton
              text="Manage Form"
              btnColor="bg-spaceCadetBlue80"
              btnHoverColor="hover:bg-spaceCadetBlue"
              color="white"
              icon={<ManageForm />}
              width="[100px]"
              textSize="sm font-bold font-ptScanCap"
              radius="2xl"
              handleOnClick={handleOnClickManageContactInfoForm}
              className="py-[5px] pr-[15px] pl-[10px]"
            />
          </div>
        </div>
        <hr className="my-4 border-quickSilver" />
        {/* <div className="flex flex-row justify-between items-center">
          <p>Operating Hours</p>
          <div className="gap-3 flex">
            <PrimaryButton
              text="Settings"
              btnColor="bg-spaceCadetBlue80"
              btnHoverColor="hover:bg-spaceCadetBlue"
              color="white"
              icon={<Settings strokeColor="#FFF" />}
              width="[100px]"
              textSize="sm font-bold font-ptScanCap"
              radius="2xl"
              // handleOnClick={onClickLinkChannel}
              className="py-[5px] pr-[15px] pl-[10px]"
            />
          </div>
        </div>
        <hr className="my-4 border-quickSilver" /> */}

        <div className="flex flex-row justify-between items-center">
          <p>Customer Feedback</p>

          <div className="gap-3 flex">
            <PrimaryButton
              text="Settings"
              btnColor="bg-spaceCadetBlue80"
              btnHoverColor="hover:bg-spaceCadetBlue"
              color="white"
              icon={<Settings strokeColor="#FFF" />}
              width="[100px]"
              textSize="sm font-bold font-ptScanCap"
              radius="2xl"
              handleOnClick={handleOnClickCustomerFeedbackSettings}
              className="py-[5px] pr-[15px] pl-[10px]"
              type="button"
            />
            <PrimaryButton
              text="Manage Form"
              btnColor="bg-spaceCadetBlue80"
              btnHoverColor="hover:bg-spaceCadetBlue"
              color="white"
              icon={<ManageForm />}
              width="[100px]"
              textSize="sm font-bold font-ptScanCap"
              radius="2xl"
              handleOnClick={handleOnClickManageCustomerFeedbackForm}
              className="py-[5px] pr-[15px] pl-[10px]"
            />
          </div>
        </div>
        <hr className="my-4 border-quickSilver" />
      </div>

      {modalOptions.type === ModalTypes.ContactInformationSetting && modalOptions.show && (
        <ContactSettingModal
          handleUpdate={onSubmit}
          formData={formData}
          setFormData={setFormData}
          {...modalOptions}
          toggleModal={() => setModalOptions({ ...modalOptions, show: false })}
        />
      )}

      {modalOptions.type === ModalTypes.ContactInfoForm && modalOptions.show && (
        <ContactFormModal
          handleUpdate={onSubmit}
          formData={formData}
          setFormData={setFormData}
          {...modalOptions}
          toggleModal={() => setModalOptions({ ...modalOptions, show: false })}
        />
      )}

      {modalOptions.type === ModalTypes.CustomerFeedbackSetting && modalOptions.show && (
        <CustomerFeedbackSettingModal
          handleUpdate={onSubmit}
          formData={formData}
          setFormData={setFormData}
          {...modalOptions}
          toggleModal={() => setModalOptions({ ...modalOptions, show: false })}
        />
      )}

      {modalOptions.type === ModalTypes.CustomerFeedbackForm && modalOptions.show && (
        <CustomerFeedbackFormModal
          handleUpdate={onSubmit}
          formData={formData}
          setFormData={setFormData}
          {...modalOptions}
          toggleModal={() => setModalOptions({ ...modalOptions, show: false })}
        />
      )}
    </>
  );
}

MoreSettings.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default MoreSettings;
