export const DEV_ENV = () => {
  if (process.env.REACT_APP_ENV === 'development') {
    return true;
  }
  return false;
};

export const PROD_ENV = () => {
  if (process.env.REACT_APP_ENV === 'production') {
    return true;
  }
  return false;
};

export const QA_ENV = () => {
  if (process.env.REACT_APP_ENV === 'qa') {
    return true;
  }
  return false;
};

export const __PROD__ = PROD_ENV();
export const __QA__ = QA_ENV();
export const __DEV__ = DEV_ENV();
