import * as React from 'react';
import PropTypes from 'prop-types';

function InputAttachment(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color = '#475574', width = 25, height = 24 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 35 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="tasa_widget__attachment_svg"
    >
      <g clipPath="url(#clip0_2749_676)">
        <path
          d="M16.2468 11.3125L16.0432 20.5025C16.0308 21.065 16.2367 21.6095 16.6156 22.0163C16.9946 22.423 17.5156 22.6587 18.064 22.6715C18.6124 22.6843 19.1433 22.4731 19.5399 22.0844C19.9365 21.6957 20.1663 21.1614 20.1787 20.5989L20.3823 11.4089C20.4073 10.284 19.9955 9.19498 19.2375 8.38146C18.4796 7.56794 17.4376 7.09655 16.3408 7.07099C15.244 7.04542 14.1822 7.46779 13.389 8.24515C12.5958 9.02252 12.1362 10.0912 12.1113 11.2162L11.9077 20.4062C11.8703 22.0936 12.488 23.7271 13.6249 24.9474C14.7618 26.1676 16.3248 26.8747 17.97 26.9131C19.6152 26.9514 21.2079 26.3179 22.3977 25.1518C23.5874 23.9858 24.2768 22.3827 24.3142 20.6953L24.5178 11.5053"
          stroke={color}
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2749_676">
          <rect
            width="23.6883"
            height="23.7155"
            fill="white"
            transform="matrix(0.71419 -0.699952 0.681669 0.73166 0.958008 16.6145)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

InputAttachment.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default InputAttachment;
