import * as React from 'react';
import PropTypes from 'prop-types';

function ActionIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color = '#9554B8', width = 45, height = 32 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 45 32"
    >
      <rect width="8" height="8" x="27" y="4" stroke={color} strokeWidth="2" rx="1" />
      <rect
        width="8"
        height="8"
        x="17"
        y="26"
        stroke={color}
        strokeWidth="2"
        rx="1"
        transform="rotate(-180 17 26)"
      />

      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 5L9 8l3 3M32 25l3-3-3-3"
      />

      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M17 7L17 9"
      />

      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M27 23L27 21"
      />

      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M22 7L22 9"
      />

      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M22 23L22 21"
      />
    </svg>
  );
}
ActionIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default ActionIcon;
