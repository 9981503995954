/* eslint-disable max-len */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-unused-expressions */
/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck

import React, { useEffect, useState, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import CHATBOT_LOGO from 'assets/images/Logo_XL 1.png';
import { menuItems } from 'routes/sideBar.routes';
import { RootState } from 'slices';
import { hideSideBar, showSideBar } from 'slices/layout';

import MenuItems from './Menu';

function Sidebar() {
  const sidebarRef = useRef();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isTabletMid = useMediaQuery({ query: '(max-width: 768px)' });
  const { sideBarOpen } = useSelector((state: RootState) => state.layout);

  useEffect(() => {
    if (isTabletMid) {
      dispatch(hideSideBar());
    } else {
      dispatch(showSideBar());
    }
  }, [isTabletMid]);

  useEffect(() => {
    isTabletMid && dispatch(hideSideBar());
  }, [pathname]);

  const Nav_animation =
    isTabletMid || !sideBarOpen
      ? {
          open: {
            x: 0,
            width: '22rem',
            // transition: {
            //   damping: 40,
            // },
          },
          closed: {
            x: -300,
            width: 0,
            // transition: {
            //   damping: 40,
            //   delay: 0.15,
            // },
          },
        }
      : {
          open: {
            width: '22rem',
            // transition: {
            //   damping: 40,
            // },
          },
          closed: {
            x: -300,
            width: 0,
            // transition: {
            //   damping: 40,
            // },
          },
        };

  return (
    <>
      <motion.div
        style={{ display: 'none' }}
        ref={sidebarRef}
        variants={Nav_animation}
        initial={{ x: isTabletMid ? -300 : 0 }}
        animate={sideBarOpen ? 'open' : 'closed'}
        className={classNames('bg-white text-gray shadow-xl z-[100] overflow-hidden sidebar', {
          bar_open: sideBarOpen,
        })}
      >
        <div className="sidebar_content fixed z-50">
          <div className={classNames('mx-auto p-4')}>
            <img src={CHATBOT_LOGO} width={180} alt="chatbot-logo" className="cursor-pointer" />
          </div>
          <ul className="whitespace-pre px-4 text-[0.9rem] py-5 flex flex-col gap-1 font-medium overflow-x-hidden scrollbar-thin scrollbar-track-white scrollbar-thumb-slate-100">
            <MenuItems menuItems={menuItems} open={sideBarOpen} />
          </ul>
        </div>
      </motion.div>
    </>
  );
}

export default Sidebar;
