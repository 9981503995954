import * as React from 'react';
import PropTypes from 'prop-types';

function Disable(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color = '#fff', width = 24, height = 24 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_898_528)">
        <path
          d="M20 16L16 20"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.99994 12L11.9999 17L10.4999 18.5C10.1736 18.8371 9.78344 19.1059 9.35219 19.2907C8.92094 19.4755 8.45722 19.5727 7.98805 19.5765C7.51888 19.5803 7.05364 19.4907 6.61944 19.3129C6.18525 19.1351 5.79077 18.8727 5.45901 18.5409C5.12725 18.2092 4.86482 17.8147 4.68704 17.3805C4.50925 16.9463 4.41965 16.4811 4.42346 16.0119C4.42727 15.5427 4.52441 15.079 4.70922 14.6477C4.89404 14.2165 5.16283 13.8263 5.49994 13.5L6.99994 12Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17 12.0001L12 7.00006L13.5 5.50006C13.8263 5.16295 14.2165 4.89416 14.6477 4.70934C15.079 4.52453 15.5427 4.42739 16.0119 4.42358C16.4811 4.41977 16.9463 4.50937 17.3805 4.68716C17.8147 4.86495 18.2092 5.12737 18.5409 5.45913C18.8727 5.7909 19.1351 6.18537 19.3129 6.61957C19.4907 7.05376 19.5803 7.519 19.5765 7.98817C19.5727 8.45734 19.4755 8.92106 19.2907 9.35231C19.1059 9.78356 18.8371 10.1737 18.5 10.5001L17 12.0001Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 21L5.5 18.5"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.5 5.5L21 3"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 11L8 13"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13 14L11 16"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16 16L20 20"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_898_528">
          <rect width={width} height={height} fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
}
Disable.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Disable;
