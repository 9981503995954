import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Textarea } from 'react-daisyui';

import { CustomSelect, PrimaryButton } from 'components';
import { Close, Confirm, Delete, Save } from 'components/Icons';

function AddTemplateModal(props) {
  const { show, toggleModal, handleUpdate, data } = props;

  const [formData, setFormData] = useState<any>({ isPrivate: true, ...data });

  // handle the input text change
  const handleInputChange = (event: { target: { id: any; value: any } }) => {
    const { id, value } = event.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  // handle the input text change
  const handleFieldInputChange = (event) => {
    // Use the functional form of setFormData to ensure you're working with the latest state
    setFormData((prevFormData) => {
      const { value, id, checked } = event.target;
      if (id === 'isPrivate') {
        return { ...prevFormData, [id]: checked };
      }
      return { ...prevFormData, [id]: value };
    });
  };
  return (
    <Modal
      open={show}
      className="top-0 left-0 right-0 p-0 border border-gainsboroBlue rounded-lg z-50 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(auto-1rem)] max-w-2xl max-h-full"
    >
      <Modal.Header className="font-bold pb-2 mb-2 p-4">
        <div className="flex justify-between items-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 w-fit flex-wrap items-center">
            <div className="text-base font-bold font-ptScanCap">
              {data?.isEdit ? 'Edit Message Template' : 'Add a Message Template'}
            </div>
          </div>
          <div className="text-sm font-normal bg-red">
            <Button
              size="xs"
              shape="circle"
              className="btn btn-xs btn-circle border-2 bg-white border-rasinBlack font-bold"
              onClick={toggleModal}
            >
              x
            </Button>
          </div>
        </div>
        <hr className="mt-4" />
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="p-8 mb-2 -mt-4 grid grid-cols-1">
            <div className="flex justify-between items-center">
              <span className="text-sm font-normal font-lato py-3">Enter the message</span>
              <div className="flex gap-2">
                <span className="text-xs font-lato">
                  {`${formData?.isPrivate ? 'Private' : 'Public'}`}
                </span>
                <input
                  id="isPrivate"
                  type="checkbox"
                  checked={formData?.isPrivate}
                  onChange={handleFieldInputChange}
                  className="toggle toggle-sm bg-quickSilver border-quickSilver"
                />
              </div>
            </div>
            <Textarea
              id="messageBody"
              className="w-full h-20 mt-1 text-sm font-lato"
              value={formData?.messageBody}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="w-full pt-8 pb-8 pl-8 mt-3 border-t bg-culturedGray flex flex-wrap gap-3">
          <PrimaryButton
            text={data?.isEdit ? 'Update' : 'Save'}
            btnColor="bg-spaceCadetBlue80"
            btnHoverColor="hover:bg-spaceCadetBlue"
            color="white"
            icon={<Save />}
            radius="2xl"
            handleOnClick={() => handleUpdate(formData)}
          />
          {data?.isEdit && (
            <PrimaryButton
              text="Delete"
              btnColor="bg-begoniaRed"
              btnHoverColor="hover:bg-begoniaRed"
              color="white"
              icon={<Delete color="white" />}
              radius="2xl"
              handleOnClick={() => handleUpdate({ ...formData, deleted: true })}
            />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

AddTemplateModal.propTypes = {
  show: PropTypes.bool,
  toggleModal: PropTypes.func,
  handleUpdate: PropTypes.func,
  data: PropTypes.any,
};

export default AddTemplateModal;
