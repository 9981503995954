import React from 'react';
import PropTypes from 'prop-types';

function Status(props) {
  const {
    label,
    type,
    color = 'white',
    bgColor = 'bg-northTexasGreen',
    borderColor = 'bg-northTexasGreen',
    className = 'font-extrabold',
    icon,
  } = props;
  let statusBgColor = bgColor;
  let statusBorderColor = borderColor;
  let textColor = color;

  if (type?.toLowerCase() === 'inactive') {
    statusBgColor = 'bg-coralRed';
  } else if (type?.toLowerCase() === 'active') {
    statusBgColor = 'bg-northTexasGreen';
  } else if (type?.toLowerCase() === 'unapproved') {
    statusBgColor = 'bg-lotionLightGray';
    statusBorderColor = 'gainsboroBlue';
    textColor = 'rasinBlack';
  }

  function formatLabel(text: string) {
    if (text?.toLowerCase() === 'unapproved') return 'UNAPPROVED';
    if (text?.toLowerCase() === 'inactive') return 'INACTIVE';
    if (text?.toLowerCase() === 'active') return 'ACTIVE';
    return text;
  }

  return (
    <div>
      <div
        className={`badge text-${textColor} ${className} p-3 text-center font-lato items-center text-xs ${statusBgColor} border-${statusBorderColor}`}
      >
        {formatLabel(label)}
        {icon && <span className="ml-1">{icon}</span>}
      </div>
    </div>
  );
}

Status.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  color: PropTypes.string,
  bgColor: PropTypes.string,
  borderColor: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.any,
};

export default Status;
