import React from 'react';
import PropTypes from 'prop-types';

import { Delete } from '../Icons';

function DeleteButton(props) {
  const { onClick } = props;
  return (
    <div>
      <div className="flex items-center">
        <button
          type="button"
          className="flex bg-begoniaRed font-ptScanCap rounded-2xl items-center px-3 py-2 text-center hover:opacity-80"
          onClick={onClick}
        >
          <Delete color="white" />
          <span className="text-white capitalize text-xs pl-1 font-bold">Delete</span>
        </button>
      </div>
    </div>
  );
}

DeleteButton.propTypes = {
  onClick: PropTypes.func,
};

export default DeleteButton;
