/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';
import { toolOptions } from '../components/constants';

function ToolBar(props) {
  const { addNewNode } = props;

  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <div className="tool-bar__container overflow-y-auto overflow-x-hidden max-h-[70vh]">
      {toolOptions.map((option) => {
        return (
          <div
            key={option?.toolName}
            onDragStart={(event) => onDragStart(event, option?.toolName)}
            draggable
            className="tool-option bg-white hover:shadow-xl"
            onClick={() => addNewNode(option?.toolName)}
          >
            {option?.icon}
            <span>{option.title}</span>
          </div>
        );
      })}
    </div>
  );
}

ToolBar.propTypes = {
  addNewNode: PropTypes.func,
};

export default ToolBar;
