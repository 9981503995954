import moment from 'moment';
import { toast } from 'react-toastify';

import { cleanExcelColumns, exportSingleSheetToExcel } from 'utils/excel';
import { InstanceType, MessengerConnectedVia } from 'enums/messenger.enum';

import { excelHeaders } from './constants';

export const handleExportExcel = async (docs: any[]) => {
  if (docs.length === 0) {
    toast.warning('No records found');
    return;
  }

  const mappedColumns = excelHeaders.map((x) => ({
    header: x.value,
    key: x.key,
    width: 25,
  }));

  // Clean the columns by removing those with empty labels
  const cleanedColumns = cleanExcelColumns(mappedColumns);
  const rows = docs; // Get the data rows

  const mappedRowData = rows.map((x) => ({
    ...x,
    createdAt: x.createdAt ? moment(x.createdAt).fromNow() : 'N/A',
  }));

  try {
    await exportSingleSheetToExcel({
      worksheetName: 'messenger-instances-list', // Name of the worksheet in the Excel file
      columns: cleanedColumns, // Cleaned columns for the Excel file
      rows: mappedRowData, // Mapped and formatted row data for the Excel file
      fileName: 'Messenger Instances List', // Name of the exported Excel file
    });
  } catch (e) {
    toast.error('Something went wrong, Try again');
  }
};

export function formatMessengerConnectedVia(val: string) {
  if (!val) {
    return '';
  }

  if (val === MessengerConnectedVia.WebsiteWidget) {
    return 'Web Widget';
  }

  return '';
}

export function formatMessengerInstanceType(val: string) {
  if (!val) {
    return '';
  }

  if (val === InstanceType.Chatbot) {
    return 'Chatbot';
  }

  if (val === InstanceType.DirectMessaging) {
    return 'Direct Messaging';
  }

  return '';
}

export const mapDocs = (data: any[]) => {
  const docs = data.map((val) => ({
    ...val,
    connectedVia: formatMessengerConnectedVia(val.connectedVia),
    instanceType: formatMessengerInstanceType(val.instanceType),
  }));
  return docs;
};
