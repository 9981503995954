import _ from 'lodash';

import instance from 'api';
import UrlBuilder from 'api/urlQueryBuilder';

import { CONVERSATIONS_REVIEWS } from 'api/apiEndpoints';

export const fetchConversationsReviewList = (id: string, params: any) => {
  const { page = 1, limit = 10, sort = 'createdAt', order = -1, ratingFrom } = params;

  // creates a new object with only the non-null, non-undefined values from the extracted variables
  const queryParams = _.pickBy({
    page,
    limit,
    sort,
    order,
    ratingFrom,
  });
  const url = UrlBuilder()
    .setUrl(CONVERSATIONS_REVIEWS.GET_CONVERSATION_REVIEWS_LIST)
    .setQueryParams({ ...queryParams })
    .setPathParams({ id })
    .build();
  return instance.get(url);
};

export async function getConversationReviewById(id: string) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder
    .setUrl(CONVERSATIONS_REVIEWS.GET_CONVERSATION_REVIEWS_BY_ID)
    .setPathParams({ id })
    .build();
  return instance.get(url);
}

export function createConversationReview(data) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(CONVERSATIONS_REVIEWS.CREATE_CONVERSATION_REVIEWS_BY_ID).build();
  return instance.post(url, data);
}

export async function updateConversationReview(id: string, data) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder
    .setUrl(CONVERSATIONS_REVIEWS.UPDATE_CONVERSATION_REVIEWS_BY_ID)
    .setPathParams({ id })
    .build();
  return instance.patch(url, data);
}
