/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDebounce } from 'use-debounce';

import SearchBar from 'components/SearchBar';
import UserCard from 'components/Cards/UserCard';

import Tabs from './Tabs';
import { tabs } from './Tabs/constants';

function Conversations(props: any) {
  const {
    handleOpen,
    conversationList,
    setConversationList,
    setIsNewChat,
    takeChat,
    setSelectedTab,
    selectedTab,
    triggerChild,
    setTriggerChild,
    setAllMessages,
    setContactData,
    setUrlSearchParams,
    replaceUrlParamsHandler,
    setLoadedConversationId,
    customLoading,
    setCustomLoading,
  } = props;

  const [searchBy, setSearchBy] = useState('');

  const [debouncedSearchBy] = useDebounce(searchBy, 500);

  // handle the input text change
  const handleInputChange = (event: { target: { id: any; value: any } }) => {
    const { value } = event.target;
    setSearchBy(value);
  };

  return (
    <div className="m-4">
      <div className="mb-4">
        <Tabs
          tabs={tabs}
          setConversationList={setConversationList}
          debouncedSearchBy={debouncedSearchBy}
          searchBy={searchBy}
          triggerChild={triggerChild}
          setIsNewChat={setIsNewChat}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          setAllMessages={setAllMessages}
          setUrlSearchParams={setUrlSearchParams}
          replaceUrlParamsHandler={replaceUrlParamsHandler}
          setContactData={setContactData}
          setLoadedConversationId={setLoadedConversationId}
          setCustomLoading={setCustomLoading}
          customLoading={customLoading}
        />
      </div>
      <div className="mb-4">
        <SearchBar
          placeholder="Search Messages"
          onSearch={handleInputChange}
          searchByInput={searchBy}
        />
      </div>
      <div className="tab-layout_custom-scrollbar overflow-y-auto overflow-x-hidden max-h-[calc(100vh-220px)]">
        {!customLoading.conversationsLoading ? (
          conversationList.map((obj) => (
            <UserCard
              key={obj._id}
              {...obj}
              handleOpen={handleOpen}
              setContactData={setContactData}
              setTriggerChild={setTriggerChild}
              ringColor={obj.selected ? 'northTexasGreen' : 'blondYellow'}
              takeChat={takeChat}
            />
          ))
        ) : (
          <div className="text-center">
            <span className="loading loading-dots loading-md" />
          </div>
        )}
      </div>
    </div>
  );
}

Conversations.propTypes = {
  handleOpen: PropTypes.func,
  conversationList: PropTypes.array,
  setConversationList: PropTypes.func,
  setIsNewChat: PropTypes.func,
  takeChat: PropTypes.func,
  selectedTab: PropTypes.number,
  setSelectedTab: PropTypes.func,
  triggerChild: PropTypes.object,
  setTriggerChild: PropTypes.func,
  setAllMessages: PropTypes.func,
  setContactData: PropTypes.func,
  setUrlSearchParams: PropTypes.func,
  replaceUrlParamsHandler: PropTypes.func,
  setLoadedConversationId: PropTypes.func,
  customLoading: PropTypes.object,
  setCustomLoading: PropTypes.func,
};

export default Conversations;
