import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Handle, Position } from 'reactflow';

import { RootState } from 'slices';
import { IFlowPayload } from 'slices/flow';
import { NODE_TYPES, nodeStyle } from '../../constants';
import { IAPIRequestData } from '../../DrawerContents/APIRequestDrawer/interfaces';

function APIRequestNode(props) {
  const { id, data, selected } = props;

  const flowData = useSelector((state: RootState) => state.flow);

  const [apiRequestSettings, setApiRequestSettings] = useState<IAPIRequestData>();
  const [url, setUrl] = useState<string>();
  // tracks the validity of the node and highlight invalid nodes
  const [isNodeValid, setIsNodeValid] = useState(true);
  const [nodeErrors, setNodeErrors] = useState<any>();

  useEffect(() => {
    // get selected api request node data from flowData array: condition list
    const apiRequestObj = flowData.nodeConfigs.find((node) => node.nodeId === id) || {};

    if (apiRequestObj) {
      const { apiRequestSettings: apiRequestSettingsObj } = apiRequestObj;
      const { url: apiRequestUrl } = apiRequestSettingsObj || {};

      setApiRequestSettings(apiRequestSettingsObj);
      setUrl(apiRequestUrl);

      if (apiRequestObj?.isValid !== undefined && apiRequestObj?.isValid === false) {
        setIsNodeValid(false);
        setNodeErrors(apiRequestObj?.errors);
      } else {
        setIsNodeValid(true);
        setNodeErrors([]);
      }
    }
  }, [flowData]);

  return (
    <div
      className={`api-request-node drop-shadow-lg opacity-80 ${
        selected ? 'border-2 border-[#FCA5A5]' : ''
      }${isNodeValid ? '' : 'border-2 border-red-500'} `}
    >
      <div>
        <div className="api-request-node__heading">{NODE_TYPES[data?.heading]?.label}</div>

        <div className="api-request-node__content">
          <p className="main-text"> Retrieve data from an external URL</p>
          {url && <p className="external-api-link">{url}</p>}

          {url && (
            <div className="api-request-handler space-y-1">
              <div className="flex relative justify-end">
                <div className="success-handler">
                  <span className="handler-text handler-text--success">Done</span>
                  <Handle
                    type="source"
                    position={Position.Right}
                    id={`apiRequest-success-${id}`}
                    className="!bg-green-500"
                    style={Position.Left && { ...nodeStyle, top: 15, right: 5 }}
                  />
                </div>
              </div>
              <div className="flex relative justify-end">
                <div className="error-handler">
                  <span className="handler-text handler-text--error">Error</span>
                  <Handle
                    type="source"
                    position={Position.Right}
                    id={`apiRequest-error-${id}`}
                    className="!bg-red-500"
                    style={Position.Left && { ...nodeStyle, top: 15, right: 5 }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Handle
        type="target"
        position={Position.Left}
        id={`apiRequest-start-${id}`}
        className="!bg-blue-500"
        style={Position.Left && { ...nodeStyle, top: 18, left: -10 }}
      />
    </div>
  );
}

APIRequestNode.propTypes = {
  id: PropTypes.string,
  data: PropTypes.any,
  selected: PropTypes.bool,
};

export default APIRequestNode;
