import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ICarouselSetting, ISlide } from '../../MessageDrawer/interfaces';
import AddCarouselButtonPopUp from './AddCarouselButtonPopUp';

interface ICarouselButtonProps {
  id: string;
  slideNumber: number;
  buttonText: string;
  buttonUrl: string;
  setEditPopupVisible: React.Dispatch<React.SetStateAction<boolean>>;
  type?: string;
  componentData: ISlide;
  setCarouselSettings: React.Dispatch<React.SetStateAction<ICarouselSetting>>;
}

function CarouselButton(props: ICarouselButtonProps) {
  const {
    id,
    slideNumber,
    buttonText,
    buttonUrl,
    setEditPopupVisible,
    type,
    componentData,
    setCarouselSettings,
  } = props;

  const [isPopupVisible, setPopupVisible] = useState(false);

  const handleAddButtonClick = () => {
    setPopupVisible(!isPopupVisible);
    setEditPopupVisible((prev) => !prev);
  };

  return (
    <div className="relative">
      <button
        type="button"
        className="border-gray-300 hover:bg-[#F2F2F3] border-2 text-left p-3 w-full text-[#0b99b2] text-sm -mt-[1px]"
        onClick={handleAddButtonClick}
      >
        <span>{buttonText}</span>
        <br />
        {buttonUrl && <span className="text-black mt-1">{buttonUrl}</span>}
      </button>
      {/* Popup component */}
      {isPopupVisible && (
        <AddCarouselButtonPopUp
          buttonId={id}
          slideNumber={slideNumber}
          buttonText={buttonText}
          buttonUrl={buttonUrl}
          type={type}
          componentData={componentData}
          setPopupVisible={setPopupVisible}
          setEditPopupVisible={setEditPopupVisible}
          setCarouselSettings={setCarouselSettings}
          editMode
        />
      )}
    </div>
  );
}

CarouselButton.propTypes = {
  id: PropTypes.string,
  slideNumber: PropTypes.number,
  buttonText: PropTypes.string,
  buttonUrl: PropTypes.string,
  setEditPopupVisible: PropTypes.func,
  type: PropTypes.string,
  componentData: PropTypes.object,
  setCarouselSettings: PropTypes.func,
};

export default CarouselButton;
