import React from 'react';
import PropTypes from 'prop-types';
import { BotLocaleStatus } from 'enums/aws/lex.enum';

interface BotAlertsProps {
  formData: any;
  status: string | null;
}

function BotAlerts(props: BotAlertsProps) {
  const { formData, status } = props;
  return (
    <>
      {!formData._id && !formData.isReady && (
        <div role="alert" className="alert alert-info my-4 bg-honeydew">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className="stroke-current shrink-0 w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span>Bot is Preparing</span>
        </div>
      )}
      {(status === BotLocaleStatus.NotBuilt || formData.buildPending === true) && (
        <div role="alert" className="alert alert-info my-4 bg-honeydew">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className="stroke-current shrink-0 w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span>Bot Needs to be Built</span>
        </div>
      )}
    </>
  );
}

BotAlerts.propTypes = {
  formData: PropTypes.any.isRequired,
  status: PropTypes.string,
};

export default BotAlerts;
