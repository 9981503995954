/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable max-len */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toggleSideBar } from 'slices/layout';
import { takenTheChat } from 'slices/event';
import classNames from 'classnames';
import { toast } from 'react-toastify';

import { RootState } from 'slices';
import { Menu, Search } from 'components/Icons';
import CHATBOT_LOGO from 'assets/images/Logo_XL 1.png';
import { PATHS } from 'routes/paths';
import useSocketEvent from 'hooks/useSocketEvent';
import { SocketEvents } from 'enums/socket.enum';
import NewMessage from 'components/Notifications/NewMessage';
import { takeChat } from 'services/conversation.service';
import { apiErrorHandler } from 'api/handler';
import { PromptModal } from 'components';

import AgentToggle from './components/AgentToggle';
import UserManagementMenu from './components/UserManagementMenu';
import { extractFirstWordAfterSlash, popAndReturnLast } from './utils';

function NavBar() {
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const { event } = useSocketEvent();

  const location = extractFirstWordAfterSlash(pathname);

  const navigate = useNavigate();

  const { sideBarOpen } = useSelector((state: RootState) => state.layout);

  const [showNotification, setShowNotification] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState<any>({});
  const [notificationArr, setNotificationArr] = useState([]);

  const [promptOptions, setPromptOptions] = useState<any>({});

  useEffect(() => {
    if (event?.data && event?.type === SocketEvents.ChannelAssignedToConversation) {
      if (notificationArr.length === 0) {
        setSelectedNotification(event?.data);
      }
      setNotificationArr([...notificationArr, event?.data]);
      setShowNotification(true);
    }
  }, [event]);

  const logoAnimationVariants = {
    hidden: {
      opacity: 0,
      x: -50,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        ease: 'easeInOut',
        duration: 0.5, // Adjust the duration as needed
      },
    },
  };

  const notificationAnimationVariants = {
    hidden: {
      opacity: 0,
      y: -100,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        ease: 'easeIn',
        duration: 0.5, // Adjust the duration as needed
      },
    },
  };

  const handleClose = () => {
    const { lastNotification: nextNotification, notificationArr: newNotificationArr } =
      popAndReturnLast(notificationArr, selectedNotification);

    if (nextNotification) {
      setSelectedNotification(nextNotification);
      setNotificationArr([...newNotificationArr]);
      setShowNotification(true);
    } else {
      setShowNotification(false);
      setNotificationArr([]);
    }
  };

  const handleTakeChat = (data: any) => {
    setPromptOptions({
      show: true,
      title: `Take Chat ${data?.sequenceId}`,
      message: 'Do you want to take this chat?',
      onConfirm: () => handleUpdateTakeChat(data?.conversationId),
      type: 'TakeChat',
    });
  };

  const handleUpdateTakeChat = async (conversationId: string) => {
    try {
      const { data } = await takeChat(conversationId);

      if (data.status) {
        setPromptOptions({
          show: false,
          ...promptOptions,
        });
        toast.success('Successfully took the chat');
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      handleClose();
      if (location === 'inbox') {
        dispatch(takenTheChat());
      }
      navigate(`${PATHS.INBOX.DEFAULT.url}?i=${conversationId}`);
    }
  };

  return (
    <>
      <motion.div className="navbar items-center gap-2.5 font-medium bg-primary flex flex-row justify-between">
        <div className="ml-5 cursor-pointer">
          <Menu color="#FFF" onClick={() => dispatch(toggleSideBar())} />

          {!sideBarOpen && (
            <div className={classNames('mx-auto p-4')}>
              <motion.img
                src={CHATBOT_LOGO}
                width={180}
                alt="chatbot-logo"
                className="cursor-pointer"
                initial="hidden"
                animate="visible"
                variants={logoAnimationVariants}
                onClick={() => {
                  navigate(PATHS.INBOX.DEFAULT.url);
                }}
              />
            </div>
          )}
        </div>

        <div className="relative block sm:flex items-center mb-2 sm:mb-0">
          <span className="absolute inset-y-0 left-0 flex items-center pl-2 mt-1">
            <Search color="#FFF" />
          </span>
          <input
            className="placeholder:text-sm font-lato text-sm rounded-lg h-11 py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:ring-1 sm:text-sm rg:w-80"
            placeholder="Search Cases, Customers"
            type="text"
            name="search"
            style={{ backgroundColor: '#283C6B', color: '#FFF', border: '1px solid #283C6B' }}
          />
        </div>

        <div className="space-x-4">
          {location === 'inbox' ? <AgentToggle /> : <div />}
          <UserManagementMenu />
        </div>
        {selectedNotification && showNotification && (
          <motion.div
            className="absolute right-[80px] top-2"
            initial="hidden"
            animate="visible"
            variants={notificationAnimationVariants}
          >
            <NewMessage
              handleTakeChat={handleTakeChat}
              handleClose={handleClose}
              {...selectedNotification}
            />
          </motion.div>
        )}
      </motion.div>
      {promptOptions.show && (
        <PromptModal
          onCancel={() => {
            setPromptOptions({ ...promptOptions, show: false });
          }}
          {...promptOptions}
          loading={false}
        />
      )}
    </>
  );
}

export default NavBar;
