/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Controller, useController } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import classNames from 'classnames';
import PhoneInputWithCountrySelect from 'react-phone-number-input';

import { UserType } from 'constants/user.const';
import { CustomSelect, InputText, PrimaryButton, TransactionTable } from 'components';
import GeneralButton from 'components/Buttons/GeneralButton';
import { getValueForSelect } from 'utils/helpers';
import { Activate, Back, Save } from 'components/Icons';
import { hasAccess, hasPermissionAccess } from 'utils/auth';
import { useSelector } from 'react-redux';
import { COLLECTIONS } from 'constants/db';
import { PATHS } from 'routes/paths';

import { RootState } from 'slices';
import { GENDER } from 'constants/contact.const';
import { PERMISSIONS } from 'constants/permissions';
import { ModalTypes, transactionTableHeaders, userTypes } from './constants';
import FormEditHeader from './containers/FormEditHeader';
import ToggleDisableBtn from './components/ToggleDisableBtn';

function FormPage(props) {
  const {
    handleSubmit,
    onSubmit,
    register,
    errors,
    handleInputChange,
    formData,
    handleSelectChange,
    editMode,
    customLoading,
    control,
    clientsOptions = [],
    handleCheckChange,
    rolesOptions = [],
    triggerChild,
    handleOnApproveAccount,
    handleOnToggleAccountDisable,
    handlePhoneNoChange,
  } = props;

  const navigate = useNavigate();

  const locationData = useSelector((state: RootState) => state.user.locationData);

  const { field: clientIdField } = useController({ name: 'clientId', control });
  const { field: userTypeField } = useController({ name: 'userType', control });
  const { field: rolesField } = useController({ name: 'roles', control });
  const { field: genderField } = useController({ name: 'gender', control });

  useEffect(() => {
    if (formData.clientId) {
      const { onChange } = clientIdField;
      onChange(formData.clientId);
    }
  }, [formData?.clientId]);

  useEffect(() => {
    if (formData.userType) {
      const { onChange } = userTypeField;
      onChange(formData.userType);
    }
  }, [formData?.userType]);

  useEffect(() => {
    if (formData.roles) {
      const { onChange } = rolesField;
      onChange(formData.roles);
    }
  }, [formData?.roles]);

  useEffect(() => {
    if (formData.gender) {
      const { onChange } = genderField;
      onChange(formData.gender);
    }
  }, [formData?.gender]);

  return (
    <div className="page">
      <div className="flex justify-between items-center">
        <div>
          {!editMode ? (
            <span className="text-spaceCadetBlue font-bold text-lg font-ptScanCap pb-4">
              Create a New User Account
            </span>
          ) : (
            <FormEditHeader formData={formData} />
          )}
        </div>
        <div>
          <GeneralButton
            text="Back"
            btnColor="bg-spaceCadetBlue80"
            color="white"
            radius="2xl"
            textSize="xs"
            btnHoverColor="hover:bg-spaceCadetBlue"
            icon={<Back />}
            handleOnClick={() => navigate(-1)}
          />
        </div>
      </div>
      <hr className="my-3" />

      {/* User form */}
      <div className="p-8 bg-white drop-shadow rounded-xl mt-6">
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="grid grid-cols-1 xs:grid-cols-1 rg:grid-cols-2 lg:grid-cols-3 gap-4">
            {hasAccess([UserType.SuperUser, UserType.SystemManagementUser]) && (
              <div className="mb-2">
                <div className="mb-2">
                  <div className="form-control">
                    <label className="label" htmlFor="client">
                      <span className="label-text text-base font-base font-lato">User Type</span>
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="userType"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <CustomSelect
                        options={userTypes}
                        error={errors.userType}
                        value={getValueForSelect(field.value, userTypes)}
                        onChange={(val) => {
                          field.onChange(val.value);
                          handleSelectChange(val, 'userType');
                        }}
                        id="userType"
                        register={register}
                        inputRef={field.ref}
                        isDisabled={editMode}
                      />
                    )}
                  />
                </div>
              </div>
            )}

            {hasAccess([UserType.SuperUser, UserType.SystemManagementUser]) &&
              formData.userType === UserType.ClientUser && (
                <div className="mb-2">
                  <div className="mb-2">
                    <div className="form-control">
                      <label className="label" htmlFor="client">
                        <span className="label-text font-normal text-base font-lato">Client</span>
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="clientId"
                      render={({ field }) => (
                        <CustomSelect
                          options={clientsOptions}
                          error={errors.clientId}
                          value={getValueForSelect(field.value, clientsOptions)}
                          onChange={(val) => {
                            field.onChange(val.value);
                            handleSelectChange(val, 'clientId');
                          }}
                          id="clientId"
                          register={register}
                          inputRef={field.ref}
                          isLoading={customLoading.fetchClients}
                          isDisabled={editMode}
                          isClearable
                        />
                      )}
                    />
                  </div>
                </div>
              )}
          </div>

          <div className="grid grid-cols-1 xs:grid-cols-1 rg:grid-cols-2 lg:grid-cols-3 gap-4">
            <div className="mb-2">
              <InputText
                type="text"
                elementId="name"
                label="Name"
                placeholder="eg: Alpha User"
                register={register}
                error={errors?.name}
                value={formData?.name}
                onChange={handleInputChange}
              />
            </div>

            <div className="mb-2">
              <label className="label cursor-pointer">
                <div className="grid grid-cols-1">
                  <span className="label-text text-base font-base font-lato">Enable Nicknames</span>
                  <div
                    className="tooltip text-left checkbox_max_w"
                    data-tip="When you activate the nickname feature, the relevant alias will appear in the chat for customers, replacing the actual name"
                  >
                    <input
                      id="enableNickName"
                      type="checkbox"
                      checked={formData.enableNickName}
                      className="checkbox mt-5"
                      onChange={handleCheckChange}
                    />
                  </div>
                </div>
              </label>
            </div>
          </div>
          {formData.enableNickName && (
            <div className="grid grid-cols-1 xs:grid-cols-1 rg:grid-cols-2 lg:grid-cols-3 gap-4">
              <div className="mb-2">
                <InputText
                  elementId="nickname"
                  label="Nickname"
                  type="text"
                  placeholder="eg: jdoe@abc.com"
                  register={register}
                  error={errors.nickname}
                  value={formData.nickname}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          )}
          <div className="grid grid-cols-1 xs:grid-cols-1 rg:grid-cols-2 lg:grid-cols-3 gap-4">
            <div className="mb-2">
              <InputText
                elementId="email"
                label="Email"
                placeholder="eg: jdoe@abc.com"
                type="email"
                register={register}
                error={errors.email}
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-control mb-4">
              <label className="label" htmlFor="primaryContactNo">
                <span className="label-text text-base font-lato">Primary Contact Number</span>
              </label>
              <Controller
                name="primaryContactNo"
                control={control}
                render={({ field }) => (
                  <PhoneInputWithCountrySelect
                    className={`input input-bordered focus:outline-none ${
                      errors?.primaryContactNo ? 'border border-begoniaRed' : ''
                    }`}
                    defaultCountry={locationData?.country_code}
                    value={formData?.primaryContactNo}
                    control={control}
                    id="primaryContactNo"
                    onChange={(e) => {
                      field.onChange(e);
                      handlePhoneNoChange('primaryContactNo', e);
                    }}
                    limitMaxLength
                  />
                )}
              />
              {errors?.primaryContactNo && (
                <label className="label relative mt-2" htmlFor="primaryContactNo">
                  <span className="label-text-alt absolute text-error">
                    {errors?.primaryContactNo?.message?.toString()}
                  </span>
                </label>
              )}
            </div>
          </div>

          <div className="grid grid-cols-1 xs:grid-cols-1 rg:grid-cols-2 lg:grid-cols-3 gap-4">
            <div className="mb-2">
              <div className="mb-2">
                <div className="form-control">
                  <label className="label" htmlFor="gender">
                    <span className="label-text text-base font-lato">Gender</span>
                  </label>
                </div>
                <Controller
                  control={control}
                  name="gender"
                  render={({ field }) => (
                    <CustomSelect
                      options={GENDER}
                      error={errors.gender}
                      value={getValueForSelect(formData?.gender, GENDER)}
                      onChange={(val) => {
                        field.onChange(val.value);
                        handleSelectChange(val, 'gender');
                      }}
                      id="gender"
                      register={register}
                      inputRef={field.ref}
                    />
                  )}
                />
              </div>
            </div>

            <div className="mb-2">
              {hasAccess([
                UserType.SuperUser,
                UserType.SystemManagementUser,
                UserType.ClientUser,
              ]) && (
                <div className="mb-2">
                  <div className="form-control">
                    <label className="label" htmlFor="roles">
                      <span className="label-text text-base font-lato">User Role</span>
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="roles"
                    render={({ field }) => (
                      <CustomSelect
                        options={rolesOptions}
                        error={errors.roles}
                        value={formData?.roles}
                        onChange={(val) => {
                          field.onChange(val.value);
                          handleSelectChange(val, 'roles');
                        }}
                        id="roles"
                        register={register}
                        inputRef={field.ref}
                        isMulti
                        isLoading={customLoading.fetchRoles}
                      />
                    )}
                  />
                </div>
              )}
            </div>
          </div>

          {hasPermissionAccess([
            PERMISSIONS.USER_MANAGEMENT.USERS.ADD_USER,
            PERMISSIONS.USER_MANAGEMENT.USERS.EDIT_USER,
          ]) && (
            <div className="mt-4 mb-4">
              <PrimaryButton
                text={editMode ? 'Update User Account' : 'Create User Account'}
                btnColor="bg-spaceCadetBlue80"
                color="white"
                radius="2xl"
                btnHoverColor="hover:bg-spaceCadetBlue"
                icon={<Save />}
                disabled={customLoading.formSubmit}
                loading={customLoading.formSubmit}
              />
            </div>
          )}
        </form>
      </div>

      {editMode && formData._id && (
        <div className="p-8 bg-white drop-shadow rounded-xl mt-6">
          <div className="flex flex-row justify-end">
            {!formData.isApproved && (
              <motion.button
                onClick={() => handleOnApproveAccount(formData, ModalTypes.ActivateAccountModal)}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.9 }}
                type="submit"
                className={classNames(
                  'px-[20px] py-[10px] rounded-2xl text-white font-ptScanCap bg-spaceCadetBlue80 hover:bg-spaceCadetBlue',
                  {
                    'bg-spaceCadetBlue80': customLoading.approveAccount,
                    'bg-spaceCadetBlue': !customLoading.approveAccount,
                  },
                )}
              >
                {customLoading.approveAccount ? (
                  <span className="loading loading-infinity loading-lg" />
                ) : (
                  <span className="text-base font-semibold flex flex-row items-center">
                    <Activate width={24} /> &nbsp; Approve
                  </span>
                )}
              </motion.button>
            )}
            {formData.isApproved && (
              <ToggleDisableBtn
                formData={formData}
                loading={customLoading.toggleAccount}
                disabled={customLoading.toggleAccount}
                handleOnToggleAccountDisable={handleOnToggleAccountDisable}
              />
            )}
          </div>
          <TransactionTable
            recordId={formData._id}
            collection={COLLECTIONS.User}
            transactionTableHeaders={transactionTableHeaders}
            triggerChild={triggerChild.transactions}
          />
        </div>
      )}
    </div>
  );
}

FormPage.propTypes = {
  handleSubmit: PropTypes.func,
  handlePhoneNoChange: PropTypes.func,
  customLoading: PropTypes.any,
  onSubmit: PropTypes.func,
  register: PropTypes.func,
  handleOnApproveAccount: PropTypes.func,
  handleOnToggleAccountDisable: PropTypes.func,
  errors: PropTypes.any,
  editMode: PropTypes.bool,
  formData: PropTypes.any,
  control: PropTypes.any,
  triggerChild: PropTypes.any,
  handleInputChange: PropTypes.func,
  handleCheckChange: PropTypes.func,
  handleSelectChange: PropTypes.func,
  clientsOptions: PropTypes.array,
  rolesOptions: PropTypes.array,
};

export default FormPage;
