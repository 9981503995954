import moment from 'moment';

/**
 * Format dateOfBirth field to a date
 * @param formData - Contact doc
 * @returns
 */
export const mapDoc = (formData) => {
  const formattedData = {
    ...formData,
    dateOfBirth: moment(formData.dateOfBirth).format('YYYY-MM-DD'),
  };
  return formattedData;
};
