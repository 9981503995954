import { InstanceType } from 'enums/messenger.enum';
import * as yup from 'yup';

const defaultAccountFormValidationSchema = {
  name: yup.string().required('Please enter Name for Messenger Instance'),
  displayName: yup.string().required('Please enter display name'),
  instanceType: yup.string().required('Please select instance type'),
  conversationEndMessage: yup.string().nullable(),
  color: yup.string().required('Please select color'),
  autoExpireConversationInMin: yup
    .number()
    .typeError('Please enter auto-expire duration (minutes)')
    .required('Please enter auto-expire duration (minutes)')
    .min(2, 'Auto-expire duration must be at least 2 minutes')
    .integer('Auto-expire duration must be an integer'),
};

export const getFormValidationSchema = (formData: any) => {
  let validationSchema: any = {
    ...defaultAccountFormValidationSchema,
    conversationExpiryMessageDisplayTimeout: yup
      .number()
      .typeError('Please enter message display timeout (minutes)')
      .integer('Message display timeout must be an integer')
      .required('Please enter message display timeout (minutes)')
      .min(1, 'Message display timeout must be at least 1 minute')
      .test(
        'is-less-than-auto-expire',
        'Message display timeout must be less than auto-expire duration',
        (value) => {
          const autoExpireDuration = formData.autoExpireConversationInMin;
          return value < autoExpireDuration;
        },
      ),
  };

  if (formData.instanceType === InstanceType.Chatbot) {
    validationSchema = {
      ...validationSchema,
      chatbotChatType: yup
        .object({
          isIntentBasedChat: yup.boolean(),
          isAiBasedChat: yup.boolean(),
          isLiveChat: yup.boolean(),
        })
        .test(
          'at-least-one-method',
          'At least one method must be selected',
          () => formData.isIntentBasedChat || formData.isAiBasedChat || formData.isLiveChat,
        ),
    };
  }

  if (formData.showPrivacyPolicyLink) {
    validationSchema = {
      ...validationSchema,
      privacyPolicyLink: yup
        .string()
        .required('Please enter privacy policy link')
        .url('Please enter a valid URL for the privacy policy link'),
    };
  }

  const schema = yup.object(validationSchema);
  return schema;
};
