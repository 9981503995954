import _ from 'lodash';

import instance from 'api';
import { AUDIT_HISTORY } from 'api/apiEndpoints';
import UrlBuilder from 'api/urlQueryBuilder';
import { IURLQueryParams } from 'interfaces/query.interface';

/**
 * Fetch paginated and filtered audit history list
 * @param {IURLQueryParams} params - Url query params )
 * @returns {} - audit history list data and pagination data
 */
export const fetchAuditHistory = (collection: string, id: string, params: IURLQueryParams) => {
  const {
    page = 1,
    limit = 20,
    sort = 'createdAt',
    order = -1,
    searchBy,
    recordId,
    searchText,
  } = params;

  // creates a new object with only the non-null, non-undefined values from the extracted variables
  const queryParams = _.pickBy({
    page,
    limit,
    sort,
    order,
    searchBy,
    recordId,
    searchText,
  });

  const url = UrlBuilder()
    .setUrl(AUDIT_HISTORY.FETCH_LIST_BY_COLLECTION_AND_RECORD)
    .setPathParams({ id, collection })
    .setQueryParams({ ...queryParams })
    .build();
  return instance.get(url);
};
