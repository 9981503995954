import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INodeConfigError, IVariable } from 'interfaces/flow.interface';
import {
  generateInitialActionFlowSettings,
  generateInitialAddCaseFlowNodeSettings,
  generateInitialAddContactFlowNodeSettings,
  generateInitialAPIRequestFlowNodeSettings,
  generateInitialCarouselFlowSettings,
  generateInitialEndChatFlowNodeSettings,
  generateInitialFilterSettings,
  generateInitialFlowNodeSettings,
  generateInitialLiveChatFlowNodeSettings,
  generateInitialMessageSettings,
  getDuplicateNode,
} from 'utils/flow';
import { cloneDeep, uniqueId } from 'lodash';
import {
  IMappingSetting,
  IUrlHeader,
  TRequestMethod,
} from 'views/Settings/FlowBuilder/components/DrawerContents/APIRequestDrawer/interfaces';
import {
  CONDITION_TYPES,
  SEGMENTATION_METHODS,
} from 'views/Settings/FlowBuilder/components/DrawerContents/FilterDrawer/constants';
import {
  ICondition,
  ISegment,
} from 'views/Settings/FlowBuilder/components/DrawerContents/FilterDrawer/interfaces';
import { ICarouselSetting } from 'views/Settings/FlowBuilder/components/DrawerContents/MessageDrawer/interfaces';

export interface IFlowPayload {
  intentId: string;
  history: any[];
  nodeConfigs: any[];
  variables: IVariable[];
  redoHistory: any[];
  flowPath: {
    nodes: any[];
    edges: any[];
  };
  flowIsSaved: boolean;
}

// Modify initialState to be an array
// ! TODO proper types
const initialState: IFlowPayload = {
  intentId: null,
  history: [], // history of all the actions and old data stored in here
  nodeConfigs: [], // all the node settings are stored in here
  variables: [], // all the global variables are stored in here
  redoHistory: [], // state which use for undo redo functionality
  flowPath: {
    // id and position of all nodes and edges and connections are stored in here
    nodes: [],
    edges: [],
  },
  flowIsSaved: false,
};

const flowSlice = createSlice({
  name: 'flow',
  initialState,
  reducers: {
    initializeFlow(state, { payload }: PayloadAction<any>) {
      const { position, nodeConfigs, ...rest } = payload;
      return { ...initialState, nodeConfigs, ...rest };
    },
    // check if the flow is saved and store the status
    handleFlowSaveStatus(state, { payload }: PayloadAction<boolean>) {
      state.flowIsSaved = payload;
    },
    resetFlow(state) {
      // resets state to initial state
      return { ...initialState };
    },
    updateFlowPath(state, { payload }: PayloadAction<any>) {
      const { nodes } = payload;
      state.flowPath.nodes = nodes;
    },
    initializeMessageSettings(state, { payload }: PayloadAction<any>) {
      const newNodeId = payload?.id;
      // Check if the nodeId already exists in the array
      const nodeIndex = state.nodeConfigs.findIndex((node) => node.nodeId === newNodeId);

      if (nodeIndex === -1) {
        const nodeConfigsCopy = cloneDeep(state.nodeConfigs);
        const flowPathCopy = cloneDeep(state.flowPath);

        const initialSettings = generateInitialMessageSettings();

        // Push the copies into the history array
        state.history.push({
          nodeConfigs: nodeConfigsCopy,
          flowPath: flowPathCopy,
        });

        // If nodeId doesn't exist, create a new object by merging with commonConfig
        state.nodeConfigs.push({
          nodeId: newNodeId,
          ...initialSettings,
          quickReplies: {
            ...initialSettings.quickReplies,
            nodeId: newNodeId,
          },
          subscriberSettings: {
            ...initialSettings.subscriberSettings,
            nodeId: newNodeId,
          },
          position: payload.position,
        });
        // state.history.push({ ...state });
      }
    },
    updateMessageSettings(state, { payload }: PayloadAction<{ nodeId: string; newData: any }>) {
      const { nodeId, newData } = payload;
      // Find the node in the array based on the nodeId
      const nodeIndex = state.nodeConfigs.findIndex((node) => node.nodeId === nodeId);

      if (nodeIndex !== -1) {
        const nodeConfigsCopy = cloneDeep(state.nodeConfigs);
        const flowPathCopy = cloneDeep(state.flowPath);

        // Push the copies into the history array
        state.history.push({
          nodeConfigs: nodeConfigsCopy,
          flowPath: flowPathCopy,
        });

        // If node exists, update it by merging with the new data
        state.nodeConfigs[nodeIndex] = {
          ...state.nodeConfigs[nodeIndex],
          ...newData,
        };

        // remove isValid and errors object
        const { isValid, errors, ...rest } = state.nodeConfigs[nodeIndex];
        state.nodeConfigs[nodeIndex] = { ...rest };
      }
    },
    initializeActionSettings(state, { payload }: PayloadAction<any>) {
      const newNodeId = payload?.id;

      // Check if the nodeId already exists in the array
      const nodeIndex = state.nodeConfigs.findIndex((node) => node.nodeId === newNodeId);

      if (nodeIndex === -1) {
        const nodeConfigsCopy = cloneDeep(state.nodeConfigs);
        const flowPathCopy = cloneDeep(state.flowPath);

        // Push the copies into the history array
        state.history.push({
          nodeConfigs: nodeConfigsCopy,
          flowPath: flowPathCopy,
        });

        const initialSettings = generateInitialActionFlowSettings();

        // If nodeId doesn't exist, create a new object by merging with commonConfig
        state.nodeConfigs.push({
          nodeId: newNodeId,
          ...initialSettings,
          position: payload.position,
        });
      }
    },
    updateActionSettings(state, { payload }: PayloadAction<{ nodeId: string; newData: any }>) {
      const { nodeId, newData } = payload;
      // Find the node in the array based on the nodeId
      const nodeIndex = state.nodeConfigs.findIndex((node) => node.nodeId === nodeId);

      if (nodeIndex !== -1) {
        const nodeConfigsCopy = cloneDeep(state.nodeConfigs);
        const flowPathCopy = cloneDeep(state.flowPath);

        // Push the copies into the history array
        state.history.push({
          nodeConfigs: nodeConfigsCopy,
          flowPath: flowPathCopy,
        });

        // If node exists, update it by merging with the new data
        state.nodeConfigs[nodeIndex] = {
          ...state.nodeConfigs[nodeIndex],
          ...newData,
        };

        // remove isValid and errors object
        const { isValid, errors, ...rest } = state.nodeConfigs[nodeIndex];
        state.nodeConfigs[nodeIndex] = { ...rest };
      }
    },

    initializeFlowSettings(state, { payload }: PayloadAction<any>) {
      const { id: nodeId } = payload;
      // Check if the nodeId already exists in the array
      const nodeIndex = state.nodeConfigs.findIndex((node) => node.nodeId === nodeId);

      if (nodeIndex === -1) {
        // If nodeId doesn't exist, create a new object by merging with commonConfig
        const nodeConfigsCopy = cloneDeep(state.nodeConfigs);
        const flowPathCopy = cloneDeep(state.flowPath);

        // Push the copies into the history array
        state.history.push({
          nodeConfigs: nodeConfigsCopy,
          flowPath: flowPathCopy,
        });

        const initialSettings = generateInitialFlowNodeSettings();

        state.nodeConfigs.push({
          nodeId,
          ...initialSettings,
          position: payload.position,
        });
      }
      // Note: If you want to return the updated state, you can use `return [...state];`
      // However, Redux Toolkit handles this automatically, so you can omit the return statement
    },
    updateFlowSettings(state, { payload }: PayloadAction<any>) {
      const { id: nodeId, data } = payload;
      // Check if the nodeId already exists in the array
      const nodeIndex = state.nodeConfigs.findIndex((node) => node.nodeId === nodeId);

      if (nodeIndex !== -1) {
        const nodeConfigsCopy = cloneDeep(state.nodeConfigs);
        const flowPathCopy = cloneDeep(state.flowPath);

        // Push the copies into the history array
        state.history.push({
          nodeConfigs: nodeConfigsCopy,
          flowPath: flowPathCopy,
        });

        // If nodeId exist, update
        const { content } = data;
        // update the flowSettings
        state.nodeConfigs[nodeIndex].flowSettings = {
          _id: content?.value,
          flowName: content?.label,
        };

        // remove isValid and errors object
        const { isValid, errors, ...rest } = state.nodeConfigs[nodeIndex];
        state.nodeConfigs[nodeIndex] = { ...rest };
      }
    },
    initializeLiveChatSettings(state, { payload }: PayloadAction<any>) {
      const { id: nodeId } = payload;
      // Check if the nodeId already exists in the array
      const nodeIndex = state.nodeConfigs.findIndex((node) => node.nodeId === nodeId);

      if (nodeIndex === -1) {
        // If nodeId doesn't exist, create a new object by merging with commonConfig
        const nodeConfigsCopy = cloneDeep(state.nodeConfigs);
        const flowPathCopy = cloneDeep(state.flowPath);

        // Push the copies into the history array
        state.history.push({
          nodeConfigs: nodeConfigsCopy,
          flowPath: flowPathCopy,
        });

        const initialSettings = generateInitialLiveChatFlowNodeSettings();

        state.nodeConfigs.push({
          nodeId,
          ...initialSettings,
          position: payload.position,
        });
      }
      // Note: If you want to return the updated state, you can use `return [...state];`
      // However, Redux Toolkit handles this automatically, so you can omit the return statement
    },
    initializeEndChatSettings(state, { payload }: PayloadAction<any>) {
      const { id: nodeId } = payload;
      // Check if the nodeId already exists in the array
      const nodeIndex = state.nodeConfigs.findIndex((node) => node.nodeId === nodeId);

      if (nodeIndex === -1) {
        // If nodeId doesn't exist, create a new object by merging with commonConfig
        const nodeConfigsCopy = cloneDeep(state.nodeConfigs);
        const flowPathCopy = cloneDeep(state.flowPath);

        // Push the copies into the history array
        state.history.push({
          nodeConfigs: nodeConfigsCopy,
          flowPath: flowPathCopy,
        });

        const initialSettings = generateInitialEndChatFlowNodeSettings();

        state.nodeConfigs.push({
          nodeId,
          ...initialSettings,
          position: payload.position,
        });
      }
      // Note: If you want to return the updated state, you can use `return [...state];`
      // However, Redux Toolkit handles this automatically, so you can omit the return statement
    },
    updateLiveChatSettings(state, { payload }: PayloadAction<any>) {
      const { id: nodeId, data } = payload;
      // Check if the nodeId already exists in the array
      const nodeIndex = state.nodeConfigs.findIndex((node) => node.nodeId === nodeId);

      if (nodeIndex !== -1) {
        const nodeConfigsCopy = cloneDeep(state.nodeConfigs);
        const flowPathCopy = cloneDeep(state.flowPath);

        // Push the copies into the history array
        state.history.push({
          nodeConfigs: nodeConfigsCopy,
          flowPath: flowPathCopy,
        });

        // If nodeId exist, update
        const { content } = data;
        // update the flowSettings
        state.nodeConfigs[nodeIndex].liveChatSettings = {
          _id: content?.value,
          channelName: content?.label,
        };

        // remove isValid and errors object
        const { isValid, errors, ...rest } = state.nodeConfigs[nodeIndex];
        state.nodeConfigs[nodeIndex] = { ...rest };
      }
    },
    initializeContactSettings(state, { payload }: PayloadAction<any>) {
      const { id: nodeId } = payload;
      // Check if the nodeId already exists in the array
      const nodeIndex = state.nodeConfigs.findIndex((node) => node.nodeId === nodeId);

      if (nodeIndex === -1) {
        const nodeConfigsCopy = cloneDeep(state.nodeConfigs);
        const flowPathCopy = cloneDeep(state.flowPath);

        // Push the copies into the history array
        state.history.push({
          nodeConfigs: nodeConfigsCopy,
          flowPath: flowPathCopy,
        });

        const initialSettings = generateInitialAddContactFlowNodeSettings();

        state.nodeConfigs.push({
          nodeId,
          ...initialSettings,
        });
      }
    },
    updateContactSettings(state, { payload }: PayloadAction<any>) {
      const { nodeId, ...rest } = payload;
      // Check if the nodeId already exists in the array
      const nodeIndex = state.nodeConfigs.findIndex((node) => node.nodeId === nodeId);

      if (nodeIndex !== -1) {
        const nodeConfigsCopy = cloneDeep(state.nodeConfigs);
        const flowPathCopy = cloneDeep(state.flowPath);

        // Push the copies into the history array
        state.history.push({
          nodeConfigs: nodeConfigsCopy,
          flowPath: flowPathCopy,
        });

        // update the contactSettings
        state.nodeConfigs[nodeIndex].contactSettings = {
          ...rest,
        };
      }
    },
    initializeCaseSettings(state, { payload }: PayloadAction<any>) {
      const { id: nodeId, ...rest } = payload;
      // Check if the nodeId already exists in the array
      const nodeIndex = state.nodeConfigs.findIndex((node) => node.nodeId === nodeId);

      if (nodeIndex === -1) {
        const nodeConfigsCopy = cloneDeep(state.nodeConfigs);
        const flowPathCopy = cloneDeep(state.flowPath);

        // Push the copies into the history array
        state.history.push({
          nodeConfigs: nodeConfigsCopy,
          flowPath: flowPathCopy,
        });

        const initialSettings = generateInitialAddCaseFlowNodeSettings();

        state.nodeConfigs.push({
          nodeId,
          ...rest,
          ...initialSettings,
        });
      }
    },
    updateCaseSettings(state, { payload }: PayloadAction<any>) {
      const { nodeId, ...rest } = payload;
      // Check if the nodeId already exists in the array
      const nodeIndex = state.nodeConfigs.findIndex((node) => node.nodeId === nodeId);

      if (nodeIndex !== -1) {
        const nodeConfigsCopy = cloneDeep(state.nodeConfigs);
        const flowPathCopy = cloneDeep(state.flowPath);

        // Push the copies into the history array
        state.history.push({
          nodeConfigs: nodeConfigsCopy,
          flowPath: flowPathCopy,
        });

        // update the caseSettings
        state.nodeConfigs[nodeIndex].caseSettings = {
          ...rest,
        };
      }
    },
    // filter node reducers
    initializeFilterSettings(state, { payload }: PayloadAction<any>) {
      const { id: nodeId, ...rest } = payload;

      // Check if the nodeId already exists in the array
      const nodeIndex = state.nodeConfigs.findIndex((node) => node.nodeId === nodeId);

      if (nodeIndex === -1) {
        const nodeConfigsCopy = cloneDeep(state.nodeConfigs);
        const flowPathCopy = cloneDeep(state.flowPath);

        // Push the copies into the history array
        state.history.push({
          nodeConfigs: nodeConfigsCopy,
          flowPath: flowPathCopy,
        });

        const initialSettings = generateInitialFilterSettings();

        state.nodeConfigs.push({
          nodeId,
          ...rest,
          ...initialSettings,
        });
      }
    },
    addNewFilterCondition(state, { payload }: PayloadAction<any>) {
      const { id: nodeId } = payload;

      const nodeIndex = state.nodeConfigs.findIndex((node) => node.nodeId === nodeId);

      if (nodeIndex !== -1) {
        const nodeConfigsCopy = cloneDeep(state.nodeConfigs);
        const flowPathCopy = cloneDeep(state.flowPath);

        // Push the copies into the history array
        state.history.push({
          nodeConfigs: nodeConfigsCopy,
          flowPath: flowPathCopy,
        });

        // if node exists
        const newCondition: ICondition = {
          _id: uniqueId(),
          conditionType: CONDITION_TYPES.IF,
          segmentationMethod: SEGMENTATION_METHODS.ANY,
          segments: [
            {
              _id: uniqueId(),
              criteria: null,
              condition: null,
              textPhrase: null,
            },
          ],
        };

        const { filterSettings } = state.nodeConfigs[nodeIndex];

        // add the new condition to the second-to-last position
        filterSettings.splice(filterSettings.length - 1, 0, newCondition);
      }
    },
    addNewSegmentToFilterCondition(
      state,
      { payload }: PayloadAction<{ nodeId: string; conditionId: string }>,
    ) {
      const { nodeId, conditionId } = payload;

      const nodeIndex = state.nodeConfigs.findIndex((node) => node.nodeId === nodeId);

      if (nodeIndex !== -1) {
        const nodeConfigsCopy = cloneDeep(state.nodeConfigs);
        const flowPathCopy = cloneDeep(state.flowPath);

        // Push the copies into the history array
        state.history.push({
          nodeConfigs: nodeConfigsCopy,
          flowPath: flowPathCopy,
        });

        // if node exists
        const conditionIndex = state.nodeConfigs[nodeIndex].filterSettings.findIndex(
          (condition: ICondition) => condition._id === conditionId,
        );

        if (conditionIndex !== -1) {
          const newSegment: ISegment = {
            _id: uniqueId(),
            criteria: null,
            condition: null,
            textPhrase: null,
          };

          state.nodeConfigs[nodeIndex].filterSettings[conditionIndex].segments.push(newSegment);
        }
      }
    },
    updateFilterCondition(
      state,
      {
        payload,
      }: PayloadAction<{
        nodeId: string;
        conditionId: string;
        updatedConditionData: Partial<ICondition>;
      }>,
    ) {
      const { nodeId, conditionId, updatedConditionData } = payload;

      // find the relevant node
      const nodeIndex = state.nodeConfigs.findIndex((node) => node.nodeId === nodeId);

      if (nodeIndex !== -1) {
        const nodeConfigsCopy = cloneDeep(state.nodeConfigs);
        const flowPathCopy = cloneDeep(state.flowPath);

        // Push the copies into the history array
        state.history.push({
          nodeConfigs: nodeConfigsCopy,
          flowPath: flowPathCopy,
        });

        // if node exists
        const conditionIndex = state.nodeConfigs[nodeIndex].filterSettings.findIndex(
          (condition: ICondition) => condition._id === conditionId,
        );

        if (conditionIndex !== -1) {
          state.nodeConfigs[nodeIndex].filterSettings[conditionIndex] = {
            ...state.nodeConfigs[nodeIndex].filterSettings[conditionIndex],
            ...updatedConditionData,
          };
        }
      }
    },
    updateFilterConditionSegment(
      state,
      {
        payload,
      }: PayloadAction<{
        nodeId: string;
        conditionId: string;
        segmentId: string;
        updatedSegmentData: Partial<ISegment>;
      }>,
    ) {
      const { nodeId, conditionId, segmentId, updatedSegmentData } = payload;

      // find the relevant node
      const nodeIndex = state.nodeConfigs.findIndex((node) => node.nodeId === nodeId);

      if (nodeIndex !== -1) {
        const nodeConfigsCopy = cloneDeep(state.nodeConfigs);
        const flowPathCopy = cloneDeep(state.flowPath);

        // Push the copies into the history array
        state.history.push({
          nodeConfigs: nodeConfigsCopy,
          flowPath: flowPathCopy,
        });

        // if node exists find condition by its id
        const conditionIndex = state.nodeConfigs[nodeIndex].filterSettings.findIndex(
          (condition: ICondition) => condition._id === conditionId,
        );

        if (conditionIndex !== -1) {
          // if condition exists find segment by id
          const segmentIndex = state.nodeConfigs[nodeIndex].filterSettings[
            conditionIndex
          ].segments.findIndex((segment: ISegment) => segment._id === segmentId);

          if (segmentIndex !== -1) {
            // if segment exists update it
            state.nodeConfigs[nodeIndex].filterSettings[conditionIndex].segments[segmentIndex] = {
              ...state.nodeConfigs[nodeIndex].filterSettings[conditionIndex].segments[segmentIndex],
              ...updatedSegmentData,
            };
          }
        }

        // remove isValid and errors object
        const { isValid, errors, ...rest } = state.nodeConfigs[nodeIndex];
        state.nodeConfigs[nodeIndex] = { ...rest };
      }
    },
    deleteFilterConditionSegment(
      state,
      { payload }: PayloadAction<{ nodeId: string; conditionId: string; segmentId: string }>,
    ) {
      const { nodeId, conditionId, segmentId } = payload;

      // find the relevant node
      const nodeIndex = state.nodeConfigs.findIndex((node) => node.nodeId === nodeId);

      if (nodeIndex !== -1) {
        const nodeConfigsCopy = cloneDeep(state.nodeConfigs);
        const flowPathCopy = cloneDeep(state.flowPath);

        // Push the copies into the history array
        state.history.push({
          nodeConfigs: nodeConfigsCopy,
          flowPath: flowPathCopy,
        });

        // if node exists, find condition index by condition id
        const conditionIndex = state.nodeConfigs[nodeIndex].filterSettings?.findIndex(
          (condition: ICondition) => condition._id === conditionId,
        );

        if (conditionIndex !== -1) {
          // if condition exist find segment by id
          const segmentIndex = state.nodeConfigs[nodeIndex].filterSettings[
            conditionIndex
          ].segments.findIndex((segment: ISegment) => segment._id === segmentId);

          if (segmentIndex === 0) {
            // Remove the entire condition if the segment is at index 0
            // (last segment of the condition)
            state.nodeConfigs[nodeIndex].filterSettings.splice(conditionIndex, 1);
          } else if (segmentIndex !== -1) {
            // Remove the segment only if it's not at index 0
            state.nodeConfigs[nodeIndex].filterSettings[conditionIndex].segments.splice(
              segmentIndex,
              1,
            );
          }
        }

        // remove isValid and errors object
        const { isValid, errors, ...rest } = state.nodeConfigs[nodeIndex];
        state.nodeConfigs[nodeIndex] = { ...rest };
      }
    },
    // API Request node reducers
    initializeAPIRequestSettings(state, { payload }: PayloadAction<any>) {
      const newNodeId = payload?.id;

      // check if the nodeId already exists in the array
      const nodeIndex = state.nodeConfigs.findIndex((node) => node.nodeId === newNodeId);

      if (nodeIndex === -1) {
        const nodeConfigsCopy = cloneDeep(state.nodeConfigs);
        const flowPathCopy = cloneDeep(state.flowPath);

        // Push the copies into the history array
        state.history.push({
          nodeConfigs: nodeConfigsCopy,
          flowPath: flowPathCopy,
        });

        const initialSettings = generateInitialAPIRequestFlowNodeSettings();

        // If nodeId doesn't exist, create a new object by merging with commonConfig
        state.nodeConfigs.push({
          nodeId: newNodeId,
          ...initialSettings,
          position: payload.position,
        });
      }
    },
    addNewAPIRequestHeader(state, { payload }: PayloadAction<{ newNodeId: string }>) {
      const { newNodeId } = payload;

      // check if the nodeId already exists in the array
      const nodeIndex = state.nodeConfigs.findIndex((node) => node.nodeId === newNodeId);

      if (nodeIndex !== -1 && state.nodeConfigs[nodeIndex].apiRequestSettings) {
        const nodeConfigsCopy = cloneDeep(state.nodeConfigs);
        const flowPathCopy = cloneDeep(state.flowPath);

        // Push the copies into the history array
        state.history.push({
          nodeConfigs: nodeConfigsCopy,
          flowPath: flowPathCopy,
        });

        // If exists
        const newHeader: IUrlHeader = {
          _id: uniqueId(),
          headerKey: '',
          value: '',
        };

        state.nodeConfigs[nodeIndex].apiRequestSettings.headers.push(newHeader);
      }
    },
    deleteAPIRequestHeader(
      state,
      { payload }: PayloadAction<{ nodeId: string; headerId: string }>,
    ) {
      const { nodeId, headerId } = payload;

      const nodeIndex = state.nodeConfigs.findIndex((node) => node.nodeId === nodeId);

      if (nodeIndex !== -1 && state.nodeConfigs[nodeIndex].apiRequestSettings) {
        const nodeConfigsCopy = cloneDeep(state.nodeConfigs);
        const flowPathCopy = cloneDeep(state.flowPath);

        // Push the copies into the history array
        state.history.push({
          nodeConfigs: nodeConfigsCopy,
          flowPath: flowPathCopy,
        });

        // if api request node exists
        state.nodeConfigs[nodeIndex].apiRequestSettings.headers = state.nodeConfigs[
          nodeIndex
        ].apiRequestSettings.headers.filter((header: IUrlHeader) => header._id !== headerId);
      }
    },
    updateAPIRequestHeader(
      state,
      {
        payload,
      }: PayloadAction<{
        nodeId: string;
        headerId: string;
        updatedHeaderData: Partial<IUrlHeader>;
      }>,
    ) {
      const { nodeId, headerId, updatedHeaderData } = payload;
      const nodeIndex = state.nodeConfigs.findIndex((node) => node.nodeId === nodeId);

      if (nodeIndex !== -1 && state.nodeConfigs[nodeIndex].apiRequestSettings) {
        const nodeConfigsCopy = cloneDeep(state.nodeConfigs);
        const flowPathCopy = cloneDeep(state.flowPath);

        // Push the copies into the history array
        state.history.push({
          nodeConfigs: nodeConfigsCopy,
          flowPath: flowPathCopy,
        });

        // if api request node exists
        const headerIndex = state.nodeConfigs[nodeIndex].apiRequestSettings.headers.findIndex(
          (header: IUrlHeader) => header._id === headerId,
        );

        if (headerIndex !== -1) {
          // if header exists
          state.nodeConfigs[nodeIndex].apiRequestSettings.headers[headerIndex] = {
            ...state.nodeConfigs[nodeIndex].apiRequestSettings.headers[headerIndex],
            ...updatedHeaderData,
          };
        }
      }
    },
    updateAPIRequestUrl(state, { payload }: PayloadAction<{ nodeId: string; url: string }>) {
      const { nodeId, url } = payload;
      const nodeIndex = state.nodeConfigs.findIndex((node) => node.nodeId === nodeId);

      if (nodeIndex !== -1 && state.nodeConfigs[nodeIndex].apiRequestSettings) {
        const nodeConfigsCopy = cloneDeep(state.nodeConfigs);
        const flowPathCopy = cloneDeep(state.flowPath);

        // Push the copies into the history array
        state.history.push({
          nodeConfigs: nodeConfigsCopy,
          flowPath: flowPathCopy,
        });

        state.nodeConfigs[nodeIndex].apiRequestSettings.url = url;
      }
    },
    updateAPIRequestMethod(
      state,
      { payload }: PayloadAction<{ nodeId: string; method: TRequestMethod }>,
    ) {
      const { nodeId, method } = payload;
      const nodeIndex = state.nodeConfigs.findIndex((node) => node.nodeId === nodeId);

      if (nodeIndex !== -1 && state.nodeConfigs[nodeIndex].apiRequestSettings) {
        const nodeConfigsCopy = cloneDeep(state.nodeConfigs);
        const flowPathCopy = cloneDeep(state.flowPath);

        // Push the copies into the history array
        state.history.push({
          nodeConfigs: nodeConfigsCopy,
          flowPath: flowPathCopy,
        });

        state.nodeConfigs[nodeIndex].apiRequestSettings.requestMethod = method;
      }
    },
    updateAPIRequestBody(state, { payload }: PayloadAction<{ nodeId: string; body: string }>) {
      const { nodeId, body } = payload;
      const nodeIndex = state.nodeConfigs.findIndex((node) => node.nodeId === nodeId);

      if (nodeIndex !== -1 && state.nodeConfigs[nodeIndex].apiRequestSettings) {
        const nodeConfigsCopy = cloneDeep(state.nodeConfigs);
        const flowPathCopy = cloneDeep(state.flowPath);

        // Push the copies into the history array
        state.history.push({
          nodeConfigs: nodeConfigsCopy,
          flowPath: flowPathCopy,
        });

        state.nodeConfigs[nodeIndex].apiRequestSettings.body = body;
      }
    },
    addNewAPIRequestMappingSetting(state, { payload }: PayloadAction<{ nodeId: string }>) {
      const { nodeId } = payload;

      // check if the nodeId already exists in the array
      const nodeIndex = state.nodeConfigs.findIndex((node) => node.nodeId === nodeId);

      if (nodeIndex !== -1 && state.nodeConfigs[nodeIndex].apiRequestSettings) {
        const nodeConfigsCopy = cloneDeep(state.nodeConfigs);
        const flowPathCopy = cloneDeep(state.flowPath);

        // Push the copies into the history array
        state.history.push({
          nodeConfigs: nodeConfigsCopy,
          flowPath: flowPathCopy,
        });

        // If exists
        const newMappingSetting: IMappingSetting = {
          _id: uniqueId(),
          mapKey: '',
          variable: '',
        };

        state.nodeConfigs[nodeIndex].apiRequestSettings.mappingSettings.push(newMappingSetting);
      }
    },
    deleteAPIRequestMappingSetting(
      state,
      { payload }: PayloadAction<{ nodeId: string; mappingId: string }>,
    ) {
      const { nodeId, mappingId } = payload;

      const nodeIndex = state.nodeConfigs.findIndex((node) => node.nodeId === nodeId);

      if (nodeIndex !== -1 && state.nodeConfigs[nodeIndex].apiRequestSettings) {
        const nodeConfigsCopy = cloneDeep(state.nodeConfigs);
        const flowPathCopy = cloneDeep(state.flowPath);

        // Push the copies into the history array
        state.history.push({
          nodeConfigs: nodeConfigsCopy,
          flowPath: flowPathCopy,
        });

        // if api request node exists
        state.nodeConfigs[nodeIndex].apiRequestSettings.mappingSettings = state.nodeConfigs[
          nodeIndex
        ].apiRequestSettings.mappingSettings.filter(
          (mappingSetting: IMappingSetting) => mappingSetting._id !== mappingId,
        );
      }
    },
    updateAPIRequestMappingSetting(
      state,
      {
        payload,
      }: PayloadAction<{
        nodeId: string;
        mappingId: string;
        updatedMappingData: Partial<IUrlHeader>;
      }>,
    ) {
      const { nodeId, mappingId, updatedMappingData } = payload;
      const nodeIndex = state.nodeConfigs.findIndex((node) => node.nodeId === nodeId);

      if (nodeIndex !== -1 && state.nodeConfigs[nodeIndex].apiRequestSettings) {
        const nodeConfigsCopy = cloneDeep(state.nodeConfigs);
        const flowPathCopy = cloneDeep(state.flowPath);

        // Push the copies into the history array
        state.history.push({
          nodeConfigs: nodeConfigsCopy,
          flowPath: flowPathCopy,
        });

        // if api request node exists
        const mappingIndex = state.nodeConfigs[
          nodeIndex
        ].apiRequestSettings.mappingSettings.findIndex(
          (mappingSetting: IMappingSetting) => mappingSetting._id === mappingId,
        );

        if (mappingIndex !== -1) {
          // if mapping setting exists
          state.nodeConfigs[nodeIndex].apiRequestSettings.mappingSettings[mappingIndex] = {
            ...state.nodeConfigs[nodeIndex].apiRequestSettings.mappingSettings[mappingIndex],
            ...updatedMappingData,
          };
        }
      }
    },
    // initialize and add nodes to the canvas
    initializeNodes(state, { payload }: PayloadAction<any>) {
      const newNodeId = payload?.id;
      // Check if the nodeId already exists in the array
      const nodeIndex = state.flowPath.nodes.findIndex((node) => node.id === newNodeId);

      if (nodeIndex === -1) {
        const nodeConfigsCopy = cloneDeep(state.nodeConfigs);
        const flowPathCopy = cloneDeep(state.flowPath);

        // Push the copies into the history array
        state.history.push({
          nodeConfigs: nodeConfigsCopy,
          flowPath: flowPathCopy,
        });

        // If nodeId doesn't exist, create a new object by merging with commonConfig
        state.flowPath.nodes.push({
          id: newNodeId,
          type: 'customNode',
          data: payload?.data,
          position: payload.position,
        });
      }
    },
    // update the existing Nodes, specifically position
    updatedNodes(state, { payload }: PayloadAction<any>) {
      const nodeId = payload?.id;
      // Find the node in the array based on the nodeId
      const nodeIndex = state.flowPath.nodes.findIndex((node) => node.id === nodeId);

      if (nodeIndex !== -1) {
        const nodeConfigsCopy = cloneDeep(state.nodeConfigs);
        const flowPathCopy = cloneDeep(state.flowPath);

        // Push the copies into the history array
        state.history.push({
          nodeConfigs: nodeConfigsCopy,
          flowPath: flowPathCopy,
        });

        // If node exists, update it by merging with the new data
        state.flowPath.nodes[nodeIndex] = {
          ...state.flowPath.nodes[nodeIndex],
          position: payload?.position,
        };
      }
    },
    deleteNode(state, { payload }: PayloadAction<any>) {
      const { nodeId } = payload;

      const nodeConfigsCopy = cloneDeep(state.nodeConfigs);
      const flowPathCopy = cloneDeep(state.flowPath);

      // Push the copies into the history array
      state.history.push({
        nodeConfigs: nodeConfigsCopy,
        flowPath: flowPathCopy,
      });

      // Delete the node from flowPath and nodeConfigs
      const nodeIndex = state.flowPath.nodes.findIndex((node) => node.id === nodeId);
      const nodeConfigIndex = state.nodeConfigs.findIndex((node) => node.nodeId === nodeId);

      // If the node is found, remove it using splice
      if (nodeIndex !== -1) {
        state.flowPath.nodes.splice(nodeIndex, 1);
      }
      // If the node is found, remove it using splice
      if (nodeConfigIndex !== -1) {
        state.nodeConfigs.splice(nodeConfigIndex, 1);
      }

      // Delete the node
      // state.flowPath.nodes = state.flowPath.nodes.filter((node) => node.id !== nodeId);

      // Delete the associated edges
      state.flowPath.edges = state.flowPath.edges.filter(
        (edge) => edge.source !== nodeId && edge.target !== nodeId,
      );
    },
    duplicateNode(state, { payload }: PayloadAction<any>) {
      const newNodeId = payload?.id;
      const oldNodeId = payload?.nodeId;
      // get current node from flowPath
      const selectedFlowPathNode = state.flowPath.nodes.find((node) => node.id === oldNodeId);

      if (selectedFlowPathNode) {
        const xPosition = selectedFlowPathNode?.position?.x;
        const yPosition = selectedFlowPathNode?.position?.y;

        state.flowPath.nodes.push({
          ...selectedFlowPathNode,
          id: newNodeId,
          position: {
            x: xPosition ? xPosition + 250 : 0,
            y: yPosition || 0,
          },
        });
      }

      // get current node from nodeConfigs
      const selectedConfigNode = state.nodeConfigs.find((node) => node.nodeId === oldNodeId);

      if (selectedConfigNode) {
        // get duplicate nodes with different unique ids for child components
        const duplicatedConfig = getDuplicateNode(selectedConfigNode, newNodeId);

        state.nodeConfigs.push({
          ...duplicatedConfig,
          nodeId: newNodeId,
        });
      }
    },
    // initialize and add nodes to the canvas
    initializeEdges(state, { payload }: PayloadAction<any>) {
      const { id, source } = payload;
      // Check if the edgeId already exists in the array
      const edgeIndex = state.flowPath.edges.findIndex((edge) => edge.id === id);
      // find node index
      const nodeIndex = state.nodeConfigs.findIndex((node) => node.nodeId === source);

      if (edgeIndex === -1) {
        const nodeConfigsCopy = cloneDeep(state.nodeConfigs);
        const flowPathCopy = cloneDeep(state.flowPath);

        // Push the copies into the history array
        state.history.push({
          nodeConfigs: nodeConfigsCopy,
          flowPath: flowPathCopy,
        });

        // If edgeId doesn't exist, create a new object by merging with commonConfig
        state.flowPath.edges.push({
          id,
          source: payload?.source,
          sourceHandle: payload?.sourceHandle,
          targetHandle: payload?.targetHandle,
          target: payload?.target,
        });

        // remove isValid and errors object
        const { isValid, errors, ...rest } = state.nodeConfigs[nodeIndex];
        state.nodeConfigs[nodeIndex] = { ...rest };
      }
    },
    // update the existing Edges, specially the position
    deleteEdge(state, { payload }: PayloadAction<any>) {
      const edgeId = payload?.id;
      // Find the index of the edge in the array based on the nodeId
      const edgeIndex = state.flowPath.edges.findIndex((edge) => edge.id === edgeId);

      if (edgeIndex !== -1) {
        const nodeConfigsCopy = cloneDeep(state.nodeConfigs);
        const flowPathCopy = cloneDeep(state.flowPath);

        // Push the copies into the history array
        state.history.push({
          nodeConfigs: nodeConfigsCopy,
          flowPath: flowPathCopy,
        });

        state.flowPath.edges.splice(edgeIndex, 1);

        // Delete the selected edge
        // state.flowPath.edges = state.flowPath.edges.filter((edge) => edge.id === edgeId);
      }
    },
    addVariables(state, { payload }: PayloadAction<IVariable>) {
      const { id, type, label, value } = payload;

      const variableIndex = state.variables.findIndex(
        (obj) => obj.label === label || obj.id === id,
      );

      if (variableIndex === -1) {
        state.variables.push({
          id,
          type,
          label,
          value,
        });
      }
    },
    // use to set variable in globally
    setVariables(state, { payload }: PayloadAction<IVariable>) {
      const { id, value } = payload;

      const variableIndex = state.variables.findIndex((obj) => obj.id === id);

      if (variableIndex !== -1) {
        state.variables[variableIndex] = {
          ...state.variables[variableIndex],
          value,
        };
      }
    },

    initializeCarouselSettings(state, { payload }: PayloadAction<any>) {
      const newNodeId = payload?.id;

      // Check if the nodeId already exists in the array
      const nodeIndex = state.nodeConfigs.findIndex((node) => node.nodeId === newNodeId);

      if (nodeIndex === -1) {
        const initialSettings = generateInitialCarouselFlowSettings();

        // If nodeId doesn't exist, create a new object by merging with commonConfig
        state.nodeConfigs.push({
          nodeId: newNodeId,
          ...initialSettings,
          position: payload.position,
        });
      }
    },

    updateCarouselSettings(
      state,
      { payload }: PayloadAction<{ nodeId: string; carouselSettings: ICarouselSetting }>,
    ) {
      const { nodeId, carouselSettings } = payload;
      // Check if the nodeId already exists in the array
      const nodeIndex = state.nodeConfigs.findIndex((node) => node.nodeId === nodeId);

      if (nodeIndex !== -1) {
        state.nodeConfigs[nodeIndex].carouselSettings = {
          ...carouselSettings,
        };

        // remove isValid and errors object
        const { isValid, errors, ...rest } = state.nodeConfigs[nodeIndex];
        state.nodeConfigs[nodeIndex] = { ...rest };
      }
    },

    markInvalidNodes(state, { payload }: PayloadAction<any>) {
      const { errors } = payload;

      errors.forEach((error: INodeConfigError) => {
        const { nodeId, errors: nodeErrors } = error;

        // Check if the nodeId already exists in the array
        const nodeIndex = state.nodeConfigs.findIndex((node) => node.nodeId === nodeId);
        if (nodeIndex !== -1) {
          state.nodeConfigs[nodeIndex].isValid = false;
          state.nodeConfigs[nodeIndex].errors = nodeErrors;
        }
      });
    },

    undo: (state) => {
      if (state.history.length > 0) {
        const previousState = state.history[state.history.length - 1];
        return {
          ...state,
          history: state.history.slice(0, -1),
          redoHistory: [
            ...state.redoHistory,
            { nodeConfigs: state.nodeConfigs, flowPath: state.flowPath },
          ],
          nodeConfigs: previousState.nodeConfigs,
          flowPath: previousState.flowPath,
        };
      }

      return state;
    },
    redo: (state) => {
      if (state.redoHistory.length > 0) {
        const nextState = state.redoHistory[state.redoHistory.length - 1];
        return {
          ...state,
          history: [...state.history, { nodeConfigs: state.nodeConfigs, flowPath: state.flowPath }],
          redoHistory: state.redoHistory.slice(0, -1),
          nodeConfigs: nextState.nodeConfigs,
          flowPath: nextState.flowPath,
        };
      }

      return state;
    },
    clearHistory: (state) => ({
      ...state,
      history: [],
      redoHistory: [],
    }),

    removeSubscriberResponseEdge(state, { payload }: PayloadAction<{ nodeId: string }>) {
      const { nodeId } = payload;
      // filter out the associated edges
      state.flowPath.edges = state.flowPath.edges.filter(
        (edge) => edge.source !== nodeId && edge.sourceHandle !== `message-userInput-${nodeId}`,
      );
    },
  },
});

export const {
  initializeFlow,
  initializeNodes,
  initializeEdges,
  resetFlow,
  initializeMessageSettings,
  initializeFilterSettings,
  initializeFlowSettings,
  initializeContactSettings,
  initializeCaseSettings,
  initializeLiveChatSettings,
  initializeEndChatSettings,
  updateLiveChatSettings,
  updateFlowSettings,
  addNewFilterCondition,
  addNewSegmentToFilterCondition,
  updateFilterCondition,
  updateFilterConditionSegment,
  deleteFilterConditionSegment,
  updateContactSettings,
  updateCaseSettings,
  initializeAPIRequestSettings,
  addNewAPIRequestHeader,
  updateAPIRequestUrl,
  updateAPIRequestMethod,
  updateAPIRequestBody,
  deleteAPIRequestHeader,
  updateAPIRequestHeader,
  addNewAPIRequestMappingSetting,
  deleteAPIRequestMappingSetting,
  updateAPIRequestMappingSetting,
  updateMessageSettings,
  initializeActionSettings,
  updateActionSettings,
  updatedNodes,
  deleteEdge,
  addVariables,
  deleteNode,
  setVariables,
  duplicateNode,
  initializeCarouselSettings,
  updateCarouselSettings,
  markInvalidNodes,
  undo,
  redo,
  clearHistory,
  updateFlowPath,
  removeSubscriberResponseEdge,
  handleFlowSaveStatus,
} = flowSlice.actions;

export default flowSlice.reducer;
