import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

import { checkMenuPermission } from 'utils/auth';
import { SideBarMenuItem } from 'routes/sideBar.routes';
import { isUrlMatch } from 'utils/helpers';
import { SubMenuItem } from 'components/Icons';

interface ISubMenuItem {
  menu: SideBarMenuItem;
  idx: number;
}

function SubMenuItemContainer(props: ISubMenuItem) {
  const { menu, idx } = props;

  const { pathname } = useLocation();

  const subMenuItemVariants = {
    hidden: { opacity: 0, x: -10 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.3 } },
  };

  const isActive = isUrlMatch(menu.paths, pathname);

  return (
    <div key={`${menu.key}_${idx}`}>
      {checkMenuPermission(menu) ? (
        <li key={`${menu.key}_${idx}`}>
          <NavLink
            to={menu.link}
            id={menu?.id}
            className="sub_menu border-independenceBlue border-b text-white"
          >
            {isActive && (
              <motion.div initial="hidden" animate="visible" variants={subMenuItemVariants}>
                <SubMenuItem color="#fff" width={16} className="mr-2" />
              </motion.div>
            )}
            {menu.label}
          </NavLink>
        </li>
      ) : (
        <div />
      )}
    </div>
  );
}

SubMenuItemContainer.propTypes = {
  menu: PropTypes.object,
  idx: PropTypes.number,
};

export default SubMenuItemContainer;
