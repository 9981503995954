/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDebounce } from 'use-debounce';
import { isUndefined, omitBy } from 'lodash';

import { IRolesListURLQueryParams } from 'interfaces/roles.interface';
import { apiErrorHandler } from 'api/handler';
import { PATHS } from 'routes/paths';
import { fetchRolesListData } from 'services/roles.service';
import { tableDefaultValues } from './constants';
import ListPage from './page';
import { handleExportExcel, mapDocs } from './utils';

function RolePermissionList() {
  const location = useLocation();
  const navigate = useNavigate();

  const [searchByInput, setSearchByInput] = React.useState();
  const [debouncedSearchBy] = useDebounce(searchByInput, 500);

  const [urlSearchParams, setUrlSearchParams] = useState(null); //  URL search parameters
  const [customLoading, setCustomLoading] = useState({ list: false });

  const [listData, setListData] = useState(tableDefaultValues);

  // Effect to initialize URL search parameters from location
  useEffect(() => {
    const urlSearch = Object.fromEntries(new URLSearchParams(location.search));
    if (urlSearch && urlSearch.page) {
      replaceUrlParamsHandler({ ...urlSearchParams, ...urlSearch });
    } else {
      replaceUrlParamsHandler({ page: 1, limit: 10 });
    }
  }, []);

  useEffect(() => {
    if (urlSearchParams) {
      getListData({ ...listData, ...urlSearchParams });
    }
  }, [urlSearchParams]);

  useEffect(() => {
    if (debouncedSearchBy || debouncedSearchBy === '') {
      handleFilterBy(debouncedSearchBy, 'searchBy');
    }
  }, [debouncedSearchBy]);

  const getListData = async (params: IRolesListURLQueryParams) => {
    try {
      setCustomLoading((prevState) => ({ ...prevState, list: true }));
      const { data } = await fetchRolesListData(params);

      if (data?.node) {
        setListData({ ...listData, ...data.node, docs: mapDocs(data.node.docs) });
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, list: false }));
    }
  };

  /**
   * Function to replace URL search parameters
   * @param { IRolesListURLQueryParams } params -  Url search query params
   */
  const replaceUrlParamsHandler = (params: IRolesListURLQueryParams) => {
    const queryParams = omitBy(
      {
        page: params.page || '1',
        limit: params.limit,
        sort: params.sort,
        order: params.order,
        searchBy: params.searchBy,
        lastUpdatedAt: params.lastUpdatedAt,
        status: params.status,
        orderBy: params.orderBy,
      },
      isUndefined,
    );

    const searchParams = new URLSearchParams(queryParams);
    setUrlSearchParams(queryParams);
    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  /**
   * Handle page change in pagination by replacing the url search params
   *
   * @param {number} pageNo - Page number user requesting to navigate
   */
  const handlePageChange = (pageNo: number) => {
    replaceUrlParamsHandler({
      ...urlSearchParams,
      page: pageNo,
    });
  };

  /**
   * A callback function that is triggered when the user changes the number of
   * results to display per page.
   * @param {number} limit - The new limit value (i.e. number of results per page).
   */
  const handleResultsPerPageChange = (limit: number) => {
    setListData((prevTableData) => ({ ...prevTableData, limit }));
    replaceUrlParamsHandler({
      page: listData.page,
      limit,
    });
  };

  /**
   * Handle filtering by adding and removing url params using value from the element and passed id
   * @param {string} value - Value that used to search or filter the field eg: doe@abc.com
   * @param {string} id - Field or parameter that need to use for filtering. eg: adminEmail
   */
  const handleFilterBy = (value: string, id: string) => {
    // create a copy of url search param
    const updatedSearchParams = {
      ...urlSearchParams,
    };
    // add new param
    if (value) {
      updatedSearchParams[id] = value;
    } else {
      delete updatedSearchParams[id]; // Remove the key if value is undefined
    }
    // update url
    replaceUrlParamsHandler(updatedSearchParams);
  };

  const handleEditTableRow = (e, row: any) => {
    navigate(`${PATHS.USER_MANAGEMENT.ROLE.FORM.url}?md=edit&i=${row._id}`);
  };

  /**
   * Based on the passed column set url query params for column wise sorting
   * @param {boolean} isDescending - If true sorting is descending order else ascending order
   * @param {string} column - Which column need to be used
   */
  const handleColumnWiseSorting = (isDescending, column) => {
    replaceUrlParamsHandler({
      sort: column,
      order: isDescending ? 1 : -1,
    });
  };

  return (
    <ListPage
      listData={listData}
      handleEdit={handleEditTableRow}
      handleFilterBy={handleFilterBy}
      handlePageChange={handlePageChange}
      handleResultsPerPageChange={handleResultsPerPageChange}
      urlSearchParams={urlSearchParams}
      setSearchByInput={setSearchByInput}
      searchByInput={searchByInput}
      handleExport={() => handleExportExcel(listData.docs)}
      handleColumnSorting={handleColumnWiseSorting}
      customLoading={customLoading}
    />
  );
}

export default RolePermissionList;
