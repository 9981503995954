/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDebounce } from 'use-debounce';

import { SearchBar } from 'components';
import GeneralButton from 'components/Buttons/GeneralButton';
import { getNotesListByContactId } from 'services/note.service';
import { apiErrorHandler } from 'api/handler';
import { toast } from 'react-toastify';
import { NoteIcon } from 'components/Icons';
import NoteCard from './NoteCard';

function NoteContainer(props) {
  const {
    contactId,
    triggerChild,
    showAddNote,
    setNoteData,
    isCaseClosed = false,
    showNote,
    handleDeleteNote,
  } = props;

  const [searchByInput, setSearchByInput] = useState();
  const [notesListData, setNotesListData] = useState([]);

  const [debouncedSearchBy] = useDebounce(searchByInput, 500);

  const [customLoading, setCustomLoading] = useState({ list: false });

  useEffect(() => {
    if (contactId) {
      fetchListData();
    }
  }, [contactId, triggerChild, debouncedSearchBy]);

  async function fetchListData() {
    try {
      setCustomLoading((prevState) => ({ ...prevState, list: true }));
      const query = { searchBy: searchByInput, limit: 10 };
      const { data } = await getNotesListByContactId(contactId, query);
      if (data.node) {
        const mappedListData = data.node.docs.map((obj) => ({
          ...obj,
          name:
            obj.contact.firstName && obj.contact.lastName
              ? `${obj.contact.firstName} ${obj.contact.lastName}`
              : '',
          location: obj?.contact?.profilePicture?.location
            ? obj?.contact?.profilePicture?.location
            : '',
          date: obj?.createdAt || '',
          note: obj?.description || '',
        }));
        setNotesListData(mappedListData);
      }
    } catch (e) {
      const { message: exception } = apiErrorHandler(e);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, list: false }));
    }
  }

  const handleOnSearch = (event) => {
    const { value } = event.target;
    setSearchByInput(value);
  };

  return (
    <div className="my-2">
      <div className="flex flex-row mb-3 items-center gap-5">
        <div className="flex justify-between items-center w-full">
          <div className="w-[400px]">
            <SearchBar
              placeholder="Search Notes"
              searchByInput={searchByInput}
              onSearch={handleOnSearch}
            />
          </div>
          <GeneralButton
            text="Add Notes"
            btnColor="bg-spaceCadetBlue80"
            color="white"
            radius="2xl"
            textSize="xs"
            btnHoverColor="hover:bg-spaceCadetBlue"
            icon={<NoteIcon color="white" />}
            handleOnClick={showAddNote}
            disabled={isCaseClosed}
          />
        </div>
      </div>
      <hr className="my-4" />
      <div className="max-h-96 overflow-y-scroll table-layout_custom-scrollbar">
        {notesListData.map((obj) => (
          <NoteCard
            key={obj._id}
            noteData={obj}
            name={obj?.name}
            date={obj?.date}
            location={obj?.location}
            note={obj?.note}
            setNoteData={setNoteData}
            showNote={showNote}
            handleDeleteNote={handleDeleteNote}
          />
        ))}
      </div>
    </div>
  );
}

NoteContainer.propTypes = {
  contactId: PropTypes.string,
  triggerChild: PropTypes.object,
  showAddNote: PropTypes.func,
  isCaseClosed: PropTypes.bool,
  setNoteData: PropTypes.func,
  showNote: PropTypes.func,
  handleDeleteNote: PropTypes.func,
};

export default NoteContainer;
