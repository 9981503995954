import React from 'react';
import PropTypes from 'prop-types';
import { Handle, Position } from 'reactflow';

import { messageButtonsNodeStyle } from '../../../constants';

function QuickReplyBadgeMessageNode(props) {
  const { id, reply, isUserInput } = props;

  return (
    <div
      className={`badge badge-outline border-blue-500  ${
        isUserInput ? 'mr-2' : 'ml-auto'
      }  relative inline-flex max-w-full`}
      style={{
        border: 'border-blue-500',
        boxSizing: 'border-box',
        width: 'fit-content',
        height: 'auto',
        marginBottom: '2px',
      }}
    >
      <p
        className="text-blue-500 mr-4 py-1 overflow-hidden whitespace-normal break-words flex-grow"
        style={{
          wordWrap: 'break-word',
          width: 'auto',
          height: 'auto',
        }}
      >
        {reply}
      </p>
      {!isUserInput && (
        <Handle
          type="source"
          position={Position.Right}
          id={`quickReply-${id}`}
          className="!bg-blue-500"
          style={{ ...messageButtonsNodeStyle, right: 2 }}
        />
      )}
    </div>
  );
}

QuickReplyBadgeMessageNode.propTypes = {
  reply: PropTypes.string,
  id: PropTypes.any,
  isUserInput: PropTypes.bool,
};

export default QuickReplyBadgeMessageNode;
