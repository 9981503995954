import instance, { postAttachments } from 'api';
import { MESSENGER } from 'api/apiEndpoints';
import UrlBuilder from 'api/urlQueryBuilder';

/**
 * Update Messenger FLow
 * @returns note details
 */
export function updateMessengerFlow(data: any) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(MESSENGER.UPDATE_MESSENGER_FLOW).build();
  return instance.post(url, data);
}

export function createMessengerFlow(data: any) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(MESSENGER.CREATE_MESSENGER_FLOW).build();
  return instance.post(url, data);
}

export function getFlowById(id: string) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(MESSENGER.GET_MESSENGER_FLOW).setPathParams({ id }).build();
  return instance.get(url);
}

export function getFlowByIntent(id: string) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder
    .setUrl(MESSENGER.GET_MESSENGER_FLOW_BY_INTENT_ID)
    .setPathParams({ id })
    .build();
  return instance.get(url);
}

export function getAllFlow(id: string) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(MESSENGER.GET_ALL_MESSENGER_FLOW).setPathParams({ id }).build();
  return instance.get(url);
}

export function uploadFlowAttachment(data: any) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(MESSENGER.UPLOAD_FLOW_ATTACHMENT).build();
  return postAttachments(url, data);
}

export function deleteFlowAttachment(intentId: string, id: string) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder
    .setUrl(MESSENGER.DELETE_FLOW_ATTACHMENT)
    .setPathParams({ intentId, id })
    .build();
  return instance.delete(url);
}
