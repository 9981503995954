/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'slices';
import { PATHS } from 'routes/paths';
import { PrimaryButton } from 'components';
import { Logout } from 'components/Icons';
import { logoutHandler } from 'utils/auth';
import ProfilePic from 'components/ProfilePic/ProfilePic';
import { AuthLogoutTypes } from 'enums/auth.enum';

function UserManagementMenu() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const userData = useSelector((state: RootState) => state.user.user);

  const menuOptions = [
    {
      key: 'accountsManagement',
      label: 'My Profile',
      callBack: onAccountManagementClick,
    },
    {
      key: 'updatePassword',
      label: 'Update Password',
      callBack: onUpdatePasswordClick,
    },
  ];

  /**
   * Redirect to user edit form
   */
  function onAccountManagementClick() {
    setIsMenuOpen(false);
    navigate(`${PATHS.ACCOUNT_MANAGEMENT.PROFILE.url}?i=${userData._id}`);
  }

  /**
   * Redirect user to update password form
   */
  function onUpdatePasswordClick() {
    setIsMenuOpen(false);
    navigate(`${PATHS.ACCOUNT_MANAGEMENT.PROFILE.url}?i=${userData._id}`);
  }

  /**
   * Logout functionality
   */
  const handleLogOut = () => {
    logoutHandler(dispatch, AuthLogoutTypes.UserLogout);
    navigate(`${PATHS.AUTH.LOGIN}`);
  };

  /**
   * Menu open or close
   */
  const handleMenuToggle = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  return (
    <div className="dropdown dropdown-end user_management__dropdown">
      <label tabIndex={0} className="flex justify-center cursor-pointer items-center">
        <div className="avatar" onClick={handleMenuToggle}>
          <ProfilePic />
        </div>
      </label>
      <ul
        tabIndex={0}
        className={`dropdown-content z-[1] menu p-0 shadow-md bg-base-100 rounded-box w-[300px] ${
          isMenuOpen ? '' : 'hidden'
        }`}
      >
        <div>
          <div className="flex menu_top_content items-center">
            <div className="avatar">
              <div className="rounded-full">
                <ProfilePic />
              </div>
            </div>

            <div className="user-details ml-2">
              <div className="font-semibold full-name text-spaceCadetBlue">{userData.name}</div>
              <div className="text-sm email text-independenceBlue">{userData.email}</div>
            </div>
          </div>
          {menuOptions.map((option) => (
            <motion.div
              key={option.key}
              className="menu_item flex cursor-pointer"
              onClick={option.callBack}
            >
              <motion.span whileHover={{ scale: 1.03 }}>{option.label}</motion.span>
            </motion.div>
          ))}
          <div className="menu_bottom_spacer"> </div>
          <div className="flex menu_bottom_content items-center">
            <PrimaryButton
              text="Logout"
              btnColor="bg-spaceCadetBlue80"
              className="m-auto"
              color="white"
              width="[150px]"
              radius="3xl"
              btnHoverColor="hover:bg-spaceCadetBlue"
              icon={<Logout />}
              textSize="xs font-bold font-ptScanCap"
              handleOnClick={handleLogOut}
            />
          </div>
        </div>
      </ul>
    </div>
  );
}

UserManagementMenu.propTypes = {};

export default UserManagementMenu;
