import React from 'react';
import PropTypes from 'prop-types';

import { Show } from '../Icons';

function ViewButton(props) {
  const { onClick } = props;
  return (
    <div>
      <div className="flex items-center">
        <button
          type="button"
          className="flex bg-spaceCadetBlue font-ptScanCap rounded-2xl items-center px-3 py-1 text-center hover:opacity-80"
          onClick={onClick}
        >
          <Show color="white" />
          <span className="text-white capitalize text-xs pl-1 font-bold">View</span>
        </button>
      </div>
    </div>
  );
}

ViewButton.propTypes = {
  onClick: PropTypes.func,
};

export default ViewButton;
