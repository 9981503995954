import React from 'react';
import PropTypes from 'prop-types';
import { Handle, Position } from 'reactflow';

import { IFilterError } from 'interfaces/flow.interface';
import { nodeStyle } from '../../constants';
import { CONDITION_TYPES, SEGMENTATION_METHODS } from '../../DrawerContents/FilterDrawer/constants';
import { ISegment } from '../../DrawerContents/FilterDrawer/interfaces';
import Error from '../../tempIcons/Error';

function SegmentCriteria(props) {
  const { conditionId, criteriaType, segmentationMethod, segments, nodeErrors } = props;

  const hasError = nodeErrors?.some((error: IFilterError) => error.conditionId === conditionId);

  const renderSegments = () =>
    segments.map((segment: ISegment) => {
      const { _id, criteria, condition, textPhrase } = segment;
      return (
        <div className="criteria-badges flex" id={_id} key={_id}>
          {criteria && <div className="badge badge-criteria">{criteria}</div>}
          {condition && <div className="badge badge-condition">{condition}</div>}
          {textPhrase && <div className="badge badge-data">{textPhrase}</div>}
        </div>
      );
    });

  return (
    <div className="filter-node__criteria" id={conditionId}>
      <div className="criteria">
        <div className="criteria__heading flex relative justify-between">
          {criteriaType !== CONDITION_TYPES.NOT ? (
            <div className="flex items-center">
              {hasError && (
                <span className="mr-1">
                  <Error height={18} width={18} />
                </span>
              )}
              {segmentationMethod === SEGMENTATION_METHODS.ANY ? 'Any' : 'All'} of the following
              criteria:
            </div>
          ) : (
            <span className="mb-0">NOT</span>
          )}

          {criteriaType !== CONDITION_TYPES.NOT ? (
            <Handle
              type="source"
              position={Position.Right}
              id={`segment-if-${conditionId}`}
              className="!bg-green-500"
              style={Position.Left && { ...nodeStyle, top: 10, right: 0 }}
            />
          ) : (
            <Handle
              type="source"
              position={Position.Right}
              id={`segment-not-${conditionId}`}
              className="!bg-red-500"
              style={Position.Left && { ...nodeStyle, top: 10, right: 0 }}
            />
          )}
        </div>
        {criteriaType !== CONDITION_TYPES.NOT && renderSegments()}
      </div>
    </div>
  );
}

SegmentCriteria.propTypes = {
  conditionId: PropTypes.string,
  criteriaType: PropTypes.string,
  segmentationMethod: PropTypes.string,
  segments: PropTypes.array,
  nodeErrors: PropTypes.arrayOf(PropTypes.object),
};

export default SegmentCriteria;
