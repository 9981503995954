/* eslint-disable implicit-arrow-linebreak */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { CustomSelect, CustomTable, PrimaryButton } from 'components';
import { PATHS } from 'routes/paths';
import { Export, Messenger, Search } from 'components/Icons';
import { selectCustomStylesForFilters } from 'styles/select';
import { getDefaultValueForSelect } from 'utils/helpers';
import GeneralButton from 'components/Buttons/GeneralButton';

import { LAST_UPDATED_AT_OPTIONS, MESSENGER_STATUS_OPTIONS } from './constants';

function ListPage(props) {
  const {
    handleFilterBy,
    listData,
    handleEdit,
    handlePageChange,
    handleResultsPerPageChange,
    urlSearchParams,
    setSearchByInput,
    searchByInput,
    handleExport,
    handleColumnSorting,
    customLoading,
  } = props;

  const selectedStatus = useMemo(
    () => getDefaultValueForSelect(urlSearchParams?.status, MESSENGER_STATUS_OPTIONS, 'value'),
    [urlSearchParams?.status],
  );

  const selectedLastUpdatedAt = useMemo(
    () =>
      getDefaultValueForSelect(urlSearchParams?.lastUpdatedAt, LAST_UPDATED_AT_OPTIONS, 'value'),
    [urlSearchParams?.lastUpdatedAt],
  );

  return (
    <div className="page">
      <div className="flex flex-wrap mb-2 w-full items-center justify-between">
        <div className="flex items-center">
          <div className="pr-5 h-12 items-center flex justify-start">
            <span className="font-ptScanCap text-lg font-semibold">Messenger Instances</span>
          </div>
          <Link to={PATHS.MESSENGERS.FORM.url}>
            <GeneralButton
              id="createInstanceBtn"
              text="Create Instance"
              btnColor="bg-spaceCadetBlue80"
              color="white"
              radius="2xl"
              textSize="sm"
              btnHoverColor="hover:bg-spaceCadetBlue"
              icon={<Messenger />}
            />
          </Link>
        </div>
        <div className="grid grid-cols-1">
          <div className="mt-4 sm:mt-0">
            <PrimaryButton
              text="Export"
              btnColor="bg-spaceCadetBlue80"
              color="white"
              width="[140px]"
              radius="2xl"
              btnHoverColor="hover:bg-spaceCadetBlue"
              icon={<Export />}
              handleOnClick={handleExport}
              disabled={customLoading.list}
            />
          </div>
        </div>
      </div>
      <div className="border-t border-slate-300 mt-5" />
      <div className="container mt-4 py-1">
        <form className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-4">
          {/* Row 1 */}
          <div className="mb-2">
            <div className="relative block sm:flex items-center mb-2 sm:mb-0">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2 mt-1">
                <svg className="h-5 w-5 mb-2 fill-slate-300" viewBox="0 0 20 20">
                  <Search color="#475574" />
                </svg>
              </span>
              <input
                className="placeholder:text-sm placeholder:text-independenceBlue font-lato text-sm bg-white border border-gainsboroBlue focus:border-[#2684ff] rounded-lg h-11 py-2 pl-9 pr-3 shadow-sm focus:outline-none sm:text-sm w-full rounded-xl"
                placeholder="Search by Instance name"
                type="text"
                name="search"
                onChange={(event) => setSearchByInput(event.target.value)}
                value={searchByInput || ''}
              />
            </div>
          </div>
          <div className="mb-4">
            <CustomSelect
              placeholder="Status"
              customStyles={selectCustomStylesForFilters}
              options={MESSENGER_STATUS_OPTIONS}
              value={selectedStatus}
              isClearable
              onChange={(selected) => {
                const value = selected?.value;
                handleFilterBy(value, 'status');
              }}
            />
          </div>
          <div className="mb-4">
            <CustomSelect
              placeholder="Last Updated At"
              customStyles={selectCustomStylesForFilters}
              options={LAST_UPDATED_AT_OPTIONS}
              value={selectedLastUpdatedAt}
              isClearable
              onChange={(selected) => {
                const value = selected?.value;
                handleFilterBy(value, 'lastUpdatedAt');
              }}
            />
          </div>
        </form>
      </div>

      <CustomTable
        tableData={listData}
        onPageChange={handlePageChange}
        handleResultsPerPageChange={handleResultsPerPageChange}
        handlers={{ onManage: handleEdit }}
        onSort={handleColumnSorting}
        loading={{ list: customLoading.list }}
        isPaginate
        handleEdit={handleEdit}
      />
    </div>
  );
}

ListPage.propTypes = {
  handleFilterBy: PropTypes.func,
  handlePageChange: PropTypes.func,
  setSearchByInput: PropTypes.func,
  handleExport: PropTypes.func,
  searchByInput: PropTypes.string,
  handleEdit: PropTypes.func,
  handleColumnSorting: PropTypes.func,
  handleResultsPerPageChange: PropTypes.func,
  urlSearchParams: PropTypes.shape({
    page: PropTypes.any,
    lastUpdatedAt: PropTypes.string,
    sort: PropTypes.string,
    status: PropTypes.string,
  }),
  listData: PropTypes.shape({
    page: PropTypes.number,
  }),
  customLoading: PropTypes.any,
};

export default ListPage;
