/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */
import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Handle, Position } from 'reactflow';
import { Delete } from 'components/Icons';
import Copy from 'components/Icons/Copy';

const startNodeStyle = {
  right: 10,
  width: 20,
  height: 20,
  border: '4px solid',
  boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.2)',
};
const nodeStyle = {
  width: 20,
  height: 20,
  border: '4px solid white',
  boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.2)',
};

function CustomNode({ data, id }) {
  return (
    <div>
      {id <= 1 && (
        <div className="origin-node items-center bg-[#31c178] pl-3 pr-6 py-1 rounded-3xl w-[120px] text-lg text-white font-semibold text-center items-center drop-shadow-xl">
          {data?.heading}
          <Handle
            type="source"
            position={Position.Right}
            id="b"
            className=" !bg-blue-500"
            style={startNodeStyle}
          />
        </div>
      )}
      {id > 1 && (
        <div
          className={`bg-white p-1 rounded-md w-[250px] drop-shadow-lg ${
            data?.isSelected ? 'border-2 border-[#31c178]' : ''
          } `}
        >
          <div>
            <div
              className={
                data?.heading === 'Message'
                  ? 'bg-[#e5fff2] rounded-md px-2 py-1 p text-center text-[#00a851] font-semibold'
                  : data?.heading === 'Flow'
                  ? 'bg-blue-200 rounded-md px-2 py-1 text-center text-blue-600 font-semibold'
                  : data?.heading === 'Filter'
                  ? 'bg-yellow-300 rounded-md px-2 py-1 text-center text-yellow-600 font-semibold'
                  : data?.heading === 'Action'
                  ? 'bg-red-300 px-2 py-1 text-center text-red-600 font-semibold'
                  : null
              }
            >
              {data?.heading}
            </div>
            {/* <div className="bg-gray-100 text-sm text-[#333333] rounded-xl mt-1 py-2 pl-3">
              {data?.content}
            </div> */}
            <input
              className="bg-gray-100 text-sm text-[#333333] rounded-xl mt-1 py-2 pl-3 outline-none w-[222px]"
              placeholder="Type your message"
              value={data?.content !== null ? data.content.toString() : ''}
            />
            {data?.variableName && data?.validateAs && (
              <div className="p-2 m-1 border-2 border-dotted rounded-md border-gray w-1/2 justify-end">
                <p className="text-sm text-blue-400">User input</p>
                <span className="text-xs">
                  {data?.validateAs}: {data?.variableName}
                </span>
              </div>
            )}
          </div>
          <div className="p-2 text-gainsboroBlue text-right">Continue</div>
          <Handle
            type="target"
            position={Position.Left}
            id="a"
            className="!bg-blue-500"
            style={Position.Left && { ...nodeStyle, top: 18, left: -10 }}
          />
          <Handle
            type="source"
            position={Position.Right}
            id="b"
            className=" !bg-blue-500"
            style={Position.Right && { ...nodeStyle, top: 98, right: -10 }}
          />
        </div>
      )}
      {id > 1 && (
        <div className="space-x-2 text-right p-2">
          <button
            className="p-2 rounded-md bg-white drop-shadow-xl"
            onClick={() => data?.onCopy(data, id)}
          >
            <Copy color="#FF7171" />
          </button>
          <button
            className="p-2 rounded-md bg-white drop-shadow-xl"
            onClick={() => data?.onDelete(id)}
          >
            <Delete color="#FF7171" />
          </button>
        </div>
      )}
    </div>
  );
}

CustomNode.propTypes = {
  data: PropTypes.any,
  id: PropTypes.string,
};

export default CustomNode;
