import {
  CASE_STATUS,
  CASE_SEVERITY,
  CASE_CATEGORY,
  CASE_LAST_UPDATED_AT,
} from 'constants/cases.const';
import { ISelectOption } from 'interfaces/component.interface';

/**
 * Set selected item for case status select filter
 * @param {string} value - Selected value from url params(status)
 * @returns {ISelectOption} - Selected status object
 */
export const getStatusSelected = (value: string): ISelectOption => {
  const selectedStatus = CASE_STATUS.find((statusOption) => statusOption.value === value);
  return selectedStatus;
};

/**
 * Set selected item for case severity select filter
 * @param {string} value - Selected value from url params(severity)
 * @returns {ISelectOption} - Selected severity object
 */
export const getSeveritySelected = (value: string): ISelectOption => {
  const selectedSeverity = CASE_SEVERITY.find((severityOption) => severityOption.value === value);
  return selectedSeverity;
};

/**
 * Set selected item for case category select filter
 * @param {string} value - Selected value from url params(category)
 * @returns {ISelectOption} - Selected category object
 */
export const getCategorySelected = (value: string): ISelectOption => {
  const selectedCategory = CASE_CATEGORY.find((categoryOption) => categoryOption.value === value);
  return selectedCategory;
};

/**
 * Set selected item for case last update at select filter
 * @param {string} value - Selected value from url params(last update at)
 * @returns {ISelectOption} - Selected lastUpdateAt object
 */
export const getLastUpdateSelected = (value: string): ISelectOption => {
  const selectedLastUpdatedAt = CASE_LAST_UPDATED_AT.find(
    (lastUpdatedAtOption) => lastUpdatedAtOption.value === value,
  );
  return selectedLastUpdatedAt;
};
