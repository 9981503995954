/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { omitBy, isUndefined } from 'lodash';
import { useDebounce } from 'use-debounce';
import moment from 'moment';

import { fetchCaseListData } from 'services/case.service';
import { apiErrorHandler } from 'api/handler';
import { cleanExcelColumns, exportSingleSheetToExcel } from 'utils/excel';

import { PATHS } from 'routes/paths';
import { ICasesListURLQueryParams } from 'views/CaseManagement/List/interface';
import { IContactListURLQueryParams } from 'views/Contact/List/interface';
import { fetchContactListData } from 'services/contact.service';
import { ISelectOption } from 'interfaces/component.interface';
import { tableDefaultValues, excelHeaders } from './constants';
import ListPage from './page';

/**
 * CasesListView component displays the cases list.
 * It provides filtering, sorting, and pagination functionality.
 *
 * @returns JSX element representing the cases list view.
 */
function CasesListView() {
  const location = useLocation();
  const navigate = useNavigate();

  const [searchByInput, setSearchByInput] = React.useState();
  const [debouncedSearchBy] = useDebounce(searchByInput, 500);

  const [tagsInput, setTagsInput] = React.useState();
  const [debouncedTags] = useDebounce(tagsInput, 500);

  const [urlSearchParams, setUrlSearchParams] = useState(null); //  URL search parameters
  const [customLoading, setCustomLoading] = useState({ list: false });
  const [caseListData, setCaseListData] = useState(tableDefaultValues);

  //  state with table headers and contact data list
  const [contactListData, setContactListData] = useState(tableDefaultValues);

  //  state with table headers and contact data list
  const [contactList, setContactList] = useState([]);

  // Effect to fetch contact list data when page is rendered
  useEffect(() => {
    getContactListData({ ...contactListData, ...urlSearchParams });
  }, []);

  // Effect to initialize URL search parameters from location
  useEffect(() => {
    const urlSearch = Object.fromEntries(new URLSearchParams(location.search));
    if (!urlSearch.page) {
      replaceUrlParamsHandler({ page: 1, limit: 10 });
      return;
    }
    setUrlSearchParams(urlSearch);
  }, [location.search]);

  useEffect(() => {
    // Handle debouncedSearchBy
    if (debouncedSearchBy || debouncedSearchBy === '') {
      handleFilterBy(debouncedSearchBy, 'searchBy');
    }
  }, [debouncedSearchBy]);

  useEffect(() => {
    // Handle debouncedTag
    if (debouncedTags || debouncedTags === '') {
      handleFilterBy(debouncedTags, 'tags');
    }
  }, [debouncedTags]);

  // Effect to fetch cases list data when URL search parameters change
  useEffect(() => {
    if (urlSearchParams) {
      getCasesListData({ ...caseListData, ...urlSearchParams });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlSearchParams]);
  /**
   * Fetch cases list data based on provided query parameters
   *
   * @param {ICasesListURLQueryParams} params - Url search params
   */
  const getCasesListData = async (params: ICasesListURLQueryParams) => {
    try {
      setCustomLoading((prevState) => ({ ...prevState, list: true }));
      const { data } = await fetchCaseListData(params);
      const { node } = data;
      const docs = (data?.node?.docs || []).map((doc) => ({
        ...doc,
        contactId: doc?.contact?.fullName || '',
        category: doc?.category.charAt(0).toUpperCase() + doc.category.slice(1),
      }));
      setCaseListData({ ...caseListData, ...node, docs });
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, list: false }));
    }
  };

  // =================================================
  /**
   * Fetch contact list data based on provided query parameters
   *
   * @param {IContactListURLQueryParams} params - Url search params
   */
  const getContactListData = async (params: IContactListURLQueryParams) => {
    try {
      const { data } = await fetchContactListData(params);
      const docs = (data?.docs || []).map((doc) => ({
        value: doc?._id,
        label: `${doc?.firstName}\n${doc?.email}\n${doc?.primaryContactNo}`,
      }));
      setContactListData(docs);
      setContactList(docs);
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    }
  };

  /**
   * Set selected item for case contact select filter
   * @param {string} value - Selected value from url params(contact)
   * @returns {ISelectOption} - Selected contact object
   */
  const getContactSelected = (value: string): ISelectOption => {
    const selectedContact = contactList?.find((contactOption) => contactOption.value === value);
    return selectedContact;
  };

  /**
   * Function to replace URL search parameters
   * @param { ICasesListURLQueryParams } params -  Url search query params
   */
  const replaceUrlParamsHandler = (params) => {
    const queryParams = omitBy(
      {
        page: params.page || '1',
        limit: params.limit,
        sort: params.sort,
        order: params.order,
        searchBy: params.searchBy,
        status: params.status,
        severity: params.severity,
        category: params.category,
        lastUpdatedAt: params.lastUpdatedAt,
        contact: params.contact,
        orderBy: params.orderBy,
        tags: params.tags,
      },
      isUndefined,
    );

    const searchParams = new URLSearchParams(queryParams);
    setUrlSearchParams(queryParams);
    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  /**
   * Handle page change in pagination by replacing the url search params
   *
   * @param {number} pageNo - Page number user requesting to navigate
   */
  const handlePageChange = (pageNo: number) => {
    replaceUrlParamsHandler({
      ...urlSearchParams,
      page: pageNo,
    });
  };

  /**
   * Handle filtering by adding and removing url params using value from the element and passed id
   * @param {string} value - Value that used to search or filter the field eg: doe@abc.com
   * @param {string} id - Field or parameter that need to use for filtering. eg: adminEmail
   */
  const handleFilterBy = (value: string, id: string) => {
    // create a copy of url search param
    const updatedSearchParams = {
      ...urlSearchParams,
    };
    // add new param
    if (value) {
      updatedSearchParams[id] = value;
    } else {
      delete updatedSearchParams[id]; // Remove the key if value is undefined
    }
    // update url
    replaceUrlParamsHandler(updatedSearchParams);
  };

  const handleEditTableRow = (event: any, row: any) => {
    // eslint-disable-next-line no-underscore-dangle
    navigate(`${PATHS.CASES_MANAGEMENT.FORM.url}?md=edit&i=${row._id}`);
  };

  const handleExportExcel = async () => {
    if (caseListData.docs.length === 0) {
      toast.warning('No records found');
      return;
    }

    const mappedColumns = excelHeaders.map((x) => ({
      header: x.value,
      key: x.key,
      width: 25,
    }));

    // Clean the columns by removing those with empty labels
    const cleanedColumns = cleanExcelColumns(mappedColumns);
    const rows = caseListData.docs; // Get the data rows

    const mappedRowData = rows.map((x) => ({
      ...x,
      createdAt: x.createdAt ? moment(x.createdAt).fromNow() : 'N/A',
    }));

    try {
      await exportSingleSheetToExcel({
        worksheetName: 'cases-list', // Name of the worksheet in the Excel file
        columns: cleanedColumns, // Cleaned columns for the Excel file
        rows: mappedRowData, // Mapped and formatted row data for the Excel file
        fileName: 'Cases List', // Name of the exported Excel file
      });
    } catch (e) {
      toast.error('Something went wrong, Try again');
    }
  };

  return (
    <ListPage
      clientListData={caseListData}
      handleEdit={handleEditTableRow}
      handleFilterBy={handleFilterBy}
      handlePageChange={handlePageChange}
      urlSearchParams={urlSearchParams}
      setSearchByInput={setSearchByInput}
      searchByInput={searchByInput}
      handleExport={handleExportExcel}
      customLoading={customLoading}
      contactList={contactListData}
      contactSelectedFilter={getContactSelected}
      tagsInput={tagsInput}
      setTagsInput={setTagsInput}
    />
  );
}

export default CasesListView;
