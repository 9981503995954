import * as React from 'react';
import PropTypes from 'prop-types';

function FlowIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color = '#1477A6', width = 45, height = 32 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 45 32"
    >
      <rect width="8" height="8" x="4" y="6" stroke={color} strokeWidth="2" rx="1" />
      <rect width="8" height="8" x="28" y="6" stroke={color} strokeWidth="2" rx="1" />
      <rect width="8" height="8" x="28" y="20" stroke={color} strokeWidth="2" rx="1" />
      <path stroke={color} strokeWidth="2" d="M11 10L29 10" />
      <path stroke={color} strokeWidth="2" d="M17 11v8a5 5 0 005 5h7" />
    </svg>
  );
}
FlowIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default FlowIcon;
