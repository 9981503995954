export const EXPIRE_TIMES = [
  {
    label: '1 min',
    value: '1 min',
  },
  {
    label: '5 min',
    value: '5 min',
  },
  {
    label: '15 min',
    value: '15 min',
  },
  {
    label: '30 min',
    value: '30 min',
  },
  {
    label: '1 hour',
    value: '1 hour',
  },
  {
    label: '3 hour',
    value: '3 hour',
  },
  {
    label: '6 hour',
    value: '6 hour',
  },
  {
    label: '24 hour',
    value: '24 hour',
  },
];

export const VALIDATE_AS = [
  {
    label: 'String',
    value: 'string',
  },
  {
    label: 'Number',
    value: 'number',
  },
  {
    label: 'Date',
    value: 'date',
  },
  {
    label: 'Time',
    value: 'time',
  },
  {
    label: 'Date and Time',
    value: 'datetime',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Phone',
    value: 'phone',
  },
  {
    label: 'URL',
    value: 'url',
  },
  // {
  //   label: 'GeoLocation',
  //   value: 'geolocation',
  // },
  {
    label: 'Image Video or Document',
    value: 'imageVideo',
  },
];
