export const SOCKET_EVENTS = {
  CLIENT: {
    WATCH_CHAT_PREVIEW: 'WATCH_CHAT_PREVIEW',
    WATCH_CHAT_INBOX: 'WATCH_CHAT_INBOX',
    JOIN_ROOM: 'JOIN_ROOM',

    AUTH: {
      CHECK_JWT_SESSION: 'CHECK_JWT_SESSION',
    },
  },

  SERVER: {
    AUTH: {
      SESSION_EXPIRED: 'SESSION_EXPIRED',
    },

    USER_CONNECTED: 'USER_CONNECTED',
    USER_DISCONNECTED: 'USER_DISCONNECTED',
    NEW_BOT_MESSAGE: 'NEW_BOT_MESSAGE',
    NEW_CUSTOMER_MESSAGE: 'NEW_CUSTOMER_MESSAGE',
    LOGOUT_USER: 'LOGOUT_USER',

    MESSAGING: {
      NEW_CUSTOMER_CONVERSATION: 'NEW_CUSTOMER_CONVERSATION',
    },

    CHANNELS: {
      CHANNEL_ASSIGNED_TO_CONVERSATION: 'CHANNEL_ASSIGNED_TO_CONVERSATION',
    },

    CONVERSATION: {
      EVENTS: 'CONVERSATION_EVENTS',
      CONVERSATION_ENDED: 'CONVERSATION_ENDED',
    },

    MESSENGER_INSTANCE: {
      BOT_DEFAULT_SETTINGS_CREATED: 'BOT_DEFAULT_SETTINGS_CREATED',
      MESSENGER_STATUS: 'MESSENGER_STATUS',
    },
  },
};
