import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-daisyui';

import { PrimaryButton } from 'components';
import { OpenList, Send, UserApprove, ViewClient } from 'components/Icons';
import { ModalTypes } from '../../constants';

function AccountCreateSuccess(props) {
  const {
    show,
    toggleModal,
    handleOnSendForApproval,
    handleApproveAccount,
    actionStatus = false,
    loading,
    data,
    type,
  } = props;

  const [confirmText, setConfirmText] = useState('');

  const { name, sequenceId } = data;

  return (
    <Modal
      open={show}
      className="top-0 left-0 right-0 p-0 border border-gainsboroBlue rounded-lg z-50 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(auto-1rem)] max-w-2xl max-h-full"
    >
      <Modal.Header className="font-bold pb-2 mb-2 p-4">
        <div className="flex justify-between items-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 w-fit flex-wrap items-center">
            <div className="text-xl font-bold font-ptScanCap">
              {type === ModalTypes.AccountCreationSuccess ? 'Success!' : 'Activate user account'}
            </div>
            <span className="text-sm font-normal font-lato text-left ml-0 sm:ml-36">
              {type === ModalTypes.AccountCreationSuccess ? 'User Account has been Created' : ''}
            </span>
          </div>
          <div className="text-sm font-normal bg-red">
            <Button
              size="xs"
              shape="circle"
              className="btn btn-xs btn-circle border-2 bg-white border-rasinBlack font-bold"
              onClick={toggleModal}
            >
              x
            </Button>
          </div>
        </div>
        <hr className="mt-3" />
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="text-center my-2">
            <p className="font-lato font-normal text-sm">{name}</p>
            <p className="font-lato font-normal text-sm">User ID : {sequenceId}</p>
          </div>
          {!actionStatus && (
            <div className="border border-northTexasGreen bg-milkGreen rounded-xl p-4 m-5 grid grid-cols-1">
              <span className="text-sm font-normal font-lato mb-3">
                Do you want to approve this User Account?
              </span>
              <input
                type="text"
                placeholder="Type 'approve' here"
                className="input border-quickSilver h-10 focus:outline-none rounded-xl font-normal text-sm w-full max-w-2xl mt-3 mb-4"
                onChange={(event) => setConfirmText(event.target.value)}
              />
              <div className="w-fit">
                <PrimaryButton
                  text="Approve User"
                  btnColor="bg-spaceCadetBlue80"
                  btnHoverColor="hover:bg-spaceCadetBlue"
                  color="white"
                  icon={<UserApprove />}
                  radius="2xl"
                  disabled={confirmText.toLowerCase() !== 'approve'}
                  handleOnClick={handleApproveAccount}
                  loading={loading}
                />
              </div>
            </div>
          )}
        </div>

        <div className="w-full py-8 bg-culturedGray border-t flex flex-wrap justify-center items-center gap-3">
          <PrimaryButton
            text="Send for Approval"
            btnColor="bg-spaceCadetBlue80"
            color="white"
            radius="2xl"
            btnHoverColor="hover:bg-spaceCadetBlue"
            icon={<Send />}
            disabled={loading}
            handleOnClick={handleOnSendForApproval}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

AccountCreateSuccess.propTypes = {
  show: PropTypes.bool,
  actionStatus: PropTypes.bool,
  loading: PropTypes.bool,
  toggleModal: PropTypes.func,
  handleApproveAccount: PropTypes.func,
  handleOnSendForApproval: PropTypes.func,
  data: PropTypes.any,
  type: PropTypes.string,
};

export default AccountCreateSuccess;
