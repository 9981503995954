/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';

import { apiErrorHandler } from 'api/handler';
import { updateURLWithSearchParams } from 'utils/helpers';
import { PromptModal } from 'components';

import {
  addRolePermissionsById,
  createRole,
  deleteRolePermissionById,
  getRolePermissionById,
  updateRolePermissionById,
} from 'services/roles.service';
import { getAllPermissions } from 'services/permissions.service';
import { fetchClientListData } from 'services/client.service';
import { getUserType } from 'utils/auth';
import { UserType } from 'constants/user.const';
import { getFormValidationSchema } from './validations';
import FormPage from './page';
import { PromptTypes, defaultFormData, promptDefaultOptions } from './constants';

function RolePermissionForm() {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams(); // read url params

  const [initialFormData, setInitialFormData] = useState<any>(defaultFormData);
  const [formData, setFormData] = useState<any>(defaultFormData);
  const [permissionFormData, setPermissionFormData] = useState<any>({});

  // const [promptOptions, setPromptOptions] = useState(promptDefaultOptions);
  const [editMode, setEditMode] = useState(false);
  const [customLoading, setCustomLoading] = useState({
    formSubmit: false,
    deleteRolePermission: false,
    fetchForm: false,
    fetchClients: false,
  });

  const [promptOptions, setPromptOptions] = useState(promptDefaultOptions);
  const [clientsOptions, setClientsOptions] = useState([]);

  const [triggerChild, setTriggerChild] = useState({ transactions: null, linkedChannels: null });
  const [allPermissions, setAllPermissions] = useState<any>({});

  const pageType = searchParams.get('md');
  const pageId = searchParams.get('i');

  const { register, handleSubmit, formState, control } = useForm<any>({
    resolver: useMemo(() => yupResolver(getFormValidationSchema(formData)), [formData.clientId]),
    values: useMemo(() => formData, [customLoading.fetchForm]),
  });

  const { errors } = formState;

  useEffect(() => {
    const userType = getUserType();
    // only fetch client list if super user
    if (userType === UserType.SuperUser) {
      getAllClients();
    }

    if (!pageType) {
      updateURLWithSearchParams({ md: 'new' });
    }
    if (pageType && pageType === 'edit' && pageId) {
      setEditMode(true);
      fetchRolePermission(pageId);
    }
  }, []);

  useEffect(() => {
    if (editMode) {
      fetchAllPermissions();
    }
  }, [editMode]);

  // set already assigned form data to permissionFormData
  useEffect(() => {
    if (formData?.permissions) {
      setPermissionFormData(formData.permissions);
    }
  }, [formData]);

  async function fetchAllPermissions() {
    try {
      const { data } = await getAllPermissions();
      if (data?.node) {
        setAllPermissions(data.node);
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    }
  }

  const getAllClients = async () => {
    try {
      setCustomLoading((prevState) => ({ ...prevState, fetchClients: true }));
      const params = { limit: 1000 };
      const { data } = await fetchClientListData(params);
      if (data?.docs) {
        const options = data?.docs?.map(({ _id, companyName }) => ({
          label: companyName,
          value: _id,
        }));
        setClientsOptions(options);
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, fetchClients: false }));
    }
  };

  async function fetchRolePermission(id: string) {
    try {
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: true, fetchForm: true }));
      const { data } = await getRolePermissionById(id);
      if (data?.node) {
        setFormData((prevState: any) => ({ ...prevState, ...data.node }));
        setInitialFormData((prevState: any) => ({ ...prevState, ...data.node }));
      }
    } catch (e) {
      const { message: exception } = apiErrorHandler(e);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: false, fetchForm: false }));
    }
  }

  // Handles the role form submission
  const onSubmit = async () => {
    try {
      const payload = formData;
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: true }));
      if (!editMode) {
        const { data } = await createRole(payload);
        if (data) {
          setFormData({ ...payload, ...data.node });
          setInitialFormData({ ...payload, ...data.node });
          setEditMode(true);
          navigate(`?md=edit&i=${data.node._id}`, { replace: true });

          setTriggerChild((prevState) => ({
            ...prevState,
            transactions: uuid(),
          }));
        }
      } else {
        const { data } = await updateRolePermissionById(formData._id, payload);

        if (data) {
          setFormData({ ...payload, ...data.node });
          setInitialFormData({ ...payload, ...data.node });

          setTriggerChild((prevState) => ({
            ...prevState,
            transactions: uuid(),
          }));
          toast.success('Successfully Updated Role Permission');
        }
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: false }));
    }
  };

  /**
   * Handle delete role
   */
  const handleDeleteRolePermission = async () => {
    try {
      setCustomLoading((prevState) => ({ ...prevState, deleteRolePermission: true }));
      const { _id } = formData;
      const { data } = await deleteRolePermissionById(_id);

      if (data?.node) {
        setFormData((prevState: any) => ({ ...prevState, ...data.node }));
        setInitialFormData((prevState: any) => ({ ...prevState, ...data.node }));

        toast.success('Role Permission Deleted Successfully');
        setPromptOptions(promptDefaultOptions);
        setTriggerChild((prevState) => ({ ...prevState, transactions: uuid() }));
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, deleteRolePermission: false }));
    }
  };

  // handle the input text change
  const handleInputChange = (event: { target: { id: any; value: any } }) => {
    const { id, value } = event.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  /**
   * Delete role permission modal prompt
   */
  const handleOnDeleteRolePermission = () => {
    setPromptOptions({
      show: true,
      title: `Delete Role ${formData.sequenceId}`,
      message: 'Do you want to delete this role?',
      onConfirm: () => handleDeleteRolePermission(),
      type: PromptTypes.DeleteRolePermission,
      data: null,
    });
  };

  // used to get updated state for loading
  function loadDynamicLoadingProp() {
    if (promptOptions.type === PromptTypes.DeleteRolePermission) {
      return customLoading.deleteRolePermission;
    }
    return false;
  }

  /**
   * Add selected permissions to an array
   * @param {{value: string, label:string}[]} selectedData - Selected permission for the dropdown
   * @param {string} id - Id of the selected dropdown(featureKey)
   */
  const handleSelectPermissionChange = (
    selectedData: { value: string; label: string }[],
    id: string,
  ) => {
    setPermissionFormData((prevState) => ({ ...prevState, [id]: selectedData }));
  };

  // handle the dropdown change
  const handleSelectChange = (event: any, id: string) => {
    setFormData({
      ...formData,
      [id]: event?.value || null,
    });
  };

  /**
   * Handle add permissions to the role
   */
  const handleAddPermission = async () => {
    try {
      setCustomLoading((prevState) => ({ ...prevState, deleteRolePermission: true }));

      // Merge permissions objects into a array
      const mergedPermissionsObjArray = [].concat(...Object.values(permissionFormData));
      // create an array using permission object ids
      const permissionIdArray = mergedPermissionsObjArray.map((item) => item.value);

      const { _id } = formData;

      const { data } = await addRolePermissionsById(_id, { permissions: permissionIdArray });
      if (data?.node) {
        toast.success('Role Permissions Added Successfully');
        setTriggerChild((prevState) => ({ ...prevState, transactions: uuid() }));
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, deleteRolePermission: false }));
    }
  };

  return (
    <>
      <FormPage
        initialFormData={initialFormData}
        onSubmit={onSubmit}
        errors={errors}
        formData={formData}
        handleInputChange={handleInputChange}
        handleSubmit={handleSubmit}
        register={register}
        editMode={editMode}
        customLoading={customLoading}
        control={control}
        handleOnDeleteRolePermission={handleOnDeleteRolePermission}
        triggerChild={triggerChild}
        setFormData={setFormData}
        allPermissions={allPermissions}
        handleSelectPermissionChange={handleSelectPermissionChange}
        handleSelectChange={handleSelectChange}
        handleAddPermission={handleAddPermission}
        givenPermissions={permissionFormData}
        clientsOptions={clientsOptions}
      />

      {promptOptions.show && (
        <PromptModal
          onCancel={() => {
            setPromptOptions({ ...promptOptions, show: false });
          }}
          {...promptOptions}
          loading={loadDynamicLoadingProp()}
        />
      )}
    </>
  );
}

export default RolePermissionForm;
