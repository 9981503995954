import * as yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';

// Validations
export const caseFormValidationSchema = yup.object({
  contactId: yup.string().required('please select a contact'),
  primaryContactNo: yup.string().required('please enter contact number'),
  // .test('invalid-primary-contact-no', 'Invalid contact number', (value) => {
  //   const isValid = isValidPhoneNumber(value);
  //   return isValid;
  // }),
  subject: yup.string().required('Please type a subject'),
  severity: yup.string().required('Please select a severity'),
  status: yup.string().required('Please select a status'),
  category: yup.string().required('Please select a category'),
});
