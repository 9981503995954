import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { ISelectOption } from 'interfaces/component.interface';
import { updateFlowSettings } from 'slices/flow';
import { RootState } from 'slices';
import { CustomSelect } from 'components';
import { getDefaultValueForSelect } from 'utils/helpers';
import { IDrawerCoreData } from 'views/Settings/FlowBuilder/interfaces';
import { IFlowDrawerData, IFlowOptions } from './interfaces';

interface IFlowDrawerProps {
  node: IDrawerCoreData;
  toggleDrawer: (formData: IFlowDrawerData, nodeId: string) => void;
  flowOptions: IFlowOptions[];
}

const FlowDrawer = forwardRef(({ toggleDrawer, node, flowOptions }: IFlowDrawerProps, ref) => {
  const dispatch = useDispatch();

  const flowData = useSelector((state: RootState) => state.flow);
  const [formData, setFormData] = useState<IFlowDrawerData>();

  useEffect(() => {
    const flowObj = flowData.nodeConfigs.find((nodes) => nodes?.nodeId === node?.id);
    if (flowObj) {
      setFormData(flowObj?.flowSettings);
    }
  }, []);

  const selectedDefaultDataType = useMemo(
    () => getDefaultValueForSelect(formData?.flowName, flowOptions, 'value'),
    [formData?.flowName],
  );

  // handle select field change
  const handleSelectChange = (option: ISelectOption, id: string) => {
    setFormData({
      ...formData,
      [id]: option?.value,
    });
    const nodePayload = {
      id: node?.id,
      data: { heading: node?.title, content: option },
    };
    dispatch(updateFlowSettings(nodePayload));
  };

  const handleSubmit = () => {
    toggleDrawer(formData, node?.id);
  };

  // Expose the handleSubmit function to the parent component using useImperativeHandle
  useImperativeHandle(ref, () => ({
    handleSubmit,
  }));

  return (
    <div className="flow-drawer__container">
      <form>
        <div className="mb-4">
          <div className="form-control">
            <label className="label" htmlFor="flow">
              <span className="label-text text-base font-medium font-lato">Start another flow</span>
            </label>
          </div>
          <CustomSelect
            id="flowName"
            required
            placeholder="Choose a flow"
            options={flowOptions}
            value={selectedDefaultDataType}
            onChange={(event) => handleSelectChange(event, 'flowName')}
          />
        </div>
      </form>
    </div>
  );
});

FlowDrawer.propTypes = {
  node: PropTypes.any,
  flowOptions: PropTypes.any,
};

export default FlowDrawer;
