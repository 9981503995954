/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/forbid-prop-types */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import PhoneInputWithCountrySelect from 'react-phone-number-input';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Textarea } from 'react-daisyui';

import { INote } from 'views/Contact/ContactView/interface';
import { RootState } from 'slices';
import { CustomSelect, InputText, PrimaryButton, TransactionTable } from 'components';
import { AddCustomer, Back, ReopenIcon, Save, ViewContact } from 'components/Icons';
import { getValueForSelect } from 'utils/helpers';
import { PATHS } from 'routes/paths';
import { COLLECTIONS } from 'constants/db';
import CustomCreatableSelect from 'components/Form/CustomCreatableSelect';
import ContactView from 'views/Contact/components/ContactView/ContactView';
import ContactCard from 'views/Contact/components/ContactView/ContactCard';
import ContactDetailCard from 'views/Contact/components/ContactView/ContactDetailCard/ContactDetailCard';
import NoteContainer from 'components/Notes';
import GeneralButton from 'components/Buttons/GeneralButton';
import AddNoteCard from 'views/Contact/components/NoteCard/AddNoteCard';
import DrawerIcon from 'components/Icons/DrawerIcon';

import FormEditHeader from './containers/FormEditHeader';
import { Severities, categories, statusOptions, tags, transactionTableHeaders } from './constants';
import TabsSection from './containers/TabsSection';
import ConversationList from './containers/conversationsTable';

function CaseFormPage(props) {
  const {
    handleSubmit,
    onSubmit,
    register,
    errors,
    handleInputChange,
    formData,
    handleSelectChange,
    editMode,
    customLoading,
    control,
    handlePhoneNoChange,
    showPreview,
    triggerChild,
    contactListData,
    visible,
    selectedContactData,
    handleTabClick,
    activeTab,
    currentContactData,
    headersData,
    updateContact,
    promptHandler,
    isCaseClosed,
    reopenCase,
    showAddNote,
    isOpenAddNoteDrawer,
    setIsOpenAddNoteDrawer,
    handleCreateNote,
    handleEditNote,
    handleOnLinkChat,
    handleOnUnlinkChat,
    setTriggerChild,
    isDisabledContact,
  } = props;
  const navigate = useNavigate();

  const locationData = useSelector((state: RootState) => state.user.locationData);

  const [noteData, setNoteData] = useState<INote>({});

  return (
    <>
      <div
        className={`px-[35px] py-[25px] ${editMode ? 'grid grid-cols-1 lg:grid-cols-3 gap-4' : ''}`}
      >
        <div className={`col_one ${editMode ? 'lg:col-span-2' : ''}`}>
          <div className="flex justify-between items-center">
            <div>
              {!editMode ? (
                <span className="text-spaceCadetBlue font-bold text-lg font-ptScanCap pb-4">
                  Create a Case
                </span>
              ) : (
                <FormEditHeader formData={headersData} />
              )}
            </div>
            <div>
              <GeneralButton
                text="Back"
                btnColor="bg-spaceCadetBlue80"
                color="white"
                radius="2xl"
                textSize="xs"
                btnHoverColor="hover:bg-spaceCadetBlue"
                icon={<Back />}
                handleOnClick={() => navigate(-1)}
              />
            </div>
          </div>
          <hr className="my-3" />

          <div className="p-8 bg-white drop-shadow rounded-xl mt-6">
            <div className="flex gap-5">
              <div className="">
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                  <div className="grid grid-cols-3 xs:grid-cols-3 rg:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
                    {editMode && isCaseClosed && (
                      <div className="flex space-x-2 items-center mt-4">
                        <GeneralButton
                          text="Reopen"
                          btnColor="bg-spaceCadetBlue80"
                          color="white"
                          radius="2xl"
                          textSize="xs"
                          handleOnClick={(event) => {
                            reopenCase(event);
                          }}
                          btnHoverColor="hover:bg-spaceCadetBlue"
                          icon={<ReopenIcon />}
                        />
                      </div>
                    )}
                  </div>
                  <div className="grid grid-cols-2 min-w-full xs:grid-cols-2 rg:grid-cols-2 lg:grid-cols-2 gap-4">
                    <div className="form-control mb-4">
                      <label className="label" htmlFor="contact">
                        <span className="label-text font-normal text-base font-lato">Contact</span>
                      </label>
                      <CustomSelect
                        id="contactId"
                        required
                        isClearable
                        options={contactListData}
                        error={errors?.contactId}
                        placeholder="Type name, phone number, email to search a contact"
                        value={getValueForSelect(formData?.contactId, contactListData)}
                        onChange={(event) => handleSelectChange(event, 'contactId')}
                        isDisabled={editMode}
                      />
                    </div>
                    {!editMode ? (
                      <div className="flex space-x-2 items-center mt-4">
                        {formData?.contactId && (
                          <GeneralButton
                            text="View Contact"
                            btnColor="bg-spaceCadetBlue80"
                            color="white"
                            radius="2xl"
                            textSize="xs"
                            handleOnClick={(event) => {
                              event.preventDefault();
                              showPreview();
                            }}
                            btnHoverColor="hover:bg-spaceCadetBlue"
                            icon={<ViewContact />}
                          />
                        )}
                        <Link to={PATHS.CONTACT.FORM.url}>
                          <GeneralButton
                            text="Add Contact"
                            btnColor="bg-spaceCadetBlue80"
                            color="white"
                            radius="2xl"
                            textSize="xs"
                            btnHoverColor="hover:bg-spaceCadetBlue"
                            icon={<AddCustomer />}
                          />
                        </Link>
                      </div>
                    ) : (
                      <div className="flex space-x-2 items-center mt-4">
                        <GeneralButton
                          text="Change Contact"
                          btnColor="bg-spaceCadetBlue80"
                          color="white"
                          radius="2xl"
                          textSize="xs"
                          handleOnClick={(event) => {
                            event.preventDefault();
                            updateContact();
                          }}
                          btnHoverColor="hover:bg-spaceCadetBlue"
                          icon={<ViewContact />}
                          disabled={editMode && isCaseClosed}
                        />
                      </div>
                    )}
                  </div>
                  <div className="grid grid-cols-2 xs:grid-cols-2 rg:grid-cols-2 lg:grid-cols-2 gap-4">
                    <div className="form-control mb-4">
                      <label className="label" htmlFor="primaryContactNo">
                        <span className="label-text text-base font-lato">
                          Primary Contact Number
                        </span>
                      </label>
                      <Controller
                        name="primaryContactNo"
                        control={control}
                        render={({ field }) => (
                          <PhoneInputWithCountrySelect
                            className={`input input-bordered focus:outline-none ${
                              (isDisabledContact || editMode) && 'phone-input'
                            } ${errors?.primaryContactNo ? 'border border-begoniaRed' : ''}`}
                            defaultCountry={locationData?.country_code}
                            value={formData?.primaryContactNo}
                            control={control}
                            disabled={(editMode && isCaseClosed) || isDisabledContact || editMode}
                            id="primaryContactNo"
                            onChange={(e) => {
                              field.onChange(e);
                              handlePhoneNoChange('primaryContactNo', e);
                            }}
                            limitMaxLength
                          />
                        )}
                      />
                      {errors?.primaryContactNo && (
                        <label className="label relative mt-2" htmlFor="primaryContactNo">
                          <span className="label-text-alt absolute text-error">
                            {errors?.primaryContactNo?.message?.toString()}
                          </span>
                        </label>
                      )}
                    </div>

                    <div className="form-control mb-4">
                      <InputText
                        elementId="adminEmail"
                        label="Email address"
                        placeholder="eg: jdoe@abc.com"
                        type="email"
                        register={register}
                        error={errors.adminEmail}
                        value={formData?.adminEmail}
                        onChange={handleInputChange}
                        disabled={(editMode && isCaseClosed) || isDisabledContact || editMode}
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-2 xs:grid-cols-2 rg:grid-cols-2 lg:grid-cols-2 gap-4">
                    <div className="mb-4">
                      <div className="form-control">
                        <label className="label" htmlFor="category">
                          <span className="label-text font-normal text-base font-lato">
                            Category
                          </span>
                        </label>
                      </div>
                      <CustomSelect
                        id="Category"
                        required
                        isClearable
                        options={categories}
                        error={errors?.category}
                        value={getValueForSelect(formData.category, categories)}
                        onChange={(event) => handleSelectChange(event, 'category')}
                        isDisabled={editMode && isCaseClosed}
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-2 xs:grid-cols-2 rg:grid-cols-2 lg:grid-cols-2 gap-4">
                    <div className="mb-4 col-span-2">
                      <InputText
                        elementId="subject"
                        label="Subject"
                        type="text"
                        register={register}
                        error={errors.subject}
                        value={formData.subject}
                        onChange={handleInputChange}
                        disabled={editMode && isCaseClosed}
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-2 xs:grid-cols-2 rg:grid-cols-2 lg:grid-cols-2 gap-4">
                    <div className="mb-4 col-span-2">
                      <label className="label" htmlFor="description">
                        <span className="label-text text-base font-lato">Description</span>
                      </label>
                      <Textarea
                        id="description"
                        className="w-full h-48 mt-1 text-base font-lato"
                        value={formData.description}
                        onChange={handleInputChange}
                        disabled={editMode && isCaseClosed}
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-2 xs:grid-cols-2 rg:grid-cols-2 lg:grid-cols-2 gap-4">
                    <div className="mb-4">
                      <div className="form-control">
                        <label className="label" htmlFor="severity">
                          <span className="label-text font-normal text-base font-lato">
                            Severity
                          </span>
                        </label>
                      </div>
                      <CustomSelect
                        id="Severity"
                        required
                        isClearable
                        options={Severities}
                        error={errors?.severity}
                        value={getValueForSelect(formData?.severity, Severities)}
                        onChange={(event) => handleSelectChange(event, 'severity')}
                        isDisabled={editMode && isCaseClosed}
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-2 xs:grid-cols-2 rg:grid-cols-2 lg:grid-cols-2 gap-4">
                    <div className="mb-4">
                      <div className="form-control">
                        <label className="label" htmlFor="status">
                          <span className="label-text font-normal text-base font-lato">Status</span>
                        </label>
                      </div>
                      <CustomSelect
                        id="Status"
                        required
                        options={statusOptions}
                        isClearable
                        error={errors?.status}
                        value={getValueForSelect(formData?.status, statusOptions)}
                        onChange={(event) => handleSelectChange(event, 'status')}
                        isDisabled={editMode && isCaseClosed}
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-2 xs:grid-cols-2 rg:grid-cols-2 lg:grid-cols-2 gap-4">
                    <div className="mb-4">
                      <div className="form-control">
                        <label className="label" htmlFor="category">
                          <span className="label-text font-normal text-base font-lato">Tags</span>
                        </label>
                      </div>
                      <CustomCreatableSelect
                        id="tags"
                        required
                        isClearable
                        isMulti
                        isDisplaySvg
                        options={tags}
                        value={formData?.tags}
                        onChange={(event) => handleSelectChange(event, 'tags')}
                        isDisabled={editMode && isCaseClosed}
                      />
                    </div>
                  </div>
                  <div className="mt-4 mb-4">
                    <PrimaryButton
                      text={editMode ? 'Update Case' : 'Save'}
                      btnColor="bg-spaceCadetBlue80"
                      color="white"
                      radius="2xl"
                      btnHoverColor="hover:bg-spaceCadetBlue"
                      icon={<Save />}
                      disabled={customLoading.formSubmit || isCaseClosed}
                      loading={customLoading.formSubmit}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="col_two" />
      </div>

      {editMode && (
        <div className="case-Management__hide-on-large-screens bg-[#F9F9F9] fixed right-0 top-5 mt-16 rg:w-1/4">
          <div className="bg-[#F9F9F9] fixed right-0 top-5 mt-16 rg:w-1/4 h-full">
            <div className="form-control w-full p-8 shadow-md h-full">
              <ContactCard contactDetails={currentContactData} isActionButtonsVisible={false} />
              <ContactDetailCard contactDetails={currentContactData} />
            </div>
          </div>
        </div>
      )}
      <div className="px-10 pb-6 grid grid-cols-1 gap-4 lg:grid-cols-3">
        {editMode && formData._id && (
          <div className="p-8 bg-white drop-shadow rounded-xl mt-6 col-span-2">
            <div>
              <TabsSection activeTab={activeTab} handleTabClick={handleTabClick} />
              <hr className="mb-4" />
              {/* Render tab content based on the activeTab state */}
              {activeTab === 'Conversations' && (
                <ConversationList
                  recordId={formData._id}
                  triggerChild={triggerChild.transactions}
                  handleOnLinkChat={handleOnLinkChat}
                  handleOnUnlinkChat={handleOnUnlinkChat}
                />
              )}
              {activeTab === 'Notes' && (
                <NoteContainer
                  triggerChild={triggerChild}
                  contactId={formData?.contactId}
                  showAddNote={showAddNote}
                  isCaseClosed={isCaseClosed}
                  setNoteData={setNoteData}
                  showNote={showAddNote}
                  handleDeleteNote={promptHandler}
                />
              )}
              {activeTab === 'Transactions' && (
                <TransactionTable
                  recordId={formData._id}
                  collection={COLLECTIONS.Cases}
                  transactionTableHeaders={transactionTableHeaders}
                  triggerChild={triggerChild.transactions}
                />
              )}
            </div>
          </div>
        )}
      </div>
      {editMode && (
        <div className="case-Management-drawer__hide-on-large-screens fixed flex items-center justify-center right-0 top-1/2 text-white transform rotate-90 -mr-14">
          <button
            type="button"
            className="flex items-center justify-center bg-spaceCadetBlue80 hover:bg-spaceCadetBlue w-auto h-auto px-8 py-[1vh] text-lg font-ptScanCap font-bold rounded-b-2xl"
            onClick={showPreview}
          >
            <span className="transform -rotate-180 text-white">Contact</span>
            <div className="-rotate-90 ml-1">
              <DrawerIcon width="28" height="28" />
            </div>
          </button>
        </div>
      )}

      {/* Preview contact model */}
      {visible && (
        <ContactView
          isActionButtonsVisible={false}
          visible={visible}
          toggleVisible={showPreview}
          contactDetails={selectedContactData}
        />
      )}
      {/* // Note drawer */}
      {isOpenAddNoteDrawer && (
        <AddNoteCard
          isNotShowDelete
          isNoteVisible={isOpenAddNoteDrawer}
          noteId={noteData?._id}
          contactId={formData?.contactId}
          showNote={showAddNote}
          setTriggerChild={setTriggerChild}
        />
      )}
    </>
  );
}

CaseFormPage.propTypes = {
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  register: PropTypes.func,
  handlePhoneNoChange: PropTypes.func,
  errors: PropTypes.any,
  formData: PropTypes.any,
  control: PropTypes.any,
  handleInputChange: PropTypes.func,
  showPreview: PropTypes.func,
  handleSelectChange: PropTypes.func,
  editMode: PropTypes.bool,
  customLoading: PropTypes.shape({
    formSubmit: PropTypes.bool,
    toggleAccount: PropTypes.bool,
    activateAccount: PropTypes.bool,
    fetchForm: PropTypes.bool,
  }),
  triggerChild: PropTypes.any,
  contactListData: PropTypes.any,
  visible: PropTypes.bool,
  selectedContactData: PropTypes.object,
  handleTabClick: PropTypes.func,
  activeTab: PropTypes.string,
  currentContactData: PropTypes.object,
  headersData: PropTypes.object,
  updateContact: PropTypes.func,
  promptHandler: PropTypes.func,
  isCaseClosed: PropTypes.bool,
  reopenCase: PropTypes.func,
  showAddNote: PropTypes.func,
  isOpenAddNoteDrawer: PropTypes.bool,
  isDisabledContact: PropTypes.bool,
  setIsOpenAddNoteDrawer: PropTypes.func,
  handleOnLinkChat: PropTypes.func,
  handleCreateNote: PropTypes.func,
  handleEditNote: PropTypes.func,
  handleOnUnlinkChat: PropTypes.func,
  setTriggerChild: PropTypes.func,
};

export default CaseFormPage;
