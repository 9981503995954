import React from 'react';
import PropTypes from 'prop-types';
import GeneralButton from 'components/Buttons/GeneralButton';
import { AddReviewIcon } from 'components/Icons';
import QAFeedbackCard from './QAFeedbackCard';
import { isDateMoreThan30DaysAgo } from '../utils';

function QAReviewCard(props) {
  const { qaReviewList, triggerQAReviewModal, handleOnToggleDeleteReview, completedAt } = props;

  return (
    <div className="h-auto w-full mt-3">
      <div className="bg-white border rounded-xl w-82 justify-between">
        <div className="flex h-auto p-4 items-center justify-between rounded-t-lg bg-brightGray gap-11">
          <span className="text-center text-xl text-spaceCadetBlue font-workSans font-bold">
            QA Review
          </span>
          {!isDateMoreThan30DaysAgo(completedAt) && (
            <GeneralButton
              text="Add Review"
              btnColor="bg-spaceCadetBlue80"
              btnHoverColor="hover:bg-spaceCadetBlue"
              color="white"
              icon={<AddReviewIcon />}
              textSize="xs"
              radius="2xl"
              handleOnClick={triggerQAReviewModal}
            />
          )}
        </div>
        <div className="w-full pt-3">
          <div className="w-full h-auto justify-start items-center overflow-hidden">
            <div className="w-auto mb-11 px-5">
              {qaReviewList.length > 0 &&
                qaReviewList.map((obj) => (
                  <QAFeedbackCard
                    key={obj?._id}
                    {...obj}
                    triggerQAReviewModal={triggerQAReviewModal}
                    handleOnToggleDeleteReview={handleOnToggleDeleteReview}
                    completedAt={completedAt}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

QAReviewCard.propTypes = {
  qaReviewList: PropTypes.array,
  triggerQAReviewModal: PropTypes.func,
  handleOnToggleDeleteReview: PropTypes.func,
  completedAt: PropTypes.any,
};

export default QAReviewCard;
