/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDebounce } from 'use-debounce';

import { CustomTable, PrimaryButton, SearchBar } from 'components';
import CreateChannel from 'components/Icons/createChannelIcon';
import GeneralButton from 'components/Buttons/GeneralButton';

function UserTable(props) {
  const { handleAddUser, handleRemoveUser, listData } = props;

  const [searchByInput, setSearchByInput] = React.useState();
  const [debouncedSearchBy] = useDebounce(searchByInput, 500);

  const [modifiedListData, setModifiedListData] = useState(listData);

  const [customLoading, setCustomLoading] = useState({ list: false });

  useEffect(() => {
    setModifiedListData(listData);
  }, [listData]);

  useEffect(() => {
    if (debouncedSearchBy) {
      const searchByName = listData.docs.filter((obj) =>
        new RegExp(debouncedSearchBy, 'i').test(obj.name),
      );
      setModifiedListData({ ...modifiedListData, docs: searchByName });
    } else {
      setModifiedListData(listData);
    }
  }, [debouncedSearchBy]);

  const handleOnSearch = async (event) => {
    const { value } = event.target;
    setSearchByInput(value);
  };

  return (
    <div className="my-2">
      <div className="flex flex-row mb-3 items-center justify-between">
        <div className="flex flex-row mb-3 items-center">
          <h2 className="text-spaceCadetBlue font-bold font-ptScanCap text-lg">Users</h2>
          <div className="rg:w-[400px] w-auto p-3">
            <SearchBar
              placeholder="Search Users"
              searchByInput={searchByInput}
              onSearch={handleOnSearch}
            />
          </div>
        </div>
        <div>
          <GeneralButton
            text="Add User"
            btnColor="bg-spaceCadetBlue80"
            color="white"
            radius="2xl"
            textSize="sm"
            btnHoverColor="hover:bg-spaceCadetBlue"
            handleOnClick={handleAddUser}
            icon={<CreateChannel />}
          />
        </div>
      </div>
      <hr className="mb-4" />
      <CustomTable
        tableData={modifiedListData}
        handlers={{ onManage: handleRemoveUser }}
        loading={{ list: customLoading.list }}
        showPageLimit={false}
        fixedHeight
        isPaginate={false}
      />
    </div>
  );
}

UserTable.propTypes = {
  listData: PropTypes.object,
  handleAddUser: PropTypes.func,
  handleRemoveUser: PropTypes.func,
};

export default UserTable;
