import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Panel } from 'reactflow';
import GeneralButton from 'components/Buttons/GeneralButton';

/**
 * This modal use for deleting edges
 * @param props
 * @returns {JSX.Element} - A JSX element representing the `Modal` component.
 */

function EdgeDeleteModal(props) {
  const { loading, selectEdge, onDeleteEdge, setVisible } = props;
  const modalRef = useRef(null);

  const handleCancel = () => {
    setVisible(false);
  };

  const handleClickInsideModal = (e) => {
    e.stopPropagation(); // Stop the click event from propagating to the parent elements
  };

  return (
    <Panel position="top-center" onClick={handleClickInsideModal}>
      <div
        ref={modalRef}
        className="flex justify-center p-0 border bg-white border-gainsboroBlue rounded-lg z-50 overflow-x-hidden overflow-y-auto w-fit drop-shadow-md"
      >
        <div className="p-2">
          <div className="border-none flex items-center">
            <span className="text-base pl-2 font-normal font-lato text-left mr-2">
              Delete the selected link
            </span>
            <div>
              <GeneralButton
                text="Delete"
                btnColor="bg-begoniaRed"
                btnHoverColor="hover:bg-coralRed"
                color="white"
                radius="2xl"
                textSize="sm"
                handleOnClick={(e) => onDeleteEdge(selectEdge)}
                disabled={loading}
              />
              <GeneralButton
                text="Cancel"
                btnColor="bg-gray-300"
                btnHoverColor="hover:bg-gray-400"
                color="black"
                radius="2xl"
                textSize="sm"
                handleOnClick={handleCancel}
                disabled={loading}
                className="ml-2 cancelButton"
              />
            </div>
          </div>
        </div>
      </div>
    </Panel>
  );
}

EdgeDeleteModal.propTypes = {
  loading: PropTypes.bool,
  selectEdge: PropTypes.object,
  onDeleteEdge: PropTypes.func,
  setVisible: PropTypes.func,
};

export default EdgeDeleteModal;
