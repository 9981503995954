import React, { useCallback, useRef } from 'react';
import { Button, Modal } from 'react-daisyui';
import PropTypes from 'prop-types';

// #region assets | components
import PrimaryButton from 'components/Buttons/Primary';
import { Confirm, Close } from 'components/Icons';

/**
 * This model use to Prompt Confirmation
 * @param props
 * @returns {JSX.Element} - A JSX element representing the `Modal` component.
 */
function PromptModal(props) {
  const { title, message, show = false, onCancel, onConfirm, loading, error = null } = props;

  return (
    <div>
      <Modal
        open={show}
        className="top-0 left-0 right-0 p-0 border border-gainsboroBlue rounded-lg z-50 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(auto-1rem)] max-w-2xl max-h-full"
      >
        <Modal.Header className="font-bold pb-2 mb-2 p-4">
          <div className="flex justify-between items-center">
            <div className="flex-wrap items-center">
              <div className="text-xl font-bold font-ptScanCap">{title}</div>
            </div>
            <div className="text-sm font-normal bg-red">
              <Button
                size="xs"
                shape="circle"
                className="btn btn-xs btn-circle border-2 bg-white border-rasinBlack font-bold"
                onClick={onCancel}
                disabled={loading}
              >
                x
              </Button>
            </div>
          </div>
          <hr className="mt-3" />
        </Modal.Header>
        <Modal.Body className="p-4">
          <div className="border-none p-4 grid grid-cols-1">
            {error ? (
              <span className="text-sm font-normal font-lato text-center text-begoniaRed">
                {error}
              </span>
            ) : (
              <div />
            )}
            <span className="text-base font-normal font-lato text-center">{message}</span>
          </div>
        </Modal.Body>
        <div className="w-full pt-5 pb-5 mt-3 border-t bg-culturedGray flex flex-wrap justify-center gap-3 items-center">
          <PrimaryButton
            text="Yes"
            btnColor="bg-spaceCadetBlue80"
            color="white"
            radius="2xl"
            btnHoverColor="hover:bg-spaceCadetBlue"
            icon={<Confirm />}
            handleOnClick={onConfirm}
            loading={loading}
            disabled={loading}
          />
          <PrimaryButton
            text="No"
            btnColor="bg-begoniaRed"
            btnHoverColor="hover:bg-coralRed"
            color="white"
            icon={<Close width={20} height={20} />}
            radius="2xl"
            handleOnClick={onCancel}
            disabled={loading}
          />
        </div>
      </Modal>
    </div>
  );
}

PromptModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  show: PropTypes.bool,
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  error: PropTypes.string,
};
export default PromptModal;
