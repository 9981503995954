export enum MessageTransactions {
  ChatClose = 'ChatClose',
  ConversationEndMessage = 'ConversationEndMessage',
}

export enum MessageTypes {
  FILE = 'file',
  IMAGE = 'image',
  TEXT = 'text',
}

export enum MessageModule {
  ChatbotWidget = 'ChatbotWidget',
}

export enum MessageCreatedBy {
  Bot = 'Bot',
  User = 'User',
  Customer = 'Customer',
  System = 'System',
}
