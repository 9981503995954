import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

import { Search } from 'components/Icons';
import { CustomTable } from 'components';
import { PATHS } from 'routes/paths';

import CreateChannel from 'components/Icons/createChannelIcon';

function ListPage(props) {
  const {
    channelListData,
    handlePageChange,
    handleResultsPerPageChange,
    handleColumnSorting,
    customLoading,
    setSearchByInput,
    searchByInput,
    handleEditTableRow,
  } = props;

  return (
    <div className="page">
      {/* Add Client Button */}
      <div className="mb-2 flex flex-row align-top items-center">
        <span className="text-spaceCadetBlue font-bold text-xl font-ptScanCap pr-4">Channels</span>
        <Link to={PATHS.USER_MANAGEMENT.CHANNEL.FORM.url}>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.9 }}
            type="submit"
            className={`pl-[10px] pr-[15px] py-[5px] btn bg-spaceCadetBlue80 font-ptScanCap text-base text-white w-auto h-auto rounded-2xl hover:bg-spaceCadetBlue
              
            }`}
            style={{ textTransform: 'initial' }}
          >
            <div className="flex items-center">
              <CreateChannel />
              <span className="text-sm pl-[5px]">Create a Channel</span>
            </div>
          </motion.button>
        </Link>
      </div>
      <hr />

      {/* Filter and Export Section */}
      <div className="container py-4">
        <form className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          {/* Row 1 */}
          <div className="mb-2">
            <div className="relative block mr-2 sm:col-span-2 col-span-1">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                <svg className="h-5 w-5 mb-2 fill-slate-300" viewBox="0 0 20 20">
                  <Search color="#475574" />
                </svg>
              </span>
              <input
                className="sm:placeholder:text-sm placeholder:text-independenceBlue font-lato text-sm block bg-white border border-gainsboroBlue rounded-lg py-2 pl-9 pr-3 h-11 shadow-sm focus:outline-none focus:border-[#2684ff] sm:text-sm w-full"
                placeholder="Search by Channel Name"
                type="text"
                name="search"
                onChange={(event) => setSearchByInput(event.target.value)}
                value={searchByInput || ''}
              />
            </div>
          </div>
        </form>
      </div>

      <CustomTable
        tableData={channelListData}
        onPageChange={handlePageChange}
        handleResultsPerPageChange={handleResultsPerPageChange}
        handlers={{ onManage: handleEditTableRow }}
        isPaginate
        onSort={handleColumnSorting}
        loading={{ list: customLoading.list }}
        handleEdit={handleEditTableRow}
      />
    </div>
  );
}

ListPage.propTypes = {
  handlePageChange: PropTypes.func,
  handleColumnSorting: PropTypes.func,
  handleResultsPerPageChange: PropTypes.func,
  setSearchByInput: PropTypes.func,
  handleEditTableRow: PropTypes.func,
  searchByInput: PropTypes.string,
  urlSearchParams: PropTypes.shape({
    page: PropTypes.any,
    country: PropTypes.string,
    sort: PropTypes.string,
    status: PropTypes.string,
    searchBy: PropTypes.string,
    clientId: PropTypes.string,
    roleId: PropTypes.string,
    userType: PropTypes.string,
  }),
  channelListData: PropTypes.shape({
    page: PropTypes.number,
  }),
  customLoading: PropTypes.any,
};

export default ListPage;
