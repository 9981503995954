import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Handle, Position } from 'reactflow';

import { useSelector } from 'react-redux';
import { RootState } from 'slices';
import { nodeStyle } from '../constants';
import { IFlowDrawerData } from '../DrawerContents/FlowDrawer/interfaces';

function FlowNode(props) {
  const { data, selected, id } = props;

  const flowData = useSelector((state: RootState) => state?.flow);

  const [flowNodeData, setFlowNodeData] = useState<IFlowDrawerData>();

  // tracks the validity of the node and highlight invalid nodes
  const [isNodeValid, setIsNodeValid] = useState(true);
  const [nodeErrors, setNodeErrors] = useState<any>();

  useEffect(() => {
    // Find the flow object in the flowData array based on nodeId
    const flowObj = flowData.nodeConfigs.find((node) => node.nodeId === id);
    if (flowObj) {
      // Update the component state with flowSettings from the found flow object
      setFlowNodeData({ ...flowObj?.flowSettings });

      if (flowObj?.isValid !== undefined && flowObj?.isValid === false) {
        setIsNodeValid(false);
        setNodeErrors(flowObj?.errors);
      } else {
        setIsNodeValid(true);
        setNodeErrors([]);
      }
    }
  }, [flowData]);

  return (
    <div
      className={`bg-white p-1 rounded-md w-[250px] drop-shadow-lg opacity-80 ${
        selected ? 'border-2 border-[#93C5FD]' : ''
      } ${isNodeValid ? '' : 'border-2 border-red-500'} `}
    >
      <div>
        <div className="bg-blue-200 rounded-md px-2 py-1 text-center text-blue-600 font-semibold">
          {data?.heading}
        </div>

        <div className="flow-node__content p-2 justify-center text-center">
          {!flowNodeData?.flowName ? (
            <span className="text-gray-500">Choose a flow</span>
          ) : (
            <div className="flow-node__value">
              <span className="text-[#5c6a70] font-semibold">Start another flow</span>
              <div className="mt-2">
                <span className="w-fit bg-gray-100 p-1 rounded">Flow {flowNodeData?.flowName}</span>
              </div>
            </div>
          )}
        </div>
        {data?.variableName && data?.validateAs && (
          <div className="p-2 m-1 border-2 border-dotted rounded-md border-gray w-1/2 justify-end">
            <p className="text-sm text-blue-400">User input</p>
            <span className="text-xs">
              {data?.validateAs}: {data?.variableName}
            </span>
          </div>
        )}
      </div>
      <div className="p-1 text-gainsboroBlue text-right" />
      <Handle
        type="target"
        position={Position.Left}
        id={`flow-start-${id}`}
        className="!bg-blue-500"
        style={Position.Left && { ...nodeStyle, top: 18, left: -10 }}
      />
    </div>
  );
}

FlowNode.propTypes = {
  data: PropTypes.any,
  id: PropTypes.any,
  selected: PropTypes.bool,
};

export default FlowNode;
