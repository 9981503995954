import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import ELK from 'elkjs/lib/elk.bundled.js';
import { updateFlowPath } from 'slices/flow';
import { useReactFlow } from 'reactflow';
import { groupedOptions } from './components/constants';

const elk: any = new ELK();

// graph layout by computing optimal positions for nodes and edges,
// based on default options.
export const useLayoutElements = () => {
  const dispatch = useDispatch();

  const { getNodes, setNodes, getEdges } = useReactFlow();
  const defaultOptions = {
    'elk.algorithm': 'layered',
    'elk.layered.spacing.nodeNodeBetweenLayers': 100,
    'elk.spacing.nodeNode': 80,
  };

  // customize the layout
  const getLayoutElements = useCallback((options) => {
    const layoutOptions: any = { ...defaultOptions, ...options };
    const graph = {
      id: 'root',
      layoutOptions,
      children: getNodes(),
      edges: getEdges(),
    };

    elk.layout(graph).then(({ children }) => {
      // By mutating the children in-place we saves ourselves from creating a
      // needless copy of the nodes array.
      children.forEach((node) => {
        node.position = { x: node.x, y: node.y };
      });

      setNodes(children);

      // get updated nodes
      const nodes = children.map((child) => {
        const { onCopy, onDelete, ...restData } = child.data; // Omit onCopy and onDelete
        const { heading } = restData;
        return {
          id: child.id,
          type: child.type,
          data: { heading },
          position: child.position,
        };
      });

      // Dispatch the updated nodes using updateFlowPath action
      dispatch(updateFlowPath({ nodes }));
    });
  }, []);

  return { getLayoutElements };
};

/** build new group options based on flow variables */
export const buildVariableDropdownOptions = (flowVariables) => {
  const updatedOptions = groupedOptions;
  groupedOptions[0].options = flowVariables || [];

  return updatedOptions;
};
