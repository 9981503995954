/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { omitBy, isUndefined } from 'lodash';
import moment from 'moment';
import { components } from 'react-select';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ISelectOption } from 'interfaces/component.interface';
import { fetchContactListData } from 'services/contact.service';
import { apiErrorHandler } from 'api/handler';

import { SortBy } from 'components/Icons';

import { cleanExcelColumns, exportSingleSheetToExcel } from 'utils/excel';
import { CLIENT_STATUS } from 'constants/client.const';
import { PATHS } from 'routes/paths';
import { defaultTableHeaders, excelHeaders, tableDefaultValues } from './constants';

// create an array of options for countries select
// ! TODO use a library
import { IContactListURLQueryParams } from './interface';
import countriesData from '../../../assets/json/countries_meta.json';
import ListPage from './page';

const countries = countriesData.map((country) => ({ value: country.code, label: country.name }));
// create an array of options for sort by select
const orderByOptions = defaultTableHeaders.map((header) => ({
  value: header.key,
  label: header.value,
}));

// Custom dropdown indicator using SortBy icon
function DropdownIndicator(props) {
  return (
    <components.DropdownIndicator {...props}>
      <SortBy width={21} />
    </components.DropdownIndicator>
  );
}

/**
 * ContactList component displays the client account list.
 * It provides filtering, sorting, and pagination functionality.
 *
 * @returns JSX element representing the client account list view.
 */
function ContactList() {
  const location = useLocation();
  const navigate = useNavigate();

  // state to store URL search parameters
  const [urlSearchParams, setUrlSearchParams] = useState(null);

  //  state with table headers and contact data list
  const [contactListData, setContactListData] = useState(tableDefaultValues);

  // Effect to initialize URL search parameters from location
  useEffect(() => {
    const urlSearch = Object.fromEntries(new URLSearchParams(location.search));
    if (!urlSearch.page) {
      replaceUrlParamsHandler({ page: 1, limit: 10 });
      return;
    }
    setUrlSearchParams(urlSearch);
  }, [location.search]);

  // Effect to fetch client list data when URL search parameters change
  useEffect(() => {
    if (urlSearchParams) {
      getContactListData({ ...contactListData, ...urlSearchParams });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlSearchParams]);

  /**
   * Fetch contact list data based on provided query parameters
   *
   * @param {IContactListURLQueryParams} params - Url search params
   */
  const getContactListData = async (params: IContactListURLQueryParams) => {
    try {
      const { data } = await fetchContactListData(params);
      const docs = (data?.docs || []).map((doc) => {
        const { firstName, preferredContactMethod, country } = doc;
        const contactMethod = preferredContactMethod || {};
        const methodDisplayNames = {
          email: 'Email',
          phoneCall: 'Phone Call',
          whatsApp: 'WhatsApp',
        };

        const countryName = countries.find((val) => val.value === country.toUpperCase());
        const preferredContactMethodValue = Object.keys(contactMethod)
          .filter((method) => contactMethod[method] === true)
          .map((method) => methodDisplayNames[method])
          .join(', ');
        return {
          ...doc,
          contactName: firstName,
          preferredContactMethod:
            preferredContactMethodValue ||
            contactMethod.charAt(0).toUpperCase() + contactMethod.slice(1) ||
            null,
          country: countryName?.label || null,
        };
      });

      setContactListData({ ...contactListData, ...data, docs });
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    }
  };

  /**
   * Function to replace URL search parameters
   * @param { IContactListURLQueryParams } params -  Url search query params
   */

  const replaceUrlParamsHandler = (params) => {
    const queryParams = omitBy(
      {
        page: params.page || '1',
        limit: params.limit,
        sort: params.sort,
        order: params.order,
        searchBy: params.searchBy,
        country: params.country,
        orderBy: params.orderBy,
        lastUpdatedAt: params.lastUpdatedAt,
      },
      isUndefined,
    );

    const searchParams = new URLSearchParams(queryParams);
    setUrlSearchParams(queryParams);
    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  /**
   * Handle page change in pagination by replacing the url search params
   *
   * @param {number} pageNo - Page number user requesting to navigate
   */
  const handlePageChange = (pageNo: number) => {
    replaceUrlParamsHandler({
      ...urlSearchParams,
      page: pageNo,
    });
  };

  /**
   * A callback function that is triggered when the user changes the number of
   * results to display per page.
   * @param {number} limit - The new limit value (i.e. number of results per page).
   */
  const handleResultsPerPageChange = (limit: number) => {
    setContactListData((prevTableData) => ({ ...prevTableData, limit }));
    replaceUrlParamsHandler({
      page: contactListData.page,
      limit,
    });
  };

  /**
   * Handle filtering by adding and removing url params using value from the element and passed id
   * @param {string} value - Value that used to search or filter the field eg: doe@abc.com
   * @param {string} id - Field or parameter that need to use for filtering. eg: adminEmail
   */
  const handleFilterBy = (value: string, id: string) => {
    // create a copy of url search param
    const updatedSearchParams = {
      ...urlSearchParams,
    };
    // add new param
    if (value) {
      updatedSearchParams[id] = value;
    } else {
      delete updatedSearchParams[id]; // Remove the key if value is undefined
    }
    // update url
    replaceUrlParamsHandler(updatedSearchParams);
  };

  /**
   * Set selected item for country select filter
   * @param {string} value - Selected value from url params(country)
   * @returns {ISelectOption} - Selected country object
   */
  const getCountrySelected = (value: string): ISelectOption => {
    const selectedCountry = countries.find(
      (countryOption) => countryOption.value === value?.toUpperCase(),
    );
    return selectedCountry;
  };

  /**
   * Set selected item for account status select filter
   * @param {string} value - Selected value from url params(status)
   * @returns {ISelectOption} - Selected status object
   */
  const getStatusSelected = (value: string): ISelectOption => {
    const selectedStatus = CLIENT_STATUS.find((statusOption) => statusOption.value === value);
    return selectedStatus;
  };

  const handleEditUser = (event, row) => {
    navigate(`${PATHS.CONTACT.VIEW.url}/?md=edit&i=${row._id}`);
  };

  /**
   * Based on the passed column set url query params for column wise sorting
   * @param {boolean} isDescending - If true sorting is descending order else ascending order
   * @param {string} column - Which column need to be used
   */
  const handleColumnWiseSorting = (isDescending, column) => {
    replaceUrlParamsHandler({
      sort: column,
      order: isDescending ? -1 : 1,
    });
  };

  // Handle excel export sheet
  const handleExportExcel = async () => {
    if (contactListData.docs.length === 0) {
      toast.warning('No records found');
      return;
    }

    const mappedColumns = excelHeaders.map((x) => ({
      header: x.value,
      key: x.key,
      width: 25,
    }));

    // Clean the columns by removing those with empty labels
    const cleanedColumns = cleanExcelColumns(mappedColumns);
    const rows = contactListData.docs; // Get the data rows

    const mappedRowData = rows.map((x) => ({
      ...x,
      createdAt: x.createdAt ? moment(x.createdAt).fromNow() : 'N/A',
    }));

    try {
      await exportSingleSheetToExcel({
        worksheetName: 'Contacts-list', // Name of the worksheet in the Excel file
        columns: cleanedColumns, // Cleaned columns for the Excel file
        rows: mappedRowData, // Mapped and formatted row data for the Excel file
        fileName: 'Contacts List', // Name of the exported Excel file
      });
    } catch (e) {
      toast.error('Something went wrong, Try again');
    }
  };

  return (
    <ListPage
      contactListData={contactListData}
      handleEditUser={handleEditUser}
      handleFilterBy={handleFilterBy}
      handlePageChange={handlePageChange}
      handleResultsPerPageChange={handleResultsPerPageChange}
      urlSearchParams={urlSearchParams}
      getCountrySelected={getCountrySelected}
      getStatusSelected={getStatusSelected}
      handleColumnSorting={handleColumnWiseSorting}
      handleExport={handleExportExcel}
    />
  );
}

export default ContactList;
