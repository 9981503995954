/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { omitBy, isUndefined } from 'lodash';
import toast from 'react-simple-toasts';
import { useDebounce } from 'use-debounce';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { CustomTable, PrimaryButton, SearchBar } from 'components';
import { AddIcon, Settings } from 'components/Icons';
import { apiErrorHandler } from 'api/handler';
import {
  createIntent,
  getIntentById,
  getIntentList,
  updateIntent,
} from 'services/messenger.service';
import { ToastTheme } from 'enums/toast.enum';
import { PATHS } from 'routes/paths';
import IntentModal from './IntentModal';
import { ModalTypes, intentsTableHeaders, modalDefaultOptions } from '../constants';
import IntentSettingsModal from './IntentSettingsModal';

function Intents(props) {
  const { instanceData, setInstanceData, pageCustomLoading } = props;

  const [searchUrlParams, setSearchUrlParams] = useState<any>();

  const navigate = useNavigate();

  const [formData, setFormData] = useState<any>();
  const [listData, setListData] = useState({
    docs: [],
    headers: intentsTableHeaders,
  });

  const [searchByInput, setSearchByInput] = useState();
  const [debouncedSearchBy] = useDebounce(searchByInput, 500);

  const [customLoading, setCustomLoading] = useState({
    fetchList: false,
    formSubmit: false,
    fetchForm: false,
  });
  const [editMode, setEditMode] = useState(false);

  const [modalOptions, setModalOptions] = useState(modalDefaultOptions);
  const [triggerChild, setTriggerChild] = useState({ transactions: null });

  // Fetch Intents when components mount
  useEffect(() => {
    if ((instanceData?._id || searchUrlParams) && !pageCustomLoading.buildingBot) {
      fetchIntents();
    }
  }, [
    instanceData?._id,
    debouncedSearchBy,
    searchUrlParams,
    triggerChild,
    pageCustomLoading.buildingBot,
  ]);

  // handle intent modal
  const onClickIntent = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    modalTypes: string,
  ) => {
    event.preventDefault();
    if (modalTypes === ModalTypes.EditIntentForm) {
      setEditMode(true);
    }
    setFormData(null);
    setModalOptions((prevState) => ({
      ...prevState,
      show: true,
      actionStatus: false,
      type: modalTypes,
      modalData: { recordId: instanceData._id },
    }));
  };

  // Get intent
  const fetchIntentById = async (id: string) => {
    try {
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: true, fetchForm: true }));
      const { data } = await getIntentById(id);
      if (data?.node) {
        setFormData((prevState: any) => ({ ...prevState, ...data.node }));
      }
    } catch (e) {
      const { message: exception } = apiErrorHandler(e);
      toast(exception, { theme: ToastTheme.failure });
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: false, fetchForm: false }));
    }
  };

  // Get All intents for messenger instance
  const fetchIntents = async () => {
    try {
      const query = {
        ...listData,
        searchBy: searchByInput,
        sort: searchUrlParams?.sort,
        order: searchUrlParams?.order,
      };
      delete query.docs;
      setCustomLoading((prevState) => ({ ...prevState, fetchList: true }));
      const { data } = await getIntentList(instanceData._id, query);
      if (data?.docs) {
        setListData({
          ...listData,
          docs: data.docs.map((val) => ({
            ...val,
            intentName: val?.displayIntentName,
          })),
        });
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast(exception, { theme: ToastTheme.failure });
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, fetchList: false }));
    }
  };

  // handle add intent and update intent
  const handleSubmit = async () => {
    const toastTask = toast(`${editMode ? 'Updating' : 'Creating'} Intent ...`, {
      loading: true,
    });
    setCustomLoading((prevState) => ({ ...prevState, formSubmit: true }));

    try {
      const payload = {
        intentName: formData?.intentName,
        displayIntentName: formData?.displayIntentName,
        botId: instanceData?.chatbotLexId,
        botVersion: 'DRAFT',
        localeId: 'en_US',
        messengerId: instanceData?._id,
      };

      if (!editMode) {
        const { data } = await createIntent(payload);
        if (data && data.node) {
          setFormData((prevState: any) => ({ ...prevState, data }));
          setTriggerChild((prevState) => ({
            ...prevState,
            transactions: uuid(),
          }));
          setModalOptions({ ...modalOptions, show: false });
          toastTask.update({
            message: 'Successfully Added Intent',
            duration: 5000,
            theme: ToastTheme.success,
            loading: false,
          });
        }
      } else {
        const updatedData = {
          intentName: formData.intentName,
          displayIntentName: formData.displayIntentName,
        };
        const { data } = await updateIntent(formData._id, updatedData);
        if (data) {
          if (data?.node) {
            setFormData((prevState: any) => ({ ...prevState, data }));
            setTriggerChild((prevState) => ({
              ...prevState,
              transactions: uuid(),
            }));
            setModalOptions({ ...modalOptions, show: false });
            setEditMode(false);
            toastTask.update({
              message: 'Successfully Updated Intent',
              duration: 5000,
              theme: ToastTheme.success,
              loading: false,
            });
          }
        }
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toastTask.update({
        message: exception,
        duration: 5000,
        theme: ToastTheme.failure,
        loading: false,
      });
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, formSubmit: false }));
    }
  };

  // Navigate to utterance page
  const handleUtterancesPage = (event, row) => {
    navigate(`${PATHS.MESSENGERS.INTENTS.url}/?md=edit&i=${row._id}`);
  };

  const handleTableSelectChange = (event, row) => {
    fetchIntentById(row._id);
  };

  // handle search
  const handleOnSearch = async (event) => {
    const { value } = event.target;
    setSearchByInput(value);
  };

  /**
   * Function to replace URL search parameters
   * @param { IContactListURLQueryParams } params -  Url search query params
   */

  const replaceUrlParamsHandler = (params) => {
    const queryParams: any = omitBy(
      {
        md: params.md,
        i: params.i,
        sort: params.sort,
        order: params.order,
      },
      isUndefined,
    );

    const searchParams = new URLSearchParams(queryParams);
    setSearchUrlParams(queryParams);
    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  /**
   * Based on the passed column set url query params for column wise sorting
   * @param {boolean} isDescending - If true sorting is descending order else ascending order
   * @param {string} column - Which column need to be used
   */
  const handleColumnWiseSorting = (isDescending, column) => {
    replaceUrlParamsHandler({
      md: 'edit',
      i: instanceData?._id,
      sort: column,
      order: isDescending ? -1 : 1,
    });
  };

  const handleOnClickCIntentSettings = () => {
    setModalOptions((prevState) => ({
      ...prevState,
      show: true,
      actionStatus: false,
      type: ModalTypes.IntentSettings,
    }));
  };

  return (
    <>
      <div className="p-5 bg-white drop-shadow rounded-xl mt-6">
        <div className="flex flex-row justify-between items-center">
          <div className="flex gap-3 items-center">
            <h2 className="text-spaceCadetBlue font-bold font-ptScanCap text-base srg:text-xl">
              Intents
            </h2>
            <div className="w-[300px]">
              <SearchBar
                placeholder="Intents"
                searchByInput={searchByInput}
                onSearch={handleOnSearch}
              />
            </div>
          </div>
          <div className="flex items-center gap-2">
            <PrimaryButton
              text="Settings"
              btnColor="bg-spaceCadetBlue80"
              btnHoverColor="hover:bg-spaceCadetBlue"
              color="white"
              icon={<Settings strokeColor="#FFF" />}
              width="[100px]"
              textSize="sm font-bold font-ptScanCap"
              radius="2xl"
              handleOnClick={handleOnClickCIntentSettings}
              className="py-[5px] pr-[15px] pl-[10px]"
              type="button"
            />
            {instanceData.isReady && listData.docs.length > 1 && (
              <PrimaryButton
                text="Add Intent"
                btnColor="bg-spaceCadetBlue80"
                btnHoverColor="hover:bg-spaceCadetBlue"
                color="white"
                icon={<AddIcon />}
                width="[100px]"
                textSize="sm font-bold font-ptScanCap"
                radius="2xl"
                handleOnClick={(e) => onClickIntent(e, ModalTypes.AddIntentForm)}
                className="py-[5px] pr-[15px] pl-[10px]"
              />
            )}
          </div>
        </div>
        <hr className="my-4" />
        <div className="mt-4">
          <CustomTable
            tableData={listData}
            isPaginate={false}
            loading={{ list: customLoading.fetchList }}
            handlers={{ handleUtterancesPage, onClickIntent }}
            tableRowSelect={handleTableSelectChange}
            onSort={handleColumnWiseSorting}
          />
        </div>
      </div>
      {/* {modalOptions.type === editMode
        ? ModalTypes.EditIntentForm
        : ModalTypes.AddIntentForm &&
          modalOptions.show && (
            <IntentModal
              handleSubmit={handleSubmit}
              formData={formData}
              setFormData={setFormData}
              editMode={editMode}
              {...modalOptions}
              toggleModal={() => setModalOptions({ ...modalOptions, show: false })}
            />
          )} */}

      <IntentModal
        handleSubmit={handleSubmit}
        formData={formData}
        setFormData={setFormData}
        editMode={editMode}
        {...modalOptions}
        toggleModal={() => {
          setModalOptions({ ...modalOptions, show: false });
          setEditMode(false);
        }}
        loading={customLoading.fetchList || customLoading.formSubmit}
      />

      {modalOptions.type === ModalTypes.IntentSettings && modalOptions.show && (
        <IntentSettingsModal
          listData={listData}
          instanceData={instanceData}
          setInstanceData={setInstanceData}
          {...modalOptions}
          toggleModal={() => setModalOptions({ ...modalOptions, show: false })}
        />
      )}
    </>
  );
}

Intents.propTypes = {
  instanceData: PropTypes.object,
  pageCustomLoading: PropTypes.object,
  triggerChild: PropTypes.string,
  setInstanceData: PropTypes.func,
  editMode: PropTypes.bool,
};

export default Intents;
