/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import { AnimatePresence } from 'framer-motion';
import { toastConfig } from 'react-simple-toasts';

import { useIp } from 'hooks/useIp';
import { authProtectedRoutes, allPublicRoutes } from 'routes/routes';
import { updateUser, updateUserLocationData } from 'slices/user';
import { SocketProvider } from 'contexts/socket';
import { getUserId } from 'utils/auth';
import { apiErrorHandler } from 'api/handler';
import { getAuthorizedUser } from 'services/auth.service';

import useAuth from './routes';
import { PublicLayout } from './layouts/Layout';
import PrivateLayout from './layouts/Layout/Private';

/**
 * The main application component.
 *
 * @component
 * @returns {JSX.Element} The JSX element representing the application.
 */
function App(): JSX.Element {
  const dispatch = useDispatch();

  const ipData = useIp();

  // specify the theme in toastConfig
  toastConfig({
    theme: 'dark',
    position: 'bottom-right',
  });

  // Determine if the user is authenticated.
  const isAuthenticated = useAuth();

  // Get the current route path using react-router-dom's useLocation hook.
  let currentPath = window?.location.pathname;

  // Remove the trailing "/" if it exists
  if (currentPath.endsWith('/')) {
    currentPath = currentPath.slice(0, -1);
  }

  // Determine if the current path is a protected route.
  const isProtectedRoute = authProtectedRoutes.some((route) => {
    if (!route.isProtected) {
      return false; // Skip non-protected routes
    }

    /** Check if the current path is equal to the route path
     *  or if it matches the pattern for dynamic routes. */
    return (
      currentPath === route.path ||
      (route.path.includes('/:') &&
        new RegExp(`^${route.path.replace(/:[^/]+/g, '[^/]+')}$`).test(currentPath))
    );
  });

  useEffect(() => {
    if (ipData.country_code) {
      dispatch(updateUserLocationData(ipData));
    }
  }, [ipData]);

  useEffect(() => {
    fetchUser();
  }, [isAuthenticated]);

  useEffect(() => {
    const previousPath = window.location.pathname;

    // Check if only the query parameters have changed.
    if (previousPath !== currentPath) {
      // Scroll to the top of the page when the path changes.
      window.scrollTo(0, 0);
    }
  }, [window?.location.pathname]);

  async function fetchUser() {
    try {
      const userId = getUserId();
      if (!userId) {
        return;
      }
      const { data } = await getAuthorizedUser(userId);
      if (data?.node) {
        dispatch(updateUser(data.node));
      }
    } catch (e) {
      apiErrorHandler(e);
    }
  }

  // Use useMemo to create the routers configuration based on authentication status.
  const routers = useMemo(
    () =>
      createBrowserRouter([
        {
          element: isAuthenticated && isProtectedRoute ? <PrivateLayout /> : <PublicLayout />,
          children: isAuthenticated ? authProtectedRoutes : allPublicRoutes,
        },
      ]),
    [isAuthenticated, isProtectedRoute],
  );

  // Provide the router to the application.
  return (
    <SocketProvider>
      <AnimatePresence>
        <RouterProvider key="router" router={routers} />
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          transition={Slide}
          limit={3}
        />
      </AnimatePresence>
    </SocketProvider>
  );
}

export default App;
