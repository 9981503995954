import moment from 'moment';

export const getNextObjectId = (objectId, objectArray) => {
  const index = objectArray.findIndex((obj) => obj._id === objectId);

  if (index !== -1 && index < objectArray.length - 1) {
    return objectArray[index + 1]._id;
  }

  return null;
};

export function constructMessage(message: any) {
  const formattedMessage = {
    ...message,
    contactName:
      message?.contact?.firstName || message?.contact?.lastName
        ? `${message?.contact?.firstName || ''} ${message?.contact?.lastName || ''}`
        : 'Customer',
    date: constructDateAndTime(message?.createdAt),
  };
  return formattedMessage;
}

// replace a text in the object array, matching the object's id
export function replaceMessageById(id, newText, objectArray) {
  const updatedArray = objectArray.map((obj) => {
    if (obj._id === id) {
      return {
        ...obj,
        lastText: newText,
        lastMsg: newText,
        date: constructDateAndTime(new Date()),
      };
    }
    return obj;
  });

  return updatedArray;
}

// this suppose to construct a date time, if the date is today, only construct the time.
// but if the date pass today, construct date and time
export function constructDateAndTime(dateString) {
  const inputDate = new Date(dateString);

  const currentDate = new Date();

  // Check if the input date is within today
  if (
    inputDate.getDate() === currentDate.getDate() &&
    inputDate.getMonth() === currentDate.getMonth() &&
    inputDate.getFullYear() === currentDate.getFullYear()
  ) {
    return moment(inputDate).format('h:mm:ss A');
  }
  const formattedDate = moment(inputDate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD - hh:mm:ss A');
  return formattedDate;
}
