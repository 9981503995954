import React, { useState } from 'react';
import { FieldError, Merge, FieldErrorsImpl, UseFormRegister } from 'react-hook-form';

export interface IValidationRules {
  required?: boolean | string;
  min?: number | string;
  max?: number | string;
  minLength?: number;
  maxLength?: number;
  pattern?: RegExp;
  validate?: (value: any) => boolean;
}

export interface IErrorMessages {
  required?: string;
  min?: string;
  max?: string;
  minLength?: string;
  maxLength?: string;
  pattern?: string;
  validate?: string;
}

export interface IFormElementProps {
  disabled?: boolean;
  label?: string;
  elementId: string;
  placeholder?: string;
  value?: string;
  validationRules?: IValidationRules;
  errorMessages?: IErrorMessages;
  register?: any; // ! TODO Type def
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | any;
  onChange?: any;
  onKeyDown?: any;
  rows?: number;
  className?: string;
  ref?: HTMLDivElement | any;
  applyEmptyValidation?: boolean;
  required?: boolean;
}

interface IInputTextProps extends IFormElementProps {
  type?: string;
  onKeyDown?: any;
}

/**
 * A custom text input component that integrates with `react-hook-form`.
 *
 * @component
 *
 * @param {IInputTextProps} props - The properties of the `TextInput` component.
 * @param {string} props.label - The label to display above the input field.
 * @param {string} props.elementId - The unique identifier for the input element.
 * @param {string} [props.placeholder] - The placeholder text to show in the input field.
 * @param {IValidationRules} [props.validationRules] - Validation rules for the input field.
 * @param {IErrorMessages} [props.errorMessages] - Error messages for different validation errors.
 * @param {UseFormRegister<FieldValues>} props.register - Register function from `react-hook-form`.
 * @param {FieldError | Merge<FieldError, FieldErrorsImpl<any>>} [props.error] - Error object.
 *
 * @returns {JSX.Element} - A JSX element representing the `TextInput` component.
 *
 * @example
 * Usage example of the `TextInput` component.
 * <TextInput
 *   elementId="username"
 *   label="Username"
 *   placeholder="Enter your username"
 *   type="text"
 *   value=""
 *   register={register}
 *   error={errors.username}
 *   validationRules={{ required: true, minLength: 4 }}
 *   errorMessages={{
 *     required: 'Username is required',
 *     minLength: 'Username must be at least 4 characters long',
 *   }}
 * />
 */
function TextInput(props: IInputTextProps) {
  const {
    label,
    elementId,
    type = 'text',
    placeholder = '',
    value,
    register,
    error,
    onChange,
    onKeyDown,
    disabled = false,
    rows = 1,
    className = '',
    ref,
    required,
  } = props;

  return (
    <div className="form-control">
      {label && (
        <label className="label" htmlFor={elementId}>
          <span className="label-text text-base font-base font-lato">{label}</span>
        </label>
      )}
      {type === 'file' && (
        <input
          {...(register ? register(elementId) : {})}
          id={elementId}
          type={type}
          placeholder={placeholder}
          className={`file-input text-sm font-lato file-input-bordered focus:outline-none placeholder:text-base placeholder:text-quickSilver placeholder:font-lato ${
            error ? 'input-error' : ''
          }`}
          value={value || ''}
          onChange={onChange}
          onKeyDown={onKeyDown}
          disabled={disabled}
        />
      )}

      {type === 'textarea' && (
        <textarea
          {...(register ? register(elementId) : {})}
          id={elementId}
          placeholder={placeholder}
          className={`textarea textarea-bordered text-base focus:outline-none ${
            error ? 'input-error' : ''
          }`}
          value={value || ''}
          onChange={onChange}
          onKeyDown={onKeyDown}
          rows={rows}
          disabled={disabled}
        />
      )}

      {type !== 'file' && type !== 'textarea' && (
        <input
          ref={ref}
          {...(register ? register(elementId) : {})}
          id={elementId}
          required={required}
          type={type}
          placeholder={placeholder}
          className={`input input-bordered focus:outline-none ${error ? 'input-error' : ''}`}
          value={value || ''}
          onChange={onChange}
          onKeyDown={onKeyDown}
          rows={rows}
          disabled={disabled}
        />
      )}
      {/* Display error message based on error type */}
      {error && (
        <label className="label relative mt-2" htmlFor={elementId}>
          <span className="label-text-alt absolute text-error">{error.message.toString()}</span>
        </label>
      )}
    </div>
  );
}

export default TextInput;
