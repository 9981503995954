import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Handle, Position } from 'reactflow';

import { RootState } from 'slices';
import SegmentCriteria from './SegmentCriteria';
import { NODE_TYPES, nodeStyle } from '../../constants';
import { ICondition } from '../../DrawerContents/FilterDrawer/interfaces';
import { CONDITION_TYPES } from '../../DrawerContents/FilterDrawer/constants';

function FilterNode(props) {
  const { id, data, selected } = props;

  const flowData = useSelector((state: RootState) => state.flow);

  const [conditionList, setConditionList] = useState<ICondition[]>();
  // tracks the validity of the node and highlight invalid nodes
  const [isNodeValid, setIsNodeValid] = useState(true);
  const [nodeErrors, setNodeErrors] = useState<any>();

  useEffect(() => {
    // get selected filter node data from flowData array: condition list
    const filterFlowObj = flowData.nodeConfigs.find((node) => node.nodeId === id) || {};
    if (filterFlowObj) {
      const { filterSettings } = filterFlowObj;
      setConditionList(filterSettings);

      if (filterFlowObj?.isValid !== undefined && filterFlowObj?.isValid === false) {
        setIsNodeValid(false);
        setNodeErrors(filterFlowObj?.errors);
      } else {
        setIsNodeValid(true);
        setNodeErrors([]);
      }
    }
  }, [flowData]);

  const renderSegmentCriteria = () => {
    if (conditionList) {
      return conditionList.map((condition: ICondition) => {
        const { _id, conditionType, segmentationMethod, segments = [] } = condition;
        const initialIfConditionDefined = segments.length > 0 && !!segments[0].criteria;

        // if `if` condition segments not defined correctly render `NOT` segment only
        if (initialIfConditionDefined || conditionType === CONDITION_TYPES.NOT) {
          return (
            <SegmentCriteria
              key={_id}
              conditionId={_id}
              criteriaType={conditionType}
              segmentationMethod={segmentationMethod}
              segments={segments}
              nodeErrors={nodeErrors}
            />
          );
        }
        return '';
      });
    }
    return ''; // if conditionList is falsy, return
  };
  return (
    <div
      className={`filter-node drop-shadow-lg opacity-80 ${
        selected ? 'border-2 border-[#FDE047]' : ''
      }${isNodeValid ? '' : 'border-2 border-red-500'} `}
    >
      <div>
        <div className="filter-node__heading">{NODE_TYPES[data?.heading]?.label}</div>

        {renderSegmentCriteria()}
      </div>
      <Handle
        type="target"
        position={Position.Left}
        id={`filter-start-${id}`}
        className="!bg-blue-500"
        style={Position.Left && { ...nodeStyle, top: 18, left: -10 }}
      />
    </div>
  );
}

FilterNode.propTypes = {
  id: PropTypes.string,
  data: PropTypes.any,
  selected: PropTypes.bool,
};

export default FilterNode;
