import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { RootState } from 'slices';
import { IFlowPayload, updateAPIRequestMethod, updateAPIRequestUrl } from 'slices/flow';
import { OpenInNewTabIcon } from 'components/Icons';
import { CustomSelect } from 'components';
import VariableInputField from '../common/VariableInputField';

import CreateVariableModal from '../../Modals/CreateVariableModal';
import { TRequestMethod } from './interfaces';
import { REQUEST_METHOD_OPTIONS } from './constants';
import RequestHeaders from './components/RequestHeaders';
import MappingSettings from './components/MappingSettings';

interface IAPIRequestDrawerProps {
  nodeId: string;
  toggleDrawer: () => void;
}

const APIRequestDrawer = forwardRef(({ nodeId, toggleDrawer }: IAPIRequestDrawerProps, ref) => {
  const dispatch = useDispatch();

  const flowData = useSelector((state: RootState) => state.flow);

  const [showCreateVariableModal, setShowCreateVariableModal] = useState(false);
  const [requestData, setRequestData] = useState<any>({});

  useEffect(() => {
    if (flowData?.nodeConfigs.length > 0) {
      // get selected filter node data from flowData array: condition list
      const selectedNode = flowData.nodeConfigs.find((node) => node?.nodeId === nodeId);

      if (selectedNode) {
        const { apiRequestSettings } = selectedNode;
        setRequestData(apiRequestSettings);
      }
    }
  }, [flowData?.nodeConfigs]);

  // if headers are present the component need to show
  const headersComponentVisible = requestData?.headers?.length > 0;
  const mappingSettingComponentVisible = requestData?.mappingSettings?.length > 0;

  /**
   * Toggle create variable modal
   */
  const toggleCreateVariableModal = () => {
    setShowCreateVariableModal((prev) => !prev);
  };

  /**
   * Update request data url
   * @param {string} url - updated url
   */
  const updateUrl = (url: string) => {
    dispatch(updateAPIRequestUrl({ nodeId, url }));
  };

  /**
   * Handle update request method
   * @param {TRequestMethod} method - New request method
   */
  const handleRequestMethodChange = (method: TRequestMethod) => {
    dispatch(updateAPIRequestMethod({ nodeId, method }));
  };

  /**
   * Apply btn handler
   */
  const handleSubmit = () => {
    toggleDrawer();
  };

  // Expose the handleSubmit function to the parent component using useImperativeHandle
  useImperativeHandle(ref, () => ({
    handleSubmit,
  }));

  return (
    <div className="api-request-node-drawer h-full">
      <div className="api-url flex flex-col space-y-4">
        <div className="heading flex justify-between">
          <div className="heading__text">Retrieve data from an external URL</div>
          <div className="doc inline-flex items-center">
            <a
              className="doc__link"
              href="https://sendpulse.com/knowledge-base/chatbot/send-receive-data"
            >
              Documentation
            </a>
            <OpenInNewTabIcon color="#9db5c4" width={12} height={12} />
          </div>
        </div>

        {/* External url */}
        <div className="endpoint flex gap-x-2">
          <div className="request-method flex-initial w-28">
            <CustomSelect
              id="requestMethod"
              options={REQUEST_METHOD_OPTIONS}
              components={{
                IndicatorSeparator: () => null,
              }}
              value={REQUEST_METHOD_OPTIONS.find(
                (option) => option.value === requestData?.requestMethod,
              )}
              onChange={(selectedOption) => {
                const newMethod = selectedOption?.value as TRequestMethod;
                handleRequestMethodChange(newMethod);
              }}
            />
          </div>

          <VariableInputField value={requestData?.url} handleInputChange={updateUrl} />
        </div>

        {/* Request headers */}
        <RequestHeaders
          nodeId={nodeId}
          requestData={requestData}
          headersComponentVisible={headersComponentVisible}
        />

        {/* Mapping settings */}
        <MappingSettings
          nodeId={nodeId}
          requestData={requestData}
          mappingSettingComponentVisible={mappingSettingComponentVisible}
          toggleCreateVariableModal={toggleCreateVariableModal}
        />
      </div>

      <CreateVariableModal show={showCreateVariableModal} onCancel={toggleCreateVariableModal} />
    </div>
  );
});

APIRequestDrawer.propTypes = {
  nodeId: PropTypes.string,
  toggleDrawer: PropTypes.func,
};

export default APIRequestDrawer;
