import React from 'react';

import Template from 'components/Icons/Template';
import { AddClient, AddUser, Inbox, Settings, ViewContact } from 'components/Icons';
import { PERMISSIONS } from 'constants/permissions';
import { UserType } from 'constants/user.const';

import { PATHS } from '../paths';

export interface SideBarMenuItem {
  id?: string;
  key: string;
  label: string;
  link?: string;
  userAccesses?: UserType[];
  permissionKeys?: string[];
  paths?: string[];
  icon?: JSX.Element;
  subMenu?: SideBarMenuItem[];
}

const { ClientUser, SuperUser, SystemManagementUser } = UserType;
const { INBOX, CLIENT_MANAGEMENT, CONTACTS, USER_MANAGEMENT, CASES, SETTINGS } = PERMISSIONS;

// menu items
export const menuItems: SideBarMenuItem[] = [
  {
    label: 'Inbox',
    link: PATHS.INBOX.DEFAULT.url,
    // permissionKeys: [],
    icon: <Inbox className="min-w-max bg-[#F4F4F4]" />,
    key: 'inbox-mi',
    subMenu: [
      {
        label: 'Chats',
        link: PATHS.INBOX.DEFAULT.url,
        userAccesses: [ClientUser],
        key: PATHS.INBOX.DEFAULT.key,
        permissionKeys: [INBOX.INBOX.VIEW_CHAT],
        paths: [PATHS.INBOX.DEFAULT.url, PATHS.INBOX.DEFAULT.url2],
      },
      {
        label: 'Bot Chats',
        link: PATHS.INBOX.BOT_CHAT.LIST.url,
        userAccesses: [ClientUser],
        key: PATHS.INBOX.BOT_CHAT.LIST.key,
        permissionKeys: [INBOX.BOT_CHATS.VIEW_BOT_CHAT],
        paths: [PATHS.INBOX.BOT_CHAT.LIST.url],
      },
      {
        label: 'Completed Chats',
        link: PATHS.INBOX.COMPLETED_CHAT.LIST.url,
        userAccesses: [ClientUser],
        key: PATHS.INBOX.COMPLETED_CHAT.LIST.key,
        permissionKeys: [INBOX.COMPLETED_CHATS.VIEW_COMPLETED_CHAT],
        paths: [PATHS.INBOX.COMPLETED_CHAT.LIST.url],
      },
    ],
  },
  {
    label: 'Clients Management',
    permissionKeys: [CLIENT_MANAGEMENT.CLIENTS.VIEW_CLIENT],
    icon: <AddClient color="#475574" className="min-w-max" />,
    key: 'client-mi',
    subMenu: [
      {
        label: 'Clients',
        link: PATHS.CLIENT_MANAGEMENT.LIST.url,
        userAccesses: [SuperUser, SystemManagementUser],
        key: PATHS.CLIENT_MANAGEMENT.LIST.key,
        permissionKeys: [CLIENT_MANAGEMENT.CLIENTS.VIEW_CLIENT],
        paths: [PATHS.CLIENT_MANAGEMENT.LIST.url, PATHS.CLIENT_MANAGEMENT.FORM.url],
      },
    ],
  },
  {
    label: 'Users Management',
    permissionKeys: [USER_MANAGEMENT.USERS.VIEW_USER],
    icon: <AddUser color="#475574" className="min-w-max" />,
    key: 'user-mi',
    subMenu: [
      {
        label: 'Users',
        link: PATHS.USER_MANAGEMENT.LIST.url,
        userAccesses: [ClientUser, SuperUser, SystemManagementUser],
        permissionKeys: [USER_MANAGEMENT.USERS.VIEW_USER],
        key: PATHS.USER_MANAGEMENT.LIST.key,
        paths: [PATHS.USER_MANAGEMENT.LIST.url, PATHS.USER_MANAGEMENT.FORM.url],
      },
      {
        label: 'Role Permissions',
        link: PATHS.USER_MANAGEMENT.ROLE.LIST.url,
        userAccesses: [ClientUser, SuperUser, SystemManagementUser],
        permissionKeys: [USER_MANAGEMENT.ROLE_PERMISSION.VIEW_ROLE_PERMISSION],
        key: PATHS.USER_MANAGEMENT.ROLE.LIST.key,
        paths: [PATHS.USER_MANAGEMENT.ROLE.LIST.url, PATHS.USER_MANAGEMENT.ROLE.FORM.url],
      },
      {
        label: 'Channels',
        link: PATHS.USER_MANAGEMENT.CHANNEL.LIST.url,
        userAccesses: [ClientUser],
        permissionKeys: [USER_MANAGEMENT.CHANNELS.VIEW_CHANNEL],
        key: PATHS.USER_MANAGEMENT.CHANNEL.LIST.key,
        paths: [PATHS.USER_MANAGEMENT.CHANNEL.LIST.url, PATHS.USER_MANAGEMENT.CHANNEL.FORM.url],
      },
    ],
  },
  {
    label: 'Contacts',
    link: PATHS.CONTACT.LIST.url,
    userAccesses: [ClientUser],
    permissionKeys: [CONTACTS.CONTACTS.VIEW_CONTACT],
    icon: <ViewContact color="#475574" className="min-w-max" />,
    key: PATHS.CONTACT.LIST.key,
    paths: [PATHS.CONTACT.LIST.url, PATHS.CONTACT.FORM.url, PATHS.CONTACT.VIEW.url],
  },
  {
    label: 'Cases',
    link: PATHS.CASES_MANAGEMENT.LIST.url,
    userAccesses: [ClientUser],
    permissionKeys: [CASES.CASES.VIEW_CASE],
    icon: <Template className="min-w-max" />,
    key: PATHS.CASES_MANAGEMENT.LIST.key,
    paths: [PATHS.CASES_MANAGEMENT.LIST.url, PATHS.CASES_MANAGEMENT.FORM.url],
  },
  {
    id: 'settingsMenuItem',
    label: 'Settings',
    permissionKeys: [],
    icon: <Settings strokeColor="#475574" width={22} height={22} />,
    key: 'settings-mi',
    subMenu: [
      {
        id: 'messengersSubMenuItem',
        label: 'Messengers',
        link: PATHS.MESSENGERS.LIST.url,
        userAccesses: [ClientUser],
        permissionKeys: [SETTINGS.MESSENGERS.VIEW_MESSENGER],
        key: PATHS.MESSENGERS.LIST.key,
        paths: [PATHS.MESSENGERS.LIST.url, PATHS.MESSENGERS.FORM.url],
      },
    ],
  },
];
