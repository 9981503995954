/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { getAgentStatus, updateAgentStatus } from 'services/user.service';
import { apiErrorHandler } from 'api/handler';
import { UserAgentStatus } from 'constants/user.const';
import { ChevronDown } from 'components/Icons';
import { formatUserAgentStatus } from 'utils/user';
import { updateUser } from 'slices/user';

function AgentToggle() {
  const dispatch = useDispatch();

  const [agentStatus, setAgentStatus] = useState<string>(UserAgentStatus.Offline);
  const [isLoading, setIsLoading] = useState(false);

  const allOptions = [
    {
      key: UserAgentStatus.Offline,
      label: 'Go Offline',
    },
    {
      key: UserAgentStatus.Online,
      label: 'Go Online',
    },
    {
      key: UserAgentStatus.InBreak,
      label: 'Take a Break',
    },
  ];

  useEffect(() => {
    fetchAgentStatus();
  }, []);

  const fetchAgentStatus = async () => {
    try {
      setIsLoading(true);
      const { data } = await getAgentStatus();
      if (data?.node?.agentStatus) {
        const status = data.node.agentStatus;
        setAgentStatus(status);
        dispatch(updateUser({ agentStatus: status }));
      }
    } catch (error) {
      apiErrorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Handle agent status change
   * @param {string} status - Agent status
   */
  const handleAgentStatusChange = async (status: string) => {
    try {
      setAgentStatus(status);
      dispatch(updateUser({ agentStatus: status }));
      await updateAgentStatus({ agentStatus: status });
    } catch (error) {
      apiErrorHandler(error);
    }
  };

  return (
    <div className="dropdown dropdown-end agent_status__dropdown">
      <label
        tabIndex={0}
        className={classNames('btn m-1 flex justify-center cursor-pointer', {
          ag_online: agentStatus === UserAgentStatus.Online,
          ag_offline: agentStatus === UserAgentStatus.Offline,
          ag_break: agentStatus === UserAgentStatus.InBreak,
          ag_loading: isLoading,
        })}
      >
        {isLoading ? (
          <span className="loading loading-dots loading-md" />
        ) : (
          <>
            {formatUserAgentStatus(agentStatus)}&nbsp;
            <ChevronDown
              color={agentStatus === UserAgentStatus.Online ? '#FFF' : '#000'}
              className="mt-1"
            />
          </>
        )}
      </label>
      <ul
        tabIndex={0}
        className="dropdown-content z-[1] menu p-0 shadow-md bg-base-100 rounded-box w-[200px]"
      >
        <div>
          <div className="menu_top_content" />
          {allOptions
            .filter((val) => val.key !== agentStatus)
            .map((val) => (
              <motion.div
                key={val.key}
                className="menu_item flex cursor-pointer"
                onClick={() => handleAgentStatusChange(val.key)}
              >
                <motion.span whileHover={{ scale: 1.03 }}>{val.label}</motion.span>
              </motion.div>
            ))}
          <div className="menu_bottom_content" />
        </div>
      </ul>
    </div>
  );
}

AgentToggle.propTypes = {};

export default AgentToggle;
