/* eslint-disable no-alert */
/* eslint-disable react/button-has-type */
/* eslint-disable no-undef */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';

import { Attachment, Close, UploadImage, EmojiIcon } from 'components/Icons';
import FileUploadModal from 'components/modals/FileUpload';

function ChatInput(props) {
  const {
    chatFormData,
    setChatFormData,
    setSelectedImage,
    selectedImage,
    handleKeyDown,
    disabled,
  } = props;

  const [fileUploadModalOptions, setFileUploadModalOptions] = useState({ show: false });
  const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState(false);
  const emojiPickerRef = useRef(null);

  /**
   * Emoji closer-Click occurred outside of the emoji picker, so close it
   */
  useEffect(() => {
    function handleClickOutside(event) {
      if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
        setIsEmojiPickerVisible(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  // handle the input text change
  const handleInputChange = (event: { target: { id: any; value: any } }) => {
    const { id, value } = event.target;
    setChatFormData({
      ...chatFormData,
      [id]: value,
    });
  };

  /**
   * Handle emoji selection and insert it into the input
   * Get the current value of the input
   * Add the selected emoji to the input value
   */
  const handleEmojiSelection = (emoji: EmojiClickData) => {
    const currentInputValue = chatFormData?.chatInputText || '';
    const updatedInputValue = currentInputValue + emoji.emoji;
    // Update the chatFormData with the updated input value
    setChatFormData({
      ...chatFormData,
      chatInputText: updatedInputValue,
    });

    // Close the emoji picker
    setIsEmojiPickerVisible(false);
  };

  /**
   * Upload Attachment
   */
  function handleOnClickFileUpload() {
    setFileUploadModalOptions({ ...fileUploadModalOptions, show: true });
  }

  // Function to handle image file selection
  const handleOnFileSelected = (file) => {
    // Check if the selected file is an image/png
    if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
      setSelectedImage(file);
    } else {
      alert('Please select a valid image file (PNG or JPEG).');
    }
    setFileUploadModalOptions({ ...fileUploadModalOptions, show: false });
  };

  const handleClearSelectedFile = () => {
    setSelectedImage(null);
  };

  return (
    <>
      <div className="bg-white py-4 rounded-lg border border-brightGray w-full">
        <div className="mx-4">
          {selectedImage ? (
            // Display the selected image with a clear button
            <>
              <div className="flex items-center">
                <img
                  className="relative"
                  src={URL.createObjectURL(selectedImage)}
                  alt="Selected"
                  style={{ maxWidth: '80px', maxHeight: '80px' }}
                />
                <div className="overlay">
                  <button
                    className="clear-attachment-icon"
                    title="clear"
                    onClick={handleClearSelectedFile}
                  >
                    <Close color="#000" />
                  </button>
                </div>
              </div>
              <br />
            </>
          ) : (
            // Display the textarea for text input
            <input
              type="text"
              name="chatInputText"
              id="chatInputText"
              className="py-2 mb-3 font-lato text-base rounded focus:outline-none w-full placeholder:text-xs font-light font-Lato focus:border-[#D9DEE8]"
              placeholder="Type a Message..."
              value={chatFormData?.chatInputText || ''}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              disabled={disabled}
            />
          )}
          <hr />
          <div className="grid grid-cols-2 mt-3">
            <div className="flex">
              <Attachment />
              <button
                type="button"
                className="inbox-upload_image ml-2 cursor-pointer"
                onClick={handleOnClickFileUpload}
                disabled={disabled}
              >
                <UploadImage />
              </button>
              <button
                type="button"
                onClick={() => setIsEmojiPickerVisible(!isEmojiPickerVisible)}
                className="inbox-emoji_picker ml-2 cursor-pointer"
              >
                <EmojiIcon />
              </button>
              <div
                style={{ position: 'absolute', bottom: 120, maxHeight: '350px' }}
                ref={emojiPickerRef}
              >
                {isEmojiPickerVisible && <EmojiPicker onEmojiClick={handleEmojiSelection} />}
              </div>
            </div>
            <div className="flex justify-end">
              <p className="text-[10px] font-lato ml-2 text-quickSilver">
                <span className="font-semibold">Send</span> Ctrl + Enter
              </p>
              <p className="text-[10px] font-lato ml-2 text-quickSilver">
                <span className="font-semibold">Send & Next</span> Ctrl + N
              </p>
            </div>
          </div>
        </div>
      </div>
      <FileUploadModal
        show={fileUploadModalOptions.show}
        onClose={() => setFileUploadModalOptions({ ...fileUploadModalOptions, show: false })}
        onFileSelected={handleOnFileSelected}
        type="image"
      />
    </>
  );
}
ChatInput.propTypes = {
  chatFormData: PropTypes.object,
  setChatFormData: PropTypes.func,
  setSelectedImage: PropTypes.func,
  handleKeyDown: PropTypes.func,
  selectedImage: PropTypes.any,
  disabled: PropTypes.any,
};
export default ChatInput;
