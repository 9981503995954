import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Handle, Position } from 'reactflow';
import { useSelector } from 'react-redux';
import { RootState } from 'slices';

import { nodeStyle } from '../../constants';
import { actions } from './constants';

function ActionNode(props) {
  const { id, data, selected } = props;

  const flowData = useSelector((state: RootState) => state.flow);

  const [actionSettings, setActionSettings] = useState({});

  // tracks the validity of the node and highlight invalid nodes
  const [isNodeValid, setIsNodeValid] = useState(true);
  const [nodeErrors, setNodeErrors] = useState<any>();

  useEffect(() => {
    const msgFlowObj = flowData.nodeConfigs.find((obj) => obj.nodeId === id);
    if (msgFlowObj) {
      setActionSettings(msgFlowObj);

      if (msgFlowObj?.isValid !== undefined && msgFlowObj?.isValid === false) {
        setIsNodeValid(false);
        setNodeErrors(msgFlowObj?.errors);
      } else {
        setIsNodeValid(true);
        setNodeErrors([]);
      }
    }
  }, [flowData]);

  const ComponentBuilder = useMemo(
    () =>
      function componentBuilder({ actionObj }) {
        switch (actionObj.action) {
          case actions.OPEN_CHAT:
            return (
              <React.Fragment key={actionObj.id}>
                <div>
                  <span>Open The Chat</span>
                </div>
                {actionObj?.enableChangeAssignee && (
                  <div>
                    <span className="text-xs mt-1">
                      changed the chat assignee to {actionObj?.assignedUser?.label || ''}
                    </span>
                  </div>
                )}
                {actionObj?.enablePausedHours && (
                  <div>
                    <span className="text-xs mt-1">
                      paused bot automation for {actionObj?.pausedHours?.label || ''}
                    </span>
                  </div>
                )}
              </React.Fragment>
            );
          case actions.SET_VARIABLE:
            return (
              <React.Fragment key={actionObj.id}>
                <span>
                  Set Value <span className="bg-gray-300">{actionObj?.value}</span> for variable{' '}
                  <span className="bg-gray-300">{actionObj?.variable}</span>
                </span>
              </React.Fragment>
            );
          case actions.DELETE_VARIABLE:
            return (
              <React.Fragment key={actionObj.id}>
                <span>
                  Delete variable <span className="bg-gray-300">{actionObj?.variable}</span>
                </span>
              </React.Fragment>
            );
          case actions.WEBHOOK:
            return (
              <React.Fragment key={actionObj.id}>
                <span className="overflow-hidden">
                  Set <span className="bg-gray-300">{actionObj?.title}</span> webhook for{' '}
                  <span className="bg-gray-300">{actionObj?.url}</span>
                </span>
              </React.Fragment>
            );
          default:
            return <span className="text-sm text-gray-400">Choose an action</span>;
        }
      },
    [actionSettings],
  );

  return (
    <div
      className={`bg-white p-1 rounded-md w-auto min-w-[200px] drop-shadow-lg opacity-80 text-center ${
        selected ? 'border-2 border-[#C084F4]' : ''
      }${isNodeValid ? '' : 'border-2 border-red-500'} `}
    >
      <div>
        <div className="bg-purple-300 px-2 py-1 text-center text-purple-600 font-semibold">
          {data?.heading}
        </div>
      </div>
      <br />
      <div className="px-3">
        <ComponentBuilder actionObj={actionSettings} />
      </div>
      <br />
      <br />
      <Handle
        type="target"
        position={Position.Left}
        id={`action-start-${id}`}
        className="!bg-blue-500"
        style={Position.Left && { ...nodeStyle, top: 18, left: -10 }}
      />
      <Handle
        type="source"
        position={Position.Right}
        id={`action-end-${id}`}
        className=" !bg-blue-500"
        style={Position.Right && { ...nodeStyle, top: 18, right: -10 }}
      />
    </div>
  );
}

ActionNode.propTypes = {
  id: PropTypes.string,
  data: PropTypes.any,
  selected: PropTypes.bool,
};

export default ActionNode;
