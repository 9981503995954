import React, { useState } from 'react';
import { Button, Modal } from 'react-daisyui';
import PropTypes from 'prop-types';

// #region assets | components
import PrimaryButton from 'components/Buttons/Primary';
import { Confirm } from 'components/Icons';
import { InputText } from 'components';

/**
 * This model use to Edit Utterance
 * @param props
 * @returns {JSX.Element} - A JSX element representing the `Modal` component.
 */
function EditUtterance(props) {
  const { show, toggleModal, loading, handleUpdate, modalData } = props;

  const { data } = modalData;
  const [formData, setFormData] = useState<any>(data);

  const [submitted, setSubmitted] = useState(false);

  // handle the input text change
  const handleInputChange = (event: { target: { id: any; value: any } }) => {
    const { id, value } = event.target;
    setFormData({
      ...formData,
      [id]: value,
    });
    setSubmitted(false);
  };

  const onSubmit = () => {
    setSubmitted(true);

    if (formData.utterance) {
      handleUpdate(formData);
      setSubmitted(false);
    }
  };

  return (
    <div>
      <Modal
        open={show}
        className="top-0 left-0 right-0 p-0 border border-gainsboroBlue rounded-lg z-50 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(auto-1rem)] max-w-2xl max-h-full"
      >
        <Modal.Header className="font-bold mb-1 p-4">
          <div className="flex justify-between items-center">
            <div className="flex-wrap items-center">
              <div className="text-xl font-bold font-ptScanCap">Edit Utterance</div>
            </div>
            <div className="text-sm font-normal bg-red">
              <Button
                size="xs"
                shape="circle"
                className="btn btn-xs btn-circle border-2 bg-white border-rasinBlack font-bold"
                onClick={toggleModal}
                disabled={loading}
              >
                x
              </Button>
            </div>
          </div>
          <hr className="mt-3" />
        </Modal.Header>
        <Modal.Body className="px-8 pb-8">
          <div className="border-none grid grid-cols-1">
            <InputText
              type="text"
              elementId="utterance"
              label="Utterance"
              value={formData?.utterance || ''}
              onChange={(event) => handleInputChange(event)}
            />
            {submitted && !formData?.utterance && (
              <label className="label relative mt-2 p-y-2">
                <span className="label-text-alt absolute text-error">Utterance is required</span>
              </label>
            )}
          </div>
        </Modal.Body>
        <div className="w-full pt-5 pb-5 mt-3 border-t bg-culturedGray flex flex-wrap justify-center gap-3 items-center">
          <PrimaryButton
            text="Edit Utterance"
            btnColor="bg-spaceCadetBlue80"
            color="white"
            radius="2xl"
            btnHoverColor="hover:bg-spaceCadetBlue"
            icon={<Confirm />}
            handleOnClick={onSubmit}
            loading={loading}
            disabled={loading}
          />
        </div>
      </Modal>
    </div>
  );
}

EditUtterance.propTypes = {
  show: PropTypes.bool,
  loading: PropTypes.bool,
  toggleModal: PropTypes.func,
  handleUpdate: PropTypes.func,
  modalData: PropTypes.object,
};
export default EditUtterance;
