import * as React from 'react';
import PropTypes from 'prop-types';

function AddTemplate(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color = '#fff', width = 24, height = 24 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        clipPath="url(#clip0_695_41)"
      >
        <path d="M8 9h8M8 13h6M12.5 20.5l-.5.5-3-3H6a3 3 0 01-3-3V7a3 3 0 013-3h12a3 3 0 013 3v5.5M16 19h6M19 16v6" />
      </g>
      <defs>
        <clipPath id="clip0_695_41">
          <path fill="#fff" d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
AddTemplate.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default AddTemplate;
