/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import _ from 'lodash';

import { CustomTable, PrimaryButton, PromptModal } from 'components';
import { AddIcon } from 'components/Icons';
import { apiErrorHandler } from 'api/handler';
import {
  deleteKnowledgeBase,
  getMessengerKnowledgeBases,
  uploadKnowledgeBaseFile,
} from 'services/messenger.service';
import AttachmentUpload from 'views/Settings/Messengers/Form/containers/AttachmentUpload';
import { IURLQueryParams } from 'interfaces/query.interface';
import { PromptTypes, knowledgeBaseTableHeaders, promptDefaultOptions } from '../constants';

function MessengerKnowledgeBase(props) {
  const { formData } = props;

  // const [formData, setFormData] = useState<any>();
  const [listData, setListData] = useState({
    docs: [],
    headers: knowledgeBaseTableHeaders,
    totalDocs: 0,
    totalPages: 0,
    limit: 10,
    page: 1,
    sort: null,
    order: -1,
    searchBy: null,
  });

  const [customLoading, setCustomLoading] = useState({
    fetchList: false,
    formSubmit: false,
    fileUpload: false,
  });
  const [fileUploadModalOptions, setFileUploadModalOptions] = useState({ show: false });
  const [promptOptions, setPromptOptions] = useState(promptDefaultOptions);

  useEffect(() => {
    if (formData._id) {
      getListData(getListParams(listData));
    }
  }, [formData._id]);

  function getListParams(data: any) {
    const updatedData = _.cloneDeep(data);
    delete updatedData.headers;
    delete updatedData.docs;
    return data;
  }

  const getListData = async (params: IURLQueryParams) => {
    try {
      setCustomLoading((prevState) => ({ ...prevState, list: true }));
      const { data } = await getMessengerKnowledgeBases(formData._id, params);
      if (data && data.docs) {
        setListData({ ...listData, ...data, docs: mapDocs(data.docs) });
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, list: false }));
    }
  };

  const mapDocs = (data: any[]) => {
    const docs = data.map((val) => ({
      ...val,
      fileName: val?.attachment?.originalname,
    }));
    return docs;
  };

  // handle file upload and create attachment
  const handleUploadAndCreateAttachment = async (file: any) => {
    try {
      setCustomLoading((prevState) => ({ ...prevState, fileUpload: true }));
      // Create FormData and append the selected file
      const payload = new FormData();
      payload.append('file', file);
      await uploadKnowledgeBaseFile(formData._id, payload);
      toast.success('Attachment has been uploaded');
      getListData(getListParams(listData));
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, fileUpload: false }));
    }
  };

  const handleDeleteKnowledgeBase = async (id: string) => {
    try {
      setCustomLoading((prevState) => ({ ...prevState, deleteFile: true }));
      setPromptOptions({
        ...promptOptions,
        loading: true,
      });
      await deleteKnowledgeBase(id);
      toast.success('Deleted Successfully');
      getListData(getListParams(listData));
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, deleteFile: false }));
      setPromptOptions({
        show: false,
        ...promptOptions,
        loading: false,
      });
    }
  };

  // open attachment model
  function handleOnClickFileUpload() {
    setFileUploadModalOptions({ ...fileUploadModalOptions, show: true });
  }

  // close attachment model
  const handleCloseFileUploadModal = () => {
    setFileUploadModalOptions({ ...fileUploadModalOptions, show: false });
  };

  // Function to handle image file selection
  const handleOnFileSelected = (file: any) => {
    if (file) {
      handleUploadAndCreateAttachment(file);
    }
    handleCloseFileUploadModal();
  };

  const handlePageChange = (pageNo: number) => {
    getListData({
      ...getListParams(listData),
      page: pageNo,
    });
  };

  const handleOnClickDelete = (e: any, data: any) => {
    setPromptOptions({
      ...promptOptions,
      show: true,
      title: 'Are You Sure?',
      message: 'Are you sure you want to Delete this knowledge base file?',
      onConfirm: () => handleDeleteKnowledgeBase(data._id),
      type: PromptTypes.DeleteKnowledgeBase,
    });
  };

  return (
    <>
      <div className="p-5 bg-white drop-shadow rounded-xl mt-6">
        <div className="flex flex-row justify-between items-center">
          <div className="flex gap-3 items-center">
            <h2 className="text-spaceCadetBlue font-bold font-ptScanCap text-base srg:text-xl">
              Messenger Knowledge Bases
            </h2>
          </div>
          <div className="flex items-center gap-2">
            <PrimaryButton
              text="Add Attachment"
              btnColor="bg-spaceCadetBlue80"
              btnHoverColor="hover:bg-spaceCadetBlue"
              color="white"
              icon={<AddIcon />}
              width="[100px]"
              textSize="sm font-bold font-ptScanCap"
              radius="2xl"
              handleOnClick={(e) => handleOnClickFileUpload()}
              className="py-[5px] pr-[15px] pl-[10px]"
            />
          </div>
        </div>
        <hr className="my-4" />
        <div className="mt-4">
          <CustomTable
            tableData={listData}
            isPaginate={false}
            loading={{ list: customLoading.fetchList }}
            onPageChange={handlePageChange}
            onManage={handleOnClickDelete}
          />
        </div>
      </div>

      {fileUploadModalOptions?.show && (
        <AttachmentUpload
          show={fileUploadModalOptions.show}
          onClose={handleCloseFileUploadModal}
          onFileSelected={handleOnFileSelected}
          type="file"
          title="Upload Attachment (PDF)"
          allowedTypes={['application/pdf']}
          description="PDF Only"
        />
      )}

      {promptOptions.show && (
        <PromptModal
          onCancel={() => {
            setPromptOptions({ ...promptOptions, show: false });
          }}
          {...promptOptions}
          loading={promptOptions.loading}
        />
      )}
    </>
  );
}

MessengerKnowledgeBase.propTypes = {
  formData: PropTypes.object,
};

export default MessengerKnowledgeBase;
