import instance from 'api';
import { MSG_TEMPLATES } from 'api/apiEndpoints';
import UrlBuilder from 'api/urlQueryBuilder';
import _ from 'lodash';
import { IClientListURLQueryParams } from 'views/ClientManagement/List/interface';

export const fetchMessageTemplateList = (params: IClientListURLQueryParams) => {
  const {
    page = 1,
    limit = 10,
    sort = 'createdAt',
    order = -1,
    country,
    status,
    searchBy,
    select,
  } = params;

  // creates a new object with only the non-null, non-undefined values from the extracted variables
  const queryParams = _.pickBy({
    page,
    limit,
    sort,
    order,
    country,
    status,
    searchBy,
    select,
  });
  const url = UrlBuilder()
    .setUrl(MSG_TEMPLATES.GET_TEMPLATE_LIST)
    .setQueryParams({ ...queryParams })
    .build();
  return instance.get(url);
};

export function createTemplate(data: any) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(MSG_TEMPLATES.CREATE_TEMPLATE).build();
  return instance.post(url, data);
}

export async function updateTemplate(id: string, data: any) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(MSG_TEMPLATES.UPDATE_TEMPLATE).setPathParams({ id }).build();
  return instance.patch(url, data);
}
