import * as React from 'react';
import PropTypes from 'prop-types';

function AllIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color = '#9554B8', width = 25, height = 16 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 25 16"
    >
      <path
        fill="#9DB5C4"
        d="M9 8c0-4.8 3.5-6 3.5-6S16 3.2 16 8s-3.5 6-3.5 6S9 12.8 9 8z"
        opacity="0.4"
      />
      <circle cx="8" cy="8" r="7.5" stroke="#9DB5C4" />
      <circle cx="17" cy="8" r="7.5" stroke="#9DB5C4" />
    </svg>
  );
}
AllIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default AllIcon;
