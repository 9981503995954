export const defaultTableHeaders = [
  {
    key: 'sequenceId',
    value: 'Chat ID',
  },
  {
    key: 'status',
    value: 'Status',
    type: 'statusLabel',
    params: {
      ended: {
        label: 'COMPLETED',
        color: 'white',
        bgColor: 'bg-northTexasGreen',
        borderColor: 'bg-gainsboroBlue',
      },
    },
  },
  {
    key: 'channelName',
    value: 'Channel',
  },
  {
    key: 'contactName',
    value: 'Contact',
  },
  {
    key: 'aiRating',
    value: 'AI Rating',
  },
  {
    key: 'customerRating',
    value: 'Customer Rating',
  },
  {
    key: 'createdAt',
    value: 'Started At',
    type: 'dateTime',
  },
  {
    key: 'completedAt',
    value: 'Completed Date and Time',
    type: 'dateTime',
  },
  {
    key: 'manage',
    value: 'Manage',
    type: 'viewBtn',
    permissions: [],
  },
];

export const tableDefaultValues = {
  totalDocs: 0,
  totalPages: 0,
  limit: 10,
  page: 1,
  headers: defaultTableHeaders,
  docs: [],
  sort: null,
  order: -1,
  searchBy: null,
};

export const LAST_UPDATED_AT_OPTIONS = [
  {
    value: 'today',
    label: 'Today',
  },
  {
    value: '24hours',
    label: 'Past 24 hours',
  },
  {
    value: 'week',
    label: 'This week',
  },
];

export const RATING_OPTIONS = [
  {
    value: '1',
    label: '1',
  },
  {
    value: '2',
    label: '2',
  },
  {
    value: '3',
    label: '3',
  },
  {
    value: '4',
    label: '4',
  },
  {
    value: '5',
    label: '5',
  },
];

export const excelHeaders = [
  {
    key: 'sequenceId',
    value: 'Chat ID',
  },
  {
    key: 'status',
    value: 'Status',
    type: 'statusLabel',
  },
  {
    key: 'channelName',
    value: 'Channel',
  },
  {
    key: 'contactName',
    value: 'Contact',
  },
  {
    key: 'aiRating',
    value: 'AI Rating',
  },
  {
    key: 'customerRating',
    value: 'Customer Rating',
  },
  {
    key: 'createdAt',
    value: 'Started At',
    type: 'dateTime',
  },
  {
    key: 'completedAt',
    value: 'Completed Date and Time',
    type: 'dateTime',
  },
];
