/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { createTemplate, updateTemplate } from 'services/templates.service';
import { apiErrorHandler } from 'api/handler';
import DrawerIcon from 'components/Icons/DrawerIcon';
import { RootState } from 'slices';
import { completeConversation, takeChat, updateConversation } from 'services/conversation.service';

import { ModalTypes, modalDefaultOptions } from './constants';
import ForwardMsgModal from '../components/modals/forwardMsgModal';
import CompleteMsgModal from '../components/modals/completeMsgModal';
import InboxContactPreview from '../components/ContactPreview';
import Messages from './containers/Messages';
import Conversations from './containers/Conversations';
import UserLayout from './containers/UserLayout';
import AddTemplateModal from './containers/UserLayout/modals/AddTemplateModal';

function Inbox() {
  const location = useLocation();
  const navigate = useNavigate();

  // read the path param
  const { id: conversationIdPathParam } = useParams();

  const sideBarOpen = useSelector((state: RootState) => state.layout.sideBarOpen);

  const _isMounted = useRef(true);

  const [promptOptions, setPromptOptions] = useState<any>({});
  const [triggerChild, setTriggerChild] = useState<any>({
    transactions: null,
    messages: null,
    conversations: null,
  });
  const [customLoading, setCustomLoading] = useState({
    submitMessage: false,
    fileUploading: false,
    complete: false,
    forward: false,
    conversationsLoading: false,
    messagesLoading: false,
  });

  const [conversationList, setConversationList] = useState([]);
  const [allMessages, setAllMessages] = useState([]); // chat msg list

  const [chatFormData, setChatFormData] = useState<any>({});
  const [contactData, setContactData] = useState<any>({});
  const [loadedConversationId, setLoadedConversationId] = useState(null);

  const [urlSearchParams, setUrlSearchParams] = useState(null);

  const [selectedImage, setSelectedImage] = useState(null); // chat image attachment

  const [visible, setVisible] = useState(false); // show preview model
  const [modalOptions, setModalOptions] = useState(modalDefaultOptions);

  const [isNewChat, setIsNewChat] = useState(false); //  when user move to the new tab
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(
    () => () => {
      _isMounted.current = false;
    },
    [],
  );

  // Effect to initialize URL search parameters from location
  useEffect(() => {
    const urlSearch = Object.fromEntries(new URLSearchParams(location.search));
    if (urlSearch && urlSearch.i) {
      replaceUrlParamsHandler({ ...urlSearchParams, ...urlSearch });
    } else {
      replaceUrlParamsHandler({});
    }
    setUrlSearchParams(urlSearch);
  }, []);

  useEffect(() => {
    if (urlSearchParams?.i) {
      // when switching between conversations mount off
      setLoadedConversationId(urlSearchParams?.i);
      // setSelectedTab(Number(urlSearchParams?.tab));
      if (conversationList.length > 0) {
        handleCardClick(urlSearchParams?.i);
        const filterContact = conversationList.filter((obj) => obj._id === urlSearchParams?.i);
        if (filterContact[0]?.contact) {
          setContactData({ ...filterContact[0]?.contact });
        }
      }
    }
  }, [urlSearchParams?.i, conversationList.length]);

  // contact update modal triggering function
  const handleOnUpdateContact = (data = {}) => {
    setVisible(false);
    setPromptOptions((prevState) => ({
      ...prevState,
      show: !promptOptions.show,
      data: { ...data },
    }));
  };

  const handleCreateTemplate = async (data: any) => {
    try {
      const payload: any = {
        isPrivate: data?.isPrivate,
        messageBody: data?.messageBody,
      };
      if (data?.isEdit) {
        if (data?.deleted) {
          payload.deleted = data?.deleted;
          await updateTemplate(data?._id, payload);
          toast.success('Successfully Deleted the Message Template');
        } else {
          await updateTemplate(data?._id, payload);
          toast.success('Successfully Updated the Message Template');
        }
      } else {
        await createTemplate(payload);
        toast.success('Successfully Created a Message Template');
      }
      setTriggerChild((prevState) => ({ ...prevState, transactions: uuid() }));
      handleOnUpdateContact();
    } catch (e) {
      const { message: exception } = apiErrorHandler(e);
      toast.error(exception);
    }
  };

  /**
   * Function to replace URL search parameters
   * @param { ICompletedChatListURLQueryParams } params -  Url search query params
   */
  const replaceUrlParamsHandler = ({ status, ...rest }: any) => {
    const queryParams = {
      ...rest,
    };
    const searchParams = new URLSearchParams(queryParams);
    setUrlSearchParams(queryParams);
    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  const handleOpen = (id: string) => {
    setUrlSearchParams((prev) => ({ ...prev, i: id }));
    replaceUrlParamsHandler({
      ...urlSearchParams,
      i: id,
      // tab: selectedTab,
    });
  };

  const triggerForwardMsgModal = () => {
    setModalOptions((prevState) => ({
      ...prevState,
      show: true,
      actionStatus: false,
      type: ModalTypes.ForwardMsgModal,
      data: { loadedConversationId },
    }));
  };

  const triggerCompleteMsgModal = () => {
    setModalOptions((prevState) => ({
      ...prevState,
      show: true,
      actionStatus: false,
      type: ModalTypes.CompleteMsgModal,
      data: { loadedConversationId },
    }));
  };

  /* suppose to change the ring color of the avatar */
  const handleCardClick = (clickedId: string) => {
    setConversationList((prevList) =>
      prevList.map((obj) => ({
        ...obj,
        selected: obj._id === clickedId ? !obj.selected : false,
      })),
    );
  };

  /** function which triggering on taking chat in the new tab   */
  const handleTakeChat = async (conversationId: string) => {
    try {
      const { data } = await takeChat(conversationId);
      if (data.status) {
        // setTriggerChild((prevState) => ({ ...prevState, conversations: uuid() }));
        setSelectedTab(0);
        toast.success('Successfully took the chat');
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    }
  };

  // forward conversation
  const forwardConversation = async (formData) => {
    try {
      setCustomLoading((prevState) => ({ ...prevState, forward: true }));
      const { data } = await updateConversation(loadedConversationId, {
        channelId: formData?.channelId,
        userId: formData?.userId,
      });
      if (data.status) {
        setTriggerChild((prevState) => ({ ...prevState, conversations: uuid() }));
        setModalOptions({ ...modalOptions, show: false });
        setSelectedTab(0);
        setAllMessages([]);
        setUrlSearchParams(null);
        setLoadedConversationId(null);
        toast.success('Successfully Forward the Chat');
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, forward: false }));
    }
  };

  // complete conversation
  const handleCompleteConversation = async (formData) => {
    try {
      setCustomLoading((prevState) => ({ ...prevState, complete: true }));
      const { data } = await completeConversation(loadedConversationId, {
        rate: formData?.rating,
        note: formData?.note,
      });
      if (data.status) {
        setTriggerChild((prevState) => ({ ...prevState, conversations: uuid() }));
        setModalOptions({ ...modalOptions, show: false });
        setSelectedTab(0);
        setAllMessages([]);
        setUrlSearchParams(null);
        setLoadedConversationId(null);
        toast.success('Successfully Completed the Chat');
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, complete: false }));
    }
  };

  // side bar of the contact, which is closed default
  const toggleContactPreview = () => {
    setVisible(!visible);
  };

  return (
    <>
      <div
        className={`xs:w-screen sm:w-full grid grid-cols-1 ${
          sideBarOpen || loadedConversationId === null ? 'md:grid-cols-3' : 'md:grid-cols-4'
        } w-full h-full bg-[#F4F4F4]`}
      >
        <div className="col-span-1 bg-[#F4F4F4] md:col-span-1">
          <Conversations
            handleOpen={handleOpen}
            conversationList={conversationList}
            setConversationList={setConversationList}
            setIsNewChat={setIsNewChat}
            takeChat={handleTakeChat}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            triggerChild={triggerChild}
            setTriggerChild={setTriggerChild}
            setAllMessages={setAllMessages}
            setContactData={setContactData}
            setUrlSearchParams={setUrlSearchParams}
            replaceUrlParamsHandler={replaceUrlParamsHandler}
            setLoadedConversationId={setLoadedConversationId}
            customLoading={customLoading}
            setCustomLoading={setCustomLoading}
          />
        </div>
        <div className="col-span-2 bg-brightGray md:col-span-2">
          <Messages
            handleForwardMsg={triggerForwardMsgModal}
            handleCompleteMsg={triggerCompleteMsgModal}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            isNewChat={isNewChat}
            selectedConversationId={loadedConversationId}
            customLoading={customLoading}
            setCustomLoading={setCustomLoading}
            setChatFormData={setChatFormData}
            chatFormData={chatFormData}
            allMessages={allMessages}
            setAllMessages={setAllMessages}
            conversationList={conversationList}
            handleOpen={handleOpen}
            setConversationList={setConversationList}
            selectedTab={selectedTab}
          />
        </div>
        {!sideBarOpen && loadedConversationId && (
          <div className="col-span-1 bg-[#F4F4F4] md:col-span-1">
            <UserLayout
              toggleTemplateModal={handleOnUpdateContact}
              triggerChild={triggerChild}
              setChatFormData={setChatFormData}
              contactData={contactData}
            />
          </div>
        )}

        {sideBarOpen && loadedConversationId && (
          <div className="fixed flex items-center justify-center right-0 top-1/2 text-white transform rotate-90 -mr-16">
            <button
              type="button"
              className="flex items-center justify-center bg-spaceCadetBlue80 hover:bg-spaceCadetBlue w-auto h-auto px-8 py-[1vh] text-lg font-ptScanCap font-bold rounded-b-2xl"
              onClick={toggleContactPreview}
            >
              <span className="transform -rotate-180 text-white">Contact</span>
              <div className="-rotate-90 ml-1">
                <DrawerIcon width="28" height="28" />
              </div>
            </button>
          </div>
        )}

        {promptOptions?.show && (
          <AddTemplateModal
            {...promptOptions}
            toggleModal={handleOnUpdateContact}
            handleUpdate={handleCreateTemplate}
          />
        )}
        {modalOptions.type === ModalTypes.ForwardMsgModal && modalOptions.show && (
          <ForwardMsgModal
            {...modalOptions}
            handleUpdate={forwardConversation}
            customLoading={customLoading}
            toggleModal={() => setModalOptions({ ...modalOptions, show: false })}
          />
        )}
        {modalOptions.type === ModalTypes.CompleteMsgModal && modalOptions.show && (
          <CompleteMsgModal
            {...modalOptions}
            handleUpdate={handleCompleteConversation}
            customLoading={customLoading}
            toggleModal={() => setModalOptions({ ...modalOptions, show: false })}
          />
        )}
      </div>

      <InboxContactPreview
        visible={visible}
        toggleVisible={toggleContactPreview}
        handleOnUpdateContact={handleOnUpdateContact}
        triggerChild={triggerChild}
        setChatFormData={setChatFormData}
        contactData={contactData}
      />
    </>
  );
}

export default Inbox;
