import { COLLECTIONS } from 'constants/db';

export enum ModalTypes {
  AccountCreationSuccess = 'AccountCreationSuccess',
  ActivateAccountModal = 'ActivateAccountModal',
}

export const transactionTableHeaders = [
  {
    key: 'sequenceId',
    value: 'Transaction ID',
  },
  {
    key: 'description',
    value: 'Transaction Description',
  },
  {
    key: 'createdAt',
    value: 'Transaction Date and Time',
    type: 'dateTime',
  },
  {
    key: 'createdBy',
    value: 'User',
    type: 'transactionUser',
  },
  {
    key: 'updatedValues',
    value: 'Data',
    type: 'transactionUpdatedValues',
    module: COLLECTIONS.Client,
  },
];

export const modalDefaultOptions = {
  show: false,
  actionStatus: false,
  type: null,
  data: {},
};

export const promptDefaultOptions = {
  show: false,
  title: '',
  message: '',
  onConfirm: null,
  type: null,
};

export enum PromptTypes {
  ToggleAccount = 'ToggleAccount',
}
