import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-daisyui';

import { CustomSelect, PrimaryButton } from 'components';
import { Confirm, Save } from 'components/Icons';
import { getValueForSelect } from 'utils/helpers';
import { CollectContactInfoVia } from 'enums/messenger.enum';

function ContactSettingModal(props) {
  const { show, toggleModal, handleUpdate, setFormData, formData } = props;

  const collectDetailOptions = [
    { label: 'Form', value: CollectContactInfoVia.Form },
    { label: 'Conversation', value: CollectContactInfoVia.Conversation },
  ];

  const [submitted, setSubmitted] = useState(false);

  // handle the dropdown change
  const handleSelectChange = (event: { value: any; other: any }, id: string) => {
    setFormData({
      ...formData,
      [id]: event?.value || null,
    });
    setSubmitted(false);
  };

  // handle the checkbox
  const handleCheckChange = (event) => {
    const { id, checked } = event.target;
    setFormData({
      ...formData,
      [id]: checked,
    });
  };

  const onSubmit = () => {
    setSubmitted(true);

    if (formData.collectContactInfoAtStart && !formData.collectContactInfoVia) {
      return;
    }

    handleUpdate();
    setSubmitted(false);
    toggleModal();
  };

  return (
    <Modal
      open={show}
      className="top-0 left-0 right-0 p-0 border border-gainsboroBlue rounded-lg z-50 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(auto-1rem)] max-w-2xl max-h-full"
    >
      <Modal.Header className="font-bold pb-2 border-b p-4">
        <div className="flex justify-between items-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 w-fit flex-wrap items-center">
            <div className="text-base font-bold font-ptScanCap whitespace-nowrap">
              Contact Information Collection Settings
            </div>
          </div>
          <div className="text-sm font-normal bg-red">
            <Button
              size="xs"
              shape="circle"
              className="btn btn-xs btn-circle border-2 bg-white border-rasinBlack font-bold"
              onClick={toggleModal}
            >
              x
            </Button>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="px-2 justify-center items-center pb-3 -mt-6">
          <div className="p-4 flex flex-row items-center gap-5">
            <input
              id="collectContactInfoAtStart"
              type="checkbox"
              checked={formData.collectContactInfoAtStart}
              className="checkbox"
              onChange={handleCheckChange}
            />
            <span className="text-[16px] font-semibold font-lato ">
              Collect the contact Information at the beginning of the conversation.
            </span>
            <br />
          </div>
          <div className="px-4 flex flex-col gap-4">
            <span className="text-lg font-normal font-lato">Collect the details as</span>
            <CustomSelect
              id="collectContactInfoVia"
              required
              isClearable
              placeholder=""
              options={collectDetailOptions}
              value={getValueForSelect(formData?.collectContactInfoVia, collectDetailOptions)}
              onChange={(event) => handleSelectChange(event, 'collectContactInfoVia')}
            />
            {submitted && formData.collectContactInfoAtStart && (
              <label className="label relative p-y-2 mt-2">
                <span className="label-text-alt absolute text-error">Select option to confirm</span>
              </label>
            )}
          </div>
        </div>
        <div className="w-full pt-5 pb-5 mt-3 bg-culturedGray flex flex-wrap justify-center items-center gap-3">
          <PrimaryButton
            text="Save"
            btnColor="bg-spaceCadetBlue80"
            btnHoverColor="hover:bg-spaceCadetBlue"
            color="white"
            icon={<Save />}
            radius="2xl"
            type="button"
            handleOnClick={onSubmit}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

ContactSettingModal.propTypes = {
  show: PropTypes.bool,
  toggleModal: PropTypes.func,
  setFormData: PropTypes.func,
  handleUpdate: PropTypes.func,
  formData: PropTypes.object,
};

export default ContactSettingModal;
