// collection name (saved in database)
export const COLLECTIONS = {
  Permission: 'permissions',
  User: 'users',
  AuditHistory: 'audit_history',
  Client: 'clients',
  Feature: 'features',
  Role: 'roles',
  Module: 'modules',
  Tier: 'tiers',
  Token: 'tokens',
  Session: 'sessions',
  UserAccess: 'user_accesses',
  DatabaseMeta: 'database_metas',
  Attachment: 'attachments',
  Contact: 'contacts',
  Cases: 'cases',
  CustomField: 'custom_fields',
  Note: 'notes',
  MessageTemplate: 'message_templates',
  Message: 'messages',
  Conversation: 'conversations',
  Channel: 'channels',
  Messenger: 'messengers',
  Customer: 'customers',
  AccessKey: 'access_keys',
  MessengerSetting: 'messenger_settings',
  MessengerSession: 'messenger_sessions',
  Notification: 'notifications',
  ConversationRating: 'conversation_ratings',
};
