export const FEATURE_KEYS = {
  user_management: 'user_management',
  role_permission: 'role_permission',
  channel: 'channel',
  client_management: 'client_management',
  contact: 'contact',
  inbox_chat: 'inbox_chat',
  bot_chat: 'bot_chat',
  completed_chat: 'completed_chat',
  case: 'case',
  messenger: 'messenger',
};

export const ROLES = {
  superUser: 'Super User',
};

export interface Permission {
  name: string;
  key: string;
  featureKey: string;
  featureId: string;
}

export const PERMISSIONS = {
  INBOX: {
    INBOX: {
      VIEW_CHAT: 'VIEW_CHAT',
      MESSAGING: 'MESSAGING',
      TAKE_CHAT: 'TAKE_CHAT',
    },
    BOT_CHATS: {
      VIEW_BOT_CHAT: 'VIEW_BOT_CHAT',
      TAKE_BOT_CHAT: 'TAKE_BOT_CHAT',
    },
    COMPLETED_CHATS: {
      VIEW_COMPLETED_CHAT: 'VIEW_COMPLETED_CHAT',
      RATE_COMPLETED_CHAT: 'RATE_COMPLETED_CHAT',
    },
  },
  USER_MANAGEMENT: {
    USERS: {
      ADD_USER: 'ADD_USER',
      DELETE_USER: 'DELETE_USER',
      VIEW_USER: 'VIEW_USER',
      EDIT_USER: 'EDIT_USER',
      APPROVE_USER: 'APPROVE_USER',
      ENABLE_DISABLE_USER_ACCOUNT: 'ENABLE_DISABLE_USER_ACCOUNT',
    },
    ROLE_PERMISSION: {
      ADD_ROLE_PERMISSION: 'ADD_ROLE_PERMISSION',
      VIEW_ROLE_PERMISSION: 'VIEW_ROLE_PERMISSION',
      EDIT_ROLE_PERMISSION: 'EDIT_ROLE_PERMISSION',
      DELETE_ROLE_PERMISSION: 'DELETE_ROLE_PERMISSION',
    },
    CHANNELS: {
      ADD_CHANNEL: 'ADD_CHANNEL',
      VIEW_CHANNEL: 'VIEW_CHANNEL',
      EDIT_CHANNEL: 'EDIT_CHANNEL',
      DISABLE_CHANNEL: 'DISABLE_CHANNEL',
      ADD_EDIT_USERS_CHANNEL: 'ADD_EDIT_USERS_CHANNEL',
    },
  },
  CLIENT_MANAGEMENT: {
    CLIENTS: {
      ADD_CLIENT: 'ADD_CLIENT',
      DELETE_CLIENT: 'DELETE_CLIENT',
      VIEW_CLIENT: 'VIEW_CLIENT',
      EDIT_CLIENT: 'EDIT_CLIENT',
      APPROVE_CLIENT: 'APPROVE_CLIENT',
      SEND_CLIENT_ADMIN_CREDENTIALS: 'SEND_CLIENT_ADMIN_CREDENTIALS',
    },
  },
  CONTACTS: {
    CONTACTS: {
      ADD_CONTACT: 'ADD_CONTACT',
      VIEW_CONTACT: 'VIEW_CONTACT',
      EDIT_CONTACT: 'EDIT_CONTACT',
    },
  },
  CASES: {
    CASES: {
      ADD_CASE: 'ADD_CASE',
      VIEW_CASE: 'VIEW_CASE',
      EDIT_CASE: 'EDIT_CASE',
    },
  },
  SETTINGS: {
    MESSENGERS: {
      ADD_MESSENGER: 'ADD_MESSENGER',
      VIEW_MESSENGER: 'VIEW_MESSENGER',
      EDIT_MESSENGER: 'EDIT_MESSENGER',
    },
  },
};
