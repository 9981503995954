import * as React from 'react';
import PropTypes from 'prop-types';

function OpenInNewTabIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color = '#000', width = 24, height = 24 } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 128 128">
      <path
        stroke={color}
        d="M84 11c-1.7 0-3 1.3-3 3s1.3 3 3 3h22.8L60.4 63.4c-1.2 1.2-1.2 3.1 0 4.2.6.6 1.4.9 2.1.9.7 0 1.5-.3 2.1-.9L111 21.2V44c0 1.7 1.3 3 3 3s3-1.3 3-3V14c0-1.7-1.3-3-3-3H84zM24 31c-7.2 0-13 5.8-13 13v60c0 7.2 5.8 13 13 13h60c7.2 0 13-5.8 13-13V59c0-1.7-1.3-3-3-3s-3 1.3-3 3v45c0 3.9-3.1 7-7 7H24c-3.9 0-7-3.1-7-7V44c0-3.9 3.1-7 7-7h45c1.7 0 3-1.3 3-3s-1.3-3-3-3H24z"
      />
    </svg>
  );
}
OpenInNewTabIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default OpenInNewTabIcon;
