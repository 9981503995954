import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import QuickReplyBadge from '../components/QuickReplyBadge';

/**
 * QuickReplyBuilder component for rendering quick reply badges based on components.
 * @param {Object} props - The component props.
 * @param {Object} props.components - The array of components to build quick replies from.
 * @param {Function} props.setQuickReplies - The function to set quick replies.
 * @param {boolean} props.isUserInputReply - Flag indicating whether
 * quick replies belong to subscriber response section.
 * @param {boolean} [props.old=false] - Flag indicating whether there are old replies
 * before expanding the subscriber response section.
 * @returns {JSX.Element} - The JSX representation of the QuickReplyBuilder component.
 */
export function QuickReplyBuilder(props) {
  const {
    components,
    setQuickReplies,
    isUserInputReply,
    old = false,
    handleUpdateQuickReplies,
  } = props;

  const sortedComponents = useMemo(
    () => [...components].sort((a, b) => a.position - b.position),
    [components],
  );

  let filteredComponents = [];

  /* filtering the quick reply,
  this will identified whether quick replies are belong to subscriber response section or not */
  if (isUserInputReply) {
    filteredComponents = sortedComponents.filter((obj) => obj.isUserInputReply === true);
  } else {
    filteredComponents = sortedComponents.filter((obj) => obj.isUserInputReply === false);
  }

  /**
   * Rendered quick reply components.
   * @type {Array}
   */
  const renderedComponents = filteredComponents.map((component) => (
    <QuickReplyBadge
      key={component.id}
      id={component.id}
      reply={component.text}
      setQuickReplies={setQuickReplies}
      old={old}
      handleUpdateQuickReplies={handleUpdateQuickReplies}
    />
  ));

  return <div>{renderedComponents}</div>;
}
QuickReplyBuilder.propTypes = {
  components: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      position: PropTypes.number,
    }),
  ).isRequired,
  isUserInputReply: PropTypes.bool,
  setQuickReplies: PropTypes.func.isRequired,
  old: PropTypes.bool,
  handleUpdateQuickReplies: PropTypes.func,
};
