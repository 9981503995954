import { COLLECTIONS } from 'constants/db';
import { UserType } from 'constants/user.const';
import { getUserType } from 'utils/auth';

export const transactionTableHeaders = [
  {
    key: 'sequenceId',
    value: 'Transaction ID',
  },
  {
    key: 'description',
    value: 'Transaction Description',
  },
  {
    key: 'createdAt',
    value: 'Transaction Date and Time',
    type: 'dateTime',
  },
  {
    key: 'createdBy',
    value: 'User',
    type: 'transactionUser',
  },
  {
    key: 'updatedValues',
    value: 'Data',
    type: 'transactionUpdatedValues',
    module: COLLECTIONS.Channel,
  },
];

export const userTableHeaders = [
  {
    key: 'name',
    value: 'User',
  },
  {
    key: 'email',
    value: 'Email Address',
  },
  {
    key: 'role',
    value: 'Role',
  },
  {
    key: 'addedAt',
    value: 'Added At',
    type: 'dateTime',
  },
  {
    key: 'addedBy',
    value: 'Added By',
    type: 'transactionUser',
  },
  {
    key: 'manage',
    value: 'Manage',
    type: 'deleteBtn',
    params: {
      buttonLabel: 'Delete',
      customActionHandlerName: 'displayModal',
    },
  },
];

export const modalDefaultOptions = {
  show: false,
  actionStatus: false,
  type: null,
  data: {},
};

export const promptDefaultOptions = {
  show: false,
  title: '',
  message: '',
  onConfirm: null,
  type: null,
};

export const customLoadingOptions = {
  formSubmit: false,
  fetchRoles: false,
  toggleAccount: false,
  fetchForm: false,
  fetchClients: false,
  approveAccount: false,
  sendForApproval: false,
};

export enum PromptTypes {
  ToggleAccount = 'ToggleAccount',
}

export enum ModalTypes {
  AddUsers = 'AddUsers',
}
