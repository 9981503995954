import * as React from 'react';
import PropTypes from 'prop-types';

function EndedTab(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color, width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 21 20">
      <g
        stroke="#AAA"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        clipPath="url(#clip0_223_25)"
      >
        <path d="M7.17 7.5h6.666M7.17 10.833h5M9.66 16.005L7.17 17.5V15H5.501a2.5 2.5 0 01-2.5-2.5V5.833a2.5 2.5 0 012.5-2.5h10a2.5 2.5 0 012.5 2.5v5M13.002 15.833L14.67 17.5l3.333-3.333" />
      </g>
      <defs>
        <clipPath id="clip0_223_25">
          <path fill="#fff" d="M0 0H20V20H0z" transform="translate(.502)" />
        </clipPath>
      </defs>
    </svg>
  );
}
EndedTab.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default EndedTab;
