/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { IAttachment } from 'interfaces/component.interface';
import Attachment from './components/Attachment';
import { renderTextWithLinksAndEmojis } from './utils';

interface SecondBubbleOnwardsProps {
  message: string;
  isAgent: boolean;
  isSender: boolean;
  attachment?: IAttachment;
  textHighlighter?: string;
  isSample?: boolean;
}

function SecondBubbleOnwards(props: SecondBubbleOnwardsProps) {
  const { message, isAgent, isSender, attachment, textHighlighter, isSample = false } = props;

  return (
    <div className={`m-1 max-w-sm ${isSender ? 'flex justify-end' : 'flex justify-start'}`}>
      <div
        className={classNames(
          `text-sm ${
            isAgent
              ? 'bg-[#C4E5B6] p-3 max-w-fit rounded-2xl border border-chineseSilver'
              : 'bg-white p-3 max-w-fit rounded-2xl border border-chineseSilver'
          }`,
          {
            'min-h-[80px] min-w-[250px]': isSample,
          },
        )}
      >
        {attachment ? (
          <>
            {attachment.mimetype === 'application/pdf' ? (
              <Attachment
                message={message}
                attachment={attachment}
                textHighlighter={textHighlighter}
              />
            ) : (
              <a href={attachment.location} target="blank">
                <img src={attachment?.location} alt="Attachment" />
              </a>
            )}
          </>
        ) : (
          <span className={textHighlighter}>{renderTextWithLinksAndEmojis(message)}</span>
        )}
      </div>
    </div>
  );
}

SecondBubbleOnwards.propTypes = {
  message: PropTypes.string,
  isAgent: PropTypes.bool,
  isSender: PropTypes.bool,
  attachment: PropTypes.any,
  textHighlighter: PropTypes.string,
  isSample: PropTypes.bool,
};

export default SecondBubbleOnwards;
