/* eslint-disable indent */
/* eslint-disable prettier/prettier */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import { Handle, Position } from 'reactflow';
import { useSelector } from 'react-redux';
import { RootState } from 'slices';
import { nodeStyle, messageButtonsNodeStyle } from '../../constants';

import DownloadIcon from '../../DrawerContents/MessageDrawer/components/tempIcons/Download';
import CancelIcon from '../../DrawerContents/MessageDrawer/components/tempIcons/Cancel';
import MessageButtons from './components/MessageButtons';
import QuickReplyBadgeMessageNode from './components/QuickReplies';
import {
  IMessageSetting,
  IQuickReply,
  ISubscriberSetting,
} from '../../DrawerContents/MessageDrawer/interfaces';
import TimerIcon from '../../DrawerContents/MessageDrawer/components/tempIcons/Timer';

function MessageNode(props) {
  const { id, data, selected } = props;

  const flowData = useSelector((state: RootState) => state.flow);

  const [messageSettings, setMessageSettings] = useState<IMessageSetting[]>([
    {
      id: uniqueId(),
      position: 1,
      name: 'message_text',
      text: '',
      buttons: [],
    },
  ]);

  // tracks the validity of the node and highlight invalid nodes
  const [isNodeValid, setIsNodeValid] = useState(true);

  // state for all the quick replies
  const [quickReplies, setQuickReplies] = useState<IQuickReply>();

  const [subscriberSettingsArr, setSubscriberSettingsArr] = useState<ISubscriberSetting>();

  useEffect(() => {
    const msgFlowObj = flowData.nodeConfigs.find((obj) => obj.nodeId === id);
    if (msgFlowObj) {
      setMessageSettings(msgFlowObj?.messageSettings);
      setQuickReplies(msgFlowObj?.quickReplies);
      setSubscriberSettingsArr(msgFlowObj?.subscriberSettings);

      if (msgFlowObj?.isValid !== undefined && msgFlowObj?.isValid === false) {
        setIsNodeValid(false);
      } else {
        setIsNodeValid(true);
      }
    }
  }, [flowData]);

  const ComponentBuilder = useMemo(
    () =>
      function componentBuilder({ components }) {
        const sortedComponents = [...components].sort((a, b) => a.position - b.position);

        const renderedComponents = sortedComponents.map((component, index) => {
          switch (component.name) {
            case 'message_text':
              return (
                <React.Fragment key={component.id}>
                  <div className="bg-gray-100 text-sm rounded-xl mt-1 mb-3 py-2 outline-none w-full">
                    {!component?.text ? (
                      <input
                        className="bg-gray-100 text-sm text-[#333333] rounded-xl mt-1 pl-3 outline-none w-full cursor-pointer"
                        placeholder="Type your message"
                        readOnly
                      />
                    ) : (
                      <p
                        className="text-[#333333] mb-2 pl-3 max-w-[90%] text-left"
                        style={{ whiteSpace: 'pre-line', wordWrap: 'break-word' }}
                      >
                        {component?.text}
                      </p>
                    )}
                    <div className="flex flex-col items-center justify-center h-full">
                      {component?.buttons &&
                        component?.buttons.length > 0 &&
                        component?.buttons.map((obj) => (
                          <MessageButtons key={obj.id} {...obj} messageId={component.id} />
                        ))}
                    </div>
                  </div>
                </React.Fragment>
              );
            case 'image_attachment':
              return (
                <React.Fragment key={component.id}>
                  {component?.url ? (
                    <img
                      className="max-h-[148px] w-[95%] justify-center items-center flex my-2 pl-2"
                      src={component?.url}
                      alt="Selected"
                    />
                  ) : (
                    <div className="bg-gray-100 text-sm rounded-xl mt-1 py-2 outline-none w-full text-center">
                      <p className="text-[#333333] mb-2 pl-3">Select image ...</p>
                    </div>
                  )}
                </React.Fragment>
              );
            case 'file_attachment':
              return (
                <React.Fragment key={component.id}>
                  {component?.url ? (
                    <div className="bg-gray-100 text-sm rounded-xl mt-1 py-1 outline-none w-full text-center flex items-center pl-2">
                      <DownloadIcon className="ml-2" />
                      <span className="text-[#333333] mb-1 mx-auto text-sm pr-3">
                        {component?.fileName}
                      </span>
                    </div>
                  ) : (
                    <div className="bg-gray-100 text-sm rounded-xl mt-1 py-2 outline-none w-full text-center">
                      <p className="text-[#333333] mb-2 pl-3">Select file ...</p>
                    </div>
                  )}
                </React.Fragment>
              );
            case 'delay':
              return (
                <React.Fragment key={component.id}>
                  <div className="flex">
                    <div className="bg-gray-100 text-sm rounded-xl mt-1 py-4 outline-none w-[50px] text-center relative">
                      <span className="absolute inset-y-0 left-0 mx-1 inline-flex items-center p-2">
                        <span className="h-[6px] w-[6px] bg-gray-500 rounded-full my-2" />
                        <span className="h-[6px] w-[6px] bg-gray-500 rounded-full my-2 mx-1" />
                        <span className="h-[6px] w-[6px] bg-gray-500 rounded-full my-2" />
                      </span>
                    </div>
                    {component?.delay && (
                      <div className="text-sm rounded-xl pl-1 py-4 outline-none w-auto text-center flex">
                        <div className="mt-1">
                          <TimerIcon />
                        </div>
                        <span className="text-gray-500 ml-[2px] mt-[2px]">
                          {component?.delay} sec
                        </span>
                      </div>
                    )}
                  </div>
                </React.Fragment>
              );
            default:
              return null;
          }
        });
        return <div>{renderedComponents}</div>;
      },
    [messageSettings],
  );

  const QuickReplyBuilder = useMemo(
    () =>
      function componentBuilder({ components, isUserInput = false }) {
        const sortedComponents = [...components].sort((a, b) => a.position - b.position);

        let filteredComponents = [];

        /* filtering the quick reply,
        this will identified whether quick replies
         are belong to subscriber response section or not */
        if (isUserInput) {
          filteredComponents = sortedComponents.filter((obj) => obj.isUserInputReply === true);
        } else {
          filteredComponents = sortedComponents.filter((obj) => obj.isUserInputReply === false);
        }

        const renderedComponents = filteredComponents.map((component) => (
          <div
            className={`${isUserInput ? 'flex gap-3' : 'flex justify-end mt-1 gap-3'}`}
            key={component.id}
          >
            <QuickReplyBadgeMessageNode
              key={component.id}
              id={component.id}
              reply={component.text}
              isUserInput={isUserInput}
            />
          </div>
        ));

        return <div>{renderedComponents}</div>;
      },
    [quickReplies],
  );

  return (
    <div
      className={`bg-white p-1 rounded-md w-[250px] drop-shadow-lg opacity-80 ${
        selected ? 'border-2 border-[#31c178]' : ''
      } ${isNodeValid ? '' : 'border-2 border-red-500'} relative`}
    >
      <div>
        <div className="bg-[#e5fff2] rounded-md px-2 py-1 p text-center text-[#00a851] font-semibold">
          {data?.heading}
        </div>
        <ComponentBuilder components={messageSettings} />
        {!quickReplies?.isUserInput && (
          <QuickReplyBuilder components={quickReplies?.replies || []} isUserInput={false} />
        )}
        {subscriberSettingsArr?.isEnabled && (
          <div className="flex flex-col-reverse justify-end gap-3 mt-2">
            <div className="pl-2 m-1 border-[1px] border-dashed rounded-md border-[#0b99b2] w-auto max-w-full min-w-[65%] ml-auto relative">
              <p className="text-sm text-blue-400 pb-1">User input</p>
              {subscriberSettingsArr?.validateAs === 'imageVideo' && (
                <p className="text-xss text-black pb-3">Image Video or Document</p>
              )}
              {subscriberSettingsArr?.validateAs &&
                subscriberSettingsArr?.validateAs !== 'imageVideo' &&
                subscriberSettingsArr?.answerSaveVariable && (
                  <p className="text-xs text-black pb-3">
                    {subscriberSettingsArr?.validateAs} :{' '}
                    {`{{ ${subscriberSettingsArr?.answerSaveVariable} }}`}
                  </p>
                )}
              <Handle
                type="source"
                position={Position.Right}
                id={`message-userInput-${id}`}
                className="!bg-green-500"
                style={Position.Right && { ...nodeStyle, top: 10, right: 2 }}
              />
              {(subscriberSettingsArr?.validateAs === 'string' ||
                subscriberSettingsArr?.validateAs === 'number') && (
                <>
                  <QuickReplyBuilder components={quickReplies?.replies || []} isUserInput />
                  <br />
                </>
              )}
              <br />
              <div style={{ position: 'absolute', bottom: 0, right: 2 }}>
                <div className="badge badge-outline border-dashed rounded-3xl border-gray-500 p-3 ml-auto gap-2 relative pr-8">
                  <CancelIcon />
                  <p className="text-black w-auto whitespace-nowrap">
                    {subscriberSettingsArr?.expiresIn} sec
                  </p>
                  <Handle
                    type="source"
                    position={Position.Right}
                    id={`message-expire-${id}`}
                    className="!bg-red-500"
                    style={{ ...messageButtonsNodeStyle, top: 12, right: 2 }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="p-2 text-neutral-500 text-right">Continue</div>
      <Handle
        type="target"
        position={Position.Left}
        id={`message-start-${id}`}
        className="!bg-blue-500"
        style={Position.Left && { ...nodeStyle, top: 18, left: -10 }}
      />
      {!subscriberSettingsArr?.isEnabled && (
        <Handle
          type="source"
          position={Position.Right}
          id={`message-end-${id}`}
          className="!bg-blue-500"
          style={{ ...nodeStyle, top: 'calc(100% - 18px)', right: -10 }}
        />
      )}
    </div>
  );
}

MessageNode.propTypes = {
  id: PropTypes.string,
  data: PropTypes.any,
  selected: PropTypes.bool,
};

export default MessageNode;
