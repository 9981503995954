import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

function GeneralButton(props) {
  const {
    id,
    className = '',
    text,
    btnColor,
    color,
    icon,
    width = 'w-auto',
    radius,
    btnHoverColor,
    textSize,
    handleOnClick,
    disabled,
    type = 'submit',
  } = props;
  return (
    <motion.button
      id={id}
      onClick={handleOnClick}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.9 }}
      type={type}
      className={`pl-[10px] pr-[15px] py-[5px] btn ${className} ${btnColor} font-ptScanCap text-base text-${color} ${width} capitalize h-auto rounded-${radius} ${btnHoverColor} ${
        disabled ? 'btn-disabled' : ''
      }`}
    >
      <div className="flex items-center">
        {icon}
        <span className={`text-${textSize} pl-[5px]`}>{text}</span>
      </div>
    </motion.button>
  );
}

GeneralButton.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.string,
  btnColor: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.element,
  width: PropTypes.string,
  radius: PropTypes.string,
  btnHoverColor: PropTypes.string,
  textSize: PropTypes.string,
  disabled: PropTypes.bool,
  handleOnClick: PropTypes.func,
  type: PropTypes.string,
};

export default GeneralButton;
