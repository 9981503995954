/* eslint-disable indent */
import React from 'react';
import CreatableSelect from 'react-select/creatable';

import { ISelectOption } from 'interfaces/component.interface';
import { reactSelectCustomStyles } from '../../styles/select';

export interface ReactSelectFixedProps {
  onChange?: (val: any, actionMeta: any) => void;
  onChangeInput?: (val: any, actionMeta: any) => void;
  required?: boolean;
  value?: ISelectOption;
  placeholder?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  isClearable?: boolean;
  options: any;
  customStyles?: any;
  id?: string;
  error?: any;
  isDisplaySvg?: boolean;
  isMulti?: boolean;
}

/**
 * @fileOverview React Select Updated
 * Handles React Select as Normal
 * Prevents Form From Submitting if Required Prop Passed
 * @param {*} props
 */
function CustomCreatableSelect(props: ReactSelectFixedProps) {
  const {
    id,
    required,
    onChange,
    onChangeInput,
    isClearable,
    placeholder,
    isLoading,
    value,
    isDisabled,
    options,
    isMulti,
    customStyles = reactSelectCustomStyles,
    error,
    isDisplaySvg = false,
  } = props;

  return (
    <div>
      <CreatableSelect
        id={id}
        name={id}
        className="select-input focus:outline-none rounded-xl font-normal text-sm w-full max-w-2xl"
        styles={
          error
            ? {
                ...customStyles,
                control: (provided: any, state: any) => ({
                  ...provided,
                  minHeight: 48,
                  fontSize: '14px',
                  borderRadius: 5,
                  borderColor: '#FF7171', // begoniaRed
                  fontFamily: 'Lato',
                }),
                dropdownIndicator: (provided: any) => ({
                  ...provided,
                  svg: {
                    display: isDisplaySvg ? 'none' : 'block',
                  },
                }),
              }
            : {
                ...customStyles,
                dropdownIndicator: (provided: any) => ({
                  ...provided,
                  svg: {
                    display: isDisplaySvg ? 'none' : 'block',
                  },
                }),
              }
        }
        components={{
          IndicatorSeparator: () => null,
        }}
        value={value}
        placeholder={placeholder}
        required={required}
        onChange={onChange}
        onInputChange={onChangeInput}
        isClearable={isClearable}
        options={options}
        isLoading={isLoading}
        isDisabled={isDisabled}
        isMulti={isMulti}
      />
    </div>
  );
}

export default CustomCreatableSelect;
