import { ActiveIcon, EndedTab, NewTab } from 'components/Icons';
import React from 'react';

export const tabs = [
  {
    title: 'Active',
    count: 0,
    icon: <ActiveIcon />,
  },
  {
    title: 'New',
    count: 0,
    icon: <NewTab />,
  },
  {
    title: 'Ended',
    count: 0,
    icon: <EndedTab />,
  },
];
