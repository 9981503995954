export const defaultTableHeaders = [
  {
    key: 'clientName',
    value: 'Client',
    sortingEnabled: true,
    type: 'clientAvatar',
    imageProp: 'clientCompanyLogoLocation',
  },
  {
    key: 'email',
    value: 'Email Address',
    sortingEnabled: true,
  },
  {
    key: 'sequenceId',
    value: 'User ID',
    sortingEnabled: true,
  },
  {
    key: 'name',
    value: "User's Name",
    sortingEnabled: true,
    type: 'userAvatar',
    imageProp: 'profilePicLocation',
  },
  {
    key: 'userType',
    value: 'User Type',
    sortingEnabled: true,
  },
  // {
  //   key: 'licenseType',
  //   value: 'LicenseType',
  // },
  {
    key: 'roles',
    value: 'Role(s)',
  },
  {
    key: 'primaryContactNo',
    value: 'Contact Number',
    type: 'phoneNumber',
    sortingEnabled: true,
  },
  {
    key: 'createdAt',
    value: 'Account Created',
    type: 'dateTime',
    sortingEnabled: true,
  },
  {
    key: 'creatorName',
    value: 'Account Created By',
    sortingEnabled: true,
  },
  {
    key: 'updatedAt',
    value: 'Last Updated At',
    type: 'dateTime',
    sortingEnabled: true,
  },
  {
    key: 'manage',
    value: 'Manage',
    type: 'manageBtn',
    params: {
      buttonLabel: 'View',
      customActionHandlerName: 'displayModal',
    },
  },
];

export const excelHeaders = [
  {
    key: 'clientName',
    value: 'Client',
    sortingEnabled: true,
  },
  {
    key: 'email',
    value: 'Email Address',
    sortingEnabled: true,
  },
  {
    key: 'sequenceId',
    value: 'User ID',
    sortingEnabled: true,
  },
  {
    key: 'name',
    value: "User's Name",
    sortingEnabled: true,
  },
  {
    key: 'userType',
    value: 'User Type',
    sortingEnabled: true,
  },
  {
    key: 'licenseType',
    value: 'LicenseType',
  },
  {
    key: 'roles',
    value: 'Role(s)',
  },
  {
    key: 'primaryContactNo',
    value: 'Contact Number',
    type: 'phoneNumber',
    sortingEnabled: true,
  },
  {
    key: 'createdAt',
    value: 'Account Created',
    type: 'dateTime',
    sortingEnabled: true,
  },
  {
    key: 'creatorName',
    value: 'Account Created By',
    sortingEnabled: true,
  },
  {
    key: 'updatedAt',
    value: 'Last Updated At',
    type: 'dateTime',
    sortingEnabled: true,
  },
];

export const defaultListViewData = {
  totalDocs: 0,
  totalPages: 0,
  limit: 10,
  page: 1,
  headers: defaultTableHeaders,
  docs: [],
  sort: null,
  order: -1,
  searchBy: null,
};
