export const CASE_STATUS = [
  {
    value: 'open',
    label: 'Open',
  },
  {
    value: 'completed',
    label: 'Completed',
  },
  {
    value: 'deleted',
    label: 'Deleted',
  },
  {
    value: 'closed',
    label: 'Closed',
  },
];

export const CASE_CATEGORY = [
  {
    value: 'general',
    label: 'General',
  },
  {
    value: 'normal',
    label: 'Normal',
  },
];

export const CASE_SEVERITY = [
  {
    value: 'high',
    label: 'High',
  },
  {
    value: 'medium',
    label: 'Medium',
  },
  {
    value: 'critical',
    label: 'Critical',
  },
  {
    value: 'low',
    label: 'Low',
  },
];

export const CASE_LAST_UPDATED_AT = [
  { label: 'Today', value: 'today' },
  { label: 'Past 24 hours', value: '24hours' },
  { label: 'This Week', value: 'week' },
];
