import * as React from 'react';
import PropTypes from 'prop-types';

function Template(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color, width = '22', height = '22' } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        stroke="#475574"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        clipPath="url(#clip0_695_92)"
      >
        <path d="M8 9h8M8 13h4.5M10 19l-1-1H6a3 3 0 01-3-3V7a3 3 0 013-3h12a3 3 0 013 3v4.5M17.8 20.817l-2.172 1.138a.391.391 0 01-.568-.41l.415-2.411-1.757-1.707a.39.39 0 01.217-.665l2.428-.352 1.086-2.193a.392.392 0 01.702 0l1.086 2.193 2.428.352a.39.39 0 01.217.665l-1.757 1.707.414 2.41a.39.39 0 01-.567.411L17.8 20.817z" />
      </g>
      <defs>
        <clipPath id="clip0_695_92">
          <path fill="#fff" d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

Template.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default Template;
