import React from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import { Handle, Position } from 'reactflow';

import { messageButtonsNodeStyle } from '../../../constants';
import LinkIcon from '../../../DrawerContents/MessageDrawer/components/tempIcons/Link';

function MessageButtons(props) {
  const { buttonText, buttonUrl, id, messageId } = props;
  return (
    <div className="bg-white text-sm text-[#333333] rounded-xl mt-1 py-1 pl-3 outline-none w-[90%] text-center flex items-center relative">
      {buttonUrl?.url && <LinkIcon />}
      <span className="-ml-4 flex-grow">{buttonText}</span>

      <Handle
        type="source"
        position={Position.Right}
        id={`message-${messageId}-btn-${id}`}
        className="!bg-blue-500 shadow-lg"
        style={{ ...messageButtonsNodeStyle, top: 15, right: 5 }}
      />
    </div>
  );
}

MessageButtons.propTypes = {
  id: PropTypes.string,
  buttonUrl: PropTypes.string,
  buttonText: PropTypes.string,
  messageId: PropTypes.string,
};

export default MessageButtons;
