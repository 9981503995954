import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { Panel } from 'reactflow';
import PropTypes from 'prop-types';
import WizardButton from './WizardButton';
import ZoomButtons from './ZoomButtons';
import { useLayoutElements } from '../../utils';

// Quick Action buttons component
function QuickActions(props) {
  const { getLayoutElements } = useLayoutElements();

  return (
    <div>
      <Panel position="top-right">
        <div className="flex items-center gap-2">
          <WizardButton getLayoutElements={getLayoutElements} />
          <ZoomButtons />
        </div>
      </Panel>
    </div>
  );
}

QuickActions.propTypes = {};

export default QuickActions;
