/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Textarea } from 'react-daisyui';

import { PrimaryButton } from 'components';
import { Complete } from 'components/Icons';

function CompleteMsgModal(props) {
  const { show, toggleModal, handleUpdate, customLoading } = props;

  const [formData, setFormData] = useState<any>({});

  const [selectedRating, setSelectedRating] = useState(null);

  const handleRatingClick = (rating) => {
    setSelectedRating(rating);
    setFormData({ ...formData, rating });
  };

  // handle the input text change
  const handleInputChange = (event: { target: { id: any; value: any } }) => {
    const { id, value } = event.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  return (
    <Modal
      open={show}
      className="top-0 left-0 right-0 p-0 border border-gainsboroBlue rounded-lg z-50 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(auto-1rem)] max-w-2xl max-h-full"
    >
      <Modal.Header className="font-bold pb-2 mb-2 p-4">
        <div className="flex justify-between items-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 w-fit flex-wrap items-center">
            <div className="text-base font-bold font-ptScanCap">Complete a Chat</div>
          </div>
          <div className="text-sm font-normal bg-red">
            <Button
              size="xs"
              shape="circle"
              className="btn btn-xs btn-circle border-2 bg-white border-rasinBlack font-bold"
              onClick={toggleModal}
            >
              x
            </Button>
          </div>
        </div>
        <hr className="mt-4" />
      </Modal.Header>
      <Modal.Body>
        <div className="px-4 -mt-4">
          <div className="p-4 grid grid-cols-1 items-center">
            <span className="text-sm font-normal justify-center items-center font-lato py-3">
              Rate the Conversation
            </span>
            <div className="flex space-x-4 justify-center">
              {[1, 2, 3, 4, 5].map((rating) => (
                <div
                  key={rating}
                  onClick={() => handleRatingClick(rating)}
                  className={`flex h-16 w-16 cursor-pointer items-center justify-center rounded-full border-[#D9DEE8] ${
                    selectedRating === rating ? 'bg-spaceCadetBlue text-white' : 'bg-[#EAECF0]'
                  } text-sm font-bold hover:opacity-70`}
                >
                  {rating}
                </div>
              ))}
            </div>
          </div>

          <div className="p-4 grid grid-cols-1">
            <span className="text-sm font-normal font-lato py-3">Note</span>
            <Textarea
              id="note"
              className="w-full h-20 text-sm font-lato"
              value={formData?.note || ''}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="w-full pt-5 pb-5 mt-3 border-t bg-culturedGray flex flex-wrap justify-center items-center gap-3">
          <PrimaryButton
            text="Complete"
            btnColor="bg-spaceCadetBlue80"
            btnHoverColor="hover:bg-spaceCadetBlue"
            color="white"
            icon={<Complete />}
            textSize="base"
            radius="2xl"
            loading={customLoading?.complete}
            handleOnClick={() => handleUpdate(formData)}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

CompleteMsgModal.propTypes = {
  show: PropTypes.bool,
  toggleModal: PropTypes.func,
  handleUpdate: PropTypes.func,
  customLoading: PropTypes.object,
};

export default CompleteMsgModal;
