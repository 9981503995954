import React, { useEffect, useRef, useState } from 'react';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';

import { ISelectOption } from 'interfaces/component.interface';
import GeneralButton from 'components/Buttons/GeneralButton';
import { RootState } from 'slices';
import { IVariable } from 'interfaces/flow.interface';
import { useDispatch, useSelector } from 'react-redux';
import { addVariables } from 'slices/flow';
import { ISubscriberSetting } from '../interfaces';

interface IAddVariablePopUpProps {
  setPopupVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setVariableList: React.Dispatch<React.SetStateAction<ISelectOption[] | IVariable[]>>;
  setSubscriberSettingsArr: (value: React.SetStateAction<ISubscriberSetting>) => void;
  variableType: string;
}

function AddVariablePopUp(props: IAddVariablePopUpProps) {
  const { setPopupVisible, setVariableList, variableType, setSubscriberSettingsArr } = props;
  const inputRef = useRef(null);

  const dispatch = useDispatch();
  const { variables } = useSelector((state: RootState) => state.flow);

  const [popupText, setPopupText] = useState('');
  const [errors, setErrors] = useState({
    isInvalid: false,
    msg: '',
  });

  useEffect(() => {
    // Focus on the input field when the component mounts
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handlePopupSubmit = () => {
    setPopupText('');

    const trimmedText = popupText.trim(); // trim leading and trailing spaces

    if (!trimmedText) {
      setErrors({
        isInvalid: true,
        msg: 'Variable name cannot be empty',
      });
      return; // exit the function early if the text is empty
    }

    if (popupText.length > 20) {
      setErrors({
        isInvalid: true,
        msg: 'Variable should be less than 20 characters',
      });
      return;
    }
    const existingLabel = variables.find((variable) => variable.label === popupText);

    if (existingLabel) {
      setErrors({
        isInvalid: true,
        msg: 'Variable name already exists',
      });
    } else {
      const dispatchPayload = {
        id: uniqueId(),
        type: variableType,
        label: popupText,
        value: popupText,
      };
      // setVariableList((prev: ISelectOption[] | IVariable[]) => [...prev, dispatchPayload]);
      dispatch(addVariables(dispatchPayload));
      setSubscriberSettingsArr((prev) => ({
        ...prev,
        answerSaveVariable: dispatchPayload?.value || null,
      }));
      setPopupVisible(false);
    }
  };

  return (
    <div className="absolute -top-1 -right-5 w-[463px] h-auto flex">
      <div className="bg-white p-5 rounded-md shadow-md w-full h-full">
        <label className="label" htmlFor="category">
          <span className="label-text font-bold text-base font-lato">Variable name:</span>
          <span className="label-text font-light text-xs font-lato text-gray-400">
            {variableType}
          </span>
        </label>
        <input
          type="text"
          ref={inputRef}
          id="popupText"
          className={`w-full rounded-md h-10 border text-black focus:outline-none pl-4 mb-2 ${
            errors?.isInvalid ? 'border border-red-400' : 'focus:ring-[1px] focus:ring-green-300'
          }`}
          value={popupText}
          onChange={(e) => setPopupText(e.target.value)}
        />

        {errors?.isInvalid && (
          <div className="flex justify-end text-red-500 text-xs">{errors?.msg || ''}</div>
        )}

        <div className="flex justify-between mt-2">
          <GeneralButton
            text="Create variable"
            btnColor="bg-spaceCadetBlue80"
            color="white"
            radius="2xl"
            textSize="xs"
            btnHoverColor="hover:bg-spaceCadetBlue"
            handleOnClick={handlePopupSubmit}
          />
          <button
            type="button"
            className="rounded-md bg-none drop-shadow-xl mt-2 text-sm text-spaceCadetBlue hover:text-spaceCadetBlue80"
            onClick={(e) => {
              setPopupVisible(false);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

AddVariablePopUp.propTypes = {
  setPopupVisible: PropTypes.func,
  setVariableList: PropTypes.func,
  variableType: PropTypes.string,
};

export default AddVariablePopUp;
