/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-param-last */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-array-index-key */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* eslint-disable react/prop-types */

import React, { useState, useMemo, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { SideBarMenuItem } from 'routes/sideBar.routes';
import SubMenuArrow from 'components/Icons/SubMenuArrow';
import { isUrlMatch } from 'utils/helpers';
import SubMenuItemContainer from './SubMenuItem';

function SubMenuContainer({ data, setSelectedMenu, selectedMenu }: { data: SideBarMenuItem }) {
  const { label, icon, subMenu, key, paths, id } = data;
  const { pathname } = useLocation();
  const [subMenuOpen, setSubMenuOpen] = useState(isUrlMatch(paths, pathname, subMenu));

  useEffect(() => {
    if (selectedMenu && selectedMenu !== key) {
      setSubMenuOpen(false);
    } else if (selectedMenu && selectedMenu === key && !subMenuOpen) {
      setSubMenuOpen(true);
    }
  }, [selectedMenu, pathname]);

  // Use useMemo to memoize the mapped subMenu items
  const memoizedSubMenu = useMemo(
    () =>
      // eslint-disable-next-line implicit-arrow-linebreak
      subMenu?.map((menu, idx) => <SubMenuItemContainer menu={menu} idx={idx} key={menu.key} />) ||
      [],
    [subMenu, pathname],
  );

  return (
    <>
      <li
        key={key}
        id={id}
        className={classNames(
          `nav_link-sub_menu relative menu_common ${
            subMenuOpen && '!bg-spaceCadetBlue border-independenceBlue border-b !rounded-b-none '
          }`,
          {
            is_active: subMenuOpen,
          },
        )}
        onClick={() => {
          setSubMenuOpen(!subMenuOpen);
          setSelectedMenu(key);
        }}
      >
        {icon && <div className={classNames('icon nav_content')}>{icon}</div>}
        <p
          className={classNames(
            `nav_content flex-1 capitalize text-sm ${subMenuOpen && 'text-white'}`,
          )}
        >
          {label}
        </p>
        <SubMenuArrow
          color={subMenuOpen ? '#fff' : null}
          className={classNames(
            `min-w-max ${
              subMenuOpen && 'rotate-180'
            } duration-200 stroke-independenceBlue nav_content`,
          )}
        />
      </li>
      <motion.ul
        animate={
          subMenuOpen
            ? {
                height: 'fit-content',
              }
            : {
                height: 0,
              }
        }
        className={classNames(
          'bg-spaceCadetBlue px-3 rounded-b-xl h-0 flex-col text-[0.8rem] font-normal overflow-hidden relative bottom-1',
        )}
      >
        {memoizedSubMenu}
      </motion.ul>
    </>
  );
}

SubMenuContainer.propTypes = {
  setSelectedMenu: PropTypes.func,
  selectedMenu: PropTypes.string,
  data: PropTypes.any,
  id: PropTypes.string,
};

export default SubMenuContainer;
