import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-daisyui';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { InputText, PrimaryButton } from 'components';
import { Save } from 'components/Icons';
import { customerFeedbackSettingsValidationSchema } from '../validations/index';

function CustomerFeedbackFormModal(props) {
  const { show, toggleModal, handleUpdate, setFormData, formData } = props;

  const { register, handleSubmit, formState, control } = useForm({
    resolver: yupResolver(customerFeedbackSettingsValidationSchema),
    values: useMemo(() => {
      const defaultValues = {
        ...formData,
      };
      return defaultValues;
    }, [formData]),
  });

  const { errors } = formState;

  const [checkboxState, setCheckboxState] = useState({
    enableStarRating: false,
    enableCustomQuestionOne: false,
    enableCustomQuestionTwo: false,
    enableCustomQuestionThree: false,
  });

  // form loading state
  const [customLoading, setCustomLoading] = useState({
    formSubmit: false,
  });

  useEffect(() => {
    const {
      enableStarRating = false,
      enableCustomQuestionOne = false,
      enableCustomQuestionTwo = false,
      enableCustomQuestionThree = false,
    } = formData.customerFeedbackForm;
    setCheckboxState({
      enableStarRating,
      enableCustomQuestionOne,
      enableCustomQuestionTwo,
      enableCustomQuestionThree,
    });
  }, [formData]);

  // handle the checkbox
  const handleCheckChange = (event) => {
    const { id, checked } = event.target;
    setFormData({
      ...formData,
      customerFeedbackForm: {
        ...formData.customerFeedbackForm,
        [id]: checked,
      },
    });
    setCheckboxState((prevState) => ({ ...prevState, [id]: checked }));
  };

  const handleInputChange = (event: { target: { id: any; value: any } }) => {
    const { id, value } = event.target;
    setFormData({
      ...formData,
      customerFeedbackForm: {
        ...formData.customerFeedbackForm,
        [id]: value,
      },
    });
  };

  const onSubmit = () => {
    setCustomLoading((prevState) => ({ ...prevState, formSubmit: true }));
    handleUpdate();
    setCustomLoading((prevState) => ({ ...prevState, formSubmit: false }));
    toggleModal();
  };

  return (
    <Modal
      open={show}
      className="top-0 left-0 right-0 p-0 border border-gainsboroBlue rounded-lg z-50 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(auto-1rem)] max-w-2xl max-h-full"
    >
      <Modal.Header className="font-bold pb-2 border-b p-4">
        <div className="flex justify-between items-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 w-fit flex-wrap items-center">
            <div className="text-base font-bold font-ptScanCap whitespace-nowrap">
              Customer Feedback Collection Form
            </div>
          </div>
          <div className="text-sm font-normal bg-red">
            <Button
              size="xs"
              shape="circle"
              className="btn btn-xs btn-circle border-2 bg-white border-rasinBlack font-bold"
              onClick={toggleModal}
            >
              x
            </Button>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="px-2 justify-center items-center">
          <div className="flex-row items-center mt-6 grid grid-cols-12 px-4">
            <input
              id="enableStarRating"
              {...register('enableStarRating')}
              type="checkbox"
              checked={formData?.customerFeedbackForm?.enableStarRating}
              className="checkbox col-span-1"
              onChange={handleCheckChange}
            />
            <span className="text-[16px] font-semibold font-lato col-span-3">
              Enable Star Rating
            </span>
            <div className="col-span-8">
              <InputText
                type="text"
                register={register}
                error={errors?.customQuestionOne}
                elementId="starRatingMessage"
                placeholder="Star rating message"
                value={formData?.customerFeedbackForm?.starRatingMessage || ''}
                onChange={(e: any) => {
                  handleInputChange(e);
                }}
                disabled={!checkboxState?.enableStarRating}
              />
            </div>
            {/* <div className="col-span-8">
              <InputText
                type="text"
                elementId="firstName"
                placeholder="Question or the Field Name"
                value={formData?.contactInfoForm.firstName || ''}
                onChange={(e: any) => {
                  handleInputChange(e);
                }}
              />
            </div> */}
          </div>
          <hr className="my-6 border-gainsboroBlue" />

          <div className="flex-row items-center mt-6 grid grid-cols-12 px-4">
            <input
              id="enableCustomQuestionOne"
              {...register('enableCustomQuestionOne')}
              type="checkbox"
              checked={formData?.customerFeedbackForm?.enableCustomQuestionOne}
              className="checkbox col-span-1"
              onChange={handleCheckChange}
            />
            <span className="text-[16px] font-semibold font-lato col-span-3">
              Custom Question 1
            </span>
            <div className="col-span-8">
              <InputText
                type="text"
                register={register}
                error={errors?.customQuestionOne}
                elementId="customQuestionOne"
                placeholder="Custom question 1"
                value={formData?.customerFeedbackForm?.customQuestionOne || ''}
                onChange={(e: any) => {
                  handleInputChange(e);
                }}
                disabled={!checkboxState?.enableCustomQuestionOne}
              />
            </div>
          </div>
          <hr className="my-6 border-gainsboroBlue" />

          <div className="flex-row items-center mt-6 grid grid-cols-12 px-4">
            <input
              id="enableCustomQuestionTwo"
              {...register('enableCustomQuestionTwo')}
              type="checkbox"
              onChange={handleCheckChange}
              checked={formData?.customerFeedbackForm?.enableCustomQuestionTwo || ''}
              className="checkbox col-span-1"
            />

            <span className="text-[16px] font-semibold font-lato col-span-3">
              Custom Question 2
            </span>
            <div className="col-span-8">
              <InputText
                type="text"
                register={register}
                error={errors?.customQuestionTwo}
                elementId="customQuestionTwo"
                placeholder="Custom question 2"
                value={formData?.customerFeedbackForm?.customQuestionTwo || ''}
                onChange={(e: any) => {
                  handleInputChange(e);
                }}
                disabled={!checkboxState?.enableCustomQuestionTwo}
              />
            </div>
          </div>
          <hr className="my-6 border-gainsboroBlue" />

          <div className="flex-row items-center mt-6 grid grid-cols-12 px-4">
            <input
              id="enableCustomQuestionThree"
              {...register('enableCustomQuestionThree')}
              type="checkbox"
              checked={formData?.customerFeedbackForm?.enableCustomQuestionThree}
              className="checkbox col-span-1"
              onChange={handleCheckChange}
            />
            <span className="text-[16px] font-semibold font-lato col-span-3">
              Custom Question 3
            </span>
            <div className="col-span-8">
              <InputText
                type="text"
                register={register}
                error={errors?.customQuestionThree}
                elementId="customQuestionThree"
                placeholder="Custom question 3"
                value={formData?.customerFeedbackForm?.customQuestionThree || ''}
                onChange={(e: any) => {
                  handleInputChange(e);
                }}
                disabled={!checkboxState?.enableCustomQuestionThree}
              />
            </div>
          </div>

          <hr className="my-6 border-gainsboroBlue" />
        </div>

        <div className="w-full pt-5 pb-5 mt-3 bg-culturedGray flex flex-wrap justify-center items-center gap-3">
          <PrimaryButton
            text="Save"
            btnColor="bg-spaceCadetBlue80"
            btnHoverColor="hover:bg-spaceCadetBlue"
            color="white"
            icon={<Save />}
            radius="2xl"
            handleOnClick={handleSubmit(onSubmit)}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

CustomerFeedbackFormModal.propTypes = {
  show: PropTypes.bool,
  toggleModal: PropTypes.func,
  setFormData: PropTypes.func,
  handleUpdate: PropTypes.func,
  formData: PropTypes.object,
};

export default CustomerFeedbackFormModal;
