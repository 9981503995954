/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import ReactDom from 'react-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Drawer } from 'react-daisyui';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { AddCustomer, ChangeContactIcon, Close, ViewContact } from 'components/Icons';
import { PrimaryButton } from 'components';
import { fetchMessageList } from 'services/message.service';
import { apiErrorHandler } from 'api/handler';
import { PATHS } from 'routes/paths';
import { S3_ASSETS } from 'constants/assets';
import { useSocket } from 'contexts/socket';
import { SOCKET_EVENTS } from 'constants/events';
import {
  INewBotMessageSocketEvent,
  INewCustomerMessageSocketEvent,
} from 'interfaces/sockets.interface';
import { RootState } from 'slices';

import {
  fetchConversationsReviewList,
  updateConversationReview,
} from 'services/conversationReview.service';
import { fetchConversationDataById } from 'services/conversation.service';
import { constructDateAndTime } from 'views/Inbox/Chat/utils';
import Conversation from './Conversation';
import RatingCards from './RatingCard';
import QAReviewCard from './QAReview';
import { ModalTypes, PromptTypes } from './utils';
import { TYPE } from './RatingCard/constants';

function ChatPreview(props) {
  const {
    visible,
    toggleHide,
    conversationId,
    setModalOptions,
    setPromptOptions,
    qaReviewList,
    setQAReviewList,
  } = props;
  const { socketConnected } = useSelector((state: RootState) => state.auth);

  const _isMounted = useRef(true);
  const socketClient = useSocket();
  const navigate = useNavigate();

  // data
  const [messageList, setMessageList] = useState([]);
  const [conversationData, setConversationData] = useState<any>();

  const [customLoading, setCustomLoading] = useState({
    loadMessages: false,
    loadReviews: false,
    review: false,
  });

  useEffect(
    () => () => {
      _isMounted.current = false;
    },
    [],
  );

  // Fetch the completed chat drawer data by id
  useEffect(() => {
    if (conversationId) {
      fetchMessageData();
      fetchQAReviewsData();
      fetchConversationPreviewData();
    }
  }, [conversationId]);

  useEffect(() => {
    const sharedObject = {
      node: {
        mount: true,
        conversationId: conversationData?._id || null, // Use null or a default value
      },
    };

    if (socketClient && sharedObject.node.conversationId && socketConnected) {
      socketClient.emit(SOCKET_EVENTS.CLIENT.WATCH_CHAT_PREVIEW, sharedObject);
    }

    return () => {
      // Modify the mount property for unmounting
      sharedObject.node.mount = false;

      if (socketClient && sharedObject.node.conversationId) {
        socketClient.emit(SOCKET_EVENTS.CLIENT.WATCH_CHAT_PREVIEW, sharedObject);
      }
    };
  }, [socketClient, conversationId, socketConnected]);

  useEffect(() => {
    socketClient?.on(SOCKET_EVENTS.SERVER.NEW_BOT_MESSAGE, (res: INewBotMessageSocketEvent) => {
      if (_isMounted.current) {
        const { message } = res.node;
        if (conversationId === message?.conversationId) {
          pushMessageToArray(message);
        }
      }
    });
  }, [socketClient, conversationData]);

  useEffect(() => {
    socketClient?.on(
      SOCKET_EVENTS.SERVER.NEW_CUSTOMER_MESSAGE,
      (res: INewCustomerMessageSocketEvent) => {
        if (_isMounted.current) {
          const { message } = res.node;
          if (conversationId === message?.conversationId) {
            pushMessageToArray(message);
          }
        }
      },
    );
  }, [socketClient, conversationData]);

  function pushMessageToArray(message: any) {
    const formattedMessage = {
      ...message,
      contactName:
        message?.contact?.firstName || message?.contact?.lastName
          ? `${message?.contact?.firstName || ''} ${message?.contact?.lastName || ''}`
          : 'Customer',
      date: moment(message?.createdAt).format('h:mm A'),
    };
    setMessageList((prevState) => [...prevState, formattedMessage]);
  }

  const fetchMessageData = async () => {
    try {
      setCustomLoading((prevState) => ({ ...prevState, loadMessages: true }));
      const { data } = await fetchMessageList(conversationId, { limit: 1000 });
      if (data?.node?.docs.length > 0) {
        const mappedData = data?.node.docs.map((obj: any) => ({
          ...obj,
          contactName:
            obj?.contact?.firstName || obj?.contact?.lastName
              ? `${obj?.contact?.firstName || ''} ${obj?.contact?.lastName || ''}`
              : 'Customer',
          date: constructDateAndTime(obj?.createdAt),
        }));
        setMessageList(mappedData);
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, loadMessages: false }));
    }
  };

  const fetchQAReviewsData = async () => {
    try {
      setCustomLoading((prevState) => ({ ...prevState, loadReviews: true }));
      const { data } = await fetchConversationsReviewList(conversationId, {
        limit: 0,
        ratingFrom: 'qa',
      });
      if (data?.node?.docs.length > 0) {
        const mappedData = data?.node.docs.map((obj: any) => ({
          ...obj,
          userName: obj?.user?.name ? obj?.user?.name : '',
          profilePic: obj?.profilePic?.location
            ? obj?.profilePic?.location
            : S3_ASSETS.CHAT_PREVIEW_PLACEHOLDER.url,
          date: obj?.createdAt,
        }));
        setQAReviewList(mappedData);
      } else {
        setQAReviewList([]);
      }
    } catch (error) {
      toggleHide();
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, loadReviews: false }));
    }
  };

  // Fetch conversation drawer preview data by ID
  const fetchConversationPreviewData = async () => {
    try {
      setCustomLoading((prevState) => ({ ...prevState, loadReviews: true }));
      const { data } = await fetchConversationDataById(conversationId);
      if (data) {
        const { node } = data;
        const mappedData = {
          ...node,
          botName: node.messenger.name,
        } as {
          messenger: {
            name: string;
          };
          [key: string]: any;
        };
        setConversationData(mappedData);
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, loadReviews: false }));
    }
  };

  const handleDeleteQAReview = async (_id) => {
    try {
      const { data } = await updateConversationReview(_id, { deleted: true });
      if (data?.node) {
        toast.success('Successfully Deleted the Review');
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setPromptOptions((prev) => ({ ...prev, show: false }));
    }
  };

  const triggerQAReviewModal = (event, data) => {
    setModalOptions((prevState) => ({
      ...prevState,
      show: true,
      actionStatus: false,
      type: ModalTypes.QAReviewModal,
      data: {
        conversationId,
        sequenceId: conversationData?.sequenceId,
        ...data,
      },
    }));
    toggleHide();
  };

  const triggerContactModal = (event, data) => {
    setModalOptions((prevState) => ({
      ...prevState,
      show: true,
      actionStatus: false,
      type: ModalTypes.ContactModal,
      data: {
        conversationId,
        sequenceId: conversationData?.sequenceId,
        ...data,
      },
    }));
    toggleHide();
  };

  const handleOnToggleDeleteReview = (event, data: any) => {
    setPromptOptions({
      show: true,
      title: 'Are You Sure?',
      message: 'Do You Want to Delete this Review?',
      onConfirm: () => handleDeleteQAReview(data?._id),
      type: PromptTypes.deleteReview,
    });
    toggleHide();
  };

  return ReactDom.createPortal(
    <Drawer
      end
      open={visible}
      onClickOverlay={toggleHide}
      side={
        <div className="bg-[#F9F9F9] min-h-screen w-auto justify-center chat_preview max-h-full">
          <div className="w-full p-[15px] bg-primary preview__header">
            <div className="flex flex-wrap justify-between items-center">
              <div className="flex justify-center items-center">
                <span className="pt-1 pb-2 font-ptScanCap text-lg font-bold text-white">
                  Chat Preview {conversationData?.sequenceId}
                </span>
                <div className="badge mb-2 bg-northTexasGreen xs:w-auto text-white font-medium p-3 text-center items-center text-sm border-none ml-2">
                  COMPLETED
                </div>
              </div>
              <div className="cursor-pointer">
                <Close width={26} height={26} onClick={toggleHide} />
              </div>
            </div>
            <span className="font-ptScanCap text-xs font-light text-white">
              Started At:{' '}
              {moment(conversationData?.createdAt, 'YYYY-MM-DD HH:mm:ss').format(
                'YYYY-MM-DD - hh:mm:ss A',
              )}{' '}
              | Completed At:{' '}
              {moment(conversationData?.completed?.at, 'YYYY-MM-DD HH:mm:ss').format(
                'YYYY-MM-DD - hh:mm:ss A',
              )}
            </span>
            <div>
              {conversationData?.channelName ? (
                <span className="mt-1 font-ptScanCap text-base font-bold text-white">
                  Channel : {conversationData?.channelName} | Bot : {conversationData?.botName}
                </span>
              ) : (
                <span className="mt-1 font-ptScanCap text-base font-bold text-white">
                  Bot : {conversationData?.botName}
                </span>
              )}
            </div>
          </div>
          <div className="preview__body">
            <div className="flex w-auto bg-white justify-between items-center preview_contact__header">
              <div className="contact_class flex items-center pl-[20px] py-[15px]">
                <div className="avatar">
                  <div className="w-12 h-12 rounded-full ring-offset-base-100">
                    <img
                      src={
                        conversationData?.contact?.profilePicture?.location ||
                        S3_ASSETS.CHAT_PREVIEW_PLACEHOLDER.url
                      }
                      alt="avatar"
                    />
                  </div>
                </div>
                <div className="pl-[21px] justify-center items-center">
                  {conversationData?.contact ? (
                    <div>
                      <span className="font-ptScanCap text-rasinBlack font-bold text-sm">
                        {`${conversationData?.contact?.firstName || ''} ${
                          conversationData?.contact?.lastName || ''
                        }`}
                      </span>
                    </div>
                  ) : (
                    <div>
                      <span className="font-ptScanCap text-rasinBlack font-bold text-sm">
                        Unsaved Contact
                      </span>
                    </div>
                  )}
                  <div className="-mt-1">
                    <span className="font-ptScanCap text-quickSilver font-bold text-sm">
                      {conversationData?.contact?.primaryContactNo}
                    </span>
                  </div>
                  <div className="-mt-1">
                    <span className="font-ptScanCap text-quickSilver font-light text-xs">
                      {conversationData?.contact?.email}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex items-center p-3">
                {conversationData?.contact && (
                  <PrimaryButton
                    text="View Contact"
                    btnColor="bg-spaceCadetBlue80"
                    btnHoverColor="hover:bg-spaceCadetBlue"
                    color="white"
                    icon={<ViewContact />}
                    width="90px"
                    textSize="xs font-bold font-ptScanCap"
                    radius="2xl"
                    handleOnClick={() =>
                      navigate(`${PATHS.CONTACT.VIEW.url}?i=${conversationData?.contact?._id}`)
                    }
                  />
                )}
                {conversationData?.contact && (
                  <div className="ml-2">
                    <PrimaryButton
                      text="Change Contact"
                      btnColor="bg-spaceCadetBlue80"
                      btnHoverColor="hover:bg-spaceCadetBlue"
                      color="white"
                      icon={<ChangeContactIcon />}
                      width="90px"
                      textSize="xs font-bold font-ptScanCap"
                      radius="2xl"
                      handleOnClick={(event) => triggerContactModal(event, { isEdit: true })}
                    />
                  </div>
                )}
                {!conversationData?.contact && (
                  <PrimaryButton
                    text="Assign Contact"
                    btnColor="bg-spaceCadetBlue80"
                    btnHoverColor="hover:bg-spaceCadetBlue"
                    color="white"
                    icon={<AddCustomer />}
                    width="90px"
                    textSize="xs font-bold font-ptScanCap"
                    radius="2xl"
                    handleOnClick={(event) => triggerContactModal(event, { isEdit: false })}
                  />
                )}
              </div>
            </div>
            <div className="h-[calc(100vh-200px)] preview__message_body overflow-y-auto table-layout_custom-scrollbar ">
              <div className="col-span-1 bg-[#F9F9F9] md:col-span-1 px-4">
                <div className="bg-white rounded-[10px] shadow-md">
                  {/* Messages component */}
                  <Conversation
                    chatList={messageList}
                    messagesLoading={customLoading.loadMessages}
                  />
                </div>
              </div>
              <div className="col-span-1 bg-[#F9F9F9] md:col-span-1 px-4 pb-5">
                {(conversationData?.aiRatingScore || conversationData?.aiRatingScore === 0) && (
                  <RatingCards
                    title="AI Based Rating"
                    rating={conversationData?.aiRatingScore}
                    feedback={conversationData?.aiRatingReason}
                    type={TYPE.AI_RATING}
                  />
                )}
                {(conversationData?.customerRatingScore ||
                  conversationData?.customerRatingScore === 0) && (
                  <RatingCards
                    title="Customer Rating"
                    rating={conversationData?.customerRatingScore}
                    feedback={conversationData?.customerRatingReason}
                    type={TYPE.CUSTOMER_RATING}
                  />
                )}
                <QAReviewCard
                  qaReviewList={qaReviewList}
                  triggerQAReviewModal={triggerQAReviewModal}
                  handleOnToggleDeleteReview={handleOnToggleDeleteReview}
                  completedAt={conversationData?.completedAt}
                />
              </div>
            </div>
          </div>
        </div>
      }
    />,
    document.body,
  );
}

ChatPreview.propTypes = {
  visible: PropTypes.bool,
  toggleShow: PropTypes.func,
  toggleHide: PropTypes.func,
  handleTakeChat: PropTypes.func,
  conversationId: PropTypes.string,
  setModalOptions: PropTypes.func,
  setPromptOptions: PropTypes.func,
  qaReviewList: PropTypes.array,
  setQAReviewList: PropTypes.func,
};

export default ChatPreview;
