export const modalDefaultOptions = {
  show: false,
  actionStatus: false,
  type: null,
  data: {},
};

export enum ModalTypes {
  QAReviewModal = 'QAReviewModal',
  ContactModal = 'ContactModal',
}

export const promptDefaultOptions = {
  show: false,
  title: '',
  message: '',
  onConfirm: null,
  type: null,
};

export enum PromptTypes {
  deleteReview = 'deleteReview',
}

export function isDateMoreThan30DaysAgo(givenDate: Date) {
  // Calculate the date that is 30 days ago
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

  // Compare the given date with the calculated date
  return givenDate < thirtyDaysAgo;
}
