import * as React from 'react';
import PropTypes from 'prop-types';

function DrawerIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color = 'white', width = '32', height = '32' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3471_3194)">
        <path
          d="M20 20.5L16 16.5L20 12.5"
          stroke={color}
          strokeWidth="2.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.6666 20.5L10.6666 16.5L14.6666 12.5"
          stroke={color}
          strokeWidth="2.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28 16.5C27.9534 13.333 26.6566 10.3129 24.3924 8.09818C22.1282 5.88343 19.0802 4.65365 15.913 4.67696C12.7458 4.70028 9.71624 5.9748 7.48489 8.22264C5.25353 10.4705 4.00134 13.5093 4.00134 16.6766C4.00134 19.8439 5.25353 22.8828 7.48489 25.1306C9.71624 27.3785 12.7458 28.653 15.913 28.6763C19.0802 28.6996 22.1282 27.4698 24.3924 25.2551C26.6566 23.0403 27.9534 20.0203 28 16.8533V16.5Z"
          stroke={color}
          strokeWidth="2.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3471_3194">
          <rect width="32" height="32" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
DrawerIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default DrawerIcon;
