import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-daisyui';

import { InputText, PrimaryButton } from 'components';
import { Confirm } from 'components/Icons';

/**
 * This model use to add and Edit Intent
 * @param props
 * @returns {JSX.Element} - A JSX element representing the `Modal` component.
 */
function IntentModal(props) {
  const {
    show,
    toggleModal,
    editMode = false,
    setFormData,
    formData,
    handleSubmit,
    loading = false,
  } = props;

  // // DropDown options for intent
  // const incomingMessagesOptions = [
  //   { label: 'Match with the entire message', value: 'Match with the entire message' },
  // ];

  const [submitted, setSubmitted] = useState(false);

  // handle the input text change
  const handleInputChange = (event: { target: { id: any; value: any } }) => {
    const { id, value = '' } = event.target;

    if (id === 'displayIntentName') {
      setFormData({
        ...formData,
        [id]: value,
        intentName: value.replace(/\s+/g, ''),
      });
      return;
    }

    setFormData({
      ...formData,
      [id]: value,
    });
  };

  // // handle the dropdown change
  // const handleSelectChange = (event: { value: any; other: any }, id: string) => {
  //   setFormData({
  //     ...formData,
  //     [id]: event?.value || null,
  //   });
  //   setSubmitted(false);
  // };

  const onSubmit = () => {
    setSubmitted(true);

    if (formData.intentName && formData.intentName !== '') {
      handleSubmit();
      setSubmitted(false);
    }
  };

  return (
    <Modal
      open={show}
      className="top-0 left-0 right-0 p-0 border border-gainsboroBlue rounded-lg z-50 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(auto-1rem)] max-w-2xl max-h-full"
    >
      <Modal.Header className="font-bold pb-2 border-b p-4">
        <div className="flex justify-between items-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 w-fit flex-wrap items-center">
            <div className="text-base font-bold font-ptScanCap whitespace-nowrap">
              {editMode ? 'Edit Intent' : 'Add Intent'}
            </div>
          </div>
          <div className="text-sm font-normal bg-red">
            <Button
              size="xs"
              shape="circle"
              className="btn btn-xs btn-circle border-2 bg-white border-rasinBlack font-bold"
              onClick={toggleModal}
            >
              x
            </Button>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="px-2 justify-center items-center pb-3 -mt-6">
          <div className="px-4 mt-2 flex flex-col gap-2">
            <InputText
              type="text"
              elementId="displayIntentName"
              label="Intent Name"
              rows={3}
              value={formData?.displayIntentName || ''}
              onChange={(event) => handleInputChange(event)}
              disabled={loading}
            />
            {submitted && !formData?.displayIntentName && (
              <label className="label relative p-y-2">
                <span className="label-text-alt absolute text-error">Intent name is required</span>
              </label>
            )}
          </div>
          {/* <div className="px-4 mt-3 flex flex-col gap-2">
            <span className="text-base font-normal ml-1 font-lato">
              Matching method with incoming messages
            </span>
            <CustomSelect
              id="matchingMethodWithIncomingMessages"
              required
              isClearable
              placeholder=""
              options={incomingMessagesOptions}
              value={getValueForSelect(
                formData?.matchingMethodWithIncomingMessages,
                incomingMessagesOptions,
              )}
              onChange={(event) => handleSelectChange(event, 'matchingMethodWithIncomingMessages')}
            />
          </div>
          <div className="px-4 mt-2 flex flex-col gap-4">
            <InputText
              type="text"
              elementId="throttlingTimeOutInMinutes "
              label="Throttling Time Out in Minutes "
              rows={3}
              value={formData?.throttlingTimeOutInMinutes || ''}
            />
          </div> */}
        </div>
        <div className="w-full pt-5 pb-5 mt-3 bg-culturedGray flex flex-wrap justify-center items-center gap-3">
          <PrimaryButton
            text={`${editMode ? 'Update Intent' : 'Add Intent'}`}
            btnColor="bg-spaceCadetBlue80"
            btnHoverColor="hover:bg-spaceCadetBlue"
            color="white"
            icon={<Confirm />}
            radius="2xl"
            type="button"
            handleOnClick={onSubmit}
            disabled={loading}
            loading={loading}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

IntentModal.propTypes = {
  show: PropTypes.bool,
  editMode: PropTypes.bool,
  loading: PropTypes.bool,
  toggleModal: PropTypes.func,
  setFormData: PropTypes.func,
  handleSubmit: PropTypes.func,
  formData: PropTypes.object,
};

export default IntentModal;
