import * as React from 'react';
import PropTypes from 'prop-types';

function AddMessageIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color = '#009E4C', width = 45, height = 45 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 45 32"
    >
      <mask id="path-1-inside-1_7395_9887" fill="#fff">
        <path
          fillRule="evenodd"
          d="M16 4C9.925 4 5 8.925 5 15c0 4.14 2.287 7.746 5.666 9.623v3.283c0 .87.917 1.433 1.693 1.041L18.194 26H28c6.075 0 11-4.925 11-11S34.075 4 28 4H16z"
          clipRule="evenodd"
        />
      </mask>
      <path
        fill={color}
        d="M10.666 24.623h2v-1.177l-1.028-.572-.972 1.749zm1.693 4.324l-.902-1.785.902 1.785zM18.194 26v-2h-.476l-.425.215.901 1.785zM7 15a9 9 0 019-9V2C8.82 2 3 7.82 3 15h4zm4.638 7.874A8.997 8.997 0 017 15H3c0 4.895 2.707 9.156 6.695 11.371l1.943-3.497zm1.028 5.032v-3.283h-4v3.283h4zm-1.209-.744a.833.833 0 011.21.744h-4c0 2.36 2.488 3.89 4.594 2.826l-1.803-3.57zm5.836-2.947l-5.835 2.947 1.803 3.57 5.835-2.947-1.803-3.57zM28 24h-9.806v4H28v-4zm9-9a9 9 0 01-9 9v4c7.18 0 13-5.82 13-13h-4zm-9-9a9 9 0 019 9h4c0-7.18-5.82-13-13-13v4zM16 6h12V2H16v4z"
        mask="url(#path-1-inside-1_7395_9887)"
      />
      <circle cx="16" cy="15" r="2" fill={color} />
      <circle cx="22" cy="15" r="2" fill={color} />
      <circle cx="28" cy="15" r="2" fill={color} />
    </svg>
  );
}
AddMessageIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default AddMessageIcon;
