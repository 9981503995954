import { isValidPhoneNumber } from 'react-phone-number-input';

export const isEmailValid = (email) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

export const isNumberValid = (value) =>
  (value !== null || value !== undefined || value !== '') && Number.isFinite(Number(value));

export const isDatetimeValid = (value) => {
  const datetimePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/; // Example: YYYY-MM-DDTHH:MM
  return datetimePattern.test(value);
};

export const isDateValid = (value) => {
  const datePattern = /^\d{4}-\d{2}-\d{2}$/; // Example: YYYY-MM-DD
  return datePattern.test(value);
};

export const isTimeValid = (value) => {
  const timePattern = /^\d{2}:\d{2}$/; // Example: HH:MM
  return timePattern.test(value);
};

// validation using react-phone-number-input library
export const isPhoneNumberValid = (value) => isValidPhoneNumber(value);

export const isUrlValid = (url) => {
  const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
  return urlPattern.test(url);
};

export const isNotEmpty = (value) => value.trim() !== '';
