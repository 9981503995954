/**
 * Defines the paths used in the application.
 */
export const PATHS = {
  AUTH: {
    LOGIN: {
      url: '/login',
      key: 'login-pg',
    },
    SIGN_UP: {
      url: '/sign-up',
      key: 'sign-up-pg',
    },
    SETUP_PASSWORD: {
      url: '/setup-password',
      key: 'setup-password-pg',
    },
    RESET_PASSWORD: {
      url: '/reset-password',
      key: 'reset-password-pg',
    },
    TEMP_PASSWORD_SETUP: {
      url: '/temp-password-setup',
      key: 'temp-password-setup-pg',
    },
  },
  CLIENT_MANAGEMENT: {
    LIST: {
      url: '/clients/account/list',
      key: 'client-account-list-pg',
    },
    FORM: {
      url: '/clients/account/form',
      key: 'client-account-form-pg',
    },
  },
  ACCOUNT_MANAGEMENT: {
    PROFILE: {
      url: '/account/profile',
      key: 'account-profile-pg',
    },
  },
  USER_MANAGEMENT: {
    LIST: {
      url: '/users/account/list',
      key: 'users-account-list-pg',
    },
    FORM: {
      url: '/users/account/form',
      key: 'users-account-form-pg',
    },
    TEST: {
      url: '/users/account/test',
    },
    ROLE: {
      LIST: {
        url: '/users/account/roles',
        key: 'users-account-roles-pg',
      },
      FORM: {
        url: '/users/account/roles/form',
        key: 'users-account-roles-form-pg',
      },
    },
    CHANNEL: {
      LIST: {
        url: '/users/account/channel',
        key: 'users-account-channel-pg',
      },
      FORM: {
        url: '/users/account/channel/form',
        key: 'users-account-channel-form-pg',
      },
    },
  },
  CONTACT: {
    LIST: {
      url: '/contacts/list',
      key: 'contacts-list-pg',
    },
    FORM: {
      url: '/contacts/form',
      key: 'contacts-view-pg',
    },
    VIEW: {
      url: '/contacts/view',
      key: 'contacts-view-pg',
    },
  },
  CASES_MANAGEMENT: {
    LIST: {
      url: '/cases/list',
      key: 'cases-list-pg',
    },
    FORM: {
      url: '/cases/form',
      key: 'cases-form-pg',
    },
    VIEW: {
      url: '/cases/chat-view',
      key: 'cases-chat-view-pg',
    },
  },
  INBOX: {
    DEFAULT: {
      url: '/inbox',
      url2: '/inbox/:id',
      key: 'inbox-pg',
    },
    BOT_CHAT: {
      LIST: {
        url: '/inbox/bot-chat',
        key: 'inbox-bot-chat-pg',
      },
    },
    COMPLETED_CHAT: {
      LIST: {
        url: '/inbox/completed-chats',
        key: 'inbox-completed-chat-pg',
      },
    },
  },
  MESSENGERS: {
    LIST: {
      url: '/messenger/instances/list',
      key: 'messenger-instances-list-pg',
    },
    FORM: {
      url: '/messenger/instances/form',
      key: 'messenger-instances-form-pg',
    },
    INTENTS: {
      url: '/messenger/intents',
      key: 'messenger-intent-pg',
    },
    FLOW_BUILDER: {
      url: '/messenger/flow-builder',
      key: ' messenger-flow-builder',
    },
  },
};
