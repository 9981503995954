import * as React from 'react';
import PropTypes from 'prop-types';

function CloseIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color = '#D3D3D3', width = 38, height = 22, onClick } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className="inline-block w-4 h-4 stroke-current"
      onClick={onClick}
      style={{ pointerEvents: 'auto' }}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 18L18 6M6 6l12 12"
        style={{ stroke: color }}
      />
    </svg>
  );
}
CloseIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  onClick: PropTypes.func,
};

export default CloseIcon;
