import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { getDefaultValueForSelect, getDefaultValueForVariableSelect } from 'utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { IFlowPayload, updateCaseSettings } from 'slices/flow';
import { RootState } from 'slices';
import AddCaseForm from './form';
import { ICaseForm } from './interfaces';

interface IAddCaseDrawerProps {
  handleCaseDrawerSubmit: (formData: ICaseForm, nodeId: string) => void;
  nodeId: string;
}
/**
 * This is Add case drawer component for flow builder
 */
const AddCaseDrawer = forwardRef(({ handleCaseDrawerSubmit, nodeId }: IAddCaseDrawerProps, ref) => {
  const dispatch = useDispatch();
  const flowData: IFlowPayload = useSelector((state: RootState) => state.flow);

  const [onInit, setOnInit] = useState(false);
  const [formData, setFormData] = useState<ICaseForm>();

  useEffect(() => {
    const caseObj = flowData.nodeConfigs.find((node) => node.nodeId === nodeId);
    if (caseObj) {
      setFormData(caseObj?.caseSettings?.data);
    }
  }, []);

  useEffect(() => {
    if (onInit) {
      dispatch(
        updateCaseSettings({
          data: formData,
          nodeId,
        }),
      );
    }
    setOnInit(true);
  }, [formData]);

  // selected value based on a specified value type
  const useSelectedValueType = (valueType: any, array: any[], id: string) =>
    useMemo(() => getDefaultValueForSelect(valueType, array, id), [valueType]);

  // selected value based grouped array options
  const useSelectedVariableValue = (valueType: string, array: any[], id: string) =>
    useMemo(() => getDefaultValueForVariableSelect(valueType, array, id), [valueType]);

  // handle the checkbox
  const handleCheckChange = (event, section: string) => {
    const { checked } = event.target;
    if (!checked) {
      // If checked is false, clear values for the relevant section
      setFormData({
        ...formData,
        [section]: {
          ...formData?.[section],
          value: null,
          valueType: null,
          isFieldEnabled: checked,
        },
      });
    } else {
      // If checked is true, update isFieldEnabled only
      setFormData({
        ...formData,
        [section]: {
          ...formData?.[section],
          isFieldEnabled: checked,
        },
      });
    }
  };

  // handle select field change
  const handleSelectChange = (event, section: string, id: string) => {
    setFormData({
      ...formData,
      [section]: {
        ...formData?.[section],
        valueType: event?.value,
        value: null || '',
      },
    });
  };

  const handleSelectVariableChange = (event, section: string, id: string) => {
    setFormData({
      ...formData,
      [section]: {
        ...formData?.[section],
        value: event?.value,
      },
    });
  };

  // handle the input text change
  const handleInputChange = (event: { target: { id: any; value: any } }, section: string) => {
    const { id, value } = event.target;
    setFormData({
      ...formData,
      [section]: {
        ...formData?.[section],
        value,
      },
    });
  };

  const handleSubmit = () => {
    handleCaseDrawerSubmit(formData, nodeId);
  };

  // Expose the handleSubmit function to the parent component using useImperativeHandle
  useImperativeHandle(ref, () => ({
    handleSubmit,
  }));

  return (
    <div className="add-case__container max-w-[500px]">
      <AddCaseForm
        formData={formData}
        useSelectedValueType={useSelectedValueType}
        useSelectedVariableValue={useSelectedVariableValue}
        handleCheckChange={handleCheckChange}
        handleSelectChange={handleSelectChange}
        handleInputChange={handleInputChange}
        handleSelectVariableChange={handleSelectVariableChange}
      />
    </div>
  );
});

export default AddCaseDrawer;
