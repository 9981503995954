import React from 'react';
import PropTypes from 'prop-types';
import { Delete } from 'components/Icons';

function DeleteBtn(props) {
  const { handleOnClick } = props;

  return (
    <button className="drawer-delete__btn" type="button" onClick={handleOnClick}>
      <Delete color="#E73147" />
    </button>
  );
}

DeleteBtn.propTypes = {
  handleOnClick: PropTypes.func,
};

export default DeleteBtn;
