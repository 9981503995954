/* eslint-disable prettier/prettier */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import GeneralButton from 'components/Buttons/GeneralButton';
import { EmojiIcon } from 'components/Icons';
import { uniqueId } from 'lodash';
import EmojiPicker from 'emoji-picker-react';
import { IMessageSetting } from '../interfaces';

interface IAddButtonPopUp {
  setPopupVisible: React.Dispatch<React.SetStateAction<boolean>>;
  editMode: boolean;
  componentData: any;
  setSettingsArr: React.Dispatch<React.SetStateAction<IMessageSetting[]>>;
  setEditPopupVisible: React.Dispatch<React.SetStateAction<boolean>>;
  buttonId: string;
  buttonText: string;
  buttonUrl: string;
  type: string;
}

function AddButtonPopUp(props: Partial<IAddButtonPopUp>) {
  const {
    setPopupVisible,
    editMode = false,
    componentData,
    setSettingsArr,
    setEditPopupVisible,
    buttonId,
    buttonText,
    buttonUrl,
    type,
  } = props;
  const inputRef = useRef(null);
  const emojiPickerRef = useRef(null);
  const buttonRef = useRef(null);
  const inputUrlRef = useRef(null);

  const [popupText, setPopupText] = useState('');
  const [selectedOption, setSelectedOption] = useState('continue');
  const [urlInputVisible, setUrlInputVisible] = useState(false);
  const [urlInputValue, setUrlInputValue] = useState('');

  const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState(false);

  useEffect(() => {
    // Focus on the input field when the component mounts
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Focus on the input field when the component mounts
    if (inputUrlRef.current && selectedOption === 'url') {
      inputUrlRef.current.focus();
    }
  }, [selectedOption]);

  useEffect(() => {
    if (editMode) {
      if (type) setSelectedOption(type);
      if (buttonText) setPopupText(buttonText);
      if (buttonUrl) {
        setUrlInputVisible(true);
        setUrlInputValue(buttonUrl);
      }
    }
  }, [editMode]);

  const handleClickOutside = (event) => {
    // Check if the clicked element is outside the emoji button and picker
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target) &&
      !buttonRef.current.contains(event.target)
    ) {
      // Close the emoji picker
      setIsEmojiPickerVisible(false);
    }
  };

  /**
   * Handle emoji selection and insert it into the input
   * Get the current value of the input
   * Add the selected emoji to the input value
   */
  const handleEmojiSelection = (emoji) => {
    const currentInputValue = popupText || '';
    const updatedInputValue = currentInputValue + emoji.emoji;
    setPopupText(updatedInputValue);

    // Close the emoji picker
    setIsEmojiPickerVisible(false);
  };

  const handleOptionChange = (event) => {
    const { value } = event.target;
    setSelectedOption(value);
    if (value === 'continue') {
      setUrlInputValue('');
    }

    // Show/hide the URL input box based on the selected option
    setUrlInputVisible(value === 'url');
  };

  const handlePopupSubmit = () => {
    if (!popupText) {
      return;
    }
    if (editMode) {
      editButton(componentData.id);
    } else {
      addNewButton(componentData.id);
    }
    setPopupText('');
    setUrlInputValue('');
    setUrlInputVisible(false);
    setPopupVisible(false);
    setEditPopupVisible(false);
  };

  const addNewButton = (parentId) => {
    setSettingsArr((prev) => {
      // Find the parent object by its id
      const parentObjectIndex = prev.findIndex((item) => item.id === parentId);
      const parentObject = prev.find((item) => item.id === parentId);

      // If the parent object is found
      if (parentObjectIndex !== -1) {
        // Clone the array to avoid mutating the original state
        const updatedArray = [...prev];

        const newButton = {
          id: uniqueId(),
          position: parentObject?.buttons.length || 1,
          buttonText: popupText,
          buttonUrl: urlInputValue,
        };

        // Clone the parent object and update the buttons array with the new button
        updatedArray[parentObjectIndex] = {
          ...updatedArray[parentObjectIndex],
          buttons: [...updatedArray[parentObjectIndex].buttons, newButton],
        };

        return updatedArray;
      }

      // If the parent object is not found, return the original array
      return prev;
    });
  };

  const editButton = (parentId) => {
    setSettingsArr((prev) => {
      // Find the parent object by its id
      const parentObjectIndex = prev.findIndex((item) => item.id === parentId);
      const parentObject = prev.find((item) => item.id === parentId);

      // If the parent object is found
      if (parentObjectIndex !== -1) {
        // Clone the array to avoid mutating the original state
        const updatedArray = [...prev];

        // Find the index of the button in the parentObject.buttons array
        const buttonIndex = parentObject.buttons.findIndex((item) => item.id === buttonId);

        // If the button is found
        if (buttonIndex !== -1) {
          // Clone the parentObject.buttons array to avoid mutating the original state
          const updatedButtonsArray = [...parentObject.buttons];

          // For example, let's say you want to update the label of the button
          updatedButtonsArray[buttonIndex] = {
            ...updatedButtonsArray[buttonIndex],
            buttonText: popupText,
            buttonUrl: urlInputValue,
            type: selectedOption,
          };

          // Update the parentObject with the updated buttons array
          updatedArray[parentObjectIndex] = {
            ...parentObject,
            buttons: updatedButtonsArray,
          };
        }

        return updatedArray;
      }

      // If the parent object is not found, return the original array
      return prev;
    });
  };

  const handleDelete = (e) => {
    e.preventDefault();
    setSettingsArr((prev) => {
      // Find the parent object by its id
      const parentObjectIndex = prev.findIndex((item) => item.id === componentData.id);
      const parentObject = prev.find((item) => item.id === componentData.id);

      // If the parent object is found
      if (parentObjectIndex !== -1) {
        // Clone the array to avoid mutating the original state
        const updatedArray = [...prev];

        // Find the index of the button in the parentObject.buttons array
        const buttonIndex = parentObject.buttons.findIndex((item) => item.id === buttonId);

        // If the button is found
        if (buttonIndex !== -1) {
          // Clone the parentObject.buttons array to avoid mutating the original state
          const updatedButtonsArray = [...parentObject.buttons];

          // Remove the button from the updatedButtonsArray
          updatedButtonsArray.splice(buttonIndex, 1);

          // Update the parentObject with the updated buttons array
          updatedArray[parentObjectIndex] = {
            ...parentObject,
            buttons: updatedButtonsArray,
          };
        }

        return updatedArray;
      }

      // If the parent object is not found, return the original array
      return prev;
    });
    setUrlInputVisible(false);
    setEditPopupVisible(false);
  };

  return (
    <div className="absolute top-0 left-0 w-full h-auto flex z-20">
      <div className="bg-white p-5 rounded-md shadow-md w-full h-full">
        <div className="relative">
          <input
            type="text"
            ref={inputRef}
            id="popupText"
            className="w-full rounded-md h-10 border-[2px] focus:outline-none focus:ring-[1px] focus:ring-green-300 pl-4 mb-2"
            value={popupText}
            placeholder="Button Text"
            onChange={(e) => setPopupText(e.target.value)}
          />
          <button
            type="button"
            ref={buttonRef}
            onClick={() => setIsEmojiPickerVisible(!isEmojiPickerVisible)}
            className="absolute top-[10px] right-3 z-[99] cursor-pointer"
          >
            <EmojiIcon />
          </button>
          <div
            style={{ position: 'absolute', top: 0, right: 0, maxHeight: '150px', zIndex: '99' }}
            ref={emojiPickerRef}
          >
            {isEmojiPickerVisible && <EmojiPicker onEmojiClick={handleEmojiSelection} />}
          </div>
        </div>

        <div className="flex items-center mb-5">
          <label className="mr-4">
            <input
              type="radio"
              name="flowType"
              value="continue"
              checked={selectedOption === 'continue'}
              onChange={handleOptionChange}
              disabled={editMode}
            />
            <span className="ml-2">Continue flow</span>
          </label>
          <label>
            <input
              type="radio"
              name="flowType"
              value="url"
              checked={selectedOption === 'url'}
              onChange={handleOptionChange}
              disabled={editMode}
            />
            <span className="ml-2">URL</span>
          </label>
        </div>

        {urlInputVisible && (
          <div className="mb-5 flex items-center">
            <select
              id="urlSelect"
              className="w-1/4 rounded-md h-10 border-[1px] focus:outline-none focus:ring-[1px] focus:ring-green-300 pl-4"
              value="URL"
              disabled
              onChange={(e) => {
                // Handle select change logic if needed
              }}
            >
              <option value="URL">URL</option>
            </select>
            <input
              type="text"
              ref={inputUrlRef}
              id="urlInput"
              className="w-3/4 rounded-md h-10 border-[1px] focus:outline-none focus:ring-[1px] focus:ring-green-300 pl-4"
              placeholder="https://example.com"
              value={urlInputValue}
              onChange={(e) => setUrlInputValue(e.target.value)}
            />
          </div>
        )}
        <div className="flex justify-between ">
          <div className="gap-2 space-x-1">
            <GeneralButton
              text={editMode ? 'Save' : 'Add'}
              btnColor="bg-spaceCadetBlue80"
              color="white"
              radius="2xl"
              textSize="xs"
              btnHoverColor="hover:bg-spaceCadetBlue"
              handleOnClick={handlePopupSubmit}
            />
            {editMode && (
              <GeneralButton
                text="Delete"
                btnColor="bg-begoniaRed"
                color="white"
                radius="2xl"
                textSize="xs"
                width="w-auto"
                btnHoverColor="hover:bg-begoniaRed"
                handleOnClick={(e) => {
                  handleDelete(e);
                }}
              />
            )}
          </div>
          <button
            type="button"
            className="rounded-md underline decoration-dotted bg-none drop-shadow-xl mt-2 text-blue-500 hover:text-spaceCadetBlue80"
            onClick={(e) => {
              setPopupVisible(false);
              setEditPopupVisible(false);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

AddButtonPopUp.propTypes = {
  setPopupVisible: PropTypes.func,
  componentData: PropTypes.object,
  setSettingsArr: PropTypes.array,
  editMode: PropTypes.bool,
  setEditPopupVisible: PropTypes.func,
  buttonId: PropTypes.string,
  buttonText: PropTypes.string,
  buttonUrl: PropTypes.string,
  type: PropTypes.string,
};

export default AddButtonPopUp;
