import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { motion } from 'framer-motion';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { RootState } from 'slices';
import { AddIcon, ChevronLeft, ChevronRight } from 'components/Icons';
import { PrimaryButton } from 'components';
import { ICarouselSetting, ISlide } from '../MessageDrawer/interfaces';
import CarouselSubTitleInputField from './components/CarouselSubTitleInputField';
import CarouselImageAttachment from './components/CarouselImageAttachment';
import SlideTitle from './components/SlideTitle';
import { newSlide } from './constants';

interface ICarouselDrawerProps {
  nodeId: string;
  handleCarouselDrawerSubmit: (
    nodeId: string,
    carouselSettings: ICarouselSetting,
    hideDrawer: boolean,
  ) => void;
}

const CarouselDrawer = forwardRef(
  ({ nodeId, handleCarouselDrawerSubmit }: ICarouselDrawerProps, ref) => {
    const flowData = useSelector((state: RootState) => state.flow);

    const [carouselSettings, setCarouselSettings] = useState<ICarouselSetting>();
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isTyping, setIsTyping] = useState(false);

    useEffect(() => {
      const carouselFlowObj = flowData.nodeConfigs.find((obj) => obj.nodeId === nodeId);

      if (carouselFlowObj) {
        setCarouselSettings(carouselFlowObj?.carouselSettings);
      }
    }, []);

    const handleFocus = () => {
      setIsTyping(true);
    };

    const handleBlur = () => {
      setIsTyping(false);
    };

    const getBorderColor = () => {
      if (!isTyping && carouselSettings?.isEnabled && carouselSettings?.text === '') {
        return 'border-red-300'; // Red border for empty text area
      }
      if (isTyping) {
        return 'border-green-300'; // Green border when typing
      }
      return 'border-gray-300'; // Gray border for non-empty and not typing
    };

    /**
     * Handle Title change
     */
    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newText = event.target.value;

        setCarouselSettings((prev) => ({ ...prev, text: newText }));
      },
      [carouselSettings?.isEnabled, carouselSettings?.text, setCarouselSettings, currentSlide],
    );

    /**
     * Handle Title Enable change
     */
    const handleEnableTitleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const isEnabled = event.target.checked;

        setCarouselSettings((prev) => ({ ...prev, isEnabled }));
      },
      [carouselSettings?.isEnabled, carouselSettings?.text, setCarouselSettings, currentSlide],
    );

    /**
     * Handles the carousel drawer submission
     * @param {boolean} hideDrawer - Hide drawer
     */
    const handleSubmit = (hideDrawer = true) => {
      handleCarouselDrawerSubmit(nodeId, carouselSettings, hideDrawer);
    };

    // Expose the handleSubmit function to the parent component using useImperativeHandle
    useImperativeHandle(ref, () => ({
      handleSubmit,
    }));

    /**
     * Handle slide change
     * @param {'next' | 'previous'} direction - Go to previous slide or next slide
     */
    const goToSlide = (direction: 'next' | 'previous') => {
      setCurrentSlide((prev) => {
        if (direction === 'next') {
          return prev + 1;
        }
        return prev - 1;
      });
    };

    /**
     * Handle add slide
     */
    const handleAddSlide = () => {
      setCarouselSettings((prev) => {
        const updatedSlides = prev?.slides || [];
        return {
          ...prev,
          slides: [...updatedSlides, newSlide],
        };
      });
      // increment slide
      setCurrentSlide((prev) => prev + 1);
      // save current data
      handleSubmit(false);
    };

    function buildComponents() {
      const slide = carouselSettings?.slides[currentSlide];
      if (slide) {
        const sortedComponents = [...slide].sort((a, b) => a.position - b.position);
        const renderedComponents = sortedComponents?.map((component: ISlide) => {
          switch (component.name) {
            case 'title':
              return (
                <React.Fragment key={component.id}>
                  <SlideTitle
                    slideNumber={currentSlide}
                    componentData={component}
                    setCarouselSettings={setCarouselSettings}
                  />
                </React.Fragment>
              );
            case 'subTitle':
              return (
                <React.Fragment key={component.id}>
                  <CarouselSubTitleInputField
                    slideNumber={currentSlide}
                    componentData={component}
                    setCarouselSettings={setCarouselSettings}
                  />
                </React.Fragment>
              );
            case 'image':
              return (
                <React.Fragment key={component.id}>
                  <CarouselImageAttachment
                    slideNumber={currentSlide}
                    componentData={component}
                    carouselSettings={carouselSettings}
                    setCarouselSettings={setCarouselSettings}
                  />
                </React.Fragment>
              );

            default:
              return null;
          }
        });

        return <div>{renderedComponents}</div>;
      }
      return null;
    }

    return (
      <div className="carousel-node-drawer h-full">
        <div className="carousel-title">
          <div className="flex title items-center">
            <input
              type="checkbox"
              className="checkbox"
              checked={carouselSettings?.isEnabled}
              onChange={handleEnableTitleChange}
            />
            <span className="title-text">Carousel Title Message</span>
          </div>
          <div className="title-msg">
            <textarea
              className={`w-full rounded-t-md border-[1px] focus:outline-none p-2 ${getBorderColor()} relative flex-grow`}
              value={carouselSettings?.text || ''}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              placeholder="Type your message"
              disabled={!carouselSettings?.isEnabled}
            />
          </div>
        </div>

        <div className="carousel-slides">
          <div className="carousel-slides__title flex justify-between">
            <span className="slide-title-text">
              {`Slide ${String(currentSlide + 1).padStart(2, '0')} of ${String(
                carouselSettings?.slides.length || 1,
              ).padStart(2, '0')}`}
            </span>
            <div className="flex items-center">
              <div className="slide-switcher flex gap-x-1">
                <motion.button
                  type="button"
                  className="previous-slide"
                  onClick={() => goToSlide('previous')}
                  disabled={currentSlide === 0}
                  whileHover={{ scale: 1.025 }}
                >
                  <ChevronLeft color="#192A51" />
                </motion.button>
                <motion.button
                  type="button"
                  className="next-slide"
                  onClick={() => goToSlide('next')}
                  disabled={carouselSettings?.slides?.length === currentSlide + 1}
                  whileHover={{ scale: 1.025 }}
                >
                  <ChevronRight color="#192A51" />
                </motion.button>
              </div>
              <PrimaryButton
                text="Add Slide"
                btnColor="bg-spaceCadetBlue80"
                color="white"
                radius="full"
                paddingY="0"
                paddingX="4"
                icon={<AddIcon />}
                textSize="xs"
                btnHoverColor="hover:bg-spaceCadetBlue"
                handleOnClick={handleAddSlide}
              />
            </div>
          </div>

          <hr className="mt-2 bg-spaceCadetBlue h-[1.5px]" />

          <div className="slide">{buildComponents()}</div>
        </div>
      </div>
    );
  },
);

CarouselDrawer.propTypes = {
  nodeId: PropTypes.string,
  handleCarouselDrawerSubmit: PropTypes.func,
};

export default CarouselDrawer;
