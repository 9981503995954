import { UserType } from 'constants/user.const';

export const defaultTableHeaders = [
  {
    key: 'name',
    value: 'Role Name',
    sortingEnabled: true,
  },
  {
    key: 'userType',
    value: 'Type',
    allowedUserTypes: [UserType.ClientUser, UserType.SystemManagementUser, UserType.SuperUser],
  },
  {
    key: 'status',
    value: 'Status',
    type: 'statusLabel',
    params: {
      active: {
        label: 'ACTIVE',
        color: 'white',
        bgColor: 'bg-northTexasGreen',
        borderColor: 'none',
      },
      deleted: {
        label: 'DELETED',
        color: 'white',
        bgColor: 'bg-statusRed',
        borderColor: 'none',
      },
    },
  },
  {
    key: 'createdAt',
    value: 'Created At',
    type: 'dateTime',
    sortingEnabled: true,
  },
  {
    key: 'updatedAt',
    value: 'Updated At',
    type: 'dateTime',
    sortingEnabled: true,
  },
  {
    key: 'manage',
    value: 'Manage',
    type: 'manageBtn',
    permissions: [],
  },
];

export const excelHeaders = [
  {
    key: 'name',
    value: 'Role Name',
    sortingEnabled: true,
  },
  {
    key: 'status',
    value: 'Status',
  },
  {
    key: 'createdAt',
    value: 'Created At',
    type: 'dateTime',
    sortingEnabled: true,
  },
  {
    key: 'updatedAt',
    value: 'Updated At',
    type: 'dateTime',
    sortingEnabled: true,
  },
];

export const tableDefaultValues = {
  totalDocs: 0,
  totalPages: 0,
  limit: 10,
  page: 1,
  headers: defaultTableHeaders,
  docs: [],
  sort: null,
  order: -1,
  searchBy: null,
};

export const LAST_UPDATED_AT_OPTIONS = [
  {
    value: 'today',
    label: 'Today',
  },
  {
    value: '24hours',
    label: 'Past 24 hours',
  },
  {
    value: 'week',
    label: 'This week',
  },
];

export const ROLE_STATUS = {
  ACTIVE: 'active',
  DELETED: 'deleted',
};

export const ROLES_STATUS_OPTIONS = [
  {
    value: ROLE_STATUS.ACTIVE,
    label: 'Active',
  },
  {
    value: ROLE_STATUS.DELETED,
    label: 'Deleted',
  },
];
