import { IFormattedNote, INote } from './interface';

/**
 * Add note status based on the `delete` property
 * if `delete`, status = `deleted` end `open`
 * @param {INote} docs - Note array of object
 * @returns {IFormattedNote[]} - Array of object containing notes with additional property `status`
 */
export const mapNotes = (docs: INote[]): IFormattedNote[] => {
  const notes: IFormattedNote[] = docs?.map((note) => {
    const { isDeleted } = note;
    let status = 'open';
    if (isDeleted) {
      status = 'deleted';
    }
    return { ...note, status };
  });
  return notes;
};
