/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-daisyui';
import { toast } from 'react-toastify';

function FileUploadModal(props) {
  const {
    show,
    onClose,
    onFileSelected,
    allowedTypes = ['image/jpeg', 'image/png'],
    type = 'image',
    title,
  } = props;
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = useCallback(
    (event) => {
      const file = event.target.files[0];
      // needs to clear the field in order to override browser built in security feature
      // that prevents same file being uploaded twice
      event.target.value = '';

      if (file) {
        if (validateFile(file)) {
          setSelectedFile(file);
          onFileSelected(file); // Call the parent component callback
        }
      }
    },
    [onFileSelected],
  );

  // Function to validate the selected file
  const validateFile = (file) => {
    // Check if the file is an image (PNG or JPG)
    if (!allowedTypes.includes(file.type)) {
      if (type === 'image') {
        toast.warn('Invalid file type. Please select a PNG or JPG image.');
      }
      return false;
    }

    if (type === 'image') {
      // Check if the file size is less than or equal to 5MB
      const maxSizeBytes = 5 * 1024 * 1024; // 5MB
      if (file.size > maxSizeBytes) {
        toast.warn('File size exceeds the maximum limit (5MB).');
        return false;
      }
    }

    return true;
  };

  const handleClose = () => {
    setSelectedFile(null); // Clear selected file when the modal is closed
    onClose();
  };

  return (
    <Modal
      open={show}
      className="top-0 left-0 right-0 p-0 border border-gainsboroBlue rounded-lg z-50 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(auto-1rem)] max-w-2xl max-h-full"
    >
      <Modal.Header className="font-bold pb-2 mb-2 border-b p-4">
        <div className="flex justify-between items-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 w-fit flex-wrap items-center">
            <div className="text-xl font-bold font-ptScanCap">{title}</div>
          </div>
          <div className="text-sm font-normal bg-red">
            <Button
              size="xs"
              shape="circle"
              className="btn btn-xs btn-circle border-2 bg-white border-rasinBlack font-bold"
              onClick={handleClose}
            >
              x
            </Button>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="p-4">
        <div className="flex items-center justify-center w-full">
          <label
            htmlFor="dropzone-file"
            className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
          >
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <svg
                className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                <span className="font-semibold">Click to upload</span> or drag and drop
              </p>
              <p className="text-xs text-gray-500 dark:text-gray-400">
                PNG or JPG (MAX. 800x800px)
              </p>
            </div>
            <input
              id="dropzone-file"
              type="file"
              className="hidden"
              onChange={handleFileChange}
              accept={allowedTypes.join(',')} // Set the accepted file types
            />
          </label>
        </div>
      </Modal.Body>
    </Modal>
  );
}

FileUploadModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  allowedTypes: PropTypes.array,
  type: PropTypes.string.isRequired,
  onFileSelected: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default FileUploadModal;
