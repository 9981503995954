import { COLLECTIONS } from 'constants/db';
import { FEATURE_KEYS } from 'constants/permissions';

export const defaultFormData = {
  name: '',
  description: '',
  clientId: '',
};

export const promptDefaultOptions = {
  show: false,
  title: '',
  message: '',
  onConfirm: null,
  type: null,
  data: null,
};

export enum PromptTypes {
  DeleteRolePermission = 'DeleteRolePermission',
}

export const defaultFormPlaceholders = {
  name: 'eg: Supervisor',
  description: 'eg: This role is for CX supervisor',
};

export const PERMISSION_GROUPS = [
  {
    moduleName: 'User Management',
    featureKeys: [
      { name: 'Users', featureKey: FEATURE_KEYS.user_management },
      { name: 'Role Permissions', featureKey: FEATURE_KEYS.role_permission },
      { name: 'Channels', featureKey: FEATURE_KEYS.channel },
    ],
  },
  {
    moduleName: 'Client Management',
    featureKeys: [{ name: 'Clients', featureKey: FEATURE_KEYS.client_management }],
  },
  {
    moduleName: 'Contacts',
    featureKeys: [{ name: 'Contacts', featureKey: FEATURE_KEYS.contact }],
  },
  {
    moduleName: 'Inbox',
    featureKeys: [
      { name: 'Chats', featureKey: FEATURE_KEYS.inbox_chat },
      { name: 'Bot Chats', featureKey: FEATURE_KEYS.bot_chat },
      { name: 'Completed Chats', featureKey: FEATURE_KEYS.completed_chat },
    ],
  },
  {
    moduleName: 'Cases',
    featureKeys: [{ name: 'Cases', featureKey: FEATURE_KEYS.case }],
  },
  {
    moduleName: 'Settings',
    featureKeys: [{ name: 'Messenger', featureKey: FEATURE_KEYS.messenger }],
  },
];

export const transactionTableHeaders = [
  {
    key: 'sequenceId',
    value: 'Transaction ID',
  },
  {
    key: 'description',
    value: 'Transaction Description',
  },
  {
    key: 'createdAt',
    value: 'Transaction Date and Time',
    type: 'dateTime',
  },
  {
    key: 'createdBy',
    value: 'User',
    type: 'transactionUser',
  },
  {
    key: 'updatedValues',
    value: 'Data',
    type: 'transactionUpdatedValues',
    module: COLLECTIONS.Role,
  },
];
