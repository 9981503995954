import { ALL_USERS, UserAgentStatus } from 'constants/user.const';

export function formatUserAgentStatus(status: string) {
  if (status === UserAgentStatus.InBreak) {
    return 'Break';
  }
  if (status === UserAgentStatus.Offline) {
    return 'Offline';
  }
  if (status === UserAgentStatus.Online) {
    return 'Online';
  }
  return '';
}

/**
 * Get user type db string to human readable format
 * @param userType - User type from db
 * @returns
 */
export const getUserTypeDisplay = (userType: string) => {
  const userTypeDisplay = ALL_USERS.find((userTypeOption) => userTypeOption.value === userType);
  return userTypeDisplay.label || userType;
};
