/* eslint-disable linebreak-style */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable default-param-last */
import { getUtterancesByIntentId } from 'services/messenger.service';
import { apiErrorHandler } from 'api/handler';
import toast from 'react-simple-toasts';
import { ToastTheme } from 'enums/toast.enum';
import { IURLQueryParams } from 'interfaces/query.interface';

export const loadUtterancesList = async (
  id: string,
  params: IURLQueryParams,
  setUtteranceList: (utteranceList: any) => void = () => {},
  setCustomLoading: (customLoading: any) => void,
) => {
  try {
    const { data } = await getUtterancesByIntentId(id, params);
    if (data?.node) {
      const { docs, totalPages, page } = data.node;

      setUtteranceList((prevUtteranceList) => ({
        ...prevUtteranceList,
        ...data.node,
        docs: docs.map((utteranceItem) => ({
          _id: utteranceItem._id,
          utterance: utteranceItem.utterance,
          createdBy: utteranceItem.createdBy?.name || 'Unknown',
          createdAt: utteranceItem.createdAt,
        })),
        totalPages,
        page,
      }));
    }
  } catch (error) {
    const { message: exception } = apiErrorHandler(error);
    toast(exception, { theme: ToastTheme.failure });
  } finally {
    setCustomLoading((prevState) => ({ ...prevState, fetchList: false }));
  }
};
