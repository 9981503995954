import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { formatDateTime } from 'utils/dates';
import { Delete, Disable } from 'components/Icons';
import { PrimaryButton, PromptModal } from 'components';
import { deleteIntent, disableIntent } from 'services/messenger.service';
import { apiErrorHandler } from 'api/handler';
import { DefaultIntents } from 'constants/intents';

import { PromptTypes, customLoadingOptions, promptModalDefaultOptions } from '../../constants';

function FormEditHeader(props) {
  const { formData, setIntentData } = props;

  const [promptOptions, setPromptOptions] = useState(promptModalDefaultOptions);
  const [deletePromptOptions, setDeletePromptOptions] = useState(promptModalDefaultOptions);

  const [customLoading, setCustomLoading] = useState(customLoadingOptions);
  const [triggerChild, setTriggerChild] = useState({ users: null, transactions: null });

  const navigate = useNavigate();
  const DisableIntent = () => {
    setPromptOptions({
      show: true,
      title: 'Are You Sure ?',
      message: `Do You Want To  ${formData?.isDisabled ? 'Disable' : 'Enable'} this Intent ${
        formData?.intentName
      }`,
      onConfirm: () => handleDisableIntent(),

      type: PromptTypes.ToggleAccount,
    });
  };

  const handleDisableIntent = async () => {
    try {
      const payload = {
        isDisable: formData?.isDisabled,
      };
      setCustomLoading((prevState) => ({ ...prevState, toggleAccount: false }));
      const { data } = await disableIntent(formData._id, payload);

      if (data?.node) {
        const { isDisabled } = data.node;
        setPromptOptions({ ...promptOptions, show: false });
        setIntentData((prev) => ({ ...prev, isDisabled }));
        toast.success(`Successfully ${formData?.isDisabled ? 'Disabled' : 'Enabled'} the Intent`);
      }
    } catch (e) {
      const { message: exception } = apiErrorHandler(e);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, toggleAccount: false }));
    }
  };

  const DeleteIntent = (data: any) => {
    setDeletePromptOptions({
      show: true,
      title: 'Are You Sure ?',
      message: `Do You Want To Delete  ${formData?.intentName} This Intent`,
      onConfirm: () => handleDeleteIntent(data._id),
      type: PromptTypes.ToggleAccount,
    });
  };

  const handleDeleteIntent = async (id: string) => {
    try {
      await deleteIntent(id);
      toast.success('Intent Deleted Successfully');
      setDeletePromptOptions({
        show: false,
        ...deletePromptOptions,
      });
      setTriggerChild((prevState) => ({ ...prevState, transactions: uuid() }));
      // Redirect to the previous page or any other route
      navigate(-1);
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    }
  };

  function loadDynamicLoadingProp() {
    if (promptOptions.type === PromptTypes.ToggleAccount) {
      return customLoading.toggleAccount;
    }
    return false;
  }

  return (
    <div className="flex items-center justify-between mb-4">
      <div className="flex items-center">
        <div className="client-account__details items-center">
          <div className="flex flex-row gap-2 items-center pb-2">
            <h2 className="text-spaceCadetBlue font-bold font-lato text-lg srg:text-xl">
              View Intent
            </h2>
          </div>
          <div className="flex flex-wrap gap-x-4 items-center">
            <p className="text-spaceCadetBlue font-normal font-lato text-base rg:text-lg">
              Intent ID : {formData?.intentId}
            </p>
            <p className="text-spaceCadetBlue font-normal font-lato text-base rg:text-lg">
              Intent Name: {formData?.displayIntentName}
            </p>

            {formData?.createdAt && (
              <div className="text-rasinBlack font-normal font-lato text-sm rg:text-base">
                Created At: {formatDateTime(formData?.createdAt)}
              </div>
            )}
            {formData?.updatedAt && (
              <div className="text-rasinBlack font-normal font-lato text-sm rg:text-base">
                Last Updated: {formatDateTime(formData?.updatedAt)}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex gap-2">
        <PrimaryButton
          text={formData?.isDisabled ? 'Disable' : 'Enable'}
          btnColor={formData?.isDisabled ? 'bg-begoniaRed' : 'bg-green-500'}
          color="white"
          width="[140px]"
          radius="2xl"
          btnHoverColor={formData?.isDisabled ? 'hover:bg-begoniaRed' : 'hover:bg-green-500'}
          icon={<Disable />}
          handleOnClick={() => DisableIntent()}
          disabled={formData?.intentId === DefaultIntents.FallbackIntent.intentId}
        />

        <PrimaryButton
          text="Delete"
          btnColor="bg-begoniaRed"
          color="white"
          radius="2xl"
          textSize="sm"
          btnHoverColor="hover:bg-begoniaRed"
          icon={<Delete color="white" />}
          handleOnClick={() => DeleteIntent(formData)}
          disabled={formData?.intentId === DefaultIntents.FallbackIntent.intentId}
        />

        {promptOptions.show && (
          <PromptModal
            onCancel={() => {
              setPromptOptions({ ...promptOptions, show: false });
            }}
            {...promptOptions}
            loading={loadDynamicLoadingProp()}
          />
        )}
        {deletePromptOptions.show && (
          <PromptModal
            onCancel={() => {
              setDeletePromptOptions({ ...deletePromptOptions, show: false });
            }}
            {...deletePromptOptions}
            loading={loadDynamicLoadingProp()}
          />
        )}
      </div>
    </div>
  );
}

FormEditHeader.propTypes = {
  formData: PropTypes.any,
  setIntentData: PropTypes.func,
};

export default FormEditHeader;
