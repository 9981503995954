export const defaultTableHeaders = [
  {
    key: 'name',
    value: 'Instance Name',
    sortingEnabled: true,
  },
  {
    key: 'instanceType',
    value: 'Type',
    sortingEnabled: true,
  },
  {
    key: 'connectedVia',
    value: 'Connected Via',
    sortingEnabled: true,
  },
  {
    key: 'isActive',
    value: 'Status',
    type: 'messengerInstanceStatus',
    sortingEnabled: true,
  },
  {
    key: 'createdAt',
    value: 'Created At',
    type: 'dateTime',
    sortingEnabled: true,
  },
  {
    key: 'updatedAt',
    value: 'Updated At',
    type: 'dateTime',
    sortingEnabled: true,
  },
  {
    key: 'manage',
    value: 'Manage',
    type: 'manageBtn',
    permissions: [],
  },
];

export const tableDefaultValues = {
  totalDocs: 0,
  totalPages: 0,
  limit: 10,
  page: 1,
  headers: defaultTableHeaders,
  docs: [],
  sort: null,
  order: -1,
  searchBy: null,
};

export const excelHeaders = [
  {
    key: 'name',
    value: 'Instance Name',
    sortingEnabled: true,
  },
  {
    key: 'instanceType',
    value: 'Type',
    sortingEnabled: true,
  },
  {
    key: 'connectedVia',
    value: 'Connected Via',
    sortingEnabled: true,
  },
  {
    key: 'isActive',
    value: 'Status',
    type: 'messengerInstanceStatus',
    sortingEnabled: true,
  },
  {
    key: 'createdAt',
    value: 'Created At',
    type: 'dateTime',
    sortingEnabled: true,
  },
  {
    key: 'updatedAt',
    value: 'Updated At',
    type: 'dateTime',
    sortingEnabled: true,
  },
];

export const LAST_UPDATED_AT_OPTIONS = [
  {
    value: 'today',
    label: 'Today',
  },
  {
    value: '24hours',
    label: 'Past 24 hours',
  },
  {
    value: 'week',
    label: 'This week',
  },
];

export const MESSENGER_STATUS_OPTIONS = [
  {
    value: 'active',
    label: 'Active',
  },
  {
    value: 'inactive',
    label: 'Inactive',
  },
];
