import { toast } from 'react-toastify';
import moment from 'moment';

import { ISelectOption } from 'interfaces/component.interface';
import { USER_STATUS, USER_TYPES, UserType } from 'constants/user.const';
import { cleanExcelColumns, exportSingleSheetToExcel } from 'utils/excel';

import { excelHeaders } from './constants';

/**
 * Set selected item for user type select filter
 * @param {string} value - Selected value from url params(userType)
 * @returns {ISelectOption} - Selected uer type object
 */
export const getUserTypeSelected = (value: string): ISelectOption => {
  const selectedUserType = USER_TYPES.find((userTypeOption) => userTypeOption.value === value);
  return selectedUserType;
};

/**
 * Set selected item for user status select filter
 * @param {string} value - Selected value from url params(userStatus)
 * @returns {ISelectOption} - Selected user status object
 */
export const getUserStatusSelected = (value: string): ISelectOption => {
  const selectedUserStatus = USER_STATUS.find(
    (userStatusOption) => userStatusOption.value === value,
  );
  return selectedUserStatus;
};

export function mapListViewDocs(docs = []) {
  if (!Array.isArray(docs)) {
    return [];
  }

  const formattedDocs = docs.map((user) => ({
    ...user,
    roles: user?.roles.map((role: any) => role.name).join(', '),
    userType: getUserType(user.userType),
  }));

  return formattedDocs;
}

export function getUserType(userType: string) {
  if (userType === UserType.ClientUser) {
    return 'Client User';
  }
  if (userType === UserType.SystemManagementUser) {
    return 'System Management User';
  }
  if (userType === UserType.SuperUser) {
    return 'Super User';
  }
  return userType;
}

export const handleExportExcel = async (docs: any[]) => {
  if (docs.length === 0) {
    toast.warning('No records found');
    return;
  }

  const mappedColumns = excelHeaders.map((x) => ({
    header: x.value,
    key: x.key,
    width: 25,
  }));

  // Clean the columns by removing those with empty labels
  const cleanedColumns = cleanExcelColumns(mappedColumns);
  const rows = docs; // Get the data rows

  const mappedRowData = rows.map((x) => ({
    ...x,
    createdAt: x.createdAt ? moment(x.createdAt).fromNow() : 'N/A',
  }));

  try {
    await exportSingleSheetToExcel({
      worksheetName: 'users-list', // Name of the worksheet in the Excel file
      columns: cleanedColumns, // Cleaned columns for the Excel file
      rows: mappedRowData, // Mapped and formatted row data for the Excel file
      fileName: 'Users List', // Name of the exported Excel file
    });
  } catch (e) {
    toast.error('Something went wrong, Try again');
  }
};
