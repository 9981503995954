import * as React from 'react';
import PropTypes from 'prop-types';

function Show(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color = '#192A51', width = 32, height = 32 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 32 32"
    >
      <g
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.75"
        clipPath="url(#clip0_3239_2653)"
      >
        <path d="M13.333 16a2.667 2.667 0 105.334 0 2.667 2.667 0 00-5.334 0z" />
        <path d="M28 16c-3.2 5.333-7.2 8-12 8s-8.8-2.667-12-8c3.2-5.333 7.2-8 12-8s8.8 2.667 12 8z" />
      </g>
      <defs>
        <clipPath id="clip0_3239_2653">
          <path fill="#fff" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
Show.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Show;
