/* eslint-disable linebreak-style */

import instance from 'api';
import { PROFILE } from 'api/apiEndpoints';
import UrlBuilder from 'api/urlQueryBuilder';

export function updatePasswordService(body: any) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(PROFILE.MANAGE).build();
  return instance.post(url, body);
}
