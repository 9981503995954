/* eslint-disable @typescript-eslint/no-empty-function */
import instance, { postAttachments } from 'api';
import { MESSAGES, MSG_TEMPLATES } from 'api/apiEndpoints';
import UrlBuilder from 'api/urlQueryBuilder';
import _ from 'lodash';
import { IClientListURLQueryParams } from 'views/ClientManagement/List/interface';

export const fetchMessageList = (id: string, params: IClientListURLQueryParams) => {
  const {
    page = 1,
    limit = 10,
    sort = 'createdAt',
    order = 1,
    country,
    status,
    searchBy,
    select,
  } = params;

  // creates a new object with only the non-null, non-undefined values from the extracted variables
  const queryParams = _.pickBy({
    page,
    limit,
    sort,
    order,
    country,
    status,
    searchBy,
    select,
  });
  const url = UrlBuilder()
    .setUrl(MESSAGES.GET_MESSAGES_LIST)
    .setPathParams({ id })
    .setQueryParams({ ...queryParams })
    .build();
  return instance.get(url);
};

export async function createMessage(id: string, data: any) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(MESSAGES.CREATE_MESSAGES).setPathParams({ id }).build();
  return instance.post(url, data);
}

export async function uploadAttachmentService(id: string, data: any, onUploadProgressHandler: any) {
  const urlBuilder = UrlBuilder();
  const url = urlBuilder.setUrl(MESSAGES.CREATE_ATTACHMENT).setPathParams({ id }).build();
  return postAttachments(url, data, onUploadProgressHandler);
}
