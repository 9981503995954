/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { AddressIcon, CompanyIcon, DesignationIcon, EmailIcon, PhoneIcon } from 'components/Icons';
import { formatDateTime } from 'utils/dates';
import ProfileSideBar from './containers/ProfileSideBar';
import UpdatePassword from './containers/UpdatePassword';

function UserProfilePage(props) {
  const { formData } = props;

  return (
    <div className="page">
      <div className="grid grid-cols-3 gap-6 h-screen">
        <div className="col-span-2 overflow-auto flex flex-col  ">
          <div className="h-fit w-82 mt-3">
            <div className="bg-white border rounded-xl w-82 justify-between pb-3">
              <div className="flex h-auto p-4 items-center justify-between rounded-t-lg bg-brightGray gap-11">
                <span className="text-center text-sm text-spaceCadetBlue font-ptScanCap font-semibold">
                  Your Profile
                </span>
              </div>
              {/* -------------------------------------------- */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-4">
                {/* Contact Number, Email Address, and Address */}
                <div className="md:flex items-center">
                  <div className="pr-3">
                    <PhoneIcon width={20} height={20} />
                  </div>
                  <div>
                    <p className="text-rasinBlack font-bold text-sm">Contact Number</p>
                    <p className="text-gray-600 font-normal text-xs">
                      {formData?.primaryContactNo || ''}
                    </p>
                  </div>
                </div>
                <div className="md:flex items-center">
                  <div className="pr-3">
                    <EmailIcon width={20} height={20} />
                  </div>
                  <div>
                    <p className="text-rasinBlack font-bold text-sm">Email Address</p>
                    <p className="text-gray-600 font-normal text-xs">{formData?.email || ''}</p>
                  </div>
                </div>
                {(formData?.streetAddress || formData?.city) && (
                  <div className="md:flex items-center">
                    <div className="pr-3">
                      <AddressIcon width={20} height={20} />
                    </div>
                    <div>
                      <p className="text-rasinBlack font-bold text-sm">Address</p>
                      <p className="text-gray-600 font-normal text-xs">
                        {formData?.streetAddress
                          ? `${formData?.city}, ${formData?.streetAddress}`
                          : ''}
                      </p>
                    </div>
                  </div>
                )}
                {/* Company and Designation */}
                {formData?.companyName && formData?.primaryContactName && (
                  <div className="md:flex items-center">
                    <div className="pr-3">
                      <CompanyIcon width={20} height={20} />
                    </div>
                    <div>
                      <p className="text-rasinBlack font-bold text-sm">Company</p>
                      <p className="text-gray-600 font-normal text-xs">
                        {formData?.companyName || ''}
                      </p>
                    </div>
                  </div>
                )}
                {formData?.primaryContactName && (
                  <div className="md:flex items-center">
                    <div className="pr-3">
                      <DesignationIcon width={20} height={20} />
                    </div>
                    <div>
                      <p className="text-rasinBlack font-bold text-sm">Designation</p>
                      <p className="text-gray-600 font-normal text-xs">
                        {formData?.primaryContactName || ''}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="mt-4 p-8 bg-white drop-shadow rounded-xl form_body">
            <span className="text-center text-sm text-spaceCadetBlue font-ptScanCap font-bold">
              Update Password
            </span>

            {formData.updatedAt && (
              <div className="text-gray-600 font-normal text-xs font-lato pt-2">
                Last Updated {formatDateTime(formData?.updatedAt)}
              </div>
            )}

            <hr className="my-3" />
            <div>
              <UpdatePassword />
            </div>
          </div>
        </div>

        <div className="bg-[#de2626] fixed right-0 top-5 mt-16 rg:w-1/4">
          <div className="bg-[#F9F9F9] fixed right-0 top-5 mt-16 rg:w-1/4 h-full p-6 flex justify-center items-center">
            <div className="w-full shadow-md h-full flex justify-center items-center bg-white border rounded-xl p-4 m-auto">
              <ProfileSideBar formData={formData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

UserProfilePage.propTypes = {
  formData: PropTypes.any,
};

export default UserProfilePage;
