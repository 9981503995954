/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import PropTypes from 'prop-types';

import { CustomSelect, InputText } from 'components';
import { reactSelectCustomStyles } from 'styles/select';

import { groupedOptions } from '../../constants';
import { caseValueTypes, caseValueWithFormFieldTypes } from './constants';
import VariableDropdown from '../../VariableDropdown';
import { ICaseForm } from './interfaces';
import FormField from './components/FormField';

interface IAddCaseFormProps {
  formData: ICaseForm;
  useSelectedValueType: (valueType: string, array: any[], id: string) => any;
  useSelectedVariableValue: (valueType: string, array: any[], id: string) => any;
  handleInputChange: (event: any, section: string) => void;
  handleCheckChange: (event: any, section: string) => void;
  handleSelectChange: (event: any, section: string, id: string) => void;
  handleSelectVariableChange: (event: any, section: string, id: string) => void;
}

/**
 * This is case form in flow builder drawer
 */
function AddCaseForm(props: IAddCaseFormProps) {
  const {
    formData,
    useSelectedValueType,
    useSelectedVariableValue,
    handleInputChange,
    handleCheckChange,
    handleSelectChange,
    handleSelectVariableChange,
  } = props;

  const handlers = {
    useSelectedValueType,
    useSelectedVariableValue,
    handleInputChange,
    handleCheckChange,
    handleSelectChange,
    handleSelectVariableChange,
  };

  return (
    <div>
      <form>
        <div className="flow-contact__form">
          <div className="flow-contact__form-headings">
            <div>
              <span>Form Field Name</span>
              <span>Value Type</span>
              <span>Value </span>
            </div>
          </div>
          {/* Contact */}
          <FormField
            label="Contact"
            id="contact"
            isFieldEnabled={formData?.contact?.isFieldEnabled}
            valueType={formData?.contact?.valueType}
            value={formData?.contact?.value}
            options={caseValueTypes}
            formData={formData}
            handlers={handlers}
            requiredField
          />
          {/* Primary contact No */}
          <FormField
            label="Primary contact No"
            id="primaryContactNo"
            isFieldEnabled={formData?.primaryContactNo?.isFieldEnabled}
            valueType={formData?.primaryContactNo?.valueType}
            value={formData?.primaryContactNo?.value}
            options={caseValueTypes}
            formData={formData}
            handlers={handlers}
            requiredField
          />
          {/* Email */}
          <FormField
            label="Email"
            id="email"
            isFieldEnabled={formData?.email?.isFieldEnabled}
            valueType={formData?.email?.valueType}
            value={formData?.email?.value}
            options={caseValueTypes}
            formData={formData}
            handlers={handlers}
          />
          {/* Category */}
          <FormField
            label="Category"
            id="category"
            isFieldEnabled={formData?.category?.isFieldEnabled}
            valueType={formData?.category?.valueType}
            value={formData?.category?.value}
            options={caseValueWithFormFieldTypes}
            formData={formData}
            handlers={handlers}
            requiredField
          />
          {/* Subject */}
          <FormField
            label="Subject"
            id="subject"
            isFieldEnabled={formData?.subject?.isFieldEnabled}
            valueType={formData?.subject?.valueType}
            value={formData?.subject?.value}
            options={caseValueTypes}
            formData={formData}
            handlers={handlers}
            requiredField
          />
          {/* Description */}
          <FormField
            label="Description"
            id="description"
            isFieldEnabled={formData?.description?.isFieldEnabled}
            valueType={formData?.description?.valueType}
            value={formData?.description?.value}
            options={caseValueTypes}
            formData={formData}
            handlers={handlers}
          />
          {/* Severity */}
          <FormField
            label="Severity"
            id="severity"
            isFieldEnabled={formData?.severity?.isFieldEnabled}
            valueType={formData?.severity?.valueType}
            value={formData?.severity?.value}
            options={caseValueWithFormFieldTypes}
            formData={formData}
            handlers={handlers}
          />
          {/* Status */}
          <FormField
            label="Status"
            id="status"
            isFieldEnabled={formData?.status?.isFieldEnabled}
            valueType={formData?.status?.valueType}
            value={formData?.status?.value}
            options={caseValueWithFormFieldTypes}
            formData={formData}
            handlers={handlers}
            requiredField
          />
          {/* Tags */}
          <FormField
            label="Tags"
            id="tags"
            isFieldEnabled={formData?.tags?.isFieldEnabled}
            valueType={formData?.tags?.valueType}
            value={formData?.tags?.value}
            options={caseValueTypes}
            formData={formData}
            handlers={handlers}
          />
        </div>
      </form>
    </div>
  );
}

AddCaseForm.propTypes = {
  formData: PropTypes.any,
  useSelectedValueType: PropTypes.func,
  useSelectedVariableValue: PropTypes.func,
  handleInputChange: PropTypes.func,
  handleCheckChange: PropTypes.func,
  handleSelectChange: PropTypes.func,
  handleSelectVariableChange: PropTypes.func,
  //   onSubmit: PropTypes.func,
};

export default AddCaseForm;
