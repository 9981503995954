import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { RootState } from 'slices';
import { buildVariableDropdownOptions } from 'views/Settings/FlowBuilder/utils';
import { segmentStyles, selectConditionOptions } from '../constants';
import { dropdownCommonStyles } from '../../../constants';
import DeleteBtn from '../../common/DeleteBtn';
import { ISegment } from '../interfaces';
import VariableDropdown from '../../../VariableDropdown';

interface ISegmentProps {
  conditionId: string;
  segmentId: string;
  criteria?: string;
  condition?: string;
  textPhrase?: string;
  handleUpdateSegmentCriteria?: (
    conditionId: string,
    segmentId: string,
    updatedSegmentData: Partial<ISegment>,
  ) => void;
  handleDeleteSegmentCriteria?: (conditionId: string, segmentId: string) => void;
}

function Segment(props: ISegmentProps) {
  const {
    conditionId,
    segmentId,
    criteria,
    condition,
    textPhrase,
    handleUpdateSegmentCriteria,
    handleDeleteSegmentCriteria,
  } = props;

  const { variables = [] } = useSelector((state: RootState) => state.flow);

  const flowData = useSelector((state: RootState) => state.flow);

  // disable if criteria is not defined
  const isFieldsDisabled = !criteria;

  /**
   * Handles segment updates
   * @param {string} fieldName - Field name(`criteria`, `condition` or `textPhrase`)
   * @param {string} value - Updated value
   */
  const handleFieldChange = (fieldName: string, value: string) => {
    handleUpdateSegmentCriteria(conditionId, segmentId, { [fieldName]: value });
  };

  const handleSegmentDelete = () => {
    handleDeleteSegmentCriteria(conditionId, segmentId);
  };

  return (
    <div className="segment-criteria flex flex-row space-x-2 pt-1">
      <div className="flex segment-criteria-criteria items-center">
        <VariableDropdown
          id="messageInputVariableDropdown"
          placeholder="Select criteria..."
          variablesOnly
          options={buildVariableDropdownOptions(flowData?.variables || [])}
          customStyles={{ ...dropdownCommonStyles, ...segmentStyles.criteriaStyles }}
          value={variables.find((option) => option.value === criteria)}
          onChange={(selectedOption) => handleFieldChange('criteria', selectedOption?.value)}
        />
      </div>
      <div className="flex segment-criteria-condition">
        <Select
          options={selectConditionOptions}
          styles={{ ...dropdownCommonStyles, ...segmentStyles.conditionStyles }}
          value={selectConditionOptions.find((option) => option.value === condition)}
          onChange={(selectedOption) => handleFieldChange('condition', selectedOption?.value)}
          isDisabled={isFieldsDisabled}
          components={{
            IndicatorSeparator: () => null,
          }}
        />
      </div>
      <div className="flex-grow segment-criteria-input">
        <input
          className="text-sm flow-builder__input"
          type="text"
          defaultValue={textPhrase || ''} // defaultValue prop sets the initial value, but lets the DOM update the input value after that. This means that the DOM takes care of preserving the cursor position, even when the component re-renders.
          onChange={(event) => handleFieldChange('textPhrase', event.target.value)}
          disabled={isFieldsDisabled}
        />
      </div>

      <DeleteBtn handleOnClick={handleSegmentDelete} />
    </div>
  );
}

Segment.propTypes = {
  conditionId: PropTypes.string.isRequired,
  segmentId: PropTypes.string.isRequired,
  criteria: PropTypes.string,
  condition: PropTypes.string,
  textPhrase: PropTypes.string,
  handleUpdateSegmentCriteria: PropTypes.func,
  handleDeleteSegmentCriteria: PropTypes.func,
};

export default Segment;
