import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import JSONPretty from 'react-json-pretty';
import { toast } from 'react-toastify';

import {
  addNewAPIRequestHeader,
  deleteAPIRequestHeader,
  updateAPIRequestHeader,
} from 'slices/flow';
import { PrimaryButton } from 'components';
import { IAPIRequestData, IUrlHeader } from '../interfaces';
import RequestHeader from './RequestHeader';
import RequestBody from './RequestBody';
import { sendTestRequest } from '../utils';
import { REQUEST_METHODS } from '../constants';

interface IRequestHeadersProps {
  nodeId?: string;
  requestData?: IAPIRequestData;
  headersComponentVisible?: boolean;
}

function RequestHeaders(props: IRequestHeadersProps) {
  const { nodeId, requestData, headersComponentVisible } = props;
  const [activeTab, setActiveTab] = useState('response');
  const [testResponse, setTestResponse] = useState<any>();

  const dispatch = useDispatch();

  const handleTestRequest = async () => {
    try {
      const { data, headers } = await sendTestRequest(
        requestData?.url,
        requestData?.requestMethod,
        requestData?.body,
      );
      if (data) {
        setTestResponse({ data, headers });
        toast.success('Test request successful');
      }
    } catch (error) {
      console.error(`ERROR:: ${error}`);
      toast.error('API Request Test Failed');
    }
  };

  /**
   * Adds a new header to the headers array in the request data.
   */
  const handleAddHeader = () => {
    dispatch(addNewAPIRequestHeader({ newNodeId: nodeId }));
  };

  /**
   * Handle header update
   * @param {string} headerId - Header Id
   * @param {Partial<IUrlHeader>} updatedHeaderData - Updated header data
   */
  const handleUpdateHeader = (headerId: string, updatedHeaderData: Partial<IUrlHeader>) => {
    dispatch(updateAPIRequestHeader({ nodeId, headerId, updatedHeaderData }));
  };

  /**
   * Delete Header from requestData
   * @param headerId
   */
  const handleDeleteHeader = (headerId: string) => {
    dispatch(deleteAPIRequestHeader({ nodeId, headerId }));
  };

  return (
    <div className="request-headers">
      {headersComponentVisible ? (
        <div className="request-headers__container">
          <div className="heading__text">Headers</div>
          <div className="space-y-2">
            {requestData?.headers?.map((header: IUrlHeader) => (
              <RequestHeader
                _id={header._id}
                key={header._id}
                headerKey={header.headerKey}
                value={header.value}
                handleDeleteHeader={handleDeleteHeader}
                handleUpdateHeader={handleUpdateHeader}
              />
            ))}
          </div>

          <button type="button" className="add-item__link" onClick={() => handleAddHeader()}>
            Add Header
          </button>
        </div>
      ) : (
        <div className="">
          <button type="button" className="add-item__link" onClick={() => handleAddHeader()}>
            Add Header
          </button>
        </div>
      )}

      {/* Request body */}
      {(requestData?.requestMethod === REQUEST_METHODS.POST ||
        requestData?.requestMethod === REQUEST_METHODS.PUT ||
        requestData?.requestMethod === REQUEST_METHODS.PATCH) && (
        <RequestBody nodeId={nodeId} body={requestData?.body ? String(requestData.body) : ''} />
      )}

      {/* Test request btn */}
      <PrimaryButton
        type="button"
        text="Test request"
        color="#5c6a70"
        width="w-28"
        className="api-request-test__btn"
        radius="4px"
        handleOnClick={handleTestRequest}
      />

      <div className="test-request-preview__container">
        <div role="tablist" className="preview__tabs tabs tabs-boxed">
          <button
            type="button"
            role="tab"
            className={`tab ${activeTab === 'response' ? 'tab-active' : ''}`}
            onClick={() => setActiveTab('response')}
          >
            Response Body
          </button>
          <button
            type="button"
            role="tab"
            className={`tab ${activeTab === 'headers' ? 'tab-active' : ''}`}
            onClick={() => setActiveTab('headers')}
          >
            Headers
          </button>
        </div>

        <div className="p-4">
          {activeTab === 'response' && (
            <div className="response">
              <JSONPretty id="json-pretty" data={testResponse?.data} />
            </div>
          )}

          {activeTab === 'headers' && (
            <div className="headers">
              <JSONPretty id="json-pretty" data={testResponse?.headers} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

RequestHeaders.propTypes = {
  nodeId: PropTypes.string,
  requestData: PropTypes.object,
  headersComponentVisible: PropTypes.bool,
};

export default RequestHeaders;
