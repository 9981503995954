import { v4 as uuidv4 } from 'uuid';

import { IAPIRequestData, TRequestMethod } from '../interfaces';

export const REQUEST_METHODS: Record<string, TRequestMethod> = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
};

export const REQUEST_METHOD_OPTIONS = [
  { value: REQUEST_METHODS.GET, label: 'GET' },
  { value: REQUEST_METHODS.POST, label: 'POST' },
  { value: REQUEST_METHODS.PUT, label: 'PUT' },
  { value: REQUEST_METHODS.PATCH, label: 'PATCH' },
  { value: REQUEST_METHODS.DELETE, label: 'DELETE' },
];

export const variableOptions = [
  { value: 'contactId', label: 'Contact ID', dataType: 'string' },
  { value: 'firstName', label: 'First Name', dataType: 'string' },
  { value: 'lastMessage', label: 'Last message', dataType: 'string' },
  { value: 'greeting', label: 'Greeting', dataType: 'string' },
  { value: 'name', label: 'Name', dataType: 'string' },
];

export const defaultAPIRequestData: IAPIRequestData = {
  requestMethod: 'GET',
  url: 'https://catfact.ninja/fact',
  headers: [
    {
      _id: uuidv4(),
      headerKey: 'Authorization',
      value: '[your API key]',
    },
  ],
  mappingSettings: [
    {
      _id: uuidv4(),
      mapKey: 'map_key',
      variable: 'firstName',
    },
  ],
};
