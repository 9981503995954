/* eslint-disable no-console */
import { useSelector } from 'react-redux';

import { RootState } from 'slices/';
import { getAccessToken } from 'utils/auth';

// Custom hook to check if a user is authenticated based on userToken presence.
// !TODO
const useAuth = () => {
  const authState = useSelector((state: RootState) => state.auth);
  let authToken = null;

  try {
    authToken = getAccessToken();
  } catch (e) {
    console.log(e);
  }

  if (!authToken) {
    return false;
  }
  // if (authToken || authState.logged) {
  if (authToken) {
    return true;
  }

  return false;
};

export default useAuth;
