import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { PATHS } from 'routes/paths';
import { toast } from 'react-toastify';

import CHATBOT_LOGO from 'assets/images/Logo_XL 1.png';
import { ViewContact } from 'components/Icons';
import { forgetPassword } from 'services/auth.service';
import { apiErrorHandler } from 'api/handler';

function ResetPasswordPage() {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({ email: '', password: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Hide the alert when the user updates any property
    setAlertMessage(null);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);

    try {
      const { data } = await forgetPassword(formData.email);
      toast.success('Password reset email sent successfully!');
      // Clear form data and settimeout
      setFormData({ ...formData, email: '' });
      setTimeout(() => {
        window.location.href = '/login';
      }, 1000);
    } catch (error) {
      const { message: exception } = apiErrorHandler(e);
      toast.error(exception);
    }

    setSubmitLoading(false);
  };
  return (
    <div className="grid grid-cols-1 rg:grid-cols-2 justify-between p-4 h-screen bg-gradient-to-b from-white via-gray-100 to-gray-200 items-center font-lato">
      <div className="left_side-component hidden rg:block">
        <p>{}</p>
      </div>
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        className="p-8 mx-auto rounded-lg items-center bg-white shadow-xl w-full max-w-md relative" // Added relative class
      >
        <div className="mb-6 flex justify-center">
          <img src={CHATBOT_LOGO} width={180} alt="chatbot-logo" />
        </div>
        <h2 className="text-xl text-spaceCadetBlue font-bold mb-6">Reset Password</h2>
        <form onSubmit={handleSubmit}>
          <div className="relative block sm:flex items-center mb-2 sm:mb-0">
            <span className="absolute inset-y-0 left-0 flex items-center pl-2 mt-1">
              <svg className="h-5 w-5 mb-2 fill-slate-300" viewBox="0 0 21 22">
                <ViewContact color="#192A51" />
              </svg>
            </span>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="placeholder:text-independenceBlue border rounded-xl py-3 pl-9 pr-3 focus:outline-none w-full"
              placeholder="Email"
              required
            />
          </div>
          {alertMessage && (
            <div className="w-full text-red-500 text-center mb-3">{alertMessage}</div>
          )}
          <div className="mb-4 mt-5">
            <button
              type="submit"
              className={`bg-blue-dark flex justify-center items-center text-white py-3 px-4 rounded-2xl w-full hover:opacity-70 transition-all duration-300 ${
                submitLoading ? 'cursor-not-allowed opacity-70' : ''
              }`}
              disabled={submitLoading}
            >
              {submitLoading ? (
                <div className="flex items-center justify-center">
                  <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white mr-2" />
                  Loading...
                </div>
              ) : (
                <p className="pl-2 text-sm font-semibold">Reset</p>
              )}
            </button>
            <Link to={PATHS.AUTH.LOGIN.url}>
              <p className="text-independenceBlue text-sm mt-4 mb-4 pl-2">
                Have an account? <u>Login</u>
              </p>
            </Link>

            <p className="text-center text-sm mt-6">© 2023 TASA Solutions</p>
          </div>
        </form>
      </motion.div>
    </div>
  );
}

export default ResetPasswordPage;
