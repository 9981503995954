/* eslint-disable arrow-body-style */
/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DOTS, usePagination } from './usePagination';

function Pagination(props: {
  onPageChange: any;
  totalCount?: 0;
  siblingCount?: 1;
  currentPage: any;
  pageSize: any;
  handleResultsPerPageChange: any;
  showPageLimit: boolean;
}) {
  const {
    onPageChange,
    totalCount = 0,
    siblingCount = 1,
    currentPage,
    pageSize,
    handleResultsPerPageChange,
    showPageLimit,
  } = props;

  const resultsPerPage = [
    { label: '10', value: 10 },
    { label: '20', value: 20 },
    { label: '30', value: 30 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
    { label: 'All', value: 0 },
  ];

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const [numberOfResults, setNumberOfResults] = useState(resultsPerPage[0].value);

  /**
   * Callback function that is triggered when the user changes the results per page.
   * @param {Object} event - The event object.
   */
  const onResultPerPageChange = (event: { target: { value: any } }) => {
    handleResultsPerPageChange(event.target.value);
    setNumberOfResults(Number(event.target.value));
  };

  if (currentPage === 0) {
    return null;
  }

  return (
    <div className=" bg-gainsboroBlue border border-brightGray rounded-b-lg px-11 py-2 font-lato">
      <div className="flex justify-end p-2 gap-2 items-center">
        {numberOfResults !== 0 &&
          paginationRange?.map((pageNumber) => {
            if (pageNumber === DOTS) {
              return (
                <span className="pagination-item dots" key={pageNumber}>
                  &#8230;
                </span>
              );
            }
            return (
              <button
                className={`${
                  pageNumber === currentPage ? 'bg-independenceBlue' : 'bg-spaceCadetBlue' // if current page active color
                } text-white hover:bg-independenceBlue px-2 rounded`}
                onClick={() => onPageChange(pageNumber)}
                key={pageNumber}
              >
                {pageNumber}
              </button>
            );
          })}

        {showPageLimit && (
          <>
            <span className="text-independenceBlue font-normal text-sm">Records Per Page</span>
            <div className="page_row_selector">
              <select
                value={pageSize || numberOfResults}
                onChange={onResultPerPageChange}
                className="p-2 rounded-lg border border-gainsboroBlue"
                name="pages"
                id="pages"
              >
                {resultsPerPage.map((count) => {
                  return (
                    <option
                      key={`${count.value}`}
                      value={count.value}
                      className="text-independenceBlue"
                    >
                      {count.label}
                    </option>
                  );
                })}
              </select>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

Pagination.propTypes = {
  onPageChange: PropTypes.func,
  totalCount: PropTypes.number,
  siblingCount: PropTypes.number,
  currentPage: PropTypes.number,
  pageSize: PropTypes.any,
  handleResultsPerPageChange: PropTypes.func,
  showPageLimit: PropTypes.bool,
};

export default Pagination;
