/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-daisyui';
import { useDebounce } from 'use-debounce';

import { CustomTable, PrimaryButton, SearchBar } from 'components';
import { fetchConversationsList } from 'services/conversation.service';
import { apiErrorHandler } from 'api/handler';
import { toast } from 'react-toastify';
import UnlinkChatInModal from 'components/Icons/UnlinkChat';
import { linkConversationTableHeaders } from './constants';

function UnlinkChatModal(props) {
  const { show, toggleModal, handleUpdate, data, triggerChild } = props;

  const [listData, setListData] = useState({
    totalDocs: 0,
    totalPages: 0,
    limit: 5,
    page: 1,
    headers: linkConversationTableHeaders,
    docs: [],
    sort: null,
    order: -1,
  });

  const [searchByInput, setSearchByInput] = React.useState();
  const [debouncedSearchBy] = useDebounce(searchByInput, 500);

  const [selectedRows, setSelectedRows] = useState<any>([]);

  useEffect(() => {
    if (data?.recordId) {
      fetchListData();
    }
  }, [data?.recordId, triggerChild, debouncedSearchBy]);

  async function fetchListData(page = 1) {
    try {
      const query = { ...listData, searchText: searchByInput, page };
      delete query.docs;
      const { data: fetchedData } = await fetchConversationsList({
        caseId: data?.recordId,
        searchText: searchByInput,
        fromLinkUnlink: 1,
      });
      if (fetchedData.node.docs) {
        if (fetchedData.node.page === 1) {
          setListData({
            ...listData,
            ...fetchedData.node,
            docs: fetchedData.node.docs,
          });
        } else {
          setListData({
            ...listData,
            docs: [...listData.docs, ...fetchedData.node.docs],
          });
        }
      }
    } catch (e) {
      const { message: exception } = apiErrorHandler(e);
      toast.error(exception);
    }
  }

  const handleTableSelectChange = (event, row) => {
    const { checked } = event.target;
    if (checked) {
      // If checked is true and row is not in selectedRows, add it
      if (!selectedRows.some((selectedRow) => selectedRow._id === row._id)) {
        setSelectedRows([...selectedRows, row]);
      }
    } else {
      // If checked is false and row is in selectedRows, remove it
      setSelectedRows(selectedRows.filter((selectedRow) => selectedRow._id !== row._id));
    }
  };

  const handleOnSearch = async (event) => {
    const { value } = event.target;
    setSearchByInput(value);
  };

  return (
    <Modal
      open={show}
      className="top-0 left-0 right-0 p-0 border border-gainsboroBlue rounded-lg z-50 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(auto-1rem)] max-w-3xl max-h-full"
    >
      <Modal.Header className="font-bold pb-2 border-b p-4">
        <div className="flex justify-between items-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 w-fit flex-wrap items-center">
            <div className="text-base font-bold font-ptScanCap whitespace-nowrap">
              Unlink Conversation
            </div>
          </div>
          <div className="text-sm font-normal bg-red">
            <Button
              size="xs"
              shape="circle"
              className="btn btn-xs btn-circle border-2 bg-white border-rasinBlack font-bold"
              onClick={toggleModal}
            >
              x
            </Button>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="pb-6 px-6 pt-3 justify-center items-center -mt-6">
          <div className="w-1/3 mb-4">
            <SearchBar
              placeholder="Search by Chat ID"
              searchByInput={searchByInput}
              onSearch={handleOnSearch}
            />
          </div>
          <CustomTable
            tableData={listData}
            showPageLimit={false}
            fixedHeight
            tableRowSelect={handleTableSelectChange}
            isPaginate={false}
          />
        </div>

        <div className="w-full pt-5 pb-5 mt-3 bg-culturedGray flex flex-wrap justify-center items-center gap-3">
          <PrimaryButton
            text="Unlink From Case"
            btnColor="bg-begoniaRed"
            btnHoverColor="hover:bg-begoniaRed"
            color="white"
            icon={<UnlinkChatInModal />}
            radius="2xl"
            handleOnClick={() => handleUpdate(selectedRows, true)}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

UnlinkChatModal.propTypes = {
  show: PropTypes.bool,
  toggleModal: PropTypes.func,
  handleUpdate: PropTypes.func,
  data: PropTypes.any,
  triggerChild: PropTypes.any,
};

export default UnlinkChatModal;
