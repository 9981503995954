import * as React from 'react';
import PropTypes from 'prop-types';

function APIRequestIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color = '#CD4E62', width = 45, height = 32 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 45 32"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M30.651 24C34.155 24 37 21.155 37 17.634a6.355 6.355 0 00-5.933-6.343v-.023C31.067 6.702 27.375 3 22.82 3c-3.801 0-6.991 2.586-7.947 6.09A7.459 7.459 0 008 16.535C8 20.66 11.331 24 15.446 24M26 27.5l-2.793 2.793a1 1 0 01-1.414 0L19 27.5"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M19 16.5l2.793-2.793a1 1 0 011.414 0L26 16.5"
      />

      <path
        stroke={color}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M22 24L23 24"
      />

      <path
        stroke={color}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M22 20L23 20"
      />
    </svg>
  );
}
APIRequestIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default APIRequestIcon;
