/* eslint-disable operator-linebreak */
import React from 'react';
import PropTypes from 'prop-types';

interface SecondaryButtonProps {
  text: string;
  btnColor: string;
  hoverTxtcolor: string;
  icon: any;
  width?: string;
  radius?: string;
  btnHoverColor?: string;
  btnSize?: string;
  textSize?: string;
  className?: string;
  handleOnClick?: any;
}

function SecondaryButton(props: SecondaryButtonProps) {
  const {
    text,
    btnColor,
    hoverTxtcolor,
    icon,
    width,
    radius,
    btnHoverColor,
    btnSize,
    textSize,
    className = '',
    handleOnClick,
  } = props;

  return (
    <div className="flex items-center">
      <button
        type="button"
        className={`btn btn-${btnSize} ${btnColor} ${className} font-ptScanCap ${width} text-independenceBlue capitalize rounded-md ${btnHoverColor} ${hoverTxtcolor}`}
        onClick={handleOnClick}
      >
        {icon}
        <span className={`text-${textSize} whitespace-nowrap`}>{text}</span>
      </button>
    </div>
  );
}

SecondaryButton.propTypes = {
  text: PropTypes.string,
  btnColor: PropTypes.string,
  hoverTxtcolor: PropTypes.string,
  icon: PropTypes.element.isRequired,
  width: PropTypes.string,
  btnHoverColor: PropTypes.string,
  btnSize: PropTypes.string,
  textSize: PropTypes.string,
  className: PropTypes.string,
  handleOnClick: PropTypes.func,
};

export default SecondaryButton;
