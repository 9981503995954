import _ from 'lodash';

import instance from 'api';
import { PERMISSIONS } from 'api/apiEndpoints';
import UrlBuilder from 'api/urlQueryBuilder';

export const getAllPermissions = () => {
  const url = UrlBuilder().setUrl(PERMISSIONS.GET_PERMISSIONS_LIST).build();
  return instance.get(url);
};
