import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-daisyui';

import { CustomSelect, PrimaryButton } from 'components';
import { Confirm } from 'components/Icons';
import { getValueForSelect } from 'utils/helpers';

function UpdateContactModal(props) {
  const { show, toggleModal, handleUpdate, actionStatus = false, data } = props;

  const { contactListData, formData: modalData } = data;

  const { sequenceId } = modalData;
  const [submitted, setSubmitted] = useState(false);

  const [formData, setFormData] = useState<any>({});

  // handle the dropdown change
  const handleSelectChange = (event: { value: any; other: any }, id: string) => {
    setFormData({
      ...formData,
      [id]: event?.value || null,
    });
    setSubmitted(false);
  };

  const onSubmit = () => {
    setSubmitted(true);

    if (formData.contactId) {
      handleUpdate(formData.contactId);
      setSubmitted(false);
    }
  };

  return (
    <Modal
      open={show}
      className="top-0 left-0 right-0 p-0 border border-gainsboroBlue rounded-lg z-50 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(auto-1rem)] max-w-2xl max-h-full"
    >
      <Modal.Header className="font-bold pb-2 border-b p-4">
        <div className="flex justify-between items-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 w-fit flex-wrap items-center">
            <div className="text-base font-bold font-ptScanCap whitespace-nowrap">
              Change the Contact for Case ID - {sequenceId}
            </div>
          </div>
          <div className="text-sm font-normal bg-red">
            <Button
              size="xs"
              shape="circle"
              className="btn btn-xs btn-circle border-2 bg-white border-rasinBlack font-bold"
              onClick={toggleModal}
            >
              x
            </Button>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="px-6 justify-center items-center -mt-6">
          {!actionStatus && (
            <div className="p-4 grid grid-cols-1">
              <span className="text-sm font-normal font-lato pb-3 pl-1">
                Search and Select a Contact
              </span>
              <CustomSelect
                id="contactId"
                required
                isClearable
                placeholder="Type name, phone number, email to search a contact"
                options={contactListData}
                value={getValueForSelect(formData?.contactId, contactListData)}
                onChange={(event) => handleSelectChange(event, 'contactId')}
              />
              {submitted && !formData.contactId && (
                <label className="label relative p-y-2 mt-2">
                  <span className="label-text-alt absolute text-error">
                    Select Contact to confirm
                  </span>
                </label>
              )}
            </div>
          )}
        </div>
        <div className="w-full pt-5 pb-5 mt-3 bg-culturedGray flex flex-wrap justify-center items-center gap-3">
          <PrimaryButton
            text="Confirm"
            btnColor="bg-spaceCadetBlue80"
            btnHoverColor="hover:bg-spaceCadetBlue"
            color="white"
            icon={<Confirm />}
            radius="2xl"
            type="button"
            handleOnClick={onSubmit}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

UpdateContactModal.propTypes = {
  show: PropTypes.bool,
  actionStatus: PropTypes.bool,
  toggleModal: PropTypes.func,
  handleUpdate: PropTypes.func,
  data: PropTypes.any,
};

export default UpdateContactModal;
