export enum MessengerConnectedVia {
  WebsiteWidget = 'websiteWidget',
}

export enum MessengerSource {
  AWSLex = 'AWS_Lex',
}

export enum InstanceType {
  Chatbot = 'Chatbot',
  DirectMessaging = 'directMessaging',
}

export enum WelcomeMessageOption {
  DoNotSend = 'DoNotSend',
  Send = 'Send',
}

export enum CollectContactInfoVia {
  Form = 'form',
  Conversation = 'conversation',
}

export enum CollectCustomerFeedbackVia {
  Form = 'form',
  Conversation = 'conversation',
}
