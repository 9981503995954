import * as React from 'react';
import PropTypes from 'prop-types';

function Star(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color, fill, width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={width}
      fill={fill}
      viewBox="0 0 22 21"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        d="M10.993 16.748l-6.172 3.245L6 13.12 1 8.253l6.9-1L10.986 1l3.086 6.253 6.9 1-5 4.867 1.179 6.873-6.158-3.245z"
      />
    </svg>
  );
}
Star.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Star;
