/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ArrowDown, ArrowUp } from 'components/Icons';

import { getUserType } from 'utils/auth';
import { applyFormats } from './utils';
import Pagination from './custom-pagination/Pagination';

function CustomTable(props: {
  tableData: any;
  isPaginate?: boolean;
  loading?: { list: boolean };
  fixedHeight?: boolean;
  showPageLimit?: boolean;
  handlers: any;
  onSort: any;
  onPageChange: any;
  handleResultsPerPageChange: any;
  onManage: any;
  onEdit?: any;
  onDelete?: any;
  onPreviewAction: any;
  handleEdit: any;
  onReachEnd: any;
  tableRowSelect: any;
}) {
  const {
    tableData,
    isPaginate = true,
    loading = { list: false },
    fixedHeight = false,
    showPageLimit = true,
    handlers,
    onSort, // column wise sorting(table header)
    onPageChange,
    handleResultsPerPageChange,
    onManage, // quick action(eg: Manage button)
    onEdit,
    onDelete,
    onPreviewAction,
    handleEdit,
    onReachEnd,
    tableRowSelect,
  } = props;

  // handler props
  const handlerProps = {
    onManage,
    onEdit,
    onDelete,
    onPreviewAction,
    tableRowSelect,
    ...handlers,
  };

  // holds the column and sorted order
  const [isUp, setIsUp] = useState({});

  const tableRef = useRef(null);

  // Use the useEffect hook to add a scroll event listener
  useEffect(() => {
    const lastScrollLeft = 0;

    const handleScroll = () => {
      const tableBody = tableRef.current;
      if (tableBody) {
        // Check if the user has scrolled to the bottom
        const isAtBottom =
          tableBody.scrollTop + tableBody.clientHeight + 1 >= tableBody.scrollHeight;

        // Check if there's vertical scrolling available
        const isVerticalScrollingAvailable = tableBody.scrollHeight > tableBody.clientHeight;

        if (isAtBottom && isVerticalScrollingAvailable && onReachEnd) {
          onReachEnd();
        }
      }
    };
    tableRef?.current?.addEventListener('scroll', handleScroll);
    return () => {
      tableRef?.current?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  /**
   * Sort results based on column value ascending or descending order
   * @param {object} header - Include the key, value and other params
   */
  const handleHeaderClick = (header: { key: string | number }) => {
    // ascending order or descending order
    const isDescending = isUp[header.key];

    if (onSort) {
      onSort(!!isDescending, header.key);
    }
    setIsUp({
      [header.key]: !isDescending,
    });
  };

  // Generates a table row element
  const buildTableRow = (row: { [x: string]: any; _id: any }, i: any) => (
    <tr
      key={row._id || `key_q_${i}`}
      onClick={(e) => {
        if (handleEdit) {
          handleEdit(e, row);
        }
      }}
      className="bg-white hover:bg-aliceBlue"
    >
      {tableData?.headers.map(
        (header: { key: string | number; value: any; tdStyles?: any }, k: any) => (
          <td
            className={classNames(
              'text-rasinBlack font-normal text-start font-lato cursor-pointer',
              header.tdStyles || '',
            )}
            key={`row_${k}`}
          >
            <div>{applyFormats(row[header.key], header, row, handlerProps, i)}</div>
          </td>
        ),
      )}
    </tr>
  );

  return (
    <div className="grid">
      <table
        className={classNames(
          'table table-pin-rows custom_table w-full table-layout_custom-scrollbar',
          {
            'max-h-80': fixedHeight,
          },
        )}
        ref={tableRef}
        style={{ minHeight: 200 }}
      >
        <thead>
          <tr className="bg-primary text-white text-base font-bold font-ptScanCap -z-0">
            {tableData?.headers.map(
              (header: {
                key: string | number;
                sortingEnabled: any;
                value:
                  | string
                  | number
                  | boolean
                  | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                  | React.ReactPortal;
                allowedUserTypes: string[];
              }) => {
                const isAllowedUser = header?.allowedUserTypes?.includes(getUserType());

                return (
                  (!header?.allowedUserTypes || isAllowedUser) && (
                    <th
                      key={header.key}
                      className="py-6 text-start"
                      style={{ width: '100vh' }} // Set equal width
                    >
                      <div className="flex">
                        <div>{header.value}</div>
                        {header.sortingEnabled && (
                          <div
                            className="ml-2 cursor-pointer"
                            onClick={
                              header.sortingEnabled ? () => handleHeaderClick(header) : undefined
                            }
                            role="button"
                          >
                            {isUp[header.key] ? (
                              <ArrowUp color="#fff" />
                            ) : (
                              <ArrowDown color="#fff" />
                            )}
                          </div>
                        )}
                      </div>
                    </th>
                  )
                );
              },
            )}
          </tr>
        </thead>

        {tableData?.docs?.length > 0 ? (
          <tbody>{tableData?.docs.map((row: any, i: any) => buildTableRow(row, i))}</tbody>
        ) : (
          <tbody style={{ height: '200px' }}>
            <tr>
              <td
                colSpan={tableData.headers.length}
                style={{ verticalAlign: 'middle', textAlign: 'center' }}
              >
                {loading.list ? (
                  <span className="loading loading-infinity loading-lg" />
                ) : (
                  'No Results Found'
                )}
              </td>
            </tr>
          </tbody>
        )}
      </table>
      {isPaginate && showPageLimit && (
        <Pagination
          currentPage={tableData.page}
          totalCount={tableData.totalDocs || tableData.matchedCount}
          pageSize={tableData.limit}
          onPageChange={onPageChange}
          handleResultsPerPageChange={handleResultsPerPageChange}
          showPageLimit={showPageLimit}
        />
      )}
      {!isPaginate && <div className="bg-gainsboroBlue rounded-b-xl h-[23px]" />}
    </div>
  );
}

CustomTable.propTypes = {
  tableData: PropTypes.object,
  isPaginate: PropTypes.bool,
  loading: PropTypes.any,
  handleEdit: PropTypes.func,
  onPageChange: PropTypes.func, // pagination page change
  onSort: PropTypes.func,
  showPageLimit: PropTypes.bool,
  fixedHeight: PropTypes.bool,
  onReachEnd: PropTypes.any,
  handleResultsPerPageChange: PropTypes.func,
  onManage: PropTypes.func,
  onPreviewAction: PropTypes.func,
  handlers: PropTypes.any,
  tableRowSelect: PropTypes.func,
};

export default CustomTable;
