import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from './tempIcons/Close';

function QuickReplyBadge(props) {
  const { id, setQuickReplies, reply, old, handleUpdateQuickReplies } = props;

  const handleRemoveBadge = () => {
    setQuickReplies((prev) => ({
      ...prev,
      replies: prev.replies.filter((obj) => obj.id !== id),
    }));
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={`badge badge-outline ${
        old ? 'border-gray-400' : 'border-blue-500'
      } gap-2 mr-2 p-1 px-3 mt-2`}
      style={{ height: 'auto' }}
      onClick={() => {
        handleUpdateQuickReplies(id);
      }}
    >
      <p className={`max-w-[150px] break-words ${old ? 'text-gray-400' : 'text-blue-500'}`}>
        {reply}
      </p>
      <button type="button" onClick={handleRemoveBadge}>
        <CloseIcon />
      </button>
    </div>
  );
}

QuickReplyBadge.propTypes = {
  reply: PropTypes.string,
  id: PropTypes.any,
  setQuickReplies: PropTypes.func,
  old: PropTypes.bool,
  handleUpdateQuickReplies: PropTypes.func,
};

export default QuickReplyBadge;
