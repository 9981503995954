import React from 'react';
import PropTypes from 'prop-types';

function MessengerStatus(props: { isActive: boolean }) {
  const { isActive } = props;

  if (!isActive) {
    return (
      <div className="badge bg-coralRed text-white font-extrabold font-lato p-3 text-center items-center text-xs border-none">
        INACTIVE
      </div>
    );
  }

  if (isActive) {
    return (
      <div className="badge bg-northTexasGreen text-white font-extrabold font-lato p-3 text-center items-center text-xs border-none">
        ACTIVE
      </div>
    );
  }

  return <div />;
}

MessengerStatus.propTypes = {
  isActive: PropTypes.bool,
};

export default MessengerStatus;
