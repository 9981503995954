import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Handle, Position } from 'reactflow';

import { Delete } from 'components/Icons';
import Copy from 'components/Icons/Copy';
import { startNodeStyle } from './constants';
import FlowNode from './Nodes/FlowNode';
import APIRequestNode from './Nodes/APIRequestNode/APIRequestNode';
import FilterNode from './Nodes/FilterNode/FilterNode';
import AddContactNode from './Nodes/AddContactNode';
import MessageNode from './Nodes/Message/MessageNode';
import AddCaseNode from './Nodes/AddCaseNode';
import ActionNode from './Nodes/Action/ActionNode';
import CarouselNode from './Nodes/CarouselNode/CarouselNode';
import LiveChatNode from './Nodes/LiveChatNode';
import EndChatNode from './Nodes/EndChatNode';

function CustomNode(props) {
  const { data, id, selected } = props;

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {id <= 1 && (
        <div className="origin-node bg-[#31c178] pl-3 pr-6 py-1 rounded-3xl w-[120px] text-lg text-white font-semibold text-center items-center drop-shadow-xl">
          {data?.heading}
          <Handle
            type="source"
            position={Position.Right}
            id="start"
            className=" !bg-blue-500"
            style={startNodeStyle}
          />
        </div>
      )}

      {id > 1 && data?.heading === 'Message' && (
        <MessageNode id={id} data={data} selected={selected} />
      )}
      {id > 1 && data?.heading === 'Flow' && <FlowNode id={id} data={data} selected={selected} />}
      {id > 1 && data?.heading === 'Action' && (
        <ActionNode id={id} data={data} selected={selected} />
      )}
      {id > 1 && data?.heading === 'APIRequest' && (
        <APIRequestNode id={id} data={data} selected={selected} />
      )}
      {id > 1 && data?.heading === 'Filter' && (
        <FilterNode id={id} data={data} selected={selected} />
      )}
      {id > 1 && data?.heading === 'AddContact' && (
        <AddContactNode id={id} data={data} selected={selected} />
      )}
      {id > 1 && data?.heading === 'AddCase' && (
        <AddCaseNode id={id} data={data} selected={selected} />
      )}
      {id > 1 && data?.heading === 'Carousel' && (
        <CarouselNode id={id} data={data} selected={selected} />
      )}
      {id > 1 && data?.heading === 'LiveChat' && (
        <LiveChatNode id={id} data={data} selected={selected} />
      )}
      {id > 1 && data?.heading === 'EndChat' && (
        <EndChatNode id={id} data={data} selected={selected} />
      )}

      {id > 1 && selected && isHovered && (
        <div className="space-x-2 text-right p-2">
          <button
            type="button"
            id="node-copy-btn"
            className="p-2 rounded-md bg-white drop-shadow-xl"
            onClick={(e) => {
              // Stop the event from propagating to the parent node
              e.stopPropagation();
              data?.onCopy(data, id);
            }}
          >
            <Copy id="node-copy-icon" />
          </button>
          <button
            type="button"
            id="node-delete-btn"
            className="p-2 rounded-md bg-white drop-shadow-xl"
            onClick={(e) => {
              // Stop the event from propagating to the parent node
              e.stopPropagation();
              data?.onDelete(id);
            }}
          >
            <Delete id="node-delete-icon" color="#FF7171" />
          </button>
        </div>
      )}
    </div>
  );
}

CustomNode.propTypes = {
  data: PropTypes.any,
  id: PropTypes.string,
  selected: PropTypes.bool,
};

export default CustomNode;
