import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { ICarouselSetting, ISlide } from '../../MessageDrawer/interfaces';

interface ISlideTitleProps {
  slideNumber: number;
  componentData: ISlide;
  setCarouselSettings: React.Dispatch<React.SetStateAction<ICarouselSetting>>;
}

function SlideTitle(props: ISlideTitleProps) {
  const { slideNumber, componentData, setCarouselSettings } = props;

  const [isTyping, setIsTyping] = useState(false);

  const handleFocus = () => {
    setIsTyping(true);
  };

  const handleBlur = () => {
    setIsTyping(false);
  };

  /**
   * Handle Slide Title change
   */
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const newText = event.target.value;

      setCarouselSettings((prev) => {
        const updatedSlides = prev.slides.map((slidesArray, index) => {
          if (index === slideNumber) {
            return slidesArray.map((slide) => {
              if (slide.id === componentData.id) {
                return { ...slide, text: newText };
              }
              return slide;
            });
          }
          return slidesArray;
        });

        return { ...prev, slides: updatedSlides };
      });
    },
    [componentData.id, setCarouselSettings, slideNumber],
  );

  /**
   * Handle Enable Title Change
   */
  const handleCheckboxChange = useCallback(() => {
    setCarouselSettings((prev) => {
      const updatedSlides = prev.slides.map((slidesArray, index) => {
        if (index === slideNumber) {
          return slidesArray.map((slide) => {
            if (slide.id === componentData.id) {
              return { ...slide, isEnabled: !slide.isEnabled };
            }
            return slide;
          });
        }
        return slidesArray;
      });

      return { ...prev, slides: updatedSlides };
    });
  }, [componentData.id, setCarouselSettings, slideNumber]);

  const getBorderColor = () => {
    const { text, isEnabled } = componentData;

    if (text === '' && !isTyping && isEnabled) {
      return 'border-red-300'; // Red border for empty text area
    }
    if (isTyping) {
      return 'border-green-300'; // Green border when typing
    }
    return 'border-gray-300'; // Gray border for non-empty and not typing
  };

  return (
    <>
      <div className="flex title items-center">
        <input
          type="checkbox"
          className="checkbox"
          checked={componentData.isEnabled}
          onChange={handleCheckboxChange}
        />
        <span className="title-text">Slide Title</span>
      </div>
      <div className="title-msg slide-title-msg">
        <textarea
          className={`w-full rounded-t-md border-[1px] focus:outline-none p-2 relative flex-grow ${getBorderColor()}`}
          value={componentData.text || ''}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder="Type your message"
          disabled={!componentData.isEnabled}
        />
      </div>
    </>
  );
}

SlideTitle.propTypes = {
  slideNumber: PropTypes.number,
  componentData: PropTypes.object,
  setCarouselSettings: PropTypes.func,
};

export default SlideTitle;
