import React from 'react';
import ReactDom from 'react-dom';
import PropTypes from 'prop-types';
import { Drawer } from 'react-daisyui';

import { Close } from 'components/Icons';
import UserLayout from '../Chat/containers/UserLayout';

function InboxContactPreview(props: any) {
  const {
    visible,
    toggleVisible,
    handleOnUpdateContact,
    triggerChild,
    setChatFormData,
    contactData,
  } = props;
  return ReactDom.createPortal(
    <Drawer
      end
      open={visible}
      onClickOverlay={toggleVisible}
      side={
        <div className="w-[500px] bg-culturedGray justify-center min-h-screen">
          <div className="w-full p-[27px] bg-primary flex flex-wrap justify-between items-center">
            <span className="font-ptScanCap text-md font-semibold text-white">Preview Contact</span>
            <div className="cursor-pointer">
              <Close width={26} height={26} onClick={toggleVisible} />
            </div>
          </div>
          <div className="p-3">
            <UserLayout
              toggleTemplateModal={handleOnUpdateContact}
              triggerChild={triggerChild}
              setChatFormData={setChatFormData}
              contactData={contactData}
            />
          </div>
        </div>
      }
    />,
    document.body,
  );
}

InboxContactPreview.propTypes = {
  visible: PropTypes.bool,
  toggleVisible: PropTypes.func,
  handleOnUpdateContact: PropTypes.func,
  triggerChild: PropTypes.object,
  contactData: PropTypes.object,
  setChatFormData: PropTypes.func,
};

export default InboxContactPreview;
