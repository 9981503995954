import * as React from 'react';
import PropTypes from 'prop-types';

function UploadImage(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color, width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 24 24">
      <g
        stroke="#9F9F9F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        clipPath="url(#clip0_31_80)"
      >
        <path d="M15 8h.01M12.5 21H6a3 3 0 01-3-3V6a3 3 0 013-3h12a3 3 0 013 3v6.5" />
        <path d="M3 16l5-5c.928-.893 2.072-.893 3 0l4 4" />
        <path d="M14 14l1-1c.67-.644 1.45-.824 2.182-.54M16 19h6M19 16v6" />
      </g>
      <defs>
        <clipPath id="clip0_31_80">
          <path fill="#fff" d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
UploadImage.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default UploadImage;
