/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { CustomTable, PrimaryButton, PromptModal } from 'components';
import { AddIcon } from 'components/Icons';
import { apiErrorHandler } from 'api/handler';
import {
  linkChannelsToMessenger,
  unlinkChannelFromMessenger,
  getLinkedChannels,
} from 'services/messenger.service';

import {
  ModalTypes,
  PromptTypes,
  linkedChannelsTableHeaders,
  modalDefaultOptions,
  promptDefaultOptions,
} from '../constants';
import LinkChannelModal from './LinkChannelModal';

function LinkedChannels(props) {
  const { formData, triggerChild } = props;

  const [modalOptions, setModalOptions] = useState(modalDefaultOptions);
  const [promptOptions, setPromptOptions] = useState(promptDefaultOptions);

  const [listData, setListData] = useState({
    docs: [],
    headers: linkedChannelsTableHeaders,
  });

  const [customLoading, setCustomLoading] = useState({
    fetchList: false,
    unlinkChannel: false,
  });

  // Fetch linked channels when the component mounts
  useEffect(() => {
    fetchLinkedChannels();
  }, [triggerChild]);

  const onClickLinkChannel = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    setModalOptions((prevState) => ({
      ...prevState,
      show: true,
      actionStatus: false,
      type: ModalTypes.LinkChannel,
      modalData: { recordId: formData._id },
    }));
  };

  // Function to handle linking a channel
  const handleLinkChannel = async (data: any) => {
    try {
      if (data.length === 0) {
        toast.warn('Select Channel To Proceed');
        return;
      }

      await linkChannelsToMessenger(formData._id, { channels: data.map((val: any) => val._id) });
      setModalOptions({ ...modalOptions, show: false });
      toast.success('Successfully Linked Channel');
      await fetchLinkedChannels();
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    }
  };

  const handleUnlinkChannel = async (data: any) => {
    try {
      const { channelId } = data;
      await unlinkChannelFromMessenger(formData._id, channelId);
      await fetchLinkedChannels();
      setPromptOptions(promptDefaultOptions);
      toast.success('Successfully Unlinked Channel');
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    }
  };

  // Function to handle unlinking a channel
  const onUnlinkChannel = async (data: any) => {
    try {
      const { channelName } = data;
      setPromptOptions({
        ...promptOptions,
        show: true,
        title: 'Unlink Channel',
        message: `Do you want to unlink ${channelName} channel from the ${formData.name}`,
        onConfirm: () => handleUnlinkChannel(data),
        type: PromptTypes.ToggleInstance,
        data,
      });
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    }
  };

  const fetchLinkedChannels = async () => {
    try {
      setCustomLoading((prevState) => ({ ...prevState, fetchList: true }));
      const { data } = await getLinkedChannels(formData._id);
      if (data?.nodes) {
        setListData({
          ...listData,
          docs: data.nodes.map((val) => ({ ...val, channelName: val?.channel?.channelName })),
        });
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({ ...prevState, fetchList: false }));
    }
  };

  // used to get updated state for loading
  function loadDynamicLoadingProp() {
    if (promptOptions.type === PromptTypes.UnlinkChannel) {
      return customLoading.unlinkChannel;
    }
    return false;
  }

  return (
    <>
      <div className="p-5 bg-white drop-shadow rounded-xl mt-6">
        <div className="flex flex-row justify-between items-center">
          <h2 className="text-spaceCadetBlue font-bold font-ptScanCap text-base srg:text-xl">
            Link Channels
          </h2>
          <PrimaryButton
            id="linkChannelBtn"
            text="Link Channels"
            btnColor="bg-spaceCadetBlue80"
            btnHoverColor="hover:bg-spaceCadetBlue"
            color="white"
            icon={<AddIcon />}
            width="[100px]"
            textSize="sm font-bold font-ptScanCap"
            radius="2xl"
            handleOnClick={onClickLinkChannel}
            className="py-[5px] pr-[15px] pl-[10px]"
          />
        </div>
        <hr className="my-4" />

        <CustomTable
          tableData={listData}
          isPaginate={false}
          loading={{ list: customLoading.fetchList }}
          handlers={{ onUnlinkChannel }}
        />
      </div>

      {modalOptions.type === ModalTypes.LinkChannel && modalOptions.show && (
        <LinkChannelModal
          handleConfirm={handleLinkChannel}
          {...modalOptions}
          toggleModal={() => setModalOptions({ ...modalOptions, show: false })}
        />
      )}

      {promptOptions.show && (
        <PromptModal
          onCancel={() => {
            setPromptOptions({ ...promptOptions, show: false });
          }}
          {...promptOptions}
          loading={loadDynamicLoadingProp()}
        />
      )}
    </>
  );
}

LinkedChannels.propTypes = {
  formData: PropTypes.object,
  triggerChild: PropTypes.string,
};

export default LinkedChannels;
