/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable function-paren-newline */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Edge, MarkerType, Node, addEdge, useEdgesState, useNodesState } from 'reactflow';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import { isEqual, uniqueId } from 'lodash';
import { useSearchParams } from 'react-router-dom';

import {
  initializeFilterSettings,
  initializeMessageSettings,
  initializeAPIRequestSettings,
  initializeActionSettings,
  initializeFlowSettings,
  initializeContactSettings,
  initializeCaseSettings,
  initializeNodes,
  initializeEdges,
  updatedNodes,
  deleteNode,
  initializeCarouselSettings,
  initializeFlow,
  duplicateNode,
  deleteEdge,
  initializeLiveChatSettings,
  initializeEndChatSettings,
  handleFlowSaveStatus,
} from 'slices/flow';
import { RootState } from 'slices';
import { hideSideBar, showSideBar } from 'slices/layout';
import { getFlowByIntent, updateMessengerFlow } from 'services/messengerFlow.service';
import { apiErrorHandler } from 'api/handler';

import { IShowDrawer } from './interfaces';
import FlowBuilderPage from './page';
import { NODE_TYPES } from './components/constants';
import FlowPrompt from './components/Modals/FlowPrompt';

function FlowBuilder() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const flowData = useSelector((state: RootState) => state.flow);

  // drawer
  const [showDrawer, setShowDrawer] = useState<IShowDrawer>({
    show: false,
    data: null,
  });

  // ReactFlow
  const reactFlowWrapperRef = useRef(null);
  // array containing information about nodes, such as its position, size, and any additional data
  const [nodes, setNodes, onNodesChange] = useNodesState<Node<any, string>[]>([
    ...flowData.flowPath.nodes,
  ]);
  // holds information about edges, connection
  const [edges, setEdges, onEdgesChange] = useEdgesState([...flowData.flowPath.edges]);
  // instance of the React Flow library
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  // settings for the draft modal
  const [promptOptions, setPromptOptions] = useState<any>({});

  // header data
  const [headerData, setHeaderData] = useState({
    intentName: '',
    updatedAt: '',
    updatedBy: '',
    messengerId: '',
  });

  // state which keeps random drawer key
  const [drawerKey, setDrawerKey] = useState(0);

  // show the delete modal
  const [visible, setVisible] = useState(false);
  // state holds that selected edge
  const [selectEdge, setSelectEdge] = useState();

  const pageId = searchParams.get('i');

  // An event listener when the component mounts
  useEffect(() => {
    document.addEventListener('keydown', handleEscapeKey);
    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [handleEscapeKey]);

  useEffect(() => {
    dispatch(hideSideBar());
    return () => {
      dispatch(showSideBar());
    };
  }, []);

  useEffect(() => {
    if (flowData.flowPath.nodes.length > 0) {
      const updatedArray: any = flowData.flowPath.nodes.map((obj) => ({
        ...obj,
        data: {
          ...obj.data,
          onDelete: handleDelete,
          onCopy: handleCopy,
        },
      }));

      setNodes([...updatedArray]);
    }
    if (flowData.flowPath.edges.length > 0) {
      const updatedArray: any = flowData.flowPath.edges.map((obj) => ({
        ...obj,
        markerEnd: { type: MarkerType.ArrowClosed, color: '#3B82F6' },
        style: { strokeWidth: 2, stroke: '#3B82F6' },
      }));

      setEdges([...updatedArray]);
    }
  }, [flowData.flowPath.nodes]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!flowData.flowIsSaved) {
        const message = 'Are you sure you want to leave? Your changes may not be saved.';
        event.returnValue = message; // Standard for most browsers
        return message; // For some older browsers
      }
      return undefined;
    };

    // Add the event listener when the component mounts
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [flowData.flowIsSaved]);

  useEffect(() => {
    if (pageId) {
      fetchFlowData(pageId);
    }
  }, [pageId]);

  /**
   *Toggle drawer visibility
   */
  const toggleDrawer = () => {
    setShowDrawer({ ...showDrawer, show: !showDrawer.show });
  };

  const fetchFlowData = async (id: string) => {
    try {
      const { data } = await getFlowByIntent(id);
      if (data?._id) {
        setHeaderData({
          updatedBy: data?.editorId?.name,
          updatedAt: data?.lastUpdated?.at
            ? moment(data?.lastUpdated?.at, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD - hh:mm:ss A')
            : null,
          intentName: data?.intent?.displayIntentName,
          messengerId: data?.messengerId,
        });

        // Check if the two objects are equal
        const areNodeConfigsEqual = isEqual(data?.draft?.nodeConfigs, data?.saved?.nodeConfigs);
        const areFlowPathEqual = isEqual(data?.draft?.flowPath, data?.saved?.flowPath);

        if ((!areFlowPathEqual || !areNodeConfigsEqual) && data?.draft?.flowPath) {
          handleFlowPrompt(data);
        } else {
          const buildStateData = {
            intentId: data?.intentId,
            flowPath: data?.saved?.flowPath,
            nodeConfigs: data?.saved?.nodeConfigs,
            variables: data?.saved?.variables,
          };

          setNodes([...buildStateData?.flowPath.nodes]);
          setEdges([...buildStateData?.flowPath.edges]);
          dispatch(initializeFlow({ ...buildStateData }));
        }
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    }
  };

  const handleDelete = (nodeId: string) => {
    const connectedEdge = flowData.flowPath.edges.find((edge) => edge.source === '1');
    // can not able to delete the first node
    if (connectedEdge.target === nodeId) {
      toast.error('Not allowed to delete the initial node');
    } else {
      // Remove the current node from the nodes array
      setNodes((prevNodes) => prevNodes.filter((node) => node.id !== nodeId));
      dispatch(deleteNode({ nodeId }));
      // close the drawer
      setShowDrawer({ ...showDrawer, show: false });
    }
  };

  const handleCopy = (data, nodeId: string) => {
    const id = uniqueId();
    // Create a copy of the current node
    const copyNode: any = {
      id, // Generate a unique ID for the new node
      nodeId,
      type: 'customNode',
      data: {
        heading: data?.heading,
        content: data?.content,
        onDelete: handleDelete,
        onCopy: handleCopy,
      },
      position: { x: 250, y: 250 },
    };

    // Update the nodes array with the new node
    setNodes((prevNodes) => [...prevNodes, copyNode]);
    dispatch(duplicateNode(copyNode));
  };

  // Function to add a new node
  const addNewNode = (nodeType) => {
    const newNode: Node<any, string> = {
      id: uniqueId(), // Generate a unique ID for the new node
      type: 'customNode',
      data: {
        heading: nodeType,
        content: null,
        onDelete: handleDelete,
        onCopy: handleCopy,
      },
      position: { x: 100, y: 100 }, // Set the initial position of the new node
    };

    nodeSettingsDispatch(newNode);

    // dispatch the position and other data of nodes
    dispatch(initializeNodes(newNode));

    // Update the nodes array with the new node
    setNodes((prevNodes) => [...prevNodes, newNode]);
  };

  // runs when edge connects to node
  const onConnect = (params) => {
    const id = uniqueId();
    // gets the source node and source handler
    const { source, sourceHandle } = params;
    // if source node and handler already exists in edges array
    const alreadyConnectedEdge = flowData.flowPath.edges.find(
      (edge) => edge.source === source && edge.sourceHandle === sourceHandle,
    );

    setEdges((eds) =>
      addEdge(
        {
          ...params,
          id,
          markerEnd: { type: MarkerType.ArrowClosed, color: '#3B82F6' },
          style: { strokeWidth: 2, stroke: '#3B82F6' },
        },
        eds,
      ),
    );

    dispatch(
      initializeEdges({
        id,
        ...params,
      }),
    );

    // if source or source handler already have connection remove that connection
    if (alreadyConnectedEdge) {
      onDeleteEdge(alreadyConnectedEdge);
    }
  };

  const onInit = (flowInstance) => setReactFlowInstance(flowInstance);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      const reactFlowBounds = reactFlowWrapperRef.current.getBoundingClientRect();
      const nodeType = event.dataTransfer.getData('application/reactflow');
      // check if the dropped element is valid
      if (typeof nodeType === 'undefined' || !nodeType) {
        return;
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });

      const newNode: Node<any, string> = {
        id: uniqueId(), // Generate a unique ID for the new node
        type: 'customNode',
        data: {
          heading: nodeType,
          content: '',
          onDelete: handleDelete,
          onCopy: handleCopy,
        },
        position,
      };

      nodeSettingsDispatch(newNode);

      // Extract serializable data from newNode
      const { onDelete, onCopy, ...serializableData } = newNode.data;

      // Create a new node object with serializable data
      const serializableNode = {
        id: newNode.id,
        type: newNode.type,
        data: { ...serializableData },
        position: newNode.position,
      };
      // Dispatch the position and other serializable data of nodes
      dispatch(initializeNodes(serializableNode));

      // setNodes((nds) => nds.concat(newNode));
      setNodes((prevNodes) => [...prevNodes, newNode]);
    },
    [reactFlowInstance],
  );

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  };

  const proOptions = { hideAttribution: true };

  /**
   * Handle ReactFlow node click event and show drawer
   * @param {React.MouseEvent} e - Click event
   * @param {Node} node - ReactFlow Node data
   */
  const handleNodeClick = (e: React.MouseEvent, node: Node) => {
    e.preventDefault();

    // don't open the drawer for start node
    if (node && node?.data?.heading === 'Start') {
      return;
    }

    // set selected node detail
    setShowDrawer({
      data: {
        id: node?.id,
        title: NODE_TYPES[node?.data?.heading]?.label,
      },
      show: true,
    });
    /** when we opens the same node twice for the second time it wont renders
     * here im randomly changing the key of the drawer so that it force to rerender
     * every time it opens
     */
    setDrawerKey(Math.random());
  };

  /**
   * Close the drawer when Esc key is pressed
   */
  function handleEscapeKey(event) {
    if (event.keyCode === 27) {
      setShowDrawer({ ...showDrawer, show: false });
    }
  }

  /**
   * Dispatch the node data to relevant redux slice
   * @param {Node} node - ReactFlow Node data
   */
  const nodeSettingsDispatch = (node) => {
    // Extract serializable data from newNode
    const { onDelete, onCopy, ...serializableData } = node.data;

    // Create a new node object with serializable data
    const serializableNode = {
      id: node.id,
      type: node.type,
      data: { ...serializableData },
      position: node.position,
    };

    if (node?.data?.heading === NODE_TYPES.Message.id) {
      dispatch(initializeMessageSettings(serializableNode));
    } else if (node?.data?.heading === NODE_TYPES.Flow.id) {
      const nodePayload = {
        ...node,
        data: { heading: node.data.heading, content: node.data.content },
      };
      dispatch(initializeFlowSettings(nodePayload));
    } else if (node?.data?.heading === NODE_TYPES.APIRequest.id) {
      // only pass data without functions
      const nodePayload = {
        ...node,
        data: { heading: node.data.heading, content: node.data.content },
      };

      dispatch(initializeAPIRequestSettings(nodePayload));
    } else if (node?.data?.heading === NODE_TYPES.AddContact.id) {
      // only pass data without functions
      const nodePayload = {
        ...node,
        data: { heading: node.data.heading, content: node.data.content },
      };

      dispatch(initializeContactSettings(nodePayload));
    } else if (node?.data?.heading === NODE_TYPES.Action.id) {
      dispatch(initializeActionSettings(serializableNode));
    } else if (node?.data?.heading === NODE_TYPES.AddCase.id) {
      // only pass data without functions
      const nodePayload = {
        ...node,
        data: { heading: node.data.heading, content: node.data.content },
      };
      dispatch(initializeCaseSettings(nodePayload));
    } else if (node?.data?.heading === NODE_TYPES.Filter.id) {
      // only pass data without functions
      const nodePayload = {
        ...node,
        data: { heading: node.data.heading, content: node.data.content },
      };
      dispatch(initializeFilterSettings(nodePayload));
    } else if (node?.data?.heading === NODE_TYPES.Carousel.id) {
      const nodePayload = {
        ...node,
        data: { heading: node.data.heading, content: node.data.content },
      };

      dispatch(initializeCarouselSettings(nodePayload));
    } else if (node?.data?.heading === NODE_TYPES.LiveChat.id) {
      const nodePayload = {
        ...node,
        data: { heading: node.data.heading, content: node.data.content },
      };
      dispatch(initializeLiveChatSettings(nodePayload));
    } else if (node?.data?.heading === NODE_TYPES.EndChat.id) {
      const nodePayload = {
        ...node,
        data: { heading: node.data.heading, content: node.data.content },
      };
      dispatch(initializeEndChatSettings(nodePayload));
    }
  };

  const onNodeDragStop = (e: React.MouseEvent, node: Node) => {
    e.preventDefault();

    // Extract serializable data from newNode
    const { onDelete, onCopy, ...serializableData } = node.data;

    // Create a new node object with serializable data
    const serializableNode = {
      id: node.id,
      type: node.type,
      data: { ...serializableData },
      position: node.position,
    };
    dispatch(updatedNodes(serializableNode));
  };

  // handle flow prompt
  const handleFlowPrompt = (data) => {
    setPromptOptions({
      show: true,
      title: 'Draft',
      message: 'You have a draft for this flow',
      actionButtons: { firstButton: 'Load draft', secondButton: 'Delete draft and continue' },
      onConfirm: () => handleConfirm(data),
      onCancel: () => handleDeclinedDraft(data),
    });
  };

  const handleConfirm = (data) => {
    const buildStateData = {
      intentId: data?.intentId,
      flowPath: data?.draft?.flowPath,
      nodeConfigs: data?.draft?.nodeConfigs,
      variables: data?.draft?.variables,
    };

    setNodes([...buildStateData?.flowPath.nodes]);
    setEdges([...buildStateData?.flowPath.edges]);
    dispatch(initializeFlow({ ...buildStateData }));
    setPromptOptions({ ...promptOptions, show: false });
  };

  const handleDeclinedDraft = async (data) => {
    const buildStateData = {
      intentId: data?.intentId,
      flowPath: data?.saved?.flowPath,
      nodeConfigs: data?.saved?.nodeConfigs,
      variables: data?.saved?.variables,
    };
    setNodes([...buildStateData?.flowPath.nodes]);
    setEdges([...buildStateData?.flowPath.edges]);
    dispatch(initializeFlow({ ...buildStateData }));
    setPromptOptions({ ...promptOptions, show: false });

    const payload = {
      intentId: pageId,
      isRemoveDraft: true,
    };
    try {
      await updateMessengerFlow(payload);
      dispatch(handleFlowSaveStatus(true));
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    }
  };

  // Get relevant edge by edge id and show modal
  const onEdgeClick = (event, edge: Edge) => {
    if (edge.source !== '1') {
      setVisible(!visible);
      const selectedEdge: any = flowData?.flowPath?.edges.find(
        (edgeObj) => edgeObj?.id === edge?.id,
      );
      setSelectEdge(selectedEdge);
    }
  };

  // Remove the selected edge
  const onDeleteEdge = (edge) => {
    setEdges((prevEdges) => prevEdges.filter((edgeObj) => edgeObj.id !== edge?.id));
    dispatch(deleteEdge(edge));
    setVisible(false);
  };

  /** once we click on the canvas this function gets triggered
   * this function use to close the delete modal when click outside of the modal
   */
  const handleCanvasOnClick = (e) => {
    if (visible) {
      setVisible(false);
    }
  };

  /**
   * Handle on canvas background click
   * @param _event - click event
   */
  const handlePaneClick = (_event) => {
    const { data } = showDrawer;
    if (data) {
      toggleDrawer();
    }
  };

  return (
    <>
      <FlowBuilderPage
        nodes={nodes}
        addNewNode={addNewNode}
        onNodesChange={onNodesChange}
        edges={edges}
        onEdgesChange={onEdgesChange}
        reactFlowWrapperRef={reactFlowWrapperRef}
        onConnect={onConnect}
        onInit={onInit}
        onDrop={onDrop}
        onDragOver={onDragOver}
        proOptions={proOptions}
        toggleDrawer={toggleDrawer}
        showDrawer={showDrawer}
        handleNodeClick={handleNodeClick}
        onNodeDragStop={onNodeDragStop}
        intentId={pageId}
        headerData={headerData}
        drawerKey={drawerKey}
        onEdgeClick={onEdgeClick}
        onDeleteEdge={onDeleteEdge}
        selectEdge={selectEdge}
        visible={visible}
        handleCanvasOnClick={handleCanvasOnClick}
        handlePaneClick={handlePaneClick}
        setVisible={setVisible}
      />
      {promptOptions.show && <FlowPrompt {...promptOptions} loading={false} />}
    </>
  );
}

export default FlowBuilder;
