import { COLLECTIONS } from './db';

/**
 * All database properties are to be added here with the relevant
 * label name
 *
 */
export const ALL_PROPERTIES = {
  common: {
    secondaryContactNo: 'Secondary Contact No',
    name: 'Name',
    primaryContactName: 'Primary Contact Name',
    primaryContactNo: 'Primary Contact No',
    gender: 'Gender',
    country: 'Country',
    email: 'Email',
    adminEmail: 'Admin Email',
    streetAddress: 'Street Address',
    city: 'City',
    description: 'Description',
    postalCodeZipCode: 'Postal Code/Zip Code',
    stateProvinceRegion: 'State/Province/Region',
    severity: 'Severity',
    designation: 'Designation',
    company: 'Company',
    salutation: 'Salutation',
  },
  [COLLECTIONS.Client]: {
    companyName: 'Company Name',
  },
  [COLLECTIONS.Cases]: {
    description: 'Description',
    reopenReason: 'Re-open Reason',
    contactName: 'Previous contact Name',
    subject: 'Subject',
    severity: 'Severity',
    primaryContactNo: 'Contact Number',
    status: 'Status',
  },
  [COLLECTIONS.User]: {
    nickname: 'nickname',
    enableNickName: 'Enable Nickname',
  },
  [COLLECTIONS.Contact]: {
    fullName: 'Updated User',
    preferredContactMethod: 'Preferred Contact Method',
  },
  [COLLECTIONS.Messenger]: {
    displayName: 'Display Name',
    welcomeMessageOption: 'Welcome Message Option',
    privacyPolicyLink: 'Privacy Policy Link',
    showPrivacyPolicyLink: 'Show Privacy Policy',
    isLiveChat: 'Enable Live Chat',
    isAiBasedChat: 'Enable Ai Based Chat',
    isIntentBasedChat: 'Enable Intent Based Chat',
    tagline: 'Tagline',
    defaultWelcomeMessage: 'Welcome Message',
    color: 'Color',
    headerColor: 'Header Color',
    connectedVia: 'Connected Via',
    instanceType: 'Instance Type',
    isActive: 'Enabled',
    collectContactInfoAtStart: 'Collect Contact Info At Start',
    collectContactInfoVia: 'Collect Contact Info Via',
    autoExpireConversationInMin: 'Auto Expire Conversation In Min',
    conversationExpiryMessageDisplayTimeout: 'Conversation Expiry Message Display Timeout',
    customerFeedbackAutoExpireInMin: 'Customer Feedback AutoExpire In Min',
    enableCustomerFeedbackCollection: 'Enable Customer Feedback Collection',
    collectCustomerFeedbackVia: 'Collect Customer Feedback Via',
  },
  [COLLECTIONS.Channel]: {
    channelName: 'Channel Name',
    description: 'Description',
    addedUserName: 'Added User',
    removedUserName: 'Removed User',
    channelStatus: 'Channel Status',
    changedOwner: 'Channel Owner',
  },
  [COLLECTIONS.Role]: {
    name: 'Name',
    description: 'Description',
    permissions: 'Permissions',
  },
};
