import * as React from 'react';
import PropTypes from 'prop-types';

function ReportUserIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color = '#A7A7A7', width = 39, height = 39 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={19.5} cy={19.5} r={19} fill="#F5F5F5" stroke="#A7A7A7" />
      <g
        clipPath="url(#clip0_1416_4013)"
        stroke={color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M15 15a4 4 0 108 0 4 4 0 00-8 0zM13 29v-2a4 4 0 014-4h3.5M23 27a3 3 0 106 0 3 3 0 00-6 0zM24 29l4-4" />
      </g>
      <defs>
        <clipPath id="clip0_1416_4013">
          <path fill="#fff" transform="translate(7 8)" d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
ReportUserIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default ReportUserIcon;
