import React from 'react';
import PropTypes from 'prop-types';

import { Manage } from '../Icons';

function MangeButton(props) {
  const { onClick, id } = props;
  return (
    <div>
      <div className="flex items-center">
        <button
          id={`manageBtnDataRow${id}`}
          type="button"
          className="flex bg-spaceCadetBlue80 font-ptScanCap rounded-2xl items-center px-3 py-2 text-center hover:bg-spaceCadetBlue"
          onClick={onClick}
        >
          <Manage />
          <span className="text-white capitalize text-xs pl-1 font-bold">Manage</span>
        </button>
      </div>
    </div>
  );
}

MangeButton.propTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func,
};

export default MangeButton;
