import * as React from 'react';
import PropTypes from 'prop-types';

function Attachment(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  const { color = '#475574', width = 18, height = 18 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#clip0_31_77)">
        <path
          stroke="#9F9F9F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M15 7l-6.5 6.5a2.121 2.121 0 003 3L18 10a4.243 4.243 0 00-6-6l-6.5 6.5a6.364 6.364 0 009 9L21 13"
        />
      </g>
      <defs>
        <clipPath id="clip0_31_77">
          <path fill="#fff" d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
Attachment.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Attachment;
