/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */

import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useController } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
  CustomSelect,
  InputText,
  PrimaryButton,
  SketchColorPicker,
  TransactionTable,
} from 'components';
import GeneralButton from 'components/Buttons/GeneralButton';
import { Activate, Back, Save } from 'components/Icons';
import { getDefaultValueForSelect } from 'utils/helpers';
import { COLLECTIONS } from 'constants/db';
import { InstanceType } from 'enums/messenger.enum';

import { PATHS } from 'routes/paths';
import {
  INSTANCE_TYPES,
  SEND_WELCOME_MESSAGE_OPTIONS,
  defaultFormPlaceholders,
  transactionTableHeaders,
} from './constants';
import FormEditHeader from './containers/FormEditHeader';
import ToggleStatusBtn from './components/ToggleStatusBtn';
import WebWidget from './containers/WebWidget';
import LinkedChannels from './containers/LinkedChannels';
import MoreSettings from './containers/MoreSettings';
import ScriptTagSnippet from './containers/ScriptTagSnippet';
import Intents from './containers/Intents';
import BotAlerts from './containers/BotAlerts';
import MessengerKnowledgeBase from './containers/MessengerKnowledgeBase';

function FormPage(props: any) {
  const {
    handleSubmit,
    onSubmit,
    register,
    errors,
    handleInputChange,
    formData,
    handleSelectChange,
    editMode,
    customLoading,
    control,
    handleOnToggleInstanceStatus,
    triggerChild,
    handleChatbotTypeChangeCheck,
    channelsList,
    handleInputNumberChange,
    handleCheckChange,
    setFormData,
    handleBuildInstanceBot,
    status,
  } = props;

  const navigate = useNavigate();

  const { field: instanceTypeField } = useController({ name: 'instanceType', control });
  const { field: welcomeMessageOptionField } = useController({
    name: 'welcomeMessageOption',
    control,
  });
  const { field: defaultChannelField } = useController({ name: 'defaultChannelId', control });
  const selectedDefaultChannelId = useMemo(
    () => getDefaultValueForSelect(formData?.defaultChannelId, channelsList, 'value'),
    [formData?.defaultChannelId],
  );
  const selectedInstanceType = useMemo(
    () => getDefaultValueForSelect(formData?.instanceType, INSTANCE_TYPES, 'value'),
    [formData?.instanceType],
  );

  const selectedWelcomeMessageOption = useMemo(
    () =>
      getDefaultValueForSelect(
        formData?.welcomeMessageOption,
        SEND_WELCOME_MESSAGE_OPTIONS,
        'value',
      ),
    [formData?.welcomeMessageOption],
  );

  useEffect(() => {
    if (formData.instanceType) {
      const { onChange } = instanceTypeField;
      onChange(formData.instanceType);
    }
  }, [formData?.instanceType]);

  useEffect(() => {
    if (formData.welcomeMessageOption) {
      const { onChange } = welcomeMessageOptionField;
      onChange(formData.welcomeMessageOption);
    }
  }, [formData?.welcomeMessageOption]);

  useEffect(() => {
    if (formData.defaultChannelId) {
      const { onChange } = defaultChannelField;
      onChange(formData.defaultChannelId);
    }
  }, [formData?.defaultChannelId]);

  return (
    <div className="page messenger_instance__form">
      <div className="flex justify-between items-center">
        <div>
          {!editMode ? (
            <span className="text-spaceCadetBlue font-bold text-lg font-ptScanCap pb-4">
              Create a Messenger Instance
            </span>
          ) : (
            <FormEditHeader formData={formData} setFormData={setFormData} status={status} />
          )}
        </div>
        <div className="gap-2 flex">
          {editMode && formData._id && (
            <ToggleStatusBtn
              formData={formData}
              handleOnToggleInstanceStatus={handleOnToggleInstanceStatus}
              loading={customLoading.toggleInstance}
            />
          )}

          <GeneralButton
            text="Back"
            btnColor="bg-spaceCadetBlue80"
            color="white"
            radius="2xl"
            textSize="xs"
            btnHoverColor="hover:bg-spaceCadetBlue"
            icon={<Back />}
            handleOnClick={() => navigate(PATHS.MESSENGERS.LIST.url)}
          />
        </div>
      </div>
      <hr className="my-3" />
      {!customLoading.fetchForm && formData._id && (
        <BotAlerts formData={formData} status={status} />
      )}
      <div className="grid grid-cols-3 xs:grid-cols-1 rg:grid-cols-3 lg:grid-cols-3 gap-4 page_body">
        <div className="col-span-2">
          <div className="p-8 bg-white drop-shadow rounded-xl form_body">
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <div className="grid grid-cols-2 xs:grid-cols-1 rg:grid-cols-2 lg:grid-cols-2 gap-4">
                <div className="mb-4">
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        disabled={editMode}
                        type="text"
                        elementId="name"
                        label="Name for Messenger Instance"
                        placeholder={defaultFormPlaceholders.name}
                        register={register}
                        error={errors?.name}
                        value={formData?.name || ''}
                        onChange={(e: any) => {
                          field.onChange(e);
                          handleInputChange(e);
                        }}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 xs:grid-cols-1 rg:grid-cols-1 lg:grid-cols-1 gap-4">
                <div className="mb-4">
                  <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        type="textarea"
                        elementId="description"
                        label="Description"
                        rows={2}
                        placeholder={defaultFormPlaceholders.description}
                        register={register}
                        error={errors?.description}
                        value={formData?.description || ''}
                        onChange={(e: any) => {
                          field.onChange(e);
                          handleInputChange(e);
                        }}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 xs:grid-cols-1 rg:grid-cols-2 lg:grid-cols-2 gap-4">
                <div className="mb-4">
                  <div className="form-control">
                    <label className="label" htmlFor="instanceType">
                      <span className="label-text text-base font-base font-lato">
                        Type of Instance
                      </span>
                    </label>
                  </div>
                  <CustomSelect
                    id="instanceType"
                    required
                    isDisabled={editMode}
                    options={INSTANCE_TYPES}
                    error={errors?.instanceType}
                    value={selectedInstanceType}
                    onChange={(event) => handleSelectChange(event, 'instanceType')}
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 xs:grid-cols-1 rg:grid-cols-2 lg:grid-cols-2 gap-4">
                <div className="mb-4">
                  <Controller
                    name="displayName"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        type="text"
                        elementId="displayName"
                        label="Display name for the Messenger"
                        placeholder={defaultFormPlaceholders.displayName}
                        register={register}
                        error={errors?.displayName}
                        value={formData?.displayName || ''}
                        onChange={(e: any) => {
                          field.onChange(e);
                          handleInputChange(e);
                        }}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 xs:grid-cols-1 rg:grid-cols-1 lg:grid-cols-1 gap-4">
                <div className="mb-4">
                  <Controller
                    name="tagline"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        type="text"
                        elementId="tagline"
                        label="Tagline or Subheading"
                        placeholder={defaultFormPlaceholders.tagline}
                        register={register}
                        error={errors?.tagline}
                        value={formData?.tagline || ''}
                        onChange={(e: any) => {
                          field.onChange(e);
                          handleInputChange(e);
                        }}
                      />
                    )}
                  />
                </div>
              </div>

              {formData.instanceType === InstanceType.Chatbot && (
                <div className="grid grid-col-1 sm:grid-cols-3 gap-x-4 gap-y-2 content-center mt-2">
                  <div className="flex items-center" id="chatbotChatType">
                    <Controller
                      name="chatbotChatType.isIntentBasedChat"
                      control={control}
                      render={({ field }) => (
                        <>
                          <input
                            id="chatbotChatType.isIntentBasedChat"
                            type="checkbox"
                            className={`checkbox ${
                              errors?.chatbotChatType ? 'border border-begoniaRed' : ''
                            } mr-2`}
                            checked={formData?.isIntentBasedChat}
                            onChange={(e) => {
                              field.onChange(e);
                              handleChatbotTypeChangeCheck(e);
                            }}
                          />
                          <span className="label-text text-base font-lato">
                            Enable Intent Based
                          </span>
                        </>
                      )}
                    />
                  </div>
                  <div className="flex items-center">
                    <Controller
                      name="chatbotChatType.isAiBasedChat"
                      control={control}
                      render={({ field }) => (
                        <>
                          <input
                            id="chatbotChatType.isAiBasedChat"
                            type="checkbox"
                            className={`checkbox ${
                              errors?.chatbotChatType ? 'border border-begoniaRed' : ''
                            } mr-2`}
                            checked={formData?.isAiBasedChat}
                            onChange={(e) => {
                              field.onChange(e);
                              handleChatbotTypeChangeCheck(e);
                            }}
                          />
                          <span className="label-text text-base font-lato">Enable AI Chat</span>
                        </>
                      )}
                    />
                  </div>
                  <div className="flex items-center">
                    <Controller
                      name="chatbotChatType.isLiveChat"
                      control={control}
                      render={({ field }) => (
                        <>
                          <input
                            id="chatbotChatType.isLiveChat"
                            type="checkbox"
                            className={`checkbox ${
                              errors?.chatbotChatType ? 'border border-begoniaRed' : ''
                            } mr-2`}
                            checked={formData?.isLiveChat}
                            onChange={(e) => {
                              field.onChange(e);
                              handleChatbotTypeChangeCheck(e);
                            }}
                          />
                          <span className="label-text text-base font-lato">Enable Live Chat</span>
                        </>
                      )}
                    />
                  </div>
                  <label className="label relative mt-2" htmlFor="chatbotChatType">
                    <span className="label-text-alt absolute text-error">
                      {errors?.chatbotChatType?.message?.toString()}
                    </span>
                  </label>
                </div>
              )}
              {formData?.isAiBasedChat && (
                <div className="mb-6 flex flex-row items-center gap-2">
                  <input
                    id="allowFallbackSwitchToAi"
                    type="checkbox"
                    className="checkbox"
                    onChange={handleCheckChange}
                    checked={formData?.allowFallbackSwitchToAi}
                  />
                  <span className="text-[16px] font-lato">Allow Fallback back to switch to AI</span>
                  <br />
                </div>
              )}

              <div className="grid grid-cols-2 xs:grid-cols-1 rg:grid-cols-2 lg:grid-cols-2 gap-4">
                <div className="mb-4">
                  <div className="form-control">
                    <label className="label" htmlFor="instanceType">
                      <span className="label-text text-base font-base font-lato">
                        Default Channel
                      </span>
                    </label>
                  </div>
                  <CustomSelect
                    id="defaultChannelId"
                    options={channelsList}
                    value={selectedDefaultChannelId}
                    onChange={(event) => handleSelectChange(event, 'defaultChannelId')}
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 xs:grid-cols-1 rg:grid-cols-1 lg:grid-cols-1 gap-4">
                <div className="mb-4">
                  <Controller
                    name="defaultWelcomeMessage"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        type="textarea"
                        elementId="defaultWelcomeMessage"
                        label="Welcome Message"
                        rows={3}
                        placeholder={defaultFormPlaceholders.defaultWelcomeMessage}
                        register={register}
                        error={errors?.defaultWelcomeMessage}
                        value={formData?.defaultWelcomeMessage || ''}
                        onChange={(e: any) => {
                          field.onChange(e);
                          handleInputChange(e);
                        }}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 xs:grid-cols-1 rg:grid-cols-2 lg:grid-cols-2 gap-4">
                <div className="mb-4">
                  <div className="form-control">
                    <label className="label" htmlFor="welcomeMessageOption">
                      <span className="label-text text-base font-base font-lato">
                        Send the Welcome Message
                      </span>
                    </label>
                  </div>
                  <CustomSelect
                    id="welcomeMessageOption"
                    required
                    options={SEND_WELCOME_MESSAGE_OPTIONS}
                    error={errors?.welcomeMessageOption}
                    value={selectedWelcomeMessageOption}
                    onChange={(event) => handleSelectChange(event, 'welcomeMessageOption')}
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 xs:grid-cols-1 rg:grid-cols-1 lg:grid-cols-1 gap-4">
                <div className="mb-4">
                  <Controller
                    name="conversationEndMessage"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        type="text"
                        elementId="conversationEndMessage"
                        label="Conversation End Message"
                        rows={3}
                        placeholder={defaultFormPlaceholders.conversationEndMessage}
                        register={register}
                        error={errors?.conversationEndMessage}
                        value={formData?.conversationEndMessage || ''}
                        onChange={(e: any) => {
                          field.onChange(e);
                          handleInputChange(e);
                        }}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 xs:grid-cols-1 rg:grid-cols-2 lg:grid-cols-2 gap-4">
                <div className="mb-4">
                  <div className="form-control">
                    <label className="label" htmlFor="color">
                      <span className="label-text text-base font-base font-lato">
                        Select a Colour
                      </span>
                    </label>
                  </div>
                  <SketchColorPicker
                    handleChange={(val) => {
                      handleInputChange({ target: { id: 'color', value: val } });
                      handleInputChange({ target: { id: 'headerColor', value: val } });
                    }}
                    value={formData.color}
                  />
                  {errors?.color && (
                    <label className="label relative p-y-2 mt-2" htmlFor="color">
                      <span className="label-text-alt absolute text-error">
                        {errors?.color.message.toString()}
                      </span>
                    </label>
                  )}
                </div>
              </div>

              <div className="mb-4">
                <div className="form-control">
                  <label className="label text-base font-base font-lato">Privacy Policy Link</label>
                </div>
                <div className="items-center grid xs:grid-cols-1 md:grid-cols-2">
                  <div className=" items-center flex">
                    <Controller
                      name="showPrivacyPolicyLink"
                      control={control}
                      render={({ field }) => (
                        <input
                          id="showPrivacyPolicyLink"
                          type="checkbox"
                          className={`checkbox ${
                            errors?.showPrivacyPolicyLink ? 'border border-begoniaRed' : ''
                          } mr-2`}
                          onClick={(e) => {
                            field.onChange(e);
                            handleCheckChange(e);
                          }}
                          checked={formData?.showPrivacyPolicyLink}
                        />
                      )}
                    />
                    <span className="label-text text-base font-lato">
                      Display Privacy Policy Link
                    </span>
                  </div>
                  <div className="xs:mt-2 md:mt-0 w-full">
                    <Controller
                      name="privacyPolicyLink"
                      control={control}
                      render={({ field }) => (
                        <InputText
                          type="text"
                          elementId="privacyPolicyLink"
                          placeholder={defaultFormPlaceholders.privacyPolicyLink}
                          register={register}
                          error={errors?.privacyPolicyLink}
                          value={formData?.privacyPolicyLink || ''}
                          onChange={(e: any) => {
                            field.onChange(e);
                            handleInputChange(e);
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-2 xs:grid-cols-1 rg:grid-cols-1 lg:grid-cols-1 gap-4">
                <div className="mb-4">
                  <Controller
                    name="autoExpireConversationInMin"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        type="number"
                        elementId="autoExpireConversationInMin"
                        label="Conversation Expiry Timeout (Time Counts from the last response from the customer)"
                        placeholder={defaultFormPlaceholders.autoExpireConversationInMin}
                        register={register}
                        error={errors?.autoExpireConversationInMin}
                        value={formData?.autoExpireConversationInMin || ''}
                        onChange={(e: any) => {
                          field.onChange(e);
                          handleInputNumberChange(e);
                        }}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 xs:grid-cols-1 rg:grid-cols-1 lg:grid-cols-1 gap-4">
                <div className="mb-4">
                  <Controller
                    name="conversationExpiryMessageDisplayTimeout"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        type="number"
                        elementId="conversationExpiryMessageDisplayTimeout"
                        label="Conversation Auto Expiry Message Display Timeout"
                        placeholder={
                          defaultFormPlaceholders.conversationExpiryMessageDisplayTimeout
                        }
                        register={register}
                        error={errors?.conversationExpiryMessageDisplayTimeout}
                        value={formData?.conversationExpiryMessageDisplayTimeout || ''}
                        onChange={(e: any) => {
                          field.onChange(e);
                          handleInputNumberChange(e);
                        }}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="flex gap-2">
                <div className="mt-4 mb-4">
                  <PrimaryButton
                    id="createInstanceFormBtn"
                    text={editMode ? 'Update Instance' : 'Create Instance'}
                    btnColor="bg-spaceCadetBlue80"
                    color="white"
                    radius="2xl"
                    textSize="base"
                    btnHoverColor="hover:bg-spaceCadetBlue"
                    icon={<Save />}
                    disabled={customLoading.formSubmit}
                    loading={customLoading.formSubmit}
                  />
                </div>
                {editMode && formData?.isReady && (
                  <div className="mt-4 mb-4">
                    <PrimaryButton
                      text="Build Bot"
                      btnColor="bg-spaceCadetBlue80"
                      color="white"
                      radius="2xl"
                      type="button"
                      textSize="base"
                      handleOnClick={handleBuildInstanceBot}
                      btnHoverColor="hover:bg-spaceCadetBlue"
                      icon={<Activate />}
                      disabled={customLoading.buildingBot}
                      loading={customLoading.buildingBot}
                    />
                  </div>
                )}
              </div>
            </form>
          </div>

          <div>
            {editMode && formData._id && (
              <>
                <LinkedChannels formData={formData} triggerChild={triggerChild.linkedChannels} />
                <MoreSettings formData={formData} setFormData={setFormData} onSubmit={onSubmit} />
                {formData?.instanceType === InstanceType.Chatbot && (
                  <Intents
                    instanceData={formData}
                    editMode={editMode}
                    setInstanceData={setFormData}
                    pageCustomLoading={customLoading}
                  />
                )}
                {formData.openAiAssistantId && <MessengerKnowledgeBase formData={formData} />}
                <div className="p-8 bg-white drop-shadow rounded-xl mt-6">
                  <TransactionTable
                    recordId={formData._id}
                    collection={COLLECTIONS.Messenger}
                    transactionTableHeaders={transactionTableHeaders}
                    triggerChild={triggerChild.transactions}
                  />
                </div>
              </>
            )}
          </div>
        </div>

        <div className="flex flex-col ">
          <WebWidget formData={formData} />
          {editMode && formData._id && <ScriptTagSnippet formData={formData} />}
        </div>
      </div>
    </div>
  );
}

FormPage.propTypes = {
  setFormData: PropTypes.func,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  register: PropTypes.func,
  errors: PropTypes.any,
  formData: PropTypes.any,
  control: PropTypes.any,
  handleInputChange: PropTypes.func,
  handleInputNumberChange: PropTypes.func,
  handleOnToggleInstanceStatus: PropTypes.func,
  handleSelectChange: PropTypes.func,
  handleChatbotTypeChangeCheck: PropTypes.func,
  editMode: PropTypes.bool,
  customLoading: PropTypes.shape({
    formSubmit: PropTypes.bool,
    toggleInstance: PropTypes.bool,
    activateAccount: PropTypes.bool,
  }),
  triggerChild: PropTypes.any,
  channelsList: PropTypes.array,
  handleCheckChange: PropTypes.func,
  handleBuildInstanceBot: PropTypes.func,
  status: PropTypes.string,
};

export default FormPage;
