import _ from 'lodash';

interface ApiErrorRes {
  message?: string;
}

/**
 * Handle All API errors here
 * Destructure the error in a readable format and return
 * @param {*} e
 */
export const apiErrorHandler = (e: { data?: any }): ApiErrorRes => {
  const message = 'Something went wrong';

  // eslint-disable-next-line no-console
  console.error(e || 'no error found');

  if (!e) {
    return { message };
  }

  if (!e || !e.data) {
    return { message: 'Something went wrong, Try Again' };
  }

  const { data } = e;

  if (data.error) {
    const { msg, userMessage } = data.error;
    if (userMessage) {
      return { message: _.capitalize(userMessage) };
    }
    if (msg) {
      return { message: _.capitalize(msg) };
    }
  }

  if (data.err) {
    const { userMessage } = data.err;
    if (userMessage) {
      return { message: _.capitalize(userMessage) };
    }
  }

  return { message };
};
