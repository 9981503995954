import React from 'react';
import * as moment from 'moment';

import MangeButton from 'components/Buttons/Manage';
import Status from 'components/Labels/StatusLabel';
import GeneralButton from 'components/Buttons/GeneralButton';
import { Delete, Edit, Preview } from 'components/Icons';
import ClientStatus from 'components/Labels/ClientStatus';
import MessengerStatus from 'components/Labels/MessengerStatus';

import DeleteButton from 'components/Buttons/Delete';
import TakeChatButton from 'components/Buttons/TakeChat';
import ViewButton from 'components/Buttons/View';
import EditButton from 'components/Buttons/FlowBuilder/EditFlowButton';
import TransactionUserCell from './components/TransactionUserCell';
import TransactionUpdatedValueCell from './components/TransactionUpdatedValueCell';
import ClientAvatar from './components/ClientAvatar';
import CustomBtn from './components/CustomBtn';

/**
 * Apply custom styling, actions and components to table cells
 * @param value - Collection field name
 * @param header - Header object from table headers
 * @param row - Table data row
 * @param handlers - Custom action handlers(callback functions)
 * @param idx - array index for the raw
 * @returns customized data/component for table cell
 */
export const applyFormats = (value: any, header: any, row: any, handlers: any, idx: number) => {
  // if header type is 'dateTime' format the value
  if (header.type === 'dateTime') {
    return value ? moment(value).format('DD-MM-YYYY hh:mm A') : '';
  }

  // if header type is 'statusLabel' change the status
  if (header.type === 'statusLabel') {
    value = value?.toLowerCase();
    const statusColor = header?.params[value]?.bgColor;
    const statusLabel = header?.params[value]?.label;
    const labelColor = header?.params[value]?.color;
    const borderColor = header?.params[value]?.borderColor;

    return (
      <Status
        bgColor={statusColor}
        label={statusLabel}
        color={labelColor}
        borderColor={borderColor}
        type={value}
      />
    );
  }
  if (header.type === 'clientStatus') {
    value = value?.toLowerCase();
    return (
      <div className="mt-2 justify-start">
        <ClientStatus label={value} />
      </div>
    );
  }

  if (header.type === 'manageBtn') {
    return (
      <MangeButton
        id={`${idx}`}
        onClick={(e) => {
          if (handlers.onManage) {
            handlers.onManage(e, row);
          }
        }}
      />
    );
  }
  if (header.type === 'intentActions') {
    return (
      <div className="flex gap-3">
        <MangeButton
          onClick={(e) => {
            if (handlers.handleUtterancesPage) {
              handlers.handleUtterancesPage(e, row);
            }
          }}
        />
        <EditButton
          onClick={(e) => {
            if (handlers.onClickIntent) {
              handlers.onClickIntent(e, 'EditIntentForm');
            }
            if (handlers.tableRowSelect) {
              handlers.tableRowSelect(e, row);
            }
          }}
          data={row}
        />
      </div>
    );
  }

  // TODO! create a general function for this buttons
  if (header.type === 'viewBtn') {
    return (
      <ViewButton
        onClick={(e) => {
          if (handlers.onManage) {
            handlers.onManage(e, row);
          }
        }}
      />
    );
  }

  if (header.type === 'deleteBtn') {
    return (
      <DeleteButton
        onClick={(e) => {
          if (handlers.onManage) {
            handlers.onManage(e, row);
          }
        }}
      />
    );
  }

  if (header.type === 'TakeChatBtn') {
    return (
      <TakeChatButton
        onClick={(e) => {
          if (handlers.onManage) {
            handlers.onManage(e, row);
          }
        }}
      />
    );
  }
  // if header is avatarEnabled showing the Avatar
  if (header.avatarEnabled) {
    return (
      <div className="flex items-center gap-4">
        {header.avatarEnabled && (
          <div className="avatar placeholder">
            <div className="bg-neutral-focus text-neutral-content rounded-full w-10">
              <span className="text-2xl">T</span>
            </div>
          </div>
        )}
        <div>{value}</div>
      </div>
    );
  }
  // if header type is 'previewButton' showing the Preview button
  if (header.type === 'previewButton') {
    return (
      <div>
        <GeneralButton
          text="Preview"
          btnColor="bg-gainsboroBlue"
          color="white"
          radius="2xl"
          btnHoverColor="hover:bg-spaceCadetBlue"
          icon={<Preview />}
          textSize="xs"
          handleOnClick={() => handlers.onPreviewAction(row)}
        />
      </div>
    );
  }
  if (header.type === 'isChecked') {
    return (
      <input
        id="isEnableNickName"
        type="checkbox"
        className="checkbox justify-center items-center ml-4"
        onChange={(e) => {
          if (handlers.tableRowSelect) {
            handlers.tableRowSelect(e, row);
          }
        }}
        checked={value}
      />
    );
  }
  if (header.type === 'actionButtons') {
    return (
      <div className="flex gap-4">
        <button
          type="button"
          onClick={(e) => {
            if (handlers.onEdit) {
              handlers.onEdit(e, row);
            }
          }}
        >
          <Edit />
        </button>
        <button
          type="button"
          onClick={(e) => {
            if (handlers.onDelete) {
              handlers.onDelete(e, row);
            }
          }}
        >
          <Delete color="#F33F3F" />
        </button>
      </div>
    );
  }

  // show full name and email in the cell
  if (header.type === 'nameWithEmail') {
    return (
      <>
        <div>{row?.createdBy?.name}</div>
        <div className="text-xs">{row?.createdBy?.email}</div>
      </>
    );
  }

  if (header.type === 'transactionUser') {
    if (typeof value === 'string') {
      return '';
    }
    if (typeof value === 'object') {
      return <TransactionUserCell data={value} />;
    }
  }

  if (header.type === 'transactionUpdatedValues') {
    if (typeof value === 'string' || !value || value === '') {
      return '';
    }

    if (typeof value === 'object') {
      return <TransactionUpdatedValueCell data={value} module={header.module || 'common'} />;
    }

    return <div />;
  }

  if (header.type === 'clientAvatar') {
    return <ClientAvatar value={value} imageUrl={row[header.imageProp] || null} />;
  }

  if (header.type === 'userAvatar') {
    return <ClientAvatar value={value} imageUrl={row[header.imageProp] || null} />;
  }

  if (header.type === 'isSeverity') {
    if (value === 'high') {
      return (
        <div className="bg-begoniaRed flex items-center justify-center rounded-full pl-3 pr-3 h-6 text-white font-lato text-xs font-semibold">
          {value?.toUpperCase()}
        </div>
      );
    }
    if (value === 'medium') {
      return (
        <div className="bg-gargoyleGasYellow flex items-center justify-center rounded-full pl-3 pr-3 h-6 text-white font-lato text-xs font-semibold">
          {value?.toUpperCase()}
        </div>
      );
    }
    if (value === 'critical') {
      return (
        <div className="bg-coralRed flex items-center justify-center rounded-full pl-3 pr-3 h-6 text-white font-lato text-xs font-semibold">
          {value?.toUpperCase()}
        </div>
      );
    }
    if (value === 'low') {
      return (
        <div className="bg-chineseSilver flex items-center justify-center rounded-full pl-3 pr-3 h-6 text-white font-lato text-xs font-semibold">
          {value?.toUpperCase()}
        </div>
      );
    }
  }

  if (header.type === 'messengerInstanceStatus') {
    return (
      <div className="mt-2 justify-start">
        <MessengerStatus isActive={value} />
      </div>
    );
  }

  if (header.type === 'customBtn') {
    return <CustomBtn module={header.module} handlers={handlers} value={value} row={row} />;
  }

  if (value === undefined) {
    return '';
  }

  if (!value) {
    return '';
  }

  return value;
};
