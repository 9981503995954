/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isUndefined, omitBy } from 'lodash';
import { useDebounce } from 'use-debounce';

import { apiErrorHandler } from 'api/handler';
import { PATHS } from 'routes/paths';

import { getChannelList } from 'services/channel.service';
import { defaultListViewData } from './constants';
import ListPage from './page';
import { IUserListURLQueryParams } from './interface';
import { mapListViewDocs } from './utils';

/**
 * UserAccountListView component displays the user account list.
 * It provides filtering, sorting, and pagination functionality.
 *
 * @returns JSX element representing the user account list view.
 */
function ChannelListView() {
  const location = useLocation();
  const navigate = useNavigate();

  const [searchByInput, setSearchByInput] = React.useState();
  const [debouncedSearchBy] = useDebounce(searchByInput, 500);

  const [urlSearchParams, setUrlSearchParams] = useState(null); //  URL search parameters
  const [customLoading, setCustomLoading] = useState({ list: false, roles: false });

  const [channelListData, setChannelListData] = useState(defaultListViewData);

  // effect to initialize URL search parameters from location
  useEffect(() => {
    const urlSearch = Object.fromEntries(new URLSearchParams(location.search));
    if (urlSearch && urlSearch.page) {
      replaceUrlParamsHandler({ ...urlSearchParams, ...urlSearch });
    } else {
      replaceUrlParamsHandler({ page: 1, limit: 10 });
    }
  }, []);

  // effect to fetch user list data when URL search parameters change
  useEffect(() => {
    if (urlSearchParams) {
      getChannelsListData({
        ...channelListData,
        ...urlSearchParams,
      });
    }
  }, [urlSearchParams]);

  useEffect(() => {
    if (debouncedSearchBy || debouncedSearchBy === '') {
      handleFilterBy(debouncedSearchBy, 'searchBy');
    }
  }, [debouncedSearchBy]);

  const getChannelsListData = async (params: any) => {
    try {
      const { data } = await getChannelList({ searchBy: searchByInput, ...params });
      if (data?.node) {
        setChannelListData({
          ...channelListData,
          ...data.node,
          docs: mapListViewDocs(data.node.docs),
        });
      }
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    }
  };

  /**
   * Function to replace URL search parameters
   * @param { IClientListURLQueryParams } params -  Url search query params
   */
  const replaceUrlParamsHandler = (params: IUserListURLQueryParams) => {
    const queryParams = omitBy(
      {
        page: params.page || '1',
        limit: params.limit,
        sort: params.sort,
        order: params.order,
        searchBy: params.searchBy,
      },
      isUndefined,
    );
    const searchParams = new URLSearchParams(queryParams);
    setUrlSearchParams({ ...urlSearchParams, ...queryParams });
    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  /**
   * Handle page change in pagination by replacing the url search params
   *
   * @param {number} pageNo - Page number user requesting to navigate
   */
  const handlePageChange = (pageNo: number) => {
    replaceUrlParamsHandler({
      ...urlSearchParams,
      page: pageNo,
    });
  };

  /**
   * A callback function that is triggered when the user changes the number of
   * results to display per page.
   * @param {number} limit - The new limit value (i.e. number of results per page).
   */
  const handleResultsPerPageChange = (limit: number) => {
    setChannelListData((prevTableData) => ({ ...prevTableData, limit }));
    replaceUrlParamsHandler({
      page: channelListData.page,
      limit,
    });
  };

  /**
   * Handle filtering by adding and removing url params using value from the element and passed id
   * @param {string} value - Value that used to search or filter the field eg: doe@abc.com
   * @param {string} id - Field or parameter that need to use for filtering. eg: adminEmail
   */
  const handleFilterBy = (value: string, id: string) => {
    // create a copy of url search param
    const updatedSearchParams = urlSearchParams;
    // add new param
    if (value) {
      updatedSearchParams[id] = value;
    } else {
      delete updatedSearchParams[id]; // Remove the key if value is undefined
    }
    // update url
    replaceUrlParamsHandler(updatedSearchParams);
  };

  const handleEditTableRow = (e, row: any) => {
    navigate(`${PATHS.USER_MANAGEMENT.CHANNEL.FORM.url}?md=edit&i=${row._id}`);
  };

  return (
    <ListPage
      channelListData={channelListData}
      handlePageChange={handlePageChange}
      handleResultsPerPageChange={handleResultsPerPageChange}
      urlSearchParams={urlSearchParams}
      customLoading={customLoading}
      searchByInput={searchByInput}
      setSearchByInput={setSearchByInput}
      handleEditTableRow={handleEditTableRow}
    />
  );
}

export default ChannelListView;
