/* eslint-disable no-else-return */
/* eslint-disable no-lonely-if */
import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'slices';

import MALE_PROFILE_PIC_LOCATION from 'assets/images/profile_male.png';
import FEMALE_PROFILE_PIC_LOCATION from 'assets/images/profile_female.png';
import UNKNOWN_PROFILE_PIC_LOCATION from 'assets/images/profile_unknown.png';

function ProfilePic(props: any) {
  const userData = useSelector((state: RootState) => state.user.user);

  /**
   * Conditional profile picture
   * @returns  {JSX.Element} - Image element
   */
  const getProfilePicture = () => {
    let gender = 'male';

    if (userData.gender && userData.gender !== '') {
      gender = userData.gender;
    }

    if (userData?.profilePicLocation) {
      return <img src={userData.profilePicLocation} alt={userData.name} />;
    } else {
      if (gender === 'male') {
        return <img src={MALE_PROFILE_PIC_LOCATION} alt={userData.name} />;
      } else if (gender === 'female') {
        return <img src={FEMALE_PROFILE_PIC_LOCATION} alt={userData.name} />;
      }
      return <img src={UNKNOWN_PROFILE_PIC_LOCATION} alt={userData.name} />;
    }
  };

  return (
    <div className="avatar">
      <div className="profile-pic rounded-full">{getProfilePicture()}</div>
    </div>
  );
}

ProfilePic.propTypes = {};

export default ProfilePic;
