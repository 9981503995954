import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Handle, Position } from 'reactflow';

import { useSelector } from 'react-redux';
import { RootState } from 'slices';
import { NODE_TYPES, nodeStyle } from '../constants';
import { ICaseForm } from '../DrawerContents/AddCaseDrawer/interfaces';

function AddCaseNode(props) {
  const { id, data, selected } = props;

  const flowData = useSelector((state: RootState) => state?.flow);

  const [caseNodeData, setCaseNodeData] = useState<ICaseForm>();

  // tracks the validity of the node and highlight invalid nodes
  const [isNodeValid, setIsNodeValid] = useState(true);
  const [nodeErrors, setNodeErrors] = useState<any>();

  useEffect(() => {
    // Find the flow object in the flowData array based on nodeId
    const flowObj = flowData.nodeConfigs.find((node) => node.nodeId === id);
    if (flowObj) {
      setCaseNodeData({ ...flowObj?.caseSettings?.data });
      if (flowObj?.isValid !== undefined && flowObj?.isValid === false) {
        setIsNodeValid(false);
        setNodeErrors(flowObj?.errors);
      } else {
        setIsNodeValid(true);
        setNodeErrors([]);
      }
    }
  }, [flowData]);

  return (
    <div
      className={`bg-white p-1 rounded-md w-[250px] drop-shadow-lg opacity-80 ${
        selected ? 'border-2 border-[#99ff99]' : ''
      } ${isNodeValid ? '' : 'border-2 border-red-500'} `}
    >
      <div>
        <div className="bg-green-300 px-2 py-1 text-center text-green-600 font-semibold">
          {NODE_TYPES[data?.heading]?.label}
        </div>

        {data?.variableName && data?.validateAs && (
          <div className="p-2 m-1 border-2 border-dotted rounded-md border-gray w-1/2 justify-end">
            <p className="text-sm text-blue-400">User input</p>
            <span className="text-xs">
              {data?.validateAs}: {data?.variableName}
            </span>
          </div>
        )}

        <div className="flow-node__content py-2">
          <div className="p-1 mx-1 mb-2 border rounded">
            Success
            <Handle
              type="source"
              position={Position.Right}
              id={`case-success-${id}`}
              className="!bg-green-500"
              style={Position.Left && { ...nodeStyle, top: 58, right: 14 }}
            />
          </div>
          <div className="p-1 m-1 border rounded">
            Fail
            <Handle
              type="source"
              position={Position.Right}
              id={`case-fail-${id}`}
              className="!bg-red-500"
              style={Position.Left && { ...nodeStyle, top: 100, right: 14 }}
            />
          </div>
        </div>
      </div>
      <Handle
        type="target"
        position={Position.Left}
        id="a"
        className="!bg-blue-500"
        style={Position.Left && { ...nodeStyle, top: 18, left: -10 }}
      />
    </div>
  );
}

AddCaseNode.propTypes = {
  id: PropTypes.number,
  data: PropTypes.any,
  selected: PropTypes.bool,
};

export default AddCaseNode;
