import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { RootState } from 'slices';
import { Handle, Position } from 'reactflow';
import { useSelector } from 'react-redux';
import { NODE_TYPES, nodeStyle } from '../constants';
import { IContactForm } from '../DrawerContents/AddContactDrawer/interfaces';

function AddContactNode(props) {
  const { id, data, selected } = props;

  const flowData = useSelector((state: RootState) => state?.flow);

  const [contactNodeData, setContactNodeData] = useState<IContactForm>();

  // tracks the validity of the node and highlight invalid nodes
  const [isNodeValid, setIsNodeValid] = useState(true);
  const [nodeErrors, setNodeErrors] = useState<any>();

  useEffect(() => {
    // Find the flow object in the flowData array based on nodeId
    const flowObj = flowData.nodeConfigs.find((node) => node.nodeId === id);
    if (flowObj) {
      setContactNodeData({ ...flowObj?.contactSettings?.data });
      if (flowObj?.isValid !== undefined && flowObj?.isValid === false) {
        setIsNodeValid(false);
        setNodeErrors(flowObj?.errors);
      } else {
        setIsNodeValid(true);
        setNodeErrors([]);
      }
    }
  }, [flowData]);

  return (
    <div
      className={`bg-white p-1 rounded-md w-[250px] drop-shadow-lg opacity-80 ${
        selected ? 'border-2 border-[#94A3B8]' : ''
      } ${isNodeValid ? '' : 'border-2 border-red-500'} `}
    >
      <div>
        <div className="bg-slate-300 px-2 py-1 text-center text-slate-600 font-semibold">
          {NODE_TYPES[data?.heading]?.label}
        </div>

        <div className="flow-node__content py-2">
          <div className="p-1 mx-1 mb-2 border rounded">
            Success
            <Handle
              type="source"
              position={Position.Right}
              id={`contact-success-${id}`}
              className="!bg-green-500"
              style={Position.Left && { ...nodeStyle, top: 58, right: 14 }}
            />
          </div>
          <div className="p-1 m-1 border rounded">
            Fail
            <Handle
              type="source"
              position={Position.Right}
              id={`contact-fail-${id}`}
              className="!bg-red-500"
              style={Position.Left && { ...nodeStyle, top: 100, right: 14 }}
            />
          </div>
        </div>
      </div>

      <Handle
        type="target"
        position={Position.Left}
        id="a"
        className="!bg-blue-500"
        style={Position.Left && { ...nodeStyle, top: 18, left: -10 }}
      />
    </div>
  );
}

AddContactNode.propTypes = {
  id: PropTypes.number,
  data: PropTypes.any,
  selected: PropTypes.bool,
};

export default AddContactNode;
