import React from 'react';
import PropTypes from 'prop-types';

import TakeChatIcon from 'components/Icons/TakeChatIcon';

function TakeChatButton(props) {
  const { onClick } = props;
  return (
    <div>
      <div className="flex items-center">
        <button
          type="button"
          className="flex bg-spaceCadetBlue80 font-ptScanCap rounded-2xl items-center px-3 py-2 text-center hover:bg-spaceCadetBlue"
          onClick={onClick}
        >
          <TakeChatIcon />
          <span className="text-white capitalize text-xs pl-1 font-bold">Take Chat</span>
        </button>
      </div>
    </div>
  );
}

TakeChatButton.propTypes = {
  onClick: PropTypes.func,
};

export default TakeChatButton;
