/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-else-return */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { useHotkeys } from 'react-hotkeys-hook';

import Complete from 'components/Icons/Complete';
import Forward from 'components/Icons/Forward';
import GeneralButton from 'components/Buttons/GeneralButton';
import SearchBar from 'components/SearchBar';
import SendButton from 'components/Buttons/Send';
import SenderChatBubble from 'components/Chat/SenderBubble';
import ReceiverChatBubble from 'components/Chat/ReceiverBubble';
import { useSocket } from 'contexts/socket';
import { SOCKET_EVENTS } from 'constants/events';
import { INewCustomerMessageSocketEvent } from 'interfaces/sockets.interface';
import { MessageCreatedBy } from 'enums/message.enum';
import { createMessage, fetchMessageList, uploadAttachmentService } from 'services/message.service';
import { apiErrorHandler } from 'api/handler';
import { RootState } from 'slices';
import SystemChatBubble from 'components/Chat/SystemBubble';
import { getConversationService } from 'services/conversation.service';

import ChatInput from '../ChatInput';
import { constructMessage, getNextObjectId, replaceMessageById } from '../../utils';

function Conversation(props) {
  const {
    handleForwardMsg,
    handleCompleteMsg,
    selectedImage,
    setSelectedImage,
    isNewChat,
    selectedConversationId,
    setCustomLoading,
    customLoading,
    setChatFormData,
    chatFormData,
    allMessages,
    setAllMessages,
    conversationList,
    handleOpen,
    setConversationList,
    selectedTab,
  } = props;

  // TODO! to test react-hotkeys-hook
  // useHotkeys('ctrl+s', (event) => {
  //   event.preventDefault();
  //   alert('Key a was pressed');
  // });

  const currentUser = useSelector((state: RootState) => state.user.user);
  const { socketConnected } = useSelector((state: RootState) => state.auth);

  const _isMounted = useRef(true);
  const uploadFileProgressRef = useRef();
  const divRef: any = useRef(null);
  const chatBubbleRef = useRef(null);

  const [uploadProgressValue, setFileUploadProgressValue] = useState(0);
  // storing the selected conversation data
  const [conversationData, setConversationData] = useState(null);

  const socketClient = useSocket();

  let consecutiveSenderCount = 0;
  let consecutiveReceiverCount = 0;
  let consecutiveUserReceiverCount = 0;
  let startOfTodaysMessages = false;

  const [onMount, setOnMount] = useState(true);

  const [searchText, setSearchText] = useState('');
  const [scrollIndex, setScrollIndex] = useState(-1);

  useEffect(
    () => () => {
      _isMounted.current = false;
    },
    [],
  );

  // Use useEffect to set the scroll position when searchText changes
  useEffect(() => {
    const matchingIndex = searchChat(searchText);
    if (!onMount && matchingIndex !== 0) {
      setScrollIndex(matchingIndex);
      scrollToChatBubble();
    } else {
      // Position the scroll bar at the bottom initially
      divRef.current.scrollTop = divRef.current.scrollHeight;
      setScrollIndex(-1);
      setOnMount(false);
    }
  }, [searchText, allMessages]);

  useEffect(() => {
    const sharedObject = {
      node: {
        mount: true,
        conversationId: selectedConversationId || null, // Use null or a default value
      },
    };

    if (socketClient && sharedObject.node.conversationId && socketConnected) {
      socketClient.emit(SOCKET_EVENTS.CLIENT.WATCH_CHAT_INBOX, sharedObject);
    }

    return () => {
      // Modify the mount property for unmounting
      sharedObject.node.mount = false;
      if (socketClient && sharedObject.node.conversationId) {
        socketClient.emit(SOCKET_EVENTS.CLIENT.WATCH_CHAT_INBOX, sharedObject);
      }
    };
  }, [socketClient, selectedConversationId, socketConnected]);

  useEffect(() => {
    // side Effect - triggering when conversation gets end by customer end
    socketClient?.on(
      SOCKET_EVENTS.SERVER.CONVERSATION.CONVERSATION_ENDED,
      (res: INewCustomerMessageSocketEvent) => {
        if (_isMounted.current) {
          const { message } = res.node;
          if (selectedConversationId === message?.conversationId) {
            fetchConversationData(selectedConversationId);
            pushMessageToArray(message);
          }
        }
      },
    );
  }, [socketClient, selectedConversationId]);

  useEffect(() => {
    const handleNewCustomerMessage = (res: INewCustomerMessageSocketEvent) => {
      if (_isMounted.current) {
        const { message } = res.node;
        if (selectedConversationId === message?.conversationId) {
          pushMessageToArray(message);
        }
      }
    };

    socketClient?.on(SOCKET_EVENTS.SERVER.NEW_CUSTOMER_MESSAGE, handleNewCustomerMessage);

    return () => {
      // Unsubscribe from the socket event when the component unmounts
      socketClient?.off(SOCKET_EVENTS.SERVER.NEW_CUSTOMER_MESSAGE, handleNewCustomerMessage);
    };
  }, [socketClient, selectedConversationId]);

  useEffect(() => {
    if (selectedConversationId) {
      fetchMessages(selectedConversationId);
      fetchConversationData(selectedConversationId);
    }
  }, [selectedConversationId]);

  const pushMessageToArray = (message: any) => {
    if (!message || !message?._id) {
      return;
    }
    const isExists = allMessages.some((val: any) => val._id === message._id);
    if (isExists) {
      return;
    }

    setAllMessages((prevState) => [...prevState, constructMessage(message)]);
  };

  async function fetchMessages(id) {
    try {
      setCustomLoading((prevState) => ({
        ...prevState,
        messagesLoading: true,
      }));
      const { data } = await fetchMessageList(id, { limit: 0 });
      if (data?.node.docs.length > 0) {
        const mappedData = data?.node.docs.map((obj: any) => constructMessage(obj));
        setAllMessages(mappedData);
      } else {
        setAllMessages([]);
      }
    } catch (e) {
      const { message: exception } = apiErrorHandler(e);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({
        ...prevState,
        messagesLoading: false,
      }));
    }
  }

  async function fetchConversationData(id) {
    try {
      setCustomLoading((prevState) => ({
        ...prevState,
        messagesLoading: true,
      }));
      const { data } = await getConversationService(id);
      if (data?.node) {
        setConversationData(data?.node);
      }
    } catch (e) {
      const { message: exception } = apiErrorHandler(e);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({
        ...prevState,
        messagesLoading: false,
      }));
    }
  }

  function onUploadProgressHandler(progressEvent) {
    const { loaded, total } = progressEvent;
    const percentage = Math.floor((loaded * 100) / total);

    if (percentage && uploadFileProgressRef?.current) {
      // @ts-ignore
      uploadFileProgressRef.current.value = Math.round(percentage);
    }
    if (percentage) {
      setFileUploadProgressValue(percentage);
    }
  }

  const handleKeyDown = (event) => {
    if ((event.ctrlKey || event.metaKey) && event.key === 'Enter') {
      handleSendChat(event, allMessages[0]);
      event.preventDefault();
    }
    if ((event.ctrlKey || event.metaKey) && event.key === 's') {
      handleSendAndGoNext(event, allMessages[0]);
      event.preventDefault();
    }
  };

  const handleSendAndGoNext = async (event: any, msgData: any) => {
    const nextChatId = getNextObjectId(msgData?.conversationId, conversationList);
    await handleSendChat(event, msgData);
    if (nextChatId) {
      handleOpen(nextChatId);
    }
  };

  const handleSendChat = async (event: any, msgData: any) => {
    event.preventDefault();
    const { conversationId, customerId } = msgData;

    const payload: any = {
      customerId,
      _id: uuid(),
      uuid: uuid(),
      createdAt: new Date(),
      createdBy: MessageCreatedBy.User,
    };

    const user = currentUser;

    if (chatFormData?.chatInputText) {
      payload.type = 'text';
      payload.text = chatFormData?.chatInputText;
    }

    if (selectedImage) {
      payload.file = selectedImage;
      payload.type = 'image';
    }

    try {
      setChatFormData({ ...chatFormData, chatInputText: '' });
      if (payload.type === 'text') {
        pushMessageToArray({ ...payload, user });
        await createMessage(conversationId, { ...payload });
        const updatedConversationList = replaceMessageById(
          conversationId,
          payload.text,
          conversationList,
        );
        setConversationList(updatedConversationList);
      }
      if (payload.type === 'image') {
        setCustomLoading((prevState) => ({
          ...prevState,
          fileUploading: true,
          submitMessage: true,
        }));
        const res = await uploadAttachmentService(conversationId, payload, onUploadProgressHandler);
        if (res.data) {
          const { node } = res.data;
          if (node) {
            const { attachment, message } = node;
            pushMessageToArray({ ...message, attachment });
          }
        }
        setSelectedImage(null);
      }
    } catch (e) {
      const { message: exception } = apiErrorHandler(e);
      toast.error(exception);
    } finally {
      setCustomLoading((prevState) => ({
        ...prevState,
        submitMessage: false,
        fileUploading: false,
      }));
    }
  };

  // get the index of the relevant message
  function searchChat(textToSearch: string) {
    const index = allMessages.findIndex((obj) => obj.text && obj.text.includes(textToSearch));
    return index;
  }

  const handleInputChange = (event: { target: { id: any; value: any } }) => {
    const { value } = event.target;
    setSearchText(value);
  };

  // handling the scroll function
  const scrollToChatBubble = () => {
    if (chatBubbleRef.current) {
      chatBubbleRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="h-full flex flex-col">
      <div className="flex flex-row lg:flex-row justify-between py-4 px-4 items-center bg-white w-auto">
        {selectedConversationId ? (
          <p className="font-lato lg:text-base xs:text-sm text-spaceCadetBlue lg:mb-0">
            Conversation With <span className="font-semibold">{allMessages[0]?.contactName}</span>
          </p>
        ) : (
          <p className="font-lato lg:text-base xs:text-sm text-spaceCadetBlue lg:mb-0">
            Select A Conversation
          </p>
        )}
        {selectedConversationId && selectedTab === 0 && (
          <div className="flex xs:flex-wrap lg:flex-nowrap gap-2 justify-center items-center pl-3">
            {!conversationData?.isCompleted && !conversationData?.isAutoExpired && (
              <GeneralButton
                text="Forward"
                btnColor="bg-spaceCadetBlue80"
                color="white"
                radius="2xl"
                textSize="xs"
                btnHoverColor="hover:bg-spaceCadetBlue"
                handleOnClick={() => handleForwardMsg()}
                icon={<Forward />}
              />
            )}
            <GeneralButton
              text="Complete"
              btnColor="bg-spaceCadetBlue80"
              color="white"
              radius="2xl"
              textSize="xs"
              btnHoverColor="hover:bg-spaceCadetBlue"
              handleOnClick={() => handleCompleteMsg()}
              icon={<Complete />}
            />
          </div>
        )}
      </div>
      {selectedConversationId && (
        <div className="mt-2 pr-2 pl-2">
          <SearchBar
            placeholder="Type here to search the conversation"
            onSearch={handleInputChange}
            searchByInput={searchText}
          />
        </div>
      )}
      <div className="flex-1 ">
        <div
          className={`${
            isNewChat || selectedTab === 2
              ? 'max-h-[calc(100vh-220px)]'
              : 'max-h-[calc(100vh-350px)]'
          } table-layout_custom-scrollbar overflow-y-auto p-3`}
          ref={divRef}
        >
          {/* Messages and chat content go here */}
          {customLoading.messagesLoading ? (
            <div className="flex items-center justify-center">
              <span className="loading loading-infinity loading-lg" />
            </div>
          ) : (
            <>
              {allMessages.map((obj, index) => {
                const messageDate = new Date(obj.createdAt);
                const isToday = messageDate.toDateString() === new Date().toDateString();

                if (obj?.createdBy === MessageCreatedBy.Bot) {
                  consecutiveReceiverCount = 0;
                  consecutiveUserReceiverCount = 0;
                  consecutiveSenderCount += 1;
                  return (
                    <>
                      {isToday && !startOfTodaysMessages && (
                        <div key="divider" className="divider bg-[#435171] h-[1px]">
                          {(() => {
                            startOfTodaysMessages = true;
                            consecutiveSenderCount = 1;
                            return (
                              <div className="bg-[#435171] justify-center items-center w-auto h-auto px-6 py-1 text-xs text-center text-white font-lato font-bold rounded-3xl">
                                Today
                              </div>
                            );
                          })()}
                        </div>
                      )}
                      <div key={obj._id} ref={index === scrollIndex ? chatBubbleRef : null}>
                        <SenderChatBubble
                          key={obj._id}
                          consecutiveRenderCount={
                            isToday && !startOfTodaysMessages ? 1 : consecutiveSenderCount
                          }
                          textHighlighter={index === scrollIndex ? 'bg-[#FFE249]' : ''}
                          {...obj}
                        />
                      </div>
                    </>
                  );
                } else if (obj?.createdBy === MessageCreatedBy.Customer) {
                  consecutiveSenderCount = 0;
                  consecutiveUserReceiverCount = 0;
                  consecutiveReceiverCount += 1;

                  return (
                    <>
                      {isToday && !startOfTodaysMessages && (
                        <div key="divider" className="divider bg-[#435171] h-[1px]">
                          {(() => {
                            startOfTodaysMessages = true;
                            consecutiveReceiverCount = 1;
                            return (
                              <div className="bg-[#435171] justify-center items-center w-auto h-auto px-6 py-1 text-xs text-center text-white font-lato font-bold rounded-3xl">
                                Today
                              </div>
                            );
                          })()}
                        </div>
                      )}
                      <div key={obj._id} ref={index === scrollIndex ? chatBubbleRef : null}>
                        <ReceiverChatBubble
                          key={obj._id}
                          consecutiveRenderCount={
                            isToday && !startOfTodaysMessages ? 1 : consecutiveReceiverCount
                          }
                          textHighlighter={index === scrollIndex ? 'bg-[#FFE249]' : ''}
                          {...obj}
                        />
                      </div>
                    </>
                  );
                } else if (obj?.createdBy === MessageCreatedBy.User) {
                  consecutiveSenderCount = 0;
                  consecutiveReceiverCount = 0;
                  consecutiveUserReceiverCount += 1;
                  return (
                    <>
                      {isToday && !startOfTodaysMessages && (
                        <div key="divider" className="divider bg-[#435171] h-[1px]">
                          {(() => {
                            startOfTodaysMessages = true;
                            consecutiveUserReceiverCount = 1;
                            return (
                              <div className="bg-[#435171] justify-center items-center w-auto h-auto px-6 py-1 text-xs text-center text-white font-lato font-bold rounded-3xl">
                                Today
                              </div>
                            );
                          })()}
                        </div>
                      )}
                      <div
                        key={obj._id}
                        ref={index === scrollIndex ? chatBubbleRef : null}
                        className={`${consecutiveUserReceiverCount === 1 ? 'mt-3' : ''}`}
                      >
                        <SenderChatBubble
                          key={obj._id}
                          consecutiveRenderCount={
                            isToday && !startOfTodaysMessages ? 1 : consecutiveUserReceiverCount
                          }
                          textHighlighter={index === scrollIndex ? 'bg-[#FFE249]' : ''}
                          {...obj}
                        />
                      </div>
                    </>
                  );
                } else if (
                  obj?.isTransaction &&
                  (obj.transactionType === 'ChatExpired' ||
                    obj.transactionType === 'CustomerEndChat')
                ) {
                  consecutiveSenderCount = 0;
                  consecutiveReceiverCount = 0;
                  consecutiveUserReceiverCount = 0;
                  return (
                    <div key={obj._id} ref={index === scrollIndex ? chatBubbleRef : null}>
                      <br />
                      <SystemChatBubble
                        key={obj._id}
                        {...obj}
                        handleCompleteConversation={handleCompleteMsg}
                        conversationData={conversationData}
                      />
                    </div>
                  );
                }
              })}
            </>
          )}
        </div>
      </div>

      {/* Chat controllers */}
      {isNewChat || selectedTab === 2 ? (
        <div />
      ) : (
        <>
          {customLoading.fileUploading && (
            <motion.div
              className="card-message-input__progress__container"
              initial={{ width: 0 }} // Initial state (width: 0)
              animate={{ width: `${uploadProgressValue}%` }} // Animate to the current progress value
              transition={{ duration: 0.5 }} // Animation duration
            >
              <div className="bg-spaceCadetBlue text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-md">
                {uploadProgressValue}%
              </div>
            </motion.div>
          )}
          <div className="bg-white p-3 flex flex-row xs:flex-row lg:flex-row w-full justify-center items-center">
            <div className="flex flex-grow ">
              <ChatInput
                chatFormData={chatFormData}
                setChatFormData={setChatFormData}
                setSelectedImage={setSelectedImage}
                selectedImage={selectedImage}
                handleKeyDown={handleKeyDown}
                disabled={conversationData?.isCompleted || conversationData?.isAutoExpired}
              />
            </div>
            <div className="items-center flex-col flex xs:flex-col lg:flex-col xs:gap-1 xs:mt-3 sm:ml-2 sm:mb-2">
              <div className="mb-2">
                <SendButton
                  text="Send"
                  btnColor="bg-spaceCadetBlue"
                  btnSize="sm"
                  handleSendChat={(event) => handleSendChat(event, allMessages[0])}
                  disabled={conversationData?.isCompleted || conversationData?.isAutoExpired}
                />
              </div>
              <div className="mb-2">
                <SendButton
                  text="Send and Next"
                  btnColor="bg-independenceBlue"
                  btnSize="sm"
                  handleSendChat={(event) => handleSendAndGoNext(event, allMessages[0])}
                  disabled={conversationData?.isCompleted || conversationData?.isAutoExpired}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

Conversation.propTypes = {
  chatFormData: PropTypes.object,
  setChatFormData: PropTypes.func,
  contactName: PropTypes.string,
  handleForwardMsg: PropTypes.func,
  handleCompleteMsg: PropTypes.func,
  handleSendChat: PropTypes.func,
  selectedImage: PropTypes.any,
  setSelectedImage: PropTypes.func,
  messagesLoading: PropTypes.bool,
  setCustomLoading: PropTypes.func,
  customLoading: PropTypes.object,
  isNewChat: PropTypes.bool,
  selectedConversationId: PropTypes.string,
  allMessages: PropTypes.array,
  setAllMessages: PropTypes.func,
  conversationList: PropTypes.array,
  handleOpen: PropTypes.func,
  setConversationList: PropTypes.func,
  selectedTab: PropTypes.number,
};
export default Conversation;
