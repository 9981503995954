import React, { useEffect, useRef, useState } from 'react';
import { Drawer } from 'react-daisyui';
import ReactDom from 'react-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// #region assets | components
import { Close } from 'components/Icons';
import GeneralButton from 'components/Buttons/GeneralButton';

// #end region components
import { updateCaseSettings, updateContactSettings, updateCarouselSettings } from 'slices/flow';
import { getAllFlow } from 'services/messengerFlow.service';
import { getLinkedChannels } from 'services/messenger.service';
import { apiErrorHandler } from 'api/handler';
import { toast } from 'react-toastify';
import { NodeHeaders } from '../constants';
import {
  APIRequestDrawer,
  ActionDrawer,
  CarouselDrawer,
  FilterDrawer,
  LiveChatDrawer,
  MessageDrawer,
} from '../components/DrawerContents';
import AddContactDrawer from '../components/DrawerContents/AddContactDrawer/AddContactDrawer';
import AddCaseDrawer from '../components/DrawerContents/AddCaseDrawer/AddCaseDrawer';
import FlowDrawer from '../components/DrawerContents/FlowDrawer/FlowDrawer';
import { IShowDrawer } from '../interfaces';
import { ICarouselSetting } from '../components/DrawerContents/MessageDrawer/interfaces';

interface IFlowBuilderDrawerProps {
  showDrawer: IShowDrawer;
  toggleDrawer: () => void;
  headerData: any;
  drawerKey: number;
}
function FlowBuilderDrawer(props: IFlowBuilderDrawerProps) {
  const { showDrawer, toggleDrawer, headerData, drawerKey } = props;
  const { data } = showDrawer;
  const { messengerId } = headerData;

  const dispatch = useDispatch();

  const [isFormValid, setIsFormValid] = useState(true);

  const actionDrawerRef = useRef(null);
  const messageDrawerRef = useRef(null);
  const contactDrawerRef = useRef(null);
  const caseDrawerRef = useRef(null);
  const filterDrawerRef = useRef(null);
  const apiRequestDrawerRef = useRef(null);
  const flowDrawerRef = useRef(null);
  const carouselDrawerRef = useRef(null);
  const liveChatDrawerRef = useRef(null);

  const [flowOptions, setFlowOptions] = useState<any>();
  const [channelsOptions, setChannelsOptions] = useState<any>();

  useEffect(() => {
    if (messengerId) {
      fetchFlowsById(messengerId);
      fetchLinkedChannels(messengerId);
    }
  }, [messengerId]);

  // handle add contact form data
  const handleContactDrawerSubmit = (contactFormData, nodeId) => {
    const requiredFields = [
      'salutation',
      'firstName',
      'lastName',
      'email',
      'primaryContactNo',
      'preferredContactMethod',
      'country',
    ];
    const areAllRequiredFieldsPresent = requiredFields.every(
      (field) =>
        contactFormData &&
        contactFormData[field] &&
        contactFormData[field].value &&
        contactFormData[field].valueType,
    );
    if (!areAllRequiredFieldsPresent) {
      setIsFormValid(false);
      toast.error('Please fill all the required fields');
    } else {
      dispatch(updateContactSettings({ data: contactFormData, nodeId }));
      toggleDrawer();
    }
  };

  // handle add case form data
  const handleCaseDrawerSubmit = (caseFormData, nodeId) => {
    const requiredFields = ['contact', 'primaryContactNo', 'category', 'subject', 'status'];

    // check required fields are in caseFormData
    const areAllRequiredFieldsPresent = requiredFields.every(
      (field) =>
        caseFormData &&
        caseFormData[field] &&
        caseFormData[field].value &&
        caseFormData[field].valueType,
    );
    if (!areAllRequiredFieldsPresent) {
      setIsFormValid(false);
      toast.error('Please fill all the required fields');
    } else {
      dispatch(updateCaseSettings({ data: caseFormData, nodeId }));
      toggleDrawer();
    }
  };

  const fetchFlowsById = async (id: string) => {
    try {
      const { data: flows } = await getAllFlow(id);
      const mappedData = flows.map((flow) => ({
        value: flow?.value || '',
        label: `${flow?.label || ''}`,
      }));
      setFlowOptions(mappedData);
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    }
  };

  const fetchLinkedChannels = async (id: string) => {
    try {
      const { data: channels } = await getLinkedChannels(id);
      const mappedData = channels?.nodes?.map((channel) => ({
        value: channel?.channel?._id || '',
        label: `${channel?.channel?.channelName || ''}`,
      }));
      setChannelsOptions(mappedData);
    } catch (error) {
      const { message: exception } = apiErrorHandler(error);
      toast.error(exception);
    }
  };

  /**
   * Handle carousel drawer submission
   * @param {string} nodeId  - node Id
   * @param {ICarouselSetting} carouselSettings - Carousel settings
   * @param {boolean} hideDrawer - Hide drawer
   */
  const handleCarouselDrawerSubmit = (
    nodeId: string,
    carouselSettings: ICarouselSetting,
    hideDrawer: true,
  ) => {
    dispatch(updateCarouselSettings({ nodeId, carouselSettings }));

    if (hideDrawer) {
      toggleDrawer();
    }
  };
  // Function to handle the submit button click in the parent component
  const handleParentSubmit = () => {
    // Trigger the handleSubmit function in the ActionDrawer component using the ref
    if (actionDrawerRef.current) {
      actionDrawerRef.current.handleSubmit();
    } else if (messageDrawerRef.current) {
      messageDrawerRef.current.handleSubmit();
    } else if (filterDrawerRef.current) {
      filterDrawerRef.current.handleSubmit();
    } else if (apiRequestDrawerRef.current) {
      apiRequestDrawerRef.current.handleSubmit();
    }
    if (messageDrawerRef.current) {
      messageDrawerRef.current.handleSubmit();
    }
    if (contactDrawerRef.current) {
      contactDrawerRef.current.handleSubmit();
    }
    if (caseDrawerRef.current) {
      caseDrawerRef.current.handleSubmit();
    }
    if (flowDrawerRef.current) {
      flowDrawerRef.current.handleSubmit();
    }
    if (carouselDrawerRef.current) {
      carouselDrawerRef.current.handleSubmit();
    }
    if (liveChatDrawerRef.current) {
      liveChatDrawerRef.current.handleSubmit();
    }
  };

  const renderDrawerContent = (key) => {
    if (data?.title === NodeHeaders.MESSAGE) {
      return (
        <MessageDrawer
          key={key + drawerKey}
          nodeId={data?.id}
          ref={messageDrawerRef}
          toggleDrawer={toggleDrawer}
        />
      );
    }
    if (data?.title === NodeHeaders.FILTER) {
      return (
        <FilterDrawer
          key={key + drawerKey}
          nodeId={data?.id}
          ref={filterDrawerRef}
          toggleDrawer={toggleDrawer}
        />
      );
    }
    if (data?.title === NodeHeaders.API_REQUEST) {
      return (
        <APIRequestDrawer
          key={key + drawerKey}
          nodeId={data?.id}
          ref={apiRequestDrawerRef}
          toggleDrawer={toggleDrawer}
        />
      );
    }
    if (data?.title === NodeHeaders.ADD_CASE) {
      return (
        <AddCaseDrawer
          key={key + drawerKey}
          nodeId={data?.id}
          ref={caseDrawerRef}
          handleCaseDrawerSubmit={handleCaseDrawerSubmit}
        />
      );
    }
    if (data?.title === NodeHeaders.ADD_CONTACT) {
      return (
        <AddContactDrawer
          key={key + drawerKey}
          nodeId={data?.id}
          ref={contactDrawerRef}
          handleContactDrawerSubmit={handleContactDrawerSubmit}
          isFormValid={isFormValid}
          setIsFormValid={setIsFormValid}
        />
      );
    }
    if (data?.title === NodeHeaders.FLOW) {
      return (
        <FlowDrawer
          key={key + drawerKey}
          node={data}
          ref={flowDrawerRef}
          toggleDrawer={toggleDrawer}
          flowOptions={flowOptions}
        />
      );
    }
    if (data?.title === NodeHeaders.ACTION) {
      return (
        <ActionDrawer
          key={key + drawerKey}
          nodeId={data?.id}
          ref={actionDrawerRef}
          toggleDrawer={toggleDrawer}
        />
      );
    }
    if (data?.title === NodeHeaders.CAROUSEL) {
      return (
        <CarouselDrawer
          key={key + drawerKey}
          nodeId={data?.id}
          ref={carouselDrawerRef}
          handleCarouselDrawerSubmit={handleCarouselDrawerSubmit}
        />
      );
    }
    if (data?.title === NodeHeaders.LIVE_CHAT) {
      return (
        <LiveChatDrawer
          key={key + drawerKey}
          node={data}
          ref={liveChatDrawerRef}
          toggleDrawer={toggleDrawer}
          messengerId={messengerId}
        />
      );
    }
    return (
      <div key={key} className="p-3">
        Default Content
      </div>
    );
  };

  return ReactDom.createPortal(
    <Drawer
      end
      className="flow-builder-drawer__container"
      overlayClassName="hidden"
      open={data?.title !== NodeHeaders.End_CHAT && showDrawer?.show}
      onClickOverlay={() => {
        toggleDrawer();
      }}
      side={
        <div className="relative flow-builder__drawer bg-culturedGray justify-center min-h-screen w-[550px] border-l ">
          <div className="w-full h-auto p-[27px] bg-primary flex flex-wrap justify-between items-center">
            <span className="font-ptScanCap text-md font-semibold text-white">Flow builder</span>
            <div className="cursor-pointer">
              <Close width={26} height={26} onClick={toggleDrawer} />
            </div>
          </div>

          <div className="p-5">
            <div className="mt-1 left-0 right-0 px-5">
              <h5 className="pb-[5px] font-bold uppercase text-sm">{data?.title}</h5>
              <hr className="bg-spaceCadetBlue h-[2px]" />
            </div>
            <br />
            <div className="drawer-content pr-5 pl-5 max-h-[calc(100vh-240px)] min-h-[calc(100vh-240px)] overflow-y-auto overflow-x-hidden">
              {renderDrawerContent(data?.id)}
            </div>
          </div>
          <div className="p-5 pr-3 ml-5 fixed bottom-0 left-0 right-0">
            <GeneralButton
              text="Apply"
              btnColor="bg-spaceCadetBlue80"
              color="white"
              radius="2xl"
              textSize="xs"
              btnHoverColor="hover:bg-spaceCadetBlue"
              handleOnClick={handleParentSubmit}
            />
          </div>
        </div>
      }
    />,
    document.body,
  );
}

FlowBuilderDrawer.propTypes = {
  showDrawer: PropTypes.object,
  toggleDrawer: PropTypes.func,
  title: PropTypes.string,
  handleCreateVariable: PropTypes.func,
  drawerKey: PropTypes.number,
  headerData: PropTypes.object,
};

export default FlowBuilderDrawer;
