/* eslint-disable react/function-component-definition */
import React, { useEffect, useRef, useState } from 'react';
import Select, { components } from 'react-select';

import { reactSelectVariableDropdownStyles } from 'styles/select';
import { ISelectOption } from 'interfaces/component.interface';
import CustomSelect, { ReactSelectFixedProps } from 'components/Form/CustomSelect';
import { ChevronDown } from 'components/Icons';
import { groupStyles } from './constants';

interface VariableDropdownProps extends ReactSelectFixedProps {
  variablesOnly?: boolean;
}

const CustomDropdownIndicator = (props) => (
  <components.DropdownIndicator {...props} className="pr-0">
    <div className="inline-flex text-stone-500 items-center">
      <div className="-mt-1">{'{}'}</div>
      <ChevronDown color="#000" width={24} height={16} strokeWidth={0.5} />
    </div>
  </components.DropdownIndicator>
);

// menu item with datatype
const formatOptionLabel = ({ value, label, type }: ISelectOption) => (
  <div className="flex justify-between items-center">
    <div className="flex-grow">{label}</div>
    <div className="text-gray-500 font-light text-sm ml-1">{type}</div>
  </div>
);

/**
 * This is variable dropdown for flow builder
 * @param {*} props
 */
const VariableDropdown = (props: VariableDropdownProps) => {
  const {
    id,
    value,
    required,
    onChange,
    placeholder,
    isDisabled,
    options,
    isNotDisplaySvg = false,
    customStyles = reactSelectVariableDropdownStyles,
    variablesOnly = false,
  } = props;

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setMenuIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      {data?.label === 'System variables' ? (
        <div className="border border-gray-200 w-full" />
      ) : (
        <span className="capitalize font-base font-lato">{data.label}</span>
      )}
    </div>
  );

  // show variables only
  if (variablesOnly) {
    return (
      <div>
        <CustomSelect
          id={id}
          required={required}
          formatOptionLabel={formatOptionLabel}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          options={options}
          isDisabled={isDisabled}
          customStyles={customStyles}
        />
      </div>
    );
  }

  return (
    <div ref={dropdownRef}>
      <Select
        id={id}
        name={id}
        menuIsOpen={menuIsOpen}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
        required={required}
        placeholder={placeholder}
        formatGroupLabel={formatGroupLabel}
        formatOptionLabel={formatOptionLabel}
        className="rounded-2xl"
        styles={{
          ...customStyles,
          dropdownIndicator: (provided: any) => ({
            ...provided,
            svg: {
              display: isNotDisplaySvg ? 'none' : 'block',
            },
          }),
        }}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: CustomDropdownIndicator,
        }}
        value={value}
        onChange={onChange}
        options={options}
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default VariableDropdown;
