export function extractFirstWordAfterSlash(input) {
  const parts = input.split('/');
  if (parts.length > 1) {
    return parts[1];
  }
  return null;
}

export function popAndReturnLast(notificationArr, selectedNotification) {
  // Find the index of the selectedNotification in the array
  const indexToRemove = notificationArr.findIndex(
    (notification) => notification._id === selectedNotification._id,
  );

  // If the selectedNotification is found, remove it from the array
  if (indexToRemove !== -1) {
    notificationArr.splice(indexToRemove, 1);
  }

  // Get the last object in the modified notificationArr
  const lastNotification = notificationArr[notificationArr.length - 1];

  return { lastNotification, notificationArr };
}
